import { Box, Drawer, IconButton } from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { MHidden } from "src/components/@material-extend";
import Scrollbar from "src/components/Scrollbar";
import Logo from "src/components/Logo";
import { styled } from "@mui/material/styles";
import SettingsIcon from "@mui/icons-material/Settings";
import SettingsSidebarConfig from "./SettingsSidebarConfig";
import { useContentSetting } from "src/Hooks/ContentSettingState";
import sidebarConfig from "./SidebarConfig";
import NavSection from "src/components/NavSection";
import NavSectionSettings from "src/components/SupportTickets/NavSectionSettings";
const DRAWER_WIDTH = 280;
const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));
export default function SettingsSidebar() {
  const { pathname } = useLocation();
  const [open, setOpen] = useState(true);
  const { userInfo, consultantInfo, consultantDetail } = useContentSetting();
  const navigate = useNavigate();
  let nav_items = sidebarConfig({
    navItem: consultantDetail?.nav_items,
    recording_name: userInfo?.first_name,
    type: userInfo?.team_type,
  });
  let default_paths = [
    "/dashboard",
    "/zoom-setting",
    "/edit-profile",
    "/change-password",
    "/accountability-tracker-report",
  ];
  var foundObject = consultantDetail?.nav_items.find(function (element) {
    if (element.value == "welcome_reminder_settings") {
      default_paths.push("/welcome-reminder-setting");
    }
    return element.value == "welcome_reminder_settings";
  });
  const checkIfPathExists = (navItems, currentPath) => {
    for (const path of default_paths) {
      if (currentPath.includes(path)) {
        return true;
      }
    }
    for (const navItem of navItems) {
      if (navItem.path && currentPath.includes(navItem.path)) {
        return true;
      }
      if (navItem.matches && navItem.matches.includes(currentPath)) {
        return true;
      }
      if (navItem.child_options) {
        for (const childOption of navItem.child_options) {
          if (
            childOption.path &&
            (currentPath.includes(childOption.path) ||
              childOption.path.includes(currentPath))
          ) {
            return true;
          }
        }
      }
    }
    return false;
  };
  const handlePathname = () => {
    if (pathname) {
      const pathParts = pathname.split("/");
      if (pathParts.length > 2) {
        return pathParts.slice(0, 2).join("/");
      } else {
        return pathname;
      }
    }
  };

  useEffect(() => {
    const isPathInNavItems = checkIfPathExists(nav_items, handlePathname());
    if (!isPathInNavItems) {
      navigate("/dashboard");
    }
  }, [pathname]);
  // useEffect(() => {
  //   if (open) {
  //     setOpen(false);
  //   }
  // }, [pathname]);
  const renderContent = (
    <Scrollbar
      sx={{
        height: "100%",
        "& .simplebar-content": {
          height: "100%",
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Box sx={{ px: 2.5, py: 3 }}>
        <Box
          component={RouterLink}
          to="/"
          sx={{
            display: "inline-flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Logo consultantInfo={consultantInfo?.brand_logo} />
        </Box>
      </Box>
      <NavSectionSettings navConfig={SettingsSidebarConfig({ foundObject })} />

      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );
  return (
    <RootStyle>
      <MHidden width="lgUp">
        <IconButton
          className="menu-icon-settings"
          onClick={() => setOpen(!open)}
        >
          <SettingsIcon />
        </IconButton>
        <Drawer
          open={open}
          onClose={() => setOpen(false)}
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              height: "calc(100% - 70px)",
              top: 64,
            },
            className: "sidebar-drawer",
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: { width: DRAWER_WIDTH, height: "calc(100% - 70px)", top: 64 },
            className: "sidebar-drawer",
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}
