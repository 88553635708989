let sidebarConfig = () => {
  var menu_items = [
    {
      title: "Mission Control",
      value: "mission_control",
      path: "/dashboard",
      icon: "",
      _id: "867a0259-dafe-40c0-82e4-55bc1d4e548f",
      order: 1,
    },
    {
      title: "The Cosmos",
      value: "the_cosmos",
      path: "/thesource",
      icon: "",
      _id: "433f8aaf-59cf-46f1-a1d9-ac31d645946d",
      order: 2,
    },
    {
      title: "Chat",
      path: "/chat",
      value: "chat",
      access_by: "chat",
      _id: "34ac8703-da69-4282-9f62-f5295cbcac8d",
      icon: "",
      order: 3,
    },
    {
      title: "Whatsapp Chat",
      value: "whatsapp_chat",
      path: "/whatsapp-chat",
      access_by: "chat",
      icon: "",
      _id: "3fd672ca-06bf-4c59-b66a-44880c8effca",
      order: 4,
    },
    {
      _id: "d2ff9f8b-20a8-4646-a08b-cbdfd931fb54",
      is_open: true,
      title: "Members",
      value: "members",
      path: "",
      icon: "",
      is_expanded: true,
      order: 5,
      child_options: [
        {
          _id: "5f063d7b-640b-4088-a821-0b77a08b18c7",
          title: "Members",
          path: "/members",
          value: "members",
          icon: "",
          order: 1,
          is_page_accessible: {
            option_label: "Members",
            option_value: "members",
          },
        },
        {
          _id: "06cafbd4-cee7-4723-acc5-ce265d01a148",
          icon: "",
          title: "Nurture Members",
          value: "nurture_members",
          path: "/nurture-members",
          access_by: "nurture_members",
          order: 2,
        },
        {
          _id: "9daa2af3-a55d-40e4-9821-c3fe4199d5ab",
          icon: "",
          title: "All Member List",
          path: "/all-members",
          value: "all_member_list",
          access_by: "all_member_list",
          order: 3,
        },
      ],
    },
    {
      _id: "455ccc75-c8d3-41b3-856b-5a133a185c5b",
      icon: "",
      is_open: true,
      title: "Payments",
      value: "payments",
      is_expanded: false,
      order: 6,
      child_options: [
        {
          _id: "558ec2ba-49ca-4740-bb81-a0a29296d69d",
          icon: "",
          title: "Transactions",
          path: "/transaction-list",
          value: "transactions",
          order: 1,
        },
        {
          _id: "443a08f7-f6e0-4fa7-81fb-5236e6e970de",
          icon: "",
          title: "Commission Detail",
          path: "/commission-detail",
          value: "commission_detail",
          order: 2,
        },
        {
          _id: "84a645c5-7979-49bc-9ca6-a4214336ec77",
          icon: "",
          title: "Payment Requests",
          path: "/payment-request",
          value: "payment_request",
          order: 3,
        },
      ],
    },
    {
      _id: "0a3fc4be-fac3-46f2-a887-ae1e08f9d351",
      icon: "",
      title: "Templates",
      path: "/website-pages",
      value: "templates",
      order: 7,
    },
    {
      _id: "f6f13dcc-c828-4ee8-81e4-85b9a21f6308",
      icon: "",
      title: "Links",
      path: "/website-links",
      value: "links",
      order: 8,
    },
    {
      _id: "34e7b7db-b8c5-4d55-98fc-700d3c6cd357",
      icon: "",
      title: "The Source Feed",
      path: "/sourcefeeds",
      value: "the_source_feed",
      order: 9,
    },
    {
      _id: "cbd826ec-9082-44c4-a94c-4af0bf98eeb9",
      icon: "",
      title: "All Source Feed",
      path: "/all-sourcefeeds",
      value: "all_source_feed",
      order: 10,
    },
    {
      _id: "214ab401-1f91-45c6-9250-42df98cd20ee",
      icon: "",
      title: "Scheduled Feeds",
      path: "/scheduled-feeds",
      value: "scheduled_feeds",
      order: 11,
    },
    {
      _id: "c843ea03-df9e-4916-b59f-7357e79b90cb",
      title: "Member Answers List",
      path: "/member-answers-list",
      icon: "",
      value: "member_answers_list",
      order: 12,
    },
    {
      _id: "73e12b1a-5c26-4092-ac3c-571fd8738f67",
      icon: "",
      title: "Subscription List",
      path: "/subscription-list",
      value: "subscription_list",
      order: 13,
    },
    {
      _id: "50f33585-a162-43f2-9248-5ae844488772",
      icon: "",
      title: "Delegate Training",
      path: "delegate-training",
      matches: ["/lessons", "/delegate-training", "/lessons-recordings"],
      value: "delegate_training",
      order: 14,
    },
    {
      _id: "3bc7d829-9b1b-4cba-b657-39485b52f625",
      icon: "",
      title: "Appointments",
      path: "",
      is_expanded: false,
      value: "appointment",
      order: 15,
      child_options: [
        {
          _id: "68cf2ca6-010a-4e51-94ee-26231efe87e5",
          title: "Bookings",
          path: "/booking",
          value: "bookings",
          order: 1,
        },
        {
          _id: "6fbc5f59-7886-4d6d-b936-14636b4bdff9",
          title: "Appointments Configuration",
          path: "/appointment",
          value: "schedule_appointment",
          order: 2,
        },
      ],
    },
    {
      _id: "08c114e9-3ce1-40c7-808f-0ac556f3b93c",
      icon: "",
      title: "90 Day Plan",
      path: "",
      value: "90_day_plan",
      is_expanded: false,
      order: 16,
      child_options: [
        {
          _id: "9f051351-3edc-481c-ac23-85e09f4e2e11",
          icon: "",
          title: "90 Day Plan",
          value: "90_day_plan",
          path: "/90-day-plan",
          order: 1,
        },
        {
          _id: "d2a962e6-b079-4eb3-8f67-c467d85323e6",
          icon: "",
          title: "90 Day Tracker",
          value: "90_day_tracker",
          path: "/90-day-tracker",
          order: 2,
        },
      ],
    },
    {
      _id: "79fe1cf4-6cd7-42d0-a855-3fcf5fec045a",
      icon: "",
      title: "Calendar",
      value: "calendar",
      is_expanded: false,
      order: 17,
      child_options: [
        {
          _id: "62c5554e-72cb-4876-98b5-889ecb5e8db7",
          icon: "",
          title: "Groups",
          path: "/groups",
          value: "groups",
          order: 1,
        },
        {
          _id: "38bdd187-f583-4b46-bbb9-20bd784bf241",
          icon: "",
          title: "Calendar Events",
          path: "/calendar-events",
          value: "calendar_events",
          order: 2,
        },
      ],
    },
    {
      _id: "83b8897f-5ef3-434d-bc5e-76142371f5e7",
      icon: "",
      title: "Support Ticket",
      path: "/support-ticket",
      value: "support_ticket",
      order: 18,
    },
    {
      _id: "c47fa8a3-9f0d-469c-b78c-9f841c251a91",
      icon: "",
      title: "Internal Tickets",
      path: "/internal-tickets",
      value: "internal-tickets",
      order: 19,
    },
    {
      _id: "c4406822-391b-4320-892f-ceb760f157c0",
      icon: "",
      title: "Portals",
      path: "/live-events",
      matches: ["/live-events"],
      value: "portals",
      order: 20,
    },
    {
      _id: "2f8afe1f-58b9-4415-93a6-d2a65b3cfa5e",
      icon: "",
      title: "My Portals",
      path: "/my-events",
      is_expanded: false,
      value: "my_portals",
      order: 21,
      matches: [
        "/my-events",
        "/dynamite-event-categories",
        "/dynamite-event-videos",
        "/dynamite-events",
      ],
    },
    {
      _id: "17fc7008-54ea-4917-b74a-05256563398e",
      icon: "",
      title: "Delegate Pods",
      is_expanded: false,
      value: "delegate_pods",
      order: 22,
      child_options: [
        {
          _id: "ee600ffb-0afa-449b-9b76-99761c01ed3d",
          icon: "",
          title: "Source Pods",
          path: "/source-pods",
          value: "source_pods",
          order: 1,
        },
        {
          _id: "657bbdbb-661f-4ebb-8373-9c0c34485fb7",
          icon: "",
          title: "Book Call Pods",
          path: "/book-call-pods",
          value: "book_call_pods",
          order: 2,
        },
      ],
    },
    {
      _id: "14f2dd46-0399-43d7-b17d-cae069a6244a",
      icon: "",
      title: "Dynamite Pods",
      path: "/dynamite-pods",
      value: "dynamite_pods",
      order: 23,
    },
    {
      icon: "",
      title: "Delegate Events",
      path: "/delegate-events",
      value: "delegate_events",
      order: 24,
    },
    {
      _id: "25a25c8b-b9c0-4f7d-b77d-074c97c55195",
      icon: "",
      title: "Your Recordings",
      path: "/recordings",
      value: "your_recordings",
      order: 25,
    },
    {
      _id: "bc7d4e01-05aa-49a6-9bb6-09b90e44908d",
      icon: "",
      title: "Your Vault",
      path: "vault",
      value: "your_vault",
      order: 26,
    },
    {
      _id: "d855c0fa-6dd4-45f9-9fc9-f1bed80e2192",
      icon: "",
      title: "Attitude Assessment",
      path: "/assessment",
      value: "attitude_assessment",
      order: 27,
    },
    {
      _id: "93a6e107-c620-4ba4-81d9-64660b4fb4db",
      icon: "",
      title: "Progress",
      path: "/progress",
      value: "progress",
      order: 28,
    },
    {
      _id: "9ebb9563-c76c-42d0-b2e5-de7a88bbf3c9",
      icon: "",
      title: "Certification",
      is_expanded: false,
      value: "certification",
      order: 29,
      child_options: [
        {
          _id: "3bbea0ca-7d66-4225-9950-9ecec741637d",
          title: "Assessments / Study",
          path: "delegate-question",
          value: "assessment_study",
          order: 1,
        },
      ],
    },
    {
      _id: "75176cef-cff2-4741-8139-67ce23bdca6c",
      icon: "",
      title: "Member Goal Statement",
      value: "member_goal_statement",
      is_expanded: false,
      order: 30,
      child_options: [
        {
          _id: "f18f9630-2dc1-4e42-a498-0a0e7e9407be",
          icon: "",
          title: "Complete",
          path: "/member-completed-goalstatements",
          value: "complete",
          order: 1,
        },
        {
          _id: "6777364d-4014-47d4-abd2-f488fa1c58bc",
          icon: "",
          title: "Incomplete",
          path: "/member-incomplete-goalstatements/",
          value: "incomplete",
          order: 2,
        },
        {
          _id: "46eb45b0-1e4a-4903-b3a2-1f0823d990d7",
          icon: "",
          title: "Responded",
          path: "/member-responded-goalstatements",
          value: "responded",
          order: 3,
        },
      ],
    },
    {
      _id: "c31a3cf2-ff7a-4957-9546-9c104f68d96c",
      icon: "",
      is_expanded: false,
      title: "Self Image",
      value: "self_image",
      order: 31,
      child_options: [
        {
          _id: "1dbc99eb-1a68-40dc-8d60-102c23a026c9",
          icon: "",
          title: "Completed",
          path: "/self-image-completed",
          value: "completed",
          order: 1,
        },
        {
          _id: "45118873-02a7-4bc7-a181-3d501f7ad677",
          icon: "",
          title: "Incompleted",
          path: "/self-image-incompleted",
          value: "incompleted",
          order: 2,
        },
        {
          _id: "7ad62d39-8cea-4b72-8394-d270a12fe850",
          icon: "",
          title: "Responded",
          path: "/self-image-responded",
          value: "responded",
          order: 3,
        },
      ],
    },
    {
      _id: "c351eb22-6d51-4187-9111-f05872a6d4b8",
      icon: "",
      title: "Help",
      path: "",
      is_expanded: false,
      value: "support",
      order: 32,
      child_options: [
        {
          _id: "26ddb8b0-31d9-4154-b3a9-81619fd1b49c",
          icon: "",
          title: "Contact Support",
          path: "/contact-support",
          value: "contact_support",
          order: 1,
        },
        {
          _id: "96eadc59-d4a6-44f3-b99e-14a87136de90",
          icon: "",
          title: "Digital Assets",
          path: "/digital-asset",
          value: "digital_assets",
          order: 2,
        },
        {
          _id: "50dc0ed7-7b87-4955-b1ad-13cb8a2fd52b",
          icon: "",
          title: "Help Tech",
          path: "/tutorials",
          value: "help_tech",
          order: 3,
        },
      ],
    },
    {
      _id: "aadbd7a3-91c1-40dd-bf24-532fc288d265",
      icon: "",
      title: "Daily Dynamite Accountabalility Tracker",
      path: "/tracker",
      value: "daily_dynamite_accountabalility_tracker",
      order: 33,
    },
    {
      _id: "aadbd7a3-91c1-40dd-bf24-532fc288d265",
      icon: "",
      title: "Daily Streak Performance",
      path: "/tracker",
      value: "daily_streak_performance",
      order: 34,
    },
    {
      _id: "aadbd7a3-91c1-40dd-bf24-532fc288d265555",
      icon: "",
      title: "Accountability Tracker Report",
      path: "/accountability-tracker-report",
      value: "daily_streak_performance",
      order: 35,
    },
  ];

  return menu_items;
};

export default sidebarConfig;
