import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CustomDrawer from "src/components/DrawerForm/CustomDrawer";
import TicketList from "src/components/SupportTickets/TicketList";
import AddSupportTicket from "./AddSupportTicket";
import SupportTicketFilter from "./SupportTicketFilter";
import roundFilterList from "@iconify/icons-ic/round-filter-list";
// import { tickestsListAPI } from "src/DAL/SupportTicket/SupportTicket";
import { useSnackbar } from "notistack";
import { CircularProgress, Pagination } from "@mui/material";
import { makeStyles } from "@mui/styles";
// import SearchBar from "material-ui-search-bar";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import CircleIcon from "@mui/icons-material/Circle";
import Paper from "@mui/material/Paper";
import { get_root_value } from "src/utils/domUtils";
import {
  get_list_support_ticket_with_pagination,
  get_list_support_ticket_with_pagination_new,
  markResolved,
  ticketsListAPI,
  _move_need_attention,
  _move_need_fixes,
  _move_reminder,
  _revert_back,
  _change_department_api,
} from "src/DAL/SupportTicket/SupportTicket";
import { useContentSetting } from "src/Hooks/ContentSettingState";
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
} from "@mui/material";
import { UserListHead, UserListToolbar } from "src/components/_dashboard/user";
import SearchNotFound from "src/components/SearchNotFound";
import { htmlDecode } from "src/utils/convertHtml";
import moment from "moment";
import Label from "src/components/Label";
import CustomPopover from "src/components/CustomPopover";
import CustomConfirmation from "src/components/menuIcons/CustomConfirmation";
import { filter } from "lodash";
import UserListToolbars from "src/components/_dashboard/user/UserListToolbars";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddOrUpdateTicketSection from "./AddOrUpdateTicketSection";
import SendReminder from "./SendReminder";
import MarkResolve from "./MarkResolve";
import CloseNote from "./CloseNote";
import InternalNote from "./InternalNote";
import { get_date_with_user_time_zone } from "src/utils/constants";
import ChangeStatusPopUp from "src/components/SupportTickets/ChangeStatusPopUp";
import CustomConfirmationdata from "src/components/menuIcons/CustomConfirmationdata";
import CustomConfirmationNeedFixesPopup from "./CustomConfirmationNeedFixesPopup";
import InternalNoteNew from "./InternalNoteNew";
import FullPagePopupSupportTicket from "../InternalTicket/FullPagePopupSupportTicket";

const TABLE_HEAD_REMINDER = [
  { id: "number", label: "#", alignRight: false },
  { id: "name", label: "User Name", alignRight: false },
  { id: "subject", label: " Subject", alignRight: false },
  { id: "dep", label: " Department", alignRight: false },
  { id: "created", label: "Created", alignRight: false },
  {
    id: "last_activity_date_time",
    label: "Responded",
    alignRight: false,
  },
  { id: "created", label: "Reminder Date", alignRight: false },
  { id: "created", label: "Reminder Status", alignRight: false },
  { id: "action", label: "Action", alignRight: false },
];
const TABLE_HEAD_Ready_to_close = [
  { id: "number", label: "#", alignRight: false },
  { id: "name", label: "User Name", alignRight: false },
  { id: "subject", label: " Subject", alignRight: false },
  { id: "dep", label: " Department", alignRight: false },
  { id: "created", label: "Created", alignRight: false },
  {
    id: "last_activity_date_time",
    label: "Responded",
    alignRight: false,
  },
  { id: "created", label: "Reminder Date", alignRight: false },
  { id: "action", label: "Action", alignRight: false },
];
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

function SupportTicketsTabs({
  tabValue,
  valueData,
  setWaiting,
  setAnswered,
  setClose,
  setTrash,
  setfixesCount,
  setAttention,
  setReminder,
  setReadyToCLose,
  reminderTab,
  setneedFixCount,
  CloseTab,
  readyToCloseTab,
  fixDate,
  setfixDatealert,
}) {
  const transactions = [
    {
      created: "2 mins ago",
      name: "Logan",
      currency: "usd",
      amount: 100,
      transaction_status: "Open",
      subject: "Lorem Ipsum is",
    },
    {
      created: "3 mins ago",
      name: "Jhon Danille",
      currency: "usd",
      subject:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500",
      transaction_status: "solved",
    },
    {
      created: "4 mins ago",
      name: "Henson wick",
      currency: "usd",
      subject:
        "Lorem Ipsum has been the industrys standard dummy text ever since the 1500",
      transaction_status: "waiting",
    },
  ];

  const navigate = useNavigate();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();
  const [listData, setListData] = useState(transactions);
  const [drawerState, setDrawerState] = useState(false);
  const [drawerMarkResolveState, setdrawerMarkResolveState] = useState(false);
  const [drawerCloseNote, setdrawerCloseNote] = useState(false);
  const [drawerInternalNote, setdrawerInternalNote] = useState(false);
  const [editDrawerState, setEditDrawerState] = useState(false);
  const [tickestData, setTickestData] = useState([]);
  const [filterBy, setFilterBy] = useState("all");
  const [selectedValue, setSelectedValue] = React.useState("all");
  const [isLoading, setIsLoading] = useState(false);
  const [searchItem, setSearchItem] = useState("");
  const [pageSize, setPageSize] = React.useState(10);
  const [ReminderMessage, setReminderMessage] = useState();
  const [page, setPage] = React.useState(0);
  const [totalRecords, setTotalRecords] = React.useState();
  const [ticketData, setticketData] = useState();
  const [selected, setSelected] = useState([]);
  const [filterName, setFilterName] = useState("");
  const [order, setOrder] = useState("asc");
  const [totalPages, setTotalPages] = useState(0);
  const [orderBy, setOrderBy] = useState("number");
  const [userList, setUserList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [ticketId, setTicketId] = useState("");
  const [memberCounts, setmemberCounts] = useState("");
  const [openResolved, setOpenResolved] = useState(false);
  const [openNeedFixes, setopenNeedFixes] = useState(false);
  const [openNeedAttention, setopenNeedAttention] = useState(false);
  const [openReminder, setopenReminder] = useState(false);
  const [revert, setRevert] = useState(false);
  const [MoveStatus, setMoveStatus] = useState("");
  const [memberId, setMemberId] = useState("");
  const [pageCount, setPageCount] = useState(1);
  const [openChangeStatus, setopenChangeStatus] = useState(false);
  const [openSendNeedFixes, setopenSendNeedFixes] = useState(false);
  const [ChangeStatusValue, setChangeStatusValue] = useState("");
  const [ticket, setticket] = useState("");
  const settings = useContentSetting();
  const { socket } = settings;
  const { state } = useLocation();
  const [inputs, setInputs] = useState({
    departments: "",
  });
  const [endDate, setendDate] = useState(new Date());
  const is_trash_path = window.location.pathname.includes(
    "trash-support-ticket"
  );
  const path = window.location.pathname;
  const { userInfo, adminTimeZone } = useContentSetting();
  const [filterNameStatus, setFilterNameStatus] = useState(false);
  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
    localStorage.setItem("member_page_number", newPage);
  };
  const getTickestsListing = async (value, searchText) => {
    setIsLoading(true);
    try {
      const formData = new FormData();
      if (is_trash_path) {
        formData.append("filter_by", "trash");
      } else {
        if (value == undefined || value == "") {
          formData.append("filter_by", "all");
          if (memberId) {
            formData.append("member_id", memberId);
          }
        } else {
          formData.append("filter_by", value);
        }
      }

      const result = await get_list_support_ticket_with_pagination_new(
        valueData,
        page,
        rowsPerPage,
        searchText ? searchText : filterName
      );
      if (result.code === 200) {
        setmemberCounts(result.total_count);
        setWaiting(result?.waiting_ticket_count);
        setReadyToCLose(result?.ready_to_close_count);
        setAnswered(result.answered_ticket_count);
        setClose(result.solved_ticket_count);
        setTrash(result?.trash_ticket_count);
        setneedFixCount(result?.need_to_fix_reminder_count);
        setfixesCount(result?.need_fixes_count);
        setAttention(result?.need_to_attention_count);
        setReminder(result?.reminder_ticket_count);
        const data = result.support_ticket.map((ticket, index) => {
          // var moment = require("moment-timezone");
          // var a = moment.tz(ticket.createdAt, defaultTimeZone);
          let time = moment
            .utc(ticket?.resolve_date)
            .format("YYYY-MM-DD hh:mm");
          let timeCreated = moment
            .utc(ticket.support_ticket_date)
            .format("YYYY-MM-DD HH:mm");
          let actionTime = ticket.last_action_date
            ? moment.utc(ticket.last_action_date).format("YYYY-MM-DD HH:mm")
            : "N/A";

          let reminder_time =
            ticket.reminder_date_time !== null
              ? moment.utc(ticket.reminder_date_time).format("YYYY-MM-DD hh:mm")
              : "empty";
          let issue_fix_date =
            ticket.issue_fix_date !== null
              ? moment.utc(ticket.issue_fix_date).format("YYYY-MM-DD")
              : "empty";
          const today = moment();
          const targetDate = moment(issue_fix_date, "YYYY-MM-DD");
          const diffInDays = targetDate.diff(today, "days");

          if (diffInDays < 2) {
            setfixDatealert(true);
          }

          return {
            id: ticket._id,

            last_activity_date_time:
              actionTime == "N/A"
                ? "N/A"
                : get_date_with_user_time_zone(
                    actionTime,
                    "YYYY-MM-DD HH:mm",
                    userInfo.time_zone,
                    adminTimeZone
                  ),
            createdAt: get_date_with_user_time_zone(
              timeCreated,
              "YYYY-MM-DD HH:mm",
              userInfo.time_zone,
              adminTimeZone
            ),
            name: ticket?.subject,
            userName: ticket?.member,
            status: ticket?.status,
            department: ticket?.department,
            resolve_date: time,
            response_status: ticket?.response_status,
            ticket_status: ticket?.ticket_status,

            reminder_status: ticket.is_reminder,

            reminder_date: reminder_time,
            issue_fix_date_time: issue_fix_date,
            object: ticket,
            diffInDays,
            count: index + 1 + rowsPerPage * page,
          };
        });
        setTotalCount(result?.total_count);
        setTotalPages(result?.total_pages);
        setUserList(data);
        setIsLoading(false);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
      }
    } catch (error) {}
  };
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const myfunction = (value) => {
    //console.log(value, "value function");
    navigate(`/support-ticket/${value._id}`, {
      state: value,
    });
  };
  const handleRequestSort = (event, property) => {
    // const isAsc = orderBy === property && order === "asc";
    // setOrder(isAsc ? "desc" : "asc");
    // setOrderBy(property);
  };
  const filteredUsers = applySortFilter(
    userList,
    getComparator(order, orderBy)
    // filterName
  );
  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  const Capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    if (query) {
      return filter(
        array,
        (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
      );
    }
    return stabilizedThis.map((el) => el[0]);
  }
  const handleSearchName = () => {
    localStorage.setItem(valueData + "search", filterName);
    getTickestsListing();
    setFilterNameStatus(true);
    setPage(0);
    setPageCount(1);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpenEditDrawer = () => {
    setEditDrawerState(true);
  };

  const handleCloseDrawer = () => {
    setDrawerState(false);
  };
  const handleCloseEditDrawer = () => {
    setEditDrawerState(false);
  };
  const handleSearch = (e) => {
    e.preventDefault();
    //console.log("search");
  };
  //Getting Note In textarea
  const handleEdit = (value) => {
    setEditValues(value);
    setEditDrawerState(true);
  };
  const handleOpenDrawer = (value) => {
    setDrawerState(true);
    setTicketId(value.id);
    setReminderMessage(value);
  };
  const handleOpenMarkResolveDrawer = (value) => {
    setdrawerMarkResolveState(true);
    setTicketId(value.id);
  };
  const handleCLoseNoteDrawer = (value) => {
    setdrawerCloseNote(true);
    setTicketId(value);
  };
  const handleOpenInternalNoteDrawer = (value) => {
    setdrawerInternalNote(true);
    setTicketId(value.id);
    setticketData(value);
  };
  const handleCloseMarkResolveDrawer = () => {
    setdrawerMarkResolveState(false);
  };

  const handleCLoseNoteCLoseDrawer = () => {
    setdrawerCloseNote(false);
  };
  const handleCLoseInternalNoteCLoseDrawer = () => {
    setdrawerInternalNote(false);
  };
  const handleAgreeDelete = (value) => {
    setMemorySlug(value.pinterestSlug);
    setOpenDelete(true);
  };
  const isUserNotFound = filteredUsers.length === 0;
  const hanleChangePage = (value) => {
    navigate(`/support-ticket/${value.id}`, {
      state: { value, tabValue },
    });
  };
  const handleMarkResolvedPopup = (value) => {
    setTicketId(value.id);
    setOpenResolved(true);
  };
  const handleRevertPopUp = (value) => {
    setTicketId(value.id);
    setRevert(true);
  };

  const handleMoveNeedFixes = (value) => {
    setTicketId(value.id);
    setopenNeedFixes(true);
  };
  const handleMoveNeedAttentionPopUp = (value) => {
    setTicketId(value.id);
    setopenNeedAttention(true);
  };
  const handleMoveReminder = (value) => {
    setTicketId(value.id);
    setopenReminder(true);
    setMoveStatus("reminder");
  };
  const handleMoveReadyToClose = (value) => {
    setTicketId(value.id);
    setopenReminder(true);
    setMoveStatus("ready_to_close");
  };
  const handleMoveFixed = (value) => {
    setTicketId(value.id);
    setopenReminder(true);
    setMoveStatus("fixed");
  };
  const handleMoveAttened = (value) => {
    setTicketId(value.id);
    setopenReminder(true);
    setMoveStatus("attended");
  };

  const handleNavigate = () => {
    setMemberId("");
    navigate("/support-ticket", { state: tabValue });
  };
  const handleChangeAllTicketList = (value) => {
    setMemberId(value.id);

    navigate(`/support-ticket/member-tickets/${value.id}`);
  };

  const handleMoveNeedAttention = async () => {
    setopenNeedAttention(false);
    setIsLoading(true);
    const result = await _move_need_attention(ticketId);
    if (result.code === 200) {
      getTickestsListing("all");
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
      socket.emit("send_add_support_ticket_comment", result);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handleRevert = async () => {
    setRevert(false);
    setIsLoading(true);
    const result = await _revert_back(ticketId);
    if (result.code === 200) {
      getTickestsListing("all");
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handleReminder = async () => {
    setIsLoading(true);
    setopenReminder(false);
    const postData = {
      status_to_move: MoveStatus,
      support_ticket: ticketId,
    };
    const result = await _move_reminder(postData);
    if (result.code === 200) {
      getTickestsListing("all");
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handleMarkResolved = async () => {
    setOpenResolved(false);
    setIsLoading(true);
    const result = await markResolved(ticketId);
    if (result.code === 200) {
      getTickestsListing("all");
      enqueueSnackbar(result.message, { variant: "success" });

      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      navigate(`/support-ticket`);
    }
  };
  // change derpartment popup functions start
  const handleChangeStatus = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const handleChangeNeedFixes = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInput((values) => ({ ...values, [name]: value }));
  };
  const handleChangeDates = (newValue) => {
    setendDate(newValue.$d);
  };

  const handlechangeStatusApi = async () => {
    setopenChangeStatus(false);
    setIsLoading(true);
    const postData = {
      department: inputs.departments,
    };
    const result = await _change_department_api(ticket, postData);
    if (result.code === 200) {
      setIsLoading(false);
      getTickestsListing();
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
      setopenChangeStatus(true);
    }
  };
  const handlechangeNeedFixesApi = async () => {
    setopenSendNeedFixes(false);
    setIsLoading(true);
    const fixdate = moment(endDate).format("YYYY-MM-DD");

    const postData = {
      support_ticket: ticketId,
      status_to_move: "fixes",
      issue_fix_date: fixdate,
    };
    const result = await _move_need_fixes(postData);
    if (result.code === 200) {
      setIsLoading(false);
      getTickestsListing();
      enqueueSnackbar(result.message, { variant: "success" });
      socket.emit("send_add_support_ticket_comment", result);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
      setopenSendNeedFixes(true);
    }
  };

  // change derpartment popup functions end
  const handleMovedNeedFixes = async () => {
    setopenNeedFixes(false);
    setIsLoading(true);
    const result = await _move_need_fixes(ticketId);
    if (result.code === 200) {
      getTickestsListing();
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
      socket.emit("send_add_support_ticket_comment", result);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handlechangeAffiliateID = (value) => {
    setticket(value.id);
    setChangeStatusValue(value);
    setInputs((inputs) => ({
      ...inputs,
      ["departments"]: value?.department?._id ? value?.department?._id : "",
    }));
    setopenChangeStatus(true);
  };
  const handleChangeNeedFixed = (value) => {
    setTicketId(value.id);
    setopenSendNeedFixes(true);
  };
  //Adding Menu options for action
  const MENU_OPTIONS = [
    {
      label: "Detail",
      icon: "gg:details-more",
      handleClick: hanleChangePage,
    },

    {
      label: "Internal Notes",
      icon: "gg:details-more",
      handleClick: handleOpenInternalNoteDrawer,
    },
    {
      label: "Mark Resolve",
      icon: "emojione-monotone:heavy-check-mark",
      handleClick: handleMarkResolvedPopup,
    },
  ];
  const MENU_OPTIONS_ANSWERED = [
    {
      label: "Detail",
      icon: "gg:details-more",
      handleClick: hanleChangePage,
    },

    {
      label: "Internal Notes",
      icon: "gg:details-more",
      handleClick: handleOpenInternalNoteDrawer,
    },
    {
      label: "Mark Resolve",
      icon: "emojione-monotone:heavy-check-mark",
      handleClick: handleOpenMarkResolveDrawer,
    },
    {
      label: "Change Department",
      icon: "fe:loop",
      handleClick: handlechangeAffiliateID,
    },
    {
      label: "Move To Needs Fixes",
      icon: "fe:loop",
      handleClick: handleChangeNeedFixed,
    },
    {
      label: "Move To Needs Attention",
      icon: "fe:loop",
      handleClick: handleMoveNeedAttentionPopUp,
    },
  ];
  handleOpenDrawer;
  const MENU_OPTIONS_Revert = [
    {
      label: "Detail",
      icon: "gg:details-more",
      handleClick: hanleChangePage,
    },
    {
      label: "Internal Notes",
      icon: "gg:details-more",
      handleClick: handleOpenInternalNoteDrawer,
    },
    // {
    //   label: "Mark Resolve",
    //   icon: "emojione-monotone:heavy-check-mark",
    //   handleClick: handleOpenMarkResolveDrawer,
    // },
    // {
    //   label: "Move  To Waiting",
    //   icon: "fe:loop",
    //   handleClick: handleRevertPopUp,
    // },
    {
      label: "Attended",
      icon: "fe:loop",
      handleClick: handleMoveAttened,
    },
    {
      label: "Change Department",
      icon: "fe:loop",
      handleClick: handlechangeAffiliateID,
    },
  ];
  const MENU_OPTIONS_Need_fixes = [
    {
      label: "Detail",
      icon: "gg:details-more",
      handleClick: hanleChangePage,
    },
    {
      label: "Internal Notes",
      icon: "gg:details-more",
      handleClick: handleOpenInternalNoteDrawer,
    },

    {
      label: "Fixed",
      icon: "fe:loop",
      handleClick: handleMoveFixed,
    },
    {
      label: "Change Department",
      icon: "fe:loop",
      handleClick: handlechangeAffiliateID,
    },
  ];
  const MENU_OPTIONS_REMINDER = [
    {
      label: "Detail",
      icon: "gg:details-more",
      handleClick: hanleChangePage,
    },
    {
      label: "Internal Notes",
      icon: "gg:details-more",
      handleClick: handleOpenInternalNoteDrawer,
    },
    // {
    //   label: "Mark Resolve",
    //   icon: "emojione-monotone:heavy-check-mark",
    //   handleClick: handleOpenMarkResolveDrawer,
    // },
    {
      label: "Send Reminder",
      icon: "carbon:send-alt",
      handleClick: handleOpenDrawer,
    },
  ];
  const MENU_OPTIONS_ReadyToClose = [
    {
      label: "Detail",
      icon: "gg:details-more",
      handleClick: hanleChangePage,
    },

    {
      label: "Internal Notes",
      icon: "gg:details-more",
      handleClick: handleOpenInternalNoteDrawer,
    },
    {
      label: "Mark Resolve",
      icon: "emojione-monotone:heavy-check-mark",
      handleClick: handleOpenMarkResolveDrawer,
    },
  ];
  const MENU_OPTIONS2 = [
    {
      label: "Detail",
      icon: "gg:details-more",
      handleClick: hanleChangePage,
    },
    // {
    //   label: "All Tickets",
    //   icon: "gg:details-more",
    //   handleClick: handleChangeAllTicketList,
    // },

    {
      label: "Internal Notes",
      icon: "gg:details-more",
      handleClick: handleOpenInternalNoteDrawer,
    },
    {
      label: "Mark Resolve",
      icon: "emojione-monotone:heavy-check-mark",
      handleClick: handleOpenMarkResolveDrawer,
    },
    {
      label: "Change Department",
      icon: "fe:loop",
      handleClick: handlechangeAffiliateID,
    },
    {
      label: "Move To Needs Fixes",
      icon: "fe:loop",
      handleClick: handleChangeNeedFixed,
    },
    {
      label: "Move To Needs Attention",
      icon: "fe:loop",
      handleClick: handleMoveNeedAttentionPopUp,
    },
    // valueData == "waiting" && {
    //   label: "Move To Reminder ",
    //   icon: "fe:loop",
    //   handleClick: handleMoveReminder,
    // },
  ];
  const MENU_OPTIONS1 = [
    {
      label: "Detail",
      icon: "gg:details-more",
      handleClick: hanleChangePage,
    },

    // {
    //   label: "All Tickets",
    //   icon: "gg:details-more",
    //   handleClick: handleChangeAllTicketList,
    // },
    {
      label: "Note",
      icon: "gg:details-more",
      handleClick: handleCLoseNoteDrawer,
    },

    // {
    //   label: "Delete",
    //   icon: "ant-design:delete-twotone",
    //   handleClick: handleAgreeDelete,
    // },
  ];
  const MENU_OPTIONS_TRASH = [
    {
      label: "Detail",
      icon: "gg:details-more",
      handleClick: hanleChangePage,
    },
  ];
  const menuHandling = (item) => {
    let menu_options = MENU_OPTIONS;
    if (item.ticket_status === 1) {
      menu_options = MENU_OPTIONS1;
    } else if (item.ticket_status === 2) {
      menu_options = MENU_OPTIONS_TRASH;
    } else if (valueData == "waiting") {
      menu_options = MENU_OPTIONS2;
    } else if (valueData == "needs_to_attention") {
      menu_options = MENU_OPTIONS_Revert;
    } else if (valueData == "need_fixes") {
      menu_options = MENU_OPTIONS_Need_fixes;
    } else if (valueData == "reminder") {
      menu_options = MENU_OPTIONS_REMINDER;
    } else if (valueData == "ready_to_close") {
      menu_options = MENU_OPTIONS_ReadyToClose;
    } else if (valueData == "answered") {
      menu_options = MENU_OPTIONS_ANSWERED;
    }
    return menu_options;
  };
  const TABLE_HEAD = [
    { id: "number", label: "#", alignRight: false },
    { id: "name", label: "User Name", alignRight: false },
    { id: "subject", label: " Subject", alignRight: false },
    { id: "dep", label: " Department", alignRight: false },
    { id: "created", label: "Created", alignRight: false },
    {
      id: "last_activity_date_time",
      label: "Responded",
      alignRight: false,
    },
    // { id: "status", label: "Status", alignRight: false },
    { id: "action", label: "Action", alignRight: false },
  ];
  const resolve_date = {
    id: "created",
    label: "Resolved Date",
    alignRight: false,
  };
  const target_date = {
    id: "target_date",
    label: "Target Date",
    alignRight: false,
  };
  if (CloseTab) {
    TABLE_HEAD.splice(6, 0, resolve_date);
  }
  if (fixDate) {
    TABLE_HEAD.splice(6, 0, target_date);
  }

  // moment.tz.setDefault(userInfo.time_zone);

  useEffect(() => {
    let searchText = localStorage.getItem(valueData + "search");
    if (searchText) {
      setFilterName(searchText);
    }
    getTickestsListing(is_trash_path ? "trash" : "", searchText);
  }, [is_trash_path, rowsPerPage, page, path]);
  useEffect(() => {
    if (!filterNameStatus) {
      return;
    }
    // getMemberListing();
  }, []);
  useEffect(() => {
    moment.tz.setDefault(userInfo.time_zone);
  }, [localStorage]);
  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  moment.tz.setDefault(userInfo.time_zone);
  return (
    <div className="container mt-4">
      <div className="row mobile-margin display-flex">
        {params.member_id ? (
          <div className="col-12">
            <IconButton
              className="back-screen-button mb-4"
              onClick={handleNavigate}
            >
              <ArrowBackIcon />
            </IconButton>
          </div>
        ) : (
          ""
        )}
        <Card style={{ overflowX: "auto" }}>
          <div className="row">
            <div className="col-sm-12 col-md-6 d-flex flex-column align-items-start mt-1">
              <TablePagination
                rowsPerPageOptions={[5, 10, 20, 50]}
                component="div"
                count={totalCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
              <Pagination
                count={totalPages}
                page={pageCount}
                defaultPage={0}
                onChange={handleChangePages}
                className="pagination-style"
              />
            </div>
            <div className="col-sm-12 col-md-6 ">
              <UserListToolbars
                numSelected={selected.length}
                filterName={filterName}
                onFilterName={handleFilterByName}
                onSearch={handleSearchName}
              />
            </div>
          </div>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <UserListHead
                order={order}
                orderBy={orderBy}
                headLabel={
                  reminderTab
                    ? TABLE_HEAD_REMINDER
                    : readyToCloseTab
                    ? TABLE_HEAD_Ready_to_close
                    : TABLE_HEAD
                }
                rowCount={userList.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                // onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {filteredUsers
                  // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, i) => {
                    const {
                      id,
                      name,
                      department,
                      createdAt,
                      userName,
                      count,
                      last_activity_date_time,
                      reminder_status,
                      reminder_date,
                      resolve_date,
                      issue_fix_date_time,
                      diffInDays,
                    } = row;
                    const isItemSelected = selected.indexOf(name) !== -1;

                    return (
                      <TableRow
                        hover
                        key={id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        <TableCell align="left">{count}</TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          onClick={() => hanleChangePage(row)}
                          className="modalIcon"
                        >
                          {userName?.first_name
                            ? userName.first_name + " " + userName.last_name
                            : "N/A"}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          onClick={() => hanleChangePage(row)}
                          className="modalIcon"
                        >
                          {name}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {department?.title
                            ? Capitalize(htmlDecode(department?.title))
                            : "N/A"}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {moment(createdAt, "YYYY-MM-DD HH:mm").fromNow()}
                        </TableCell>
                        <TableCell align="left">
                          {last_activity_date_time == "N/A"
                            ? "N/A"
                            : moment(
                                last_activity_date_time,
                                "YYYY-MM-DD HH:mm"
                              ).fromNow()}
                        </TableCell>
                        {reminderTab && (
                          <TableCell
                            component="th"
                            scope="row"
                            style={{ width: "200px" }}
                          >
                            {reminder_status === true ? (
                              <>
                                {reminder_date != "empty"
                                  ? get_date_with_user_time_zone(
                                      reminder_date,
                                      "YYYY-MM-DD hh:mm A",
                                      userInfo.time_zone,
                                      adminTimeZone
                                    )
                                  : "N/A"}
                              </>
                            ) : (
                              "N/A"
                            )}
                          </TableCell>
                        )}

                        {readyToCloseTab && (
                          <TableCell
                            component="th"
                            scope="row"
                            style={{ width: "200px" }}
                          >
                            {reminder_status === true ? (
                              <>
                                {reminder_date != "empty"
                                  ? get_date_with_user_time_zone(
                                      reminder_date,
                                      "YYYY-MM-DD hh:mm A",
                                      userInfo.time_zone,
                                      adminTimeZone
                                    )
                                  : "N/A"}
                              </>
                            ) : (
                              "N/A"
                            )}
                          </TableCell>
                        )}
                        {CloseTab && (
                          <TableCell
                            component="th"
                            scope="row"
                            style={{ width: "120px" }}
                          >
                            {resolve_date != null
                              ? get_date_with_user_time_zone(
                                  resolve_date,
                                  "YYYY-MM-DD hh:mm A",
                                  userInfo.time_zone,
                                  adminTimeZone
                                )
                              : "N/A"}
                          </TableCell>
                        )}
                        {fixDate && (
                          <TableCell
                            component="th"
                            scope="row"
                            style={{ width: "150px" }}
                          >
                            {issue_fix_date_time != null
                              ? get_date_with_user_time_zone(
                                  issue_fix_date_time,
                                  "YYYY-MM-DD",
                                  userInfo.time_zone,
                                  adminTimeZone
                                )
                              : "N/A"}
                            {diffInDays < 2 ? (
                              <CircleIcon
                                className="support_notification_sidebar_page"
                                style={{ color: "red" }}
                              />
                            ) : (
                              <CircleIcon className="support_notification_sidebar_page_alert" />
                            )}
                          </TableCell>
                        )}

                        {reminderTab && (
                          <TableCell component="th" scope="row">
                            <Label
                              variant="ghost"
                              color={
                                reminder_status === false ? "error" : "success"
                              }
                            >
                              {reminder_status === false ? "Pending" : "Sent"}
                            </Label>
                            {reminder_status}
                          </TableCell>
                        )}

                        {/* 
                        <TableCell align="left">
                          <Label
                            variant="ghost"
                            // color={status === false ? "error" : "success"}
                            className={
                              ticket_status === 0 && response_status == 1
                                ? "answer-ticket"
                                : ticket_status == 1
                                ? "solved-ticket"
                                : ticket_status == 2
                                ? "trash-ticket"
                                : "pending-ticket"
                            }
                          >
                            {ticket_status === 0 && response_status == 1
                              ? "Answer"
                              : ticket_status == 1
                              ? " Solved"
                              : ticket_status == 2
                              ? "Trash"
                              : "waiting"}
                          </Label>
                        </TableCell> */}

                        <TableCell>
                          {
                            <CustomPopover
                              menu={menuHandling(row)}
                              data={row}
                            />
                          }
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {/* {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )} */}
              </TableBody>
              {isUserNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={9} sx={{ py: 3 }}>
                      <SearchNotFound searchQuery={filterName} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[5, 10, 20, 50]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <Pagination
            count={totalPages}
            page={pageCount}
            defaultPage={0}
            onChange={handleChangePages}
            className="pagination-style"
          />
        </Card>
      </div>
      <CustomConfirmation
        open={openResolved}
        setOpen={setOpenResolved}
        title={"Are you sure you want to mark this ticket as resolved?"}
        handleAgree={handleMarkResolved}
      />
      <CustomConfirmation
        open={openNeedFixes}
        setOpen={setopenNeedFixes}
        title={"Are you sure you want to move this ticket to needs fixes?"}
        handleAgree={handleMovedNeedFixes}
      />
      <CustomConfirmation
        open={openNeedAttention}
        setOpen={setopenNeedAttention}
        title={"Are you sure you want to move this ticket to need attention?"}
        handleAgree={handleMoveNeedAttention}
      />
      <CustomConfirmation
        open={revert}
        setOpen={setRevert}
        title={"Are you sure you want to move this ticket to waiting?"}
        handleAgree={handleRevert}
      />
      <CustomConfirmation
        open={openReminder}
        setOpen={setopenReminder}
        title={
          MoveStatus == "ready_to_close"
            ? "Are you sure you want to move this ticket to ready to close?"
            : MoveStatus == "fixed"
            ? "Are you sure you want to move this ticket to fixed?"
            : MoveStatus == "attended"
            ? "Are you sure you want to move this ticket to attended?"
            : "Are you sure you want to move this ticket to Reminder?"
        }
        handleAgree={handleReminder}
      />

      <CustomDrawer
        isOpenDrawer={drawerState}
        onOpenDrawer={handleOpenDrawer}
        onCloseDrawer={handleCloseDrawer}
        pageTitle="Reminder"
        componentToPassDown={
          <SendReminder
            formType="ADD"
            ticketId={ticketId}
            ReminderMessage={ReminderMessage}
            dataList={getTickestsListing}
            onCloseDrawer={handleCloseDrawer}
          />
        }
      />
      <CustomDrawer
        isOpenDrawer={drawerMarkResolveState}
        onOpenDrawer={handleOpenMarkResolveDrawer}
        onCloseDrawer={handleCloseMarkResolveDrawer}
        pageTitle="Mark Resolve"
        componentToPassDown={
          <MarkResolve
            formType="ADD"
            ticketId={ticketId}
            dataList={getTickestsListing}
            onCloseDrawer={handleCloseMarkResolveDrawer}
          />
        }
      />
      <CustomDrawer
        isOpenDrawer={drawerCloseNote}
        onOpenDrawer={handleCLoseNoteDrawer}
        onCloseDrawer={handleCLoseNoteCLoseDrawer}
        pageTitle="Note"
        componentToPassDown={
          <CloseNote
            formType="ADD"
            ticketData={ticketId}
            dataList={getTickestsListing}
            onCloseDrawer={handleCloseMarkResolveDrawer}
          />
        }
      />
      <FullPagePopupSupportTicket
        open={drawerInternalNote}
        setOpen={setdrawerInternalNote}
        ticketData={ticketData != undefined ? ticketData.object : ticketData}
        componentToPassDown={
          <InternalNoteNew
            formType="ADD"
            ticketId={ticketId}
            onCloseDrawer={handleCLoseInternalNoteCLoseDrawer}
          />
        }
      />
      <CustomConfirmationdata
        open={openChangeStatus}
        setOpen={setopenChangeStatus}
        value={ChangeStatusValue}
        handleChange={handleChangeStatus}
        handleAgree={handlechangeStatusApi}
        inputs={inputs}
      />
      <div className="move-fixes-popup-height">
        <CustomConfirmationNeedFixesPopup
          open={openSendNeedFixes}
          setOpen={setopenSendNeedFixes}
          value={ChangeStatusValue}
          handleChange={handleChangeDates}
          handleAgree={handlechangeNeedFixesApi}
          endDate={endDate}
        />
      </div>
    </div>
  );
}

export default SupportTicketsTabs;
