import { Chip } from "@mui/material";
import React from "react";
import { communityHeart } from "src/assets";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";

export default function HandleAction({
  isLiked,
  handleFeedAction,
  setShowAddComment,
  feed_id,
}) {
  return (
    <div className="row comment-icon">
      <div className="col-4 svg-color comment-icons text-center">
        {isLiked ? (
          <Chip
            label={
              <span className="d-flex">
                <img src={communityHeart} alt="" />
                &nbsp; Liked
              </span>
            }
            variant="outlined"
            onClick={() => {
              handleFeedAction(feed_id, "feedunlike");
            }}
          />
        ) : (
          <Chip
            label={
              <span className="d-flex">
                <FavoriteBorderIcon /> &nbsp;Like
              </span>
            }
            variant="outlined"
            onClick={() => {
              handleFeedAction(feed_id, "feedlike");
            }}
          />
        )}
      </div>
      <div className="col-4"></div>
      <div className="col-4 svg-color comment-icons text-center">
        {setShowAddComment && (
          <Chip
            onClick={() => {
              setShowAddComment(true);
            }}
            label={
              <>
                <ChatBubbleOutlineIcon />
                &nbsp; Comment
              </>
            }
            variant="outlined"
          />
        )}
      </div>
    </div>
  );
}
