import React, { useState, useEffect } from "react";
import { TextField, FormControl } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useSnackbar } from "notistack";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import {
  booking_slots_by_delegate_and_date,
  delegate_list_for_pass_booking,
  submit_pass_booking_api,
} from "src/DAL/Booking/bookingApi";

export default function PassBooking({
  selectedBooking,
  dataList,
  onCloseDrawer,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    startDate: selectedBooking.date,
  });
  const [selectedDelegate, setSelectedDelegate] = useState(null);
  const [delegateList, setDelegateList] = useState([]);
  const [selectedTime, setSelectedTime] = useState(null);
  const [timeSlotList, setTimeSlotList] = useState([]);
  const [startDateMin, setStartDateMin] = useState(new Date());

  const handleChangeStartDate = (event) => {
    setState((prevState) => {
      return {
        ...prevState,
        startDate: event.$d,
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (selectedDelegate == null) {
      return enqueueSnackbar("Delegate's name can not be empty !", {
        variant: "error",
      });
    }
    if (selectedTime == null) {
      return enqueueSnackbar("Time Slot can not be empty !", {
        variant: "error",
      });
    }
    setIsLoading(true);
    var startdateString = moment(state.startDate).format("YYYY/MM/DD");

    const data = {
      consultant_id: selectedDelegate._id,
      date: startdateString,
      slot_id: selectedTime.slot_id,
      time: selectedTime.start_time,
    };

    const result = await submit_pass_booking_api(selectedBooking._id, data);
    if (result.code === 200) {
      dataList("", "");
      setIsLoading(false);
      onCloseDrawer();
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const getDelegatesList = async () => {
    const result = await delegate_list_for_pass_booking();
    if (result.code === 200) {
      setDelegateList(result.consultant_list);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const getTimeListing = async () => {
    const dateString = moment(state.startDate).format("YYYY/MM/DD");
    let data = {
      date: dateString,
      consultant_id: selectedDelegate._id,
    };
    const result = await booking_slots_by_delegate_and_date(data);
    if (result.code === 200) {
      setTimeSlotList(result.slots);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleChangeDelegate = (event) => {
    setSelectedDelegate(event);
    setSelectedTime(null);
  };

  useEffect(() => {
    getDelegatesList();
  }, []);

  useEffect(() => {
    if (selectedDelegate?._id) {
      getTimeListing();
    }
  }, [state.startDate, selectedDelegate]);

  return (
    <div className="container new-memories">
      <form onSubmit={handleSubmit}>
        <FormControl fullWidth className="mt-3">
          <Autocomplete
            id="name"
            options={delegateList}
            getOptionLabel={(name) =>
              name.first_name +
              " " +
              name.last_name +
              " " +
              "(" +
              name.email +
              ")"
            }
            getOptionSelected={(option, value) => option._id === value._id}
            value={selectedDelegate}
            onChange={(event, newValue) => {
              handleChangeDelegate(newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} label="Delegate *" variant="outlined" />
            )}
            required
          />
        </FormControl>

        <FormControl fullWidth className="mt-3">
          <TextField
            id="outlined-basic"
            label="Page Title"
            variant="outlined"
            fullWidth
            required
            value={
              selectedBooking?.page?.sale_page_title
                ? selectedBooking?.page?.sale_page_title
                : ""
            }
            disabled
          />
        </FormControl>

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            format="DD-MM-YYYY"
            mask="__/__/____"
            inputFormat="DD-MM-YYYY"
            minDate={startDateMin}
            label="Date"
            name="startDate"
            value={state.startDate}
            onChange={(e) => handleChangeStartDate(e, "startDate")}
            renderInput={(params) => (
              <TextField {...params} className="mt-3" required={true} />
            )}
          />
        </LocalizationProvider>
        <FormControl fullWidth className="mt-3">
          <Autocomplete
            id="time-slots"
            options={timeSlotList}
            getOptionLabel={(time) => time.start_time + " - " + time.end_time}
            getOptionSelected={(option, value) =>
              option.slot_id === value.slot_id
            }
            value={selectedTime}
            onChange={(event, newValue) => {
              setSelectedTime(newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} label="Time Slots*" variant="outlined" />
            )}
            required
          />
        </FormControl>

        <div className="d-flex justify-content-between mt-3">
          <div className="booking-timezone">
            {selectedDelegate && <p>{selectedDelegate.time_zone} </p>}
          </div>
          <button className="submit-button" disabled={isLoading}>
            {isLoading ? "Submitting..." : "Submit"}
          </button>
        </div>
      </form>
    </div>
  );
}
