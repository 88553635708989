import { TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useState } from "react";

import CustomConfirmation from "./CustomConfirmation";
import { save_filter_api } from "src/DAL/leadStatus/leadStatus";

export default function SaveFilter({
  tabName,
  filter_object,
  setOpen,
  handleSubmitted,
}) {
  const [inputs, setInputs] = useState({ filter_name: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [openStatus, setOpenStatus] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleChangeStatus = async () => {
    setOpenStatus(false);
    setIsLoading(true);
    if (filter_object.filter_name) {
      delete filter_object.filter_name;
    }
    if (filter_object.filter_From) {
      delete filter_object.filter_From;
    }
    const postData = {
      filter_name: inputs.filter_name,
      filter_on_tab_name: tabName,
      filter_object: filter_object,
    };

    const result = await save_filter_api(postData);
    if (result.code === 200) {
      setOpen(false);
      setIsLoading(false);
      if (handleSubmitted) {
        handleSubmitted(result);
      }
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setOpenStatus(true);
  };

  console.log(inputs, "inputsinputsinputs");
  return (
    <div className="container">
      <form className="row" onSubmit={handleSubmit}>
        <div className="col-lg-12 col-md-6 col-sm-12 mt-3">
          <TextField
            className="mt-4"
            id="outlined-basic"
            label="Filter Name"
            variant="outlined"
            name="filter_name"
            value={inputs.filter_name}
            required={true}
            onChange={(e) => handleChange(e)}
          />
        </div>

        <CustomConfirmation
          open={openStatus}
          setOpen={setOpenStatus}
          title={"Are you sure you want to save this filter?"}
          handleAgree={handleChangeStatus}
        />
        <div class="col-12 mt-3">
          <button class="small-contained-button w-100">
            {isLoading ? "Saving..." : "Save"}
          </button>
        </div>
      </form>
    </div>
  );
}
