import React, { useState } from "react";
import ReactPlayer from "react-player";

const ReactVideoPlayer = (props) => {
  return (
    <div className="wrapper">
      <ReactPlayer
        controls
        className="player"
        url={props.url}
        width="100%"
        height="100%"
        stopOnUnmount
        pip
        playsinline
      />
    </div>
  );
};

export default ReactVideoPlayer;
