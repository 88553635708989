import React, { useState, useEffect } from "react";
import {
  IconButton,
  CircularProgress,
  Chip,
  Tooltip,
  Rating,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import LockIcon from "@mui/icons-material/Lock";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { PodsDetailApi } from "src/DAL/Pods/Pods";
import { s3baseUrl } from "src/config/config";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { htmlDecode } from "src/utils/convertHtml";
import moment from "moment";
import { get_date_with_user_time_zone } from "src/utils/constants";
import ImageSlider from "src/components/GeneralComponents/Shop/ImageSlider";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
const ShopItemDetail = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { state } = useLocation();
  const [itemData, setItemData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [copied, setCopied] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const handleShoppingCart = () => {
    navigate(`/shop/shopping-cart`);
  };

  // const getMemoryDetail = async () => {
  //   const result = await PodsDetailApi(params.pod_slug);
  //   if (result.code === 200) {
  //     setitemData(result.room);
  //     setIsLoading(false);
  //   } else {
  //     enqueueSnackbar(result.message, { variant: "error" });
  //     navigate(`/pods`);
  //   }
  // };

  useEffect(() => {
    setItemData(state);
  }, []);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      <div className="row mobile-margin display-flex">
        <div className="col-12">
          <IconButton
            className="back-screen-button"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon />
          </IconButton>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-5">
          <ImageSlider />
          {/* <img width="100%" src={itemData.card_image} /> */}
        </div>
        <div className="col-12 col-md-6 pt-3">
          {/* {itemData.start_date && (
            <p>
              Start Time :&nbsp;
              {get_date_with_user_time_zone(
                moment(itemData.start_date).format("YYYY-MM-DD") +
                  " " +
                  itemData.start_time,
                "hh:mm A",
                userInfo.time_zone,
                adminTimeZone
              )}
            </p>
          )} */}
          <h2 className="d-inline">{htmlDecode(itemData.card_title)}</h2>
          <div className="off-percentage mt-3">
            <div className="plus-icons">
              <span>+</span>
            </div>
            <p>Extra 5% Off</p>
          </div>
          <div className="shop-item-rating mt-3">
            <Rating name="read-only" value={5} readOnly />
            <p>15 Reviews 82 orders</p>
          </div>
          <p className="shop-detail-price mt-2 mb-2">
            {htmlDecode(itemData.card_short_description)}
          </p>

          <p className="mt-0">
            {htmlDecode(itemData.card_short_description_2)}
          </p>

          <div className="col-12 mt-2 mb-3 text-center detail-item-quantity">
            <div className="item-quantity">
              <span>-</span>
              <input type="text" name="" id="" value="2 x" />
              <span>+</span>
            </div>
          </div>

          <div className="row">
            <div className="col-12 mt-1">
              <button
                className="small-contained-button add-to-cart-button"
                onClick={handleShoppingCart}
              >
                <>
                  <ShoppingCartIcon />
                  Add To Cart
                </>
              </button>
            </div>
            {/* <div className="col-12 col-md-6 zoom-password">
              {itemData.password && (
                <CopyToClipboard
                  text={itemData.password}
                  onCopy={() => setCopiedPassword(true)}
                >
                  <Tooltip title="Click to copy password">
                    <Chip
                      label={
                        <>
                          {itemData.password}
                          <LockIcon />
                        </>
                      }
                      color="primary"
                      className="float-end me-1"
                      variant="outlined"
                    />
                  </Tooltip>
                </CopyToClipboard>
              )}
            </div> */}
          </div>
        </div>
        {/* <div className="col-12 section-space">
          <div
            dangerouslySetInnerHTML={{
              __html: itemData.detail_description,
            }}
          ></div>
        </div> */}
      </div>
      {/* <ShopDialog open={openDialog} handleClose={handleDialogClose} /> */}
    </div>
  );
};

export default ShopItemDetail;
