import React, { useState, useEffect } from "react";
import {
  TextField,
  InputLabel,
  Select,
  FormControl,
  MenuItem,
  TextareaAutosize,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useSnackbar } from "notistack";
import { get_root_value } from "src/utils/domUtils";
import { makeStyles } from "@mui/styles";
import {
  AddSupportTicketAPI,
  DeleteSupportTicketImageOnS3,
  departmentListAPI,
  NewdepartmentListAPI,
  resolve,
  UpdateSupportTicketAPI,
  UploadSupportTicketImageOnS3,
} from "src/DAL/SupportTicket/SupportTicket";
import { s3baseUrl } from "src/config/config";
import {
  csvImage,
  docImage,
  pdfImage,
  wordImage,
  audioImage,
  xlsxImage,
  otherImage,
} from "src/assets";
import TinyEditor from "src/components/Ckeditor";
import { useContentSetting } from "src/Hooks/ContentSettingState";
import { useNavigate } from "react-router-dom";
const useStyles = makeStyles(() => ({
  paper: {
    background: get_root_value("--popup-background-color"),
    color: get_root_value("--input-text-color"),
  },
}));

export default function MarkResolve({
  onCloseDrawer,
  dataList,
  editData,
  get_list_support_ticket_with_pagination_new,
  ticketId,
  formType,
  navigateBack,
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [Message, setMessage] = useState("");
  const settings = useContentSetting();
  const { socket } = settings;
  const [inputs, setInputs] = useState({
    close_note: "",
    reason_to_solve: "answered",
  });
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const data = {
      close_note: inputs.close_note,
      reason_to_solve: inputs.reason_to_solve,
      support_ticket: ticketId,
    };
    const result = await resolve(data);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      onCloseDrawer();
      setIsLoading(false);

      socket.emit("send_close_ticket_notification", result);
      dataList();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  return (
    <div className="container new-memories">
      <form onSubmit={formType === "ADD" ? handleSubmit : handleUpdate}>
        <div className="mt-3">
          <div className="col-12 mt-4 mb-4">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">
                Reason To Solve
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="reason_to_solve"
                value={inputs.reason_to_solve}
                label="Reason To Solve"
                onChange={handleChange}
              >
                <MenuItem value="answered">Answered</MenuItem>
                <MenuItem value="solved">Solved</MenuItem>
                <MenuItem value="auto-Closure">Auto-Closure</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-12 mt-4 mb-4">
            <div className="col-lg-12 col-md-12 col-sm-12 ">
              <p className="tracking_code_heading">Resolve Note*</p>
              <TextareaAutosize
                aria-label="empty textarea"
                value={inputs.close_note}
                name="close_note"
                required
                className="textarea-autosize"
                onChange={handleChange}
                placeholder="Note..."
                style={{
                  width: "100%",
                  height: "100px",
                  backgroundColor: "transparent",
                  color: "white",
                  borderRadius: "10px",
                  outline: "none",
                  paddingTop: "8px",
                  paddingLeft: "5px",
                  focus: "green",
                }}
              />
            </div>
          </div>
          <span className="float-end">
            <button className="submit-button" disabled={isLoading}>
              {isLoading
                ? formType === "ADD"
                  ? "Resolving..."
                  : "Updating..."
                : formType === "ADD"
                ? "Resolve"
                : "Update"}
            </button>
          </span>
        </div>
      </form>
    </div>
  );
}
