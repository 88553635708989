import React, { useState } from "react";
import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  TextareaAutosize,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import PageDescription from "../GeneralComponents/PageDescription";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import Slider from "@mui/material/Slider";
import {
  get_performance_data,
  perfomance_add_api,
  updatePerformanceApi,
  update_reminder_time_performanceapi,
} from "src/DAL/Tracking/Tracking";
import moment from "moment";
import { useEffect } from "react";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
  fonts: {
    fontSize: "13px",
    fontWeight: "500",
  },
  root: {
    color: "#f6bd4b",
    // color for the part that is not covered
  },
  rail: {
    color: "#888", // color for the part that is covered
  },
}));
const marks = [
  {
    value: 0,
    label: "0",
  },

  {
    value: 10,
    label: "10",
  },
];
const PerformanceStreak = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = React.useState(false);
  const [memberData, setMemberData] = React.useState("");
  const [SettingObject, setSettingObject] = React.useState();
  const [ButtonLoader, setButtonLoader] = React.useState(false);

  const [inputs, setinputs] = useState({
    time: null,
    days: [],
    attitude_performance_rate: 0,
    focus_performance_rate: 0,
    desire_performance_rate: 0,
    discipline_performance_rate: 0,
    win_note: "",
    win_note_performance_rate: 0,
  });

  const classes = useStyles();
  const handleChange = (newValue, name) => {
    setinputs((prevState) => {
      return {
        ...prevState,
        [name]: newValue?.$d,
      };
    });
  };
  const handleChangeInputs = (newValue, name) => {
    const value = newValue.target.value;
    setinputs((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };
  const handleUpdateReminderTime = async (e) => {
    e.preventDefault();
    let postData = {
      dynamite_streak_performance_reminder_time: {
        time: moment(inputs.time).format("HH:mm"),
        days: inputs.days,
      },
    };
    const result = await update_reminder_time_performanceapi(postData);
    if (result.code == 200) {
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleSubmit = async () => {
    setButtonLoader(true);
    let postData = {
      attitude_performance_rate: inputs.attitude_performance_rate,
      focus_performance_rate: inputs.focus_performance_rate,
      desire_performance_rate: inputs.desire_performance_rate,
      discipline_performance_rate: inputs.discipline_performance_rate,
      win_note: inputs.win_note,
      win_note_performance_rate: inputs.win_note_performance_rate,
    };
    const result = await perfomance_add_api(postData);
    if (result.code == 200) {
      setButtonLoader(false);
      if (!memberData) {
        getPerformanceData();
      }
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setButtonLoader(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleUpdate = async () => {
    setButtonLoader(true);
    let postData = {
      attitude_performance_rate: inputs.attitude_performance_rate,
      focus_performance_rate: inputs.focus_performance_rate,
      desire_performance_rate: inputs.desire_performance_rate,
      discipline_performance_rate: inputs.discipline_performance_rate,
      win_note: inputs.win_note,
      win_note_performance_rate: inputs.win_note_performance_rate,
    };
    const result = await updatePerformanceApi(memberData, postData);
    if (result.code == 200) {
      setButtonLoader(false);
      if (!memberData) {
        getPerformanceData();
      }
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setButtonLoader(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const getPerformanceData = async () => {
    setIsLoading(true);
    const result = await get_performance_data();
    if (result.code == 200) {
      setMemberData(result.strek._id);
      setSettingObject(result?.streak_performance_setting);
      setinputs((inputs) => ({
        ...inputs,
        ["time"]: result.dynamite_streak_performance_reminder_time.time
          ? result.dynamite_streak_performance_reminder_time.time
          : null,
        ["days"]: result.dynamite_streak_performance_reminder_time.days
          ? result.dynamite_streak_performance_reminder_time.days
          : [],
        ["attitude_performance_rate"]: result.strek.attitude_performance_rate
          ? result.strek.attitude_performance_rate
          : 0,
        ["desire_performance_rate"]: result.strek.desire_performance_rate
          ? result.strek.desire_performance_rate
          : 0,
        ["discipline_performance_rate"]: result.strek
          .discipline_performance_rate
          ? result.strek.discipline_performance_rate
          : 0,
        ["focus_performance_rate"]: result.strek.focus_performance_rate
          ? result.strek.focus_performance_rate
          : 0,
        ["win_note"]: result.strek.win_note ? result.strek.win_note : "",
        ["win_note_performance_rate"]: result.strek.win_note_performance_rate
          ? result.strek.win_note_performance_rate
          : 0,
      }));

      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getPerformanceData();
  }, []);
  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <>
      <div className="container">
        <div className="row mb-2 mt-4">
          <div className="col-lg-5">
            <PageDescription
              parameter="vault_description"
              else_title={
                SettingObject?.performance_streak_heading ??
                "Performance Streak"
              }
            />
          </div>
          <div className="col-12 col-md-6 col-lg-7 mt-4 mt-md-3 mt-lg-2">
            <form
              onSubmit={(e) => {
                handleUpdateReminderTime(e, "morning_reminder");
              }}
            >
              <div className="row">
                <div className="col-5 pe-0">
                  {" "}
                  <FormControl variant="outlined" size="small" required>
                    <InputLabel id="demo-simple-select-outlined-label">
                      Select Day
                    </InputLabel>
                    <Select
                      multiple
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={inputs.days}
                      onChange={(e) => handleChangeInputs(e, "days")}
                      label="Select Day"
                      name="days"
                    >
                      <MenuItem value={0}>Sunday</MenuItem>
                      <MenuItem value={1}>Monday</MenuItem>
                      <MenuItem value={2}>Tuesday</MenuItem>
                      <MenuItem value={3}>Wednesday</MenuItem>
                      <MenuItem value={4}>Thursday</MenuItem>
                      <MenuItem value={5}>Friday</MenuItem>
                      <MenuItem value={6}>Saturday</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="col-5 pe-0 ">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      label="Reminder Time"
                      value={inputs.time}
                      onChange={(e) => {
                        handleChange(e, "time");
                      }}
                      renderInput={(params) => (
                        <TextField {...params} size="small" required={true} />
                      )}
                    />
                  </LocalizationProvider>
                </div>
                <div className="col-2  ps-1">
                  <span className="float-end">
                    <button className="submit-button" type="submit">
                      Save
                    </button>
                  </span>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 pe-0">
            <div className="mt-3 h-100">
              <div style={{ padding: "0px 0px" }}>
                <h2
                  className="mt-2  mb-4"
                  style={{
                    fontSize: "20px",
                    fontWeight: "600",

                    color: "white",
                  }}
                >
                  {SettingObject?.rate_your_performance_streak_heading ??
                    "Rate Your Performance"}
                </h2>
                <div className="d-flex justify-content-between ">
                  <p style={{ fontSize: "17px" }} className="">
                    Your Attitude
                  </p>
                  <div style={{ width: "57%" }} className="slider-container">
                    <Slider
                      aria-label="Temperature"
                      defaultValue={0}
                      className={classes.root}
                      value={inputs.attitude_performance_rate}
                      classes={{ rail: classes.rail }}
                      marks={marks}
                      onChange={(e) =>
                        handleChangeInputs(e, "attitude_performance_rate")
                      }
                      // getAriaValueText={valuetext}
                      valueLabelDisplay="on"
                      min={0}
                      max={10}
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-between mt-2">
                  <p style={{ fontSize: "17px" }} className="">
                    {SettingObject?.your_focus_text ?? "Your Focus"}
                  </p>
                  <div style={{ width: "57%" }} className="slider-container">
                    <Slider
                      aria-label="Temperature"
                      defaultValue={0}
                      className={classes.root}
                      value={inputs.focus_performance_rate}
                      onChange={(e) =>
                        handleChangeInputs(e, "focus_performance_rate")
                      }
                      classes={{ rail: classes.rail }}
                      // getAriaValueText={valuetext}
                      valueLabelDisplay="on"
                      marks={marks}
                      min={0}
                      max={10}
                    />
                  </div>
                </div>{" "}
                <div className="d-flex justify-content-between mt-2">
                  <p style={{ fontSize: "17px" }} className="">
                    {SettingObject?.your_desire_text ?? " Your Desire"}
                  </p>
                  <div style={{ width: "57%" }} className="slider-container">
                    <Slider
                      aria-label="Temperature"
                      defaultValue={0}
                      marks={marks}
                      value={inputs.desire_performance_rate}
                      onChange={(e) =>
                        handleChangeInputs(e, "desire_performance_rate")
                      }
                      className={classes.root}
                      classes={{ rail: classes.rail }}
                      // getAriaValueText={valuetext}
                      valueLabelDisplay="on"
                      min={0}
                      max={10}
                    />
                  </div>
                </div>{" "}
                <div className="d-flex justify-content-between mt-2">
                  <p style={{ fontSize: "17px", margiTop: "8px" }} className="">
                    {SettingObject?.your_discipline_text ?? "Your Discipline"}
                  </p>
                  <div
                    style={{ width: "57%" }}
                    className="slider-container mt-2"
                  >
                    <Slider
                      aria-label="Temperature"
                      defaultValue={0}
                      valueLabelDisplay="on"
                      marks={marks}
                      value={inputs.discipline_performance_rate}
                      onChange={(e) =>
                        handleChangeInputs(e, "discipline_performance_rate")
                      }
                      className={classes.root}
                      classes={{ rail: classes.rail }}
                      // getAriaValueText={valuetext}

                      min={0}
                      max={10}
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-between mt-2">
                  <p style={{ fontSize: "17px" }} className="">
                    {SettingObject?.rate_this_win_text ?? "Rate This Win"}
                  </p>
                  <div style={{ width: "57%" }} className="slider-container">
                    <Slider
                      aria-label="Temperature"
                      defaultValue={0}
                      value={inputs.win_note_performance_rate}
                      className={classes.root}
                      onChange={(e) =>
                        handleChangeInputs(e, "win_note_performance_rate")
                      }
                      classes={{ rail: classes.rail }}
                      // getAriaValueText={valuetext}
                      valueLabelDisplay="on"
                      marks={marks}
                      min={0}
                      max={10}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 ps-4">
            <div className="mt-3 mb-3">
              <h2
                className="mt-3  mb-4"
                style={{
                  fontSize: "20px",
                  fontWeight: "600",

                  color: "white",
                }}
              >
                {SettingObject?.win_from_today_heading ??
                  "Share A win From Today"}
              </h2>
              <div className="mt-2">
                <TextareaAutosize
                  aria-label="empty textarea"
                  placeholder={
                    SettingObject?.win_from_today_placeholder ??
                    "Share A win From Today"
                  }
                  className="text-color textarea-autosize"
                  required
                  value={inputs.win_note}
                  onChange={(e) => handleChangeInputs(e, "win_note")}
                  minRows={4}
                  name="option"
                  style={{
                    width: "100%",
                    borderRadius: "11px",
                    background: "#1D2537",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    height: "300px",
                    paddingLeft: "15px",
                    color: "white",
                    fontSize: "15px",
                    border: "2px solid rgba(71, 66, 34, 0.3)",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mt-2">
          <span className="float-end">
            <button
              className="submit-button"
              onClick={() => (memberData ? handleUpdate() : handleSubmit())}
            >
              {memberData
                ? ButtonLoader
                  ? "Updating..."
                  : "Update"
                : ButtonLoader
                ? "Saving..."
                : "Save"}
            </button>
          </span>
        </div>
      </div>
    </>
  );
};

export default PerformanceStreak;
