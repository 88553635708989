import { invokeApi } from "../../bl_libs/invokeApi";

export const AddPods = async (data) => {
  const requestObj = {
    path: `api/room/consultant`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const PodsListing = async (data) => {
  const requestObj = {
    path: `api/room`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const DeletePod = async (id) => {
  const requestObj = {
    path: `api/room/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const EditPods = async (pods_slug, data) => {
  const requestObj = {
    path: `api/room/consultant/${pods_slug}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const DetailPodsApi = async (pods_slug) => {
  const requestObj = {
    path: `api/delegates_room/room_detail/${pods_slug}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const DetailPodsApis = async (pods_slug) => {
  const requestObj = {
    path: `api/room/detail/${pods_slug}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const podsDetailApi = async (pods_slug) => {
  const requestObj = {
    path: `api/room/detail/v1/${pods_slug}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const pods_detail_room_api = async (
  slug,
  page,
  limit,
  search_text,
  value
) => {
  const requestObj = {
    path: `api/room/room_users_list/${slug}?page=${page}&limit=${limit}&search_text=${search_text}&type=${value}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
