import React, { useState } from "react";
import { useEffect } from "react";
import no_data_found from "src/assets/images/no_data_found.png";
export default function RecordNotFound(props) {
  const [delayTime, setDelayTime] = useState(false);
  useEffect(() => {
    setTimeout(() => setDelayTime(true), 1000);
  }, []);
  return (
    <>
      {/* {delayTime && ( */}
      <div className="no-access-string">
        <img className="mx-auto" src={no_data_found} />
        <p className="mt-3">{`${
          props.title ? props.title : "Data Not Found"
        }`}</p>
      </div>
      {/* )} */}
    </>
  );
}
