import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import MUICustomTabs from "src/components/GeneralComponents/MUICustomTabs";
import { CircularProgress, IconButton } from "@mui/material";
import { s3baseUrl } from "src/config/config";
import PopOverViewMore from "./PopOverViewMore";
import MemberProgramPopover from "./memberProgramPopOver";
import ActiveLastBreadcrumb from "src/components/BreadCrums";
import {
  eventDetailApi,
  eventDetailApiWithPagination,
} from "src/DAL/Calendar/Calendar";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
let status_object = {
  0: "group",
  1: "individual",
  2: "all",
};
const EventDetailListNew = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const state = useLocation();
  const [eventMembers, setEventMembers] = useState([]);
  const [eventGroups, setEventGroups] = useState([]);
  const [allMember, setAllMember] = useState([]);
  const [useInfo, setuseInfo] = useState();
  const [program, setprogram] = useState([]);
  const [event, setEvent] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElEvnet, setAnchorElEvnet] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState("");
  const [searchText, setSearchText] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(0);

  const opens = Boolean(anchorEl);
  const id = opens ? "simple-popover" : undefined;
  const opensEvent = Boolean(anchorElEvnet);
  const idevent = opensEvent ? "simple-popover" : undefined;
  let event_slug = state?.state?.editValues?.event_slug;
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };
  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPageCount(1);
    setPage(0);
  };
  const searchFunction = () => {
    setPage(0);
    setPageCount(1);
    DetailEvent();
  };
  const DetailEvent = async (value) => {
    setIsLoading(true);
    let type = status_object[tabValue];
    let search = value == "tab" ? "" : searchText;
    const result = await eventDetailApiWithPagination(
      event_slug,
      type,
      page,
      rowsPerPage,
      search
    );
    if (result.code === 200) {
      if (type == "group") {
        setEventGroups(result.groups);
      } else if (type == "individual") {
        setEventMembers(
          result.users.map((item) => {
            let find_name = item.first_name + " " + item.last_name;
            return {
              ...item,
              find_name,
              table_avatar: {
                src: s3baseUrl + item?.profile_image,
                alt: item?.first_name,
              },
            };
          })
        );
      } else if (type == "all") {
        setAllMember(
          result.users.map((item) => {
            let find_name = item.first_name + " " + item.last_name;
            return {
              ...item,
              find_name,
              table_avatar: {
                src: s3baseUrl + item?.profile_image,
                alt: item?.first_name,
              },
            };
          })
        );
      }
      setTotalPages(result.total_pages);
      setTotalCount(result.total_count);
      setuseInfo(result?.event.title);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleClickPopUP = (event, row) => {
    setprogram(row.program);
    setAnchorEl(event.currentTarget);
  };

  const handleClickPopUPEvent = (event, row) => {
    setEvent(row.event);
    setAnchorElEvnet(event.currentTarget);
  };
  const handleClosePopUpEvent = () => {
    setAnchorElEvnet(null);
  };

  const handleClosePopUp = () => {
    setAnchorEl(null);
  };

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    setPage(0);
    setPageCount(1);
    setSearchText();
    DetailEvent("tab");
  }, [tabValue]);
  useEffect(() => {
    DetailEvent();
  }, [page, rowsPerPage]);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    { id: "title", label: "Title" },
    { id: "group_by", label: "Group By" },
    {
      id: "program",
      label: "Programme / Event",
      renderData: (row) => {
        const firstFiveprograms = row.program?.slice(0, 5);
        const firstFiveEvent = row.event?.slice(0, 5);
        return (
          <>
            {firstFiveprograms.length > 0
              ? firstFiveprograms.map((program, i) => {
                  if (program._id !== null) {
                    return (
                      <>
                        <div key={i}>
                          <p>{program?._id?.title + ","}</p>
                        </div>
                        {i == 4 && (
                          <p
                            className="mb-1 mt-1 view-more"
                            aria-describedby={id}
                            variant="contained"
                            onClick={(e) => handleClickPopUP(e, row)}
                          >
                            view more
                          </p>
                        )}
                      </>
                    );
                  }
                })
              : firstFiveEvent.length > 0 &&
                firstFiveEvent.map((event, i) => {
                  if (event._id !== null) {
                    return (
                      <>
                        <div key={i}>
                          <p>{event._id?.title + ","}</p>
                        </div>
                        {i == 4 && (
                          <p
                            className="mb-1 mt-1 view-more"
                            aria-describedby={idevent}
                            variant="contained"
                            onClick={(e) => handleClickPopUPEvent(e, row)}
                          >
                            view more
                          </p>
                        )}
                      </>
                    );
                  }
                })}
          </>
        );
      },
    },
    { id: "group_type", label: "Group Type" },
  ];

  const TABLE_HEAD_MEMBER = [
    { id: "number", label: "#", type: "number" },
    { id: "find_name", label: "Name" },
    {
      id: "table_avatar",
      label: "Profile",
      type: "thumbnail",
    },
    { id: "email", label: "Email" },
    {
      id: "program",
      label: "Programme",
      renderData: (row) => {
        const firstFiveprograms = row.program?.slice(0, 5);
        return (
          <div>
            {firstFiveprograms.length > 0 &&
              firstFiveprograms.map((item, i) => {
                if (item._id !== null) {
                  return (
                    <>
                      <div key={i}>
                        <p>{item?._id?.title + ","}</p>
                      </div>
                      {i == 4 && (
                        <p
                          className="mb-1 mt-1 view-more"
                          aria-describedby={id}
                          variant="contained"
                          onClick={(e) => handleClickPopUP(e, row)}
                        >
                          view more
                        </p>
                      )}
                    </>
                  );
                }
              })}
          </div>
        );
      },
    },
  ];

  const TABLE_HEAD_Event = [
    { id: "number", label: "#", type: "number" },
    { id: "find_name", label: "Name" },
    {
      id: "table_avatar",
      label: "Profile",
      type: "thumbnail",
    },
    { id: "email", label: "Email" },
  ];

  let settings = {
    className: "card-with-background mt-3",
  };

  let breadCrumbMenu = [
    {
      title: "Calendar",
      navigation: "/calendar-events",
      active: false,
    },
    {
      title: "Events List",
      navigation: "/calendar-events/event-list",
      active: false,
    },
    {
      title: "Event Detail",
      active: false,
    },
    {
      title: useInfo,
      active: true,
    },
  ];

  const TABS_OPTIONS = [
    {
      title: "Group List",
      component: (
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={eventGroups}
          className="card-with-background mt-3 text-capitalize"
          pagePagination={true}
          custom_pagination={{
            total_count: totalCount,
            rows_per_page: rowsPerPage,
            page: page,
            handleChangePage: handleChangePage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          custom_search={{
            searchText: searchText,
            setSearchText: setSearchText,
            handleSubmit: searchFunction,
          }}
          pageCount={pageCount}
          totalPages={totalPages}
          handleChangePages={handleChangePages}
        />
      ),
    },
    {
      title: "event individual member",
      component: (
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD_MEMBER}
          data={eventMembers}
          pagePagination={true}
          {...settings}
          custom_pagination={{
            total_count: totalCount,
            rows_per_page: rowsPerPage,
            page: page,
            handleChangePage: handleChangePage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          custom_search={{
            searchText: searchText,
            setSearchText: setSearchText,
            handleSubmit: searchFunction,
          }}
          pageCount={pageCount}
          totalPages={totalPages}
          handleChangePages={handleChangePages}
        />
      ),
    },
    {
      title: "Event Member",
      component: (
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD_Event}
          data={allMember}
          pagePagination={true}
          {...settings}
          custom_pagination={{
            total_count: totalCount,
            rows_per_page: rowsPerPage,
            page: page,
            handleChangePage: handleChangePage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          custom_search={{
            searchText: searchText,
            setSearchText: setSearchText,
            handleSubmit: searchFunction,
          }}
          pageCount={pageCount}
          totalPages={totalPages}
          handleChangePages={handleChangePages}
        />
      ),
    },
  ];

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12 display-flex mb-4">
            <span>
              <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
            </span>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-lg-8 col-sm-12">
            <h2>Calendar Event Detail</h2>
          </div>
        </div>
        <MUICustomTabs
          data={TABS_OPTIONS}
          value={tabValue}
          handleChange={handleChange}
        />
        <MemberProgramPopover
          handleClick={handleClickPopUP}
          handleClose={handleClosePopUp}
          anchorEl={anchorEl}
          id={id}
          navigatePage={true}
          open={opens}
          program={program}
        />
        <PopOverViewMore
          handleClick={handleClickPopUPEvent}
          handleClose={handleClosePopUpEvent}
          anchorEl={anchorElEvnet}
          id={idevent}
          open={opensEvent}
          program={event}
          title="Event"
        />
      </div>
    </>
  );
};

export default EventDetailListNew;
