import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import React from "react";
import { get_root_value } from "src/utils/domUtils";
// import { get_root_value } from "src/utils/domUtils";

function CustomConfirmation({
  open,
  openDelete,
  setOpen,
  handleAgree,
  title,
  title_html,
}) {
  const handleCloseDialog = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog
        open={open || openDelete}
        onClose={handleCloseDialog}
        PaperProps={{
          style: {
            backgroundColor: get_root_value("--popup-background-color"),
            // backgroundColor: "black",
            color: get_root_value("--portal-theme-primary"),
            boxShadow: "none",
            zIndex: "1350",
          },
        }}
      >
        {title_html ? (
          title_html
        ) : (
          <DialogTitle>
            {title ? title : "Are you sure you want to take this action?"}
          </DialogTitle>
        )}
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleAgree}>Agree</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default CustomConfirmation;
