import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export default function FullPagePopupSupportTicket({
  open,
  setOpen,
  componentToPassDown,
  ticketData,
}) {
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        id="show-dialog-on-full"
        disableEnforceFocus={true}
      >
        <div className="pt-3 ps-3 d-flex">
          <IconButton className="back-screen-button" onClick={handleClose}>
            <CloseIcon />{" "}
          </IconButton>
          <span>
            <h4
              className="anchor-style ms-2"
              style={{ marginTop: "11px", color: " #f6bd4b" }}
            >
              {ticketData?.member?.first_name
                ? ticketData?.member?.first_name +
                  " " +
                  ticketData?.member?.last_name +
                  " (" +
                  ticketData?.member?.email +
                  ")"
                : ""}
              <span>
                {" "}
                {ticketData ? " " + "|" + " " + ticketData.subject : ""}
              </span>
            </h4>
          </span>
        </div>
        <div className="responce-messages">{componentToPassDown}</div>
      </Dialog>
    </div>
  );
}
