import { s3baseUrl } from "src/config/config";

export const isFileExistsOnS3 = async (file) => {
  try {
    const response = await fetch(s3baseUrl + file, {
      method: "HEAD",
    });
    return response.ok;
  } catch (error) {
    return false;
  }
};
