import { Icon } from "@iconify/react";
import { Avatar } from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import { communityRectangleImage } from "src/assets";
import CustomPopover from "src/components/GeneralComponents/CustomPopover";
import { s3baseUrl } from "src/config/config";
import { useContentSetting } from "src/Hooks/ContentSettingState";
import {
  get_date_with_user_time_zone,
  get_short_string,
  is_small_screen,
  LONG_TEXT_LIMIT,
  string_avatar,
} from "src/utils/constants";
import { htmlDecode, urlify } from "src/utils/convertHtml";
import SingleChildInception from "./SingleChildInception";

function SingleComment(props) {
  const {
    comment,
    handleCommentEdit,
    handleCommentDelete,
    handleCommentPin,
    handleOpenReplyBox,
    eventDetail,
  } = props;
  const settings = useContentSetting();
  const [isShowCommentMore, setIsShowCommentMore] = useState(true);

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleCommentEdit,
    },

    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleCommentDelete,
    },
  ];

  const MENU_OPTIONS_EXECUTIVE = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleCommentEdit,
    },

    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleCommentDelete,
    },

    {
      label: comment.is_featured ? "Unpin" : "Pin",
      icon: "akar-icons:pin",
      handleClick: handleCommentPin,
    },
  ];

  if (handleOpenReplyBox) {
    MENU_OPTIONS_EXECUTIVE.push({
      label: "Reply",
      icon: "mdi:reply",
      handleClick: handleOpenReplyBox,
    });
  }

  const get_short_date = () => {
    let from_now = moment(
      get_date_with_user_time_zone(
        comment?.createdAt,
        "DD MMM YYYY HH:mm",
        settings.userInfo.time_zone,
        settings.adminTimeZone
      )
    ).fromNow();

    from_now = from_now.replace("a few seconds ago", "just now");
    from_now = from_now.replace("seconds", "s");
    from_now = from_now.replace("minutes", "m");
    from_now = from_now.replace("hours", "h");
    // from_now = from_now.replace("day", "d");

    return from_now;
  };

  return (
    <div>
      <div
        className={"d-flex mt-1 position-relative profile-comments inception"}
      >
        <div className="poster-name-image d-flex w-100">
          <Avatar
            src={s3baseUrl + comment?.user_info_action_for?.profile_image}
            alt="photoURL"
          >
            {string_avatar(comment?.user_info_action_for?.name)}
          </Avatar>
          <div className="w-100">
            <div className="text-start set-title-width poster-name ps-2 w-100">
              <span className="card-title mb-0 fs-12 fw-500">
                {htmlDecode(comment?.user_info_action_for?.name)}
              </span>

              {comment.is_featured && (
                <Icon
                  icon="bi:pin-angle-fill"
                  className="pinned-icon ms-2"
                ></Icon>
              )}
              {!is_small_screen() && (
                <span className="date-color float-end pe-4 fw-400 fs-8">
                  {get_short_date()}
                </span>
              )}
            </div>
            {is_small_screen() && (
              <span className="date-color ps-2">
                {get_date_with_user_time_zone(
                  comment?.createdAt,
                  "DD MMM YYYY [at] hh:mm A",
                  settings.userInfo.time_zone,
                  settings.adminTimeZone
                )}
              </span>
            )}
            <div className="post-description mt-1 ps-2 pt-0 fs-12  fw-400">
              {comment?.message?.length > 200 ? (
                isShowCommentMore ? (
                  <>
                    <p className="text-start mb-0 pb-1">
                      {" "}
                      {get_short_string(
                        `${htmlDecode(comment?.message)}`,
                        LONG_TEXT_LIMIT
                      )}
                    </p>
                    <span
                      className="ms-2 see-more"
                      onClick={() => {
                        setIsShowCommentMore(false);
                      }}
                    >
                      See More
                    </span>
                  </>
                ) : (
                  <>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: urlify(htmlDecode(comment?.message)),
                      }}
                      className="text-start mb-0"
                    ></p>
                    <span
                      className="ms-2 see-more"
                      onClick={() => {
                        setIsShowCommentMore(true);
                      }}
                    >
                      See Less
                    </span>
                  </>
                )
              ) : (
                <span
                  dangerouslySetInnerHTML={{
                    __html: urlify(htmlDecode(comment?.message)),
                  }}
                  className="text-start date-color mb-0"
                ></span>
              )}
            </div>
            <div className="col-12">
              {comment.file_url && (
                <a
                  href={s3baseUrl + comment.file_url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="comment-view-attachment"
                >
                  <span className="chat-image-preview mt-2">
                    <img src={s3baseUrl + comment.file_url} />
                  </span>
                </a>
              )}
            </div>
          </div>
        </div>

        {(comment?.comment_is_self === true ||
          settings.userInfo.is_executive) && (
          <div className="affirmation-dots">
            <CustomPopover
              menu={
                settings.userInfo.is_executive
                  ? MENU_OPTIONS_EXECUTIVE
                  : MENU_OPTIONS
              }
              data={comment}
            />
          </div>
        )}
      </div>
      {comment?.replies?.length > 0 &&
        comment?.replies.map((reply, index) => {
          return (
            <div className="ps-3" key={index}>
              <SingleChildInception
                reply={reply}
                handleCommentEdit={handleCommentEdit}
                handleCommentDelete={handleCommentDelete}
              />
            </div>
          );
        })}
    </div>
  );
}

export default SingleComment;
