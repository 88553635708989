import { CircularProgress } from "@mui/material";
import PaymentRequestInfo from "./PaymentRequestInfo";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { memberBillingDetailApi } from "src/DAL/PaymentRequest/paymentRequest";
import { useContentSetting } from "src/Hooks/ContentSettingState";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
const PaymentRequestInfoFile = () => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);

  const { stripeKeyObject } = useContentSetting();

  const params = useParams();

  let [key, setKey] = useState("");
  const getRequestDetail = async () => {
    setIsLoading(true);
    const result = await memberBillingDetailApi(params.request_title);
    if (result.code === 200) {
      let payment_mode = result.payment_request.payment_mode;
      if (payment_mode === "sandBox") {
        setKey(stripeKeyObject.sandBox_publish_key);
      } else {
        setKey(stripeKeyObject.live_publish_key);
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getRequestDetail();
  }, []);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <>
      <Elements stripe={loadStripe(key)}>
        <PaymentRequestInfo />
      </Elements>
    </>
  );
};

export default PaymentRequestInfoFile;
