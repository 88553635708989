import {
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  hv1Image,
  hv2Image,
  hv3Image,
  hv4Image,
  hv5Image,
  hv6Image,
} from "src/assets";
import CustomMUICard from "src/components/_dashboard/GeneralComponents/CustomMUICard";
import { get_tutorials_list } from "src/DAL/Tutorials/Tutorials";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import { s3baseUrl } from "src/config/config";
import Visibility from "@mui/icons-material/Visibility";
import SearchIcon from "@mui/icons-material/Search";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const dummy = [
  {
    title: "Zuabir",
    name: "Zuabir",
    email: "zubi@gmail.com",
  },
];

export default function TutorialsList() {
  const navigate = useNavigate();
  const classes = useStyles();
  const [tutorialsData, setTutorialsData] = useState([]);
  const [tutorialsVideos, setTutorialsVideos] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [query, setQuery] = useState("");

  const getTutorialsList = async () => {
    const result = await get_tutorials_list();
    if (result.code === 200) {
      let CARD_OPTIONS = [];
      result.help_video_category.map((category) => {
        CARD_OPTIONS.push({
          ...category,
          // help_videos: category.help_videos.map((video) => {
          help_videos: category.map((video) => {
            return {
              ...video,
              card_title: video.title,
              card_short_description: video.short_description,
              card_image: s3baseUrl + video.image.thumbnail_1,
              card_classes: "col-lg-4 col-md-6 col-sm-12 mt-2",
            };
          }),
        });
      });
      setTutorialsData(CARD_OPTIONS);
      setTutorialsVideos(CARD_OPTIONS);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handleChangeSearch = (e) => {
    setQuery(e.target.value);
    localStorage.setItem("teturials", e.target.value);
  };
  const handleClick = (object) => {
    navigate(`/tutorials/tutorial-detail/${object.help_video_slug}`, {
      state: object,
    });
  };

  const getFilteredData = (query, data) => {
    let dataToFilter = [...data];
    const filtered = dataToFilter.map((value, i) => {
      if (query) {
        let resultFilter = value.help_videos.filter((post) => {
          if (post.title.toLowerCase().includes(query.toLowerCase())) {
            return true;
          } else {
            return false;
          }
        });
        return { ...value, help_videos: resultFilter };
      } else {
        return value;
      }
    });
    return filtered;
  };

  useEffect(() => {
    getTutorialsList();
    let search_text = localStorage.getItem("teturials");
    if (search_text) {
      setQuery(search_text);
    }
  }, []);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-9 col-mg-9 col-sm-12">
          <h2>Tutorials </h2>
        </div>
        <div className="col-lg-3 col-md-3 col-sm-12 text-end">
          <FormControl sx={{ m: 1, width: "25ch" }} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">
              Search
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type="text"
              onChange={handleChangeSearch}
              value={query}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    edge="end"
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              }
              label="Search"
            />
          </FormControl>
        </div>
        <div className="col-12 tutorials-list">
          {getFilteredData(query, tutorialsVideos).map((category, index) => {
            return (
              <div className="mt-5 title" key={index}>
                {category[0]?.help_video_category?.title && (
                  <h2>{category[0]?.help_video_category?.title}</h2>
                )}
                <CustomMUICard
                  data={category.help_videos}
                  handleClick={handleClick}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
