import { Card, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import HlsVideoPlayer from "../Live-Stream/components/HlsVideoPlayer";
import { get_stream_detail } from "src/DAL/LiveStream/LiveStream";

export default function LiveStreamEmbedCode() {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [liveStream, setLiveStream] = useState({});
  const navigate = useNavigate();

  const { stream_id } = useParams();
  const decoded_stream_id = stream_id;
  // const decoded_stream_id = atob(stream_id);

  //   const encodedString = btoa(stream_id);
  //   console.log("Encoded:", encodedString);

  // Decode the Base64 string back to the original
  console.log("Decoded:", decoded_stream_id);

  const handle_get_stream_detail = async () => {
    const result = await get_stream_detail(decoded_stream_id);
    if (result.code === 200) {
      setLiveStream(result.stream);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handle_get_stream_detail();
  }, [decoded_stream_id]);

  return (
    <Card
      className="live-stream-wrapper"
      style={{ height: "100vh", borderRadius: "0px" }}
    >
      {isLoading && (
        <CircularProgress className="live-stream-loader" color="primary" />
      )}
      <HlsVideoPlayer
        hlsUrl={liveStream.stream_end_point_url}
        isFullScreen={isFullScreen}
        setIsFullScreen={setIsFullScreen}
      />
    </Card>
  );
}
