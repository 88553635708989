import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { IconButton, CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  lessonDetail,
  markelessonCompleted,
} from "../../DAL/Programmes/Programmes";
import { htmlDecode } from "../../utils/convertHtml";
import { s3baseUrl } from "../../config/config";
import { styled } from "@mui/material/styles";
import {
  csvImage,
  docImage,
  pdfImage,
  wordImage,
  audioImage,
  xlsxImage,
  otherImage,
} from "src/assets";
import ProgrammeRecordingTab from "src/components/programmes/ProgrammeRecordingTab";
import ResourcesCardTab from "src/components/programmes/ResourcesCardTab";
import LessonNoteTab from "src/components/programmes/LessonNoteTab";
import CustomConfirmation from "src/components/menuIcons/CustomConfirmation";
import ReactVideoPlayer from "src/components/_dashboard/GeneralComponents/ReactVideoPlayer";
import MUICustomTabs from "src/components/GeneralComponents/MUICustomTabs";
const ProgrammesImgStyle = styled("img")({
  width: "100%",
  height: "100%",
  objectFit: "cover",
});

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

function LessonsDetail() {
  const params = useParams();
  const navigate = useNavigate();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [lessonsDetail, setLessonsDetail] = useState({});
  const [resourcesList, setResourcesList] = useState([]);
  const [recordingList, setRecordingList] = useState([]);
  const [nextLesson, setNextLesson] = useState({});
  const [previousLesson, setPreviousLesson] = useState({});
  const [notesList, setNotesList] = useState([]);
  const [completeLessonSlug, setCompleteLessonSlug] = useState("");
  const [openMarkComplete, setOpenMarkComplete] = useState(false);
  const [tabValue, setTabValue] = useState(0);

  const imageLinks = {
    docx: wordImage,
    mp3: audioImage,
    pdf: pdfImage,
    csv: csvImage,
    doc: docImage,
    xlsx: xlsxImage,
    xls: xlsxImage,
    other: otherImage,
  };

  //Changing tab values
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  //Getting lesson detail
  const getLesonDetail = async () => {
    const result = await lessonDetail(params.lesson_slug);
    if (result.code === 200) {
      console.log(result, "result for documents");
      setLessonsDetail(result.lesson);
      setResourcesList(result.document_list);
      let recording_array = [];
      result.recording_list?.map((recording) => {
        recording_array.push({
          ...recording,
          card_title: recording.title,
          card_image: s3baseUrl + recording.recording_image.thumbnail_2,
          card_short_description: recording.short_description,
          card_classes: "col-12 mt-3",
        });
      });
      setRecordingList(recording_array);
      setNotesList(result.notes_list);
      setNextLesson(result.next_lesson);
      setPreviousLesson(result.previous_lesson);
      setIsLoading(false);
    } else {
      // setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
      navigate(`/programmes`);
    }
  };

  const handleMarkComplete = (lesson_slug) => {
    setCompleteLessonSlug(lesson_slug);
    setOpenMarkComplete(true);
  };

  const mrakCompleted = async () => {
    setOpenMarkComplete(false);
    setIsLoading(true);
    const result = await markelessonCompleted(completeLessonSlug);
    if (result.code === 200) {
      getLesonDetail();
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const showPreviouLesson = () => {
    navigate(`/lessons/${previousLesson.lesson_slug}`);
  };
  const showNextLesson = () => {
    navigate(`/lessons/${nextLesson.lesson_slug}`);
  };

  useEffect(() => {
    getLesonDetail();
  }, [params.lesson_slug]);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  const TABS_OPTIONS = [
    {
      title: "Lessons",
      component: (
        <ProgrammeRecordingTab
          data={recordingList}
          lesson_slug={params.lesson_slug}
        />
      ),
    },
    {
      title: "Resources",
      component: (
        <ResourcesCardTab data={resourcesList} imageLink={imageLinks} />
      ),
    },
    // {
    //   title: "Notes",
    //   component: (
    //     <LessonNoteTab
    //       notesList={notesList}
    //       lesson_slug={params.lesson_slug}
    //       progran_slug={lessonsDetail.program[0]._id.program_slug}
    //       getLesonDetail={getLesonDetail}
    //     />
    //   ),
    // },
  ];

  return (
    <div className="container">
      <div className="row mobile-margin display-flex">
        <div className="col-12 lesson-detail-page">
          <IconButton
            className="back-screen-button"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon />
          </IconButton>
          {/* {lessonsDetail.lesson_completion_status === true ? (
            <button
              className="mark-contained-button float-end mt-1 disabled"
              disabled="disabled"
            >
              Completed
            </button>
          ) : (
            <button
              className="small-contained-button float-end mt-1"
              onClick={() => {
                handleMarkComplete(lessonsDetail.lesson_slug);
              }}
            >
              Mark Complete
            </button>
          )} */}
        </div>
      </div>

      <div className="row section-space">
        <div className="col-12">
          <h1 className="programmes-heading">
            {htmlDecode(lessonsDetail.title)}
          </h1>
        </div>
      </div>
      <div className="row media-margin">
        <div className="col-12">
          {lessonsDetail.landing_lesson_video_url ? (
            <ReactVideoPlayer url={lessonsDetail.landing_lesson_video_url} />
          ) : (
            <ProgrammesImgStyle
              src={s3baseUrl + lessonsDetail.lesson_images.thumbnail_1}
            />
          )}
        </div>
        <div className="col-12 section-space">
          {Object.entries(previousLesson).length > 0 && (
            <button
              className="small-contained-button"
              onClick={showPreviouLesson}
            >
              {"<<"} Previous
            </button>
          )}
          {Object.entries(nextLesson).length > 0 && (
            <button
              className="small-contained-button float-end"
              onClick={showNextLesson}
            >
              Next {">>"}
            </button>
          )}
        </div>

        {lessonsDetail.audio_file && (
          <div className="col-12 mt-3 text-center">
            <div>
              <audio
                controlsList="nodownload"
                className="w-100"
                src={s3baseUrl + lessonsDetail.audio_file}
                controls
              />
            </div>
          </div>
        )}
        <div className="col-12 section-space set-image-center">
          <div
            className="lesson_detailed_description"
            dangerouslySetInnerHTML={{
              __html: lessonsDetail.detailed_description,
            }}
          ></div>
        </div>
        <div className="col-12 section-space">
          <MUICustomTabs
            data={TABS_OPTIONS}
            value={tabValue}
            handleChange={handleChange}
          />
        </div>
      </div>
      <CustomConfirmation
        open={openMarkComplete}
        setOpen={setOpenMarkComplete}
        title={"Are you sure you want to mark this lesson as completed?"}
        handleAgree={mrakCompleted}
      />
    </div>
  );
}

export default LessonsDetail;
