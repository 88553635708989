import React from "react";
import { useParams } from "react-router-dom";

export default function AddedEmbedCode() {
  const { s_id } = useParams();
  return (
    <iframe
      src={`http://localhost:1312/live-stream-embed/${s_id}`}
      width="560"
      height="315"
      allow="autoplay; fullscreen"
      allowFullScreen
    ></iframe>
  );
}
