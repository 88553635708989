import React from "react";
import {
  _check_chat_existing,
  _get_chat_detail,
  _get_chat_message,
} from "src/DAL/Chat/Chat";
import { useContentSetting } from "src/Hooks/ContentSettingState";
import { is_small_screen } from "src/utils/constants";
import ChatRight from "../components/Chat-Right/ChatRight";
import PageDescription from "src/components/GeneralComponents/PageDescription";
import { useChat } from "src/Hooks/ChatContext/ChatContext";
import ChatList from "../components/ChatList";
import BroadCastChatList from "./BroadCastChatList";
import BroadcastChatRight from "./BroadcastChatRight";
import { useState } from "react";
import { broadcast } from "src/assets";

function BroadCastChat() {
  const { userInfo, isAccess } = useContentSetting();
  const { selectedChat } = useChat();
  const [drawerState, setDrawerState] = useState(false);
  const [broadcast, setBroadcast] = React.useState();
  const [broadcastsEdit, setBroadcastEdit] = React.useState(false);
  const [count, setCount] = React.useState(0);

  let is_chat_allowed = true;
  if (isAccess.is_chat_allowed) {
    is_chat_allowed = true;
  }

  return (
    <>
      <div className="container">
        <PageDescription parameter="" else_title="Broadcast" />
      </div>
      <div
        className={
          is_small_screen()
            ? "row w-100 chat-section m-0 mt-2"
            : "row w-100 chat-section mt-2"
        }
      >
        {/* Left Part */}
        {(!is_small_screen() || (is_small_screen() && !selectedChat?._id)) && (
          <BroadCastChatList
            is_chat_allowed={is_chat_allowed}
            drawerState={drawerState}
            setDrawerState={setDrawerState}
            setBroadcast={setBroadcast}
            broadcasts={broadcast}
            broadcastsEdit={broadcastsEdit}
            count={count}
          />
        )}

        {/* Right part */}
        {(!is_small_screen() || (is_small_screen() && broadcast?._id)) && (
          <div className="col-12 col-md-8 chat-right-part p-2">
            <BroadcastChatRight
              drawerState={drawerState}
              setDrawerState={setDrawerState}
              broadcastMain={broadcast}
              setBroadcast={setBroadcast}
              setBroadcastEdit={setBroadcastEdit}
              setCount={setCount}
            />
          </div>
        )}
      </div>
    </>
  );
}

export default BroadCastChat;
