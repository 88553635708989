import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Chip, CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { SaleSectionList } from "src/DAL/salesLeads/SaleLeads";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function AllSales() {
  const navigate = useNavigate();
  const classes = useStyles();
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [deleteDoc, setDeleteDoc] = useState("");
  const [MarkDEfaultDoc, setMarkDEfaultDoc] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [ChangeStatusValue, setChangeStatusValue] = useState("");
  const [openChangeStatus, setopenChangeStatus] = useState(false);
  const [OpenMarkDefault, setOpenMarkDefault] = useState(false);
  const [anchorEl, setAnchorEl] = useState(false);
  const [inputs, setInputs] = useState({
    title: "",
    section_status: "",
  });
  const [totalPages, setTotalPages] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
    localStorage.setItem("sale_page_number", newPage);
  };
  const handleClickOpenStatus = (event) => {
    setAnchorEl(true);
  };
  {
    anchorEl, setAnchorEl, handleClickOpenStatus;
  }
  const getConsultantListing = async () => {
    const result = await SaleSectionList();
    if (result.code === 200) {
      setUserList(result.sections);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handleEdit = (value) => {
    navigate(`/all-sales/edit-sales/${value._id}`, {
      state: value,
    });
  };
  const handleChangeStatus = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleNavigateSakesCount = (value) => {
    navigate(`/all-leads/${value._id}`, {
      state: value,
    });
  };
  const handleNavigate = () => {
    navigate(`/all-sales/add-sales`);
  };

  const TABLE_HEAD = [
    { id: "number", label: "#", alignRight: false, type: "number" },
    {
      id: "title",
      label: "Title",
      alignRight: false,
      renderData: (row) => {
        return (
          <>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => handleNavigateSakesCount(row)}
            >
              <div>{row.title}</div>
            </div>
          </>
        );
      },
    },
    {
      id: "sales_lead_count",
      label: "Sale Lead Count",
      alignRight: false,
      renderData: (row) => {
        return (
          <>
            <div
              className="text-center"
              onClick={() => handleNavigateSakesCount(row)}
              style={{ marginRight: "80px" }}
            >
              <div className="text-center count-color">
                {row.sales_lead_count}
              </div>
            </div>
          </>
        );
      },
    },
    // {
    //   id: "short_description",
    //   label: "Short Description",
    //   alignRight: false,
    //   type: "html",
    // },

    {
      id: "sale_section_type",
      label: "Sale Type",
      alignRight: false,
      renderData: (row) => {
        return (
          <>
            <div
              className={
                row.sale_section_type == "paid"
                  ? "paid"
                  : row.sale_section_type == "free"
                  ? "free"
                  : "normal"
              }
            >
              {row?.sale_section_type}
            </div>
          </>
        );
      },
    },
    // { id: "order", label: "Order", alignRight: false },
    // { id: "status", label: "Status", alignRight: false, type: "row_status" },
    // { id: "action", label: "Action", alignRight: false, type: "action" },
  ];

  useEffect(() => {
    getConsultantListing();
  }, []);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <>
      {/* <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleDelete}
      />
      <CustomConfirmation
        open={OpenMarkDefault}
        setOpen={setOpenMarkDefault}
        title={"Are you sure you want to make this section default?"}
        handleAgree={handleMarkDefaultApi}
      /> */}

      <div className="container">
        <div className="row mb-4">
          <div className="col-lg-8 col-sm-12">
            <h2>Sale Sections</h2>
          </div>
        </div>
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={userList}
          className="card-with-background"
          pageCount={pageCount}
          totalPages={totalPages}
          handleChangePages={handleChangePages}
          pagePagination={true}
        />
      </div>
    </>
  );
}
