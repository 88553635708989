import * as React from "react";
import { useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { IconButton, CircularProgress } from "@mui/material";
import { add_agreeement_configuration } from "src/DAL/WebsitePages/WebsitePages";
import { useEffect } from "react";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCkeditor";
import { detailPaymentPlanApi } from "src/DAL/WebsitePages/paymentPlan";
import { agreementConfigurationPaymentRequestApi } from "src/DAL/PaymentRequest/paymentRequest";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function PaymentRequestAgreementConfiguration() {
  const navigate = useNavigate();
  const classes = useStyles();
  const params = useParams();
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const [inputs, setInputs] = useState({
    is_sign_agreement: false,
    agreement_description: "",
    agreement_alert_description: "",
  });
  console.log(state, "statestate");

  const handleFormatdata = (data) => {
    setInputs({
      ...data,
    });
    setIsLoading(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (inputs.is_sign_agreement) {
      if (!inputs.agreement_description) {
        enqueueSnackbar("Agreement Description is required", {
          variant: "error",
        });
        return;
      } else if (!inputs.agreement_alert_description) {
        enqueueSnackbar("Agreement Alert Description is required", {
          variant: "error",
        });
        return;
      }
    }
    setIsLoadingForm(true);
    let postData = {
      agreement_config: {
        is_sign_agreement: inputs.is_sign_agreement,
        agreement_description: inputs.agreement_description,
        agreement_alert_description: inputs.agreement_alert_description,
      },
    };
    console.log(postData, "postDatapostDatapostData");
    const result = await agreementConfigurationPaymentRequestApi(
      postData,
      state.payment_request_slug
    );
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoadingForm(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingForm(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  useEffect(() => {
    if (state?.object?.agreement_config) {
      handleFormatdata(state?.object?.agreement_config);
    }
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      <div className="row mobile-margin display-flex">
        <div className="col-12">
          <IconButton
            className="back-screen-button mb-4"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon />
          </IconButton>
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <h2>Agreement Configuration</h2>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">
                Show Agreement page
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="is_sign_agreement"
                value={inputs.is_sign_agreement}
                label="Show Agreement page"
                onChange={handleChange}
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </div>
          {inputs.is_sign_agreement == true && (
            <>
              <div className="col-12 mt-4">
                <h4>Agreement Description *</h4>
                <GeneralCkeditor
                  setInputs={setInputs}
                  inputs={inputs}
                  name="agreement_description"
                  editorHeight={320}
                />
              </div>
              <div className="col-12 mt-4">
                <h4>Agreement Alert Description *</h4>
                <GeneralCkeditor
                  setInputs={setInputs}
                  inputs={inputs}
                  name="agreement_alert_description"
                  editorHeight={320}
                />
              </div>
            </>
          )}

          <div className="text-end mt-4">
            <button className="small-contained-button">
              {isLoadingForm ? "Updating..." : "Update"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
