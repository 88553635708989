import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { IconButton, CircularProgress } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { s3baseUrl } from "../../config/config";
import ReactVideoPlayer from "src/components/ReactVideoPlayer";
import { useSnackbar } from "notistack";
import {
  ProgramRecordingDetail,
  LessonRecordingDetail,
} from "../../DAL/Programmes/Programmes";
import { makeStyles } from "@mui/styles";
import { htmlDecode } from "src/utils/convertHtml";
import ChatCardLesson from "src/components/LiveChat/ChatCardLesson";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
function ProgrammRecordingDetails(props) {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [recordingData, seRecordingData] = React.useState({});
  const [nextRecording, setNextRecording] = useState({});
  const [previousRecording, setPreviousRecording] = useState({});
  // chat varibales
  const [eventDetail, setEventDetail] = useState();
  const [liveChat, setLiveChat] = useState([]);
  const [pinnedComments, setPinnedComments] = useState([]);
  const [chatMessage, setChatMessage] = useState("");
  const [image, setImage] = React.useState("");
  const [oldImage, setOldImage] = React.useState("");
  const [previews, setPreviews] = useState("");
  const [selectedComment, setSelectedComment] = useState({});
  const [formType, setFormType] = useState("ADD");
  const [openDelete, setOpenDelete] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [newChat, setNewChat] = useState(false);
  const [videoDuration, setVideoDuration] = useState(0);
  const [videoPlayed, setVideoPlayed] = useState(0);
  const [videoCompletedStatus, setVideoCompletedStatus] = useState(false);

  const handleNavigate = () => {
    navigate(-1);
  };

  const getRecordingDetail = async () => {
    setIsLoading(true);
    let result = {};
    if (location.pathname.includes("lessons-recordings") == true) {
      result = await LessonRecordingDetail(params.recording_slug);
    } else {
      result = await ProgramRecordingDetail(params.recording_slug);
    }

    if (result.code === 200) {
      seRecordingData(result.recording);
      setNextRecording(result.next_recording);
      setPreviousRecording(result.previous_recording);

      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      navigate(`/programmes`);
    }
  };
  const showPreviousRecording = () => {
    navigate(`/lessons-recordings/${previousRecording.recording_slug}`);
  };
  const showNextRecording = () => {
    navigate(`/lessons-recordings/${nextRecording.recording_slug}`);
  };

  useEffect(() => {
    getRecordingDetail();
  }, [params.recording_slug]);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <div className="container">
      <div className="row mobile-margin display-flex">
        <div className="col-12">
          <IconButton
            className="back-screen-button"
            onClick={() => handleNavigate()}
          >
            <ArrowBackIcon />
          </IconButton>
        </div>
      </div>

      <div className="row section-space">
        <div className="col-12">
          <h1 className="programmes-heading">
            {htmlDecode(recordingData.title)}
          </h1>
        </div>
      </div>
      <div className="row media-margin">
        <div className="col-12">
          <ReactVideoPlayer url={recordingData.video_url} />
        </div>
        <div className="col-12 section-space">
          {Object.entries(previousRecording).length > 0 && (
            <button
              className="small-contained-button"
              onClick={showPreviousRecording}
            >
              {"<<"} Previous
            </button>
          )}
          {Object.entries(nextRecording).length > 0 && (
            <button
              className="small-contained-button float-end"
              onClick={showNextRecording}
            >
              Next {">>"}
            </button>
          )}
        </div>

        {recordingData.audio_recording && (
          <div className="col-12 mt-3 text-center">
            <div>
              <audio
                controlsList="nodownload"
                className="w-100"
                src={s3baseUrl + recordingData.audio_recording}
                controls
              />
            </div>
          </div>
        )}
        <div className="col-12 section-space">
          <p>{htmlDecode(recordingData.short_description)}</p>
        </div>
      </div>
      {/* <ChatCardLesson
        eventDetail={eventDetail}
        pinnedComments={pinnedComments}
        handleCommentDelete={handleCommentDelete}
        handleCommentEdit={handleCommentEdit}
        handleCommentPin={handleCommentPin}
        liveChat={liveChat}
        handleOpenReplyBox={handleOpenReplyBox}
        newChat={newChat}
        setNewChat={setNewChat}
        setShowScrollArrow={setShowScrollArrow}
        showScrollArrow={showScrollArrow}
        isReply={isReply}
        chatMessage={chatMessage}
        setChatMessage={setChatMessage}
        formType={formType}
        handleRemoveAll={handleRemoveAll}
        previews={previews}
        handleRemove={handleRemove}
        handleUpload={handleUpload}
        isSubmitting={isSubmitting}
        handleSendMessage={handleSendMessage}
        handleMessageUpdate={handleMessageUpdate}
        setIsLoading={setIsLoading}
      /> */}
    </div>
  );
}

export default ProgrammRecordingDetails;
