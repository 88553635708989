import { invokeApi } from "../../bl_libs/invokeApi";
export const SaleSectionList = async (data) => {
  const requestObj = {
    path: `api/sales_section/sales_section_list_for_delegate`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const pagesListingApi = async (type) => {
  const requestObj = {
    path: `api/sale_page?type=general`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const pagesListingWithPlanapi = async (type) => {
  const requestObj = {
    path: `api/sale_page/with_plans/list`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const saleSectionLeadsListApi = async (data, loadMore) => {
  const requestObj = {
    path: `${loadMore ? loadMore : "api/sales_section/all_leads/delegates"}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data ? data : "all",
  };
  return invokeApi(requestObj);
};
export const saleLeadDateChange = async (postData) => {
  const requestObj = {
    path: `api/sales_section/update_lead_date`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: postData,
  };
  return invokeApi(requestObj);
};
export const addAssociateApi = async (data) => {
  const requestObj = {
    path: `api/sales_section/add_other_associate`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const consultantActiveListing = async (data) => {
  const requestObj = {
    path: `api/consultant/active_consultant_list`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const saleLeadMarkCompleteApi = async (data) => {
  const requestObj = {
    path: `api/sales_section/change_lead_status`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
