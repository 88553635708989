import React from 'react';

function SupportTicket(props) {
  return (
    <div>
      <h1>Support Ticket</h1>
    </div>
  );
}

export default SupportTicket;
