import { Avatar, AvatarGroup } from "@mui/material";
import React from "react";
import { communityHeart } from "src/assets";
import { s3baseUrl } from "src/config/config";
import { get_kmb_number } from "src/utils/constants";

export default function ActionsCount({
  handleOpenLikedBox,
  likeCount,
  topLikedUser,
  commentsCount,
  handleClick,
  commentCountUpdated,
}) {
  return (
    <div className="my-1 comment-icon d-flex justify-content-between clear-both">
      <div className="svg-color comment-icons">
        <div
          className="likes-post d-flex ps-0 ps-md-2"
          onClick={handleOpenLikedBox}
        >
          {likeCount > 0 && (
            <>
              <img src={communityHeart} alt="" />
              <p className="ms-1 mb-0">
                {get_kmb_number(likeCount)} {likeCount > 1 ? "Likes" : "Like"}
              </p>
              {/* &nbsp;
              {topLikedUser.length > 0 && (
                <AvatarGroup max={2}>
                  {topLikedUser &&
                    topLikedUser.map((liked_user, index) => {
                      return (
                        <Avatar
                          alt={liked_user.user_info_action_by.name[0]}
                          src={
                            s3baseUrl +
                            liked_user.user_info_action_by.profile_image
                          }
                          key={index}
                        />
                      );
                    })}
                </AvatarGroup>
              )} */}
              {/* {likeCount > 2 && <span>&nbsp;and {likeCount - 2} others</span>} */}
            </>
          )}
        </div>
      </div>
      {commentsCount > 0 && (
        <div
          className="cursor-pointer"
          onClick={() => {
            if (handleClick) {
              handleClick();
            }
          }}
        >
          {commentsCount > 0 && <span> {commentsCount} comments</span>}
        </div>
      )}
    </div>
  );
}
