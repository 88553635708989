import { Tooltip } from "@mui/material";
import moment from "moment";
import RecordNotFound from "../RecordNotFound";
import { useNavigate } from "react-router-dom";
import {
  TIME_ZONE_CONVERSION,
  get_date_with_user_time_zone,
} from "src/utils/constants";
import { useContentSetting } from "src/Hooks/ContentSettingState";

const MonthlyINtentions = ({ detailData }) => {
  const navigate = useNavigate();
  const { userInfo, adminTimeZone } = useContentSetting();
  const handleNavigate = (item) => {
    item.tabVlaue = 4;
    navigate(`/accountability-tracker-report/delegate/${item.user}`, {
      state: item,
    });
  };

  return (
    <>
      {detailData.daily_dynamite && (
        <h4 class="main-heading mt-3">
          {`Accountability Analysis form ${detailData.date_from} to ${detailData.date_to}`}
        </h4>
      )}
      <div className="container">
        <div className="row mt-3 dynamite-diary">
          {detailData.daily_dynamite && detailData.daily_dynamite.length > 0 ? (
            detailData.daily_dynamite.map((value, index) => {
              return (
                <>
                  <Tooltip title="View Detail">
                    <div
                      className="col-lg-6 mb-3"
                      style={{ cursor: "pointer" }}
                    >
                      <div
                        className="mini-cards d-flex"
                        onClick={() => handleNavigate(value)}
                      >
                        <div className="w-100 ms-2 me-2">
                          <div className="diary-font-weight d-flex">
                            <h4>{value?.date ? value?.date : ""}</h4>
                            <div className="d-flex">
                              <span
                                style={{ paddingTop: "2px" }}
                                className="diary-first-heading pe-2"
                              >
                                {value?.date_time
                                  ? TIME_ZONE_CONVERSION(
                                      value.date_time,
                                      "hh:mm A",
                                      userInfo.time_zone,
                                      adminTimeZone
                                    )
                                  : ""}
                              </span>
                            </div>
                          </div>

                          <div className="d-flex justify-content-between ml-3 mt-2 diary-first-heading">
                            <div>
                              <h4 style={{ fontSize: "14px" }}>
                                {value.statement_array[0]?.option}
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tooltip>
                </>
              );
            })
          ) : (
            <RecordNotFound title={"Activities"} />
          )}
        </div>
      </div>
    </>
  );
};

export default MonthlyINtentions;
