import { invokeApi } from "../../bl_libs/invokeApi";
export const lessonQuestionListingApi = async (created_for, created_for_id) => {
  const requestObj = {
    path: `api/questionnaire/list/by_module?created_for=${created_for}&created_for_id=${
      created_for_id ? created_for_id : ""
    }`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const quotes_detail_api = async (quotation_id) => {
  const requestObj = {
    path: `api/quotation/${quotation_id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const deleteLessonQuestion = async (id) => {
  const requestObj = {
    path: `api/questionnaire/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const addLessonQuestionApi = async (data) => {
  const requestObj = {
    path: `api/questionnaire`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const editLessonQuestionApi = async (data, id) => {
  const requestObj = {
    path: `api/questionnaire/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const all_questions_listing_api = async (page, limit, data) => {
  const requestObj = {
    path: `api/questionnaire/list_question_reply/member_for_delegate?page=${page}&limit=${limit}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const list_member_replies_against_module = async (page, limit, data) => {
  const requestObj = {
    path: `api/questionnaire/list_member_replies_against_module?page=${page}&limit=${limit}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const list_member_replies_against_module_events = async (
  page,
  limit,
  data
) => {
  const requestObj = {
    path: `api/questionnaire/list_member_replies_against_module?page=${page}&limit=${limit}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const list_member_replies_against_member = async (
  page,
  limit,
  member_id
) => {
  const requestObj = {
    path: `api/questionnaire/list_member_question_and_answers/${member_id}?page=${page}&limit=${limit}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const answers_detail_by_user_and_question_api = async (data) => {
  const requestObj = {
    path: `api/questionnaire/list_question_reply_against/lesson`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const questions_listing_api = async (data) => {
  const requestObj = {
    path: `api/questionnaire/get_configration`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const add_answers_api = async (data) => {
  const requestObj = {
    path: `api/questionnaire/add_comment_on_questionare`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const upload_document_api = async (data) => {
  const requestObj = {
    path: `app/upload_csv_file_on_s3`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
