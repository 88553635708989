import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextareaAutosize,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import {
  active_booking_status_api,
  update_booking_status_api,
} from "src/DAL/Booking/bookingApi";
import MUIAutocomplete from "src/components/GeneralComponents/MUIAutocomplete";
import CustomConfirmation from "../GeneralComponents/CustomConfirmation";
import GeneralCkeditor from "../GeneralComponents/GeneralCkeditor";

export default function ChangeBookingStatus({
  selectedObject,
  setOpen,
  bookingsData,
}) {
  const [inputs, setInputs] = useState({
    booking_status_info: null,
    note: `<b>BIO:</b><span>    <br><b>GOAL:</b> <br><b>PARADIGM:</b> <br><b>SOLUTION:</b>`,
    add_as_personal_note: false,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [bookingStatus, setBookingStatus] = useState([]);
  const [openStatus, setOpenStatus] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleChangeOthers = (name, value) => {
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleChangeStatus = async () => {
    setOpenStatus(false);
    setIsLoading(true);
    const postData = {
      status: inputs.booking_status_info,
      note: inputs.note,
      add_as_personal_note: inputs.add_as_personal_note,
    };
    const result = await update_booking_status_api(
      selectedObject._id,
      postData
    );
    if (result.code === 200) {
      bookingsData();
      setOpen(false);
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const get_active_booking_status = async () => {
    const result = await active_booking_status_api();
    if (result.code === 200) {
      setBookingStatus(result.active_booking_status);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setOpenStatus(true);
  };

  useEffect(() => {
    get_active_booking_status();
    setInputs((inputs) => ({
      ...inputs,
      booking_status_info: selectedObject.booking_status_info,
      note: `<span style="font-weight: bold;">BIO:</span> <br><span style="font-weight: bold;">GOAL:</span> <br><span style="font-weight: bold;">PARADIGM:</span> <br><span style="font-weight: bold;">SOLUTION:</span> <br>`,

      add_as_personal_note: false,
    }));
  }, [selectedObject]);
  console.log(inputs, "inputsinputsinputs");
  return (
    <div className="container">
      <form className="row" onSubmit={handleSubmit}>
        <div className="col-lg-12 col-md-6 col-sm-12 mt-3">
          <MUIAutocomplete
            inputLabel="Booking Status"
            selectedOption={inputs.booking_status_info}
            setSelectedOption={(v) => {
              handleChangeOthers("booking_status_info", v);
            }}
            optionsList={bookingStatus}
            autoComplete="new-password"
            name="title"
          />
        </div>
        <div className="col-12 mt-3">
          <FormControl fullWidth required>
            <InputLabel id="demo-simple-select-label">
              Would you like to add it to Personal Notes?
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="add_as_personal_note"
              value={inputs.add_as_personal_note}
              label="Would you like to add it to Personal Notes?"
              onChange={(event) =>
                handleChangeOthers("add_as_personal_note", event.target.value)
              }
            >
              <MenuItem value={true}>Yes</MenuItem>
              <MenuItem value={false}>No</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="col-12 ">
          <p className="tracking_code_heading text-white">Note </p>
          <GeneralCkeditor
            setInputs={setInputs}
            inputs={inputs}
            name="note"
            editorHeight={320}
          />
        </div>
        <CustomConfirmation
          open={openStatus}
          setOpen={setOpenStatus}
          title={"Are you sure you want to update booking status?"}
          handleAgree={handleChangeStatus}
        />
        <div class="col-12 mt-3">
          <button class="small-contained-button w-100">
            {isLoading ? "Updating..." : "Update"}
          </button>
        </div>
      </form>
    </div>
  );
}
