import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import AffiliateTransactions from "./AffiliateTransactions";

export default function CommissionTabs() {
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", typography: "body1", marginTop: "10px" }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Credit" value="1" />
            <Tab label="Paid" value="2" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <AffiliateTransactions type="credit" />
        </TabPanel>
        <TabPanel value="2">
          <AffiliateTransactions type="paid" />
        </TabPanel>
      </TabContext>
    </Box>
  );
}
