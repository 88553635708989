import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import {
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { s3baseUrl } from "../../config/config";
import CustomConfirmation from "src/components/menuIcons/CustomConfirmation";
import { DetailPodsApi } from "src/DAL/Pods/Pods";
import RecordNotFound from "src/components/RecordNotFound";
import { htmlDecode } from "src/utils/convertHtml";
import { DelegatePodsListing } from "src/DAL/delegatePods/delegatePods";
import SearchIcon from "@mui/icons-material/Search";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const NewPodsRoomCards = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [podsData, setPodsData] = useState([]);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [deleteValue, setDeleteValue] = useState("");
  const [query, setQuery] = useState("");
  const handleChangeSearch = (e) => {
    setQuery(e.target.value);
    localStorage.setItem("dynamite_pods", e.target.value);
  };
  const getFilteredData = (query) => {
    let dataToFilter = [...podsData];
    if (query) {
      let resultFilter = dataToFilter.filter((post) =>
        post.title.toLowerCase().includes(query.toLowerCase())
      );
      return resultFilter;
    } else {
      return dataToFilter;
    }
  };
  const getPodsListing = async () => {
    setIsLoading(true);
    const result = await DelegatePodsListing();
    if (result.code === 200) {
      setPodsData(result.room);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handleEdit = (value) => {
    navigate(`/pods/edit-pod/${value?.room_slug}`, {
      state: { editValues: value },
    });
  };

  const handleNavigateDetail = (value) => {
    navigate(`/dynamite-pods/dynamite-pods-details/${value?.room_slug}`, {
      state: { detailValues: value },
    });
  };
  const handleAgreeDelete = (value) => {
    setDeleteValue(value.room_slug);
    setOpenDelete(true);
    handleCloseMenu();
  };
  const handleNavigate = () => {
    navigate(`/pods/add-pod`);
  };

  //Deleting Recording
  const handleDelete = async () => {
    setOpenDelete(false);

    const result = await DetailPodsApi(deleteValue);
    if (result.code === 200) {
      getPodsListing();
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },

    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  useEffect(() => {
    getPodsListing();
    const search_text = localStorage.getItem("dynamite_pods");
    if (search_text) {
      setQuery(search_text);
    }
  }, []);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <div className="container">
      <div className="row">
        <div className="col-8">
          <h2>Dynamite Pods </h2>
        </div>{" "}
        <div className="col-4">
          <div>
            <FormControl variant="outlined" style={{ marginTop: "2px" }}>
              <InputLabel htmlFor="outlined-adornment-password">
                Search
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type="text"
                variant="small"
                onChange={handleChangeSearch}
                value={query}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      edge="end"
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                }
                label="Search"
              />
            </FormControl>
          </div>
        </div>
        {/* <div className="col-3 text-end">
          <button onClick={handleNavigate} className="small-contained-button">
            Add Pod
          </button>
        </div> */}
        {getFilteredData(query)?.length == 0 ? (
          <RecordNotFound title="Pods" />
        ) : (
          getFilteredData(query)?.map((value, index) => {
            return (
              <div className="col-lg-4 col-md-6 col-sm-12 mb-4" key={index}>
                <div className="card mt-4 pods-cards-shadow cursor h-100">
                  <img
                    src={s3baseUrl + value.room_image.thumbnail_2}
                    className="card-img-top pods-image"
                    alt="Pods"
                    onClick={() => handleNavigateDetail(value)}
                  />
                  <div className="card-body">
                    <div className="row">
                      <div
                        className="col-10"
                        onClick={() => handleNavigateDetail(value)}
                      >
                        <h3 className="h2-heading">
                          {htmlDecode(value.title)}
                        </h3>
                      </div>
                      <div className="col-2 menu-option"></div>
                      <CustomConfirmation
                        open={openDelete}
                        setOpen={setOpenDelete}
                        title={"Are you sure you want to delete this pod?"}
                        handleAgree={handleDelete}
                      />
                    </div>
                    <p className="programme-card-desc mb-3">
                      {htmlDecode(value.short_description)}
                    </p>
                  </div>
                </div>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

export default NewPodsRoomCards;
