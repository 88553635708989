import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import { useTheme } from "@mui/material/styles";
import FormHelperText from "@mui/material/FormHelperText";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";

import { useNavigate, useParams } from "react-router-dom";
import { IconButton, CircularProgress } from "@mui/material";
import {
  detailContentPageApi,
  updatePageContentSettingApi,
} from "src/DAL/WebsitePages/WebsitePages";
import { uploadImageOns3 } from "src/DAL/commonApi/commonApi";
import { result } from "lodash";
import { s3baseUrl } from "src/config/config";
import { useEffect } from "react";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCkeditor";
import { handleImageExtensions } from "src/utils/constants";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const Input = styled("input")({
  display: "none",
});

export default function UpdatePageContent() {
  const navigate = useNavigate();
  const classes = useStyles();
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [isAccess, setIsAccess] = useState(true);
  const [isAllAccess, setIsAllAccess] = useState(true);
  const [templateFields, setTemplateFields] = useState([]);
  const [salePageData, setSalePageData] = useState({});
  const [selectedAccess, setSelectedAccess] = useState([]);
  const [templateFieldsData, setTemplateFieldsData] = useState([]);
  const [inputs, setInputs] = useState({});

  const getTemplateFieldList = async () => {
    setIsLoading(true);
    const result = await detailContentPageApi(params.id);
    if (result.code === 200) {
      setSalePageData(result.sale_page);
      setTemplateFields(result?.sale_page?.template?.template_attributes_info);
      setSelectedAccess(result?.sale_page?.template_attributes_info);
      setTemplateFieldsData(result?.sale_page?.sale_page_detail);
      setIsAllAccess(result?.sale_page?.is_content_access);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const fileChangedHandler = async (e, i) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("image", e.target.files[0]);
    formData.append("width", i.width);
    formData.append("height", i.height);

    const imageUpload = await uploadImageOns3(formData);
    if (imageUpload.code == 200) {
      setInputs({
        ...inputs,
        [e.target.name]: imageUpload.image_path,
      });
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const fileChangedHandlerUpdate = async (e, i) => {
    setIsLoading(true);
    const name = e.target.name;
    const formData = new FormData();

    formData.append("image", e.target.files[0]);
    formData.append("width", i.width);
    formData.append("height", i.height);

    const imageUpload = await uploadImageOns3(formData);
    if (imageUpload.code == 200) {
      setTemplateFieldsData((prevState) => ({
        ...prevState,
        [name]: imageUpload.image_path,
      }));
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let content_info = {};
    templateFields.map((item) => {
      if (templateFieldsData && templateFieldsData[item.attribute_db_name]) {
        content_info[item.attribute_db_name] = templateFieldsData[
          item.attribute_db_name
        ]
          ? templateFieldsData[item.attribute_db_name]
          : null;
      } else {
        content_info[item.attribute_db_name] = null;
      }
    });

    let postData = {
      template_attributes_info: isAllAccess === false ? [] : selectedAccess,
      content_info,
      is_content_access: isAllAccess,
    };

    const result = await updatePageContentSettingApi(postData, params.id);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      navigate(-1);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChange = (event, i) => {
    const name = event.target.name;
    const value = event.target.value;
    setTemplateFieldsData((values) => ({ ...values, [name]: value }));
  };

  const handleChangeSet = (event, i) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  useEffect(() => {
    getTemplateFieldList();
  }, []);

  const is_content_disabled = (template_id, template_access) => {
    let disabled = true;
    isAllAccess === false
      ? (disabled = true)
      : selectedAccess?.indexOf(template_id) !== -1
      ? (disabled = false)
      : template_access === false
      ? (disabled = true)
      : (disabled = false);
    return disabled;
  };

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  console.log(templateFields, "templateFields");

  return (
    <div className="container">
      <div className="row mobile-margin display-flex">
        <div className="col-12 display-flex mb-4">
          <IconButton
            className="back-screen-button"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon />
          </IconButton>
          <span className="sale-page-title">
            {salePageData.sale_page_title}
          </span>
        </div>
      </div>

      <div className="row">
        <div className="col-12 d-flex justify-content-between">
          <h2>Update Content</h2>
        </div>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="row">
          {templateFields.map((template, i) => {
            return (
              <>
                {template.attribute_type == "input" &&
                is_content_disabled(template._id, template.is_access) ===
                  false ? (
                  <div className="col-lg-6 col-md-6 col-sm-12 mt-4 content-disabled">
                    <TextField
                      id="outlined-basic"
                      label={template?.attribute_label}
                      variant="outlined"
                      fullWidth
                      required={template.required == true ? true : false}
                      name={template?.attribute_db_name}
                      value={
                        templateFieldsData !== undefined
                          ? templateFieldsData[template?.attribute_db_name]
                          : inputs.template?.attribute_db_name
                      }
                      onChange={
                        templateFieldsData == undefined
                          ? (e) => handleChangeSet(e, i)
                          : (e) => handleChange(e, i)
                      }
                    />
                  </div>
                ) : template.attribute_type == "file" &&
                  is_content_disabled(template._id, template.is_access) ===
                    false ? (
                  <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
                    <div className="row w-100 div-style ms-0 pt-0">
                      <div className="col-5">
                        <p className="">{template.attribute_label}</p>
                      </div>
                      <div className="col-2">
                        {templateFieldsData !== undefined &&
                        templateFieldsData[template?.attribute_db_name] ? (
                          <img
                            src={
                              s3baseUrl +
                              templateFieldsData[template?.attribute_db_name]
                            }
                            height="50"
                          />
                        ) : inputs[template.attribute_db_name] !== undefined ? (
                          <img
                            src={s3baseUrl + inputs[template.attribute_db_name]}
                            height="50"
                          />
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="col-5 text-end pt-2">
                        <label htmlFor={template.attribute_db_name}>
                          <Input
                            accept="image/*"
                            id={template.attribute_db_name}
                            multiple
                            type="file"
                            name={template.attribute_db_name}
                            onChange={
                              templateFieldsData == undefined
                                ? (e) => fileChangedHandler(e, template)
                                : (e) => fileChangedHandlerUpdate(e, template)
                            }
                          />
                          <Button
                            className="small-contained-button"
                            startIcon={<FileUploadIcon />}
                            component="span"
                          >
                            Upload
                          </Button>
                        </label>
                      </div>
                      {inputs[template.attribute_db_name]}
                    </div>
                    <p className="text-muted mb-0">
                      {"Recommended size (" +
                        template.width +
                        "px * " +
                        template.height +
                        "px ) "}
                      {"( " +
                        handleImageExtensions(template.image_extension) +
                        " )"}
                    </p>
                  </div>
                ) : template.attribute_type == "editor" &&
                  is_content_disabled(template._id, template.is_access) ===
                    false ? (
                  <div className="col-12 mt-4">
                    <div className="d-flex justify-content-between">
                      <h4 className="mb-2">
                        {template?.attribute_label}
                        {template.required == true ? "*" : ""}
                      </h4>
                    </div>
                    <GeneralCkeditor
                      setInputs={setTemplateFieldsData}
                      inputs={templateFieldsData}
                      name={template.attribute_db_name}
                      editorHeight={320}
                    />
                  </div>
                ) : (
                  ""
                )}
              </>
            );
          })}

          <div className="text-end mt-4">
            <button className="small-contained-button">Submit</button>
          </div>
        </div>
      </form>
    </div>
  );
}
