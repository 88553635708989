import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import {
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { _list_of_member_for_chat_against_event } from "src/DAL/LiveEvents/LiveEvents";
import { s3baseUrl } from "src/config/config";
import CustomMUICard from "src/components/GeneralComponents/CustomMUICard";
import RecordNotFound from "src/components/GeneralComponents/RecordNotFound";
import { live_events_consultant_admin_list_api } from "src/DAL/LiveEvents/LiveEventsPortal";
import CustomConfirmation from "src/components/menuIcons/CustomConfirmation";
import {
  delete_dynamite_event_api,
  duplicate_dynamite_event_api,
} from "src/DAL/LiveEvents/DynamiteEvents";
import CustomDrawer from "src/components/GeneralComponents/CustomDrawer";
import MemberImportFromProgram from "./components/MemberImportFromProgram";
import SearchIcon from "@mui/icons-material/Search";

// ----------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

// ----------------------------------------------------------------------

export default function MyLiveEvents() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [eventsList, setEventsList] = useState({});
  const [selectedObject, setSelectedObject] = useState({});
  const [openDelete, setOpenDelete] = useState(false);
  const [openDuplicate, setOpenDuplicate] = useState(false);
  const [importMemberDrawerState, setImportMemberDrawerState] = useState(false);
  const [query, setQuery] = useState("");
  const handleChangeSearch = (e) => {
    setQuery(e.target.value);
    localStorage.setItem("my_portals", e.target.value);
  };
  const getFilteredData = (query) => {
    let dataToFilter = [...eventsList];
    if (query) {
      let resultFilter = dataToFilter.filter((post) =>
        post.title.toLowerCase().includes(query.toLowerCase())
      );
      return resultFilter;
    } else {
      return dataToFilter;
    }
  };
  const handleClick = (object) => {
    delete object.card_button;
    if (!object.locked) {
      navigate(`/my-events/${object._id}`);
    } else if (
      object.lock_configration &&
      object.lock_configration.lock_event_description !== ""
    ) {
      navigate(`/live-events-locked/${object._id}`, { state: object });
    }
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    const result = await delete_dynamite_event_api(selectedObject.event_slug);
    if (result.code === 200) {
      getLiveEventsList();
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleDuplicate = async () => {
    setOpenDuplicate(false);
    const result = await duplicate_dynamite_event_api(selectedObject._id);
    if (result.code === 200) {
      getLiveEventsList();
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const getLiveEventsList = async () => {
    const result = await live_events_consultant_admin_list_api("");
    if (result.code === 200) {
      let CARD_OPTIONS = [];
      result.dynamite_events.map((dynamite_event) => {
        CARD_OPTIONS.push({
          ...dynamite_event,
          card_title: dynamite_event.title,
          card_short_description: dynamite_event.short_description,
          card_image: s3baseUrl + dynamite_event.images.thumbnail_2,
          card_classes: "col-lg-4 col-md-6 col-sm-12 mt-4",
        });
      });
      setEventsList(CARD_OPTIONS);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleAddPortalNavigate = () => {
    navigate(`/my-events/add-events`);
  };

  const handleEdit = (value) => {
    navigate(`/my-events/add-events/${value.event_slug}`, {
      state: value,
    });
  };

  const handleChangeEventConfig = (value) => {
    navigate(`/my-events/configuration/${value.event_slug}`, {
      state: value,
    });
  };

  const handleAgreeDelete = (value) => {
    setSelectedObject(value);
    setOpenDelete(true);
  };

  const handleChangeLockContent = (value) => {
    navigate(`/my-events/lock-event-content/${value.event_slug}`, {
      state: value,
    });
  };

  const handleChangCategories = (value) => {
    navigate(`/dynamite-event-categories/${value._id}`);
  };

  const handleEvntMembers = (value) => {
    navigate(`/dynamite-events/${value._id}/members`);
  };

  const handleGeneralEvntMembers = (value) => {
    navigate(`/dynamite-events/${value._id}/general-events`);
  };

  const handleAgreeDuplicate = (value) => {
    setSelectedObject(value);
    setOpenDuplicate(true);
  };

  const handleOpenImportDrawer = (value) => {
    setSelectedObject(value);
    setImportMemberDrawerState(true);
  };

  const handleCloseImportDrawer = () => {
    setSelectedObject(null);
    setImportMemberDrawerState(false);
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
    {
      label: "Lock Event Content",
      icon: "clarity:lock-solid",
      handleClick: handleChangeLockContent,
    },
    {
      label: "Import Members",
      icon: "pajamas:import",
      handleClick: handleOpenImportDrawer,
    },
    {
      label: "Event Timer Configuration",
      icon: "fluent:timer-24-regular",
      handleClick: handleChangeEventConfig,
    },
    {
      label: "Categories",
      icon: "carbon:category-new-each",
      handleClick: handleChangCategories,
    },
    {
      label: "Members",
      icon: "prime:users",
      handleClick: handleEvntMembers,
    },
    {
      label: "Events",
      icon: "material-symbols-light:event-available-rounded",
      handleClick: handleGeneralEvntMembers,
    },
    {
      label: "Duplicate",
      icon: "mdi-light:content-duplicate",
      handleClick: handleAgreeDuplicate,
    },
  ];

  useEffect(() => {
    getLiveEventsList();
    const search_text = localStorage.getItem("my_portals");
    if (search_text) {
      setQuery(search_text);
    }
  }, []);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <h2>My Portals</h2>
          </div>
          <div
            className="col-lg-6 col-sm-12 text-end d-flex"
            style={{
              justifyContent: "end",
            }}
          >
            <div>
              <FormControl variant="outlined" style={{ marginTop: "2px" }}>
                <InputLabel htmlFor="outlined-adornment-password">
                  Search
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type="text"
                  variant="small"
                  onChange={handleChangeSearch}
                  value={query}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        edge="end"
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Search"
                />
              </FormControl>
            </div>

            <button
              onClick={handleAddPortalNavigate}
              className="small-contained-button"
              style={{
                marginTop: "5px",
                marginLeft: "6px",
              }}
            >
              Add Events
            </button>
          </div>
        </div>
        {getFilteredData(query).length > 0 ? (
          <CustomMUICard
            data={getFilteredData(query)}
            MENU_OPTIONS={MENU_OPTIONS}
            handleClick={handleClick}
          />
        ) : (
          <RecordNotFound title="Portals Not Found" />
        )}
      </div>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this event?"}
        handleAgree={handleDelete}
      />
      <CustomConfirmation
        open={openDuplicate}
        setOpen={setOpenDuplicate}
        title={"Are you sure you want to duplicate this event?"}
        handleAgree={handleDuplicate}
      />
      <CustomDrawer
        isOpenDrawer={importMemberDrawerState}
        onOpenDrawer={handleOpenImportDrawer}
        onCloseDrawer={handleCloseImportDrawer}
        pageTitle="Import Members"
        componentToPassDown={
          <MemberImportFromProgram
            onCloseDrawer={handleCloseImportDrawer}
            selectedObject={selectedObject}
          />
        }
      />
    </>
  );
}
