import React, { useState, useEffect } from "react";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Tooltip,
} from "@mui/material";
import { useSnackbar } from "notistack";
import "react-datepicker/dist/react-datepicker.css";
import { makeStyles } from "@mui/styles";
import { get_root_value } from "src/utils/domUtils";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCkeditor";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import moment from "moment";
import dayjs from "dayjs";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { useContentSetting } from "src/Hooks/ContentSettingState";
import {
  eventDetailApi,
  update_event_iteration_api,
  update_event_iteration_api_delegate,
} from "src/DAL/Calendar/Calendar";
import { memberAndGroupsListApi } from "src/DAL/member/Member";
import { TIME_ZONE_CONVERSION } from "src/utils/constants";
import PerformActionOn from "../ModalBox/PerformActionOn";
import MUIAutocomplete from "./MUIAutocomplete";

const useStyles = makeStyles(() => ({
  paper: {
    background: get_root_value("--popup-background-color"),
    color: get_root_value("--input-text-color"),
  },
}));

const notification_object = {
  notify_before_unit: "minutes",
  notify_before_time: 30,
  notification_title: "",
  notification_send_type: [],
};

export default function UpdateCalendarItrationNew({
  dataList,
  onCloseDrawer,
  defaultTimeZone,
  editValues,
  created_for,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [personName, setPersonName] = useState([]);
  const [groupsName, setGroupsName] = useState([]);
  const [groupListing, setGroupListing] = useState([]);
  const { userInfo, adminTimeZone } = useContentSetting();
  const [newNotification, setNewNotification] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [serchText, setSerchText] = useState("");
  const [oldTime, setOldTime] = useState({});
  const [member, setMember] = useState([]);
  const [notificationArray, setNotificationArray] = useState([
    notification_object,
  ]);
  const [state, setState] = useState({
    event_title: "",
    event_color: "#000",
    event_slug: "",
    status: true,
    start_date: dayjs(new Date()).$d,
    end_date: dayjs(new Date()).$d,
    start_time: dayjs(new Date()).$d,
    end_time: dayjs(new Date()).$d,
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };
  const get_start_end_time = (date) => {
    return TIME_ZONE_CONVERSION(
      date,
      "YYYY-MM-DD hh:mm A",
      adminTimeZone,
      userInfo.time_zone
    );
  };

  const handleChangeNotification = (event, index) => {
    const { name, value } = event.target;
    let temp_state = [...notificationArray];
    let temp_element = { ...temp_state[index] };
    temp_element[name] = value;
    temp_state[index] = temp_element;
    setNotificationArray(temp_state);
  };

  const handleChangeNewNotification = (value, index) => {
    let temp_state = [...notificationArray];
    let temp_element = { ...temp_state[index] };
    temp_element.notification_send_type = value;
    temp_state[index] = temp_element;
    setNotificationArray(temp_state);
  };

  const handleChangeDate = (name, event) => {
    setState((prevState) => {
      return {
        ...prevState,
        [name]: event.$d,
      };
    });
  };

  const handleAddNotification = () => {
    setNotificationArray((old_array) => [...old_array, notification_object]);
  };
  const handleChangeGroup = (value) => {
    setGroupsName(value);
  };

  const handleRemoveNotification = (index) => {
    const updatedItems = [...notificationArray];
    updatedItems.splice(index, 1);
    setNotificationArray(updatedItems);
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const formData = {
      iteration_id: editValues._id,
      start_date: moment(state.start_date).format("YYYY-MM-DD"),
      end_date: moment(state.end_date).format("YYYY-MM-DD"),
      start_time: moment(state.start_time).format("HH:mm"),
      end_time: moment(state.end_time).format("HH:mm"),
      title: state.event_title,
      color: state.event_color,
      description: state.description,
      status: state.status,
      is_notify_user: notificationArray.length > 0 ? true : false,
      notify_before: notificationArray,
    };
    update_event_iteration_api_delegate;

    const result =
      created_for == "consultant_user"
        ? await update_event_iteration_api_delegate(
            formData,
            editValues.event_slug
          )
        : await update_event_iteration_api(formData, editValues.event_slug);
    if (result.code === 200) {
      dataList();
      setIsLoading(false);
      onCloseDrawer();
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const updateIteration = async (value) => {
    setOpenPopup(false);
    setIsLoading(true);
    const formData = {
      title: state.event_title,
      color: state.event_color,
      description: state.description,
      iteration_id: state._id,
      start_date: moment(state.start_date).format("YYYY-MM-DD"),
      end_date: moment(state.end_date).format("YYYY-MM-DD"),
      start_time: moment(state.start_time).format("HH:mm"),
      end_time: moment(state.end_time).format("HH:mm"),
      update_type: value,
      is_notify_user: notificationArray.length > 0 ? true : false,
      notify_before: notificationArray,
    };
    const result =
      created_for == "consultant_user"
        ? await update_event_iteration_api_delegate(
            formData,
            editValues.event_slug
          )
        : await update_event_iteration_api(formData, editValues.event_slug);
    if (result.code === 200) {
      dataList();
      onCloseDrawer();
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      state.event_title !== editValues.event_title ||
      state.start_time.getTime() !== oldTime.start_time.getTime() ||
      state.end_time.getTime() !== oldTime.end_time.getTime()
    ) {
      setOpenPopup(true);
    } else {
      updateIteration("current");
    }
  };

  const getGroupsAndMembers = async () => {
    const result = await memberAndGroupsListApi(serchText);
    if (result.code === 200) {
      if (!serchText.length) {
        setGroupListing(result.group);
      }
      setPersonName(result.members);
      setNewNotification(result.notification_send_types);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handleSearch = (event) => {
    event.preventDefault();
    const value = event.target.value;
    setSerchText(value);
  };
  const getEventinformation = async () => {
    const result = await eventDetailApi(editValues.event_slug);
    if (result.code === 200) {
      setGroupsName(result.event_groups);
      setMember(result.event_members);
      // setEventDetail(result.event);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  useEffect(() => {
    getGroupsAndMembers();
    getEventinformation();
  }, []);

  useEffect(() => {
    if (serchText.length > 2) {
      getGroupsAndMembers();
    }
  }, [serchText]);

  useEffect(() => {
    setState({
      ...editValues,
      start_date: dayjs(get_start_end_time(editValues.start_date_time)).$d,
      end_date: dayjs(get_start_end_time(editValues.end_date_time)).$d,
      start_time: dayjs(get_start_end_time(editValues.start_date_time)).$d,
      end_time: dayjs(get_start_end_time(editValues.end_date_time)).$d,
    });
    setOldTime({
      start_time: dayjs(get_start_end_time(editValues.start_date_time)).$d,
      end_time: dayjs(get_start_end_time(editValues.end_date_time)).$d,
    });
    let notify_before = [];
    if (editValues.notify_before && editValues.notify_before.length > 0) {
      notify_before = editValues.notify_before;
    }
    setNotificationArray(notify_before);
    let group_array = [];
    if (editValues?.group?.length > 0) {
      editValues?.group?.map((group) => {
        if (group?._id !== null) {
          group_array.push(group?._id);
        }
      });
    }
    setGroupsName(group_array);

    let member_array = [];
    if (editValues?.member?.length > 0) {
      editValues?.member?.map((member) => {
        member_array.push(member?._id);
      });
    }
    setMember(member_array);
  }, [editValues]);
  useEffect(() => {
    let value = 30;
    if (state.notify_before_unit == "days") value = 7;
    else if (state.notify_before_unit == "hours") value = 24;

    setState((prevState) => {
      return {
        ...prevState,
        notify_before_time: value,
      };
    });
  }, [state.notify_before_unit]);

  return (
    <div className="container">
      <form className="row" onSubmit={handleSubmit}>
        <div className="text-end mb-4">
          <button
            className="small-contained-button event-submit-button"
            disabled={isLoading}
          >
            {isLoading ? "Submitting..." : "Submit"}
          </button>
        </div>
        <div className="col-12 col-md-6 col-lg-4">
          <TextField
            className="mt-3"
            id="outlined-basic"
            label="Title"
            variant="outlined"
            name="event_title"
            value={state.event_title}
            required={true}
            onChange={handleChange}
          />
        </div>
        <div className={`col-12 col-md-6 ${"col-lg-4"}`}>
          <TextField
            id="event_color"
            type="color"
            label="Color"
            name="event_color"
            className="mt-3"
            variant="outlined"
            required={true}
            value={state.event_color}
            onChange={handleChange}
          />
        </div>
        <div className="col-12 col-md-2 col-lg-4">
          <div className="col-12">
            <FormControl variant="outlined" className="mt-3">
              <InputLabel id="demo-simple-select-outlined-label">
                Status
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={state.status}
                onChange={handleChange}
                label="Status"
                name="status"
                className="svg-color"
                MenuProps={{
                  classes: {
                    paper: classes.paper,
                  },
                }}
                sx={{
                  color: get_root_value("--input-text-color"),
                }}
              >
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Inactive</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        <div className="col-12 col-md-6 col-lg-3">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              label="Start Date"
              name="start_date"
              inputFormat="DD-MM-YYYY"
              value={state.start_date}
              format="DD-MM-YYYY"
              className="mt-3"
              onChange={(e) => handleChangeDate("start_date", e)}
              renderInput={(params) => (
                <TextField {...params} required={true} />
              )}
            />
          </LocalizationProvider>
        </div>
        <div className="col-12 col-md-6 col-lg-3">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
              label="Start Time"
              name="start_time"
              value={state.start_time}
              sx={{ color: "#fff" }}
              className="mt-3"
              onChange={(e) => handleChangeDate("start_time", e)}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </div>
        <div className="col-12 col-md-6 col-lg-3">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              label="End Date"
              name="end_date"
              inputFormat="DD-MM-YYYY"
              value={state.end_date}
              format="DD-MM-YYYY"
              className="mt-3"
              onChange={(e) => handleChangeDate("end_date", e)}
              renderInput={(params) => (
                <TextField {...params} required={true} />
              )}
            />
          </LocalizationProvider>
        </div>
        <div className="col-12 col-md-6 col-lg-3">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
              label="End Time"
              name="end_time"
              value={state.end_time}
              sx={{ color: "#fff" }}
              className="mt-3"
              onChange={(e) => handleChangeDate("end_time", e)}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </div>

        <div className="col-12 mt-3 mb-4">
          <h5 className="sale-page-title ms-0">Event Detail</h5>
          <hr />
          <div className="mt-4">
            <h4 className="heading-calender">Notifications Setting</h4>
          </div>
          <div className="row">
            {notificationArray.length > 0 &&
              notificationArray.map((notification, index) => {
                return (
                  <>
                    <div className="col-11 col-md-5 mt-3">
                      <MUIAutocomplete
                        inputLabel="Notification Type"
                        selectedOption={notification.notification_send_type}
                        setSelectedOption={handleChangeNewNotification}
                        optionsList={newNotification}
                        multiple
                        name="title"
                        index={index}
                      />
                    </div>
                    <div className="col-11 col-md-3">
                      <FormControl variant="outlined" className="mt-3">
                        <InputLabel id="demo-simple-select-outlined-label">
                          Notify Before
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={notification.notify_before_unit}
                          onChange={(e) => {
                            handleChangeNotification(e, index);
                          }}
                          label="Notifications Before"
                          name="notify_before_unit"
                          className="inputs-fields svg-color"
                          MenuProps={{
                            classes: {
                              paper: classes.paper,
                            },
                          }}
                          sx={{
                            color: get_root_value("--input-text-color"),
                          }}
                        >
                          <MenuItem value="days">Days</MenuItem>
                          <MenuItem value="hours">Hours</MenuItem>
                          <MenuItem value="minutes">Minutes</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <div className="col-11 col-md-3">
                      <TextField
                        className="mt-3"
                        id="outlined-basic"
                        label={`${
                          notification.notify_before_unit == "days"
                            ? "Days"
                            : notification.notify_before_unit == "hours"
                            ? "Hours"
                            : "Minutes"
                        }`}
                        variant="outlined"
                        name="notify_before_time"
                        type="number"
                        value={notification.notify_before_time}
                        required={true}
                        onChange={(e) => {
                          handleChangeNotification(e, index);
                        }}
                        inputProps={{
                          min: 0,
                          max:
                            notification.notify_before_unit == "days"
                              ? 100
                              : notification.notify_before_unit == "hours"
                              ? 24
                              : 60,
                        }}
                      />
                    </div>
                    <div className="col-1 p-0 mt-4">
                      <Tooltip title="Remove">
                        <RemoveCircleOutlineIcon
                          onClick={() => handleRemoveNotification(index)}
                          className="diary-icon-remove"
                        />
                      </Tooltip>
                    </div>
                  </>
                );
              })}
            <div className="col-12 mt-3 mb-4">
              <FormControlLabel
                control={
                  <AddCircleOutlineIcon className="diary-icon-add mx-2" />
                }
                label="Add Notification"
                onClick={handleAddNotification}
                className="heading-calender"
              />
            </div>
          </div>
          <h4 className="heading-calender">Event Description </h4>
          <GeneralCkeditor
            setInputs={setState}
            inputs={state}
            name="description"
            editorHeight={320}
          />
        </div>
      </form>
      <PerformActionOn
        open={openPopup}
        setOpen={setOpenPopup}
        title={"Perform this action On?"}
        handleAgree={updateIteration}
      />
    </div>
  );
}
