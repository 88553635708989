import { MenuItem } from "@mui/material";
import React, { useEffect } from "react";

export default function StagesPopup({
  leadStages,
  position,
  setOpenStagesBox,
  handleClick,
  POPUP_WIDTH,
}) {
  const handleClickOutside = (event) => {
    if (!event.target.closest(".lead-stages-popup")) {
      setOpenStagesBox(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      className="lead-stages-popup"
      style={{ top: position.top + 3, left: position.left, width: POPUP_WIDTH }}
    >
      {leadStages.map((stage) => {
        return (
          <MenuItem
            key={stage._id}
            onClick={() => handleClick(stage)}
            className="lead-stages-popup-item"
          >
            <span>{stage.title}</span>
          </MenuItem>
        );
      })}
    </div>
  );
}
