import React, { useEffect } from "react";
import {
  Box,
  Card,
  CardHeader,
  CircularProgress,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import Chart from "react-apexcharts";
import FilterListIcon from "@mui/icons-material/FilterList";
import PageDescription from "../GeneralComponents/PageDescription";
import CircleIcon from "@mui/icons-material/Circle";
import CompleteUncompleteChart from "./CompleteUncompleteChart";
import LeaderboardCard from "src/pages/Community/LeaderboardCard";
import LeaderboardCardPerformance from "./LeaderboardCardPerformance";
import { DashboardFilter } from "../DashboardFilter";
import { useState } from "react";
import CustomDrawer from "../DrawerForm/CustomDrawer";
import { AccountabilityReportFilter } from "./AccountabilityReportFilter";
import { AppWebsiteVisits } from "../_dashboard/app";
import MUICustomTabs from "../GeneralComponents/MUICustomTabs";
import DynamiteAccountability from "./DynamiteAccountability";
import {
  get_detail_data,
  get_past_activities_data,
  get_past_activities_data_accoutability,
} from "src/DAL/Tracking/Tracking";
import moment from "moment";
import SalesPerformance from "./SalesPerformance";
import BookingReport from "./BookingReport";
import MOnthalyReportAll from "./MOnthalyReportAll";
import AccountabilityReportMonthly from "./AccountabilityReportMonthly";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
  fonts: {
    fontSize: "13px",
    fontWeight: "500",
  },
}));
const EMPTY_FILTER = {
  delegate: null,
  month: new Date(),
};
const filterDate = {
  from_date: null,
  to_date: null,
  month: new Date(),
};
const AccountabilityReport = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = React.useState(true);
  const [drawerState, setDrawerState] = useState(false);
  const [drawerStateBookings, setDrawerStateBookings] = useState(false);
  const [clearFilterCount, setclearFilterCount] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [filterData, setFilterData] = useState(EMPTY_FILTER);
  const [filterDataBooking, setFilterDataBooking] = useState(EMPTY_FILTER);
  const [search, setSearch] = useState("");
  const handleChangeOthers = (event, name) => {
    setFilterData((values) => ({ ...values, [name]: event }));
  };
  const handleChangeOthersBooking = (event, name) => {
    setFilterDataBooking((values) => ({ ...values, [name]: event }));
  };
  const handleChangeStartDate = (date, viewType) => {
    setFilterDatesBooking((prevState) => ({
      ...prevState,
      [viewType]: date,
    }));
  };
  const [Activities, setActivitiesArray] = React.useState([]);
  const [totlePages, setTotlePages] = useState(0);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [loadMorePath, setLoadMorePath] = useState("");
  const [isloadingdetail, setIsLoadingDetail] = useState(false);
  const [detailData, setDetailData] = useState({});
  const [cout, setCount] = useState(0);
  const [SalesPerformanceFilterCount, setSalesPerformanceFilterCount] =
    useState(false);
  const [searchCount, setSearchCount] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [StreakCount, setStreakCount] = useState("");
  const [settings, setSettings] = useState();
  const [pageNumber, setPageNumber] = useState(0);
  const { state } = useLocation();
  const [loadMore, setLoadMore] = useState(
    `api/daily_dynamite_tracker/list_and_performance_stats_infor_for_delegate?limit=20&page=0`
  );
  const [filterDates, setFilterDates] = useState(filterDate);
  const [filterDatesBooking, setFilterDatesBooking] = useState(filterDate);
  const classes = useStyles();

  const handleChangeTabs = (event, newValue) => {
    setTabValue(newValue);
    setActivitiesArray([]);
    setSearch("");
  };

  const getPerformanceData = async (data, type) => {
    let filterData = {};
    filterData = { ...data };
    let postData = {};
    if (type == "from_filter" && search != "") {
      postData = {
        ...postData,
        search_text: search,
      };
    }
    let path = "";
    if (type == "from_filter") {
      path = `api/daily_dynamite_tracker/list_and_performance_stats_infor_for_delegate?limit=20&page=0`;
    } else {
      path = loadMore;
    }
    const result = await get_past_activities_data_accoutability(path, postData);
    if (result.code == 200) {
      setStreakCount(result.streak_count);
      setSettings(result.streak_performance_setting);
      let newArray = [];
      if (type == "from_filter") {
        newArray = result.delegate;
        setPageNumber(1);
      } else {
        newArray = Activities?.concat(result.delegate);
        setPageNumber((pageNumber) => pageNumber + 1);
      }
      // Add isExpand property to newArray
      newArray = newArray.map((item, index) => ({
        ...item,
        isExpand: index === 0 ? true : false,
      }));

      getPerformanceDetail(newArray[0], filterDates);

      setActivitiesArray(newArray);
      setTotlePages(result.total_pages);
      setLoadMorePath(result.load_more_url);
      setIsLoadingMore(false);

      setIsLoading(false);
      setFirstLoad(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const loadMoreData = () => {
    setCount((count) => count + 1);
    setIsLoadingMore(true);
    setLoadMore(loadMorePath);
  };
  const handleToggle = (item, index) => {
    if (!item.isExpand) {
      getPerformanceDetail(item, filterDates);
    }
    setActivitiesArray((old) =>
      old.map((item, i) => {
        item.isExpand = i === index ? !item.isExpand : false;
        return item;
      })
    );
  };

  const getPerformanceDetail = async (item, filterDate) => {
    setIsLoadingDetail(true);
    let postData = {
      type: "performance_info",
      delegate_id: item._id,
      start_date: filterDate.from_date
        ? moment(filterDate.from_date).format("DD-MM-YYYY")
        : "",
      end_date: filterDate.to_date
        ? moment(filterDate.to_date).format("DD-MM-YYYY")
        : "",
    };
    const result = await get_detail_data(postData);
    if (result.code == 200) {
      setDetailData(result);
      setIsLoadingDetail(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingDetail(false);
    }
  };

  const searchFunction = (e) => {
    setIsLoading(true);
    if (e) {
      e.preventDefault();
    }
    getPerformanceData(filterDates, "from_filter");
    handleCloseDrawer();
  };
  const searchFunctionBooking = (e) => {
    if (e) {
      e.preventDefault();
    }
    // localStorage.setItem("trackert_filter",filterDatesBooking)
    setSearchCount(true);
    handleCloseDrawerBooking();
  };
  const clearFlter = () => {
    ``;
    setFilterDates(filterDate);
    getPerformanceData(filterDate, "from_filter");
    handleCloseDrawer();
  };
  const clearFlterBooking = () => {
    setFilterDatesBooking(filterDate);
    setclearFilterCount(true);
    handleCloseDrawerBooking();
  };

  const handleOpenDrawer = () => {
    setDrawerState(true);
  };
  const handleOpenDrawerBooking = () => {
    setDrawerStateBookings(true);
  };
  const handleSalesDrawe = () => {
    setSalesPerformanceFilterCount(true);
  };

  const handleCloseDrawer = () => {
    setDrawerState(false);
  };
  const handleCloseDrawerBooking = () => {
    setDrawerStateBookings(false);
  };
  const mainSearch = () => {
    setSearchCount(true);
    localStorage.setItem("tracker_search_text", search);
  };
  const TABS_OPTIONS = [
    {
      title: "Streak Performance",
      component: (
        <DynamiteAccountability
          totlePages={totlePages}
          pageNumber={pageNumber}
          loadMoreData={loadMoreData}
          isLoadingMore={isLoadingMore}
          isLoading={isLoading}
          Activities={Activities}
          handleToggle={handleToggle}
          isloadingdetail={isloadingdetail}
          detailData={detailData}
        />
      ),
    },
    {
      title: "Sales Performance",
      component: (
        <SalesPerformance
          user_type=""
          heading="Member Answers List"
          SalesPerformanceFilterCount={SalesPerformanceFilterCount}
          setSalesPerformanceFilterCount={setSalesPerformanceFilterCount}
        />
      ),
    },
    {
      title: "Bookings",
      component: (
        <BookingReport
          search={search}
          searchCount={searchCount}
          filterDataBooking={filterDatesBooking}
          clearFilterCount={clearFilterCount}
          setSearchCount={setSearchCount}
          setclearFilterCount={setclearFilterCount}
        />
      ),
    },
    {
      title: "Monthly Report",
      component: (
        <MOnthalyReportAll
          search={search}
          searchCount={searchCount}
          filterDataBooking={filterDatesBooking}
          clearFilterCount={clearFilterCount}
          setSearchCount={setSearchCount}
          setclearFilterCount={setclearFilterCount}
        />
      ),
    },
    {
      title: "Accountability Tracker",
      component: (
        <AccountabilityReportMonthly
          search={search}
          searchCount={searchCount}
          filterDataBooking={filterDatesBooking}
          clearFilterCount={clearFilterCount}
          setSearchCount={setSearchCount}
          setclearFilterCount={setclearFilterCount}
        />
      ),
    },
  ];
  useEffect(() => {
    getPerformanceData(filterDates, "data");
  }, [loadMore, cout, tabValue]);
  useEffect(() => {
    if (state) {
      setTabValue(state ? state : 0);
      window.history.replaceState({}, document.title);
    }
    let search_text = localStorage.getItem("tracker_search_text");
    if (search_text) {
      setSearch(search_text);
    }
  }, [state]);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-12 col-lg-7">
            <div className="mb-3 mt-3">
              <PageDescription
                parameter="vault_description"
                else_title="Delegate Report"
              />
            </div>
          </div>

          <div
            className="col-12 col-md-12 col-lg-5 mt-3 d-flex "
            style={{ justifyContent: "end" }}
          >
            {(tabValue == 0 ||
              tabValue == 2 ||
              tabValue == 3 ||
              tabValue == 4) && (
              <div>
                <TextField
                  size="small"
                  className="text-muted"
                  id="outlined-basic"
                  label="search delegate"
                  variant="outlined"
                  fullWidth
                  name="old_password"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  required={true}
                />
              </div>
            )}
            {tabValue == 0 && (
              <>
                <div className="mb-3 ms-2 me-1 text-end">
                  <button
                    className="small-contained-button"
                    onClick={searchFunction}
                  >
                    Search
                  </button>
                </div>{" "}
                <div className="mb-3  me-2 text-end">
                  <button
                    className="small-contained-button"
                    onClick={handleOpenDrawer}
                  >
                    Filter
                  </button>
                </div>
              </>
            )}
            {tabValue == 1 && (
              <div className="mb-3  me-2 text-end">
                <button
                  className="small-contained-button"
                  onClick={handleSalesDrawe}
                >
                  Filter
                </button>
              </div>
            )}
            {(tabValue == 2 || tabValue == 3 || tabValue == 4) && (
              <>
                <div className="mb-3 ms-2 me-1 text-end">
                  <button
                    className="small-contained-button"
                    onClick={() => mainSearch()}
                  >
                    Search
                  </button>
                </div>
                <div className="mb-3  me-2 text-end">
                  <button
                    className="small-contained-button"
                    onClick={handleOpenDrawerBooking}
                  >
                    Filter
                  </button>
                </div>
              </>
            )}
          </div>

          <div className="mt-3">
            <MUICustomTabs
              data={TABS_OPTIONS}
              value={tabValue}
              handleChange={handleChangeTabs}
            />
          </div>
        </div>
      </div>
      <CustomDrawer
        isOpenDrawer={drawerState}
        onOpenDrawer={handleOpenDrawer}
        onCloseDrawer={handleCloseDrawer}
        pageTitle="Filter"
        componentToPassDown={
          <AccountabilityReportFilter
            getDashboardDetail=""
            inputs={filterDates}
            filterData={filterData}
            handleChangeOthers={handleChangeOthers}
            setInputs={setFilterDates}
            searchFunction={searchFunction}
            onClear={clearFlter}
            onCloseDrawer={handleCloseDrawer}
          />
        }
      />{" "}
      <CustomDrawer
        isOpenDrawer={drawerStateBookings}
        onOpenDrawer={handleOpenDrawerBooking}
        onCloseDrawer={handleCloseDrawerBooking}
        pageTitle={
          tabValue == 3 || tabValue == 4 ? "Filter" : "Filter by Booking Date"
        }
        componentToPassDown={
          <AccountabilityReportFilter
            getDashboardDetail=""
            inputs={filterDatesBooking}
            filterData={filterDataBooking}
            handleChangeOthers={handleChangeOthersBooking}
            setInputs={setFilterDatesBooking}
            searchFunction={searchFunctionBooking}
            onClear={clearFlterBooking}
            onCloseDrawer={handleCloseDrawerBooking}
            handleChangeStartDate={handleChangeStartDate}
            tabValue={tabValue}
          />
        }
      />
    </>
  );
};

export default AccountabilityReport;
