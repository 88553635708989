import { filter } from "lodash";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Card,
  Table,
  Stack,
  Avatar,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  Pagination,
  IconButton,
} from "@mui/material";
import Label from "src/components/Label";
import { UserListHead, UserListToolbar } from "src/components/_dashboard/user";
import { CircularProgress } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { s3baseUrl } from "src/config/config";
import SearchNotFound from "src/components/SearchNotFound";
import {
  digitalAssetsListingApi,
  digital_Assets_listing_with_category,
} from "src/DAL/DigitalAssetsApi/Assetsapi";

//

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "number", label: "#", alignRight: false },
  { id: "name", label: " Title", alignRight: false },
  { id: "description", label: "Short Description", alignRight: false },
  // { id: "type", label: "Type", alignRight: false },
  { id: "view", label: "View", alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function DigitalAssetsListings() {
  const navigate = useNavigate();
  const classes = useStyles();
  const { category_id } = useParams();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("number");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [deleteDoc, setDeleteDoc] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [documentList, setDocumentList] = useState([]);
  const [isWaiting, setIsWaiting] = useState(false);
  const [fullName, setfullName] = useState();
  const [totalPages, setTotalPages] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [memberData, setmemberData] = useState();
  const handleRequestSort = (event, property) => {
    // const isAsc = orderBy === property && order === "asc";
    // setOrder(isAsc ? "desc" : "asc");
    // setOrderBy(property);
  };

  const getConsultantListing = async () => {
    try {
      const result = await digital_Assets_listing_with_category(category_id);
      if (result.code === 200) {
        const data = result.assets.map((room) => {
          return {
            id: room._id,
            name: room.title,
            description: room.short_description,
            type: room.file_type,
            audioOtherFile: room.assets_file_url,
            videoFile: room.video_url,
            imageFile: room?.assets_images_url?.thumbnail_1,
            status: room.status,
            view: room?.assets_file_url,
            object: room,
          };
        });
        setUserList(data);
        setmemberData(result.category.title);
        setIsLoading(false);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
      }
    } catch (error) {}
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - userList.length) : 0;

  const filteredUsers = applySortFilter(
    userList,
    getComparator(order, orderBy),
    filterName
  );
  const getDocument = async () => {
    setIsWaiting(true);
    let result = await digitalAssetsListingApi();
    if (result.code == 200) {
      setIsWaiting(false);
      setDocumentList(result.assets);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsWaiting(false);
    }
  };

  useEffect(() => {
    getConsultantListing();
  }, []);
  const isUserNotFound = filteredUsers.length === 0;
  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <>
      <div className="container">
        <div className="row mobile-margin display-flex">
          <div className="col-12 mb-2">
            <IconButton
              className="back-screen-button mb-1"
              onClick={() => navigate(`/digital-asset`)}
            >
              <ArrowBackIcon />
            </IconButton>
            {memberData && (
              <span className="pt-2 ms-2" style={{ fontSize: "17px" }}>
                {memberData}
              </span>
            )}
          </div>
          <div className="col-12">
            <h1>Digital Assets</h1>
          </div>
        </div>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        ></Stack>

        <Card style={{ overflowX: "auto" }}>
          <div className="row">
            <div className="col-sm-12 col-md-6 d-flex flex-column align-items-start mt-1">
              <TablePagination
                rowsPerPageOptions={[50, 100, 150]}
                component="div"
                count={userList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
              <Pagination
                count={totalPages + 1}
                page={pageCount}
                defaultPage={0}
                onChange={handleChangePage}
                className="pagination-style"
              />
            </div>
            <div className="col-sm-12 col-md-6 ">
              <UserListToolbar
                numSelected={selected.length}
                filterName={filterName}
                onFilterName={handleFilterByName}
              />
            </div>
          </div>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <UserListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={userList.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                // onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {filteredUsers
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, i) => {
                    const {
                      id,
                      name,
                      description,
                      status,
                      type,
                      view,
                      audioOtherFile,
                      videoFile,
                      imageFile,
                    } = row;
                    const isItemSelected = selected.indexOf(name) !== -1;

                    return (
                      <TableRow
                        hover
                        key={id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        <TableCell align="left">{i + 1}</TableCell>
                        <TableCell component="th" scope="row">
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            <span className="capitalaize"> {name}</span>
                          </Stack>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            {description ? description : "N/A"}
                          </Stack>
                        </TableCell>

                        {/* <TableCell component="th" scope="row">
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            <span className="capitalaize">
                              {type === "other_assets" ? "Other Assets" : type}
                            </span>
                          </Stack>
                        </TableCell> */}
                        <TableCell sx={{ marginRight: 20 }}>
                          <a
                            href={
                              type == "url"
                                ? videoFile
                                : type == "image"
                                ? s3baseUrl + imageFile
                                : type == "audio"
                                ? s3baseUrl + audioOtherFile
                                : type == "other_assets"
                                ? s3baseUrl + audioOtherFile
                                : ""
                            }
                            target="_blank"
                            className="view"
                          >
                            view{" "}
                          </a>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {/* {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )} */}
              </TableBody>
              {isUserNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={10} sx={{ py: 3 }}>
                      <SearchNotFound searchQuery={filterName} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[50, 100, 150]}
            component="div"
            count={userList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <Pagination
            count={totalPages + 1}
            page={pageCount}
            defaultPage={0}
            onChange={handleChangePage}
            className="pagination-style"
          />
        </Card>
      </div>
    </>
  );
}
