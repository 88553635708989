import { invokeApi } from "src/bl_libs/invokeApi";

export const _auto_responded_message = async () => {
  const requestObj = {
    path: `api/auto_responder_message`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const _Update_automated_responded_message = async (data, id) => {
  const requestObj = {
    path: `api/auto_responder_message/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const delete_auto_responded_message = async (slug) => {
  const requestObj = {
    path: `api/auto_responder_message/${slug}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const _add_automated_responded_message = async (data) => {
  const requestObj = {
    path: `api/auto_responder_message`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const departmentListAPIs = async (data) => {
  const requestObj = {
    path: `api/department/delegate_department_listing`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
