import * as React from "react";
import { useState } from "react";
import { useSnackbar } from "notistack";
import { makeStyles, styled } from "@mui/styles";
import {
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useEffect } from "react";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { wellcomeReminderSettingApi } from "src/DAL/WebsitePages/SuccessScheduleApi";
import { consultantProfileApi } from "src/DAL/Login/Login";
import { useContentSetting } from "src/Hooks/ContentSettingState";
import { uploadImageOns3 } from "src/DAL/commonApi/commonApi";
import { s3baseUrl } from "src/config/config";
import CkeditorForChat from "./GeneralComponents/CkeditorForChat";
import {
  DesktopDatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import moment from "moment";

const Input = styled("input")({
  display: "none",
});

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function WelcomeReminderSetting() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const { userInfo } = useContentSetting();

  const initialTime = new Date();
  initialTime.setHours(0, 0, 0, 0);
  let initial_values = {
    reminder_days: "0",
    reminder_message: "",
    notify_time: initialTime,
    message_type: "html",
  };

  const [inputList, setInputList] = useState([initial_values]);

  const fileChangedHandler = async (e, index) => {
    const value = e.target.files[0];
    const name = e.target.name;
    const formData = new FormData();

    formData.append("image", value);
    formData.append("width", 1000);
    formData.append("height", 670);

    const imageUpload = await uploadImageOns3(formData);
    if (imageUpload.code == 200) {
      const list = [...inputList];
      list[index][name] = imageUpload.image_path;
      setInputList(list);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleAddClick = (index) => {
    setInputList([
      ...inputList.slice(0, index),
      initial_values,
      ...inputList.slice(index),
    ]);
  };
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };

  const handleChangeDates = (value, index) => {
    const name = "notify_time";
    const list = [...inputList];
    list[index][name] = value.$d;
    setInputList(list);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsLoadingForm(true);
    let postData_ = inputList.map((item) => {
      return {
        ...item,
        notify_time: moment(item.notify_time).format("HH:mm"),
      };
    });
    let postData = {
      welcome_reminder_setting: postData_,
    };

    const result = await wellcomeReminderSettingApi(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoadingForm(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingForm(false);
    }
  };

  const getProfile = async () => {
    setIsLoading(true);
    const result = await consultantProfileApi(userInfo?._id);
    if (result.code == 200) {
      let settings = result?.consultant?.welcome_reminder_setting;
      setIsLoading(false);
      if (settings?.length > 0) {
        let updated_settings = settings.map((setting) => {
          return {
            ...setting,
            notify_time: `2018-01-01 ${setting.notify_time}`,
          };
        });
        setInputList(updated_settings);
      }
    }
  };

  useEffect(() => {
    getProfile();
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      <div className="row mb-3">
        <div className="col-12">
          <h2>Welcome Reminder Setting</h2>
        </div>
      </div>

      <form onSubmit={handleSubmit}>
        {inputList.map((item, index) => {
          return (
            <>
              <div className="row mb-4 setting-card">
                <div className="col-11">
                  <div className="row">
                    {/* <div className="col-12 col-md-6">
                      <TextField
                        id="outlined-basic"
                        label="Title"
                        variant="outlined"
                        fullWidth
                        required
                        type="text"
                        name="title"
                        value={item.title}
                        onChange={(e) => handleInputChange(e, index)}
                      />
                    </div> */}
                    <div className="col-12 col-md-6 mt-3 mt-md-0">
                      <TextField
                        id="outlined-basic"
                        label="After Days"
                        variant="outlined"
                        fullWidth
                        required
                        className="mt-3"
                        type="number"
                        name="reminder_days"
                        value={item.reminder_days}
                        onChange={(e) => handleInputChange(e, index)}
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                          label="Notify Time"
                          name="notify_time"
                          value={item.notify_time}
                          sx={{ color: "#fff" }}
                          className="mt-3"
                          onChange={(e) => handleChangeDates(e, index)}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                    </div>
                    <div className="col-12 col-md-6 mt-3">
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Message Type
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          name="message_type"
                          value={item.message_type}
                          label="Message Type"
                          onChange={(e) => handleInputChange(e, index)}
                        >
                          <MenuItem value="html">General</MenuItem>
                          <MenuItem value="image">Image</MenuItem>
                          <MenuItem value="video">Video</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    {item.message_type == "image" && (
                      <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
                        <div className="row w-100 div-style ms-0 pt-0">
                          <div className="col-5">
                            <p className="">Image *</p>
                            <FormHelperText className="pt-0">
                              (Recommended Size 150 X 22)
                            </FormHelperText>
                          </div>
                          <div className="col-2">
                            {item.image && (
                              <img src={s3baseUrl + item.image} height="50" />
                            )}
                          </div>
                          <div className="col-5 text-end pt-2">
                            <label htmlFor="contained-button-file1">
                              <Input
                                accept="image/*"
                                id="contained-button-file1"
                                multiple
                                type="file"
                                name="image"
                                onChange={(e) => fileChangedHandler(e, index)}
                              />

                              <Button
                                className="small-contained-button"
                                startIcon={<FileUploadIcon />}
                                component="span"
                              >
                                Upload
                              </Button>
                            </label>
                          </div>
                        </div>
                        {item?.image?.name == "" ? (
                          ""
                        ) : (
                          <p className="text-secondary">{item?.image?.name}</p>
                        )}
                      </div>
                    )}

                    {item.message_type == "video" && (
                      <div className="col-12 mt-3">
                        <FormControl fullWidth>
                          <TextField
                            id="outlined-multiline-flexible"
                            label="Embed Code"
                            multiline
                            rows={6}
                            name="embed_code"
                            value={item.embed_code}
                            onChange={(e) => handleInputChange(e, index)}
                            required
                          />
                        </FormControl>
                      </div>
                    )}
                    <div className="col-12 mt-4">
                      <h4>Reminder Message</h4>
                      <CkeditorForChat
                        setInputs={setInputList}
                        inputs={inputList}
                        required
                        name="reminder_message"
                        index={index}
                        editorHeight={320}
                        is_array={true}
                      />
                      {/* <GeneralIterativeCkeditor
                        setInputs={setInputList}
                        inputs={inputList}
                        required
                        name="reminder_message"
                        index={index}
                        editorHeight={320}
                      /> */}
                    </div>
                  </div>
                </div>
                <div className="col-1">
                  <AddCircleOutlineIcon
                    // className="question-survey"
                    onClick={() => handleAddClick(index + 1)}
                  />
                  {inputList.length !== 1 && (
                    <RemoveCircleOutlineIcon
                      className="mt-2 mt-lg-0"
                      onClick={() => handleRemoveClick(index)}
                    />
                  )}
                </div>
              </div>
            </>
          );
        })}
        <div className="text-end mt-4">
          <button className="small-contained-button">
            {isLoadingForm ? "Updating..." : "Update"}
          </button>
        </div>
      </form>
    </div>
  );
}
