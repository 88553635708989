import { useSnackbar } from "notistack";
import React from "react";
import { manage_poll_answer_api } from "src/DAL/Community/PollFeed";
import { useContentSetting } from "src/Hooks/ContentSettingState";
import { TIME_ZONE_CONVERSION } from "src/utils/constants";

function get_winner_option(options) {
  if (options.length === 0) {
    return null;
  }
  return options.reduce(
    (max, option) => (option.votes > max.votes ? option : max),
    options[0]
  );
}

export default function PollFeed(props) {
  const { userInfo, adminTimeZone, feedSetting } = useContentSetting();

  const { enqueueSnackbar } = useSnackbar();
  const { feed, handleUpdateFeed, handlePollDetail } = props;
  const { poll_info, selected_options } = feed;

  const handleAnswer = async (option) => {
    if (poll_info.poll_status == "expired") return;
    // setOpenDeleteComment(false);
    const postData = {
      feed_id: feed._id,
      option_id: option._id,
    };

    const result = await manage_poll_answer_api(postData);
    if (result.code === 200) {
      if (result.feed_obj.action_info.action_id === userInfo._id) {
        const data = {
          ...result.feed_obj,
          is_self: true,
        };
        handleUpdateFeed(data);
      } else {
        handleUpdateFeed(result.feed_obj);
      }
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const replacedString = (string) => {
    if (!string) return;
    return string.replace(
      /{winner_option}/g,
      get_winner_option(poll_info.options).text
    );
  };

  return (
    <div className="feed-poll-box">
      <div className="p-2">
        {poll_info.poll_status == "expired" ? (
          <div
            dangerouslySetInnerHTML={{
              __html: replacedString(feedSetting.poll_winner_description),
            }}
          ></div>
        ) : (
          poll_info.options.map((option) => {
            let answered = selected_options?.find(
              (ans) => ans._id == option._id
            );
            return (
              <div
                className="feed-poll-option mb-2"
                onClick={() => handleAnswer(option)}
              >
                <div className={`p-2 ${answered ? "answered" : ""}`}>
                  <div className="d-flex justify-content-between px-1">
                    <div>{option.text}</div>
                    {option.votes > 0 && (
                      <div className="option-count">{option.votes}</div>
                    )}
                  </div>
                </div>
              </div>
            );
          })
        )}
      </div>
      {poll_info.poll_status == "expired" ? (
        <div className="poll-expires">Poll Expired</div>
      ) : (
        <div className="poll-expires">
          Poll Expires on
          {TIME_ZONE_CONVERSION(
            poll_info.expiry_date_time,
            " DD-MM-YYYY [at] hh:mm A",
            adminTimeZone,
            userInfo.time_zone
          )}
        </div>
      )}
      <div
        className="view-poll-result text-center p-2"
        onClick={() => handlePollDetail(feed)}
      >
        View Details
      </div>
    </div>
  );
}
