import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { Avatar, Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import { _consultant_member_list_with_search } from "src/DAL/Chat/Chat";
import { string_avatar } from "src/utils/constants";
import { s3baseUrl } from "src/config/config";
import { _consultant_member_list_whatsapp } from "src/DAL/WhatsApp/WhatsApp";
import parsePhoneNumber from "libphonenumber-js";

export default function NewWhatsAppUsers({ handleAddChat, onCloseDrawer }) {
  const { enqueueSnackbar } = useSnackbar();
  const [usersList, setUsersList] = useState([]);
  const [searchText, setSearchText] = useState("");

  const getChatUsersList = async () => {
    let postObject = {
      search_text: searchText,
    };
    const result = await _consultant_member_list_whatsapp(postObject);
    if (result.code === 200) {
      let members = result.members_list.map((member) => {
        const parsedPhoneNumber = parsePhoneNumber(`+${member.contact_number}`);
        const isValid = parsedPhoneNumber ? parsedPhoneNumber.isValid() : false;
        return { ...member, isValid };
      });
      setUsersList(members);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleChange = (event) => {
    const { value } = event.target;
    setSearchText(value);
  };

  useEffect(() => {
    getChatUsersList();
  }, [searchText]);

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="new-message-search-box mt-3">
            <Icon
              fontSize={20}
              className="new-message-search-icon"
              icon="akar-icons:search"
            />
            <input
              className="new-message-search-input"
              type="text"
              placeholder="Search"
              name="search_text"
              value={searchText}
              onChange={handleChange}
            />

            <div className="new-message-users-list">
              {usersList.map((user) => {
                return (
                  <div
                    className={`message-user-box p-2 mt-3 ${
                      user.contact_number && user.isValid ? "" : "not-allowed"
                    }`}
                    onClick={() => {
                      if (user.contact_number && user.isValid) {
                        handleAddChat(user._id, onCloseDrawer);
                      }
                    }}
                    key={user._id}
                  >
                    <Tooltip
                      title={`${
                        !user.contact_number
                          ? "No contact number provided"
                          : !user.isValid
                          ? "Provided contact number is invalid"
                          : ""
                      }`}
                      placement="top"
                    >
                      <div className="d-flex align-items-center">
                        <div className="user-profile">
                          <Avatar
                            src={s3baseUrl + user.profile_image}
                            className="message-user-avatar"
                            alt="photoURL"
                            style={{ width: 33, height: 33 }}
                          >
                            {string_avatar(
                              user.first_name + " " + user.last_name
                            )}
                          </Avatar>
                        </div>
                        <div className="ms-2">
                          <p>{user.first_name + " " + user.last_name}</p>
                        </div>
                      </div>
                    </Tooltip>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
