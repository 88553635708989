import React from "react";
import { Card } from "@mui/material";
import { htmlDecode } from "src/utils/convertHtml";

function SideImageCard({ data, handleClick }) {
  return (
    <div className="row">
      {data.map((item, index) => {
        return (
          <div className={item.card_classes} key={index}>
            <Card
              className="side-image-mui-card"
              onClick={
                handleClick
                  ? () => {
                      handleClick(item);
                    }
                  : undefined
              }
            >
              <div className="row p-3">
                <div className="col-lg-2 col-md-3 col-sm-12">
                  <img
                    className="side-image-mui-card-image"
                    src={item.card_image}
                    alt={item.card_title}
                    width="100%"
                  />
                </div>
                <div className="col-lg-10 col-md-9 col-sm-12">
                  <h4 className="side-image-mui-card-heading">
                    {htmlDecode(item.card_title)}
                  </h4>
                  <p className="normal-font">
                    {htmlDecode(item.card_short_description)}
                  </p>
                </div>
              </div>
            </Card>
          </div>
        );
      })}
    </div>
  );
}

export default SideImageCard;
