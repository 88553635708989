import React, { useState, useEffect } from "react";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { DatePicker } from "@mui/lab";
import { useSnackbar } from "notistack";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { AddEventApi } from "src/DAL/Calendar/Calendar";
import { makeStyles } from "@mui/styles";
import { get_root_value } from "src/utils/domUtils";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import { GroupAndMemberListing } from "src/DAL/Groups/Groups";
// import TinyEditor from "src/components/Editor/TinyCkEditor";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const useStyles = makeStyles(() => ({
  paper: {
    background: get_root_value("--popup-background-color"),
    color: get_root_value("--input-text-color"),
  },
}));

export default function AddEvent({ onCloseDrawer, dataList }) {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const [personName, setPersonName] = useState([]);
  const [person, setPerson] = useState([]);
  const [groupsName, setGroupsName] = useState([]);
  const [groupListing, setGroupListing] = useState([]);
  const [eventDescription, setEventDescription] = useState("");
  const [state, setState] = useState({
    eventTitle: "",
    eventColor: "",
    startTime: "00:00",
    endTime: "00:00",
    status: "true",
    itrationNumber: "",
    recurringType: "daily",
    startDate: new Date(),
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const getGroupsAndMembers = async () => {
    const result = await GroupAndMemberListing();
    if (result.code === 200) {
      setGroupListing(result.groups);
      setPersonName(result.members);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChangeDate = (event) => {
    setState((prevState) => {
      return {
        ...prevState,
        startDate: event,
      };
    });
  };
  const handleChangeGroup = (event) => {
    const {
      target: { value },
    } = event;
    setGroupsName(typeof value === "string" ? value.split(",") : value);
  };

  const handleChangeMember = (event) => {
    const {
      target: { value },
    } = event;
    setPerson(typeof value === "string" ? value.split(",") : value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let group_array = [];
    let group_object = {};
    groupsName.map((group) => {
      group_object = {
        group_slug: group,
      };
      group_array.push(group_object);
    });
    let selected_member_array = [];
    let selected_member_object = {};
    person.map((member) => {
      selected_member_object = {
        member_id: member,
      };
      selected_member_array.push(selected_member_object);
    });
    var dateString = moment(state.startDate).format("YYYY-MM-DD");
    const formDataObject = {
      title: state.eventTitle,
      color: state.eventColor,
      status: state.status,
      recurring_type: state.recurringType,
      start_date: dateString,
      recurring_iteration: state.itrationNumber,
      description: eventDescription,
      start_time: state.startTime,
      end_time: state.endTime,
      group: group_array,
      member: selected_member_array,
    };

    const result = await AddEventApi(JSON.stringify(formDataObject));
    if (result.code === 200) {
      dataList();
      setIsLoading(false);
      onCloseDrawer();
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  useEffect(() => {
    getGroupsAndMembers();
  }, []);

  return (
    <div className="container new-memories">
      <form onSubmit={handleSubmit}>
        <TextField
          className="mt-4 inputs-fields"
          id="outlined-basic"
          label="Event Title"
          variant="outlined"
          name="eventTitle"
          value={state.eventTitle}
          required={true}
          onChange={(e) => handleChange(e)}
        />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Start Date"
            name="startDate"
            inputFormat="dd/MM/yyyy"
            value={state.startDate}
            format="YYYY-MM-DD"
            onChange={(e) => handleChangeDate(e)}
            renderInput={(params) => (
              <TextField
                {...params}
                className="mt-3 inputs-fields"
                required={true}
              />
            )}
          />
        </LocalizationProvider>
        <TextField
          id="color"
          type="color"
          label="Event Color"
          name="eventColor"
          className="mt-3 inputs-fields"
          variant="outlined"
          required={true}
          value={state.eventColor}
          onChange={(e) => handleChange(e)}
        />
        <TextField
          variant="outlined"
          id="time"
          label="Start Time"
          type="time"
          className="mt-3 inputs-fields"
          name="startTime"
          required={true}
          value={state.startTime}
          onChange={(e) => handleChange(e)}
        />
        <TextField
          variant="outlined"
          id="time"
          label="End Time"
          type="time"
          className="mt-3 inputs-fields"
          name="endTime"
          required={true}
          value={state.endTime}
          onChange={(e) => handleChange(e)}
        />
        <TextField
          id="outlined-basic"
          label="Number of Times To Repeat"
          variant="outlined"
          name="itrationNumber"
          value={state.itrationNumber}
          required={true}
          onChange={(e) => handleChange(e)}
          type="number"
          className="mt-3 inputs-fields"
        />
        <FormControl variant="outlined" className="mt-3">
          <InputLabel id="demo-simple-select-outlined-label">
            Recurring Type
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={state.recurringType}
            onChange={(e) => handleChange(e)}
            label="recurringType"
            name="recurringType"
            className="inputs-fields svg-color"
            MenuProps={{
              classes: {
                paper: classes.paper,
              },
            }}
            sx={{
              color: get_root_value("--input-text-color"),
            }}
          >
            <MenuItem value="daily">
              <em>Daily</em>
            </MenuItem>
            <MenuItem value="weekly">
              <em>Weekly</em>
            </MenuItem>
            <MenuItem value="monthly">
              <em>Monthly</em>
            </MenuItem>
          </Select>
        </FormControl>
        <FormControl variant="outlined" className="mt-3">
          <InputLabel id="demo-simple-select-outlined-label">Status</InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={state.status}
            onChange={(e) => handleChange(e)}
            label="Status"
            name="status"
            className="inputs-fields svg-color"
            MenuProps={{
              classes: {
                paper: classes.paper,
              },
            }}
            sx={{
              color: get_root_value("--input-text-color"),
            }}
          >
            <MenuItem value="true">
              <em>Active</em>
            </MenuItem>
            <MenuItem value="weekly">
              <em>Inactive</em>
            </MenuItem>
          </Select>
        </FormControl>
        <FormControl className="mt-3">
          <InputLabel id="demo-multiple-name-label">Groups</InputLabel>
          <Select
            labelId="demo-multiple-name-label"
            id="demo-multiple-name"
            multiple
            value={groupsName}
            onChange={handleChangeGroup}
            input={<OutlinedInput label="Groups" />}
            MenuProps={MenuProps}
          >
            {groupListing.map((name) => (
              <MenuItem
                key={name}
                value={name.group_slug}
                style={getStyles(name, groupsName, theme)}
              >
                {name.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl className="mt-3">
          <InputLabel id="demo-multiple-name-label">Members</InputLabel>
          <Select
            labelId="demo-multiple-name-label"
            id="demo-multiple-name"
            multiple
            value={person}
            onChange={handleChangeMember}
            input={<OutlinedInput label="Members" />}
            MenuProps={MenuProps}
          >
            {personName.map((name) => (
              <MenuItem
                key={name}
                value={name._id}
                style={getStyles(name, person, theme)}
              >
                {name.first_name + " " + name.last_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <div className="col-12 mt-3">
          {/* <TinyEditor
            setDetailDescription={setEventDescription}
            detailDescriptionCk={eventDescription}
            edito_height={250}
          /> */}
        </div>

        <div className="text-end mt-3 mb-3">
          <button className="small-contained-button" disabled={isLoading}>
            {isLoading ? "Saving..." : "Save"}
          </button>
        </div>
      </form>
    </div>
  );
}
