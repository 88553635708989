import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  CircularProgress,
  Container,
  Tooltip,
} from "@mui/material";
import { useSnackbar } from "notistack";
import Chart from "react-apexcharts";
import { useEffect, useState } from "react";
import {
  getBookingReport,
  getBroadcastDetail,
  get_booking_stats_api,
} from "src/DAL/Broadcast/Broadcast";
import { makeStyles } from "@mui/styles";
import { s3baseUrl } from "src/config/config";
import WhatsappPopUpModel from "../GeneralComponents/WhatsappPopUpModel";
import BookingListForDelegates from "./BookingListForDelegates";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import moment from "moment";
import RecordNotFound from "../GeneralComponents/RecordNotFound";
import ReactApexChart from "react-apexcharts";
import MonthlyAccountabilityReportForUsers from "./MonthlyAccountabilityReportForUsers";
import {
  getMonthalyAccountabilityReport,
  getMonthalyReportSpacific,
} from "src/DAL/Tracking/Tracking";
import MonthlyINtentions from "./MonthlyINtentions";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "10%",
    marginBottom: "10%",
  },
  fonts: {
    fontSize: "13px",
    fontWeight: "500",
  },
}));
const AccountabilityReportMonthly = ({
  search,
  searchCount,
  filterDataBooking,
  clearFilterCount,
  setSearchCount,
  setclearFilterCount,
}) => {
  const classes = useStyles();
  const [totlePages, setTotlePages] = useState(0);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [isloadingdetail, setIsLoadingDetail] = useState(false);
  const [detailData, setDetailData] = useState([]);
  const [loadMorePath, setLoadMorePath] = useState("");
  const [userData, setUserData] = useState({});
  let [pageNumber, setPageNumber] = useState(0);
  const [Activities, setActivitiesArray] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [mainIndex, SetMainIndex] = useState(0);
  const [state, setState] = useState({
    month: new Date(),
  });
  const [open, setOpen] = useState(false);
  const openDrawr = (item) => {
    setUserData(item);
    setOpen(true);
  };
  const [loadMore, setLoadMore] = useState(
    `api/daily_dynamite_tracker/list_and_performance_stats_infor_for_delegate?page=0&limit=20`
  );
  const [cout, setCount] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  console.log(
    filterDataBooking,
    "filterDataBookingfilterDataBookingfilterDataBooking"
  );
  const getBraodcastDetail = async (type) => {
    let path = "";
    let search_text = localStorage.getItem("tracker_search_text");
    let postData = {
      type: "list",
      search_text: search_text ? search_text : "",
    };
    if (type == "search") {
      setIsLoading(true);
      path =
        "api/daily_dynamite_tracker/list_and_performance_stats_infor_for_delegate?page=0&limit=20";
      postData.search_text = search_text ? search_text : search;
      postData.start_date = filterDataBooking.from_date
        ? moment(filterDataBooking.from_date).format("DD-MM-YYYY")
        : "";
      postData.end_date = filterDataBooking.to_date
        ? moment(filterDataBooking.to_date).format("DD-MM-YYYY")
        : "";
    } else if (type == "other") {
      setIsLoading(true);
      postData.search_text = search_text ? search_text : search;
      path =
        "api/daily_dynamite_tracker/list_and_performance_stats_infor_for_delegate?page=0&limit=20";
    } else {
      path = loadMore;
    }

    const result = await getBookingReport(path, postData);
    if (result.code == 200) {
      console.log(result, "resultresultresultresult");
      if (type == "search") {
        const newArray = result.delegate.map((item, index) => ({
          ...item,
          isExpand: index === 0 ? true : false,
        }));
        getPerformanceDetail(newArray[0], filterDataBooking);
        setActivitiesArray(newArray);
      } else if (type == "other") {
        const newArray = result.delegate.map((item, index) => ({
          ...item,
          isExpand: index === 0 ? true : false,
        }));
        getPerformanceDetail(newArray[0], filterDataBooking);
        setActivitiesArray(newArray);
      } else {
        setActivitiesArray(Activities?.concat(result.delegate));
      }
      setPageNumber((pageNumber) => pageNumber + 1);
      setTotlePages(result.total_pages + 1);
      setLoadMorePath(result.load_more_url);
      setIsLoadingMore(false);
      setIsLoading(false);
      setSearchCount(false);
      setclearFilterCount(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const getPerformanceDetail = async (item) => {
    setIsLoadingDetail(true);

    let postData = {
      date_from: filterDataBooking.from_date
        ? moment(filterDataBooking.from_date).format("DD-MM-YYYY")
        : "",
      date_to: filterDataBooking.to_date
        ? moment(filterDataBooking.to_date).format("DD-MM-YYYY")
        : "",
      delegate_id: item._id,
    };
    const result = await getMonthalyAccountabilityReport(postData);
    if (result.code == 200) {
      console.log(result, "resultresultresult  for spacific");
      setDetailData(result);
      setIsLoadingDetail(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingDetail(false);
    }
  };

  const handleToggle = (item, index) => {
    SetMainIndex(index);
    if (!item.isExpand) {
      getPerformanceDetail(item, filterDataBooking);
    }
    setActivitiesArray((old) =>
      old.map((item, i) => {
        item.isExpand = i === index ? !item.isExpand : false;
        return item;
      })
    );
  };

  const loadMoreData = () => {
    setCount((count) => count + 1);
    setIsLoadingMore(true);
    setLoadMore(loadMorePath);
  };
  function makeArrayUnique(array) {
    const uniqueMap = new Map();
    array.forEach((obj) => {
      uniqueMap.set(obj._id, obj);
    });
    return Array.from(uniqueMap.values());
  }

  useEffect(() => {
    getBraodcastDetail();
  }, [loadMore, cout]);
  useEffect(() => {
    setPageNumber(1);
    if (searchCount) {
      getBraodcastDetail("search");
    }
  }, [searchCount]);
  useEffect(() => {
    setPageNumber(0);
    if (clearFilterCount) {
      getBraodcastDetail("other");
    }
  }, [clearFilterCount]);
  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="row mt-3">
      {Activities && Activities.length > 0 ? (
        makeArrayUnique(Activities).map((item, index) => {
          return (
            <Container className="mt-2">
              <Accordion expanded={Boolean(item.isExpand)}>
                <AccordionSummary
                  onClick={() => {
                    handleToggle(item, index);
                  }}
                  expandIcon={<ExpandMoreIcon className="icon-color" />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  className="svg-color"
                >
                  <div
                    className="delegate-info"
                    style={{ width: "100%", justifyContent: "space-between" }}
                  >
                    <div className="delegate-info">
                      <Avatar
                        className="avatar-adjust"
                        sx={{ width: 30, height: 30 }}
                        alt={item.first_name}
                        src={s3baseUrl + item?.image?.thumbnail_1}
                      />
                      <span style={{ color: "white" }}>
                        {item.first_name + " " + item.last_name + " "}
                        <span className="text-muted">
                          {"(" + item.email + ")"}
                        </span>{" "}
                      </span>
                    </div>
                  </div>
                </AccordionSummary>

                <AccordionDetails
                  style={{
                    backgroundColor: "#121826",
                    border: "2px solid #1D2537",
                    borderBottomLeftRadius: "20px",
                    borderBottomRightRadius: "20px",
                  }}
                >
                  {mainIndex == index && (
                    <span>
                      {isloadingdetail ? (
                        <CircularProgress
                          className={classes.loading}
                          color="primary"
                        />
                      ) : (
                        <MonthlyINtentions detailData={detailData} />
                      )}
                    </span>
                  )}
                </AccordionDetails>
              </Accordion>
            </Container>
          );
        })
      ) : (
        <div className="mt-5">
          <RecordNotFound />
        </div>
      )}
      {totlePages > pageNumber ? (
        <div className="col-12 text-center">
          <button
            className="small-contained-button mt-3"
            onClick={loadMoreData}
            disabled={isLoadingMore}
          >
            {isLoadingMore ? "Loading..." : "Load More"}
          </button>
        </div>
      ) : (
        " "
      )}
      {userData.first_name && (
        <WhatsappPopUpModel
          open={open}
          setOpen={setOpen}
          title={"Bookings"}
          mainTitle={userData?.first_name + " " + userData?.last_name}
          show_date_and_income={true}
          componentToPassDown={
            <BookingListForDelegates
              id={userData._id}
              filterDataBooking={filterDataBooking}
            />
          }
        />
      )}
    </div>
  );
};

export default AccountabilityReportMonthly;
