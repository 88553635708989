import React, { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
  delete_meeting_api,
  get_meeting_list_api,
} from "src/DAL/Meeting/Meeting";
import { makeStyles } from "@mui/styles";
import CircularProgress from "@mui/material/CircularProgress";
import { useSnackbar } from "notistack";
import CustomConfirmation from "src/components/GeneralComponents/CustomConfirmation";
import { styled } from "@mui/material/styles";
import { InputAdornment, OutlinedInput } from "@mui/material";
import { Box } from "@mui/material";
import { Icon } from "@iconify/react";
import searchFill from "@iconify/icons-eva/search-fill";
import MUICustomTabs from "src/components/GeneralComponents/MUICustomTabs";
import MeetingDetail from "../../components/Meeting/MeetingDetail";
import GeneralPopUpModel from "src/components/GeneralComponents/GeneralPopUpModel";
import MeetingItems from "src/components/Meeting/MeetingItems";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  height: 41,
  color: "#fff",
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  "&.Mui-focused": {
    boxShadow: theme.customShadows.z8,
    border: "1px solid #f6bd4b",
  },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

const MeetingsList = () => {
  const classes = useStyles();
  const observer = useRef();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [meetingsData, setMeetingsData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [openDelete, setOpenDelete] = useState(false);
  const [meetingId, setMeetingId] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [loadMorePath, setLoadMorePath] = useState("");
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [openDetail, setOpenDetail] = useState(false);
  const [meetingDetail, setMeetingDetail] = useState({});
  const load_more_path = "api/meeting/list_meeting?page=0&limit=15";

  const getMeetingList = async (path, search, loading) => {
    if (!loading) {
      setIsLoading(true);
      path = load_more_path;
    }
    let type = "upcomming";
    if (tabValue === 1) {
      type = "previous";
    }
    let postData = {
      search_text: search,
      type: type,
    };
    const result = await get_meeting_list_api(path, postData);
    if (result.code == 200) {
      setLoadMorePath(result.load_more);
      setTotalPages(result.total_pages);
      if (loading) {
        setMeetingsData((old) => [...old, ...result.meetings]);
        setPageNumber((prev) => prev + 1);
      } else {
        setMeetingsData(result.meetings);
        setPageNumber(1);
      }
      setIsLoading(false);
      setIsLoadingMore(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
      setIsLoadingMore(false);
    }
  };

  const handleSearchText = (e) => {
    setSearchText(e.target.value);
    localStorage.setItem("meeting_search_text", e.target.value);
  };

  const handleChangeTabs = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleAddNewMeeting = () => {
    navigate("/meetings/add-meeting");
  };

  const handleOpenDetail = (value) => {
    setOpenDetail(true);
    setMeetingDetail(value);
  };

  const handleEditMeeting = (value) => {
    navigate(`/meetings/edit-meeting/${value._id}`);
  };

  const handleMeetingContent = (value) => {
    navigate(`/meetings/meeting-content/${value._id}`, { state: value });
  };

  const handleAgreeDelete = (value) => {
    setOpenDelete(true);
    setMeetingId(value._id);
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    const result = await delete_meeting_api(meetingId);

    if (result.code === 200) {
      let AfterDeleteArray = meetingsData.filter(
        (item) => item._id !== meetingId
      );
      setMeetingsData(AfterDeleteArray);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "ant-design:edit-twotone",
      handleClick: handleEditMeeting,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
    {
      label: "View Detail",
      icon: "ant-design:eye-twotone",
      handleClick: handleOpenDetail,
    },
    {
      label: "Meeting Content",
      icon: "ant-design:eye-twotone",
      handleClick: handleMeetingContent,
    },
  ];

  const loadMoreData = () => {
    setIsLoadingMore(true);
    getMeetingList(loadMorePath, searchText, true);
  };

  useEffect(() => {
    if (!isLoading) {
      const timeoutId = setTimeout(() => {
        getMeetingList(loadMorePath, searchText);
      }, 800);
      return () => clearTimeout(timeoutId);
    }
  }, [searchText]);

  const lastBookElementRef = useCallback(
    (node) => {
      if (isLoadingMore) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && totalPages >= pageNumber) {
          loadMoreData();
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoadingMore, totalPages, pageNumber, loadMorePath, observer]
  );

  const tabObject = {
    meetingsData,
    MENU_OPTIONS,
    totalPages,
    pageNumber,
    isLoadingMore,
    handleOpenDetail,
    lastBookElementRef,
    loadMoreData,
  };

  const TABS_OPTIONS = [
    {
      title: "Upcoming",
      component: isLoading ? (
        <div className={classes.loading}>
          <CircularProgress color="primary" />
        </div>
      ) : (
        <MeetingItems data={{ ...tabObject, tabType: "upcoming" }} />
      ),
    },
    {
      title: "Previous",
      component: isLoading ? (
        <div className={classes.loading}>
          <CircularProgress color="primary" />
        </div>
      ) : (
        <MeetingItems data={{ ...tabObject, tabType: "previous" }} />
      ),
    },
  ];

  useEffect(() => {
    let find_search = localStorage.getItem("meeting_search_text");
    if (find_search) {
      setSearchText(find_search);
    }
    getMeetingList(load_more_path, find_search);
  }, [tabValue]);

  return (
    <div className="container">
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this Meeting?"}
        handleAgree={handleDelete}
      />
      <div className="row section-space">
        <div className="col-md-4">
          <h2>Meetings</h2>
        </div>
        <div className="col-md-8 text-end">
          <SearchStyle
            className="ms-auto"
            value={searchText}
            onChange={handleSearchText}
            placeholder="Search"
            startAdornment={
              <InputAdornment position="start">
                <Box
                  component={Icon}
                  icon={searchFill}
                  sx={{ color: "text.disabled" }}
                />
              </InputAdornment>
            }
          />
          <button
            onClick={handleAddNewMeeting}
            className="small-contained-button ms-3"
          >
            Add New Meeting
          </button>
        </div>
        <div className="col-12 mt-4">
          <MUICustomTabs
            data={TABS_OPTIONS}
            value={tabValue}
            handleChange={handleChangeTabs}
          />
        </div>
      </div>
      <GeneralPopUpModel
        open={openDetail}
        setOpen={setOpenDetail}
        title={"Meeting Detail"}
        componentToPassDown={<MeetingDetail meetingDetail={meetingDetail} />}
      />
    </div>
  );
};

export default MeetingsList;
