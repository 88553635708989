import React from "react";

export default function EventInfoForPost({ feed }) {
  let event_info = feed.event_info;
  return (
    <div>
      <div className="event-in-post">
        <div
          dangerouslySetInnerHTML={{
            __html: event_info.event_title,
          }}
        ></div>
        <div
          style={{
            textAlign: event_info.button_alignment
              ? event_info.button_alignment
              : "right",
          }}
        >
          <a
            href={event_info.button_link}
            className="post-event-button py-1"
            target="blank"
            style={{
              backgroundColor: event_info.button_background_color,
              color: event_info.button_text_color,
            }}
          >
            {event_info.button_text}
          </a>
        </div>
      </div>
    </div>
  );
}
