import React, { useEffect, useState } from "react";
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Box,
  CircularProgress,
} from "@mui/material";
import roundClearAll from "@iconify/icons-ic/round-clear-all";
import { get_root_value } from "src/utils/domUtils";
import { Icon } from "@iconify/react";
import { SaleSectionList } from "src/DAL/salesLeads/SaleLeads";
// import { SaleSectionList } from "src/DAL/SaleSections/saleSection";
// const useStyles = makeStyles(() => ({
//   loading: {
//     marginLeft: "50%",
//     marginTop: "20%",
//   },
// }));
export default function LeadsFilterDrawer({
  onCloseDrawer,
  dataList,
  value,
  handleChange,
  setSelectedValue,
}) {
  const [isLoading, setIsLoading] = useState(true);
  const [radioButtons, setradioButtons] = useState("");
  const handleSubmit = async (new_value) => {
    if (new_value === "filter") {
      dataList("filter");
    } else {
      setSelectedValue("all");
      dataList("all");
    }
    onCloseDrawer();
  };
  const getConsultantListing = async () => {
    const result = await SaleSectionList();
    if (result.code === 200) {
      setradioButtons(result.sections);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getConsultantListing();
  }, []);

  if (isLoading == true) {
    return (
      <div className="centered">
        {" "}
        <CircularProgress color="primary" />{" "}
      </div>
    );
  }

  return (
    <div className="container new-memories px-4">
      <FormControl component="fieldset" className="mt-4">
        <RadioGroup
          aria-label="gender"
          name="gender1"
          value={value}
          onChange={handleChange}
        >
          {radioButtons?.map((item, index) => {
            return (
              <FormControlLabel
                value={item._id}
                control={<Radio />}
                label={item.title}
              />
            );
          })}
        </RadioGroup>
      </FormControl>
      <Box sx={{ py: 2 }}>
        <Button
          fullWidth
          size="large"
          type="submit"
          color="inherit"
          variant="outlined"
          onClick={() => {
            handleSubmit("filter");
          }}
          startIcon={
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ marginRight: 5 }}
            >
              <path
                d="M13.1165 0.25H0.883548C0.321452 0.25 0.0378205 0.932013 0.436097 1.33029L5.3125 6.20743V11.6406C5.3125 11.8471 5.41325 12.0406 5.58242 12.1591L7.69179 13.6351C8.10794 13.9264 8.6875 13.6312 8.6875 13.1167V6.20743L13.564 1.33029C13.9615 0.932804 13.6798 0.25 13.1165 0.25Z"
                fill={get_root_value("--portal-theme-primary")}
              />
            </svg>
          }
        >
          Filter
        </Button>
      </Box>
    </div>
  );
}
