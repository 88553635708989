import { invokeApi } from "../../../bl_libs/invokeApi";

//venue api's
export const deletevenuesApi = async (id) => {
  const requestObj = {
    path: `api/vanue/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const addVenueApi = async (data) => {
  const requestObj = {
    path: `api/vanue`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const updatevenueApi = async (data, id) => {
  const requestObj = {
    path: `api/vanue/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const getVenueApi = async (id) => {
  let api_path = `api/faq`;
  if (id) {
    api_path = `api/vanue/${id}`;
  }
  const requestObj = {
    path: api_path,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
