import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { s3baseUrl } from "src/config/config";
import MUICustomTabs from "src/components/GeneralComponents/MUICustomTabs";
import { CircularProgress, IconButton } from "@mui/material";
import AllMemberList from "./AllMemberList";
import { GroupDetailApi } from "src/DAL/Groups/Groups";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ProgramsInfo from "./ProgramsInfo";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const CalendarGroupDetail = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const { group_slug } = useParams();
  const [groupPrograms, setGroupPrograms] = useState([]);
  const [groupMembers, setGroupMembers] = useState([]);
  const [groupEvents, setGroupEvents] = useState([]);
  const [groupData, setGroupData] = useState("");
  const [tabValue, setTabValue] = useState(0);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const groupDetail = async () => {
    setIsLoading(true);
    const result = await GroupDetailApi(group_slug);
    if (result.code == 200) {
      setIsLoading(false);
      setGroupEvents(
        result.group_events.map((item) => {
          return {
            ...item,
            table_avatar: {
              src: s3baseUrl + item.images.thumbnail_1,
              alt: item?.title,
            },
          };
        })
      );
      setGroupPrograms(
        result.group_programs.map((item) => {
          return {
            ...item,
            table_avatar: {
              src: s3baseUrl + item.program_images.thumbnail_1,
              alt: item?.title,
            },
          };
        })
      );
      setGroupMembers(
        result.group_members.map((item) => {
          let find_name = item.first_name + " " + item.last_name;
          return {
            ...item,
            find_name,
            table_avatar: {
              src: s3baseUrl + item?.profile_image,
              alt: item?.title,
            },
          };
        })
      );
      setGroupData(result.group);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    groupDetail();
  }, []);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    { id: "title", label: "Title" },
    {
      id: "table_avatar",
      label: "Picture",
      type: "thumbnail",
    },
    { id: "short_description", label: "Description" },
  ];

  const TABLE_HEAD_MEMBER = [
    { id: "number", label: "#", type: "number" },
    { id: "find_name", label: "Name" },
    {
      id: "table_avatar",
      label: "Profile",
      type: "thumbnail",
    },
    { id: "email", label: "Email" },
    {
      id: "program",
      label: "Programmes / Event",
      renderData: (row) => <ProgramsInfo row={row} groupData={groupData} />,
    },
  ];

  let settings = {
    className: "card-with-background mt-3 ",
  };

  const TABS_OPTIONS = [
    {
      title:
        groupData?.group_by === "event" ? "Events List" : "Programmes List",
      component: (
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={groupData?.group_by === "event" ? groupEvents : groupPrograms}
          pagePagination={true}
          {...settings}
        />
      ),
    },

    {
      title: "Group individual Member List",
      component: (
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD_MEMBER}
          data={groupMembers}
          pagePagination={true}
          {...settings}
        />
      ),
    },
    {
      title: "All Member List",
      component: <AllMemberList />,
    },
  ];

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12 d-flex mb-3">
            <span>
              <IconButton
                className="back-screen-button"
                onClick={() => navigate(`/groups`)}
              >
                <ArrowBackIcon />
              </IconButton>
            </span>
            <h2 className="ms-1">{groupData.title}</h2>
          </div>
        </div>
        <MUICustomTabs
          data={TABS_OPTIONS}
          value={tabValue}
          handleChange={handleChange}
        />
      </div>
    </>
  );
};

export default CalendarGroupDetail;
