import React, { useState } from "react";
import {
  FormControl,
  FormControlLabel,
  Button,
  Box,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  TextField,
} from "@mui/material";
import roundClearAll from "@iconify/icons-ic/round-clear-all";
import { get_root_value } from "src/utils/domUtils";
import { Icon } from "@iconify/react";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import { useEffect } from "react";
import MUIAutocomplete from "../GeneralComponents/MUIAutocomplete";
import { COMMUNITY_LEVELS, show_proper_words } from "src/utils/constants";
import { get_member_filter_data_list_api } from "src/DAL/member/Member";
import { countries } from "src/utils/country";
import Autocomplete from "@mui/material/Autocomplete";

export default function MemberFilter({
  filterData,
  handleChangeOthers,
  handleChange,
  handleExistingFilter,
  SaveFilter,
  handleChangeSave,
  searchSubmitFilter,
  handleClearFilter,
  handleSave,
  is_delegate_filter,
  is_nurture_filter,
  consultant_both,
  filter_on_tab_name,
  handleIsCountry,
}) {
  const [salePages, setSalePages] = useState([]);
  const [exsistingFilter, setExsistingFilter] = useState([]);
  const [plansList, setPlansList] = useState([]);
  const [leadStatus, setLeadStatus] = useState([]);
  const [nurturesList, setNurturesList] = useState([]);
  const [delegatesList, setDelegatesList] = useState([]);
  const [inputs, setInputs] = useState({ filter_From: "new" });
  const [searchText, setSearchText] = useState("");
  const [delegateSearch, setDelegateSearch] = useState("");

  const getDataList = async () => {
    let result = await get_member_filter_data_list_api(
      searchText,
      delegateSearch,
      filter_on_tab_name
    );

    if (result.code == 200) {
      setExsistingFilter(result.saved_portal_filter);
      let sale_pages = result.sale_pages.map((sale_page) => {
        return {
          ...sale_page,
          chip_label: sale_page.sale_page_title,
          chip_value: sale_page._id,
        };
      });
      setSalePages(sale_pages);

      let lead_status = result.lead_status.map((status) => {
        return {
          ...status,
          chip_label: status.title,
          chip_value: status._id,
        };
      });
      let no_lead_status = {
        _id: "no_lead_status",
        title: "No Lead Status",
      };
      setLeadStatus([no_lead_status, ...lead_status]);

      let delegates = result.delegates.map((delegate) => {
        let name = delegate.first_name + " " + delegate.last_name;
        return {
          ...delegate,
          name: name + " | " + show_proper_words(delegate.team_type),
          chip_label: name,
          chip_value: delegate._id,
        };
      });
      setNurturesList(delegates);

      let delegates_list = result.delegates_list.map((delegate) => {
        let name = delegate.first_name + " " + delegate.last_name;
        return {
          ...delegate,
          name: name + " | " + show_proper_words(delegate.team_type),
          chip_label: name,
          chip_value: delegate._id,
        };
      });
      setDelegatesList(delegates_list);
    } else {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
    }
  };
  delete COMMUNITY_LEVELS[0];
  let community_array = COMMUNITY_LEVELS.map((level) => {
    return {
      ...level,
      chip_label: level.title,
      chip_value: level.name,
    };
  });

  const set_plans_list = (plans) => {
    let payment_plans = plans.map((plan) => {
      return {
        ...plan,
        chip_label: plan.plan_title,
        chip_value: plan._id,
      };
    });
    setPlansList(payment_plans);
  };

  const new_lead_status_array = leadStatus.map((status) => {
    return {
      ...status,
      chip_label: status.title,
      chip_value: status._id,
    };
  });
  let countries_array = countries.map((item) => {
    return {
      ...item,
      chip_label: item.label,
      chip_value: item.code,
    };
  });
  const handleChangeFilterType = (event) => {
    const { name, value } = event.target;
    setInputs((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };
  const handleChangePage = (value) => {
    if (value) {
      set_plans_list(value.payment_plans);
    } else {
      setPlansList([]);
    }
    handleChangeOthers(value, "event_page");
    handleChangeOthers(null, "plan");
  };
  const handleExsitingFilter = (value) => {
    handleExistingFilter(value);
  };
  useEffect(() => {
    getDataList();
    if (filterData.event_page) {
      set_plans_list(filterData.event_page.payment_plans);
    }
  }, [searchText, delegateSearch]);
  return (
    <div className="container new-memories px-4">
      <form className="row" onSubmit={searchSubmitFilter}>
        <div className="col-12">
          <FormControl fullWidth className="mt-3">
            <InputLabel id="demo-simple-select-label">Filter From</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={SaveFilter.filter_From}
              name="filter_From"
              label="Filter From"
              onChange={handleChangeSave}
            >
              <MenuItem value={"new"}>Apply New Filter</MenuItem>
              <MenuItem value={"existing"}>Apply Saved Filter</MenuItem>
            </Select>
          </FormControl>
        </div>
        <>
          {SaveFilter.filter_From == "existing" && (
            <div className="mt-3">
              <MUIAutocomplete
                inputLabel="Saved Filter"
                selectedOption={SaveFilter.filter_name}
                setSelectedOption={handleExsitingFilter}
                optionsList={exsistingFilter}
                name="filter_name"
              />
            </div>
          )}
          {SaveFilter.filter_From == "new" ||
          SaveFilter.filter_name !== null ? (
            <>
              <div className="mt-3">
                <MUIAutocomplete
                  inputLabel="Sale Pages"
                  selectedOption={filterData.event_page}
                  setSelectedOption={handleChangePage}
                  optionsList={salePages}
                  name="sale_page_title"
                />
              </div>
              <div className="mt-3">
                <MUIAutocomplete
                  inputLabel="Choose Plan"
                  selectedOption={filterData.plan}
                  setSelectedOption={(e) => {
                    handleChangeOthers(e, "plan");
                  }}
                  optionsList={plansList}
                  name="plan_title"
                />
              </div>
              {is_nurture_filter && (
                <div className={`mt-3 ${consultant_both ? "" : "d-none"}`}>
                  <MUIAutocomplete
                    inputLabel=" Choose Nurture"
                    selectedOption={filterData.nurture}
                    setSelectedOption={(e) => {
                      handleChangeOthers(e, "nurture");
                    }}
                    optionsList={nurturesList}
                    name="name"
                    setCustomSearch={setSearchText}
                  />
                </div>
              )}
              {is_delegate_filter && (
                <div className="mt-3">
                  <MUIAutocomplete
                    inputLabel=" Choose Delegate"
                    selectedOption={filterData.delegate}
                    setSelectedOption={(e) => {
                      handleChangeOthers(e, "delegate");
                    }}
                    optionsList={delegatesList}
                    name="name"
                    setCustomSearch={setDelegateSearch}
                  />
                </div>
              )}
              <div className="mt-3">
                <MUIAutocomplete
                  inputLabel="Lead Status"
                  multiple
                  selectedOption={filterData.lead_status}
                  setSelectedOption={(e) => {
                    handleChangeOthers(e, "lead_status");
                  }}
                  optionsList={new_lead_status_array}
                  name="title"
                />
              </div>
              <div className="mt-3">
                <MUIAutocomplete
                  inputLabel="Levels"
                  selectedOption={filterData.community}
                  setSelectedOption={(e) => {
                    handleChangeOthers(e, "community");
                  }}
                  optionsList={community_array}
                  multiple
                  name="title"
                />
              </div>
              <div className="col-12">
                <FormControl fullWidth className="mt-3">
                  <InputLabel id="demo-simple-select-label">
                    Source Member Ship
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={filterData.member_user_type}
                    name="member_user_type"
                    label=" Source Member Ship"
                    onChange={handleChange}
                  >
                    <MenuItem value="">None</MenuItem>
                    <MenuItem value="free">Free Source Member Ship</MenuItem>
                    <MenuItem value="paid">Paid Source Member Ship</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="col-12">
                <FormControl fullWidth className="mt-3">
                  <InputLabel id="demo-simple-select-label">
                    Member Status
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={filterData.status}
                    name="status"
                    label="Member Status"
                    onChange={handleChange}
                  >
                    <MenuItem value="">None</MenuItem>
                    <MenuItem value={true}>Active</MenuItem>
                    <MenuItem value={false}>Inactive</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="col-12">
                <FormControl fullWidth className="mt-3">
                  <InputLabel id="demo-simple-select-label">
                    Online Status
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={filterData.user_status_type}
                    name="user_status_type"
                    label="Online Status"
                    onChange={handleChange}
                  >
                    <MenuItem value="">All</MenuItem>
                    <MenuItem value="online">Online</MenuItem>
                    <MenuItem value="offline">Offline</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="col-12 mt-3">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Membership Status
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="member_ship_expiry"
                    value={filterData.member_ship_expiry}
                    label="Membership Status"
                    onChange={handleChange}
                  >
                    <MenuItem value="">None</MenuItem>
                    <MenuItem value="expired">Expired</MenuItem>
                    <MenuItem value="not_expired">Active</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="col-12 mt-3">
                <Autocomplete
                  id="country-select-demo"
                  options={countries_array}
                  autoHighlight
                  value={filterData.country ? filterData.country : null}
                  onChange={(event, newValue) => {
                    handleIsCountry(newValue);
                  }}
                  getOptionLabel={(option) => option.label}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      <img
                        loading="lazy"
                        width="20"
                        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                        alt=""
                      />
                      {option.label} ({option.code})
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Choose  Country"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "off", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              </div>
              {filterData.member_ship_expiry == "not_expired" && (
                <div className="col-12 mt-3">
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Expiry In
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="expiry_in"
                      value={filterData.expiry_in}
                      label="Expiry In"
                      onChange={handleChange}
                    >
                      <MenuItem value={3}>3 Days</MenuItem>
                      <MenuItem value={7}>7 Days</MenuItem>
                      <MenuItem value={15}>15 Days</MenuItem>
                      <MenuItem value={30}>30 Days</MenuItem>
                      <MenuItem value="custom">Custom</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              )}
              {filterData.member_ship_expiry === "not_expired" &&
                filterData.expiry_in === "custom" && (
                  <>
                    <div className="col-12 mt-3">
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          label="Membership expiry Start Date"
                          name="membership_purchase_expiry_from"
                          value={filterData.membership_purchase_expiry_from}
                          format="YYYY-MM-DD"
                          inputFormat="dd-MM-yyyy"
                          o
                          onChange={(e) =>
                            handleChangeOthers(
                              e,
                              "membership_purchase_expiry_from"
                            )
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              className="inputs-fields w-100"
                              required
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                    <div className="col-12 mt-3">
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          label="Membership expiry End Date"
                          name="membership_purchase_expiry_to"
                          value={filterData.membership_purchase_expiry_to}
                          format="YYYY-MM-DD"
                          inputFormat="dd-MM-yyyy"
                          onChange={(e) =>
                            handleChangeOthers(
                              e,
                              "membership_purchase_expiry_to"
                            )
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              className="inputs-fields w-100"
                              required
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </>
                )}
              <FormControlLabel
                className="mt-2"
                control={
                  <Checkbox
                    checked={filterData.is_date_range}
                    onChange={(e) => {
                      handleChangeOthers(e.target.checked, "is_date_range");
                    }}
                  />
                }
                label="Search By Date Range"
              />
              {filterData.is_date_range && (
                <div className="col-12">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Start Date"
                      name="from_date"
                      value={filterData.from_date}
                      format="YYYY-MM-DD"
                      inputFormat="dd-MM-yyyy"
                      onChange={(e) => handleChangeOthers(e, "from_date")}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className="mt-3 inputs-fields"
                          required
                        />
                      )}
                    />
                  </LocalizationProvider>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="End Date"
                      name="to_date"
                      value={filterData.to_date}
                      format="YYYY-MM-DD"
                      inputFormat="dd-MM-yyyy"
                      onChange={(e) => handleChangeOthers(e, "to_date")}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className="mt-3 inputs-fields"
                          required
                        />
                      )}
                    />
                  </LocalizationProvider>
                </div>
              )}
              <FormControlLabel
                className="mt-2"
                control={
                  <Checkbox
                    checked={filterData.coins_range}
                    onChange={(e) => {
                      handleChangeOthers(e.target.checked, "coins_range");
                    }}
                  />
                }
                label="Search By Coins"
              />
              {filterData.coins_range && (
                <>
                  <div className="col-6">
                    <TextField
                      className="mt-3 inputs-fields"
                      type="number"
                      id="coins_from"
                      label="Coins From"
                      variant="outlined"
                      name="coins_from"
                      value={filterData.coins_from}
                      required
                      InputProps={{ inputProps: { min: 0 } }}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-6">
                    <TextField
                      className="mt-3 inputs-fields"
                      type="number"
                      id="coins_to"
                      label="Coins To"
                      variant="outlined"
                      name="coins_to"
                      value={filterData.coins_to}
                      required
                      InputProps={{
                        inputProps: { min: filterData.coins_from },
                      }}
                      onChange={handleChange}
                    />
                  </div>
                </>
              )}
            </>
          ) : (
            ""
          )}
        </>
        <div className="col-12">
          <Box sx={{ py: 2 }}>
            <Button
              fullWidth
              size="large"
              type="submit"
              color="inherit"
              variant="outlined"
              startIcon={
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ marginRight: 5 }}
                >
                  <path
                    d="M13.1165 0.25H0.883548C0.321452 0.25 0.0378205 0.932013 0.436097 1.33029L5.3125 6.20743V11.6406C5.3125 11.8471 5.41325 12.0406 5.58242 12.1591L7.69179 13.6351C8.10794 13.9264 8.6875 13.6312 8.6875 13.1167V6.20743L13.564 1.33029C13.9615 0.932804 13.6798 0.25 13.1165 0.25Z"
                    fill={get_root_value("--portal-theme-primary")}
                  />
                </svg>
              }
            >
              Filter
            </Button>
          </Box>
        </div>
        <div className="col-12">
          <Button
            fullWidth
            size="large"
            type="submit"
            color="inherit"
            variant="outlined"
            className="mb-3"
            onClick={handleClearFilter}
            startIcon={<Icon icon={roundClearAll} />}
          >
            Clear All
          </Button>
        </div>
      </form>
    </div>
  );
}
