import * as React from "react";
import { useState, useEffect, useCallback, useRef } from "react";
import { useNavigate } from "react-router";
import { Box, Card, Chip } from "@mui/material";
import { CircularProgress, Stack, Pagination, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Icon } from "@iconify/react";
import Tooltip from "@mui/material/Tooltip";
import roundFilterList from "@iconify/icons-ic/round-filter-list";
import { useSnackbar } from "notistack";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import moment from "moment";
import ModelBox from "src/components/ModalBox/ImageModalBox";
import CustomDrawer from "src/components/GeneralComponents/CustomDrawer";
import CancelIcon from "@mui/icons-material/Cancel";

import NotesDrawer from "src/components/GeneralComponents/NotesDrawer";
import AddOrUpdateProgress from "src/components/Progress/AddorUpdateProgress";
import { s3baseUrl } from "src/config/config";

// import RoomCard from "./RoomCard";
import {
  capitalizeFirst,
  get_date_with_user_time_zone,
} from "src/utils/constants";
import { useContentSetting } from "src/Hooks/ContentSettingState";
import NotesIcon from "@mui/icons-material/Notes";
import { htmlDecode } from "src/utils/convertHtml";
import CustomPopover from "src/components/CustomPopover";
import CustomConfirmation from "src/components/GeneralComponents/CustomConfirmation";
import { DeleteProgress, ProgressListApi } from "src/DAL/Progress/Progress";
import { ProgressFilter } from "src/components/Progress/ProgressFilter";
import Note from "src/components/Progress/Note";

import Badge from "@mui/material/Badge";
import MailIcon from "@mui/icons-material/Mail";
import MarkUnreadChatAltIcon from "@mui/icons-material/MarkUnreadChatAlt";
import RecordNotFound from "src/components/RecordNotFound";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function Progress() {
  const classes = useStyles();
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();
  const [drawerState, setDrawerState] = useState(false);
  const [filterDrawerStare, setFilterDrawerState] = useState(false);
  const [drawerInternalNote, setdrawerInternalNote] = useState(false);
  const [comments, setComments] = useState([]);
  const [editDrawerState, setEditDrawerState] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { userInfo, adminTimeZone } = useContentSetting();
  const [progressId, setProgressId] = useState("");
  const [inputs, setInputs] = useState({
    start_date: null,
    end_date: null,
    // start_date: moment(new Date()).format("YYYY-MM-DD"),
    // end_date: moment(new Date()).format("YYYY-MM-DD"),
  });

  const [check, setCheck] = useState("");
  const [data, setData] = useState({});
  const [pageNumber, setPageNumber] = useState(0);
  const [totlePages, setTotlePages] = useState(0);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [loadMorePath, setLoadMorePath] = useState("");
  const [filterType, setFilterType] = useState("");
  const [InternalNoteData, setInternalNoteData] = useState();
  const [loadMore, setLoadMore] = useState(
    `api/progress_report/list_progress_report_by_filter_v2?page=0&limit=10`
  );
  const [selectedValue, setSelectedValue] = React.useState("all");
  const [open, setOpen] = useState(false);
  const [categoryValue, setCategoryValue] = useState("");
  const [state, setState] = useState();
  const [categoryData, setCategoryData] = useState([]);

  const [imageUrl, setImageUrl] = useState("");
  const handleOpen = (image_path) => {
    setImageUrl(image_path);
    setOpen(true);
  };
  const handleChipDeletetype = () => {
    getProgressListNewFilter(
      `api/progress_report/list_progress_report_by_filter_v2?page=0&limit=10`,
      "",
      "",
      "",
      "value",
      ""
    );

    setSelectedValue("all");

    setIsLoading(true);
  };
  const handleChipDeletedate = () => {
    getProgressListNewFilter(
      `api/progress_report/list_progress_report_by_filter_v2?page=0&limit=10`,
      "",
      "",
      "",
      "date",
      ""
    );

    setInputs({
      start_date: null,
      end_date: null,
    });
    setCheck(false);

    setIsLoading(true);
  };
  const handleChipDeletecategory = () => {
    getProgressListNewFilter(
      `api/progress_report/list_progress_report_by_filter_v2?page=0&limit=10`,
      "",
      "",
      "",
      "category",
      ""
    );

    setState("");
    setCategoryValue("");
    setIsLoading(true);
  };
  const handleClose = () => setOpen(false);
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const observer = useRef();

  const lastBookElementRef = useCallback(
    (node) => {
      if (isLoadingMore) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && totlePages > pageNumber) {
          loadMoreData();
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoadingMore, totlePages, pageNumber, loadMorePath, observer]
  );

  const loadMoreData = () => {
    setLoadMore(loadMorePath);
    setIsLoadingMore(true);
  };

  const handleOpenDrawer = () => {
    setDrawerState(true);
  };
  const handleCloseDrawer = () => {
    setDrawerState(false);
  };
  const handleOpenFilterDrawer = () => {
    setFilterDrawerState(true);
  };
  const handleCloseFilterDrawer = () => {
    setFilterDrawerState(false);
  };
  const handleOpenEditDrawer = (value) => {
    setProgressId(value._id);
    setEditDrawerState(true);
  };
  const handleCloseEditDrawer = () => {
    setEditDrawerState(false);
  };

  const handleOpenInternalNoteDrawer = (id, internalNotes) => () => {
    setdrawerInternalNote(true);
    setProgressId(id);
    setInternalNoteData(internalNotes);
  };
  const handleCLoseInternalNoteCLoseDrawer = () => {
    setdrawerInternalNote(false);
    getProgressListNew(
      `api/progress_report/list_progress_report_by_filter_v2?page=0&limit=10`
    );
  };
  const handleAgreeDelete = (value) => {
    setProgressId(value._id);

    setOpenDelete(true);
  };
  const handleDelete = async () => {
    setOpenDelete(false);

    const result = await DeleteProgress(progressId);
    if (result.code === 200) {
      getProgressListingOnDelete(progressId);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleOpenEditDrawer,
    },

    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  const getProgressListingOnDelete = async (id) => {
    // const data = comments.filter((comment) => comment._id !== id);
    // setComments(data);
    getProgressListNew(
      `api/progress_report/list_progress_report_by_filter_v2?page=0&limit=10`
    );
  };

  const getProgressListNew = async (path) => {
    setLoadMore("");
    const result = await ProgressListApi(path);
    if (result.code === 200) {
      setComments(result.progress_report);

      setPageNumber(1);

      setTotlePages(result.total_pages);
      setLoadMorePath(result.load_more);
      setIsLoading(false);
      setIsLoadingMore(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingMore(false);
    }
  };
  const getProgressListNewFilter = async (
    path,
    filterData,
    value,
    category,
    type,
    categoryObject
  ) => {
    setLoadMore("");
    setIsLoading(true);
    setLoadMore("");
    setFilterType(type);
    if (type == "filter") {
      setCategoryValue(categoryObject);
      setSelectedValue(value);
    }

    let filterDataVal;

    filterDataVal = {
      // start_date: filterData.start_date,
      // end_date: filterData.end_date,
      // filter_by: value,
      // progress_category: category,
    };

    if (type === "filter") {
      filterDataVal.filter_by = value;

      if (category == "none") {
        filterDataVal.progress_category = "";
      } else {
        filterDataVal.progress_category = category;
      }

      if (filterData.start_date !== null && filterData.end_date == null) {
        filterDataVal.end_date = filterData.start_date;
      } else {
        filterDataVal.end_date = filterData.end_date;
      }
      if (filterData.end_date !== null && filterData.start_date == null) {
        filterDataVal.start_date = filterData.end_date;
      } else {
        filterDataVal.start_date = filterData.start_date;
      }
    } else if (type == "value") {
      filterDataVal.filter_by = "all";
      if (categoryValue?._id == "none" || categoryValue == undefined) {
        filterDataVal.progress_category = "";
      } else {
        filterDataVal.progress_category = categoryValue?._id;
      }

      if (inputs.start_date !== null && inputs.end_date == null) {
        filterDataVal.end_date = inputs.start_date;
      } else {
        filterDataVal.end_date = inputs.end_date;
      }
      if (inputs.end_date !== null && inputs.start_date == null) {
        filterDataVal.start_date = inputs.end_date;
      } else {
        filterDataVal.start_date = inputs.start_date;
      }
    } else if (type == "date") {
      filterDataVal.start_date = null;
      filterDataVal.end_date = null;
      if (categoryValue?._id == "none" || categoryValue == undefined) {
        filterDataVal.progress_category = "";
      } else {
        filterDataVal.progress_category = categoryValue?._id;
      }
      filterDataVal.filter_by = selectedValue;
    } else if (type == "category") {
      filterDataVal.progress_category = "";
      filterDataVal.filter_by = selectedValue;

      if (inputs.start_date !== null && inputs.end_date == null) {
        filterDataVal.end_date = inputs.start_date;
      } else {
        filterDataVal.end_date = inputs.end_date;
      }
      if (inputs.end_date !== null && inputs.start_date == null) {
        filterDataVal.start_date = inputs.end_date;
      } else {
        filterDataVal.start_date = inputs.start_date;
      }
    } else {
      filterDataVal = "";
      setData("");
    }
    // let postData = {
    //   type: "",
    //   cateory: "",
    // };
    // if (checked) {
    //   postData.start_date = inputs.start_date;
    // }

    const result = await ProgressListApi(
      path,

      filterDataVal && filterDataVal
    );

    if (result.code === 200) {
      if (filterData) {
        // setPageNumber(pageNumber + 1);
        setPageNumber(1);
      } else {
        setPageNumber(1);
      }
      setComments(result.progress_report);

      setTotlePages(result.total_pages);
      setLoadMorePath(result.load_more);

      if (type == "filter" || "value" || "date" || "category") {
        setData(filterDataVal);
      }
      setIsLoadingMore(false);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingMore(false);
      setIsLoading(false);
    }
  };
  const getProgressList = async () => {
    // console.log(loadMore, "loadmoreee");
    let fData;
    if (filterType == "filter" || "value" || "date" || "category") {
      fData = data;
    } else {
      fData = "";
    }
    if (loadMore !== "") {
      const result = await ProgressListApi(loadMore, fData && fData);

      if (result.code === 200) {
        const new_comments = result.progress_report.map((report) => {
          return { ...report };
        });
        // const new_comments = result.progress_report;

        var newArray = comments.concat(new_comments);

        setComments(newArray);

        setPageNumber(pageNumber + 1);
        setTotlePages(result.total_pages);
        setLoadMorePath(result.load_more);
        setIsLoadingMore(false);
        setIsLoading(false);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoadingMore(false);
      }
    }
  };

  useEffect(() => {
    getProgressList();
  }, [loadMore]);

  moment.tz.setDefault(userInfo.time_zone);
  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  // console.log(comments, "comments");
  return (
    <div className="container">
      <div className="row section-space">
        <div className="col-sm-12 col-md-6">
          <h2>Progress List</h2>
        </div>
        <div className="col-sm-12 col-md-6 text-end">
          <button
            onClick={handleOpenDrawer}
            className="small-contained-button me-2 mt-1 mb-4"
          >
            Add Progress
          </button>

          <button
            className="small-contained-button me-2 mt-1 mb-4"
            onClick={handleOpenFilterDrawer}
          >
            Filter &nbsp;&nbsp; <Icon icon={roundFilterList} />
          </button>
        </div>
      </div>

      {Object.keys(data).length > 0 ? (
        <Stack direction="row" spacing={0.5}>
          {(data.start_date ||
            data.filter_by != "all" ||
            (categoryValue && categoryValue._id !== "none")) && (
            <div>
              <span style={{ fontWeight: 500, fontSize: "14px" }}>
                Filtered By :{" "}
              </span>
            </div>
          )}
          {data.filter_by != "all" ? (
            <Chip
              sx={{ color: "white", fontSize: "12px", fontWeight: 500 }}
              size="small"
              label={
                data.filter_by.charAt(0).toUpperCase() + data.filter_by.slice(1)
              }
              onDelete={handleChipDeletetype}
              deleteIcon={<CancelIcon />}
            />
          ) : null}

          {data.start_date ? (
            <>
              <Chip
                sx={{ color: "white" }}
                size="small"
                label={
                  moment(data.start_date).format("DD-MM-YYYY") +
                  " " +
                  "-" +
                  " " +
                  moment(data.end_date).format("DD-MM-YYYY")
                }
                onDelete={handleChipDeletedate}
                deleteIcon={<CancelIcon />}
              />
            </>
          ) : null}
          {categoryValue && categoryValue._id !== "none" ? (
            <Chip
              sx={{ color: "white" }}
              size="small"
              label={
                categoryValue.title.charAt(0).toUpperCase() +
                categoryValue.title.slice(1)
              }
              onDelete={handleChipDeletecategory}
              deleteIcon={<CancelIcon />}
            />
          ) : null}
          {(data.start_date ||
            data.filter_by != "all" ||
            (categoryValue && categoryValue._id !== "none")) && (
            <div>
              <p>
                <a
                  onClick={() => {
                    getProgressListNew(
                      `api/progress_report/list_progress_report_by_filter_v2?page=0&limit=10`,
                      "",
                      "clear"
                    );

                    setInputs({
                      start_date: null,
                      end_date: null,
                    });
                    setCheck(false);
                    setState("");
                    setSelectedValue("all");

                    setData("");
                    setIsLoading(true);
                  }}
                  style={{ color: "#f6bd4b", cursor: "pointer" }}
                >
                  {" "}
                  CLEAR ALL
                </a>
              </p>
            </div>
          )}
        </Stack>
      ) : null}

      {comments?.length > 0 ? (
        comments.map((comment, i) => {
          return (
            <Card className="chat-main-card p-2 mt-3 ">
              <div className="w-100 ps-2 pe-3">
                <div className="chat-message-body pt-1 pb-4 mt-2">
                  <div className="row  ">
                    <div className="d-flex">
                      <div className="circle-image">
                        <img src={s3baseUrl + comment.user_info.image} />
                      </div>

                      <div className="d-flex justify-content-between align-items-center w-100 pe-3">
                        <div className="d-flex  mb-0  flex-column">
                          <p className="card-title weight mb-0">
                            {htmlDecode(comment.user_info.first_name)}{" "}
                            {htmlDecode(comment.user_info.last_name)}
                          </p>
                          <span className="type-color-small">
                            {comment.report_type.charAt(0).toUpperCase() +
                              comment.report_type.slice(1)}{" "}
                            {/* {comment.progress_category?.title
                              ?.charAt(0)
                              .toUpperCase() +
                              comment.progress_category?.title?.slice(1)} */}
                          </span>
                          {/* <span className="type-color-small">
                            {comment.report_type.charAt(0).toUpperCase() +
                              comment.report_type.slice(1)}
                          </span> */}
                        </div>

                        <div className=" custom-popover-box">
                          <div className="popover-custom">
                            {userInfo._id == comment.user_info._id && (
                              <CustomPopover
                                menu={MENU_OPTIONS}
                                data={comment}
                              />
                            )}
                          </div>
                          <span className="me-2 date-size">
                            {moment(comment.start_date).format("DD-MM-YYYY")}

                            {comment.report_type === "custom" ||
                            comment.report_type === "monthly" ||
                            comment.report_type === "weekly" ? (
                              <>
                                <span className="ms-1 me-1 date-heading ">
                                  -
                                </span>

                                {moment(comment.end_date).format("DD-MM-YYYY")}
                              </>
                            ) : null}
                          </span>
                          <span className="me-4">
                            <Badge
                              badgeContent={
                                comment.internal_note.length === 0
                                  ? "0"
                                  : comment.internal_note.length
                              }
                              color="primary"
                              style={{ cursor: "pointer" }}
                              title="View Notes"
                              onClick={handleOpenInternalNoteDrawer(
                                comment._id,
                                comment.internal_note
                              )}
                            >
                              <MarkUnreadChatAltIcon
                                color="primary"
                                fontSize="small"
                                // className="me-1 mb-1"
                              />
                            </Badge>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ms-3">
                    <p className="mt-2 pe-3 ">
                      {
                        <div
                          dangerouslySetInnerHTML={{
                            __html: comment.description,
                          }}
                        ></div>
                      }
                    </p>

                    {comment.image && (
                      <span className="">
                        <Box
                          // border="2px solid #f6bd4b"
                          height="76px"
                          width="76px"
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <img
                            // onClick={() => {
                            //   handleOpen(s3baseUrl + comment.image.thumbnail_1);
                            // }}

                            onClick={() => {
                              window.open(
                                s3baseUrl + comment.image.thumbnail_1,
                                "_blank"
                              );
                            }}
                            style={{
                              maxWidth: "100%",
                              maxHeight: "100%",
                              cursor: "pointer",
                            }}
                            src={s3baseUrl + comment.image.thumbnail_2}
                          />
                        </Box>
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </Card>
          );
        })
      ) : (
        <RecordNotFound title="Progress Report " />
      )}

      {totlePages > pageNumber ? (
        <div className="col-12 text-center favourite-buttton-box">
          <button
            ref={lastBookElementRef}
            className="small-contained-button mt-3"
            onClick={loadMoreData}
            id="load-more-feed"
          >
            {/* Loading */}
            {isLoadingMore ? "Loading..." : "Load More"}
          </button>
        </div>
      ) : (
        ""
      )}
      <CustomDrawer
        isOpenDrawer={drawerState}
        onOpenDrawer={handleOpenDrawer}
        onCloseDrawer={handleCloseDrawer}
        pageTitle="Add New Progress"
        componentToPassDown={
          <AddOrUpdateProgress
            formType="ADD"
            dataList={getProgressListNew}
            onCloseDrawer={handleCloseDrawer}
          />
        }
      />
      <CustomDrawer
        isOpenDrawer={editDrawerState}
        onOpenDrawer={handleOpenEditDrawer}
        onCloseDrawer={handleCloseEditDrawer}
        pageTitle="Edit"
        componentToPassDown={
          <AddOrUpdateProgress
            editValues={progressId}
            dataList={getProgressListNew}
            onCloseDrawer={handleCloseEditDrawer}
            formType="EDIT"
            setComments={setComments}
          />
        }
      />
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this Report?"}
        handleAgree={handleDelete}
      />
      <CustomDrawer
        isOpenDrawer={filterDrawerStare}
        onOpenDrawer={handleOpenFilterDrawer}
        onCloseDrawer={handleCloseFilterDrawer}
        pageTitle="Filter"
        componentToPassDown={
          <ProgressFilter
            dataList={getProgressListNewFilter}
            inputs={inputs}
            setInputs={setInputs}
            check={check}
            setCheck={setCheck}
            onCloseDrawer={handleCloseFilterDrawer}
            value={selectedValue}
            handleChange={handleChange}
            setSelectedValue={setSelectedValue}
            state={state}
            setState={setState}
            categoryData={categoryData}
            setCategoryData={setCategoryData}
          />
        }
      />
      <ModelBox open={open} handleClose={handleClose} image_url={imageUrl} />
      <NotesDrawer
        isOpenDrawer={drawerInternalNote}
        onOpenDrawer={handleOpenInternalNoteDrawer}
        onCloseDrawer={handleCLoseInternalNoteCLoseDrawer}
        pageTitle="Notes"
        componentToPassDown={
          <Note
            formType="ADD"
            progressId={progressId}
            internalNotes={InternalNoteData}
            onCloseDrawer={handleCLoseInternalNoteCLoseDrawer}
          />
        }
      />
    </div>
  );
}
