import faker from "faker";
import PropTypes from "prop-types";
import { noCase } from "change-case";
import { useCallback, useRef, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { set, sub, formatDistanceToNow } from "date-fns";
import { Icon } from "@iconify/react";
import bellFill from "@iconify/icons-eva/bell-fill";
import CircleIcon from "@mui/icons-material/Circle";
import clockFill from "@iconify/icons-eva/clock-fill";
import doneAllFill from "@iconify/icons-eva/done-all-fill";
// material
import { alpha } from "@mui/material/styles";
import {
  Box,
  List,
  Badge,
  Button,
  Avatar,
  Tooltip,
  Divider,
  IconButton,
  Typography,
  ListItemText,
  ListSubheader,
  ListItemAvatar,
  ListItemButton,
} from "@mui/material";
// utils
import { mockImgAvatar } from "../../utils/mockImages";
// components
import Scrollbar from "../../components/Scrollbar";
import MenuPopover from "../../components/MenuPopover";
import {
  commentIcon,
  communityHandScreenshot,
  communityHeart,
} from "src/assets";
import { notification_list_with_pagination } from "src/DAL/Notification/Notification";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { s3baseUrl } from "src/config/config";
import {
  capitalizeFirst,
  get_date_with_user_time_zone,
} from "src/utils/constants";
import moment from "moment";
import { useContentSetting } from "src/Hooks/ContentSettingState";
import CustomPopover from "src/components/CustomPopover";

// ----------------------------------------------------------------------

export default function NotificationsPopover({
  notifications,
  pageNumber,
  totalPages,
  setIsLoadingMore,
  isLoadingMore,
  unreadNotificationCount,
  getMoreNotificationList,
  loadMorePath,
  handleRedirect,
  handleMarkAllAsRead,
  handleOpenAllDelete,
  handle_menu_options,
  setOpen,
  open,
}) {
  const anchorRef = useRef(null);
  const observer = useRef();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoadMore, setIsLoadMore] = useState(false);
  const { userInfo, adminTimeZone } = useContentSetting();

  const totalUnRead = unreadNotificationCount;

  // moment.tz.setDefault(userInfo.time_zone);
  function renderContent(notification) {
    const title = (
      <Typography variant="subtitle2" className="notification-title">
        {/* {notification.title} */}
        <Typography
          component="span"
          variant="body2"
          sx={{ color: "text.secondary" }}>
          {/* <br /> */}
          {noCase(notification.notification_title)}
        </Typography>
      </Typography>
    );

    return {
      avatar: (
        <img
          alt={notification.title}
          src={notification.user_info_sender?.profile_image}
        />
      ),
      title,
    };
  }

  NotificationItem.propTypes = {
    notification: PropTypes.object.isRequired,
  };

  function NotificationItem({ notification }) {
    const { avatar, title } = renderContent(notification);
    const notification_date = get_date_with_user_time_zone(
      notification.createdAt,
      "YYYY-MM-DD HH:mm",
      userInfo.time_zone,
      adminTimeZone
    );
    console.log(notification.is_seen, "notification.is_seen ");

    return (
      <div
        to="#"
        disableGutters
        // component={RouterLink}
        sx={{
          p: 1.5,
          // px: 2.5,
          mt: "1px",
          ...(notification.isUnRead && {
            // bgcolor: "action.selected",
            color: "#fff",
          }),
        }}
        className={`notification-item-box ${
          notification.is_seen === true
            ? "notification-item"
            : "notification-item-unread"
        }`}>
        <CircleIcon
          className={`notifications-circle ${
            notification.is_seen ? "notifications-circle-seen" : ""
          }`}
        />
        <ListItemAvatar>
          <div className="like-profile-image">
            <Avatar
              // src={avatar}
              src={s3baseUrl + notification.user_info_sender?.profile_image}
              alt="photoURL">
              {notification.user_info_sender?.name[0]}
            </Avatar>
            <div className="tiny-like-icon tiny-like-icon-notification">
              {notification.notification_type === "feedlike" ||
              notification.notification_type === "commentlike" ? (
                <img src={communityHeart} alt="" />
              ) : notification.notification_type === "gratitude" ? (
                <img src={communityHandScreenshot} alt="" />
              ) : (
                <img src={commentIcon} alt="" />
              )}
            </div>
          </div>
          {/* <Avatar sx={{ bgcolor: "background.neutral" }}>{avatar}</Avatar> */}
        </ListItemAvatar>
        <ListItemText
          primary={title}
          secondary={
            <Typography
              variant="caption"
              sx={{
                mt: 0.5,
                display: "flex",
                alignItems: "center",
                color: "text.disabled",
              }}>
              <Box
                component={Icon}
                icon={clockFill}
                sx={{ mr: 0.5, width: 16, height: 16 }}
              />
              {capitalizeFirst(
                moment(notification_date, "YYYY-MM-DD HH:mm").fromNow()
              )}
              {/* {formatDistanceToNow(new Date(notification.createdAt))} */}
            </Typography>
          }
          onClick={(e) => {
            e.preventDefault();
            handleRedirect(
              notification._id,
              notification.feeds?._id ? notification.feeds?._id : "",
              notification.notification_type,
              notification.support_ticket ? notification.support_ticket : "",
              notification.sender ? notification.sender : "",
              notification
            );
          }}
        />
        <div className="notifications-delete-button">
          <CustomPopover
            menu={handle_menu_options(notification)}
            data={notification}
          />
        </div>
      </div>
    );
  }
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const loadMoreData = () => {
    setIsLoadingMore(true);
    getMoreNotificationList(notifications, loadMorePath);
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        size="large"
        color={open ? "primary" : "default"}
        className="notification-icons-btn"
        onClick={handleOpen}
        sx={{
          ...(open && {
            bgcolor: (theme) =>
              alpha(
                theme.palette.primary.main,
                theme.palette.action.focusOpacity
              ),
          }),
        }}>
        <Badge badgeContent={totalUnRead} color="error">
          <Icon icon={bellFill} width={20} height={20} />
        </Badge>
      </IconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 360, border: "none" }}
        className="notification-popover-paper">
        <Box
          sx={{ display: "flex", alignItems: "center", py: 2, px: 2.5 }}
          className="notification-popover">
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notifications</Typography>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              You have {totalUnRead} unread messages
            </Typography>
          </Box>

          {totalUnRead > 0 && (
            <Tooltip title=" Mark all as read">
              <IconButton color="primary" onClick={handleMarkAllAsRead}>
                <Icon icon={doneAllFill} width={20} height={20} />
              </IconButton>
            </Tooltip>
          )}
          {notifications?.length > 0 && (
            <Tooltip title="Remove all">
              <IconButton color="primary" onClick={handleOpenAllDelete}>
                <Icon icon="ic:outline-delete" width={20} height={20} />
              </IconButton>
            </Tooltip>
          )}
        </Box>

        <Divider />

        <Scrollbar sx={{ height: { xs: 340, sm: "auto" } }}>
          <List
            disablePadding
            // subheader={
            //   <ListSubheader
            //     disableSticky
            //     sx={{ py: 1, px: 2.5, typography: "overline" }}
            //   >
            //     New
            //   </ListSubheader>
            // }
          >
            {notifications.map((notification, index) => (
              <NotificationItem key={index} notification={notification} />
            ))}
            {totalPages > pageNumber ? (
              <div className="col-12 text-center ">
                <span
                  className="load-more-notification-btn"
                  // ref={lastBookElementRef}
                >
                  <button onClick={loadMoreData}>
                    {isLoadingMore ? "Loading..." : "Load More"}
                  </button>
                </span>
              </div>
            ) : (
              ""
            )}
          </List>

          {/* <List
            disablePadding
            subheader={
              <ListSubheader
                disableSticky
                sx={{ py: 1, px: 2.5, typography: "overline" }}
              >
                Before that
              </ListSubheader>
            }
          >
            {notifications.slice(2, 5).map((notification) => (
              <NotificationItem
                key={notification.id}
                notification={notification}
              />
            ))}
          </List> */}
        </Scrollbar>

        {/* <Divider />

        <Box sx={{ p: 1 }}>
          <Button fullWidth disableRipple component={RouterLink} to="#">
            View All
          </Button>
        </Box> */}
      </MenuPopover>
    </>
  );
}
