import { invokeApi } from "../../bl_libs/invokeApi";
export const transactionListApi = async (page, limit) => {
  const requestObj = {
    path: `api/consultant/transaction/list?page=${page}&limit=${limit}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const transactionListApiNew = async (page, rowsPerPage, data) => {
  const requestObj = {
    path: `api/consultant/transaction/list_with_search?page=${page}&limit=${rowsPerPage}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
