import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Card, Checkbox, IconButton, FormControlLabel } from "@mui/material";
import Label from "src/components/Label";
import {
  NOTIFICATIONS_ARRAY,
  convertCurrencyToSign,
  dd_date_format,
} from "src/utils/constants";
import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { htmlDecode } from "src/utils/convertHtml";
import moment from "moment";
import { useStripe } from "@stripe/react-stripe-js";
import CustomConfirmation from "src/components/menuIcons/CustomConfirmation";
import {
  memberBillingDetailApi,
  send_request_reminder_api,
} from "src/DAL/PaymentRequest/paymentRequest";
import CardInformation from "src/components/Payment/CardInformation";
import {
  confirm_one_time_payment,
  confirm_subscription_payment,
  get_intent_client_secret_for_one_time,
  pay_now_for_subscription,
} from "src/DAL/Payment/Payment";
import FullPagePopupForTitle from "src/components/GeneralComponents/CalendarList/FullPagePopupForTitle";
import EmailNotifySetting from "src/components/GeneralComponents/CalendarList/EmailNotifySetting";
import CustomDrawer from "src/components/DrawerForm/CustomDrawer";
import MessageSetting from "src/components/GeneralComponents/CalendarList/MessageSetting";
import WhatsappNotifySetting from "src/components/GeneralComponents/CalendarList/WhatsappNotifySetting";
import PushNotifySetting from "src/components/GeneralComponents/CalendarList/PushNotifySetting";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { useContentSetting } from "src/Hooks/ContentSettingState";

// ----------------------------------------------------------------------

const handle_status = (row) => {
  return (
    <Label variant="ghost" color={row.status === "" ? "error" : "success"}>
      {row.status === false ? "Unsuccessful" : "successful"}
    </Label>
  );
};

const TABLE_HEAD = [
  { id: "number", label: "#", type: "number" },
  { id: "totalAmount", label: "Total Amount" },
  { id: "transaction_note", label: "Transaction Note" },
  { id: "date", label: "Transaction Date" },
  { id: "status", label: "Status", renderData: (row) => handle_status(row) },
];

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function PaymentRequestInfo() {
  const navigate = useNavigate();
  const params = useParams();
  const { socket } = useContentSetting();
  const classes = useStyles();
  const stripe = useStripe();
  const { enqueueSnackbar } = useSnackbar();
  const [transactionsList, setTransactionsList] = useState([]);
  const [notificationsList, setNotificationsList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [openReminder, setOpenReminder] = useState(false);
  const [isSendingReminder, setIsSendingReminder] = useState(false);
  const [clientSecret, setClientSecret] = useState("");
  const [resPostData, setResPostData] = useState("");
  const [requestData, setRequestData] = useState({});
  const [isLoadingCard, setIsLoadingCard] = useState(false);
  const [selectedObject, setSelectedObject] = useState({});
  const [notificationAction, setNotificationAction] = useState({});
  const [EmailNotification, setEmailNotification] = useState(false);
  const [MemberData, setMemberData] = useState("");
  const [drawerState, setDrawerState] = useState("");

  const handleOpenData = (data) => {
    if (data.name == "email_notification_access") {
      setEmailNotification(true);
    } else {
      setDrawerState(data.name);
    }
  };

  const handleClose = (selected_obj, drawer_state) => {
    if (drawer_state && drawer_state !== "backdropClick") {
      setNotificationAction(selected_obj);
    }
    setDrawerState("");
    setSelectedObject({});
    setEmailNotification(false);
  };

  const handleChangeNewNotification = (event, value) => {
    let checked = event.target.checked;
    setNotificationAction({ ...notificationAction, [value.name]: checked });
  };

  const {
    date,
    currency,
    initial_amount,
    request_title,
    is_first_paid,
    payment_status,
    month,
    request_iteration_type,
    request_type,
    total_amount,
    object,
  } = requestData;

  const getRequestDetail = async () => {
    const result = await memberBillingDetailApi(params.request_title);
    if (result.code === 200) {
      setMemberData(result.member_info);
      setRequestData(result.payment_request_info);
      let notifications = NOTIFICATIONS_ARRAY.filter(
        (action) => result.notification_action[action.name] == true
      );
      setNotificationsList(notifications);
      setNotificationAction(result.notification_action);
      const data = result.payment_request_transaction.map((transaction) => {
        return {
          ...transaction,
          date: dd_date_format(transaction.transaction_date),
          totalAmount:
            convertCurrencyToSign(transaction.currency) + transaction.amount,
        };
      });
      setTransactionsList(data);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleAgreeReminder = () => {
    setOpenReminder(true);
  };

  const handleSendReminder = async () => {
    setOpenReminder(false);
    setIsSendingReminder(true);
    let postData = {
      payment_request_id: requestData._id,
      notification_action: notificationAction,
    };
    socket.emit("send_payment_request_reminder_reciever", postData);
    enqueueSnackbar("Reminder sent successfully", { variant: "success" });
    setIsSendingReminder(false);
  };

  // payment section code
  const confirmCardPayment = async (postData) => {
    if (requestData.request_type === "recurring") {
      const result = await confirm_subscription_payment(postData);

      if (result.code === 200) {
        setIsLoadingCard(false);
        enqueueSnackbar("Payment succeeded successfully.", {
          variant: "success",
        });
        navigate(`/payment-request`);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoadingCard(false);
      }
    } else {
      const result = await confirm_one_time_payment(postData);

      if (result.code === 200) {
        setIsLoadingCard(false);
        enqueueSnackbar("Payment succeeded successfully.", {
          variant: "success",
        });
        navigate(`/payment-request`);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoadingCard(false);
      }
    }
  };

  const handleSecureCard = (client_secret, cardElement, postData) => {
    enqueueSnackbar("Processing card...", {
      variant: "info",
    });

    stripe
      .confirmCardPayment(client_secret, {
        payment_method: {
          card: cardElement,
        },
      })
      .then(function (result) {
        // Handle result.error or result.paymentIntent
        setIsLoadingCard(false);
        if (result.error) {
          setIsLoadingCard(false);
          enqueueSnackbar(result.error.message, { variant: "error" });
          return;
        }

        confirmCardPayment(postData);
      })
      .catch((err) => {
        enqueueSnackbar(err.message, { variant: "error" });
      });
  };

  const payNowForSubscription = async (token, cardElement) => {
    const postData = {
      payment_request_slug: requestData.payment_request_slug,
      source_token: token,
    };
    // if client secret is already generated
    if (clientSecret && resPostData) {
      handleSecureCard(clientSecret, cardElement, resPostData);
      return;
    }
    const result = await pay_now_for_subscription(postData);
    if (result.code === 200) {
      setIsLoadingCard(false);
      enqueueSnackbar("Payment succeeded successfully.", {
        variant: "success",
      });
      navigate(`/payment-request`);
    } else if (result.code === 210) {
      const postData = {
        payment_request_slug: requestData.payment_request_slug,
        price_id: result.stripe_initial_price_id,
        recurring_price_id: result.strip_recurring_price_id,
        subscription_id: result.strip_subscription_id,
      };
      // save data for failure use
      setClientSecret(result.client_secret);
      setResPostData(postData);
      handleSecureCard(result.client_secret, cardElement, postData);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingCard(false);
    }
  };

  const getIntentClientSecretForOneTime = async (token, cardElement) => {
    const postData = {
      payment_request_slug: requestData.payment_request_slug,
    };
    // if client secret is already generated
    if (clientSecret && resPostData) {
      handleSecureCard(clientSecret, cardElement, resPostData);
      return;
    }
    const result = await get_intent_client_secret_for_one_time(postData);
    if (result.code === 200) {
      const postData = {
        payment_request_slug: requestData.payment_request_slug,
      };
      setClientSecret(result.client_secret);
      setResPostData(postData);
      handleSecureCard(result.client_secret, cardElement, postData);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingCard(false);
    }
  };

  const handleCardAction = (card_token, cardElement) => {
    if (requestData.request_type === "recurring") {
      payNowForSubscription(card_token, cardElement);
    } else {
      getIntentClientSecretForOneTime(card_token, cardElement);
    }
  };

  let drawer_data = {
    onCloseDrawer: handleClose,
    data: notificationAction,
    drawerState: drawerState,
  };

  const handle_drawer = () => {
    if (drawerState === "message_notification_access") {
      return {
        label: "Message Notification Setting",
        component: <MessageSetting {...drawer_data} />,
      };
    } else if (drawerState === "whatsapp_notification_access") {
      return {
        label: "Whatsapp Notification Setting",
        component: <WhatsappNotifySetting {...drawer_data} />,
      };
    } else if (drawerState === "push_notification_access") {
      return {
        label: "Push Notification Setting",
        component: <PushNotifySetting {...drawer_data} />,
      };
    }
  };

  useEffect(() => {
    getRequestDetail();
  }, []);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12 mb-3 ">
            <IconButton
              className="back-screen-button mb-4"
              onClick={() => navigate(-1)}
            >
              <ArrowBackIcon />
            </IconButton>
            <span
              className="ms-2"
              style={{ fontSize: "18px", fontWeight: "500" }}
            >
              {MemberData?.first_name
                ? MemberData?.first_name + " " + MemberData?.last_name
                : ""}
            </span>
          </div>
          <div className="col-lg-8 col-sm-12">
            <h2>Payment Request Transaction</h2>
          </div>
        </div>
        <Card className="payment-card mb-3">
          <div className="row p-3">
            <div className="col-12">
              <div className="mb-3">
                <h3 className="payment-request-heading">
                  {htmlDecode(request_title)}
                </h3>
              </div>
              <div className="mb-3 d-flex align-items-center svg-color date-color">
                <Icon icon="uiw:date" />
                <span className="ms-2">
                  {moment(date).format("DD MMM YYYY")}
                </span>
              </div>
              <div className="row">
                <div className="col-8 col-md-6 col-lg-3 payment-heading">
                  Request Type:
                </div>
                <div className="col-4 col-md-6 col-lg-2 text-muted text-visible">
                  {request_type === "onetime" ? "OneTime" : "Recurring"}
                </div>
                <div className="col-8 col-md-6 col-lg-4 mt-3 mt-lg-0 payment-heading">
                  Total Programme Amount:
                </div>
                <div className="col-4 col-md-6 col-lg-3  mt-3 mt-lg-0 text-muted text-visible">
                  {convertCurrencyToSign(currency)}
                  {total_amount.toLocaleString()}
                </div>
                {request_type == "recurring" && (
                  <>
                    <div className="col-8 col-md-6 col-lg-3  mt-3 payment-heading">
                      Initial Deposit Amount:
                    </div>
                    <div className="col-4 col-md-6 col-lg-2  mt-3 text-muted text-visible">
                      {convertCurrencyToSign(currency)}
                      {initial_amount.toLocaleString()}
                    </div>
                    <div className="col-8 col-md-6 col-lg-4  mt-3 payment-heading">
                      Total Installments:
                    </div>
                    <div className="col-4 col-md-6 col-lg-3  mt-3 text-muted text-visible">
                      {month.toLocaleString()}
                    </div>
                    <div className="col-8 col-md-6 col-lg-3  mt-3 payment-heading">
                      Installments Plan:
                    </div>
                    {
                      <div className="col-4 col-md-6 col-lg-3 mt-3 text-muted text-visible">
                        {request_iteration_type == "custom"
                          ? object?.number_of_days +
                            " " +
                            "Days" +
                            " " +
                            "(Custom)"
                          : month.toLocaleString() +
                            " " +
                            request_iteration_type}
                      </div>
                    }
                  </>
                )}
              </div>
            </div>
            {!is_first_paid && notificationsList.length > 0 && (
              <div className="col-12 mt-4">
                {notificationsList.map((notify) => {
                  return (
                    <span className="position-relative color-white mt-2">
                      {Boolean(notificationAction[notify.name]) && (
                        <Icon
                          fontSize="18"
                          style={{
                            color: "var(--portal-theme-primary)",
                          }}
                          className="me-2 setIconPosition"
                          icon="circum:edit"
                          onClick={() => handleOpenData(notify)}
                        />
                      )}
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={(e) =>
                              handleChangeNewNotification(e, notify)
                            }
                            checked={Boolean(notificationAction[notify.name])}
                            className="p-1"
                          />
                        }
                        label={notify.label}
                        className="me-4"
                      />
                    </span>
                  );
                })}
                <button
                  class="small-contained-button float-end"
                  onClick={handleAgreeReminder}
                  disabled={isSendingReminder}
                  style={{
                    minWidth: "144px",
                  }}
                >
                  {isSendingReminder ? "Sending..." : "Send Reminder"}
                </button>
              </div>
            )}
          </div>
        </Card>

        <div>
          {is_first_paid == false && payment_status == "pending" ? (
            <CardInformation
              isLoading={isLoadingCard}
              setIsLoading={setIsLoadingCard}
              handleCardAction={handleCardAction}
            />
          ) : (
            ""
          )}
        </div>
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={transactionsList}
          className="card-with-background mt-3"
        />
      </div>
      <FullPagePopupForTitle
        open={EmailNotification}
        setOpen={setEmailNotification}
        handleClosePopup={handleClose}
        title={selectedObject}
        componentToPassDown={
          <EmailNotifySetting
            {...drawer_data}
            open={EmailNotification}
            request_title={request_title}
          />
        }
      />
      <CustomDrawer
        isOpenDrawer={Boolean(drawerState)}
        onCloseDrawer={handleClose}
        pageTitle={handle_drawer()?.label}
        componentToPassDown={handle_drawer()?.component}
      />
      <CustomConfirmation
        open={openReminder}
        setOpen={setOpenReminder}
        title={"Are you sure you want to send reminder ?"}
        handleAgree={handleSendReminder}
      />
    </>
  );
}
