import { Avatar } from "@mui/material";
import React from "react";
import { s3baseUrl } from "src/config/config";
import { string_avatar } from "src/utils/constants";
import {
  _delete_message,
  _get_chat_message,
  _send_message,
  _update_message,
} from "src/DAL/Chat/Chat";
import { Icon } from "@iconify/react";
import WhatsAppChatMessages from "../ChatMessages/WhatsAppChatMessages";

function ChatRightWhatsApp(props) {
  const { selectedChat, setChatList, handleCloseChat } = props;
  const { receiver_info } = selectedChat;

  return (
    <div className="chat-right-wrapper">
      <div className="chat-right-head p-1 d-flex justify-content-between">
        <div className="d-flex">
          {receiver_info && (
            <>
              <div className="user-profile">
                <Avatar
                  src={s3baseUrl + receiver_info?.profile_image}
                  style={{ height: 45, width: 45 }}
                >
                  {string_avatar(receiver_info?.first_name[0])}
                </Avatar>
              </div>
              <div className="chat-profile-data">
                <div className="chat-profile-name ps-2 text-capitalize">
                  {receiver_info?.first_name + " " + receiver_info?.last_name}
                </div>
              </div>
            </>
          )}
        </div>

        {receiver_info && (
          <div className="me-2" onClick={handleCloseChat}>
            <Icon
              fontSize={18}
              className="chat-box-cross-icon"
              icon="material-symbols:close"
            />
          </div>
        )}
      </div>
      <WhatsAppChatMessages
        selectedChat={selectedChat}
        setChatList={setChatList}
      />
    </div>
  );
}

export default ChatRightWhatsApp;
