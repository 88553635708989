import React from "react";
import { websiteUrl } from "src/config/config";
import { useSnackbar } from "notistack";
import RecordNotFound from "src/components/RecordNotFound";
import { Tooltip } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CopyToClipboard from "react-copy-to-clipboard";

function SourceFeedLinks({ title, data, affiliateUrl }) {
  const { enqueueSnackbar } = useSnackbar();

  const handleOpen = (value) => {
    window.open(websiteUrl + value + "/" + affiliateUrl, "_blank");
  };

  const handleCopiedUrl = () => {
    enqueueSnackbar("Preview Url copied to clipboard", { variant: "success" });
  };

  return (
    <div className="profile-cards mb-3">
      <h4 className="p-3 pb-0">
        {title ? title : "Monthly New Leads Leaderboard"}
      </h4>
      <hr />
      <div className="monthly-leads p-2 pt-0">
        {data?.length < 1 ? (
          <RecordNotFound title="Links" heading="No Links Present" />
        ) : (
          data?.map((value) => {
            return (
              <div className="d-flex justify-content-between mb-2">
                <div className="card-title mb-0">
                  {value?.sale_page_title.replace(" ", "\n")}
                </div>
                <div>
                  {value?.sale_page_title_slug ? (
                    <span className="d-flex links-icons mt-1 cursor-pointer ">
                      <Tooltip title="Open In New Tab">
                        <OpenInNewIcon
                          className="ms-1"
                          onClick={() =>
                            handleOpen(value?.sale_page_title_slug)
                          }
                        />
                      </Tooltip>
                      <CopyToClipboard
                        text={
                          websiteUrl +
                          value?.sale_page_title_slug +
                          "/" +
                          affiliateUrl
                        }
                        onCopy={() => handleCopiedUrl(true)}
                      >
                        <Tooltip title="Click to copy Preview URL">
                          <ContentCopyIcon className="ms-1" />
                        </Tooltip>
                      </CopyToClipboard>
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
}

export default SourceFeedLinks;
