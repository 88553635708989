import React, { useState, useEffect } from "react";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Tooltip,
} from "@mui/material";
import { useSnackbar } from "notistack";
import "react-datepicker/dist/react-datepicker.css";
import { makeStyles } from "@mui/styles";
import { get_root_value } from "src/utils/domUtils";
import OutlinedInput from "@mui/material/OutlinedInput";
import { memberAndGroupsListApi } from "src/DAL/member/Member";
import Autocomplete from "@mui/material/Autocomplete";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCkeditor";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import moment from "moment";
import dayjs from "dayjs";
import { update_member_event_api } from "src/DAL/Calendar/Calendar";
import { TIME_ZONE_CONVERSION } from "src/utils/constants";
import { useContentSetting } from "src/Hooks/ContentSettingState";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const useStyles = makeStyles(() => ({
  paper: {
    background: get_root_value("--popup-background-color"),
    color: get_root_value("--input-text-color"),
  },
}));

const notification_object = {
  notify_before_unit: "minutes",
  notify_before_time: 30,
  notification_title: "",
};

export default function UpdateCalendarEvent({
  dataList,
  setShowEditComponent,
  editValues,
  setEditValues,
  setEventDetailData,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [groupListing, setGroupListing] = useState([]);
  const [personName, setPersonName] = useState([]);
  const [groupsName, setGroupsName] = useState([]);
  const [member, setMember] = useState([]);
  const [serchText, setSerchText] = useState("");
  const { userInfo, adminTimeZone } = useContentSetting();
  const [notificationArray, setNotificationArray] = useState([
    notification_object,
  ]);
  const [state, setState] = useState({
    description: "",
    title: "",
    color: "#000",
    recurring_type: "daily",
    weekday: [new Date().getDay()],
    notify_before_unit: "minutes",
    notify_before_time: 30,
    start_date: dayjs(new Date()).$d,
    start_time: dayjs(new Date()).$d,
    end_date: dayjs(new Date()).$d,
    end_time: dayjs(new Date()).$d,
    status: true,
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleChangeDate = (name, event) => {
    setState((prevState) => {
      return {
        ...prevState,
        [name]: event.$d,
      };
    });
  };

  const handleChangeNotification = (event, index) => {
    const { name, value } = event.target;
    let temp_state = [...notificationArray];
    let temp_element = { ...temp_state[index] };
    temp_element[name] = value;
    temp_state[index] = temp_element;
    setNotificationArray(temp_state);
  };

  const getGroupsAndMembers = async () => {
    const result = await memberAndGroupsListApi(serchText);
    if (result.code === 200) {
      if (!serchText.length) {
        setGroupListing(result.group);
      }
      setPersonName(result.members);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleSearch = (event) => {
    event.preventDefault();
    const value = event.target.value;
    setSerchText(value);
  };

  const handleChangeGroup = (event) => {
    const {
      target: { value },
    } = event;
    setGroupsName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let group_array = [];
    groupsName.map((group) => {
      group_array.push({
        group_slug: group,
      });
    });

    let selected_member_array = [];
    member.map((member) => {
      selected_member_array.push({
        member_id: member._id,
      });
    });

    const formData = {
      title: state.title,
      color: state.color,
      description: state.description,
      recurring_type: state.recurring_type,
      weekday: state.weekday,
      status: state.status,
      start_date: moment(state.start_date).format("YYYY-MM-DD"),
      end_date: moment(state.end_date).format("YYYY-MM-DD"),
      start_time: moment(state.start_time).format("HH:mm"),
      end_time: moment(state.end_time).format("HH:mm"),
      group: group_array,
      member: selected_member_array,
      is_notify_user: notificationArray.length > 0 ? true : false,
      notify_before: notificationArray,
    };

    const result = await update_member_event_api(formData, state.event_slug);
    if (result.code === 200) {
      let event = result.event;
      let new_groups = [];
      groupsName.map((group) => {
        new_groups.push({
          _id: {
            group_slug: group,
          },
        });
      });
      let member_array = [];
      if (member.length > 0) {
        member.map((member) => {
          member_array.push({
            user_type: "event_individual_user",
            _id: member,
          });
        });
      }
      let eventObject = {
        ...event,
        event_slug: event.event_slug,
        event_title: event.title,
        event_color: event.color,
        start_date: moment(state.start_date).format("YYYY-MM-DD"),
        group: new_groups,
        member: member_array,
      };
      dataList();
      setShowEditComponent(false);
      setEditValues(eventObject);
      setEventDetailData(eventObject);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleAddNotification = () => {
    setNotificationArray((old_array) => [...old_array, notification_object]);
  };

  const handleRemoveNotification = (index) => {
    const updatedItems = [...notificationArray];
    updatedItems.splice(index, 1);
    setNotificationArray(updatedItems);
  };

  const get_start_end_time = (date) => {
    return TIME_ZONE_CONVERSION(
      date,
      "YYYY-MM-DD HH:mm",
      adminTimeZone,
      userInfo.time_zone
    );
  };

  useEffect(() => {
    setState({
      ...editValues,
      start_date: dayjs(get_start_end_time(editValues.start_date_time)).$d,
      end_date: dayjs(get_start_end_time(editValues.end_date_time)).$d,
      start_time: dayjs(get_start_end_time(editValues.start_date_time)).$d,
      end_time: dayjs(get_start_end_time(editValues.end_date_time)).$d,
    });
    let group_array = [];
    if (editValues.group.length > 0) {
      editValues.group.map((group) => {
        group_array.push(group._id.group_slug);
      });
    }
    setGroupsName(group_array);

    let member_array = [];
    if (editValues.member.length > 0) {
      editValues.member.map((member) => {
        member_array.push(member._id);
      });
    }
    setMember(member_array);
    let notify_before = [];
    if (editValues.notify_before && editValues.notify_before.length > 0) {
      notify_before = editValues.notify_before;
    }
    setNotificationArray(notify_before);
  }, [editValues]);
  console.log(editValues, "editValueseditValueseditValues");
  useEffect(() => {
    getGroupsAndMembers();
  }, []);

  useEffect(() => {
    if (serchText.length > 2) {
      getGroupsAndMembers();
    }
  }, [serchText]);

  return (
    <div className="container new-memories">
      <form onSubmit={handleSubmit} className="row">
        <div className="col-12">
          <TextField
            className="mt-4"
            id="outlined-basic"
            label="Title"
            variant="outlined"
            name="title"
            value={state.title}
            required={true}
            onChange={handleChange}
          />
        </div>
        <div className="col-12">
          <TextField
            id="color"
            type="color"
            label="Color"
            name="color"
            className="mt-3"
            variant="outlined"
            required={true}
            value={state.color}
            onChange={handleChange}
          />
        </div>
        <div className="col-12">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              label="Start Date"
              name="start_date"
              inputFormat="DD-MM-YYYY"
              value={state.start_date}
              format="DD-MM-YYYY"
              className="mt-3"
              onChange={(e) => handleChangeDate("start_date", e)}
              renderInput={(params) => (
                <TextField {...params} required={true} />
              )}
            />
          </LocalizationProvider>
        </div>
        <div className="col-12">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
              label="Start Time"
              name="start_time"
              value={state.start_time}
              sx={{ color: "#fff" }}
              className="mt-3"
              onChange={(e) => handleChangeDate("start_time", e)}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </div>
        <div className="col-12">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              label="End Date"
              name="end_date"
              inputFormat="DD-MM-YYYY"
              value={state.end_date}
              format="DD-MM-YYYY"
              className="mt-3"
              onChange={(e) => handleChangeDate("end_date", e)}
              renderInput={(params) => (
                <TextField {...params} required={true} />
              )}
            />
          </LocalizationProvider>
        </div>
        <div className="col-12">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
              label="End Time"
              name="end_time"
              value={state.end_time}
              sx={{ color: "#fff" }}
              className="mt-3"
              onChange={(e) => handleChangeDate("end_time", e)}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </div>
        <div className="col-12">
          <FormControl variant="outlined" className="mt-3">
            <InputLabel id="demo-simple-select-outlined-label">
              Recurring Type
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={state.recurring_type}
              onChange={(e) => handleChange(e)}
              label="recurring Type"
              name="recurring_type"
              className="inputs-fields svg-color"
              MenuProps={{
                classes: {
                  paper: classes.paper,
                },
              }}
              sx={{
                color: get_root_value("--input-text-color"),
              }}
            >
              <MenuItem value="daily">Daily</MenuItem>
              <MenuItem value="weekly">Weekly</MenuItem>
              <MenuItem value="monthly">Monthly</MenuItem>
            </Select>
          </FormControl>
        </div>
        {state.recurring_type == "weekly" && (
          <div className="col-12">
            <FormControl variant="outlined" className="mt-3">
              <InputLabel id="demo-simple-select-outlined-label">
                Select Day
              </InputLabel>
              <Select
                multiple
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={state.weekday}
                onChange={(e) => handleChange(e)}
                label="Select Day"
                name="weekday"
                className="inputs-fields svg-color"
                MenuProps={{
                  classes: {
                    paper: classes.paper,
                  },
                }}
                sx={{
                  color: get_root_value("--input-text-color"),
                }}
              >
                <MenuItem value={0}>Sunday</MenuItem>
                <MenuItem value={1}>Monday</MenuItem>
                <MenuItem value={2}>Tuesday</MenuItem>
                <MenuItem value={3}>Wednesday</MenuItem>
                <MenuItem value={4}>Thursday</MenuItem>
                <MenuItem value={5}>Friday</MenuItem>
                <MenuItem value={6}>Saturday</MenuItem>
              </Select>
            </FormControl>
          </div>
        )}
        <div className="col-12">
          <FormControl variant="outlined" className="mt-3">
            <InputLabel id="demo-simple-select-outlined-label">
              Status
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={state.status}
              onChange={handleChange}
              label="Status"
              name="status"
              className="svg-color"
              MenuProps={{
                classes: {
                  paper: classes.paper,
                },
              }}
              sx={{
                color: get_root_value("--input-text-color"),
              }}
            >
              <MenuItem value={true}>Active</MenuItem>
              <MenuItem value={false}>Inactive</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="col-12">
          <FormControl className="mt-3">
            <InputLabel id="demo-multiple-name-label">Groups</InputLabel>
            <Select
              labelId="demo-multiple-name-label"
              id="demo-multiple-name"
              multiple
              value={groupsName}
              onChange={handleChangeGroup}
              input={<OutlinedInput label="Groups" />}
              MenuProps={MenuProps}
            >
              {groupListing.map((name) => (
                <MenuItem key={name} value={name.group_slug}>
                  {name.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="col-12">
          <FormControl className="mt-3">
            <Autocomplete
              multiple
              id="tags-outlined"
              options={personName}
              clearOnBlur={true}
              getOptionLabel={(option) =>
                option.first_name + " (" + option.email + ")"
              }
              filterSelectedOptions
              value={member}
              onChange={(event, newValue) => {
                setMember(newValue);
              }}
              className="mui-autocomplete"
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Members"
                  placeholder="Members"
                  onChange={handleSearch}
                />
              )}
            />
          </FormControl>
        </div>
        <div className="mt-3 mb-2">
          <h4>Notifications Setting</h4>
        </div>
        {notificationArray.length > 0 &&
          notificationArray.map((notification, index) => {
            return (
              <>
                <div className="col-11">
                  <TextField
                    className="mb-3"
                    id="outlined-basic"
                    label={`Notification ${index + 1} Title`}
                    variant="outlined"
                    name="notification_title"
                    value={notification.notification_title}
                    onChange={(e) => {
                      handleChangeNotification(e, index);
                    }}
                    required={true}
                  />
                </div>
                <div className="col-6">
                  <FormControl variant="outlined" className="mb-3">
                    <InputLabel id="demo-simple-select-outlined-label">
                      Notify Before
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={notification.notify_before_unit}
                      onChange={(e) => {
                        handleChangeNotification(e, index);
                      }}
                      label="Notifications Before"
                      name="notify_before_unit"
                      className="inputs-fields svg-color"
                      MenuProps={{
                        classes: {
                          paper: classes.paper,
                        },
                      }}
                      sx={{
                        color: get_root_value("--input-text-color"),
                      }}
                    >
                      <MenuItem value="days">Days</MenuItem>
                      <MenuItem value="hours">Hours</MenuItem>
                      <MenuItem value="minutes">Minutes</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="col-5">
                  <TextField
                    className="mb-3"
                    id="outlined-basic"
                    label={`${
                      notification.notify_before_unit == "days"
                        ? "Days"
                        : notification.notify_before_unit == "hours"
                        ? "Hours"
                        : "Minutes"
                    }`}
                    variant="outlined"
                    name="notify_before_time"
                    type="number"
                    value={notification.notify_before_time}
                    required={true}
                    onChange={(e) => {
                      handleChangeNotification(e, index);
                    }}
                    inputProps={{
                      min: 0,
                      max:
                        notification.notify_before_unit == "days"
                          ? 100
                          : notification.notify_before_unit == "hours"
                          ? 24
                          : 60,
                    }}
                  />
                </div>
                <div className="col-1 p-0 mt-4">
                  <Tooltip title="Remove">
                    <RemoveCircleOutlineIcon
                      onClick={() => handleRemoveNotification(index)}
                      className="diary-icon-remove"
                    />
                  </Tooltip>
                </div>
              </>
            );
          })}
        <div className="col-12 mt-1">
          <FormControlLabel
            control={<AddCircleOutlineIcon className="diary-icon-add mx-2" />}
            label="Add Notification"
            onClick={handleAddNotification}
          />
        </div>
        <div className="col-12 mt-3">
          <h4>Event Description </h4>
          <GeneralCkeditor
            setInputs={setState}
            inputs={state}
            name="description"
            editorHeight={320}
          />
        </div>

        <div className="text-end my-3">
          <button className="small-contained-button" disabled={isLoading}>
            {isLoading ? "Updating..." : "Update"}
          </button>
        </div>
      </form>
    </div>
  );
}
