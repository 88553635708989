import React, { useState, useEffect } from "react";
import { IconButton, CircularProgress, Chip, Tooltip } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useParams } from "react-router-dom";
import LockIcon from "@mui/icons-material/Lock";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
// import { PodsDetailApi } from "src/DAL/Pods/Pods";
import { s3baseUrl } from "src/config/config";
// import ReactTooltip from "react-tooltip";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { htmlDecode } from "src/utils/convertHtml";
import moment from "moment";
import { get_date_with_user_time_zone } from "src/utils/constants";
import { DetailPodsApi } from "src/DAL/Pods/Pods";
import { useContentSetting } from "src/Hooks/ContentSettingState";
// import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
const NewPodsRoomDetail = () => {
  const { userInfo, adminTimeZone } = useContentSetting();
  const navigate = useNavigate();
  const params = useParams();
  const [podsData, setPodsData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [copied, setCopied] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  console.log(params.pod_slug, "params.pod_slug");
  const getMemoryDetail = async () => {
    setIsLoading(true);
    const result = await DetailPodsApi(params.pod_slug);
    if (result.code === 200) {
      setPodsData(result.room);
      console.log(result, "details data");
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const setCopiedPassword = () => {
    enqueueSnackbar("Password Copied To Clipboard", { variant: "success" });
    setCopied(true);
  };
  useEffect(() => {
    getMemoryDetail();
  }, []);
  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      <div className="row mobile-margin ">
        <div className="col-12 d-flex">
          <span>
            <IconButton
              className="back-screen-button"
              onClick={() => navigate(-1)}
            >
              <ArrowBackIcon />
            </IconButton>
          </span>
          <h2 className="d-inline ms-2">{htmlDecode(podsData.title)}</h2>
        </div>
      </div>
      <div className="row section-space">
        <div className="col-12">
          {/* <h1 className="d-inline">pod cast detail</h1> */}
        </div>
      </div>
      <div className="row section-space"></div>
      <div className="row">
        <div className="col-12 col-md-8">
          <img
            width="100%"
            src={s3baseUrl + podsData?.room_image?.thumbnail_1}
          />
          {/* <img
            width="100%"
            src="https://dynamite-lifestyle-dev-app-bucket.s3.amazonaws.com/room/f3371813-9531-42d1-b8e9-4ec203a780b9.jpg"
            alt="image here"
          /> */}
        </div>
        <div className="col-12 col-md-4">
          {podsData.start_date && (
            <p>
              Start Time :&nbsp;
              {get_date_with_user_time_zone(
                moment(podsData.start_date).format("YYYY-MM-DD") +
                  " " +
                  podsData.start_time,
                "hh:mm A",
                userInfo?.time_zone,
                adminTimeZone
              )}
            </p>
          )}
          <p className="mt-3">{htmlDecode(podsData.short_description)}</p>
          {/* <p className="mt-3">Start Time : 05:00 PM </p>
          <p className="mt-3">Time 8pm BST</p> */}

          <div className="row">
            <div className="col-12 col-md-6">
              {podsData.zoom_link && (
                <a
                  href={podsData.zoom_link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className="small-contained-button">
                    Join Meeting
                  </button>
                </a>
              )}
            </div>
            {/* <div className="col-12 col-md-6">
              <a href="#" target="_blank" rel="noopener noreferrer">
                <button className="small-contained-button">Join Meeting</button>
              </a>
            </div> */}
            <div className="col-12 col-md-6 zoom-password">
              {podsData.password && (
                <CopyToClipboard
                  text={podsData.password}
                  onCopy={() => setCopiedPassword(true)}
                >
                  <Tooltip title="Click to copy password">
                    <Chip
                      label={
                        <>
                          {podsData.password}
                          <LockIcon />
                        </>
                      }
                      color="primary"
                      className="float-end me-1"
                      variant="outlined"
                    />
                  </Tooltip>
                </CopyToClipboard>
              )}

              {/* <CopyToClipboard
                text="password"
                onCopy={() => setCopiedPassword(true)}
              >
                <Tooltip title="Click to copy password">
                  <Chip
                    label={
                      <>
                        lock data
                        <LockIcon sx={{ fontSize: "16px" }} />
                      </>
                    }
                    color="primary"
                    className="float-end me-1"
                    variant="outlined"
                  />
                </Tooltip>
              </CopyToClipboard> */}
            </div>
          </div>
        </div>
        <div className="col-12 section-space">
          <div
            dangerouslySetInnerHTML={{
              __html: podsData.detail_description,
            }}
          ></div>
          {/* <div>
            <p>
              <span style={{ fontSize: 20 }}>
                <strong>Hosted by Kim Calvert&nbsp;</strong>
              </span>
            </p>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default NewPodsRoomDetail;
