import { CircularProgress, IconButton } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { booking_detail_api } from "src/DAL/Booking/bookingApi";
import { makeStyles } from "@mui/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { useSnackbar } from "notistack";
import moment from "moment";
import BookingQuestions from "src/components/booking/BookingQuestions";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function BookingDetail({}) {
  const params = useParams();
  const navigate = useNavigate();
  const { state } = useLocation();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [bookingData, setBookingData] = useState({});
  const [bookingTime, setBookingTime] = useState("");

  const handleFormatData = (data) => {
    setBookingData(data);
    const dateTime = moment().format("YYYY-MM-DD") + " " + data.time;
    let booking_tome =
      data.time +
      " to " +
      moment(dateTime, "DD-MM-YYYY hh:mm A")
        .add(data.slot_duration, "minutes")
        .format("hh:mm A");
    setBookingTime(booking_tome);
    setIsLoading(false);
  };

  const getBookingDetail = async () => {
    const result = await booking_detail_api(params.booking_id);
    if (result.code == 200) {
      handleFormatData(result.booking);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (state) {
      handleFormatData(state);
    } else {
      getBookingDetail();
    }
  }, []);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      {
        <div className="container booking-detail mt-3">
          <div className="row mobile-margin display-flex mb-3">
            <div className="col-12">
              <IconButton
                className="back-screen-button"
                onClick={() => navigate("/booking")}
              >
                <ArrowBackIcon />
              </IconButton>
            </div>
          </div>
          <h2>Booking Detail</h2>
          <div className="row booking-normal-detail mt-3">
            <div className="col-md-6">
              <div className="d-flex ">
                <b>User Name :</b>&nbsp;
                <p>
                  {bookingData.user_info.first_name +
                    " " +
                    bookingData.user_info.last_name}
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="d-flex ">
                <b>User Email :</b>&nbsp;
                <p>{bookingData.user_info.email}</p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="d-flex ">
                <b>Booking Date :</b>&nbsp;
                <p>{moment(bookingData.date).format("DD-MM-YYYY")}</p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="d-flex ">
                <b>Booking Time :</b>&nbsp;
                <p>{bookingTime}</p>
              </div>
            </div>
          </div>

          <div className="row mt-3">
            <h2>Booking Questions</h2>
            <BookingQuestions data={bookingData} />
          </div>
        </div>
      }
    </>
  );
}
