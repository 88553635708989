import React from "react";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import { useState, useEffect } from "react";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {
  TextField,
  Button,
  Box,
  FormControlLabel,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

import roundClearAll from "@iconify/icons-ic/round-clear-all";
import { get_root_value } from "src/utils/domUtils";
import { Icon } from "@iconify/react";
import {
  active_booking_status_api,
  bookingPageList,
} from "src/DAL/Booking/bookingApi";
import MUIAutocomplete from "../GeneralComponents/MUIAutocomplete";
import { show_proper_words } from "src/utils/constants";

export const BookingFilter = ({
  filterData,
  handleChaneOthers,
  handleSubmitFilter,
  handleClearFilterAll,
}) => {
  const [salePageList, setSalePageList] = useState([]);
  const [bookingStatus, setBookingStatus] = useState([]);

  const getPageListing = async () => {
    const result = await bookingPageList();
    if (result.code === 200) {
      setSalePageList(result.Sale_page);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const get_active_booking_status = async () => {
    const result = await active_booking_status_api();
    if (result.code === 200) {
      let status_array = result.active_booking_status.map((status) => {
        return {
          ...status,
          chip_label: status.title,
          chip_value: status._id,
        };
      });
      setBookingStatus(status_array);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  useEffect(() => {
    get_active_booking_status();
    getPageListing();
  }, []);

  const SORT_BY = ["call_booked_newest_first", "call_booked_oldest_first"];

  return (
    <div className="container new-memories">
      <form onSubmit={handleSubmitFilter}>
        <div className="row">
          <FormControlLabel
            control={
              <Checkbox
                checked={filterData.filter_by_dates}
                onChange={(e) => {
                  handleChaneOthers("filter_by_dates", e.target.checked);
                }}
              />
            }
            label="Filter By Booking Dates"
          />
          {filterData.filter_by_dates && (
            <>
              <div className="col-12 mt-3">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="From"
                    name="start_date"
                    inputFormat="dd-MM-yyyy"
                    value={filterData.start_date}
                    onChange={(value) => {
                      handleChaneOthers("start_date", value);
                    }}
                    format="DD-MM-YYYY"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="inputs-fields"
                        fullWidth
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>
              <div className="col-12 mt-3">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="To"
                    name="endDate"
                    value={filterData.end_date}
                    inputFormat="dd-MM-yyyy"
                    onChange={(value) => {
                      handleChaneOthers("end_date", value);
                    }}
                    format="DD-MM-YYYY"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="inputs-fields"
                        fullWidth
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>
            </>
          )}

          <div className="col-12 mt-3">
            <MUIAutocomplete
              multiple
              inputLabel="Select Booking Pages"
              selectedOption={filterData.sale_page}
              setSelectedOption={(value) => {
                handleChaneOthers("sale_page", value);
              }}
              optionsList={salePageList}
              name="sale_page_title"
            />
          </div>
          <div className="col-12 mt-3">
            <MUIAutocomplete
              inputLabel="Booking Status"
              selectedOption={filterData.booking_status}
              setSelectedOption={(e) => {
                handleChaneOthers("booking_status", e);
              }}
              optionsList={bookingStatus}
              name="title"
            />
          </div>
          <div className="col-12 mt-3">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Sort By</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="sort_by"
                value={filterData.sort_by}
                label="Sort By"
                onChange={(e) => {
                  handleChaneOthers("sort_by", e.target.value);
                }}
              >
                {SORT_BY.map((item) => {
                  return (
                    <MenuItem value={item}>{show_proper_words(item)}</MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
          <Box className="mt-4">
            <Button
              fullWidth
              size="large"
              type="submit"
              color="inherit"
              variant="outlined"
              startIcon={
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ marginRight: 5 }}
                >
                  <path
                    d="M13.1165 0.25H0.883548C0.321452 0.25 0.0378205 0.932013 0.436097 1.33029L5.3125 6.20743V11.6406C5.3125 11.8471 5.41325 12.0406 5.58242 12.1591L7.69179 13.6351C8.10794 13.9264 8.6875 13.6312 8.6875 13.1167V6.20743L13.564 1.33029C13.9615 0.932804 13.6798 0.25 13.1165 0.25Z"
                    fill={get_root_value("--portal-theme-primary")}
                  />
                </svg>
              }
            >
              Filter
            </Button>
          </Box>
        </div>
      </form>
      <Button
        fullWidth
        size="large"
        type="submit"
        color="inherit"
        variant="outlined"
        onClick={handleClearFilterAll}
        startIcon={<Icon icon={roundClearAll} />}
        className="mt-3"
      >
        Clear All
      </Button>
    </div>
  );
};
