import { CircularProgress } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import CustomDrawer from "src/components/DrawerForm/CustomDrawer";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import LeadStatusMemberDetail from "./LeadStatusMemberDetail";
import { Icon } from "@iconify/react";
import LeadCenterFilter from "./LeadCenterFilter";
import {
  add_user_to_lead_stage_api,
  change_stage_order_api,
  delete_stages_list_api,
  lead_stages_list_api,
  remove_user_from_stage_api,
} from "src/DAL/LeadsCenter/lead_stages";
import LeadCenterSIngleItem from "./LeadCenterSIngleItem";
import CustomConfirmation from "src/components/GeneralComponents/CustomConfirmation";
import roundFilterList from "@iconify/icons-ic/round-filter-list";
import { makeStyles } from "@mui/styles";
import FilteredChip from "src/components/GeneralComponents/FilteredChip";
import LeadCenterMembers from "./LeadCenterMembers";
import { useSnackbar } from "notistack";
import { check_filter_exist, remove_before_dash } from "src/utils/constants";
import WhatsappPopUpModel from "src/components/GeneralComponents/WhatsappPopUpModel";
import LeadStatusHistory from "../Member/LeadStatusHistory";
import AddNewStage from "./AddNewStage";
import StagesPopup from "./StagesPopup";
import MenuPopover from "./MenuPopover";
import { useNavigate } from "react-router-dom";
import FullPagePopup from "src/components/GeneralComponents/FullPagePopup";
import PersonalMemberNotes from "../MemberProfile/PersonalMemberNotes";

const EMPTY_FILTER = {
  sale_page: null,
  lead_status: [],
  plan: null,
  nurture: null,
  delegate: null,
};

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const MENUS_WIDTH = 250;
const STAGES_POPUP_WIDTH = 300;
const tabName = "leads_center";

const handle_update_data = (formData) => {
  if (formData.sale_page) {
    formData.sale_page = formData.sale_page._id;
  }
  if (formData.plan) {
    formData.plan = formData.plan._id;
  }
  if (formData.nurture) {
    formData.nurture_id = formData.nurture._id;
  }
  if (formData.delegate) {
    formData.delegate_id = formData.delegate._id;
  }
  if (formData.lead_status.length > 0) {
    formData.lead_status = formData.lead_status.map((status) => status._id);
  }
  return formData;
};

const LeadsCenter = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [isLoading, setIsLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [openDrawer, seOpenDrawer] = useState(false);
  const [changeStatusOpen, setChangeStatusOpen] = useState(false);
  const [userValue, setUserValue] = useState({});
  const [membersData, setMembersData] = useState([]);
  const [allLeadStatus, setAllLeadStatus] = useState([]);
  const [leadStages, setLeadStages] = useState([]);
  const [stagesForPopup, setStagesForPopup] = useState([]);
  const [isFilterExists, setIsFilterExists] = useState(false);
  const [isShowNotes, setIsShowNotes] = useState(false);
  const [selectedObject, setSelectedObject] = useState({});
  const [filterDrawerState, setFilterDrawerState] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openDeleteUser, setOpenDeleteUser] = useState(false);
  const [openStagesBox, setOpenStagesBox] = useState(false);
  const [openUserMenu, setOpenUserMenu] = useState(false);
  const [filterData, setFilterData] = useState(EMPTY_FILTER);
  const [formDataUpdated, setformDataUpdated] = useState(EMPTY_FILTER);
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
  const [SaveFilter, setSaveFilter] = useState({
    filter_name: null,
    filter_From: "new",
  });

  const handleChangeOthers = (event, name) => {
    setFilterData((values) => ({ ...values, [name]: event }));
  };

  const handleSave = (event, name) => {
    setSaveFilter((values) => ({ ...values, [name]: event }));
  };

  const handleExistingFilter = (event) => {
    if (event == null || SaveFilter.filter_From == "new") {
      setFilterData(EMPTY_FILTER);
      setSaveFilter((values) => ({ ...values, ["filter_name"]: null }));
    } else {
      setFilterData(event.filter_object);
    }
  };

  const handleSubmitted = () => {
    setIsFilterExists(true);
  };

  const handleChangeSave = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setSaveFilter((values) => ({ ...values, [name]: value }));
    if (value == "new") {
      setSaveFilter((values) => ({ ...values, ["filter_name"]: null }));
      setFilterData(EMPTY_FILTER);
    }
  };

  const handle_add_user_to_a_stage = async (data) => {
    const { destination_id, user_id, source_id } = data;
    let postData = {
      stage_id: destination_id,
      user_id: user_id,
    };
    if (source_id !== "users") {
      postData.old_stage_id = source_id;
    }
    setOpenStagesBox(false);
    setOpenUserMenu(false);
    const result = await add_user_to_lead_stage_api(postData);
    if (result.code == 200) {
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handle_dragg_stage = async (order, stage_id) => {
    let postData = {
      order: order + 1,
    };
    setOpenUserMenu(false);
    const result = await change_stage_order_api(postData, stage_id);
    if (result.code == 200) {
      // enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleMoveUser = async (props) => {
    const { destination_id, source_id, user_id } = props;
    if (!destination_id) return;
    if (source_id === destination_id) return;
    let sourceList = membersData;
    if (source_id !== "users") {
      sourceList = leadStages.find((stage) => stage._id === source_id).users;
    }
    let destinationList = membersData;
    if (destination_id !== "users") {
      destinationList = leadStages.find(
        (stage) => stage._id === destination_id
      ).users;

      let already_exist = destinationList.find((user) => user._id === user_id);
      if (already_exist) {
        enqueueSnackbar("User already exists in this stage", {
          variant: "error",
        });
        return;
      }
    }

    let moved_user = sourceList.find((user) => user._id === user_id);

    if (source_id !== "users") {
      setLeadStages((prev) =>
        prev.map((item) => {
          if (item._id === source_id) {
            return {
              ...item,
              users: sourceList.filter((u) => u._id !== user_id),
              total_member_count: item.total_member_count - 1,
            };
          }
          return item;
        })
      );
    }

    if (destination_id !== "users") {
      setLeadStages((prev) =>
        prev.map((item) => {
          if (item._id === destination_id) {
            return {
              ...item,
              users: [...destinationList, moved_user],
              total_member_count: item.total_member_count + 1,
            };
          }
          return item;
        })
      );
    }
    if (destination_id === "users") {
      let postData = {
        stage_id: source_id,
        user_id: user_id,
      };
      let message = "User Removed Successfully";
      enqueueSnackbar(message, { variant: "success" });
      remove_user_from_stage_api(postData);
    } else {
      handle_add_user_to_a_stage(props);
    }
  };

  const onDragEnd = (result) => {
    const { source, destination, draggableId, type } = result;
    if (!destination) {
      return;
    }
    if (type === "PARENT") {
      const items = Array.from(leadStages);
      const [reorderedItem] = items.splice(source.index, 1);
      items.splice(destination.index, 0, reorderedItem);
      setLeadStages(items);
      handle_dragg_stage(destination.index, reorderedItem._id);
      return;
    }
    let source_id = remove_before_dash(source.droppableId);
    let destination_id = remove_before_dash(destination.droppableId);
    let user_id = remove_before_dash(draggableId);
    let dataObject = { source_id, destination_id, user_id };
    handleMoveUser(dataObject);
  };

  const handleClickUser = (stage) => {
    let source_id = selectedObject.stage;
    let destination_id = stage._id;
    let user_id = selectedObject._id;
    let dataObject = { source_id, destination_id, user_id };
    handleMoveUser(dataObject);
  };

  const handleClearFilter = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setSaveFilter({
      filter_name: null,
      filter_From: "new",
    });
    setFilterData(EMPTY_FILTER);
    get_lead_stages_list(EMPTY_FILTER);
    localStorage.setItem("leads_centre_filter", JSON.stringify(EMPTY_FILTER));
    setFilterDrawerState(false);
  };

  const handleAgreeDelete = (value) => {
    setSelectedObject(value);
    setOpenDelete(true);
  };

  const handleAgreeDeleteUser = (value) => {
    setOpenDeleteUser(true);
  };

  const handle_stage_loader = (stage_id, value) => {
    setLeadStages((prev) =>
      prev.map((item) => {
        if (item._id === stage_id) {
          return { ...item, is_loading: value };
        }
        return item;
      })
    );
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    handle_stage_loader(selectedObject._id, true);
    const result = await delete_stages_list_api(selectedObject._id);
    if (result.code === 200) {
      setLeadStages((prev) =>
        prev.filter((item) => item._id !== selectedObject._id)
      );
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleDeleteUser = async () => {
    setOpenDeleteUser(false);
    handle_stage_loader(selectedObject.stage, true);

    let postData = {
      stage_id: selectedObject.stage,
      user_id: selectedObject._id,
    };
    const result = await remove_user_from_stage_api(postData);
    if (result.code === 200) {
      setLeadStages((prev) =>
        prev.map((item) => {
          if (item._id === selectedObject.stage) {
            let users = item.users.filter((u) => u._id !== selectedObject._id);
            let total_member_count = item.total_member_count - 1;
            return { ...item, is_loading: false, users, total_member_count };
          }
          return item;
        })
      );
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleMoveToStage = () => {
    let user = selectedObject;
    let lead_stages = [...leadStages];
    setStagesForPopup(lead_stages);
    if (user.stage !== "users") {
      //don't show the stage in popup from where user is going to be moved
      setStagesForPopup(
        lead_stages.filter((stage) => stage._id !== user.stage)
      );
    }
    const targetElement = document.querySelector(".lead-centre-user-menus");
    const rect = targetElement.getBoundingClientRect();
    let left = rect.left;
    let top = rect.bottom + window.scrollY;
    if (rect.left > 300) {
      left = rect.left - STAGES_POPUP_WIDTH;
      top = rect.bottom - 40 + window.scrollY;
    }
    setPosition({ left, top });
    setTimeout(() => {
      setOpenStagesBox(true);
    }, 0);
  };

  const handleClickDots = (e, user) => {
    setSelectedObject(user);
    const targetElement = e.target;
    const rect = targetElement.getBoundingClientRect();
    const left = rect.left - MENUS_WIDTH;
    const top = rect.bottom - 5 + window.scrollY;
    setMenuPosition({ left, top });
    setTimeout(() => {
      setOpenUserMenu(true);
    }, 0);
  };

  const handle_view_profile = (value) => {
    navigate(`/leads-center/profile/${value._id}`, {
      state: value,
    });
  };

  const handlePersonelNotes = () => {
    setIsShowNotes(true);
  };

  const MENU_OPTIONS_member = [
    {
      label: "Personal Notes",
      icon: "gridicons:pages",
      handleClick: handlePersonelNotes,
    },
  ];

  if (leadStages.length > 0) {
    MENU_OPTIONS_member.unshift({
      label: "Move to a stage",
      icon: "fe:loop",
      handleClick: handleMoveToStage,
    });
  }

  const MENU_OPTIONS_USER = [
    {
      label: "Remove User",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDeleteUser,
    },
    {
      label: "Personal Notes",
      icon: "gridicons:pages",
      handleClick: handlePersonelNotes,
    },
  ];

  if (leadStages.length > 1) {
    MENU_OPTIONS_USER.push({
      label: "Move to an other stage",
      icon: "fe:loop",
      handleClick: handleMoveToStage,
    });
  }

  const handleOpenDrawer = (value) => {
    setUserValue(value);
    seOpenDrawer(true);
  };

  const handleCloseDrawer = () => {
    seOpenDrawer(false);
  };

  const handleOpenFilterDrawer = () => {
    setFilterDrawerState(true);
  };

  const handleCloseFilterDrawer = () => {
    setFilterDrawerState(false);
  };

  const searchFunction = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setPage(0);
    get_lead_stages_list(filterData);
    localStorage.setItem("leads_centre_filter", JSON.stringify(filterData));
    handleCloseFilterDrawer();
  };

  const handleOpenHistory = (value) => {
    setSelectedObject(value);
    setChangeStatusOpen(true);
  };

  const handleDeleteChip = (data) => {
    setIsLoading(true);
    setPage(0);
    setFilterData(data);
    localStorage.setItem("leads_centre_filter", JSON.stringify(data));
    get_lead_stages_list(data);
  };

  const get_lead_stages_list = async (flterData) => {
    let formData = { ...flterData };
    formData = handle_update_data(formData);
    formData.page = page;
    formData.limit = rowsPerPage;
    const result = await lead_stages_list_api(formData);
    if (result.code === 200) {
      setformDataUpdated(flterData);
      setLeadStages(result.stages);
      setTotalPages(result.total_pages);
      setTotalCount(result.total_member_count);
      setPageNumber((old) => old + 1);
      setMembersData(result.users);
      setAllLeadStatus(result.lead_status_array);
      const is_filter_exist = check_filter_exist(
        result.saved_portal_filter,
        flterData
      );
      setIsFilterExists(is_filter_exist);

      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    let filter_Data = EMPTY_FILTER;
    let leads_centre_filter = localStorage.getItem("leads_centre_filter");
    if (leads_centre_filter) {
      setFilterData(JSON.parse(leads_centre_filter));
      filter_Data = JSON.parse(leads_centre_filter);
    }
    get_lead_stages_list(filter_Data);
  }, [rowsPerPage, page]);

  function trackScrollLeft(e) {
    const targetMenu = document.querySelector(".lead-centre-user-menus");
    const stagesPopup = document.querySelector(".lead-stages-popup");
    if (targetMenu) {
      let value = e.target.scrollLeft;
      let given_value = menuPosition.left;
      targetMenu.style.left = `${given_value - value}px`;
      if (stagesPopup) {
        let stage_given_value = position.left;
        stagesPopup.style.left = `${stage_given_value - value}px`;
      }
    }
  }

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <div className="container-fluid">
          <div className="d-flex justify-content-between mb-3">
            <div className="d-flex">
              <div>
                <h2 className="lead-center text-nowrap">Leads Centre</h2>
              </div>
              <div className="mb-2 ms-4">
                <FilteredChip
                  data={formDataUpdated}
                  tempState={filterData}
                  EMPTY_FILTER={EMPTY_FILTER}
                  onDeleteChip={handleDeleteChip}
                  onClear={handleClearFilter}
                  tabName={isFilterExists ? "" : tabName}
                  handleSubmitted={handleSubmitted}
                />
              </div>
            </div>
            <div className="text-end">
              <button
                className="small-contained-button ms-2 text-nowrap"
                onClick={handleOpenFilterDrawer}
              >
                Filters &nbsp;&nbsp; <Icon icon={roundFilterList} />
              </button>{" "}
            </div>
          </div>
        </div>
        <div
          className="h-100 d-flex"
          id="lead-center-flex"
          style={{ overflowY: "auto" }}
          onScroll={trackScrollLeft}
        >
          <LeadCenterMembers
            membersData={membersData}
            handleClickDots={handleClickDots}
            setMembersData={setMembersData}
            filterData={filterData}
            handleOpenHistory={handleOpenHistory}
            allLeadStatus={allLeadStatus}
            pageNumber={pageNumber}
            totalPages={totalPages}
            setPageNumber={setPageNumber}
            setTotalPages={setTotalPages}
            setTotalCount={setTotalCount}
            totalCount={totalCount}
            rowsPerPage={rowsPerPage}
            handle_update_data={handle_update_data}
            handle_view_profile={handle_view_profile}
            onScroll={trackScrollLeft}
            openUserMenu={openUserMenu}
          />
          <Droppable droppableId="parents" type="PARENT" direction="horizontal">
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className="d-flex"
              >
                {leadStages.length > 0 &&
                  leadStages.map((lead, index) => (
                    <Draggable
                      key={index}
                      draggableId={`parent-${index}`}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                        >
                          <div {...provided.dragHandleProps}>
                            <LeadCenterSIngleItem
                              lead={lead}
                              setLeadStages={setLeadStages}
                              handleAgreeDelete={handleAgreeDelete}
                              handle_update_data={handle_update_data}
                              handleOpenHistory={handleOpenHistory}
                              allLeadStatus={allLeadStatus}
                              filterData={filterData}
                              rowsPerPage={rowsPerPage}
                              handleClickDots={handleClickDots}
                              handle_view_profile={handle_view_profile}
                              status_index={index}
                              provided={provided}
                              openUserMenu={openUserMenu}
                            />
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}

                {provided.placeholder}
              </div>
            )}
          </Droppable>
          <AddNewStage setLeadStages={setLeadStages} />
        </div>
        {openStagesBox && (
          <StagesPopup
            leadStages={stagesForPopup}
            position={position}
            setOpenStagesBox={setOpenStagesBox}
            handleClick={handleClickUser}
            POPUP_WIDTH={STAGES_POPUP_WIDTH}
          />
        )}
        <CustomDrawer
          isOpenDrawer={openDrawer}
          onOpenDrawer={handleOpenDrawer}
          onCloseDrawer={handleCloseDrawer}
          pageTitle="Member Detail"
          componentToPassDown={<LeadStatusMemberDetail userValue={userValue} />}
        />
      </DragDropContext>
      {openUserMenu && (
        <MenuPopover
          menuPosition={menuPosition}
          setOpenUserMenu={setOpenUserMenu}
          menu={
            selectedObject.stage === "users"
              ? MENU_OPTIONS_member
              : MENU_OPTIONS_USER
          }
          MENUS_WIDTH={MENUS_WIDTH}
        />
      )}
      <CustomDrawer
        isOpenDrawer={filterDrawerState}
        onOpenDrawer={handleOpenFilterDrawer}
        onCloseDrawer={handleCloseFilterDrawer}
        pageTitle="Lead Centre Filter"
        componentToPassDown={
          <LeadCenterFilter
            filterData={filterData}
            setFilterData={setFilterData}
            handleChangeOthers={handleChangeOthers}
            searchSubmitFilter={searchFunction}
            handleClearFilter={handleClearFilter}
            is_delegate_filter={true}
            is_nurture_filter={true}
            consultant_both={true}
            tabName={tabName}
            handleExistingFilter={handleExistingFilter}
            SaveFilter={SaveFilter}
            handleChangeSave={handleChangeSave}
            handleSave={handleSave}
          />
        }
      />
      <WhatsappPopUpModel
        open={changeStatusOpen}
        setOpen={setChangeStatusOpen}
        title={"Lead Status History"}
        show_date_and_income={true}
        componentToPassDown={
          <LeadStatusHistory leadStatusData={selectedObject} />
        }
      />
      <FullPagePopup
        open={isShowNotes}
        setOpen={setIsShowNotes}
        componentToPassDown={
          <PersonalMemberNotes
            is_popup={true}
            user_id={selectedObject._id}
            handleClose={() => setIsShowNotes(false)}
          />
        }
      />
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this stage?"}
        handleAgree={handleDelete}
      />
      <CustomConfirmation
        open={openDeleteUser}
        setOpen={setOpenDeleteUser}
        title={"Are you sure you want to remove user from this stage?"}
        handleAgree={handleDeleteUser}
      />
    </>
  );
};

export default LeadsCenter;
