import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import MUICustomTabs from "src/components/GeneralComponents/MUICustomTabs";
import SupportTicketsTabs from "./supportTicketsTabs";
import { useContentSetting } from "src/Hooks/ContentSettingState";
import CircleIcon from "@mui/icons-material/Circle";
import { consultantProfileApi } from "src/DAL/Login/Login";
import { _move_need_attention } from "src/DAL/SupportTicket/SupportTicket";

const SupportTicketNew = () => {
  const { state, pathname } = useLocation();
  const params = useParams();
  const { userInfo, handleSetUserInfo } = useContentSetting();
  const [tabValue, setTabValue] = useState(0);
  const [Waiting, setWaiting] = useState(" ");
  const [Answered, setAnswered] = useState(" ");
  const [Close, setClose] = useState(" ");
  const [Trash, setTrash] = useState(" ");
  const [Attention, setAttention] = useState(" ");
  const [fixesCount, setfixesCount] = useState(" ");
  const [reminder, setReminder] = useState(" ");
  const [reminderTab, setreminderTab] = useState(true);
  const [readyToCloseTab, setreadyToCloseTab] = useState(true);
  const [CloseTab, setCloseTab] = useState(true);
  const [fixDate, setfixDate] = useState(true);
  const [fixDatealert, setfixDatealert] = useState(false);
  const [needFixCount, setneedFixCount] = useState(0);

  const [ReadyToCLose, setReadyToCLose] = useState(" ");
  ReadyToCLose;
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const getProfile = async () => {
    const result = await consultantProfileApi(userInfo?._id);
    if (result.code == 200) {
      localStorage.setItem(`user_data`, JSON.stringify(result.consultant));
      localStorage.setItem(`admin_time_zone`, JSON.stringify(result.time_zone));
      handleSetUserInfo(result.consultant);
    }
  };
  const handleMoveNeedAttention = async () => {
    setopenNeedAttention(false);
    setIsLoading(true);
    const result = await _move_need_attention(ticketId);
    if (result.code === 200) {
      getTickestsListing("all");
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
      socket.emit("send_add_support_ticket_comment", result);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (state) {
      setTabValue(state.tabValue ? state.tabValue : 0);
      window.history.replaceState({}, document.title);
    }
  }, [state]);

  useEffect(() => {
    getProfile();
  }, [tabValue]);

  const TABS_OPTIONS = [
    {
      title: (
        <div className="d-flex">
          Waiting ({Waiting == undefined ? 0 : Waiting})
          {userInfo.notify_tab == "waiting" && (
            <CircleIcon className="support_notification_sidebar_page" />
          )}
        </div>
      ),
      // title: `Waiting (${Waiting == undefined ? 0 : Waiting}) ${(
      //   <CircleIcon className="support_notification_sidebar" />
      // )} `,
      component: (
        <SupportTicketsTabs
          tabValue={tabValue}
          valueData={"waiting"}
          setWaiting={setWaiting}
          setAnswered={setAnswered}
          setClose={setClose}
          setTrash={setTrash}
          setfixDatealert={setfixDatealert}
          setfixesCount={setfixesCount}
          setAttention={setAttention}
          setReminder={setReminder}
          setReadyToCLose={setReadyToCLose}
          setneedFixCount={setneedFixCount}
        />
      ),
    },
    {
      title: (
        <div className="d-flex">
          Answered ({Answered == undefined ? 0 : Answered})
          {userInfo.notify_tab == "answered" && (
            <CircleIcon className="support_notification_sidebar_page" />
          )}
        </div>
      ),
      // title: `Answered (${Answered == undefined ? 0 : Answered})`,
      component: (
        <SupportTicketsTabs
          tabValue={tabValue}
          valueData={"answered"}
          setWaiting={setWaiting}
          setAnswered={setAnswered}
          setClose={setClose}
          setTrash={setTrash}
          setfixDatealert={setfixDatealert}
          setfixesCount={setfixesCount}
          setAttention={setAttention}
          setReminder={setReminder}
          setReadyToCLose={setReadyToCLose}
          setneedFixCount={setneedFixCount}
        />
      ),
    },
    {
      title: (
        <div className="d-flex">
          Needs Fixes ({fixesCount == undefined ? 0 : fixesCount})
          {userInfo.notify_tab == "need_fixes" && (
            <CircleIcon className="support_notification_sidebar_page" />
          )}
          {needFixCount > 0 ? (
            <CircleIcon
              className="support_notification_sidebar_page"
              style={{ color: "red" }}
            />
          ) : (
            ""
          )}
        </div>
      ),

      // title: `Needs Fixes (${fixesCount == undefined ? 0 : fixesCount})`,
      component: (
        <SupportTicketsTabs
          tabValue={tabValue}
          valueData={"need_fixes"}
          setWaiting={setWaiting}
          setAnswered={setAnswered}
          setClose={setClose}
          setTrash={setTrash}
          setfixesCount={setfixesCount}
          setAttention={setAttention}
          setfixDatealert={setfixDatealert}
          setReminder={setReminder}
          setReadyToCLose={setReadyToCLose}
          setneedFixCount={setneedFixCount}
          fixDate={fixDate}
        />
      ),
    },
    {
      title: (
        <div className="d-flex">
          Needs Attention ({Attention == undefined ? 0 : Attention})
          {userInfo.notify_tab == "need_to_attention" && (
            <CircleIcon className="support_notification_sidebar_page" />
          )}
        </div>
      ),

      // title: `Needs Attention (${Attention == undefined ? 0 : Attention})`,
      component: (
        <SupportTicketsTabs
          tabValue={tabValue}
          valueData={"needs_to_attention"}
          setWaiting={setWaiting}
          setAnswered={setAnswered}
          setClose={setClose}
          setTrash={setTrash}
          setneedFixCount={setneedFixCount}
          setfixesCount={setfixesCount}
          setAttention={setAttention}
          setReminder={setReminder}
          setfixDatealert={setfixDatealert}
          setReadyToCLose={setReadyToCLose}
        />
      ),
    },
    {
      title: `Reminders (${reminder == undefined ? 0 : reminder})`,
      component: (
        <SupportTicketsTabs
          tabValue={tabValue}
          valueData={"reminder"}
          setWaiting={setWaiting}
          setAnswered={setAnswered}
          setneedFixCount={setneedFixCount}
          setfixDatealert={setfixDatealert}
          setClose={setClose}
          setTrash={setTrash}
          setfixesCount={setfixesCount}
          setAttention={setAttention}
          setReminder={setReminder}
          setReadyToCLose={setReadyToCLose}
          reminderTab={reminderTab}
        />
      ),
    },
    {
      title: `Ready to CLose (${ReadyToCLose == undefined ? 0 : ReadyToCLose})`,
      component: (
        <SupportTicketsTabs
          tabValue={tabValue}
          valueData={"ready_to_close"}
          setWaiting={setWaiting}
          setAnswered={setAnswered}
          setClose={setClose}
          setTrash={setTrash}
          setneedFixCount={setneedFixCount}
          setfixDatealert={setfixDatealert}
          setfixesCount={setfixesCount}
          setAttention={setAttention}
          setReminder={setReminder}
          setReadyToCLose={setReadyToCLose}
          readyToCloseTab={readyToCloseTab}
        />
      ),
    },
    {
      title: `Close (${Close == undefined ? 0 : Close})`,
      component: (
        <SupportTicketsTabs
          tabValue={tabValue}
          valueData={"solved"}
          setWaiting={setWaiting}
          setAnswered={setAnswered}
          setClose={setClose}
          setTrash={setTrash}
          setfixesCount={setfixesCount}
          setAttention={setAttention}
          setneedFixCount={setneedFixCount}
          setfixDatealert={setfixDatealert}
          setReminder={setReminder}
          setReadyToCLose={setReadyToCLose}
          CloseTab={CloseTab}
        />
      ),
    },
    {
      title: `Trash (${Trash == undefined || Trash == 0 ? 0 : Trash})`,
      component: (
        <SupportTicketsTabs
          tabValue={tabValue}
          valueData={"trash"}
          setWaiting={setWaiting}
          setAnswered={setAnswered}
          setClose={setClose}
          setTrash={setTrash}
          setfixesCount={setfixesCount}
          setAttention={setAttention}
          setReminder={setReminder}
          setneedFixCount={setneedFixCount}
          setfixDatealert={setfixDatealert}
          setReadyToCLose={setReadyToCLose}
        />
      ),
    },
  ];
  return (
    <div className="container">
      <div className="row section-space">
        <div className="col-sm-12 col-md-6 mb-4">
          <h2>Support Tickets</h2>
        </div>
        <div className="col-12">
          <MUICustomTabs
            data={TABS_OPTIONS}
            value={tabValue}
            handleChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
};

export default SupportTicketNew;
