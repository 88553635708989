import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { s3baseUrl } from "src/config/config";
// import { convertCurrencyToSign, show_proper_words } from "src/utils/constant";
import moment from "moment";
// import { pending_commission_list_detail } from "src/DAL/PendingCommissions/PendingCommissions";
import { fShortenNumber } from "src/utils/formatNumber";
import ActiveLastBreadcrumb from "src/components/BreadCrums";
import { convertCurrencyToSign, show_proper_words } from "src/utils/constants";
import { commission_list_detail } from "src/DAL/salesTeam/SalesTeam";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function SalesTeamCommissionsDetail({ type_paid }) {
  const classes = useStyles();
  const params = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [searchText, setSearchText] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [userList, setUserList] = useState([]);
  const [userInfo, setUserInfo] = useState("");

  const pendingCommissionList = async () => {
    setIsLoading(true);
    let postData = { type: "credit", search_text: "" };
    if (searchText) {
      postData.search_text = searchText;
    }

    const result = await commission_list_detail(
      params?.id,
      postData,
      page,
      rowsPerPage
    );

    if (result.code === 200) {
      let data = result.transaction.map((transaction) => {
        let date = "N/A";
        if (transaction.transaction_date) {
          date = moment(transaction.transaction_date).format("DD-MM-YYYY");
        }

        let transaction_title = "";
        if (transaction.transaction_type == "payment_request") {
          transaction_title = transaction.payment_request_info
            ? "Payment Request ( " +
              transaction.payment_request_info.request_title +
              " | " +
              show_proper_words(transaction.payment_request_info.request_type) +
              " )"
            : "( N/A )";
        } else if (transaction.transaction_type == "payment_plan") {
          transaction_title = transaction.sale_page_info
            ? "Sale Page ( " +
              transaction.sale_page_info.sale_page_title +
              " | " +
              transaction.plan_info?.plan_title +
              " )"
            : "( N/A )";
        }

        return {
          ...transaction,
          transaction_title,
          date,
        };
      });
      setUserList(data);
      setUserInfo(result.consultant);
      setTotalPages(result.total_pages);
      setTotalCount(result.total_member_count);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
    localStorage.setItem("member_page_number", newPage);
  };

  const searchFunction = () => {
    setPage(0);
    setPageCount(1);
    pendingCommissionList();
  };

  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    { id: "user_name", label: "Name" },

    { id: "transaction_title", label: "Transaction" },
    {
      id: "type",
      label: "Commission Amount",
      renderData: (row) => {
        return (
          <>
            {convertCurrencyToSign(row.currency) +
              fShortenNumber(
                row?.marketing_affiliate_comission
                  ? row?.marketing_affiliate_comission
                  : "0.00"
              )}
          </>
        );
      },
    },

    { id: "date", label: "Date" },
  ];

  let breadCrumbMenu = [
    {
      title: "Sales Team",
      navigation: -1,
      active: false,
    },

    {
      title:
        userInfo?.first_name +
        " " +
        userInfo?.last_name +
        " " +
        "(" +
        userInfo?.email +
        ")",
      active: true,
    },
  ];

  useEffect(() => {
    pendingCommissionList();
  }, [rowsPerPage, page]);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="container">
        <div className="row mb-4">
          <div className="col-12 mb-3">
            <span>
              <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
            </span>
          </div>
          <div className="col-lg-9 col-sm-12">
            <h2>Commission Detail</h2>
          </div>
        </div>
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={userList}
          className="card-with-background"
          custom_pagination={{
            total_count: totalCount,
            rows_per_page: rowsPerPage,
            page: page,
            handleChangePage: handleChangePage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          custom_search={{
            searchText: searchText,
            setSearchText: setSearchText,
            handleSubmit: searchFunction,
          }}
          pageCount={pageCount}
          totalPages={totalPages}
          handleChangePages={handleChangePages}
          pagePagination={true}
        />
      </div>
    </>
  );
}
