import React from "react";
import Chip from "@mui/material/Chip";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import VideocamIcon from "@mui/icons-material/Videocam";
import { s3baseUrl } from "src/config/config";
import { Avatar } from "@mui/material";
import { string_avatar } from "src/utils/constants";
import LiveTvIcon from "@mui/icons-material/LiveTv";
import { useContentSetting } from "src/Hooks/ContentSettingState";
import CodeIcon from "@mui/icons-material/Code";

function OpenCreatePostCard({ handleOpenCreateBox }) {
  const { userInfo } = useContentSetting();

  return (
    <div className="open-create-post-card mb-2">
      <div className="profile-cards p-3 community-card-title wealth-wallet">
        <div className="input-with-profile d-flex">
          <Avatar src={s3baseUrl + userInfo?.image?.thumbnail_1} alt="photoURL">
            {string_avatar(
              userInfo?.last_name
                ? userInfo?.first_name + " " + userInfo.last_name
                : userInfo?.first_name
            )}
          </Avatar>
          {userInfo.first_name ? (
            <div
              className="input-field ms-2"
              onClick={() => {
                handleOpenCreateBox("general");
              }}
            >
              What's on your mind, {userInfo.first_name}?
            </div>
          ) : (
            <div
              className="input-field ms-2"
              onClick={() => {
                handleOpenCreateBox("general");
              }}
            >
              What's on your mind?
            </div>
          )}
        </div>
        <hr className="line" />
        <div className="row">
          <div className="col-4 col-md-4 col-lg-4 mt-sm-0 mt-md-0  main-icon-conatiner">
            <div className="upload-video upload-video-update">
              <Chip
                onClick={() => {
                  handleOpenCreateBox("video");
                }}
                label={
                  <>
                    <span className="main-icon-content"> Upload Video </span>

                    <VideocamIcon />
                  </>
                }
                variant="outlined"
              />
            </div>
          </div>
          <div className="col-4 col-md-4 col-lg-4 mt-sm-0 mt-md-0  main-icon-conatiner">
            <div className="upload-video upload-video-update">
              <Chip
                onClick={() => {
                  handleOpenCreateBox("image");
                }}
                label={
                  <>
                    <span className="main-icon-content"> Upload Image</span>

                    <PhotoCameraIcon />
                  </>
                }
                variant="outlined"
              />
            </div>
          </div>
          <div className="col-4 col-md-4 col-lg-4  mt-sm-0 mt-md-0">
            <div
              className={`upload-video upload-video-update ${
                userInfo.is_executive ? "" : "force-disabled"
              }`}
            >
              <Chip
                onClick={() => {
                  handleOpenCreateBox("embed_code");
                }}
                label={
                  <>
                    <span className="main-icon-content"> Embed Code</span>
                    <CodeIcon />
                  </>
                }
                variant="outlined"
              />
            </div>
          </div>
          {/* <div className="col-12 col-md-4  mt-3 mt-md-0">
            <div
              className={`upload-video ${
                userInfo.is_executive ? "" : "force-disabled"
              }`}
            >
              <Chip
                onClick={() => {
                  handleOpenCreateBox("live");
                }}
                label={
                  <>
                    Livestream
                    <LiveTvIcon />
                  </>
                }
                variant="outlined"
              />
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default OpenCreatePostCard;
