import React, { useState, useEffect } from "react";
import moment from "moment";

const CountdownTimer = ({ moduleDetail, setIsTimePassed }) => {
  const { meeting_time_zone, meeting_start_date, meeting_start_time } =
    moduleDetail;

  const targetDate = moment.tz(
    `${meeting_start_date} ${meeting_start_time}`,
    "YYYY-MM-DD HH:mm:ss",
    meeting_time_zone
  );

  const calculateTimeLeft = () => {
    const now = moment.tz(new Date(), meeting_time_zone);
    const duration = moment.duration(targetDate.diff(now));
    const totalSeconds = targetDate.diff(now, "seconds");
    if (totalSeconds === 0 || totalSeconds < 0) {
      setIsTimePassed(true);
    }

    return {
      days: Math.floor(duration.asDays()),
      hours: duration.hours(),
      minutes: duration.minutes(),
      seconds: duration.seconds(),
    };
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      const newTimeLeft = calculateTimeLeft();
      // If countdown has ended, clear the interval
      if (
        newTimeLeft.days <= 0 &&
        newTimeLeft.hours <= 0 &&
        newTimeLeft.minutes <= 0 &&
        newTimeLeft.seconds <= 0
      ) {
        clearInterval(timer);
      }

      setTimeLeft(newTimeLeft);
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div className="live-stream-timer">
      <div className="d-flex">
        {timeLeft.days > 0 && (
          <div className="timer-item">
            <div className="timer-label">Days</div>
            <div className="timer-count">
              {timeLeft.days < 10 ? `0${timeLeft.days}` : timeLeft.days}
            </div>
          </div>
        )}
        <div className="timer-item">
          <div className="timer-label">Hours</div>
          <div className="timer-count">
            {timeLeft.hours < 10 ? `0${timeLeft.hours}` : timeLeft.hours}
          </div>
        </div>
        <div className="timer-item">
          <div className="timer-label">Minutes</div>
          <div className="timer-count">
            {timeLeft.minutes < 10 ? `0${timeLeft.minutes}` : timeLeft.minutes}
          </div>
        </div>
        <div className="timer-item">
          <div className="timer-label">Seconds</div>
          <div className="timer-count">
            {timeLeft.seconds < 10 ? `0${timeLeft.seconds}` : timeLeft.seconds}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CountdownTimer;
