import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton } from "@mui/material";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import { htmlDecode } from "src/utils/convertHtml";
import moment from "moment";
import { s3baseUrl } from "src/config/config";
import ReactVideoPlayer from "src/components/ReactVideoPlayer";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

function TutorialDetail() {
  const navigate = useNavigate();
  const { state } = useLocation();

  return (
    <div className="container new-community-page">
      <section className="success-wrapper pt-2">
        <div className="container">
          <div className="row">
            <div className="col-12 d-flex">
              <span>
                <IconButton
                  className="back-screen-button"
                  onClick={() => navigate(-1)}
                >
                  <ArrowBackIcon />
                </IconButton>
              </span>
              <span className="ms-3">
                <h1>{htmlDecode(state.card_title)}</h1>
                <p style={{ color: "white" }}>
                  {htmlDecode(state.card_short_description)}
                </p>
              </span>
            </div>
            <div className="col-lg-12"></div>
            <div className="col-lg-12 mt-2">
              <ReactVideoPlayer url={state.video_url} />
            </div>
            <div className="col-lg-12 mt-3">
              <div
                dangerouslySetInnerHTML={{
                  __html: state.detailed_description,
                }}
              ></div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default TutorialDetail;
