import React from "react";
import { Autocomplete, TextField } from "@mui/material";
export default function ChangeLeadStatusForList({
  selectedObject,
  leadStatus,
  handleChangeLeadStatus,
  open,
  setOpenDiv,
}) {
  const [value, setValue] = React.useState(true);
  const handleChangeOthers = (value) => {
    setValue(false);
    handleChangeLeadStatus(value, selectedObject);
  };
  return (
    <>
      <form className="row">
        <div
          className={`col-lg-12 col-md-6 col-sm-12 for-list ${
            selectedObject.lead_status == null ? "" : "for-hide"
          }`}
          style={{ width: "270px" }}
        >
          <Autocomplete
            size="small"
            value={
              selectedObject.lead_status ? selectedObject.lead_status : null
            }
            open={value}
            onOpen={() => setValue(true)}
            onClose={() => setValue(false)}
            getOptionLabel={(option) => (option.title ? option.title : "")}
            onChange={(event, newValue) => {
              handleChangeOthers(newValue);
            }}
            style={{
              backgroundColor: selectedObject.lead_status
                ? selectedObject.lead_status?.background_color
                : "",
              borderRadius: "10px",
            }}
            id="combo-box-demo"
            options={leadStatus}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Lead Status"
                onBlur={() => setValue(false)}
                autoFocus
                inputProps={{
                  ...params.inputProps,
                  style: {
                    color: selectedObject.lead_status
                      ? selectedObject.lead_status?.text_color
                      : "transparant",
                    ...params.inputProps.style, // Include other styles from inputProps
                  },
                }}
              />
            )}
          />
        </div>
      </form>
    </>
  );
}
