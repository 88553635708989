import { Card } from "@mui/material";
import React, { useEffect } from "react";
import { s3baseUrl } from "src/config/config";
import { htmlDecode } from "src/utils/convertHtml";
import CatagoryChatCard from "./CatagoryChatCard";
import { useState } from "react";
import RecordNotFound from "src/components/GeneralComponents/RecordNotFound";

export default function CategoryCard({ category, handleOpenLikes }) {
  const [videosList, setVideosList] = useState([]);

  const handleToggleChat = (video) => {
    setVideosList((old) => {
      return old.map((item) => {
        let show_chat = Boolean(item.show_chat);
        if (item._id === video._id) {
          item.show_chat = !show_chat;
        }
        return item;
      });
    });
  };

  useEffect(() => {
    setVideosList(category.dynamite_event_category_video);
  }, [category]);

  return (
    <>
      {videosList && videosList.length > 0 ? (
        videosList.map((video, index) => {
          return (
            <>
              <div className="row align-center">
                <div
                  className={`col-12 ${video.show_chat ? "col-md-8" : ""}`}
                  id={video._id}
                >
                  <Card
                    className={`lesson-card ${"lesson-card-${index}"}  p-3 mt-3`}
                  >
                    <div className="col-12">
                      <div className="row">
                        <div className="col-12 col-md-9">
                          <h4>{htmlDecode(video.title)}</h4>
                        </div>
                        <div className="col-6 d-block d-md-none"></div>
                        <div className="col-6 col-md-3 text-end">
                          <button
                            onClick={() => handleToggleChat(video)}
                            className="ms-3 comment-submit-button"
                          >
                            {` ${video.show_chat ? "Hide" : "Show"}`} Chat
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 mb-3">
                      {video.video_url && (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: video.video_url,
                          }}
                        ></div>
                      )}
                      {video.video_url === "" && (
                        <img
                          src={s3baseUrl + video.image.thumbnail_1}
                          alt=""
                          srcSet=""
                        />
                      )}
                    </div>
                    {video.audio_file_url && video.video_type === "audio" && (
                      <div className="col-12 mt-3 text-center">
                        <div>
                          <audio
                            controlsList="nodownload"
                            className="w-100"
                            src={s3baseUrl + video.audio_file_url}
                            controls
                          />
                        </div>
                      </div>
                    )}
                    <div className="col-12 mt-3">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: video.detail_description,
                        }}
                      ></div>
                    </div>
                  </Card>
                </div>
                {video.show_chat && (
                  <div className="col-12 col-md-4 mt-md-3">
                    <CatagoryChatCard
                      video={video}
                      handleOpenLikes={handleOpenLikes}
                    />
                  </div>
                )}
              </div>
            </>
          );
        })
      ) : (
        <RecordNotFound title="Data not found" />
      )}
    </>
  );
}
