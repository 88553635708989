import React, { useState } from "react";
import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  TextareaAutosize,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import PageDescription from "../GeneralComponents/PageDescription";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import Slider from "@mui/material/Slider";
import {
  get_performance_data,
  perfomance_add_api,
  updatePerformanceApi,
  update_reminder_time_performanceapi,
} from "src/DAL/Tracking/Tracking";
import moment from "moment";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import GeneralPopUpModel from "../GeneralComponents/GeneralPopUpModel";
import GeneralPopUpModelWarning from "../GeneralComponents/GeneralPopUpModelWarning";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
  fonts: {
    fontSize: "13px",
    fontWeight: "500",
  },
  root: {
    color: "#f6bd4b",
    // color for the part that is not covered
  },
  rail: {
    color: "#888", // color for the part that is covered
  },
}));
const marks = [
  {
    value: 0,
    label: <span style={{ color: "gray" }}>0</span>,
  },

  {
    value: 10,
    label: "10",
  },
];
const PerformanceStreakcopy = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = React.useState(false);
  const [memberData, setMemberData] = React.useState("");
  const [SettingObject, setSettingObject] = React.useState({});
  const [ButtonLoader, setButtonLoader] = React.useState(false);

  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();
  const [inputs, setinputs] = useState({
    time: null,
    days: [],
    attitude_performance_rate: 0,
    focus_performance_rate: 0,
    desire_performance_rate: 0,
    discipline_performance_rate: 0,
    win_note: "",
    win_note_performance_rate: 0,
  });

  const classes = useStyles();
  const handleChange = (newValue, name) => {
    setinputs((prevState) => {
      return {
        ...prevState,
        [name]: newValue?.$d,
      };
    });
  };
  let total_scroe =
    inputs.attitude_performance_rate +
    inputs.focus_performance_rate +
    inputs.desire_performance_rate +
    inputs.discipline_performance_rate +
    inputs.win_note_performance_rate;
  console.log(total_scroe, "total_scroetotal_scroetotal_scroe");
  const handleChangeInputs = (newValue, name) => {
    const value = newValue.target.value;
    setinputs((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };
  const handleUpdateReminderTime = async (e) => {
    e.preventDefault();
    let postData = {
      dynamite_streak_performance_reminder_time: {
        time: moment(inputs.time).format("HH:mm"),
        days: inputs.days,
      },
    };
    const result = await update_reminder_time_performanceapi(postData);
    if (result.code == 200) {
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleSubmit = async () => {
    setButtonLoader(true);
    let postData = {
      attitude_performance_rate: inputs.attitude_performance_rate,
      focus_performance_rate: inputs.focus_performance_rate,
      desire_performance_rate: inputs.desire_performance_rate,
      discipline_performance_rate: inputs.discipline_performance_rate,
      win_note: inputs.win_note,
      win_note_performance_rate: inputs.win_note_performance_rate,
    };
    const result = await perfomance_add_api(postData);
    if (result.code == 200) {
      setButtonLoader(false);
      if (!memberData) {
        getPerformanceData();
      }
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setButtonLoader(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleUpdate = async () => {
    setButtonLoader(true);
    let postData = {
      attitude_performance_rate: inputs.attitude_performance_rate,
      focus_performance_rate: inputs.focus_performance_rate,
      desire_performance_rate: inputs.desire_performance_rate,
      discipline_performance_rate: inputs.discipline_performance_rate,
      win_note: inputs.win_note,
      win_note_performance_rate: inputs.win_note_performance_rate,
    };
    const result = await updatePerformanceApi(memberData, postData);
    if (result.code == 200) {
      setButtonLoader(false);
      if (!memberData) {
        getPerformanceData();
      }
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setButtonLoader(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const getPerformanceData = async () => {
    setIsLoading(true);
    const result = await get_performance_data();
    if (result.code == 200) {
      setShowPopup(!result.is_reminder_added);
      setMemberData(result.strek._id);
      setSettingObject(result?.streak_performance_setting);
      setinputs((inputs) => ({
        ...inputs,
        ["time"]: result.dynamite_streak_performance_reminder_time.time
          ? result.dynamite_streak_performance_reminder_time.time
          : null,
        ["days"]: result.dynamite_streak_performance_reminder_time.days
          ? result.dynamite_streak_performance_reminder_time.days
          : [],
        ["attitude_performance_rate"]: result.strek.attitude_performance_rate
          ? result.strek.attitude_performance_rate
          : 0,
        ["desire_performance_rate"]: result.strek.desire_performance_rate
          ? result.strek.desire_performance_rate
          : 0,
        ["discipline_performance_rate"]: result.strek
          .discipline_performance_rate
          ? result.strek.discipline_performance_rate
          : 0,
        ["focus_performance_rate"]: result.strek.focus_performance_rate
          ? result.strek.focus_performance_rate
          : 0,
        ["win_note"]: result.strek.win_note ? result.strek.win_note : "",
        ["win_note_performance_rate"]: result.strek.win_note_performance_rate
          ? result.strek.win_note_performance_rate
          : 0,
      }));

      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handleNavigate = () => {
    navigate(`/performance-streak/past-activities`);
  };
  useEffect(() => {
    getPerformanceData();
  }, []);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  const PERFORMANCES = [
    {
      label: "Your Attitude",
      var_name: "attitude_performance_rate",
      var_text: "your_attitude_text",
    },
    {
      label: "Your Focus",
      var_name: "focus_performance_rate",
      var_text: "your_focus_text",
    },
    {
      label: "Your Desire",
      var_name: "desire_performance_rate",
      var_text: "your_desire_text",
    },
    {
      label: "Your Discipline",
      var_name: "discipline_performance_rate",
      var_text: "your_discipline_text",
    },
    {
      label: "Rate This Win",
      var_name: "win_note_performance_rate",
      var_text: "rate_this_win_text",
    },
  ];

  return (
    <>
      <div className="container">
        <div className="row mb-2 mt-4">
          <div className="col-lg-5">
            <PageDescription
              parameter="vault_description"
              else_title={
                SettingObject?.performance_streak_heading ??
                "Performance Streak"
              }
            />
          </div>
          <form
            onSubmit={(e) => {
              handleUpdateReminderTime(e, "morning_reminder");
            }}
            className="col-12 col-lg-7 mt-4 mt-md-3 mt-lg-2"
          >
            <div className="row">
              <div className="col-12 col-md-5">
                <FormControl variant="outlined" size="small" required>
                  <InputLabel id="demo-simple-select-outlined-label">
                    Select Streak Days
                  </InputLabel>
                  <Select
                    multiple
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={inputs.days}
                    onChange={(e) => handleChangeInputs(e, "days")}
                    label="Select Streak Days"
                    name="days"
                  >
                    <MenuItem value={0}>Sunday</MenuItem>
                    <MenuItem value={1}>Monday</MenuItem>
                    <MenuItem value={2}>Tuesday</MenuItem>
                    <MenuItem value={3}>Wednesday</MenuItem>
                    <MenuItem value={4}>Thursday</MenuItem>
                    <MenuItem value={5}>Friday</MenuItem>
                    <MenuItem value={6}>Saturday</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="col-8 col-md-5 mt-3 mt-md-0 pe-0 ">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    label="Reminder Time"
                    value={inputs.time}
                    onChange={(e) => {
                      handleChange(e, "time");
                    }}
                    renderInput={(params) => (
                      <TextField {...params} size="small" required={true} />
                    )}
                  />
                </LocalizationProvider>
              </div>
              <div className="col-4 col-md-2 mt-3 mt-md-0 ps-1">
                <span className="float-end">
                  <button className="submit-button" type="submit">
                    Save
                  </button>
                </span>
              </div>
            </div>
          </form>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className=" mt-3 h-100">
              <div className="row" style={{ padding: "0px 0px" }}>
                <h2
                  className="mt-2 mb-4"
                  style={{
                    fontSize: "20px",
                    fontWeight: "600",

                    color: "white",
                  }}
                >
                  {SettingObject?.rate_your_performance_streak_heading ??
                    "Rate Your Performance"}
                </h2>

                {PERFORMANCES.map((item) => {
                  return (
                    <div className="col-lg-6" key={item.var_name}>
                      <div className=" input-accountability-box-performance">
                        <span className="input-accountability-label input-accountability-labe-performance">
                          {SettingObject && SettingObject[item.var_text]
                            ? SettingObject[item.var_text]
                            : item.label}
                        </span>
                        <div className="input-slider">
                          <span className="mt-1 me-3">0</span>
                          <Slider
                            aria-label="Temperature"
                            defaultValue={0}
                            className={classes.root}
                            value={inputs[item.var_name]}
                            classes={{ rail: classes.rail }}
                            // marks={marks}
                            onChange={(e) =>
                              handleChangeInputs(e, item.var_name)
                            }
                            valueLabelDisplay={
                              inputs[item.var_name] > 0 ? "on" : "off"
                            }
                            min={0}
                            max={10}
                          />
                          <span
                            className={`mt-1 ms-3 ${
                              inputs[item.var_name] == 10
                                ? "colored-last-value"
                                : ""
                            }`}
                          >
                            10
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                })}

                <div className="col-lg-6">
                  <div
                    className=" input-accountability-box-performance"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      paddingTop: "14px",
                      paddingBottom: "14px",
                    }}
                  >
                    <h3
                      style={{
                        textTransform: "capitalize",
                        fontWeight: "500",
                        color: "#f6bd4b",
                        fontSize: "15px",
                      }}
                      className="total-score-heading"
                    >
                      {SettingObject?.total_score_text + " " + ":"}
                    </h3>
                    <p
                      style={{
                        marginBottom: "7px",
                        fontSize: "16px",
                        marginBottom: "0px",
                      }}
                    >
                      <span>{total_scroe}</span>
                      <span>/</span>
                      <span
                        style={{
                          color: "#f6bd4b",
                        }}
                      >
                        50
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="mt-3 mb-3">
              <h2
                className="mt-3  mb-2"
                style={{
                  fontSize: "20px",
                  fontWeight: "600",

                  color: "white",
                }}
              >
                {SettingObject?.win_from_today_heading ??
                  "Share A win From Today"}
              </h2>
              <div className="mt-2">
                <TextareaAutosize
                  aria-label="empty textarea"
                  placeholder={
                    SettingObject?.win_from_today_placeholder ??
                    "Share A win From Today"
                  }
                  className="text-color textarea-autosize"
                  required
                  value={inputs.win_note}
                  onChange={(e) => handleChangeInputs(e, "win_note")}
                  minRows={4}
                  name="option"
                  style={{
                    width: "100%",
                    borderRadius: "11px",
                    background: "#1D2537",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    paddingLeft: "15px",
                    color: "white",
                    fontSize: "15px",
                    border: "2px solid rgba(71, 66, 34, 0.3)",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mt-2">
          <span className="float-end ">
            <button
              className="submit-button"
              onClick={() => (memberData ? handleUpdate() : handleSubmit())}
            >
              {memberData
                ? ButtonLoader
                  ? "Updating..."
                  : "Update"
                : ButtonLoader
                ? "Saving..."
                : "Save"}
            </button>
          </span>{" "}
          <span className="float-end me-2">
            <button className="submit-button" onClick={() => handleNavigate()}>
              {SettingObject?.past_activities_button_text_performance
                ? SettingObject?.past_activities_button_text_performance
                : "Past Activities"}
            </button>
          </span>
        </div>
      </div>
      {SettingObject?.streak_performance_days_reminder && (
        <GeneralPopUpModelWarning
          open={showPopup}
          setOpen={setShowPopup}
          title={"Warning"}
          componentToPassDown={
            <p>
              <div>
                <div
                  className="dashboard_description"
                  dangerouslySetInnerHTML={{
                    __html: SettingObject?.streak_performance_days_reminder,
                  }}
                ></div>
              </div>
            </p>
          }
        />
      )}
    </>
  );
};

export default PerformanceStreakcopy;
