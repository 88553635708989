import { invokeApi } from "../../bl_libs/invokeApi";

// ----------------------------------------------------
export const add_sale_team_member = async (postData) => {
  const requestObj = {
    path: `api/sales_team/add_team_member`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: postData,
  };
  return invokeApi(requestObj);
};
export const edit_sale_team_member = async (postData, id) => {
  const requestObj = {
    path: `api/sales_team/update_team_member/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: postData,
  };
  return invokeApi(requestObj);
};

export const team_list_api = async (page, limit, postData) => {
  const requestObj = {
    path: `api/sales_team/list_team_for_delegate?page=${page}&limit=${limit}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: postData,
  };
  return invokeApi(requestObj);
};
export const member_for_subteams = async (page, limit, search_text) => {
  const requestObj = {
    path: `api/event_subscriber/member_list_for_sub_team?page=${page}&limit=${limit}&search_text=${
      search_text ? search_text : ""
    }`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const team_list_transaction_api = async (page, limit, search) => {
  const requestObj = {
    path: `api/affiliate_transaction/get/for_delegate??page=${page}&limit=${limit}&search_text=${
      search ? search : ""
    }`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const team_list_selection_api = async (search) => {
  const requestObj = {
    path: `api/sales_team/list_team_for_delegate??search_text=${
      search ? search : ""
    }`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const team_list_selection_api_new = async (search) => {
  const requestObj = {
    path: `api/sales_team/list_team_for_delegate/commission?search_text=${
      search ? search : ""
    }`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const commissions_team_list_api = async (page, limit, postData) => {
  const requestObj = {
    path: `api/consultant/get_sales_team_list/with_comission??page=${page}&limit=${limit}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: postData,
  };
  return invokeApi(requestObj);
};

export const delete_team_api = async (id) => {
  const requestObj = {
    path: `api/sales_team/delete_team_member/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const commission_list_detail = async (_id, data, page, rowsPerPage) => {
  const requestObj = {
    path: `api/consultant/subteam_transaction_list/${_id}?page=${page}&limit=${rowsPerPage}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const add_affiliate_transaction = async (data) => {
  const requestObj = {
    path: `api/affiliate_transaction/`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
