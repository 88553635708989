import { useSnackbar } from "notistack";
import React, { useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";

export default function CopyLiveEmbedCode({ module_id }) {
  const { enqueueSnackbar } = useSnackbar();
  let encrypt_module_id = atob(module_id);

  const [embedCode, setEmbedCode] = useState(
    `<iframe src="http://localhost:1312/live-stream-embed/${encrypt_module_id}" style="width:100%;height:100%;" title="Dynamite Lifestyle Live Stream Video"></iframe>`
  );

  const handleCopyMessage = (message) => {
    enqueueSnackbar(message, { variant: "success" });
  };

  return (
    <div className="copy-live-embed-code">
      <h2>Live Stream Embed Code</h2>
      <hr />
      <textarea
        disabled
        onChange={(e) => setEmbedCode(e.target.value)}
        rows={3}
      >
        {embedCode}
      </textarea>
      <div className="text-end">
        <CopyToClipboard
          text={embedCode}
          onCopy={() => handleCopyMessage("Embed Code Copied to clipboard")}
        >
          <button className="small-contained-button">Copy Embed Code</button>
        </CopyToClipboard>
      </div>
    </div>
  );
}
