import React, { useEffect, useRef, useState } from "react";
import Hls from "hls.js";
import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import videoFile from "./video.mp4";
import { Tooltip } from "@mui/material";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";

export default function HlsVideoPlayer({
  hlsUrl,
  isFullScreen,
  setIsFullScreen,
  handleChangePage,
}) {
  const videoRef = useRef(null);
  const progressBarRef = useRef(null);
  const seekOnIconRef = useRef(null);
  const isLiveRef = useRef(true);
  const videoContainerRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLive, setIsLive] = useState(true);

  const handlePlayToggle = () => {
    const video = videoRef.current;
    const is_paused = video.paused;
    if (is_paused) {
      video
        .play()
        .then(() => {
          setIsPlaying(true);
        })
        .catch((error) => {
          console.error("Error attempting to play:", error);
        });
    } else {
      video.pause();
      setIsPlaying(false);
    }
  };

  const handleClickLive = () => {
    if (isLiveRef.current) return;
    handleGoLive();
  };

  const handleGoLive = () => {
    const video = videoRef.current;
    if (video && video.duration > 10) {
      video.currentTime = video.duration - 10;
    } else {
      video.currentTime = 0;
    }
    isLiveRef.current = true;
    setIsLive(true);
  };

  const handleProgessBar = (percentage) => {
    const seekOnIcon = seekOnIconRef.current;
    const progressBar = document.getElementById("progress-bar");
    if (isLiveRef.current) {
      seekOnIcon.style.left = "100%";
      progressBar.style.width = "100%";
    } else {
      seekOnIcon.style.left = `calc(${percentage}% - 5px)`;
      progressBar.style.width = `${percentage}%`;
    }
  };

  useEffect(() => {
    const video = videoRef.current;
    if (video && hlsUrl) {
      if (Hls.isSupported()) {
        const hls = new Hls();
        hls.loadSource(hlsUrl);
        hls.attachMedia(video);
        hls.on(Hls.Events.MANIFEST_PARSED, () => {
          video.play();
        });
      } else if (video.canPlayType("application/vnd.apple.mpegurl")) {
        video.src = hlsUrl;
        video.addEventListener("canplay", () => {
          video.play();
        });
      }

      const handleLoadedMetadata = () => {
        handleGoLive();
        video.muted = true;
        video
          .play()
          .then(() => {
            setIsPlaying(true);
          })
          .catch((error) => {
            console.error("Error attempting to play:", error);
          });
        video.addEventListener("error", function (event) {
          console.error("Error occurred while playing the video:", event);
        });
      };

      const handleTimeUpdate = () => {
        const currentTime = video.currentTime;
        const duration = video.duration;
        const percentage = duration ? (currentTime / duration) * 100 : 0;
        if (percentage >= 0 && percentage <= 100) {
          if (percentage >= 99) {
            isLiveRef.current = true;
            setIsLive(true);
          }
          handleProgessBar(percentage);
        }
      };

      video.addEventListener("loadedmetadata", handleLoadedMetadata);
      video.addEventListener("timeupdate", handleTimeUpdate);
      video.addEventListener("ended", handleGoLive);

      video.addEventListener("error", (event) => {
        console.error("Error occurred while playing the video:", event);
      });

      return () => {
        video.removeEventListener("loadedmetadata", handleLoadedMetadata);
        video.removeEventListener("timeupdate", handleTimeUpdate);
        video.removeEventListener("ended", handleGoLive);
      };
    }
  }, [videoRef, hlsUrl]);

  useEffect(() => {
    const handleFullscreenChange = () => {
      if (!document.fullscreenElement) {
        setIsFullScreen(false);
      }
    };
    document.addEventListener("fullscreenchange", handleFullscreenChange);
    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, [isFullScreen]);

  const updateVideoTime = (e) => {
    const rect = progressBarRef.current.getBoundingClientRect();
    const offsetX = e.clientX - rect.left;
    const percentage = (offsetX / progressBarRef.current.offsetWidth) * 100;
    if (percentage >= 0 && percentage <= 100) {
      const currentTime = (percentage / 100) * videoRef.current.duration;
      videoRef.current.currentTime = currentTime;
      if (percentage < 99) {
        isLiveRef.current = false;
        setIsLive(false);
      } else {
        isLiveRef.current = true;
        setIsLive(true);
      }

      handleProgessBar(percentage);
    }
  };

  const handleMouseDown = (e) => {
    const seekOnIcon = seekOnIconRef.current;
    updateVideoTime(e);
    seekOnIcon.classList.add("d-block");
    const handleMouseMove = (e) => {
      updateVideoTime(e);
    };

    const handleMouseUp = () => {
      seekOnIcon.classList.remove("d-block");
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };

    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);
  };

  const handleFullScreenToggle = () => {
    const videoContainer = videoContainerRef.current;
    if (!document.fullscreenElement) {
      videoContainer.requestFullscreen().catch((err) => {
        console.error(`Error attempting to enter full-screen mode: ${err}`);
      });
    } else {
      document.exitFullscreen();
    }
    setIsFullScreen(!isFullScreen);
  };

  return (
    <div id="video-wrapper" ref={videoContainerRef}>
      {handleChangePage && (
        <div className="exit-video-button">
          <Tooltip title="Go back">
            <div onClick={handleChangePage}>x</div>
          </Tooltip>
        </div>
      )}
      <div id="video-container">
        <video
          id="video"
          ref={videoRef}
          className="hls-video-player"
          preload="auto"
        ></video>
        <div id="player-controls">
          <div
            id="progress-bar-container"
            onMouseDown={handleMouseDown}
            onClick={updateVideoTime}
            ref={progressBarRef}
          >
            <div className="seek-on-icon-box">
              <div className="seek-on-icon" ref={seekOnIconRef}></div>
            </div>
            <div id="progress-bar"></div>
          </div>
          <div className="play-pause-box mt-1 px-2">
            <div className="d-flex">
              <Tooltip title={isPlaying ? "Pause" : "Play"}>
                <div className="play-pause" onClick={handlePlayToggle}>
                  {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
                </div>
              </Tooltip>
              <div
                className="d-flex align-items-center ms-2"
                onClick={handleClickLive}
              >
                <div
                  className={`playing-live-icon me-1 ${
                    isLive ? "playing-live" : ""
                  }`}
                ></div>
                <div className="cursor-pointer">LIVE</div>
              </div>
            </div>
            <div>
              <Tooltip
                title={isFullScreen ? "Exis Full Screen" : "Full Screen"}
              >
                <div className="full-screen" onClick={handleFullScreenToggle}>
                  {isFullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
                </div>
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
