import React from "react";
import { ListItemText, ListItemButton, Tooltip } from "@mui/material";

import {
  matchPath,
  useLocation,
  NavLink as RouterLink,
} from "react-router-dom";
import { useSnackbar } from "notistack";
import CopyToClipboard from "react-copy-to-clipboard";
import { useState } from "react";
import { useContentSetting } from "src/Hooks/ContentSettingState";
import ChangePassword from "src/layouts/dashboard/ChangePassword";

export default function NavSectionSettingItem({ data }) {
  const { pathname } = useLocation();
  const { userInfo } = useContentSetting();
  const { enqueueSnackbar } = useSnackbar();
  const [openPasswordModal, setopenPasswordModal] = useState(false);
  console.log(data, "datadata");
  const match = ({ path, matches }) => {
    if (matches) {
      let is_active = false;
      matches.forEach((match_path) => {
        const match = match_path
          ? !!matchPath({ path: match_path, end: false }, pathname)
          : false;

        if (match) {
          is_active = true;
        }
      });
      return is_active;
    }
    return path ? !!matchPath({ path, end: false }, pathname) : false;
  };
  const handleCopiedUrl = () => {
    enqueueSnackbar("ID copied to clipboard", { variant: "success" });
  };
  return (
    <>
      {data.password ? (
        <ListItemButton
          sx={{ pl: 5, fontSize: "14px" }}
          onClick={() => {
            setopenPasswordModal(true);
          }}
          className={
            match({ path: data.path, matches: data.matches })
              ? "menuActive menus-list"
              : "menus-list"
          }
        >
          {data.icon}
          <ListItemText
            primaryTypographyProps={{ fontSize: "14px" }}
            primary={data.title}
          />
        </ListItemButton>
      ) : (
        <ListItemButton
          component={RouterLink}
          to={data.path ? data.path : ""}
          sx={{ pl: 5, fontSize: "14px" }}
          className={
            match({ path: data.path, matches: data.matches })
              ? "menuActive menus-list"
              : "menus-list"
          }
        >
          {data.icon}
          <ListItemText
            primaryTypographyProps={{ fontSize: "14px" }}
            primary={data.title}
          />
        </ListItemButton>
      )}
      <ChangePassword
        openPasswordModal={openPasswordModal}
        setopenPasswordModal={setopenPasswordModal}
      />
    </>
  );
}
