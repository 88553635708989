import React from "react";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { TextField } from "@mui/material";
import moment from "moment";
import { useState } from "react";
import { useEffect } from "react";
import MUIAutocomplete from "../GeneralComponents/MUIAutocomplete";
import { get_member_filter_data_list_api } from "src/DAL/member/Member";
import { show_proper_words } from "src/utils/constants";

export const AccountabilityReportFilter = ({
  inputs,
  filterData,
  handleChangeOthers,
  setInputs,
  getDashboardDetail,
  searchFunction,
  tabValue,
  onClear,
  handleChangeStartDate,
}) => {
  const [delegateSearch, setDelegateSearch] = useState("");
  const [delegatesList, setDelegatesList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const getDataList = async () => {
    let result = await get_member_filter_data_list_api(
      searchText,
      delegateSearch,
      "nurture"
    );

    if (result.code == 200) {
      let delegates_list = result.delegates_list.map((delegate) => {
        let name = delegate.first_name + " " + delegate.last_name;
        return {
          ...delegate,
          name: name + " | " + show_proper_words(delegate.team_type),
          chip_label: name,
          chip_value: delegate._id,
        };
      });
      setDelegatesList(delegates_list);
    } else {
      enqueueSnackbar(result.message, { variant: "success" });
      // setIsLoading(false);
    }
  };
  const handleChangeDate = (name, value) => {
    setInputs((prevState) => {
      return {
        ...prevState,
        [name]: moment(value).format("YYYY-MM-DD"),
      };
    });
  };
  const handleFilter = () => {
    getDashboardDetail(inputs);
    // setInputs({
    //   start_date: null,
    //   end_date: null,
    // });
  };
  const handleClearFilter = () => {
    getDashboardDetail();
    setInputs({
      start_date: null,
      end_date: null,
    });
  };
  useEffect(() => {
    getDataList();
    // if (filterData.event_page) {
    //   set_plans_list(filterData.event_page.payment_plans);
    // }
  }, [searchText, delegateSearch]);

  return (
    <div className="container">
      <form onSubmit={searchFunction}>
        <div className="row">
          {tabValue == 3 ? (
            <div className="col-12 col-md-12 col-lg-12 mt-3 d-flex  ">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label={"Month and Year"}
                  name="startDate"
                  value={inputs.month}
                  views={["month", "year"]}
                  onChange={(e) => handleChangeStartDate(e, "month")}
                  maxDate={new Date()}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      // className="inputs-fields"
                      required={true}
                      fullWidth
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
          ) : (
            <>
              <div className="col-12 mt-3">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="From"
                    name="from_date"
                    format="YYYY-MM-DD"
                    inputFormat="dd-MM-yyyy"
                    value={inputs.from_date}
                    onChange={(e) => {
                      handleChangeDate("from_date", e);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="inputs-fields"
                        required={true}
                        fullWidth
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>
              <div className="col-12 mt-3">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="To"
                    name="startDate"
                    value={inputs.to_date}
                    format="YYYY-MM-DD"
                    inputFormat="dd-MM-yyyy"
                    onChange={(e) => {
                      handleChangeDate("to_date", e);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="inputs-fields"
                        required={true}
                        fullWidth
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>
            </>
          )}
          <div className="col-12 mt-3 text-end">
            <button className="small-contained-button me-2" onClick={onClear}>
              Clear Filter
            </button>
            <button className="small-contained-button">Submit</button>
          </div>
        </div>
      </form>
    </div>
  );
};
