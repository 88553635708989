import * as React from "react";
import Box from "@mui/material/Box";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useTheme } from "@mui/material/styles";
import FormHelperText from "@mui/material/FormHelperText";
//import Editor from "../../components/Editor/Editor";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import LoadingButton from "@mui/lab/LoadingButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import Chip from "@mui/material/Chip";
// import { VaultListing } from "src/DAL/Vault/Vault";
import Autocomplete from "@mui/material/Autocomplete";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import Stack from "@mui/material/Stack";
import moment from "moment";
import DeleteIcon from "@mui/icons-material/Delete";

import {
  useNavigate,
  useParams,
  Link as RouterLink,
  useLocation,
} from "react-router-dom";
import { IconButton, Divider, CircularProgress } from "@mui/material";
// import { AddProgram } from "src/DAL/Programmes/Programmes";
import { object } from "prop-types";
import { project_name } from "src/config/config";
import { replyAgainstSelfImageApi } from "src/DAL/SelfImageMember/SelfImageMember";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCkeditor";
// import { ProgramListing } from "src/DAL/Program/Programs";
// import { AddRecording } from "src/DAL/Recording/Recordings";
// import { get_root_value } from "src/utils/domUtils";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
  button: {
    backgroundColor: "black",
    color: "white",
    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
}));

const ITEM_HEIGHT = 70;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const Input = styled("input")({
  display: "none",
});

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const names = [
  "Oliver Hansen",
  "Van Henry",
  "April Tucker",
  "Ralph Hubbard",
  "Omar Alexander",
  "Carlos Abbott",
  "Miriam Wagner",
  "Bradley Wilkerson",
  "Virginia Andrews",
  "Kelly Snyder",
];

export default function AddKimsReply({ goalDetail }) {
  const classes = useStyles();
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [audioFile, setAudio] = React.useState();
  const [detailDescriptionCk, setDetailDescriptionCk] = React.useState({
    message: "",
  });
  //console.log(params, "params");

  const audioFileChange = (e) => {
    //console.log(e.target.files[0]);
    setAudio(e.target.files[0]);
  };

  const handldeDeleteAudio = () => {
    //console.log("deleteAudio");
    setAudio();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    //console.log("Kim reply");

    const formData = new FormData();
    formData.append("message", detailDescriptionCk.message);
    formData.append("created_for", "self_image");
    if (audioFile) {
      formData.append("audio_file", audioFile);
    }

    //console form data
    // console.log(...formData, "...formData");
    setIsLoading(true);
    const result = await replyAgainstSelfImageApi(formData);
    if (result.code === 200) {
      //console.log(result, "result");
      enqueueSnackbar(result.message, { variant: "success" });
      goalDetail();
      setIsLoading(false);
    } else {
      //console.log(result);
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    // getProgramListing();
    // getVaultList();
  }, []);

  return (
    <div className="row">
      <div className="col-12 mt-2">
        <h4>Reply</h4>
        <GeneralCkeditor
          setInputs={setDetailDescriptionCk}
          inputs={detailDescriptionCk}
          name="message"
          editorHeight={350}
        />
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
        <div className="row w-100 div-style ms-0 pt-0">
          <div className="col-5">
            <p className="">Upload Audio</p>
            <FormHelperText className="pt-0">
              Audio mp3 (max 200mb)
            </FormHelperText>
          </div>
          <div className="col-2">
            {audioFile && (
              <DeleteIcon
                onClick={handldeDeleteAudio}
                className="mt-3 icon-color"
              />
            )}
          </div>
          <div className="col-5 text-end pt-2">
            <label htmlFor="audio">
              <Input
                accept="audio/mp3,audio/*;capture=microphone"
                id="audio"
                multiple
                name="audio"
                type="file"
                onChange={audioFileChange}
              />

              <Button
                className="small-contained-button"
                startIcon={<FileUploadIcon />}
                component="span"
              >
                Upload
              </Button>
            </label>
          </div>
        </div>
        <p className="text-secondary">{audioFile && audioFile.name}</p>
      </div>

      <div className="text-end mt-4">
        <button onClick={handleSubmit} className="small-contained-button">
          {isLoading ? "Submitting..." : "Submit"}
        </button>
      </div>
    </div>
  );
}
