import { Avatar, CircularProgress, Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { lead_stages_list_api } from "src/DAL/LeadsCenter/lead_stages";
import { s3baseUrl } from "src/config/config";
import HistoryIcon from "@mui/icons-material/History";
import RecordNotFound from "src/components/GeneralComponents/RecordNotFound";
import MoreVertIcon from "@mui/icons-material/MoreVert";

export default function LeadCenterMembers(props) {
  const {
    membersData,
    handleClickDots,
    filterData,
    setMembersData,
    allLeadStatus,
    handleOpenHistory,
    pageNumber,
    totalPages,
    setPageNumber,
    setTotalPages,
    setTotalCount,
    totalCount,
    rowsPerPage,
    handle_update_data,
    handle_view_profile,
    onScroll,
    openUserMenu,
  } = props;

  const [search, setSearch] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const observer = useRef();

  const lastBookElementRef = useCallback(
    (node) => {
      if (isLoadingMore) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && totalPages > pageNumber) {
          loadMoreData();
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoadingMore, totalPages, pageNumber, observer]
  );

  const handleChangeSearch = async (e) => {
    setIsLoading(true);
    const { value } = e.target;
    setSearch(value);
    let formData = {
      ...filterData,
      search_text: value,
      page: 0,
      limit: rowsPerPage,
    };
    handle_get_users(formData, "search");
  };

  const handle_get_users = async (formData, type) => {
    formData = handle_update_data(formData);
    formData.list_type = "all_users";
    const result = await lead_stages_list_api(formData);
    if (result.code === 200) {
      setTotalPages(result.total_pages);
      setTotalCount(result.total_member_count);
      if (type === "search") {
        setPageNumber(1);
        setMembersData(result.users);
        setIsLoading(false);
        return;
      }
      setPageNumber((prev) => prev + 1);
      setMembersData((old) => [...old, ...result.users]);
      setIsLoadingMore(false);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingMore(false);
      setIsLoading(false);
    }
  };

  const loadMoreData = () => {
    setIsLoadingMore(true);
    let postData = { ...filterData };
    postData.page = pageNumber;
    postData.limit = rowsPerPage;
    handle_get_users(postData);
  };

  useEffect(() => {
    let applied_filter = localStorage.getItem("leads_centre_searches");
    if (applied_filter) {
      applied_filter = JSON.parse(applied_filter);
      if (applied_filter && applied_filter != "null" && applied_filter?.users) {
        let event = { target: { value: applied_filter.users } };
        handleChangeSearch(event);
      }
    }
  }, []);

  useEffect(() => {
    let applied_filter = localStorage.getItem("leads_centre_searches");
    let filter_data = {};
    if (applied_filter && applied_filter != "null") {
      applied_filter = JSON.parse(applied_filter);
      filter_data = applied_filter;
    }
    filter_data.users = search;
    localStorage.setItem("leads_centre_searches", JSON.stringify(filter_data));
  }, [search]);

  return (
    <>
      <div className="main-member-container ms-2">
        <div className="heading-container mt-2">
          <h4>Members</h4>
          <p>{totalCount}</p>
        </div>
        <div className="w-100 mb-2">
          <input
            type="text"
            className="leads-search-input"
            placeholder="Search user"
            value={search}
            onChange={handleChangeSearch}
          />
        </div>
        <Droppable droppableId="users">
          {(provided) => (
            <div
              style={{
                height: "calc(85vh - 120px)",
                overflowY: openUserMenu ? "hidden" : "auto",
              }}
              ref={provided.innerRef}
              {...provided.droppableProps}
              // onScroll={(e) => onScroll(e, "top")}
              className="lead-center-users-scroll"
            >
              {isLoading ? (
                <div className="text-center">
                  <CircularProgress color="primary" className="mt-5" />
                </div>
              ) : membersData.length > 0 ? (
                membersData.map((item, index) => {
                  let find_lead_status = allLeadStatus.find(
                    (status) => status._id === item.lead_status
                  );

                  return (
                    <Draggable
                      key={`key${item._id}`}
                      draggableId={`user-${item._id}`}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          className="member-card-container mb-2"
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <div className="d-flex w-100 align-items-center ">
                            <div>
                              <Avatar
                                className="avatar-adjust-new"
                                sx={{ width: 35, height: 35 }}
                                alt={item.first_name[0]}
                                src={s3baseUrl + item.profile_image}
                              />
                            </div>
                            <div className="w-100 overflow-hidden">
                              <div className="w-100 name-container">
                                <div className="d-flex justify-content-between ">
                                  <p
                                    className="mb-0 cursor-pointer"
                                    style={{ fontSize: "15px" }}
                                    onClick={() => handle_view_profile(item)}
                                  >
                                    {item.first_name + " " + item.last_name}
                                  </p>
                                  <MoreVertIcon
                                    style={{
                                      cursor: "pointer",
                                      fontSize: 20,
                                      color: "#637381",
                                    }}
                                    className="pointer custom-popover-icon"
                                    onClick={(e) =>
                                      handleClickDots(e, {
                                        ...item,
                                        stage: "users",
                                      })
                                    }
                                  />
                                </div>
                                {find_lead_status && (
                                  <span
                                    className="lead-status-text"
                                    style={{
                                      backgroundColor:
                                        find_lead_status.background_color,
                                      color: find_lead_status.text_color,
                                    }}
                                  >
                                    {find_lead_status.title}
                                  </span>
                                )}
                                {item.lead_status_history?.length > 0 && (
                                  <span
                                    className="ms-2"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => handleOpenHistory(item)}
                                  >
                                    <Tooltip title="View History">
                                      <HistoryIcon />
                                    </Tooltip>
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  );
                })
              ) : (
                <div
                  className="h-100 d-flex"
                  style={{ alignItems: "center", width: "280px" }}
                >
                  <RecordNotFound />
                </div>
              )}
              {totalPages > pageNumber && !isLoading && (
                <div className="col-12 text-center mt-3">
                  <span ref={lastBookElementRef} onClick={loadMoreData}>
                    {isLoadingMore ? "Loading..." : "Load More"}
                  </span>
                </div>
              )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
    </>
  );
}
