import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import {
  delete_feed_action,
  get_feeds_detail,
  community_feed_action_api,
} from "src/DAL/Community/Community";
import { projectMode, s3baseUrl } from "src/config/config";
import { htmlDecode, urlify } from "src/utils/convertHtml";
import ShowAllLikes from "./ShowAllLikes";
import {
  count_chars,
  FEED_TXET_LIMIT,
  getImages,
  post_description,
  replaceUserNamesWithHTML,
  string_avatar,
  TIME_ZONE_CONVERSION,
} from "src/utils/constants";
import { FeedComments, VideoStream } from "./components";
import CreatePost from "./CreatePost";
import { useContentSetting } from "src/Hooks/ContentSettingState";
import CustomPopover from "src/components/CustomPopover";
import CustomConfirmation from "src/components/menuIcons/CustomConfirmation";
import FeedImageSlider from "src/components/GoalStatement/FeedImageSlider";
import UserInfoCard from "./components/LikesPopup/UserInfoCard";
import EventInfoForPost from "./components/LikesPopup/EventInfoForPost";
import ActionsCount from "./components/LikesPopup/ActionsCount";
import HandleAction from "./components/LikesPopup/HandleAction";
import ReactVideoPlayer from "src/components/ReactPlayers/ReactVideoPlayer";
import { useLocation } from "react-router-dom";
import { Tooltip } from "@mui/material";
import UpdateIcon from "@mui/icons-material/Update";
import PollFeed from "./components/FeedPoll/PollFeed";
import FeedPollResult from "./components/FeedPoll/FeedPollResult";
import GeneralModelBox from "src/components/GeneralComponents/GeneralModelBox";
import VideoStreaming from "./components/Video-Stream/VideoStreaming";
import { isFileExistsOnS3 } from "src/utils/file_utils";

export default function FeedDetailPopup({
  handleCloseDetailBox,
  post_id,
  handleINternalNoteClient,
  feed,
  getFeedsListingOnAddFeed,
  handleSuccessDelete,
  handleINternalNote,
  handleSuccessUpdate,
  event_id,
  openDetailModal,
  selectedImageIndex,
  list_type,
  is_detail_page,
  handleUpdatePoll,
}) {
  const settings = useContentSetting();
  const { socket, adminTimeZone } = settings;
  const { pathname } = useLocation();
  const [showTab, setShowTab] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const [feedDetail, setFeedDetail] = useState(feed);
  const [isLiked, setIsLiked] = useState(feed.is_liked);
  const [topLikedUser, setTopLikedUser] = useState(feed.top_liked_user);
  const [feedType, setFeedType] = useState(feed.feed_type);
  const [likeCount, setLikeCount] = useState(feed.like_count);
  const [formAction, setFormAction] = useState("EDIT");
  const [openLikesModal, setOpenLikesModal] = useState(false);
  const [feedId, setFeedId] = useState("");
  const [isShowMore, setIsShowMore] = useState(true);
  const [openDelete, setOpenDelete] = useState(false);
  const [commentCountUpdated, setCommentCountUpdated] = useState(feed);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [feedSetting, setFeedSetting] = useState({});
  const [showGlow, setShowGlow] = useState(true);
  const live_event = window.location.pathname.includes("/live-events/");
  const my_event = window.location.pathname.includes("/my-events/");
  const [openPoll, setOpenPoll] = useState(false);
  const [liveStream, setLiveStream] = useState({});
  const [isFileExist, setIsFileExist] = useState(false);

  const getFeedsDeteil = async () => {
    const result = await get_feeds_detail(post_id);
    if (result.code === 200) {
      setFeedDetail(result.feeds);
      setCommentCountUpdated(result.feeds);
      setFeedSetting(result.feed_settings);
      setLiveStream(result.live_stream);
      setIsLiked(result.feeds.is_liked);
      setTopLikedUser(result.feeds.top_liked_user);
      setLikeCount(result.feeds.like_count);
      if (result.feeds.stream_recording_url) {
        const is_file = await isFileExistsOnS3(
          result.feeds.stream_recording_url
        );
        setIsFileExist(is_file);
      }
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleOpenLikedBox = () => {
    setOpenLikesModal(true);
    setShowTab(1);
  };

  const handleCloseSimpleBox = () => {
    setOpenLikesModal(false);
    setShowTab();
  };
  const handleClosePoll = () => {
    setOpenPoll(false);
  };
  const handleFeedAction = async (id, action) => {
    if (action === "feedlike") {
      setIsLiked(true);
    } else if (action === "feedunlike") {
      setIsLiked(false);
    }
    const formData = new FormData();
    formData.append("action", action);
    formData.append("feed", id);
    const result = await community_feed_action_api(formData);
    if (result.code === 200) {
      if (action === "feedlike" || action === "feedunlike") {
        setTopLikedUser(result.action_response.top_liked_user);
        setLikeCount(result.action_response.like_count);
      }
      const socketData = {
        action,
        feed_id: id,
        token: localStorage.getItem("token"),
        creator_id: result.action_response.creator_id,
        action_by: result.action_response.sender,
        action_response: result.action_response,
      };

      if (list_type == "the_cosmos") {
        socket.emit("delegate_feed_room_action_event", socketData);
      } else {
        socket.emit("feed_room_action_event", socketData);
      }
    } else {
      if (action === "feedlike") {
        setIsLiked(false);
      } else if (action === "feedunlike") {
        setIsLiked(true);
      }
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleFeedEdit = () => {
    setFormAction("EDIT");
    setOpenCreateModal(true);
  };

  const handleAgreeDelete = (value) => {
    setFeedId(value._id);
    setOpenDelete(true);
  };

  const handleAgree = async () => {
    setOpenDelete(false);
    const result = await delete_feed_action(feedId);
    if (result.code === 200) {
      enqueueSnackbar("Post deleted successfully", { variant: "success" });
      setOpenDelete(false);

      handleSuccessDelete(feed);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const get_start_end_time = (date) => {
    return TIME_ZONE_CONVERSION(
      date,
      "DD-MM-YYYY hh:mm A",
      adminTimeZone,
      adminTimeZone
    );
  };

  const MENU_OPTIONS = [
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];
  if (feedDetail.feed_type === "poll") {
    if (feedDetail.poll_info.poll_status !== "expired") {
      MENU_OPTIONS.push({
        label: "Edit",
        icon: "akar-icons:edit",
        handleClick: handleFeedEdit,
      });
    }
  } else {
    MENU_OPTIONS.push({
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleFeedEdit,
    });
  }
  if (live_event || my_event) {
    MENU_OPTIONS.push({
      label: "Add As Personel Notes",
      icon: "akar-icons:edit",
      handleClick: handleINternalNote,
    });
  }
  const handleUpdateFeed = (feed) => {
    setFeedDetail(feed);
    if (handleUpdatePoll) {
      handleUpdatePoll(feed);
    }
  };
  const handlePollDetail = (feed) => {
    setOpenPoll(true);
  };
  const handleMenuClient = (row) => {
    let MENU_OPTIONS_CLIENT = [];
    if (live_event || my_event) {
      MENU_OPTIONS_CLIENT.push({
        label: "Add As Personel Notes",
        icon: "akar-icons:edit",
        handleClick:
          row.action_info?.action_by == "member_user"
            ? handleINternalNoteClient
            : handleINternalNote,
      });
    }
    return MENU_OPTIONS_CLIENT;
  };
  const handle_poll_answered = (data) => {
    setFeedDetail((old) => {
      let poll_info = { ...old.poll_info };
      let selected_options = [];
      if (old.selected_options?.length > 0) {
        selected_options = [...old.selected_options];
      }
      if (old._id == data.feed_obj._id) {
        poll_info = data.feed_obj.poll_info;
        if (data.action_by._id === settings.userInfo._id) {
          selected_options = data.feed_obj.selected_options;
        }
      }
      return { ...old, poll_info, selected_options };
    });
  };

  const handle_poll_expired = (data) => {
    let find_feed = data.feeds.find(
      (item) => item.feed_id === selectedFeed._id
    );
    if (find_feed) {
      setFeedDetail((old) => {
        let poll_info = old.poll_info;
        poll_info.poll_status = "expired";
        return { ...old, poll_info };
      });
    }
  };
  useEffect(() => {
    setShowGlow(true);
    setTimeout(() => {
      setShowGlow(false); // Hide the image after 3 seconds
    }, 3000);
    getFeedsDeteil();
  }, [post_id]);

  useEffect(() => {
    if (openDetailModal) {
      let path = `${pathname}/feed-detail/${post_id}`;
      window.history.replaceState(null, null, path);

      // for close on escape
      window.onkeyup = function (event) {
        if (event.keyCode == 27) {
          handleCloseDetailBox();
        }
      };

      return () => {
        window.history.replaceState(null, null, pathname);
      };
    }
  }, []);

  const update_feed_likes_by_socket = (data) => {
    setLikeCount(data.action_response.like_count);
    setTopLikedUser(data.action_response.top_liked_user);
    if (settings.userInfo._id == data.action_by) {
      setIsLiked(data.action_response.is_liked);
    }
  };

  useEffect(() => {
    socket.emit("delegate_feed_room", "delegate_live_feed_room");
    socket.emit("live_event_room", "live_feed_room");

    socket.on("live_feed_room_reciever", (data) => {
      if (data.action === "feedlike" || data.action === "feedunlike") {
        update_feed_likes_by_socket(data);
      } else if (data.action === "add_comment") {
        setFeedDetail((old) => {
          return {
            ...old,
            comment_count: old.comment_count + 1,
          };
        });
      } else if (
        data.action === "delete_comment_reply" ||
        data.action === "delete_comment"
      ) {
        getFeedsDeteil();
      } else if (data.action === "poll_answered") {
        handle_poll_answered(data);
      } else if (data.action === "poll_expired") {
        handle_poll_expired(data);
      }
    });
    socket.on("delegate_live_feed_room_reciever", (data) => {
      if (data.action === "feedlike" || data.action === "feedunlike") {
        update_feed_likes_by_socket(data);
      } else if (data.action === "add_comment") {
        setFeedDetail((old) => {
          return {
            ...old,
            comment_count: old.comment_count + 1,
          };
        });
      } else if (
        data.action === "delete_comment_reply" ||
        data.action === "delete_comment"
      ) {
      }
    });

    return () => {
      socket.off("delegate_live_feed_room_reciever");
      socket.off("live_feed_room_reciever");
    };
  }, [openDetailModal, post_id]);

  useEffect(() => {
    socket.on("end_stream_event_listener", (payload) => {
      if (payload.module_id === post_id) {
        enqueueSnackbar("Stream ended.", { variant: "info" });
        handleCloseDetailBox();
      }
    });

    return () => {
      socket.off("end_stream_event_listener");
    };
  }, [post_id]);

  const feedData = {
    feedDetail,
    feedSetting,
    topLikedUser,
    likeCount,
    isLiked,
    handleFeedAction,
    handleOpenLikedBox,
    is_detail_page,
    handleCloseDetailBox,
    liveStream,
    setLiveStream,
  };

  return (
    <>
      <div>
        {feedDetail?.is_live_streaming ? (
          <VideoStreaming {...feedData} />
        ) : (
          <div className="row post-detail-box">
            {feedDetail.is_reward_feed &&
              showGlow &&
              feedDetail.reward_data?.reward_feed_gif && (
                <div className="gif-image">
                  <img
                    src={s3baseUrl + feedDetail.reward_data?.reward_feed_gif}
                  />
                </div>
              )}
            {feedDetail?.feed_type !== "general" &&
              (feedDetail.feed_images?.length > 0 ||
                feedDetail.video_url ||
                feedDetail.image?.thumbnail_1 ||
                feedDetail?.is_live_streaming ||
                feedDetail.embed_code) && (
                <div className="col-12 col-md-8">
                  {feedDetail.feed_type === "image" &&
                    feedDetail.feed_images &&
                    feedDetail.feed_images.length > 0 && (
                      <div className="m-2 m-md-5">
                        <FeedImageSlider
                          data={getImages(feedDetail.feed_images)}
                          selectedIndex={selectedImageIndex}
                        />
                      </div>
                    )}
                  {feedDetail.feed_type === "embed_code" && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: feedDetail.embed_code,
                      }}
                      className="feed-image m-2 m-md-5"
                    ></div>
                  )}
                  {feedDetail?.feed_type === "video" && feedDetail?.video_url && (
                    <div className="feed-image m-2 m-md-5 position-relative">
                      <ReactVideoPlayer
                        url={feedDetail.video_url}
                        thumbnail={s3baseUrl + feedDetail.image?.thumbnail_1}
                        playingVideo={true}
                      />
                    </div>
                  )}
                  {feedDetail?.feed_type === "meeting" && (
                    <div className="feed-image m-2 m-md-5 position-relative">
                      {feedDetail.stream_recording_url && isFileExist ? (
                        <ReactVideoPlayer
                          url={s3baseUrl + feedDetail.stream_recording_url}
                          thumbnail={s3baseUrl + feedDetail.image?.thumbnail_1}
                          playingVideo={true}
                        />
                      ) : (
                        feedDetail.image?.thumbnail_1 && (
                          <>
                            {!feedDetail.is_live_streaming && (
                              <div className="stream-video-available">
                                <div>
                                  <h2>Meeting has been ended</h2>
                                  <h2>Video will be available shortly</h2>
                                </div>
                              </div>
                            )}
                            <img
                              src={s3baseUrl + feedDetail.image.thumbnail_1}
                            />
                          </>
                        )
                      )}
                    </div>
                  )}

                  {feedDetail?.feed_type === "live" &&
                    (projectMode === "DEV" ? (
                      <div className="post-detail-image">
                        <div className="feed-image m-5 position-relative">
                          {feedDetail.stream_recording_url && isFileExist ? (
                            <ReactVideoPlayer
                              url={s3baseUrl + feedDetail.stream_recording_url}
                              thumbnail={
                                s3baseUrl + feedDetail.image?.thumbnail_1
                              }
                              playingVideo={true}
                            />
                          ) : (
                            feedDetail.image?.thumbnail_1 && (
                              <>
                                {!feedDetail.is_live_streaming && (
                                  <div className="stream-video-available">
                                    <div>
                                      <h2>Stream has been ended</h2>
                                      <h2>Video will be available shortly</h2>
                                    </div>
                                  </div>
                                )}
                                <img
                                  src={s3baseUrl + feedDetail.image.thumbnail_1}
                                />
                              </>
                            )
                          )}
                        </div>
                      </div>
                    ) : (
                      feedDetail?.embed_code && (
                        <div className="post-detail-image">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: feedDetail?.embed_code,
                            }}
                            className="feed-image m-5"
                          ></div>
                        </div>
                      )
                    ))}
                </div>
              )}
            <div
              className={`col-12 mt-5 mt-md-0 mx-auto profile-cards p-3 mb-3 main-section ${
                feedDetail?.feed_type !== "general" &&
                (feedDetail.feed_images?.length > 0 ||
                  feedDetail.video_url ||
                  feedDetail.image?.thumbnail_1 ||
                  feedDetail?.is_live_streaming ||
                  feedDetail.embed_code)
                  ? "col-md-4"
                  : "col-md-6"
              }`}
            >
              <div className="d-flex w-100 justify-content-between">
                <UserInfoCard
                  profile_image={
                    s3baseUrl + feedDetail.action_info?.profile_image
                  }
                  user_name={htmlDecode(feedDetail.action_info?.name)}
                  avatar_char={string_avatar(feedDetail.action_info?.name)}
                  date={feedDetail?.createdAt}
                  activity_type={null}
                />
                <div className="d-flex">
                  {(!feedDetail.is_publish ||
                    feedDetail.is_publish == "false") && (
                    <Tooltip
                      title={`post will be published on ${get_start_end_time(
                        feedDetail.schedule_date_time
                      )} (Europe/Dublin)`}
                    >
                      <UpdateIcon />
                    </Tooltip>
                  )}
                  {feedDetail?.is_self ? (
                    <div className="poster-logo text-end">
                      <CustomPopover menu={MENU_OPTIONS} data={feedDetail} />
                    </div>
                  ) : live_event || my_event ? (
                    <CustomPopover
                      menu={handleMenuClient(feedDetail)}
                      data={feedDetail}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className="mt-2 post-description">
                <p
                  dangerouslySetInnerHTML={{
                    __html: urlify(
                      count_chars(feedDetail.description) > FEED_TXET_LIMIT &&
                        isShowMore
                        ? post_description(
                            replaceUserNamesWithHTML(
                              feedDetail.description,
                              feedDetail.mentioned_users
                            ),
                            FEED_TXET_LIMIT
                          )
                        : replaceUserNamesWithHTML(
                            feedDetail.description,
                            feedDetail.mentioned_users
                          )
                    ),
                  }}
                ></p>
                {count_chars(feedDetail.description) > FEED_TXET_LIMIT && (
                  <span
                    className="ms-2"
                    onClick={() => {
                      setIsShowMore(!isShowMore);
                    }}
                  >
                    {isShowMore ? "See More" : "See Less"}
                  </span>
                )}
              </div>
              {feedDetail.feed_type === "poll" && feedDetail.poll_info && (
                <PollFeed
                  feed={feedDetail}
                  handleUpdateFeed={handleUpdateFeed}
                  handlePollDetail={handlePollDetail}
                  // feedSetting={feedSetting}
                />
              )}
              {feedDetail.event_info?.is_event_info && (
                <EventInfoForPost feed={feedDetail} />
              )}

              {feedDetail.is_publish && (
                <>
                  <ActionsCount
                    handleOpenLikedBox={handleOpenLikedBox}
                    likeCount={likeCount}
                    topLikedUser={topLikedUser}
                    commentsCount={feedDetail.comment_count}
                    commentCountUpdated={commentCountUpdated.comment_count}
                  />
                  <hr className="mt-0" />
                  <HandleAction
                    isLiked={isLiked}
                    handleFeedAction={handleFeedAction}
                    feed_id={feedDetail._id}
                  />
                  <hr />
                </>
              )}

              <FeedComments
                is_popup={openDetailModal}
                feed_id={post_id}
                getFeedsListingOnAddFeed={getFeedsListingOnAddFeed}
                handleCloseDetailBox={handleCloseDetailBox}
                list_type={list_type}
                is_detail_page={is_detail_page}
                getFeedsDeteil={getFeedsDeteil}
                feed={feedDetail}
                event_id={event_id}
              />
            </div>
          </div>
        )}
      </div>
      {openLikesModal && (
        <ShowAllLikes
          openLikesModal={openLikesModal}
          setOpenLikesModal={setOpenLikesModal}
          handleCloseSimpleBox={handleCloseSimpleBox}
          showTab={showTab}
          post_id={post_id}
          handleCloseDetailBox={handleCloseDetailBox}
        />
      )}
      {setOpenCreateModal && (
        <CreatePost
          setFeedType={setFeedType}
          feedType={feedType}
          openCreateModal={openCreateModal}
          setOpenCreateModal={setOpenCreateModal}
          getFeedsListing={getFeedsListingOnAddFeed}
          userInfo={settings.userInfo}
          selectedFeed={feed}
          formAction={formAction}
          setFormAction={setFormAction}
          handleSuccessUpdate={handleSuccessUpdate}
          event_id={event_id}
          list_type={list_type}
        />
      )}

      <GeneralModelBox
        open={openPoll}
        setOpen={setOpenPoll}
        className="feed-poll-popup-modal"
        componentToPassDown={
          <FeedPollResult
            selectedFeed={feed}
            handleClosePoll={handleClosePoll}
          />
        }
      />
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this post?"}
        handleAgree={handleAgree}
      />
    </>
  );
}
