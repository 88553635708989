import React, { useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { invokeApi } from "src/bl_libs/invokeApi";
import { s3baseUrl } from "src/config/config";
import { useEffect } from "react";
import { useState } from "react";
import { useContentSetting } from "src/Hooks/ContentSettingState";

export default function GeneralCkeditor({
  inputs,
  setInputs,
  name,
  editorHeight,
  is_array,
  is_direct,
  index,
  value,
}) {
  const editorRef = useRef(null);
  const { tinymceKey } = useContentSetting();
  const [focuse, setfocuse] = useState(false);
  const log = () => {
    if (editorRef.current) {
      if (is_array) {
        const list = [...inputs];
        list[index][name] = editorRef.current.getContent();
        setInputs(list);
      } else if (inputs) {
        setInputs((prevState) => ({
          ...prevState,
          [name]: editorRef.current.getContent(),
        }));
      } else {
        setInputs(editorRef.current.getContent());
      }
    }
  };
  function example_image_upload_handler(blobInfo, success, failure, progress) {
    let requestObj = {
      path: "app/update_image_on_s3/",
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };

    let _formData = new FormData();
    _formData.append("image", blobInfo.blob());
    _formData.append("width", "600");
    requestObj["postData"] = _formData;

    invokeApi(requestObj).then((res) => {
      if (res.code === 200) {
        success(s3baseUrl + res.image_path);
      } else {
        failure(res.message);
      }
    });
  }

  const editor_value = () => {
    let value_data = "";
    if (is_array && inputs[index][name]) {
      value_data = inputs[index][name];
    } else if (is_direct) {
      value_data = value;
    } else if (inputs[name]) {
      value_data = inputs[name];
    }
    return value_data;
  };

  function handleEditorInit() {
    console.log("Editor has been completely rendered and initialized.");
    setTimeout(() => {
      // let container = document.getElementsByClassName(
      //   "tox-notifications-container"
      // );
      let containerWarning = document.getElementsByClassName(
        "tox-notification--warning"
      );
      if (containerWarning.length > 0) {
        containerWarning[0].style.display = "none";
        setfocuse(false);
      }
      setfocuse(false);
    }, 100);
  }
  useEffect(() => {
    handleEditorInit();
  }, [focuse]);

  return (
    <>
      <Editor
        // apiKey="t2nyzbnize7wumzwxwp186a3uz9h5wsjbsgo8xnzaffif8rd"
        apiKey={tinymceKey}
        onChange={log}
        value={editor_value()}
        onInit={(evt, editor) => (
          (editorRef.current = editor), handleEditorInit(editor)
        )}
        onEditorChange={(newValue, editor) => {
          log(newValue, editor);
          setfocuse(true);
        }}
        init={{
          setup: function (editor) {
            editor.on("focus", function (e) {
              console.log("TinyMCE Editor Focused");
              setfocuse(true);
            });
          },
          images_upload_handler: example_image_upload_handler,
          height: editorHeight ? editorHeight : 500,
          menubar: true,
          plugins: [
            "advlist autolink lists link image charmap print preview anchor",
            "searchreplace visualblocks code fullscreen",
            "insertdatetime media table paste code help wordcount",
          ],
          toolbar:
            "undo redo | formatselect | " +
            "bold italic backcolor | alignleft aligncenter " +
            "alignright alignjustify | bullist numlist outdent indent | " +
            "removeformat | help",
          content_style:
            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
        }}
      />
    </>
  );
}
