import React, { useState, useEffect } from "react";
import Slider from "@mui/material/Slider";
import EarningList from "../../components/message/EarningList";
import CustomDrawer from "src/components/GeneralComponents/CustomDrawer";
import AddNewEarning from "./AddNewEarning";
import { Card, Divider } from "@mui/material";
import {
  AddNineteenDay,
  EarningListApi,
} from "src/DAL/90Day/NinetyDay";
import { get_root_value } from "src/utils/domUtils";
import moment from "moment";
import NumberFormat from "react-number-format";
import ChartJs from "./ChartJs";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import { convertCurrencyToSign } from "src/utils/constants";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import TextField from "@mui/material/TextField";
import { InputAdornment } from "@mui/material";
import { useSnackbar } from "notistack";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import PageDescription from "src/components/GeneralComponents/PageDescription";
import { useContentSetting } from "src/Hooks/ContentSettingState";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const marks = [
  {
    value: 1,
    label: "Day 1",
  },

  {
    value: 90,
    label: "Day 90",
  },
];

const theme = createTheme({});
const NintyDayTracker = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [drawerState, setDrawerState] = useState(false);
  const [member, setMember] = useState([]);
  const [TargetAmount, setTargetAmount] = useState([
  ]);
  const [totalAmount, setTotalAmount] = useState("");
  const [earningList, setEarningList] = useState([]);
  const [MemberearningList, setMemberearningList] = useState([]);
  const [lastEarningDate, setLastEarningdate] = useState("");
  const [lastEarningamount, setLastEarningAmount] = useState("");
  const [TargetToAchieve, setTargetToAchieve] = useState("");
  const [days, setDays] = useState([]);
  const [sliderDay, setSliderDay] = useState(1);
  const [chartDay, setChartDay] = useState([]);
  const [chartDynamicData, setChartDynamicData] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [amount, setAmount] = useState("");
  const [date, setDate] = useState(new Date());
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { userInfo } = useContentSetting();

  const getEarningList = async () => {
    const result = await EarningListApi();
    if (result.code == 200) {
      if (
        result.ninteen_day_vision_start_date !== "" &&
        result.start_date !== "Invalid date"
      ) {
        setDate(result.ninteen_day_vision_start_date);
      }

      setAmount(result.target_amount);

      let LastDateObject = "";
      if (result.DelegateEarning.length > 0) {
        LastDateObject = result.DelegateEarning[0].date;
      }
      setIsLoading(false);
      const target_amount_achieved = result.target_amount;
      const earningList = result.DelegateEarning;

      if (earningList.length === 0) {
        setSliderDay(1);
      }

      setTotalAmount(result.total_earning);

      const lastEarning = earningList[0];
      setTargetToAchieve(result.target_amount);
      setTargetAmount(() => [
        {
          value: 0,
          label: `£0`,
        },
        {
          value: TargetToAchieve,
          label: `£ ${TargetToAchieve}`,
        },
      ]);

      const lastDateEarning = moment(lastEarning?.date).format("MM/DD/YYYY");
      setMemberearningList(result.DelegateEarning);
      const start_date = moment(result.ninteen_day_vision_start_date).format(
        "MM/DD/YYYY"
      );
      const array_of_earning = [];
      const ChartPoints = [];
      const array_of_days = [];
      const reverseEarning = [];
      const demoXYValues = [];
      let counting = 0;
      if (LastDateObject > startDate) {
        earningList.reverse().forEach((earning) => {
          reverseEarning.push(earning.earning); // needs to reverse
          const date = moment(earning.date).format("MM/DD/YYYY");
          counting = counting + earning.earning;
          const dateCheck = dateDifference(start_date, date);

          // array_of_earning.push(earning.earning);
          array_of_days.push(dateDifference(start_date, date));
          array_of_earning.push(counting);
          ChartPoints.push(dateDifference(start_date, date));
          demoXYValues.push({
            x: dateCheck < 0 ? 1 : dateCheck + 1,
            y: counting,
          });
        });
      } else {
      }

      let days = [];
      for (let index = 1; index <= 90; index++) {
        days.push(index);
      }
      setChartDay(ChartPoints);

      const target_earning = [];
      target_earning.push({
        value: target_amount_achieved,
        label: `£ ${target_amount_achieved.toLocaleString()}`,
      });
      setChartDynamicData(demoXYValues);
      setEarningList(array_of_earning);
      setDays(days);
      setTargetAmount(target_earning);
      setLastEarningAmount(lastEarning?.earning); //last earning value
      setLastEarningdate(lastDateEarning);
      setStartDate(
        moment(result.ninteen_day_vision_start_date).format("MM/DD/YYYY")
      );
    }
  };

  const addDays = (date) => {
    var result = new Date(date);
    result.setDate(result.getDate() + 89);
    return moment(result).format("DD MMM YYYY");
  };

  const handleSubmitTarget = async (e) => {
    e.preventDefault();
    let postData = {
      nineteen_day_vision: {
        tracker_target_amount: amount,
        tracker_start_date: moment(date).format("YYYY-MM-DD"),
      },
    };
    setLoading(true);
    const result = await AddNineteenDay(postData);
    if (result.code == 200) {
      getEarningList();
      enqueueSnackbar(result.message, { variant: "success" });
      setLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setLoading(false);
    }
  };

  const dateDifference = (startDate, lastDate) => {
    const date1 = new Date(startDate);
    const date2 = new Date(lastDate);
    const Difference_In_Time = date2.getTime() - date1.getTime();
    const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    const NinetyDay = Difference_In_Days;
    return NinetyDay;
  };

  let day = 0;
  day = dateDifference(startDate.toString(), lastEarningDate.toString()); //difference

  const handleOpenDrawer = () => {
    setDrawerState(true);
  };
  const handleCloseDrawer = () => {
    setDrawerState(false);
  };

  const handleChangeInEarnings = () => {
    getEarningList();
    setReload(!reload);
  };
  useEffect(() => {
    const day = dateDifference(
      startDate.toString(),
      lastEarningDate.toString()
    );
    if (day > 0) {
      setSliderDay(day + 1); //differnce between 2 dates is giving less count
    } else {
      setSliderDay(1);
    }
  }, [day, totalAmount]);

  useEffect(() => {
    getEarningList();
  }, []);
  useEffect(() => {
    getEarningList();
  }, [reload]);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="container">
        <PageDescription
          parameter="ninteen_day_chart_description"
          else_title="90 Day Tracker"
        />
        <div className="col-12 mt-4">
          <ChartJs
            ChartDataList={earningList}
            days={days}
            chartDay={chartDay}
            chartDynamicData={chartDynamicData}
            TargetToAchieve={TargetToAchieve}
            totalAmount={totalAmount}
          />
        </div>

        <div className="row mt-5">
          <Card className="pt-5 pb-3  programm-card h-100 secondary-background">
            <form onSubmit={handleSubmitTarget}>
              <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-6">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      format="DD-MM-YYYY"
                      mask="__/__/____"
                      inputFormat="DD-MM-YYYY"
                      label="90 Day Start Date"
                      value={date ? date : new Date()}
                      fullWidth
                      onChange={(newValue) => {
                        setDate(newValue.$d);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          required
                          className="inputs-fields"
                        />
                      )}
                    />
                  </LocalizationProvider>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 mt-3 mt-md-0">
                  <NumberFormat
                    value={amount}
                    decimalScale={0}
                    sx={{
                      input: { color: get_root_value("--input-text-color") },
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          £
                          {/* {convertCurrencyToSign(
                            userInfo.nineteen_day_plan_currency
                          )} */}
                        </InputAdornment>
                      ),
                    }}
                    customInput={TextField}
                    type="text"
                    className="inputs-fields"
                    label="Target Amount"
                    thousandSeparator={true}
                    fullWidth
                    onValueChange={({ value: v }) => setAmount(v)}
                  />
                </div>
                <div className="col-sm-12 col-md-8 col-lg-10 mt-4 text-center goal_achieved_white goal_achieved_upper_text">
                  <p>
                    YOUR 90 GOAL WILL BE ACHIEVED BY :&nbsp;
                    <span id="date_text_string">
                      {date ? addDays(date) : addDays(new Date())}
                    </span>
                  </p>
                </div>
                <div className="col-sm-12 col-md-4 col-lg-2 mt-md-0 text-end">
                  <button className="small-contained-button mt-4">
                    {loading ? "Saving..." : "Save Target"}
                  </button>
                </div>
              </div>
            </form>
            {/* <div className="col-sm-12 text-center goal_achieved_white goal_achieved_lower_text">
              <p>
                YOUR 90 GOAL WILL BE ACHIEVED BY :&nbsp;
                <span id="date_text_string">{addDays(date)}</span>
              </p>
            </div> */}
          </Card>
          <div className="col-lg-6 col-md-6 col-sm-12 ps-4 mt-4 range-slider">
            <ThemeProvider theme={theme}>
              <Slider
                className={`w-75 ms-4 mt-5 ${classes.slider}`}
                style={{ color: get_root_value("--portal-theme-primary") }}
                aria-label="Always visible"
                value={sliderDay}
                disabled
                step={1}
                min={1}
                max={90}
                marks={marks}
                valueLabelFormat={`Day ${sliderDay}`}
                valueLabelDisplay="on"
              />
            </ThemeProvider>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 ps-4 mt-4 range-slider">
            <Slider
              className="w-75 ms-4 mt-5"
              style={{ color: get_root_value("--portal-theme-primary") }}
              value={Number(totalAmount)}
              classes={{
                valueLabel: classes.valueLabel,
              }}
              disabled
              step={1}
              min={0}
              max={TargetToAchieve}
              marks={TargetAmount}
              valueLabelFormat={`
              £ ${totalAmount.toLocaleString()}`}
              valueLabelDisplay="on"
            />
          </div>
          <div className="col-12 my-4">
            <Divider />
          </div>
          <div className="col-12 text-end mb-4">
            <button
              className="small-contained-button"
              onClick={handleOpenDrawer}
            >
              Add New Earning
            </button>
          </div>
          <div className="col-12">
            <EarningList
              handleChangeInEarnings={handleChangeInEarnings}
              dataList={getEarningList}
              reload={reload}
              setReload={setReload}
              setEarningList={setEarningList}
              setMemberearningList={setMemberearningList}
              memberAdded={member}
            />
          </div>
        </div>
      </div>
      <CustomDrawer
        isOpenDrawer={drawerState}
        onOpenDrawer={handleOpenDrawer}
        onCloseDrawer={handleCloseDrawer}
        pageTitle="Add Earning"
        componentToPassDown={
          <AddNewEarning
            date={date}
            maxDate={addDays(date)}
            setReload={setReload}
            reload={reload}
            setMember={setMember}
            dataList={handleChangeInEarnings}
            earningList={earningList}
            setEarningList={setEarningList}
            onCloseDrawer={handleCloseDrawer}
            apiCall={getEarningList}
          />
        }
      />
    </>
  );
};

export default NintyDayTracker;
