import React from "react";
import { useNavigate } from "react-router-dom";
import RecordNotFound from "src/components/GeneralComponents/RecordNotFound";
import SideImageCard from "src/components/GeneralComponents/SideImageCard";

export default function ProgrammeRecordingTab({ data, lesson_slug }) {
  const navigate = useNavigate();
  const handleClick = (recording) => {
    if (lesson_slug) {
      navigate(`/lessons-recordings/${recording.recording_slug}`);
    } else {
      navigate(`/recordings/${recording.recording_slug}`);
    }
  };

  return (
    <>
      {data.length > 0 ? (
        <SideImageCard data={data} handleClick={handleClick} />
      ) : (
        <RecordNotFound title="Recording Not Found" />
      )}
    </>
  );
}
