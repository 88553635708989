import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Toolbar,
  Tooltip,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { Icon } from "@iconify/react";
import roundFilterList from "@iconify/icons-ic/round-filter-list";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { s3baseUrl } from "src/config/config";
import CustomDrawer from "src/components/DrawerForm/CustomDrawer";
import { fShortenNumber } from "src/utils/formatNumber";
import FilteredChip from "src/components/GeneralComponents/FilteredChip";
import CommissionFilter from "./CommissionFilter";
import { commissions_team_list_api } from "src/DAL/salesTeam/SalesTeam";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function PendingCommissions({ type }) {
  const navigate = useNavigate();
  const classes = useStyles();
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [inputChange, setInputChange] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [filterDrawerState, setFilterDrawerState] = useState(false);

  const EMPTY_FILTER = {
    // commission_from: "",
    // commission_to: "",
    search_text: "",
    type: "pending",
  };

  const [inputs, setinputs] = useState(EMPTY_FILTER);
  const [updateFilterData, setUpdateFilterData] = useState(EMPTY_FILTER);

  const handleChangeInputs = (newValue, name) => {
    setInputChange(true);
    const value = newValue.target.value;
    let PostData = {
      ...inputs,
      [name]: value,
      ["search_text"]: "",
    };
    setSearchText("");
    setPage(0);
    setPageCount(1);
    setinputs(PostData);
    pendingCommissionList(PostData, "empty");
  };

  const handleOpenFilterDrawer = () => {
    setFilterDrawerState(true);
  };

  const handleCloseFilterDrawer = () => {
    setFilterDrawerState(false);
  };

  // --------------search and filter
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
    localStorage.setItem("member_page_number", newPage);
  };

  const searchFunction = (e) => {
    e.preventDefault();
    setPage(0);
    setPageCount(1);
    inputs.search_text = searchText;
    handleCloseFilterDrawer();
    pendingCommissionList(inputs, '"data"');
    localStorage.setItem("pending-commissions", JSON.stringify(inputs));
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setinputs((values) => ({ ...values, [name]: value }));
  };
  // ---------------end

  const pendingCommissionList = async (Data, empty) => {
    setIsLoading(true);
    let postData = Data;
    if (searchText) {
      postData.search_text = empty == "empty" ? "" : searchText;
    }
    postData.type = type;
    const result = await commissions_team_list_api(page, rowsPerPage, postData);
    if (result.code === 200) {
      let chipData = { ...Data };
      if (chipData.commission_from) {
        let commission = `Due Commission From: £${chipData.commission_from} Due Commission To: £${chipData.commission_to}`;
        chipData.Commission = {
          chip_label: commission,
          chip_value: commission,
        };
        delete chipData.commission_from;
        delete chipData.commission_to;
      }

      delete chipData.type;
      if (chipData.search_text) {
        delete chipData.search_text;
      }
      setUpdateFilterData(chipData);
      const userListing = result.sales_team.map((member) => {
        let imagePath = member.profile_image
          ? member.profile_image
          : member.image?.thumbnail_1;

        return {
          ...member,
          name:
            member.first_name +
            " " +
            member.last_name +
            " " +
            "(" +
            member.email +
            ")",
          totalCommission: "£" + fShortenNumber(member?.total_commission),
          commissionPaid: "£" + fShortenNumber(member?.commission_paid),
          commissionDue: "£" + fShortenNumber(member?.commission_due),
          table_avatar: {
            src: s3baseUrl + imagePath,
            alt: member.first_name,
          },
        };
      });
      setUserList(userListing);
      setTotalPages(result.total_pages);
      setTotalCount(result.total_count);
      localStorage.setItem("pending-commissions", JSON.stringify(postData));
      setIsLoading(false);
      setInputChange(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleDeleteChip = (data) => {
    delete data.commission_from;
    delete data.commission_to;
    setPage(0);
    setPageCount(1);
    localStorage.setItem("pending-commissions", JSON.stringify(data));
    pendingCommissionList(data, "data");
    setUpdateFilterData(data);
    setinputs(data);
  };

  const handleDetail = (value) => {
    navigate(`/pending-commissions/commission-detail/${value._id}`, {
      state: value,
    });
  };

  const handlePaid = (value) => {
    navigate(`/pending-commissions/commission-paid/${value._id}`, {
      state: value,
    });
  };

  const handlePay = (value) => {
    navigate(`/pending-commissions/add-transaction/${value._id}`, {
      state: { ...value, inputs },
    });
  };

  const MENU_OPTIONS = [];

  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    {
      id: "name",
      label: "User",
      renderData: (row) => {
        return (
          // <Tooltip title={"View Detail"} placement="top" arrow>
          <p
            className="mb-0"
            // style={{ cursor: "pointer" }}
            // onClick={() => handleDetail(row)}
          >
            {row.name}
          </p>
          // </Tooltip>
        );
      },
    },
    {
      id: "table_avatar",
      label: "Image",
      type: "thumbnail",
    },
    { id: "totalCommission", label: "Total Commission" },
    { id: "commissionPaid", label: "Paid Commission" },
    { id: "commissionDue", label: "Due Commission" },
  ];

  if (type == "pending") {
    MENU_OPTIONS.push({
      label: "Pay Commission ",
      icon: "akar-icons:edit",
      handleClick: handlePay,
    });
    TABLE_HEAD.unshift({ id: "action", label: "Action", type: "action" });
  }
  const handleClearFilter = () => {
    localStorage.removeItem("pending-commissions");
    setinputs(EMPTY_FILTER);
    pendingCommissionList(EMPTY_FILTER, "data");
    setFilterDrawerState(false);
  };

  useEffect(() => {
    let filter_data = EMPTY_FILTER;
    let find_filter = localStorage.getItem(`pending-commissions`);

    if (find_filter) {
      filter_data = JSON.parse(find_filter);
      if (filter_data.search_text) {
        setSearchText(filter_data.search_text);
      }
    }
    if (inputChange) {
      pendingCommissionList(inputs, "data");
    } else {
      setinputs(filter_data);
      setUpdateFilterData(filter_data);
      pendingCommissionList(filter_data, "data");
    }
  }, [rowsPerPage, page, type]);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="container">
        <div className="row mb-4">
          <div className="col-lg-10 col-sm-12">
            <h2>
              {type == "pending" ? "Pending Commissions" : "Paid Commission"}
            </h2>
          </div>{" "}
          <div className="col-lg-2 col-sm-12 d-flex">
            {/* <button
              className="small-contained-button button-commission ms-1 mt-3"
              style={{ widh: "200px" }}
              onClick={handleOpenFilterDrawer}
            >
              Filters &nbsp;&nbsp; <Icon icon={roundFilterList} />
            </button> */}
          </div>
        </div>
        <div>
          <FilteredChip
            data={updateFilterData}
            tempState={inputs}
            EMPTY_FILTER={EMPTY_FILTER}
            onDeleteChip={handleDeleteChip}
            onClear={handleClearFilter}
          />
        </div>
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={userList}
          MENU_OPTIONS={MENU_OPTIONS}
          className="card-with-background"
          custom_pagination={{
            total_count: totalCount,
            rows_per_page: rowsPerPage,
            page: page,
            handleChangePage: handleChangePage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          custom_search={{
            searchText: searchText,
            setSearchText: setSearchText,
            handleSubmit: searchFunction,
          }}
          pageCount={pageCount}
          totalPages={totalPages}
          handleChangePages={handleChangePages}
          pagePagination={true}
        />
        <CustomDrawer
          isOpenDrawer={filterDrawerState}
          onOpenDrawer={handleOpenFilterDrawer}
          onCloseDrawer={handleCloseFilterDrawer}
          pageTitle="Filter"
          componentToPassDown={
            <CommissionFilter
              filterData={inputs}
              searchSubmitFilter={searchFunction}
              handleClearFilter={handleClearFilter}
              handleChange={handleChange}
            />
          }
        />
      </div>
    </>
  );
}
