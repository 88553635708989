import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import CustomPopover from "src/components/MenuOption/CustomPopoverSection";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { htmlDecode } from "src/utils/convertHtml";
import UpdateCalendarEvent from "./UpdateCalendarEvent";
import UpdateEventIteration from "./UpdateEventIteration";
import PerformActionOn from "src/components/ModalBox/PerformActionOn";
import { delete_event_api, eventDetailApi } from "src/DAL/Calendar/Calendar";
import {
  TIME_ZONE_CONVERSION,
  get_date_with_user_time_zone,
} from "src/utils/constants";
import { useContentSetting } from "src/Hooks/ContentSettingState";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function EventDetailNew({
  eventDetailData,
  onCloseDrawer,
  dataList,
  setEditDrawerState,
  setEventDetailData,
}) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [openDelete, setOpenDelete] = useState(false);
  const [showEditComponent, setShowEditComponent] = useState(false);
  const [showEditItration, setShowEditItration] = useState(false);
  const [eventDetail, setEventDetail] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { userInfo, adminTimeZone } = useContentSetting();

  const handleEdit = () => {
    setShowEditComponent(true);
  };
  const handleEventMembers = (value) => {
    navigate(`/calender/members/${value.event_slug}/${value._id}`);
  };
  const handleEditItration = () => {
    setShowEditItration(true);
  };
  const handleAgreeDelete = () => {
    setOpenDelete(true);
  };

  const getEventinformation = async () => {
    const result = await eventDetailApi(eventDetailData.event_slug);
    if (result.code === 200) {
      setEventDetail(result.event);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  //Deleting event
  const handleDelete = async (value) => {
    setOpenDelete(false);
    let postData = {
      event_slug: eventDetailData.event_slug,
      iteration_id: eventDetailData._id,
      update_type: value,
    };
    const result = await delete_event_api(postData);
    if (result.code === 200) {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
      dataList();
      onCloseDrawer();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const MENU_OPTIONS = [
    {
      label: "Edit Event",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Edit Iteration",
      icon: "akar-icons:edit",
      handleClick: handleEditItration,
    },
    {
      label: "Delete Event",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
    {
      label: "Event Members",
      icon: "ant-design:delete-twotone",
      handleClick: handleEventMembers,
    },
  ];

  useEffect(() => {
    getEventinformation();
  }, []);

  const get_start_date_end_time = (date) => {
    return TIME_ZONE_CONVERSION(
      date,
      "DD-MM-YYYY hh:mm A",
      adminTimeZone,
      userInfo.time_zone
    );
  };

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      {showEditComponent ? (
        <UpdateCalendarEvent
          editValues={eventDetail}
          dataList={dataList}
          setEditValues={setEventDetail}
          setShowEditComponent={setShowEditComponent}
          setEditDrawerState={setEditDrawerState}
          setEventDetailData={setEventDetailData}
        />
      ) : showEditItration ? (
        <UpdateEventIteration
          editValues={eventDetailData}
          dataList={dataList}
          setShowEditComponent={setShowEditItration}
          setEditDrawerState={setEditDrawerState}
          setEventDetailData={setEventDetailData}
        />
      ) : (
        <div className="container event-details">
          <h1 className="pe-4">{htmlDecode(eventDetailData.event_title)}</h1>
          <div className="row">
            <div className="col-12">
              <div className="row">
                <div className="col-5">
                  <b>Event From :</b>&nbsp;
                </div>
                <div className="col-7">
                  <p>{get_start_date_end_time(eventDetail.start_date_time)}</p>
                </div>
                <div className="col-5">
                  <b>Event To :</b>&nbsp;
                </div>
                <div className="col-7">
                  <p>{get_start_date_end_time(eventDetail.end_date_time)}</p>
                </div>
                <div className="col-5">
                  <b>Iteration From :</b>&nbsp;
                </div>
                <div className="col-7">
                  <p>
                    {get_start_date_end_time(eventDetailData.start_date_time)}
                  </p>
                </div>
                <div className="col-5">
                  <b>Iteration To :</b>&nbsp;
                </div>
                <div className="col-7">
                  <p>
                    {get_start_date_end_time(eventDetailData.end_date_time)}
                  </p>
                </div>
                <div className="col-5">
                  <b>Status :</b>&nbsp;
                </div>
                <div className="col-7">
                  {eventDetailData.status === false ? (
                    <button className="small-contained-chip-error">
                      Inctive
                    </button>
                  ) : (
                    <button className="small-contained-chip-success">
                      Active
                    </button>
                  )}
                </div>
                {eventDetail.description && (
                  <>
                    <div className="col-5 mt-3">
                      <b>Description :</b>&nbsp;
                    </div>
                    <div className="col-12">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: eventDetail.description,
                        }}
                        className="mt-2"
                      ></div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>

          <div className="affirmation-dots">
            <CustomPopover menu={MENU_OPTIONS} data={eventDetailData} />
          </div>

          <PerformActionOn
            open={openDelete}
            setOpen={setOpenDelete}
            title={"Delete recurring event?"}
            handleAgree={handleDelete}
          />
        </div>
      )}
    </>
  );
}
