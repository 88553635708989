import React, { useEffect, useRef, useState } from "react";
import { Card, CircularProgress, Fab, Tooltip } from "@mui/material";
import { Icon } from "@iconify/react";
import { useNavigate, useParams } from "react-router-dom";
import CustomConfirmation from "src/components/GeneralComponents/CustomConfirmation";
import CustomDrawer from "src/components/GeneralComponents/CustomDrawer";
import { s3baseUrl } from "src/config/config";
import { formatTime } from "src/utils/constants";
import moment from "moment";
import { useContentSetting } from "src/Hooks/ContentSettingState";
import { Participants } from "./components";
const momenttz = require("moment-timezone");

import {
  ConsoleLogger,
  DefaultDeviceController,
  DefaultMeetingSession,
  LogLevel,
  MeetingSessionConfiguration,
} from "amazon-chime-sdk-js";
import VideoRemoteOutput from "./components/VideoRemoteOutput";
import LiveStreamFooter from "./components/LiveStreamFooter";
import LiveStreamSettings from "./components/Participants/LiveStreamSettings";

const Background = ({ imageUrl }) => (
  <div
    style={{
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundImage: `url(${imageUrl})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "cover",
      zIndex: 1,
    }}
  />
);

export default function LiveMeeting(props) {
  const {
    feedDetail,
    liveStream,
    isFullScreen,
    setIsFullScreen,
    handleCloseDetailBox,
    joinType,
    setLiveStream,
    module_name,
    navigateTo,
  } = props;
  const { topic } = useParams();
  const navigate = useNavigate();
  const { socket, userInfo } = useContentSetting();
  const audioLocalRef = useRef(null);
  const videoContainerRef = useRef(null);
  const [isMuted, setIsMuted] = useState(true);
  const [isPaused, setIsPaused] = useState(true);
  const [openParticipants, setOpenParticipants] = useState(false);
  const [openEndStream, setOpenEndStream] = useState(false);
  const [isScreenShared, setIsScreenShared] = useState(false);
  const [openSettings, setOpenSettings] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [participants, setParticipants] = useState([]);
  const [tileUsers, setTileUsers] = useState([]);
  const [meetingSession, setMeetingSession] = useState(null);
  const [participantCount, setParticipantCount] = useState(1);
  const [remoteTiles, setRemoteTiles] = useState([]);
  const [selectedAttendee, setSelectedAttendee] = useState({});
  const [activeCam, setActiveCam] = useState(null);
  const [activeMic, setActiveMic] = useState(null);
  const [hostUsers, setHostUsers] = useState([]);
  const [participantsCount, setParticipantsCount] = useState(0);

  let total_time = -2;
  if (liveStream?.stream_start_time) {
    const startTime = moment(liveStream.stream_start_time);
    const endTime = momenttz.tz(new Date(), "Europe/Dublin");
    total_time = endTime.diff(startTime, "seconds");
  }
  const [timer, setTimer] = useState(total_time);

  const handleOpenParticipantsDrawer = () => {
    if (joinType !== "host") return;
    setOpenParticipants(true);
  };

  const handleCloseParticipantsDrawer = () => {
    setOpenParticipants(false);
  };

  const handleOpenSettingsDrawer = () => {
    setOpenSettings(true);
  };

  const handleCloseSettingsDrawer = () => {
    setOpenSettings(false);
  };

  const handleEndStreamConfirmation = () => {
    setOpenEndStream(true);
  };

  const handleEndStream = () => {
    if (topic) {
      socket.emit("end_stream_event", { module_id: topic });
    } else {
      manage_stream_participant("user_left");
    }
  };

  const update_remote_tiles = (tileState) => {
    setTileUsers((old) => {
      if (old.find((tile) => tile.attendee_id === tileState.boundAttendeeId)) {
        return old;
      }
      let new_tile = {
        tile_id: tileState.tileId,
        attendee_id: tileState.boundAttendeeId,
      };
      return [...old, new_tile];
    });
  };

  const live_stream_started_receiver = (data) => {
    console.log(data, "live_stream_started_receiver");
    const { meetingResponse, attendeeResponse, live_stream, host_users } = data;
    setRemoteTiles(host_users);
    if (joinType === "host") {
      setLiveStream(live_stream);
    } else {
      let findHost = host_users.find(
        (user) => user.attendee_id === liveStream.selected_attendee_id
      );
      if (findHost) {
        setIsPaused(findHost.is_video_paused);
      }
    }
    const logger = new ConsoleLogger("Logger", LogLevel.INFO);
    const deviceController = new DefaultDeviceController(logger);
    const configuration = new MeetingSessionConfiguration(
      meetingResponse,
      attendeeResponse
    );
    const meetingSession = new DefaultMeetingSession(
      configuration,
      logger,
      deviceController
    );
    setMeetingSession(meetingSession);
  };

  const setupMeetingSession = async () => {
    try {
      const audioLocalElement = audioLocalRef.current;
      const audioInputDevices =
        await meetingSession.audioVideo.listAudioInputDevices();
      const videoInputDevices =
        await meetingSession.audioVideo.listVideoInputDevices();
      if (audioInputDevices.length) {
        let deviceId = audioInputDevices[0].deviceId;
        await meetingSession.audioVideo.startAudioInput(deviceId);
        setActiveMic(deviceId);
      }

      meetingSession.audioVideo.bindAudioElement(audioLocalElement);
      meetingSession.audioVideo.realtimeMuteLocalAudio();

      if (videoInputDevices.length) {
        let deviceId = videoInputDevices[0].deviceId;
        await meetingSession.audioVideo.startVideoInput(deviceId);
        setActiveCam(deviceId);
      }

      const observer = {
        videoTileDidUpdate: (tileState) => {
          if (!tileState.boundAttendeeId) return;
          update_remote_tiles(tileState);
        },
        videoTileWasRemoved: (tileId) => {
          setTileUsers((prevTiles) =>
            prevTiles.filter((tile) => tile.tile_id !== tileId)
          );
        },
        audioVideoDidStart: () => {
          console.log("AudioVideo session started");
        },
      };
      meetingSession.audioVideo.addObserver(observer);
      meetingSession.audioVideo.start();
      setIsLoading(false);
    } catch (error) {
      console.error("Error setting up meeting session:", error);
    }
  };

  const preventReload = (e) => {
    if (
      e.key === "F5" ||
      (e.ctrlKey && e.key === "r") ||
      (e.metaKey && e.key === "r")
    ) {
      e.preventDefault();
      e.returnValue = "";
    }
  };

  const handleBeforeUnload = (e) => {
    e.preventDefault();
    e.returnValue = "";
    handleEndStream();
  };

  const handleLeave = () => {
    if (selectedAttendee.attendee_id === liveStream.selected_attendee_id) {
      if (isScreenShared) {
        stopScreenShare();
      }
    }
    if (handleCloseDetailBox) {
      handleCloseDetailBox();
    }
  };

  const handleMakeHost = (user, action) => {
    const data = {
      user_id: user._id,
      module_id: topic,
      action: action,
      module_name: module_name,
      user_type: user.user_type,
    };

    console.log(data, "handleMakeHost");
    socket.emit("manage_stream_participant", data);
  };

  const handleSelectTile = (user) => {
    if (joinType !== "host") return;
    const data = {
      module_id: topic,
      action: "update_selected_attendee_id",
      attendee_id: user.attendee_id,
      user_type: user.user_type,
      module_name: module_name,
    };

    if (isScreenShared) {
      stopScreenShare();
    }

    console.log(data, "handleSelectTile");
    socket.emit("manage_stream_participant", data);
  };

  const manage_stream_participant = (action) => {
    const data = {
      user_id: userInfo._id,
      module_id: feedDetail._id,
      action: action,
      module_name: module_name,
      user_type: "consultant_user",
    };

    console.log(data, "manage_stream_participant");
    socket.emit("manage_stream_participant", data);
  };

  const meeting_video_paused = (data) => {
    console.log(data, "meeting_video_paused");
    const { attendee_id, is_video_paused, module_id } = data;
    if (module_id === topic || module_id === feedDetail._id) {
      if (attendee_id === liveStream.selected_attendee_id) {
        setIsPaused(is_video_paused);
      }
      setRemoteTiles((old) =>
        old.map((user) => {
          if (user.attendee_id === attendee_id) {
            user.is_video_paused = is_video_paused;
          }
          return user;
        })
      );
    }
  };

  const meeting_screen_shared = (data) => {
    console.log(data, "meeting_screen_shared");
    const { attendee_id, is_screen_shared, module_id } = data;
    if (module_id === topic || module_id === feedDetail._id) {
      if (attendee_id === `${liveStream.selected_attendee_id}#content`) {
        setIsScreenShared(is_screen_shared);
      }
      setRemoteTiles((old) =>
        old.map((user) => {
          if (`${user.attendee_id}#content` === attendee_id) {
            user.is_screen_shared = is_screen_shared;
          }
          return user;
        })
      );
    }
  };
  const meeting_video_muted = (data) => {
    console.log(data, "meeting_video_muted");
    const { attendee_id, is_video_muted, module_id } = data;
    if (module_id === topic || module_id === feedDetail._id) {
      if (attendee_id === liveStream.selected_attendee_id) {
        setIsMuted(is_video_muted);
      }
      setRemoteTiles((old) =>
        old.map((user) => {
          if (user.attendee_id === attendee_id) {
            user.is_video_muted = is_video_muted;
          }
          return user;
        })
      );
    }
  };

  const startScreenShare = async () => {
    try {
      await meetingSession.audioVideo.startContentShareFromScreenCapture();
      setIsScreenShared(true);
      let postData = {
        module_id: topic || feedDetail._id,
        is_screen_shared: true,
        user_id: userInfo._id,
        module_name: module_name,
        attendee_id: `${liveStream.selected_attendee_id}#content`,
      };
      console.log(postData, "startScreenShare");
      socket.emit("meeting_screen_shared", postData);
    } catch (error) {
      console.error("Error starting screen share:", error);
    }
  };

  const stopScreenShare = () => {
    let postData = {
      module_id: topic || feedDetail._id,
      is_screen_shared: false,
      user_id: userInfo._id,
      module_name: module_name,
      attendee_id: `${liveStream.selected_attendee_id}#content`,
    };
    console.log(postData, "stopScreenShare");
    socket.emit("meeting_screen_shared", postData);
    meetingSession.audioVideo.stopContentShare();
    setIsScreenShared(false);
  };

  useEffect(() => {
    // socket.emit("live_event_room", "live_feed_room");
    if (joinType === "participant") {
      manage_stream_participant("user_joined");
    } else {
      const data = {
        user_id: userInfo._id,
        module_id: topic,
        module_name: module_name,
        user_type: "consultant_user",
      };
      console.log(data, "live_stream_started");
      socket.emit("live_stream_started", data);
    }

    socket.on("manage_stream_participant_receiver", (data) => {
      console.log(data, "manage_stream_participant_receiver");
      if (data.module_id === topic || data.module_id === feedDetail._id) {
        const {
          user,
          response,
          participant_count,
          live_stream,
          new_presenter,
          presenter_left,
        } = data;

        setParticipantCount(participant_count);
        if (presenter_left) {
          setLiveStream((old) => ({
            ...old,
            selected_attendee_id: new_presenter.attendee_id,
          }));
          setIsScreenShared(false);
        }
        if (data.action !== "update_selected_attendee_id") {
          setRemoteTiles(response.host_users);
        }

        if (data.action === "user_joined") {
          user.join_time = new Date();
          setParticipants((old) => {
            let find_user = old.find((u) => u._id === user._id);
            if (find_user) return old;
            setParticipantsCount((count) => count + 1);
            return [user, ...old];
          });
        } else if (data.action === "update_selected_attendee_id") {
          setLiveStream(live_stream);
        } else if (data.action === "make_co_host") {
          setHostUsers((old) => {
            let find_user = old.find((u) => u._id === user._id);
            if (find_user) return old;
            return [...old, { ...user, user_role: "co_host" }];
          });
          setParticipants((old) => old.filter((p) => p._id !== user._id));
          setParticipantsCount((count) => count - 1);
        } else if (data.action === "remove_co_host") {
          if (user._id === userInfo._id) {
            setMeetingSession((session) => {
              session.audioVideo.stopLocalVideoTile();
              session.audioVideo.realtimeMuteLocalAudio();
              session.audioVideo.stopContentShare();
              return session;
            });
          }
          setHostUsers((old) => old.filter((host) => host._id !== user._id));
          setParticipants((old) => {
            let find_user = old.find((u) => u._id === user._id);
            if (find_user) return old;
            setParticipantsCount((count) => count + 1);
            return [...old, { ...user, user_role: "participant" }];
          });
        } else {
          setParticipantsCount((count) => count - 1);
          setHostUsers((old) => old.filter((host) => host._id !== user._id));
          setParticipants((old) =>
            old.filter((participant) => participant._id !== user._id)
          );
        }
      }
    });

    socket.on("live_stream_started_receiver", live_stream_started_receiver);

    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("keydown", preventReload);
    window.addEventListener("unload", handleEndStream);
    return () => {
      socket.off("manage_stream_participant_receiver");
      socket.off("live_stream_started_receiver");
      window.removeEventListener("keydown", preventReload);
      window.removeEventListener("unload", handleEndStream);
      window.removeEventListener("beforeunload", handleBeforeUnload);
      handleEndStream();
    };
  }, []);

  useEffect(() => {
    const handleFullscreenChange = () => {
      if (!document.fullscreenElement) {
        setIsFullScreen(false);
      }
    };
    document.addEventListener("fullscreenchange", handleFullscreenChange);
    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, [isFullScreen]);

  useEffect(() => {
    if (!meetingSession) return;
    setupMeetingSession();

    let interval;
    interval = setInterval(() => {
      setTimer((prevTimer) => prevTimer + 1);
    }, 1000);

    return async () => {
      if (meetingSession) {
        meetingSession.audioVideo.stopAudioInput();
        meetingSession.audioVideo.stopVideoInput();
        meetingSession.stop();
      }
      clearInterval(interval);
    };
  }, [meetingSession]);

  useEffect(() => {
    if (!liveStream || !meetingSession) return;
    socket.on("meeting_screen_shared", meeting_screen_shared);
    socket.on("meeting_video_paused", meeting_video_paused);
    socket.on("meeting_video_muted", meeting_video_muted);
    return () => {
      socket.off("meeting_screen_shared");
      socket.off("meeting_video_paused");
      socket.off("meeting_video_muted");
    };
  }, [liveStream, meetingSession]);

  useEffect(() => {
    if (!liveStream || !meetingSession) return;
    if (tileUsers.length > 0) {
      const mainVideoElement = document.getElementById("main-video-element");

      const createVideoElement = () => {
        const videoElement = document.createElement("video");
        videoElement.autoplay = true;
        videoElement.playsInline = true;
        videoElement.className = "w-100";
        return videoElement;
      };

      let videoElement;
      let findAt = tileUsers.find(
        (u) => u.attendee_id === `${liveStream.selected_attendee_id}#content`
      );
      if (!findAt) {
        findAt = tileUsers.find(
          (u) => u.attendee_id === liveStream.selected_attendee_id
        );
      }

      if (findAt) {
        videoElement = createVideoElement();
        mainVideoElement.replaceWith(videoElement);
        videoElement.id = "main-video-element";
        meetingSession.audioVideo.bindVideoElement(
          findAt.tile_id,
          videoElement
        );
      } else {
        videoElement = createVideoElement();
        mainVideoElement.replaceWith(videoElement);
        videoElement.id = "main-video-element";
      }

      setRemoteTiles((old) => {
        let findUser = old.find(
          (attendee) => attendee.attendee_id === liveStream.selected_attendee_id
        );
        if (findUser) {
          setIsPaused(findUser.is_video_paused);
          setIsMuted(findUser.is_video_muted);
          setIsScreenShared(findUser.is_screen_shared);
          setSelectedAttendee(findUser);
        }
        return old;
      });
    }
  }, [liveStream?.selected_attendee_id, meetingSession, tileUsers]);

  if (!meetingSession || !liveStream) return <></>;

  const controlsInfo = {
    meetingSession,
    feedDetail,
    isPaused,
    isMuted,
    attendee_id: liveStream.selected_attendee_id,
    isSelected: true,
  };

  const handleFullScreenToggle = () => {
    const videoContainer = videoContainerRef.current;
    if (!document.fullscreenElement) {
      videoContainer.requestFullscreen().catch((err) => {
        console.error(`Error attempting to enter full-screen mode: ${err}`);
      });
    } else {
      document.exitFullscreen();
    }
    setIsFullScreen(!isFullScreen);
  };

  let selectedUser = remoteTiles.find(
    (selected) =>
      selected.attendee_id === liveStream.selected_attendee_id &&
      selected._id === userInfo._id
  );

  const settingData = {
    activeCam,
    setActiveCam,
    activeMic,
    setActiveMic,
    meetingSession,
    openSettings,
  };

  return (
    <Card className="live-stream-wrapper" ref={videoContainerRef}>
      {isLoading ? (
        <CircularProgress className="live-stream-loader" color="primary" />
      ) : (
        isPaused &&
        !isScreenShared && (
          <Background imageUrl={s3baseUrl + feedDetail.image.thumbnail_1} />
        )
      )}
      <div className="remote-tiles-box">
        {meetingSession &&
          remoteTiles.length > 0 &&
          remoteTiles.map((attendee, index) => {
            if (liveStream.selected_attendee_id === attendee.attendee_id) {
              if (!isScreenShared) return;
              if (isPaused) return;
            }
            return (
              <VideoRemoteOutput
                key={`attendee-${attendee.attendee_id}-${index}`}
                attendee_info={attendee}
                meetingSession={meetingSession}
                className="w-100"
                tileUsers={tileUsers}
                feedDetail={feedDetail}
                handleOpenSettingsDrawer={handleOpenSettingsDrawer}
              />
            );
          })}
      </div>
      <div className="live-stream-header">
        <div className="stream-info">
          <Icon
            className="me-1"
            fontSize={16}
            style={{ color: "red" }}
            icon="carbon:dot-mark"
          />
          <span>Live</span>
        </div>
        {timer >= 0 && (
          <div className="call-timer-section">
            <div className="call-timer-box">{formatTime(timer)}</div>
          </div>
        )}
        <div
          className="stream-users-info"
          onClick={handleOpenParticipantsDrawer}
        >
          <Icon
            className="me-1"
            fontSize={16}
            icon="ic:baseline-remove-red-eye"
          />
          <span>{participantCount}</span>
        </div>
      </div>

      <audio ref={audioLocalRef}></audio>

      <div id="main-video-element"></div>
      <div className="live-stream-footer">
        <div className="end-stream-btn" onClick={handleEndStreamConfirmation}>
          <Tooltip title={`${joinType === "host" ? "End" : "Leave"} Meeting`}>
            <Fab
              style={{ backgroundColor: "red", color: "white" }}
              size="medium"
              variant="extended"
              aria-label="add"
            >
              <Icon
                className="stream-control-icon"
                icon="material-symbols:call-end"
              />
            </Fab>
          </Tooltip>
        </div>
        <div
          className={`stream-controls-wrapper justify-content-${
            selectedUser ? "center" : "end"
          }`}
        >
          {selectedUser && <LiveStreamFooter {...controlsInfo} />}
          {selectedUser && (
            <Tooltip
              title={isScreenShared ? "Stop Screen Sharing" : "Share Screen"}
            >
              <Fab
                onClick={isScreenShared ? stopScreenShare : startScreenShare}
                className="stream-control-button"
                size="medium"
                variant="extended"
                aria-label="screen-share"
              >
                <Icon
                  className="stream-control-icon"
                  icon={`fluent:share-screen-${
                    isScreenShared ? "stop" : "start"
                  }-28-regular`}
                />
              </Fab>
            </Tooltip>
          )}
          <Tooltip title={isFullScreen ? "Exit Full Screen" : "Full Screen"}>
            <div className="stream-control">
              <Fab
                onClick={handleFullScreenToggle}
                className="stream-control-button"
                size="medium"
                variant="extended"
                aria-label="add"
              >
                <Icon
                  className="stream-control-icon"
                  icon={`${
                    isFullScreen
                      ? "ic:baseline-fullscreen-exit"
                      : "ic:baseline-fullscreen"
                  }`}
                />
              </Fab>
            </div>
          </Tooltip>
        </div>
        <div>
          {selectedUser && (
            <div
              className="settings-stream-btn"
              onClick={handleOpenSettingsDrawer}
            >
              <Fab style={{}} size="medium" variant="extended" aria-label="add">
                <Icon
                  className="stream-control-icon"
                  icon="icon-park-solid:setting-two"
                />
              </Fab>
            </div>
          )}
        </div>
      </div>
      <CustomConfirmation
        open={openEndStream}
        setOpen={setOpenEndStream}
        title={`Are you sure you want to ${
          joinType === "participant" ? "leave" : "end"
        } this meeting?`}
        handleAgree={() =>
          joinType === "host" ? navigate(navigateTo) : handleLeave()
        }
      />
      <CustomDrawer
        isOpenDrawer={openParticipants}
        onOpenDrawer={handleOpenParticipantsDrawer}
        onCloseDrawer={handleCloseParticipantsDrawer}
        pageTitle={`Live ${
          liveStream.stream_type === "meeting" ? "Meeting" : "Stream"
        } Participants`}
        componentToPassDown={
          <Participants
            module_id={topic}
            openParticipants={openParticipants}
            participants={participants}
            setParticipants={setParticipants}
            handleMakeHost={handleMakeHost}
            handleSelectTile={handleSelectTile}
            liveStream={liveStream}
            hostUsers={hostUsers}
            setHostUsers={setHostUsers}
            participantsCount={participantsCount}
            setParticipantsCount={setParticipantsCount}
          />
        }
      />

      <CustomDrawer
        isOpenDrawer={openSettings}
        onOpenDrawer={handleOpenSettingsDrawer}
        onCloseDrawer={handleCloseSettingsDrawer}
        pageTitle="Live Streams Settings"
        componentToPassDown={<LiveStreamSettings {...settingData} />}
      />
    </Card>
  );
}
