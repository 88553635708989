import { useEffect, useState } from "react";
import Chart from "react-apexcharts";

const GeneralGraph = ({ popupData, name, spacificDate }) => {
  console.log(name, "namename");
  const [data, setData] = useState();
  function getDataInRange(data, startDate, endDate) {
    let start = new Date(startDate.split("-").reverse().join("-"));
    let end = new Date(endDate.split("-").reverse().join("-"));

    let days = Math.ceil(
      (end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24)
    );
    let dates = [];
    let scores = [];
    let monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    for (let i = 0; i <= days; i++) {
      let currentDate = new Date(start);
      currentDate.setDate(start.getDate() + i);
      let day = String(currentDate.getDate()).padStart(2, "0");
      let month = String(currentDate.getMonth() + 1).padStart(2, "0");
      let year = currentDate.getFullYear();
      let dateStringForComparison = `${day}-${month}-${year}`;
      let found = data.find((item) => item.date === dateStringForComparison);
      let dayForDisplay = currentDate.getDate();
      let monthForDisplay = monthNames[currentDate.getMonth()];
      let dateStringForDisplay = `${monthForDisplay} ${dayForDisplay}`;
      dates.push(dateStringForDisplay);
      scores.push(found ? found.rate : 0);
    }
    let dataObject = { dates, scores };
    setData(dataObject);
    return dataObject;
  }
  let lineChart = {
    options: {
      chart: {
        type: "line",
      },
      grid: {
        show: true,
        borderColor: "gray",
        strokeDashArray: 4,
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: "#c6c2c2",
          },
        },
        axisBorder: {
          show: true,
          color: "gray",
          dashArray: 4,
        },
        max: 10,
      },
      xaxis: {
        labels: {
          style: {
            colors: "#c6c2c2",
          },
          axisTicks: {
            show: false, // This hides the default x-axis ticks
          },
        },

        categories: data?.dates,
      },

      stroke: {
        curve: "smooth",
      },
      fill: {
        colors: ["#f6bd4b"],
      },
      dataLabels: {
        enabled: false,
      },
    },
    series: [
      {
        name: name,
        data: data?.scores,
        color: "#f6bd4b",
      },
    ],
  };
  useEffect(() => {
    if (popupData) {
      getDataInRange(spacificDate, popupData.start_date, popupData.end_date);
    }
  }, [popupData]);
  return (
    <>
      {data && (
        <Chart
          options={lineChart.options}
          series={lineChart.series}
          type="area"
          height={340}
        />
      )}
    </>
  );
};

export default GeneralGraph;
