import React from "react";
import { useNavigate } from "react-router-dom";
import { s3baseUrl } from "src/config/config";
import { htmlDecode } from "src/utils/convertHtml";

function EventVideos({ event_category, event_slug }) {
  const navigate = useNavigate();
  const handleChangePage = (slug) => {
    navigate(`/live-events/${event_slug}/video-detail/${slug}`);
  };

  return (
    <>
      {event_category?.map((category) => {
        if (category.dynamite_event_category_video.length > 0) {
          return (
            <div className={`profile-cards p-3 community-card-title mb-3`}>
              <h4>{htmlDecode(category.title)}</h4>
              {category.dynamite_event_category_video &&
                category.dynamite_event_category_video.map((video, index) => {
                  return (
                    <>
                      <div
                        className="row"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          handleChangePage(video.dynamite_event_category_slug);
                        }}
                      >
                        <div key={index}>
                          <div className="col-12 mt-2">
                            {video && video.image?.thumbnail_2 && (
                              <img src={s3baseUrl + video.image.thumbnail_2} />
                            )}
                            <h6>{htmlDecode(video.title)}</h6>
                          </div>
                        </div>
                      </div>
                      {index !==
                        category.dynamite_event_category_video.length - 1 && (
                        <hr className="mt-0 mb-0" />
                      )}
                    </>
                  );
                })}
            </div>
          );
        }
      })}
    </>
  );
}

export default EventVideos;
