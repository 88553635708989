import { invokeApi } from "../../bl_libs/invokeApi";

export const updateMissionControlApi = async (data) => {
  const requestObj = {
    path: `api/consultant/update_mission_control_statement`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const dashboardApi = async (filterData) => {
  let data = "api/consultant/delegate_dashboard";
  if (filterData) {
    data = `api/consultant/delegate_dashboard?type=filter`;
  }
  const requestObj = {
    path: data,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: filterData,
  };
  return invokeApi(requestObj);
};
export const login = async (data) => {
  const requestObj = {
    path: `api/consultant/login`,
    method: "POST",
    headers: {
      // 'x-sh-auth': 1234
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const loginAsCustomerApi = async (data) => {
  const requestObj = {
    path: `api/consultant/login_by_email`,
    method: "POST",
    headers: {
      // 'x-sh-auth': 1234
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const logout = async (data) => {
  const requestObj = {
    path: `api/logout.php`,
    method: "POST",
    headers: {
      // 'x-sh-auth': 1234
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const register = async (data) => {
  const requestObj = {
    path: `api/user_registor.php`,
    method: "POST",
    headers: {
      // 'x-sh-auth': localStorage.getItem('token')
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const confirmEmail = async (data) => {
  const requestObj = {
    path: `api/consultant/forgot_password_send_verification_code`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const confirmPinCode = async (data) => {
  const requestObj = {
    path: `api/consultant/email_code_verification`,
    method: "POST",
    headers: {
      // 'x-sh-auth': localStorage.getItem('token')
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const updatePassword = async (data) => {
  const requestObj = {
    path: `api/consultant/reset_password`,
    method: "POST",
    headers: {
      // 'x-sh-auth': localStorage.getItem('token')
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const profileDetail = async (data) => {
  const requestObj = {
    path: `api/user_profile/detail_profile.php`,
    method: "POST",
    headers: {
      // 'x-sh-auth': localStorage.getItem('token')
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const updateAdminPassword = async (data) => {
  const requestObj = {
    path: `api/update_password/update_password.php`,
    method: "POST",
    headers: {
      // 'x-sh-auth': localStorage.getItem('token')
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const updateProfile = async (data) => {
  const requestObj = {
    path: `api/user_profile/edit_profile.php`,
    method: "POST",
    headers: {
      // 'x-sh-auth': localStorage.getItem('token')
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const consultantSettingApi = async () => {
  const requestObj = {
    path: `api/consultant_init`,
    method: "GET",
    headers: {
      // 'x-sh-auth': localStorage.getItem('token')
    },
  };
  return invokeApi(requestObj);
};
export const witoutTokeninitapi = async () => {
  const requestObj = {
    path: `api/consultant_init/without_token`,
    method: "GET",
    headers: {
      // 'x-sh-auth': localStorage.getItem('token')
    },
  };
  return invokeApi(requestObj);
};
export const witTokeninitapi = async () => {
  const requestObj = {
    path: `api/consultant_init/with_token`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const consultantProfileApi = async (id, token) => {
  const requestObj = {
    path: `api/consultant/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": token ? token : localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const login_by_admin_api = async (data) => {
  const requestObj = {
    path: `app/login_impersonate_user`,
    method: "POST",
    headers: {
      // 'x-sh-auth': 1234
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const update_feed_image_on_s3 = async (data) => {
  const requestObj = {
    path: `app/feed/update_image_on_s3`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

// BROADCAST API

export const getMemebersAndGroups = async () => {
  const requestObj = {
    path: `api/consultant/groups_and_members/list`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const createBroadcast = async (data) => {
  const requestObj = {
    path: `api/broadcast/add_broadcast`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const updateBroadcast = async (data, id) => {
  const requestObj = {
    path: `api/broadcast/update_broadcast/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _get_broadcast_history = async (path, data) => {
  const requestObj = {
    path: path,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };

  return invokeApi(requestObj);
};
export const _send_message_broadcast = async (data) => {
  const requestObj = {
    path: `api/broadcast/add_broadcast_message`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };

  return invokeApi(requestObj);
};
export const _send_message_broadcast_update = async (data, cast, id) => {
  const requestObj = {
    path: `api/broadcast/edit_broadcast_message/${cast}/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };

  return invokeApi(requestObj);
};

export const getBroadcastDetail = async (loadMore) => {
  const requestObj = {
    path: loadMore,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const getBookingReport = async (loadMore, data) => {
  const requestObj = {
    path: loadMore,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const get_booking_stats_api = async (data) => {
  const requestObj = {
    path: `api/consultant/booking_stats/delegate`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const getBroadcastDetailNew = async (id) => {
  const requestObj = {
    path: `api/broadcast/list_broadcast_messages/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const deleteMessage = async (id, message_id) => {
  const requestObj = {
    path: `api/broadcast/delete_broadcast_message/${id}/${message_id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
