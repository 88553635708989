import { useEffect, useState } from "react";
import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import MUIAutocomplete from "src/components/GeneralComponents/MUIAutocomplete";
import {
  sale_page_list_with_plans_api,
  subscription_list_with_page_and_plan,
} from "src/DAL/WebsitePages/WebsitePages";
import { dd_date_format } from "src/utils/constants";
import { projectMode } from "src/config/config";

const TABLE_HEAD = [
  { id: "number", label: "#", alignRight: false, type: "number" },
  { id: "member_name", label: "Member", alignRight: false },
  { id: "page_name", label: "Page Title", alignRight: false },
  { id: "plan_title", label: "Plan Title", alignRight: false },
  { id: "stripe_mode", label: "Subscription Mode", alignRight: false },
  { id: "invoice_date", label: "Next Invoice Date", alignRight: false },

  {
    id: "created_at",
    label: "Subscription Date",
    alignRight: false,
  },
];

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
  loadingSection: {
    marginLeft: "45%",
    marginTop: "20%",
    marginBottom: "20%",
    padding: 0,
  },
}));

export default function SubscriptionList() {
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingSection, setIsLoadingSection] = useState(true);
  const [selectedPage, setSelectedPage] = useState(null);
  const [pagesList, setPagesList] = useState([]);
  const [plansList, setPlansList] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [subscriptionsData, setSubscriptionsData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const classes = useStyles();
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [filterState, setFilterState] = useState({
    subscription_mode: projectMode == "DEV" ? "sandBox" : "live",
  });
  const handleChangePage = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFilterState((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };
  const getPagesList = async () => {
    setIsLoading(true);
    const result = await sale_page_list_with_plans_api();
    if (result.code === 200) {
      let page_list = result.sale_pages.map((page) => {
        let page_title = page.sale_page_title;
        if (page.type == "template") {
          page_title = page.sale_page_title + " (Template)";
        }
        return {
          ...page,
          page_title: page_title,
        };
      });
      setPagesList(page_list);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const getMembersList = async () => {
    setIsLoadingSection(true);
    let postData = {
      sale_page: selectedPage?._id ? selectedPage?._id : "",
      payment_plan: selectedPlan?._id ? selectedPlan?._id : "",
      search_text: searchText,
      filter: filterState.subscription_mode,
    };
    const result = await subscription_list_with_page_and_plan(
      page,
      rowsPerPage,
      postData
    );
    if (result.code == 200) {
      let subscription_list = [];
      if (result.subscription.length > 0) {
        subscription_list = result.subscription.map((event, index) => {
          let member_name = "";
          if (event.member.first_name) {
            member_name =
              event.member.first_name +
              " " +
              event.member.last_name +
              " (" +
              event.member.email +
              ")";
          }
          let event_plan = event.plan;
          let plan_title = "N/A";
          if (event_plan?.plan_title) {
            plan_title = event_plan?.plan_title;
          }
          let payment_access = event_plan?.payment_access;
          if (plan_title != "N/A") {
            if (event_plan?.is_plan_free) {
              plan_title = plan_title + " (Free)";
            } else if (payment_access == "recursion") {
              plan_title = plan_title + " (Recurring)";
            } else if (payment_access == "onetime") {
              plan_title = plan_title + " (OneTime)";
            } else {
              plan_title = plan_title;
            }
          }

          return {
            ...event,
            member_name: member_name,
            page_name: event.sale_page?.sale_page_title,
            created_at: dd_date_format(event.createdAt),
            invoice_date: dd_date_format(event.next_invoice_date),
            plan_title: plan_title,
          };
        });
      }

      setSubscriptionsData(subscription_list);
      setTotalCount(result.total_subscription_count);
      setTotalPages(result.total_pages);
      setIsLoadingSection(false);
    } else {
      enqueueSnackbar(result.message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      setIsLoadingSection(false);
    }
  };

  const searchFunction = () => {
    setPage(0);
    setPageCount(1);
    getMembersList();
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
    localStorage.setItem("member_page_number", newPage);
  };

  useEffect(() => {
    getPagesList();
  }, []);

  useEffect(() => {
    if (selectedPage?.payment_plans) {
      setPlansList(selectedPage.payment_plans);
    } else {
      setPlansList([]);
    }
    setSelectedPlan(null);
  }, [selectedPage]);

  useEffect(() => {
    getMembersList();
  }, [rowsPerPage, page]);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12 mb-3">
            <h2>Subscriptions List</h2>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-12 mb-2 mb-sm-0">
            <MUIAutocomplete
              inputLabel="Sale Page"
              selectedOption={selectedPage}
              setSelectedOption={setSelectedPage}
              optionsList={pagesList}
              autoComplete="new-password"
              name="page_title"
            />
          </div>
          <div className="col-lg-3 col-md-3 col-sm-12 mb-2 mb-sm-0">
            <MUIAutocomplete
              inputLabel="Choose Plan"
              selectedOption={selectedPlan}
              setSelectedOption={setSelectedPlan}
              optionsList={plansList}
              autoComplete="new-password"
              name="plan_title"
            />
          </div>
          <div className="col-lg-3 col-md-3 col-sm-12 mb-2 mb-sm-0">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Subscription Mode
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="subscription_mode"
                required
                value={filterState.subscription_mode}
                label="Subscription Mode"
                onChange={handleChange}
              >
                <MenuItem value="sandBox">SandBox</MenuItem>
                <MenuItem value="live">Live</MenuItem>
                <MenuItem value="all">All</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-12 mb-2 mb-sm-0">
            <div className="text-end mt-2">
              <button
                className="small-contained-button"
                onClick={searchFunction}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          {isLoadingSection ? (
            <CircularProgress
              className={classes.loadingSection}
              color="primary"
            />
          ) : (
            <div className="col-12">
              <CustomMUITable
                TABLE_HEAD={TABLE_HEAD}
                data={subscriptionsData}
                className="card-with-background"
                custom_pagination={{
                  total_count: totalCount,
                  rows_per_page: rowsPerPage,
                  page: page,
                  handleChangePage: handleChangePage,
                  onRowsPerPageChange: handleChangeRowsPerPage,
                }}
                custom_search={{
                  searchText: searchText,
                  setSearchText: setSearchText,
                  handleSubmit: searchFunction,
                }}
                pageCount={pageCount}
                totalPages={totalPages}
                handleChangePages={handleChangePages}
                pagePagination={true}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
}
