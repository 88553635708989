import { CircularProgress, Tooltip } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { member_for_subteams } from "src/DAL/salesTeam/SalesTeam";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import CustomConfirmation from "src/components/menuIcons/CustomConfirmation";
import { makeStyles } from "@mui/styles";
import { s3baseUrl } from "src/config/config";
import { useSnackbar } from "notistack";
import { TIME_ZONE_CONVERSION, dd_date_format } from "src/utils/constants";
import { useContentSetting } from "src/Hooks/ContentSettingState";
import { useNavigate } from "react-router-dom";
import MessageIcon from "@mui/icons-material/Message";
import { useChat } from "src/Hooks/ChatContext/ChatContext";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const TIME_ZONE_CONVERSION_new = (
  date,
  format,
  from_time_zone,
  to_time_zone
) => {
  const moment = require("moment-timezone");

  // Parse the input date using moment.tz to handle both time zones
  let momentObj = moment.tz(date, from_time_zone);

  // Convert to the target time zone and format the date
  let final_date = momentObj.tz(to_time_zone).format(format);

  return final_date;
};
const MembersForSubTeam = () => {
  const classes = useStyles();
  const { handleAddChat, onlineOfflineData } = useChat();
  const { userInfo, adminTimeZone } = useContentSetting();
  const { enqueueSnackbar } = useSnackbar();
  const { isAccess } = useContentSetting();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [teamList, SetTeamList] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState("");
  const [searchText, setSearchText] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(100);

  const [page, setPage] = useState(0);
  const handleSendMessage = async (id) => {
    handleAddChat(id);
  };
  const get_start_date_end_time = (date) => {
    return TIME_ZONE_CONVERSION_new(
      date,
      "DD-MM-YYYY hh:mm A",
      adminTimeZone,
      userInfo.time_zone
    );
  };
  console.log(adminTimeZone, userInfo.time_zone);

  const getTeamListing = async () => {
    setIsLoading(true);

    const result = await member_for_subteams(page, rowsPerPage, searchText);
    if (result.code == 200) {
      const team_member = result.event_subscriber.map((item) => {
        let name = item.first_name + " " + item.last_name;

        return {
          ...item,
          name,
          email: item.email,
          registerDate: item.createdAt ? dd_date_format(item.createdAt) : "N/A",
          last_login_activity: item.last_login_activity
            ? get_start_date_end_time(item.last_login_activity)
            : "N/A",
          table_avatar: {
            src: s3baseUrl + item?.profile_image,
            alt: item.first_name,
          },
          status: item.status,
        };
      });
      SetTeamList(team_member);
      setTotalPages(result.total_pages);
      setTotalCount(result.total_count);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };
  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPageCount(1);
    setPage(0);
  };

  const searchFunction = (e) => {
    if (e) {
      e.preventDefault();
    }
    setPage(0);
    setPageCount(1);
    getTeamListing();
  };

  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    {
      id: "action",
      label: "Action",
      type: "action",
    },
    { id: "table_avatar", label: "Profile", type: "thumbnail" },
    {
      id: "name",
      label: "Name",
      renderData: (row) => {
        return (
          <div className="d-flex">
            {isAccess.is_chat_allowed && (
              <Tooltip title={"Chat"} arrow>
                <MessageIcon
                  className="anchor-style"
                  onClick={() => handleSendMessage(row._id)}
                />
              </Tooltip>
            )}
            <div
              className={`ms-2 ${isAccess.view_profile ? "anchor-style" : ""}`}
              onClick={(e) => {
                e.preventDefault();
                if (isAccess.view_profile) {
                  handleDetail(row);
                }
              }}
            >
              {row.name}
            </div>
          </div>
        );
      },
    },
    { id: "email", label: "Email" },
    { id: "registerDate", label: "Registration  Date" },
    {
      id: "last_login_activity",
      label: "Last Login Activity",
      alignRight: false,
    },

    { id: "status", label: "Status", type: "row_status" },
  ];
  if (!isAccess.view_profile) {
    TABLE_HEAD.splice(1, 1);
  }
  const handleDetail = (value) => {
    navigate(`/members/profile/${value._id}`, {
      state: value,
    });
  };
  const MENU_OPTIONS = [];
  if (isAccess.view_profile) {
    MENU_OPTIONS.push({
      label: "View Profile",
      icon: "eva:eye-fill",
      handleClick: handleDetail,
    });
  }
  useEffect(() => {
    getTeamListing();
  }, [rowsPerPage, page]);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-8">
            <h2>Members</h2>
          </div>
        </div>

        <div className="row">
          <div className="col-12 mt-1 ">
            <CustomMUITable
              TABLE_HEAD={TABLE_HEAD}
              data={teamList}
              MENU_OPTIONS={MENU_OPTIONS}
              className="card-with-background"
              custom_pagination={{
                total_count: totalCount,
                rows_per_page: rowsPerPage,
                page: page,
                handleChangePage: handleChangePage,
                onRowsPerPageChange: handleChangeRowsPerPage,
              }}
              custom_search={{
                searchText: searchText,
                setSearchText: setSearchText,
                handleSubmit: searchFunction,
              }}
              pagePagination={true}
              pageCount={pageCount}
              totalPages={totalPages}
              handleChangePages={handleChangePages}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default MembersForSubTeam;
