import * as React from "react";
import Popover from "@mui/material/Popover";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

export default function GeneralPopOver({
  handleClose,
  anchorEl,
  component,
  popover_id,
  open,
  title,
}) {
  return (
    <div>
      <Popover
        id={popover_id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{ heigth: 100 }}
      >
        <div className="sticky-top card">
          <p className="heading_programs mb-0">{title}</p>
          <span className="icon-container" onClick={() => handleClose()}>
            <CancelOutlinedIcon fontSize="small" />
          </span>
        </div>
        {component}
      </Popover>
    </div>
  );
}
