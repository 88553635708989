import React, { useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
// material
import { Avatar, CircularProgress } from "@mui/material";
// components
import Label from "../components/Label";

//
// import USERLIST from "../_mocks_/user";
import DeletedModal from "src/components/modal/DeleteModal";
import { AllMemberListing, MemberListing } from "src/DAL/member/Member";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { baseUri, s3baseUrl } from "src/config/config";
import { htmlDecode } from "src/utils/convertHtml";
import { goalSaveAndCloseListApiV1 } from "src/DAL/MemberGoalStatement/MemberGoalStatement";
import moment from "moment";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { useEffect } from "react";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function MemberGoalStatementSaveAndClose() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const [UserId, setUserId] = useState(false);
  const [userList, setUserList] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState("");
  const [searchText, setSearchText] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(0);

  const Capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const handleEdit = (value) => {
    navigate(`/member-responded-goalstatements/${value.id}`, {
      state: value,
    });
  };
  const navigateByName = (value) => {
    navigate(`/member-responded-goalstatements/${value.id}`, {
      state: value,
    });
  };

  const getMemberListing = async (text) => {
    try {
      setIsLoading(true);
      const result = await goalSaveAndCloseListApiV1(page, rowsPerPage, text);
      if (result.code === 200) {
        const data = result.member_array.map((member) => {
          return {
            id: member._id,
            name: member.first_name + " " + member.last_name,
            email: member.email,
            status: member.status,
            programs: member.program,
            avatarUrl: member.profile_image,
            saveAndCloseDate: member.save_and_close_date,
            goal: member.goal_statement_status,
            goalStatus: member.goal_statement_completed_status,
            object: member,
          };
        });
        setUserList(data);
        setIsLoading(false);
        setTotalPages(result.total_pages);
        setTotalCount(result.total_count);
      } else {
        // enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
      }
    } catch (error) {}
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };
  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPageCount(1);
    setPage(0);
  };

  const MENU_OPTIONS = [
    {
      label: "Detail",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
  ];
  // ----------------------------------------------------------------------

  const TABLE_HEAD = [
    { id: "action", label: "Action", alignRight: false, type: "action" },
    { id: "number", label: "#", alignRight: false, type: "number" },
    {
      id: "name",
      label: "Name",
      renderData: (row) => {
        return (
          <>
            <span
              className="cursor-pointer"
              onClick={() => navigateByName(row)}
            >
              {Capitalize(htmlDecode(row.name))}
            </span>
          </>
        );
      },
    },
    {
      id: "profile",
      label: "Profile",
      renderData: (row) => {
        return (
          <>
            <Avatar src={s3baseUrl + row.avatarUrl} alt={row.name} />
          </>
        );
      },
    },
    { id: "email", label: "Email", alignRight: false },
    {
      id: "goalStatus",
      label: "Goal Statement Status",
      renderData: (row) => {
        return (
          <>
            <Label
              variant="ghost"
              color={(row.goalStatus === false && "error") || "success"}
            >
              {row.goalStatus == true ? "Completed" : "Incomplete"}
            </Label>
          </>
        );
      },
    },
    {
      id: "status",
      label: "Status",
      renderData: (row) => {
        return (
          <>
            <Label
              variant="ghost"
              color={(row.status === false && "error") || "success"}
            >
              {row.status == true ? "Active" : "Inactive"}
            </Label>
          </>
        );
      },
    },
    {
      id: "saveCloseDate",
      label: "Responded",
      renderData: (row) => {
        return (
          <>
            <span>
              {row.saveAndCloseDate
                ? moment(row.saveAndCloseDate).format("YYYY-MM-DD")
                : "N/A"}
            </span>
          </>
        );
      },
    },
    {
      id: "goal",
      label: "Goal",
      renderData: (row) => {
        return (
          <>
            <Label
              variant="ghost"
              color={(row.goal === false && "error") || "success"}
            >
              {row.goal === true ? "Unlock" : "Lock"}
            </Label>
          </>
        );
      },
    },
  ];

  // ----------------------------------------------------------------------
  const searchFunction = () => {
    setPage(0);
    setPageCount(1);
    localStorage.setItem("responded_search", searchText);
    getMemberListing(searchText);
  };

  useEffect(() => {
    let search_text = localStorage.getItem("responded_search");
    if (search_text) {
      setSearchText(search_text);
      getMemberListing(search_text);
    } else {
      setSearchText("");
      getMemberListing("");
    }
  }, [page, rowsPerPage]);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <>
      <DeletedModal title="member" id={UserId} open={open} setOpen={setOpen} />

      <div className="container">
        <div className="row mb-3">
          <div className="col-12">
            <h2>Member Goal Statement</h2>
          </div>
        </div>

        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={userList}
          MENU_OPTIONS={MENU_OPTIONS}
          className="card-with-background"
          custom_pagination={{
            total_count: totalCount,
            rows_per_page: rowsPerPage,
            page: page,
            handleChangePage: handleChangePage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          custom_search={{
            searchText: searchText,
            setSearchText: setSearchText,
            handleSubmit: searchFunction,
          }}
          pagePagination={true}
          pageCount={pageCount}
          totalPages={totalPages}
          handleChangePages={handleChangePages}
        />
      </div>
    </>
  );
}
