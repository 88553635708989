import React from "react";
function NoticeBoard({ notice_board }) {
  return (
    <>
      <div className="profile-cards notice-board p-3 mb-3">
        <div
          className=""
          dangerouslySetInnerHTML={{
            __html: notice_board,
          }}
        ></div>
      </div>
    </>
  );
}

export default NoticeBoard;
