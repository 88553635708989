import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Container,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RecordNotFound from "src/components/RecordNotFound";
import { quarters_list_api } from "src/DAL/QuartersList/QuartersList";
import { makeStyles } from "@mui/styles";
import GeneralQuestionsList from "../GeneralQuestions/GeneralQuestionsList";
import { useLocation, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
  fonts: {
    fontSize: "13px",
    fontWeight: "500",
  },
}));

export default function QuarterQuestions() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { search } = useLocation();
  const [quartersList, setQuartersList] = useState([]);
  const [totlePages, setTotlePages] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [loadMorePath, setLoadMorePath] = useState("");
  const created_for_id = new URLSearchParams(search).get("created_for_id");
  const [loadMore, setLoadMore] = useState(
    "api/quarter/list?page=0&limit=50&search_text="
  );

  const handleOpen = (item, check) => {
    if (check && item._id === created_for_id) {
      navigate(`/quarter-questions`);
    } else {
      navigate(`/quarter-questions?created_for_id=${item._id}`);
    }
  };

  const loadMoreData = () => {
    setIsLoadingMore(true);
    setLoadMore(loadMorePath);
  };

  const getQuartersData = async () => {
    const result = await quarters_list_api(loadMore);
    if (result.code == 200) {
      let newArray = [];
      if (pageNumber === 0) {
        newArray = result.quarter;
        if (newArray.length > 0) {
          handleOpen(newArray[0]);
        }
        setPageNumber(1);
      } else {
        newArray = quartersList?.concat(result.quarter);
        setPageNumber((pageNumber) => pageNumber + 1);
      }

      setQuartersList(newArray);
      setTotlePages(result.total_pages);
      setLoadMorePath(result.load_more_url);
      setIsLoadingMore(false);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getQuartersData();
  }, [loadMore]);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="ps-4">
        <h2 className="portal-color">Quarter Questions</h2>
      </div>
      {quartersList.length > 0 ? (
        quartersList.map((item, index) => {
          return (
            <Container className="mt-2">
              <Accordion expanded={Boolean(item._id === created_for_id)}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className="icon-color" />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  className="svg-color"
                  onClick={() => {
                    handleOpen(item, true);
                  }}
                >
                  <div className="delegate-info">
                    <span style={{ color: "white" }}>{item.title}</span>
                  </div>
                </AccordionSummary>

                <AccordionDetails
                  style={{
                    backgroundColor: "#121826",
                    border: "2px solid #1D2537",
                    borderBottomLeftRadius: "20px",
                    borderBottomRightRadius: "20px",
                  }}
                >
                  {item._id === created_for_id && (
                    <div className="color-text-primary">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.detailed_description,
                        }}
                        className="mt-3"
                      ></div>
                      <GeneralQuestionsList
                        created_for="quarter"
                        remove_back_button={true}
                      />
                    </div>
                  )}
                </AccordionDetails>
              </Accordion>
            </Container>
          );
        })
      ) : (
        <RecordNotFound title={"Questions"} />
      )}
      {totlePages >= pageNumber ? (
        <div className="col-12 text-center">
          <button
            className="small-contained-button mt-3"
            onClick={loadMoreData}
            disabled={isLoadingMore}
          >
            {isLoadingMore ? "Loading..." : "Load More"}
          </button>
        </div>
      ) : (
        ""
      )}
    </>
  );
}
