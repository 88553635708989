import React, { useEffect } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Container,
  Grid,
  TextareaAutosize,
  IconButton,
  CircularProgress,
  Tooltip,
  TextField,
  Avatar,
  Card,
  CardHeader,
  Box,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import roundFilterList from "@iconify/icons-ic/round-filter-list";
import { Icon } from "@iconify/react";
import Chart from "react-apexcharts";
import CircleIcon from "@mui/icons-material/Circle";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import { useState } from "react";
import { get_past_activities_data } from "src/DAL/Tracking/Tracking";
import { useNavigate } from "react-router-dom";
import RecordNotFound from "../RecordNotFound";
import moment from "moment";
import { useSnackbar } from "notistack";
import CompleteUncompleteChart from "./CompleteUncompleteChart";
import { el } from "date-fns/locale";
import WhatsappPopUpModel from "../GeneralComponents/WhatsappPopUpModel";
import { s3baseUrl } from "src/config/config";
import GeneralGraph from "./GeneralGraph";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
  fonts: {
    fontSize: "13px",
    fontWeight: "500",
  },
  root: {
    color: "#f6bd4b",
    height: 1,
    // color for the part that is not covered
  },
  rail: {
    color: "#888", // color for the part that is covered
    height: 1,
  },
}));
const marks = [
  {
    value: 0,
    label: <span style={{ color: "gray" }}>0</span>,
  },

  {
    value: 10,
    label: "10",
  },
];
const filterDate = {
  from_date: null,
  to_date: null,
};

const DynamiteAccountability = ({
  totlePages,
  pageNumber,
  loadMoreData,
  isLoadingMore,
  isLoading,
  Activities,
  handleToggle,
  isloadingdetail,
  detailData,
}) => {
  // const [isLoading, setIsLoading] = React.useState(true);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [popupData, setPopupData] = useState({});
  const [popUpName, setPopUpName] = useState("");
  const [spacificDate, setSpacificDate] = useState([]);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const openDrawr = (name, data, array) => {
    setPopUpName(name);
    setSpacificDate(array);
    setPopupData(data);
    setOpen(true);
  };

  return (
    <div className="row mt-3">
      <WhatsappPopUpModel
        open={open}
        setOpen={setOpen}
        title={popUpName + " Performance"}
        show_date_and_income={true}
        componentToPassDown={
          <GeneralGraph
            popupData={popupData}
            name={popUpName}
            spacificDate={spacificDate}
          />
        }
      />
      {Activities.length > 0 ? (
        Activities.map((item, index) => {
          return (
            <Container className="mt-2">
              <Accordion expanded={Boolean(item.isExpand)}>
                <AccordionSummary
                  onClick={() => {
                    handleToggle(item, index);
                  }}
                  expandIcon={<ExpandMoreIcon className="icon-color" />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  className="svg-color"
                >
                  <div
                    className="delegate-info"
                    style={{ width: "100%", justifyContent: "space-between" }}
                  >
                    <div className="delegate-info">
                      <Avatar
                        className="avatar-adjust"
                        sx={{ width: 30, height: 30 }}
                        alt={"talha"}
                        src={s3baseUrl + item?.image?.thumbnail_1}
                      />
                      <span style={{ color: "white" }}>
                        {item.first_name + " " + item.last_name + " "}
                        <span className="text-muted">
                          {"(" + item.email + ")"}
                        </span>{" "}
                      </span>
                    </div>
                    <span>
                      <p
                        className="text-end mb-0 pe-4"
                        style={{ color: "rgb(246, 189, 75)" }}
                      >
                        <span style={{ color: "white" }} className="style">
                          {item.dynamite_streak_performance_count}
                        </span>
                      </p>
                    </span>
                  </div>
                </AccordionSummary>

                <AccordionDetails
                  style={{
                    backgroundColor: "#121826",
                    border: "2px solid #1D2537",
                    borderBottomLeftRadius: "20px",
                    borderBottomRightRadius: "20px",
                  }}
                >
                  {isloadingdetail ? (
                    <CircularProgress
                      className={classes.loading}
                      color="primary"
                    />
                  ) : (
                    <div className="container mt-3">
                      <div className="row">
                        <div className="col-lg-12 mt-2">
                          <h4 className="main-heading">
                            {` Streak Performance Analysis form ${detailData.start_date} to
                            ${detailData.end_date}`}
                          </h4>
                        </div>
                        <div
                          className="col-lg-6 mt-3 d-flex justify-content-between font-size-14"
                          style={{ color: "white" }}
                        >
                          <Tooltip title="View Attitude Performance in Graphical Form">
                            <span
                              className="pointer"
                              onClick={() =>
                                openDrawr(
                                  "Attitude ",
                                  detailData,
                                  detailData.attitude_performance_rate_array
                                )
                              }
                            >
                              {" "}
                              Attitude Average:{" "}
                            </span>
                          </Tooltip>
                          <span className="text-muted me-5">
                            {detailData.attitude_performance_rate_avg.toFixed(
                              2
                            )}
                          </span>
                        </div>
                        <div
                          className="col-lg-4 mt-3 d-flex justify-content-between font-size-14"
                          style={{ color: "white" }}
                        >
                          {" "}
                          <Tooltip title="View Focus Performance in Graphical Form">
                            <span
                              className="pointer"
                              onClick={() =>
                                openDrawr(
                                  "Focus",
                                  detailData,
                                  detailData.focus_performance_rate_array
                                )
                              }
                            >
                              {" "}
                              Focus Average:{" "}
                            </span>
                          </Tooltip>
                          <span className="text-muted">
                            {detailData.focus_performance_rate_avg.toFixed(2)}{" "}
                          </span>
                        </div>
                        <div
                          className="col-lg-6 mt-3 d-flex justify-content-between font-size-14"
                          style={{ color: "white" }}
                        >
                          <Tooltip title="View Desires Performance in Graphical Form">
                            <span
                              className="pointer"
                              onClick={() =>
                                openDrawr(
                                  "Desires",
                                  detailData,
                                  detailData.desire_performance_rate_array
                                )
                              }
                            >
                              Desires Average:{" "}
                            </span>
                          </Tooltip>
                          <span className="text-muted me-5">
                            {detailData.desire_performance_rate_avg.toFixed(2)}{" "}
                          </span>
                        </div>{" "}
                        <div
                          className="col-lg-4 mt-3 d-flex justify-content-between font-size-14"
                          style={{ color: "white" }}
                        >
                          <Tooltip title="View Discipline Performance in Graphical Form">
                            <span
                              className="pointer"
                              onClick={() =>
                                openDrawr(
                                  "Discipline",
                                  detailData,
                                  detailData.discipline_performance_rate_array
                                )
                              }
                            >
                              {" "}
                              Discipline Average:{" "}
                            </span>
                          </Tooltip>
                          <span className="text-muted">
                            {detailData.discipline_performance_rate_avg.toFixed(
                              2
                            )}
                          </span>
                        </div>
                        <div
                          className="col-lg-6 mt-3 d-flex justify-content-between font-size-14"
                          style={{ color: "white" }}
                        >
                          <Tooltip title="View Win Performance in Graphical Form">
                            <span
                              className="pointer"
                              onClick={() =>
                                openDrawr(
                                  "Win",
                                  detailData,
                                  detailData.win_note_performance_rate_array
                                )
                              }
                            >
                              {" "}
                              Win Average:{" "}
                            </span>
                          </Tooltip>
                          <span className="text-muted me-5">
                            {detailData.win_note_performance_rate_avg.toFixed(
                              2
                            )}
                          </span>
                        </div>
                        <div
                          className="col-lg-4 mt-3 mb-4 d-flex justify-content-between font-size-14"
                          style={{ color: "white" }}
                        >
                          <span> Completed Intention : </span>
                          <span className="text-muted">
                            {detailData.complete_accountability_avg.toFixed(2)}%
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                </AccordionDetails>
              </Accordion>
            </Container>
          );
        })
      ) : (
        <RecordNotFound title={"Activities"} />
      )}
      {totlePages > pageNumber ? (
        <div className="col-12 text-center">
          <button
            className="small-contained-button mt-3"
            onClick={loadMoreData}
            disabled={isLoadingMore}
          >
            {isLoadingMore ? "Loading..." : "Load More"}
          </button>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default DynamiteAccountability;
