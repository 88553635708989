import React, { useState } from "react";
import Chip from "@mui/material/Chip";
import moment from "moment";
import { get_root_value } from "src/utils/domUtils";
import { show_proper_words } from "src/utils/constants";
import GeneralPopUpModel from "./GeneralPopUpModel";
import SaveFilter from "./SaveFilter";

const FilteredChip = ({
  data,
  tempState,
  EMPTY_FILTER,
  ALTER_FILTER,
  REMOVE_ITEMS,
  onDeleteChip,
  onClear,
  tabName,
  handleSubmitted,
}) => {
  const [changeStatus, setChangeStatus] = useState(false);
  const handleSaveFilter = (value) => {
    setChangeStatus(true);
  };
  const hanlde_delete = (item) => {
    let value = item.value;
    let key = item.key;
    let item_type = typeof data[key];
    // console.log(
    //   value,
    //   ": key_value",
    //   item_type,
    //   ": key_type",
    //   key,
    //   ": keyName",
    //   Array.isArray(data[key]),
    //   ": Array.isArray(data[key])"
    // );

    let temp_state = { ...tempState };
    if (item_type == "object" && Array.isArray(data[key])) {
      let new_array = data[key].filter((array) => array.chip_value !== value);
      temp_state[key] = new_array;
    } else {
      if (ALTER_FILTER) {
        let alter_value = ALTER_FILTER[key];
        if (alter_value !== undefined) {
          temp_state[key] = ALTER_FILTER[key];
        } else {
          temp_state[key] = EMPTY_FILTER[key];
        }
      } else {
        temp_state[key] = EMPTY_FILTER[key];
      }
    }
    onDeleteChip(temp_state, item);
  };

  let STATUS = {
    true: "Active",
    false: "Inactive",
  };

  let chips_array = [];
  Object.keys(data).map((keyName, i) => {
    let find_remove = REMOVE_ITEMS?.find((item) => item === keyName);
    if (!find_remove) {
      let key_value = data[keyName];
      let key_type = typeof key_value;

      // console.log(
      //   key_value,
      //   ": key_value",
      //   key_type,
      //   ": key_type",
      //   keyName,
      //   ": keyName"
      // );

      const push_date = () => {
        chips_array.push({
          value: key_value,
          key: keyName,
          label:
            show_proper_words(keyName) +
            " " +
            moment(key_value).format("DD-MM-YYYYY"),
        });
      };

      if (keyName == "status" && key_value !== "") {
        chips_array.push({
          key: keyName,
          value: key_value,
          label: STATUS[key_value],
        });
      } else if (key_value && key_value !== "0") {
        switch (key_type) {
          case "string":
            if (moment(key_value).isValid()) {
              push_date();
            } else {
              chips_array.push({
                key: keyName,
                value: key_value,
                label: show_proper_words(key_value),
              });
            }
            break;
          case "number":
            chips_array.push({
              key: keyName,
              value: key_value,
              label: key_value,
            });
            break;
          case "object":
            if (key_value) {
              if (Array.isArray(key_value)) {
                key_value.map((item) => {
                  chips_array.push({
                    key: keyName,
                    value: item.chip_value,
                    label: item.chip_label,
                  });
                });
              } else if (moment(key_value).isValid()) {
                if (key_value.chip_value) {
                  chips_array.push({
                    key: keyName,
                    value: key_value.chip_value,
                    label: key_value.chip_label,
                  });
                } else {
                  push_date();
                }
              } else {
                chips_array.push({
                  key: keyName,
                  value: key_value.chip_value,
                  label: key_value.chip_label,
                });
              }
            }
            break;
          default:
        }
      }
    }
  });

  return (
    <>
      {chips_array.length > 0 && (
        <div className="col-lg-12 col-sm-12 mb-2">
          <b className="me-3 pt-1 ms-2">Filtered By:</b>
          {chips_array.map((item, index) => {
            return (
              <>
                {item.label && (
                  <Chip
                    key={index}
                    label={item.label}
                    className="mb-2 mt-1 me-1"
                    onDelete={() => hanlde_delete(item)}
                    sx={{
                      color: "white",
                    }}
                  />
                )}
              </>
            );
          })}
          {tabName && (
            <span
              className="anchor-style save-button ms-2 pt-1"
              onClick={handleSaveFilter}
              style={{
                color: get_root_value("--portal-theme-primary"),
                padding: "10px 15px 5px 15px",
                borderRadius: "12px",
                display: "inline-block",
              }}
            >
              Save Filter
            </span>
          )}
          <span
            className="anchor-style ms-2 pt-1"
            onClick={onClear}
            style={{
              color: get_root_value("--portal-theme-primary"),
            }}
          >
            Clear All
          </span>{" "}
          <GeneralPopUpModel
            open={changeStatus}
            setOpen={setChangeStatus}
            title={"Save Filter"}
            componentToPassDown={
              <SaveFilter
                tabName={tabName}
                filter_object={tempState}
                setOpen={setChangeStatus}
                handleSubmitted={handleSubmitted}
              />
            }
          />
        </div>
      )}
    </>
  );
};

export default FilteredChip;
