import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useEffect, useState } from "react";
import { get_members_and_delegates_api } from "src/DAL/Community/Community";
import MUIAutocomplete from "src/components/GeneralComponents/MUIAutocomplete";

const SearchFilterFeeds = ({
  searchFeeds,
  setSearchFeeds,
  handleChangeSearch,
  list_type,
  userType,
}) => {
  const [UsersList, setUsersList] = useState([]);
  const [searchText, setSearchText] = useState("");

  const handleChangeOthers = (event, name) => {
    setSearchFeeds((values) => ({ ...values, [name]: event }));
    // setIschangeFilter(true);
  };
  const get_members_and_delegates = async (search = "") => {
    let postData = {
      search_text: search,
      list_type: list_type,
      community_levels: userType == "all" ? [] : [userType],
    };

    const result = await get_members_and_delegates_api(postData);
    if (result.code === 200) {
      let users = result.users;
      users = result.users.map((user) => {
        let user_name =
          user.first_name + " " + user.last_name + " " + "(" + user.email + ")";
        return { ...user, user_name };
      });
      setUsersList(users);
    } else {
      setFilteredUsers([]);
    }
  };
  console.log(searchFeeds, "UsersListUsersListUsersList");

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (searchText.length > 0) {
        get_members_and_delegates(searchText.trim());
      } else {
        get_members_and_delegates(searchText);
      }
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [searchText]);
  useEffect(() => {
    setSearchFeeds((values) => ({ ...values, ["feed_action_type"]: "all" }));
    get_members_and_delegates(searchText);
  }, [userType]);
  useEffect(() => {
    setSearchFeeds((values) => ({ ...values, ["feed_action_by"]: null }));
    get_members_and_delegates(searchText);
  }, [searchFeeds.feed_action_type]);
  return (
    <>
      <form>
        <div>
          <FormControl fullWidth size="small">
            <InputLabel id="demo-simple-select-label">Feed Type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="feed_action_type"
              value={searchFeeds.feed_action_type}
              label="Feed Type"
              onChange={handleChangeSearch}
            >
              <MenuItem value={"all"}>All</MenuItem>
              <MenuItem value={"own"}>My Feeds</MenuItem>
              <MenuItem value={"other"}>Others Feeds</MenuItem>
            </Select>
          </FormControl>
        </div>
        {searchFeeds.feed_action_type == "other" && (
          <div className="mt-3 filter">
            <MUIAutocomplete
              inputLabel="Select Member"
              size="small"
              addBorder={true}
              selectedOption={searchFeeds.feed_action_by}
              setSelectedOption={(e) => {
                handleChangeOthers(e, "feed_action_by");
              }}
              optionsList={UsersList}
              name="user_name"
              setCustomSearch={setSearchText}
            />
          </div>
        )}
      </form>
    </>
  );
};

export default SearchFilterFeeds;
