import {
  Avatar,
  CircularProgress,
  InputAdornment,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useCallback, useEffect, useRef } from "react";
import { useState } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import CustomPopover from "src/components/GeneralComponents/CustomPopover";
import RecordNotFoundSalesLeads from "src/components/RecordNotFoundSalesLeads";
import AddTaskIcon from "@mui/icons-material/AddTask";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  lead_stages_list_api,
  update_lead_stage_api,
} from "src/DAL/LeadsCenter/lead_stages";
import { s3baseUrl } from "src/config/config";
import { useSnackbar } from "notistack";
import { remove_uplicate_users } from "src/utils/constants";
import HistoryIcon from "@mui/icons-material/History";

export default function LeadCenterSIngleItem(props) {
  const {
    lead,
    setLeadStages,
    handleAgreeDelete,
    filterData,
    allLeadStatus,
    handleOpenHistory,
    rowsPerPage,
    handleClickDots,
    handle_update_data,
    handle_view_profile,
    openUserMenu,
  } = props;

  const [selectedItem, setSelectedItem] = useState({});
  const [search, setSearch] = useState("");
  const [isUpdate, setIsUpdate] = useState(false);
  const [filterApplied, setFilterApplied] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const observer = useRef();

  const lastBookElementRef = useCallback(
    (node) => {
      if (isLoadingMore) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && totalPages > pageNumber) {
          loadMoreData();
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoadingMore, totalPages, pageNumber, observer]
  );

  const handleEdit = () => {
    setIsUpdate(true);
  };

  const MENU_OPTIONS_delete = [
    {
      label: "Edit",
      icon: "ant-design:delete-twotone",
      handleClick: handleEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  const handleUpdate = (e) => {
    e.preventDefault();
    setLeadStages((prev) =>
      prev.map((item) => {
        if (selectedItem._id === item._id) {
          return selectedItem;
        }
        return item;
      })
    );
    setIsUpdate(false);
    let postData = {
      title: selectedItem.title,
    };
    update_lead_stage_api(postData, selectedItem._id);
  };

  const handleChange = (e) => {
    setSelectedItem({ ...selectedItem, title: e.target.value });
  };

  const handleChangeSearch = async (e) => {
    setSelectedItem((old) => ({ ...old, is_loading: true }));
    const { value } = e.target;
    setSearch(value);
    let formData = {
      ...filterData,
      search_text: value,
      page: 0,
      limit: rowsPerPage,
    };
    handle_get_users(formData, "search");
  };

  const handle_get_users = async (formData, type) => {
    formData = handle_update_data(formData);
    formData.list_type = "stage_users";
    if (selectedItem?._id) {
      formData.stage_id = selectedItem._id;
    }

    const result = await lead_stages_list_api(formData);
    if (result.code === 200) {
      setFilterApplied(true);
      setTotalPages(result.total_pages);
      setTotalCount(result.total_member_count);
      if (type === "search") {
        setPageNumber(1);
        setLeadStages((old) =>
          old.map((item) => {
            if (item._id === formData.stage_id) {
              let stage = result.stages[0];
              return stage;
            } else {
              return item;
            }
          })
        );
        return;
      }
      setPageNumber((prev) => prev + 1);
      setLeadStages((old) =>
        old.map((item) => {
          if (item._id === formData.stage_id) {
            let users = result.stages[0].users;
            return { ...item, users: [...item.users, ...users] };
          } else {
            return item;
          }
        })
      );
      setIsLoadingMore(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingMore(false);
    }
  };

  const loadMoreData = () => {
    setIsLoadingMore(true);
    let postData = { ...filterData };
    postData.page = pageNumber;
    postData.limit = rowsPerPage;
    handle_get_users(postData);
  };

  useEffect(() => {
    let users = remove_uplicate_users(lead.users);
    setTotalCount(lead.total_member_count);
    setTotalPages(lead.total_pages);
    setSelectedItem({ ...lead, users });
    setSelectedItem((old) => ({ ...old, is_loading: false }));
    let applied_filter = localStorage.getItem("leads_centre_searches");
    if (applied_filter) {
      applied_filter = JSON.parse(applied_filter);
      if (
        applied_filter &&
        applied_filter != "null" &&
        applied_filter[lead._id] &&
        !filterApplied
      ) {
        setSelectedItem((old) => ({ ...old, is_loading: true }));
        setSearch(applied_filter[lead._id]);
        let formData = {
          ...filterData,
          search_text: applied_filter[lead._id],
          page: 0,
          stage_id: lead._id,
          limit: rowsPerPage,
        };
        handle_get_users(formData, "search");
      }
    }
  }, [lead]);

  useEffect(() => {
    let applied_filter = localStorage.getItem("leads_centre_searches");
    let filter_data = {};
    if (applied_filter && applied_filter != "null") {
      applied_filter = JSON.parse(applied_filter);
      filter_data = applied_filter;
    }
    filter_data[lead._id] = search;
    localStorage.setItem("leads_centre_searches", JSON.stringify(filter_data));
  }, [search]);

  return (
    <>
      <div
        className={`main-member-container ms-2 ${
          isUpdate ? "selected-card" : ""
        }`}
      >
        <div className="heading-container mt-2">
          {isUpdate ? (
            <form onSubmit={handleUpdate} className="w-100">
              <TextField
                id="outlined-basic"
                label=""
                autoFocus
                variant="outlined"
                fullWidth
                required
                className="mb-2"
                placeholder="Update Stage"
                size="small"
                InputProps={{
                  style: {
                    fontSize: "13px",
                    paddingLeft: "4px",
                    paddingRight: "10px",
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <span className="diary-icon-add" onClick={handleUpdate}>
                        <Tooltip title={"click here to update "}>
                          <AddTaskIcon />
                        </Tooltip>
                      </span>
                    </InputAdornment>
                  ),
                  inputProps: {
                    readOnly: false,
                    min: 1,
                  },
                }}
                value={selectedItem.title}
                onChange={handleChange}
              />
            </form>
          ) : (
            <h4>{selectedItem.title}</h4>
          )}
          {!isUpdate && (
            <div className="d-flex" style={{ alignItems: "flex-start" }}>
              <p>{totalCount}</p>

              <CustomPopover menu={MENU_OPTIONS_delete} data={lead} />
            </div>
          )}
        </div>
        <div className="w-100 mb-2">
          <input
            type="text"
            className="leads-search-input"
            placeholder="Search user"
            value={search}
            onChange={handleChangeSearch}
          />
        </div>
        <Droppable key={selectedItem._id} droppableId={selectedItem._id}>
          {(provided) => (
            <div
              style={{
                height: "calc(85vh - 120px)",
                overflowY: openUserMenu ? "hidden" : "auto",
              }}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {selectedItem.is_loading ? (
                <div className="text-center">
                  <CircularProgress color="primary" className="mt-5" />
                </div>
              ) : selectedItem.users?.length > 0 ? (
                selectedItem.users?.map((item, index) => {
                  let find_lead_status = allLeadStatus.find(
                    (status) => status._id === item.lead_status
                  );
                  return (
                    <Draggable
                      key={item._id}
                      draggableId={`${selectedItem._id}-${item._id}`}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          className="member-card-container mb-2"
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <div className="d-flex w-100 align-items-center">
                            <div>
                              <Avatar
                                className="avatar-adjust-new"
                                sx={{ width: 35, height: 35 }}
                                alt={item.first_name[0]}
                                src={s3baseUrl + item.profile_image}
                              />
                            </div>
                            <div className="w-100 overflow-hidden">
                              <div className="w-100 name-container">
                                <div className="d-flex justify-content-between ">
                                  <p
                                    className="mb-0 cursor-pointer"
                                    style={{ fontSize: "15px" }}
                                    onClick={() => handle_view_profile(item)}
                                  >
                                    {item.first_name + " " + item.last_name}
                                  </p>
                                  <MoreVertIcon
                                    style={{
                                      cursor: "pointer",
                                      fontSize: 20,
                                      color: "#637381",
                                    }}
                                    className="pointer custom-popover-icon"
                                    onClick={(e) =>
                                      handleClickDots(e, {
                                        ...item,
                                        stage: selectedItem._id,
                                      })
                                    }
                                  />
                                </div>
                                {find_lead_status && (
                                  <span
                                    className="lead-status-text"
                                    style={{
                                      backgroundColor:
                                        find_lead_status.background_color,
                                      color: find_lead_status.text_color,
                                    }}
                                  >
                                    {find_lead_status.title}
                                  </span>
                                )}
                                {item.lead_status_history?.length > 0 && (
                                  <span
                                    className="ms-2"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => handleOpenHistory(item)}
                                  >
                                    <Tooltip title="View History">
                                      <HistoryIcon />
                                    </Tooltip>
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  );
                })
              ) : (
                <div style={{ width: "296px", height: "100%" }}>
                  <div
                    className="h-100 d-flex"
                    style={{ alignItems: "center", width: "280px" }}
                  >
                    <RecordNotFoundSalesLeads />
                  </div>
                </div>
              )}
              {totalPages > pageNumber && (
                <div className="col-12 text-center mt-3">
                  <span ref={lastBookElementRef} onClick={loadMoreData}>
                    {isLoadingMore ? "Loading..." : "Load More"}
                  </span>
                </div>
              )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
    </>
  );
}
