import React, { useEffect, useState } from "react";
import {
  Button,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { get_root_value } from "src/utils/domUtils";
import { useSnackbar } from "notistack";
import { add_member_from_proggram_event_Api } from "src/DAL/member/Member";
import { MainTerminalProgramListing } from "src/DAL/Program/Programs";
import MUIAutocomplete from "src/components/GeneralComponents/MUIAutocomplete";

export default function MemberImportFromProgram({
  onCloseDrawer,
  selectedObject,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [programValue, setProgramValue] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [filterData, setFilterData] = useState({
    importFrom: "group",
    access_type: "free",
  });
  const programmeListData = async () => {
    let postData = {
      filter_array: [filterData.importFrom],
    };

    let result = await MainTerminalProgramListing(postData);
    if (result.code == 200) {
      console.log(result, "resultresultresult");
      setProgramValue(
        filterData.importFrom == "group" ? result.group_array : result.programs
      );
    }
  };

  const handleSubmit = async () => {
    if (!selectedProgram) {
      enqueueSnackbar("Please choose a program", { variant: "error" });
      return;
    }
    setLoading(true);
    let postData = {
      event_id: selectedObject._id,
      access_type: filterData.access_type,
    };
    if (filterData.importFrom == "group") {
      postData.group_id = selectedProgram._id;
    } else {
      postData.program_id = selectedProgram._id;
    }
    let result = await add_member_from_proggram_event_Api(postData);
    if (result.code == 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setLoading(false);
      onCloseDrawer();
    } else {
      setLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleChaneOthers = (name, value) => {
    setFilterData((values) => ({ ...values, [name]: value }));
    setSelectedProgram(null);
  };
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setFilterData((values) => ({ ...values, [name]: value }));
  };

  useEffect(() => {
    programmeListData();
  }, [filterData.importFrom]);

  return (
    <>
      <div className="container new-memories px-4">
        <div className="col-12 mt-3">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Import From</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="sort_by"
              value={filterData.importFrom}
              label="Import From"
              onChange={(e) => {
                handleChaneOthers("importFrom", e.target.value);
              }}
            >
              <MenuItem value="group">Group</MenuItem>
              <MenuItem value="program">Program</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="col-12 mt-3">
          <MUIAutocomplete
            inputLabel={
              filterData.importFrom == "group" ? "Group" : "Programme"
            }
            selectedOption={selectedProgram}
            setSelectedOption={setSelectedProgram}
            optionsList={programValue}
            name="title"
            required
          />
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <FormControl fullWidth required>
            <InputLabel id="demo-simple-select-label">Access Type </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="access_type"
              value={filterData.access_type}
              label="Access Type"
              onChange={handleChange}
              required
            >
              <MenuItem value={"free"}>Free </MenuItem>
              <MenuItem value={"paid"}>Paid </MenuItem>
            </Select>
          </FormControl>
        </div>
        <Box sx={{ py: 2 }}>
          <Button
            fullWidth
            size="large"
            type="submit"
            color="inherit"
            variant="outlined"
            onClick={() => {
              handleSubmit("filter");
            }}
            startIcon={
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ marginRight: 5 }}
              >
                <path
                  d="M13.1165 0.25H0.883548C0.321452 0.25 0.0378205 0.932013 0.436097 1.33029L5.3125 6.20743V11.6406C5.3125 11.8471 5.41325 12.0406 5.58242 12.1591L7.69179 13.6351C8.10794 13.9264 8.6875 13.6312 8.6875 13.1167V6.20743L13.564 1.33029C13.9615 0.932804 13.6798 0.25 13.1165 0.25Z"
                  fill={get_root_value("--portal-theme-primary")}
                />
              </svg>
            }
          >
            {loading ? "Importing..." : "Import"}
          </Button>
        </Box>
      </div>
    </>
  );
}
