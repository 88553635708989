import { Tooltip } from "@mui/material";
import { useContentSetting } from "src/Hooks/ContentSettingState";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CopyToClipboard from "react-copy-to-clipboard";
import { useSnackbar } from "notistack";

const BankDetails = () => {
  const { bankDetails } = useContentSetting();
  const { enqueueSnackbar } = useSnackbar();
  function cleanHTMLForNotepad(html) {
    // Replace &nbsp; with a regular space
    let text = html.replace(/&nbsp;/g, " ");

    // Replace block-level elements like <p>, <div>, <br> with new lines
    text = text.replace(/<\/?(p|div|br)[^>]*>/g, "\n");

    // Remove any remaining HTML tags
    text = text.replace(/<[^>]*>/g, "");

    // Remove extra spaces, newlines, and tabs, ensuring new lines are preserved
    return text.replace(/\s+/g, " ").replace(/\n\s+/g, "\n").trim();
  }
  const handleCopyMessage = async (message) => {
    enqueueSnackbar(message, { variant: "success" });
  };
  return (
    <>
      <div className="container">
        <div className="row ">
          <div className="position-relative">
            <CopyToClipboard
              text={cleanHTMLForNotepad(bankDetails)}
              onCopy={() => handleCopyMessage("URL Copied to clipboard")}
              style={{
                position: "absolute",
                right: "2px",
                top: "23px",
                cursor: "pointer",
              }}
            >
              <Tooltip title={"Copy"}>
                <div className="me-2 link-chip">
                  <ContentCopyIcon
                    style={{
                      fontSize: "18px",
                    }}
                  />{" "}
                </div>
              </Tooltip>
            </CopyToClipboard>
          </div>
          <div
            className="ms-3 bank_detail"
            dangerouslySetInnerHTML={{
              __html: bankDetails,
            }}
          ></div>
          <div style={{ textAlign: "center" }}></div>
        </div>
      </div>
    </>
  );
};

export default BankDetails;
