import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { IconButton, CircularProgress } from "@mui/material";
import {
  addPageApi,
  detailPageApi,
  editPageApi,
  templateListingApi,
} from "src/DAL/WebsitePages/WebsitePages";
import { useEffect } from "react";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function AddOrUpdatePage() {
  const navigate = useNavigate();
  const classes = useStyles();
  const params = useParams();
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [formType, setFormType] = useState("ADD");

  const [inputs, setInputs] = useState({
    brand_favicon: {},
    brand_logo: {},
    footer_logo: {},
    sale_page_title: "",
    meta_title: "",
    meta_keywords: "",
    payment_mode: "sandBox",
    is_password_auto_generated: false,
    is_email_send: false,
    custom_link: "",
    active_campaign_list_id: 0,
    active_campaign_tag_id: 0,
    default_commission_percentage: 0,
    redirect_user: "thanks",
    type_of_page: "sale_page",
    book_a_call_type: "",
    thanks_template: "",
    payment_template: "",
    meta_description: "",
    pixel_code_header: "",
    pixel_code_body: "",
    google_tracking_code_header: "",
    google_tracking_code_body: "",
    website_portal_css_code: "",
    website_portal_js_code: "",
    template: "",
    is_general_brand_logo: true,
    is_default_commission: false,
    is_general_social_links: true,
    facebook_link: "",
    pinterest_link: "",
    instagram_link: "",
    youtube_link: "",
    mailbox_link: "",
    snap_chat_link: "",
    twitter_link: "",
    linkedin_link: "",
    tiktok_link: "",
    thankyou_page_content: "",
    header_menu: "",
    footer_menu: "",
    is_publically_accessible: true,
    is_paid: false,
    booking_tracking: true,
    book_a_call_custom_url: "",
    page_alias_url: "",
  });

  const formatData = (data) => {
    setFormType("EDIT");
    setInputs(data);
    setInputs({
      ...data,
      template: data.template?._id,
    });
    setIsLoading(false);
  };

  const getPageDetail = async () => {
    setIsLoading(true);
    const result = await detailPageApi(params.page_slug);
    if (result.code == 200) {
      formatData(result.sale_page);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const templatesListData = async () => {
    setIsLoading(true);
    let result = await templateListingApi();
    if (result.code == 200) {
      setTemplates(result.Sale_page);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const formData = new FormData();
    if (inputs.page_alias_url == undefined || inputs.page_alias_url == "") {
      enqueueSnackbar("Page Alias Text is required", { variant: "error" });
      return;
    }
    formData.append("meta_title", inputs.meta_title);
    formData.append(
      "page_alias_url",
      !!inputs.page_alias_url ? inputs.page_alias_url : ""
    );
    formData.append("meta_description", inputs.meta_description);
    formData.append("payment_mode", inputs.payment_mode);
    formData.append("meta_keywords", inputs.meta_keywords);
    formData.append("pixel_code_header", inputs.pixel_code_header);
    formData.append(
      "google_tracking_code_header",
      inputs.google_tracking_code_header
    );
    formData.append(
      "google_tracking_code_body",
      inputs.google_tracking_code_body
    );
    formData.append(
      "is_password_auto_generated",
      inputs.is_password_auto_generated
    );
    formData.append(
      "is_email_send",
      inputs.is_password_auto_generated ? inputs.is_email_send : false
    );
    if (inputs.redirect_user == "other_link") {
      formData.append("custom_link", inputs.custom_link);
    }
    formData.append("website_portal_css_code", inputs.website_portal_css_code);
    formData.append("website_portal_js_code", inputs.website_portal_js_code);
    formData.append("pixel_code_body", inputs.pixel_code_body);
    formData.append("active_campaign_list_id", inputs.active_campaign_list_id);
    formData.append("active_campaign_tag_id", inputs.active_campaign_tag_id);
    formData.append("redirect_user", inputs.redirect_user);
    if (inputs.type_of_page == "book_a_call_page") {
      formData.append("booking_tracking", inputs.booking_tracking);
      formData.append("is_paid", inputs.is_paid);
      formData.append("book_a_call_custom_url", inputs.book_a_call_custom_url);
      formData.append("book_a_call_type", inputs.book_a_call_type);
    }
    formData.append(
      "is_publically_accessible",
      inputs.is_publically_accessible
    );
    formData.append("sale_page_title", inputs.sale_page_title);
    formData.append("thankyou_page_content", inputs.thankyou_page_content);
    formData.append("is_general_brand_logo", inputs.is_general_brand_logo);
    formData.append("is_general_social_links", inputs.is_general_social_links);
    formData.append("is_default_commission", inputs.is_default_commission);
    if (inputs.is_default_commission) {
      formData.append(
        "default_commission_percentage",
        inputs.default_commission_percentage
      );
    }

    formData.append(
      "header_menu",
      inputs.header_menu == undefined ? "" : inputs.header_menu
    );
    formData.append(
      "footer_menu",
      inputs.footer_menu == undefined ? "" : inputs.footer_menu
    );
    if (inputs.type_of_page == "sale_page") {
      formData.append(
        "thanks_template",
        inputs.thanks_template ? inputs.thanks_template : ""
      );
      formData.append(
        "payment_template",
        inputs.payment_template ? inputs.payment_template : ""
      );
    }
    if (formType === "ADD") {
      formData.append("template", inputs.template);
    }
    if (inputs.is_general_social_links == false) {
      formData.append("facebook_link", inputs.facebook_link);
      formData.append("pinterest_link", inputs.pinterest_link);
      formData.append("youtube_link", inputs.youtube_link);
      formData.append("instagram_link", inputs.instagram_link);
      formData.append("mailbox_link", inputs.mailbox_link);
      // added by Zubair
      formData.append("snap_chat_link", inputs.snap_chat_link);
      formData.append("twitter_link", inputs.twitter_link);
      formData.append("linkedin_link", inputs.linkedin_link);
      formData.append("tiktok_link", inputs.tiktok_link);
    }

    const result =
      formType === "ADD"
        ? await addPageApi(formData)
        : await editPageApi(formData, params.page_slug);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      navigate(-1);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    if (name == "template") {
      let template = templates.find((template) => template._id == value);
      if (!!template?.page_alias_url) {
      } else {
        template.page_alias_url = inputs.page_alias_url;
      }
      setInputs(template);
    }

    setInputs((values) => ({ ...values, [name]: value }));
  };

  useEffect(() => {
    if (params.page_slug) {
      if (state) {
        formatData(state);
      } else {
        getPageDetail();
      }
    }
    templatesListData();
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      <div className="row mobile-margin display-flex">
        <div className="col-12 d-flex ">
          <span>
            <IconButton
              className="back-screen-button mb-4"
              onClick={() => navigate(-1)}
            >
              <ArrowBackIcon />
            </IconButton>
          </span>

          <h2 className="ms-1">{`${
            formType === "ADD" ? "Add" : "Edit"
          } Page`}</h2>
        </div>
        <div className="col-12"></div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Page Title"
              variant="outlined"
              fullWidth
              name="sale_page_title"
              required
              value={inputs.sale_page_title}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Page Alias Title"
              variant="outlined"
              fullWidth
              name="page_alias_url"
              value={inputs.page_alias_url}
              required
              onChange={handleChange}
            />
          </div>
          {formType === "ADD" && (
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <FormControl fullWidth required>
                <InputLabel id="demo-simple-select-label">
                  Choose Template
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="template"
                  value={inputs.template}
                  label="Choose Template"
                  onChange={handleChange}
                >
                  {templates.map((sale_page) => {
                    return (
                      <MenuItem value={sale_page._id}>
                        {sale_page.sale_page_title}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          )}

          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Meta Title"
              variant="outlined"
              fullWidth
              name="meta_title"
              required
              value={inputs.meta_title}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Meta Keyword"
              variant="outlined"
              fullWidth
              name="meta_keywords"
              required
              value={inputs.meta_keywords}
              onChange={handleChange}
            />
          </div>
          <div className="text-end mt-4">
            <button className="small-contained-button">
              {formType == "ADD" ? "Submit" : "Update"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
