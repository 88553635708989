import React, { useEffect, useState } from "react";

// import { GoalStatementList } from "../../components/GoalStatement";
// import GoalGallerySlider from "../../components/GoalStatement/GoalGallerySlider";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import { CircularProgress } from "@mui/material";
import { s3baseUrl } from "src/config/config";
import { useParams } from "react-router-dom";
// import { delegateQuestionsListing } from "src/DAL/consultant/consultant";
import MessageGraph from "src/components/MessageGraph";
import { PaiChart } from "src/components/pie-chart/PaiChart";
import { TrendingUpRounded } from "@mui/icons-material";
import RecordNotFound from "src/components/RecordNotFound";
// import PageDescription from "src/components/GeneralComponents/PageDescription";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

function WheelOfLifeGraphQuestions({ goalMembers, finalData }) {
  const classes = useStyles();
  const params = useParams();

  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [goalStatementQuestions, setGoalStatementQuestions] = useState([]);
  const [goalStatementAnswers, setGoalStatementAnswers] = useState([]);
  const [goalStatementGallery, setGoalStatementGallery] = useState([]);
  const [assessmentQuestions, setAssessmentQuestions] = useState(
    finalData.wheel_of_life
  );
  const [goalStatementCompletedStatus, setGoalStatementCompletedStatus] =
    useState(false);
  const [goalStatementCompletedDate, setGoalStatementCompletedDate] = useState(
    new Date()
  );
  const [replies, setreplies] = useState([]);

  const getFilteredQuestion = (question_id) => {
    const values = goalStatementQuestions.filter(
      (value) => value._id == question_id
    );
    return values[0];
  };

  // const getGoalStatementList = async () => {
  //   const result = await delegateQuestionsListing(params.id);
  //   if (result.code === 200) {
  //     const get_answer = (question_id) => {
  //       return result?.answers.filter((answer) => answer._id == question_id)[0]
  //         ?.answer_statement;
  //     };

  //     const data = result.delegate_ninety_day_question.map(
  //       (question, index) => {
  //         return {
  //           ...question,
  //           answer: get_answer(question._id),
  //         };
  //       }
  //     );

  //     setGoalStatementQuestions(data);
  //     setGoalStatementAnswers(result.answers.replies);

  //     setIsLoading(false);
  //   } else {
  //     enqueueSnackbar(result.message, { variant: "error" });
  //     setIsLoading(false);
  //   }
  // };
  console.log(assessmentQuestions, "assessmentQuestions ok");
  const get_graph_data = () => {
    const colors = assessmentQuestions?.map((q) => q?.scaling_color);
    const labels = assessmentQuestions?.map((q) => q?.scaling_main_heading);
    const data = assessmentQuestions?.map((q) => (q?.answer ? q?.answer : 0));
    return { colors, labels, data };
  };

  // useEffect(() => {
  //   getGoalStatementList();
  // }, [finalData]);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  console.log(assessmentQuestions, "assessmentQuestions");
  return (
    <div className="">
      {/* <PageDescription
        parameter="goal_statement_description"
        else_title="Goal Statement"

        
      /> */}
      {assessmentQuestions.length > 0 ? (
        <>
          <div className="row ">
            <div className="col-12">
              <MessageGraph
                replies={replies}
                data={goalMembers}
                userData={finalData}
                answers={goalStatementAnswers}
                goalStatementCompletedStatus={goalStatementCompletedStatus}
                goalStatementCompletedDate={goalStatementCompletedDate}
              />
            </div>
          </div>

          <div className="col-12 col-lg-12 text-center mt-4">
            <PaiChart assessment_data={get_graph_data()} nameData={finalData} />
          </div>
        </>
      ) : (
        <RecordNotFound title="Questions" />
      )}
    </div>
  );
}

export default WheelOfLifeGraphQuestions;
