import * as React from "react";
import { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { IconButton, CircularProgress, Button } from "@mui/material";
import {
  addPaymentPlanApi,
  detailPaymentPlanApi,
  editPaymentPlanApi,
  events_programs_product_lead_status_api,
} from "src/DAL/WebsitePages/paymentPlan";
import { page_detail_by_id } from "src/DAL/WebsitePages/WebsitePages";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCkeditor";
import { s3baseUrl } from "src/config/config";
import { uploadImageOns3 } from "src/DAL/commonApi/commonApi";
import MUIAutocomplete from "src/components/GeneralComponents/MUIAutocomplete";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const Input = styled("input")({
  display: "none",
});

export default function AddOrUpdatePaymentPlan() {
  const navigate = useNavigate();
  const classes = useStyles();
  const params = useParams();
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [leadStatusList, setLeadStatusList] = useState([]);
  const [file, setProfileImage] = React.useState();
  const [formType, setFormType] = useState("ADD");
  const [moduleData, setModuleData] = useState({});
  const [oldImage, setOldImage] = useState();
  const [pageData, setPageData] = useState({});
  const module_actual_name = "payment_plans";

  const fileChangedHandler = async (e) => {
    setProfileImage(URL.createObjectURL(e.target.files[0]));
    const formData = new FormData();
    formData.append("image", e.target.files[0]);
    formData.append("width", "823");
    formData.append("height", "363");
    const imageUpload = await uploadImageOns3(formData);
    if (imageUpload.code == 200) {
      setInputs({
        ...inputs,
        ["plan_image"]: imageUpload.image_path,
      });
    } else {
      enqueueSnackbar(imageUpload.message, { variant: "error" });
    }
  };
  const [inputs, setInputs] = useState({
    plan_title: "",
    is_plan_free: true,
    plan_package: "",
    number_of_days: "",
    plan_button_text: "",
    commission_pay_to: "referral",
    event_type: "online",
    is_wheel_of_life_enable: false,
    plan_ticket_type: "general",
    active_campaign_tag_id: 0,
    active_campaign_list_id: 0,
    plan_status: true,
    short_description: "",
    detailed_description: "",
    benefit_description: "",
    plan_currency: "gbp",
    plan_price: 0,
    is_dont_show_full_amount: true,
    stripe_product_name: "",
    payment_access: "onetime",
    plan_type: "week",
    time_period_interval: 0,
    trial_period_days: 0,
    initial_amount: 0,
    plan_order: 0,
    installment_amount: 0,
    product: null,
    is_basic_membership: false,
    plan_for: "Basic",
    member: "",
    extra_coins: 0,
    lead_status: null,
    is_new_user_created_for: false,
    first_and_next_recurring_days_diffrence: false,
    days_difference: 0,
    subscriber_count: 0,
    basic_membership_days: "",
    plan_image: {},
    commission_info: {
      public_user_commission_amount: 0,
      public_user_commission_amount_for_first_transaction: 0,
      public_user_commission_amount_for_next_transaction: 0,
      associate_user_commission_amount: 0,
      associate_user_commission_amount_for_first_transaction: 0,
      associate_user_commission_amount_for_next_transaction: 0,
      delegate_user_commission_amount: 0,
      delegate_user_commission_amount_for_first_transaction: 0,
      delegate_user_commission_amount_for_next_transaction: 0,
      dynamite_digital_user_commission_amount: 0,
      dynamite_digital_user_commission_amount_for_first_transaction: 0,
      dynamite_digital_user_commission_amount_for_next_transaction: 0,
    },
  });

  const ChangeCommissonAmount = () => {
    if (inputs.payment_access === "onetime") {
      setInputs((values) => ({
        ...values,
        commission_info: {
          ...values.commission_info,
          public_user_commission_amount_for_first_transaction: 0,
          public_user_commission_amount_for_next_transaction: 0,
          associate_user_commission_amount_for_first_transaction: 0,
          associate_user_commission_amount_for_next_transaction: 0,
          delegate_user_commission_amount_for_first_transaction: 0,
          delegate_user_commission_amount_for_next_transaction: 0,
          dynamite_digital_user_commission_amount_for_next_transaction: 0,
          dynamite_digital_user_commission_amount_for_first_transaction: 0,
        },
      }));
    } else {
      setInputs((values) => ({
        ...values,
        commission_info: {
          ...values.commission_info,
          public_user_commission_amount: 0,
          associate_user_commission_amount: 0,
          delegate_user_commission_amount: 0,
          dynamite_digital_user_commission_amount: 0,
        },
      }));
    }
    return true;
  };

  const renderCurrency = () => {
    return (
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Plan Currency *</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          name="plan_currency"
          value={inputs.plan_currency}
          label="Plan currency"
          onChange={handleChange}
        >
          <MenuItem value="usd">Dollar</MenuItem>
          <MenuItem value="gbp">UK Pounds</MenuItem>
          <MenuItem value="eur">Euro</MenuItem>
        </Select>
      </FormControl>
    );
  };

  const get_data_list = async () => {
    const result = await events_programs_product_lead_status_api();
    if (result.code === 200) {
      setProducts(result.product);
      setLeadStatusList(result.lead_status);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (inputs.is_plan_free == false && inputs.payment_access == "recursion") {
      var maxCommission = Math.max(
        inputs.commission_info
          .public_user_commission_amount_for_first_transaction,
        inputs.commission_info
          .associate_user_commission_amount_for_first_transaction,
        inputs.commission_info
          .delegate_user_commission_amount_for_first_transaction
      );
      var maxCommissionForNext = Math.max(
        inputs.commission_info
          .public_user_commission_amount_for_next_transaction,
        inputs.commission_info
          .associate_user_commission_amount_for_next_transaction,
        inputs.commission_info
          .delegate_user_commission_amount_for_next_transaction
      );

      let totalCommission =
        parseInt(maxCommission) +
        parseInt(
          inputs.commission_info
            .dynamite_digital_user_commission_amount_for_first_transaction
        );
      let totalCommissionForNext =
        parseInt(maxCommissionForNext) +
        parseInt(
          inputs.commission_info
            .dynamite_digital_user_commission_amount_for_next_transaction
        );

      if (totalCommission > inputs.initial_amount) {
        enqueueSnackbar(
          "Commission amount cannot be greater than initial amount",
          { variant: "error" }
        );
        setIsLoading(false);
        return;
      }

      if (totalCommissionForNext > parseInt(inputs.installment_amount)) {
        enqueueSnackbar(
          "Commission amount for next transaction cannot be greater than installment amount",
          { variant: "error" }
        );
        setIsLoading(false);
        return;
      }
    }

    if (inputs.is_plan_free == false && inputs.payment_access == "onetime") {
      var maxCommission = Math.max(
        inputs.commission_info.public_user_commission_amount,
        inputs.commission_info.associate_user_commission_amount,
        inputs.commission_info.delegate_user_commission_amount
      );
      let totalCommission =
        maxCommission +
        parseInt(
          inputs.commission_info.dynamite_digital_user_commission_amount
        );

      if (totalCommission > parseInt(inputs.plan_price)) {
        enqueueSnackbar("Commission amount cannot be greater than plan price", {
          variant: "error",
        });
        setIsLoading(false);
        return;
      }
    }

    const set_commisson = await ChangeCommissonAmount();
    if (set_commisson) {
      let formDatas = {
        plan_title: inputs.plan_title,
        extra_coins: inputs.extra_coins,
        subscriber_count: inputs.subscriber_count,
        commission_pay_to: inputs.commission_pay_to,
        event_type: inputs.event_type,
        is_new_user_created_for: inputs.is_new_user_created_for,
        plan_package: inputs.plan_package,
        plan_button_text: inputs.plan_button_text,
        active_campaign_tag_id:
          inputs.active_campaign_tag_id >= 0
            ? inputs.active_campaign_tag_id?.toString()
            : "",
        active_campaign_list_id:
          inputs.active_campaign_list_id >= 0
            ? inputs.active_campaign_list_id?.toString()
            : "",
        plan_status: inputs.plan_status,
        is_plan_free: inputs.is_plan_free,
        short_description: inputs.short_description,
        detailed_description: inputs.detailed_description,
        benefit_description: inputs.benefit_description,
        is_basic_membership: inputs.is_basic_membership,
        plan_for: inputs.plan_for,
        commission_info: inputs.commission_info,
        is_wheel_of_life_enable: inputs.is_wheel_of_life_enable,
      };
      if (inputs.payment_access == "onetime" && inputs.is_plan_free == false) {
        formDatas.basic_membership_days = inputs.basic_membership_days;
      }
      if (inputs.event_type == "onsite") {
        formDatas.plan_ticket_type = inputs.plan_ticket_type;
      }
      if (inputs.lead_status) {
        formDatas.lead_status = inputs.lead_status._id;
      }
      if (file) {
        formDatas.plan_image = file ? inputs.plan_image : oldImage;
      } else {
        formDatas.plan_image = oldImage;
      }
      if (params && params.page_id) {
        formDatas.created_for = "sale_page";
        formDatas.page_id = params.page_id;
      } else {
        formDatas.created_for = "general";
      }
      if (formType == "EDIT") {
        formDatas.plan_order = inputs.plan_order;
      }

      if (inputs.is_plan_free == false) {
        formDatas.plan_price = inputs.plan_price;
        formDatas.stripe_product_name = inputs.stripe_product_name;
        formDatas.plan_currency = inputs.plan_currency;
        formDatas.payment_access = inputs.payment_access;
        if (inputs.payment_access == "recursion") {
          formDatas.product = inputs.product ? inputs.product._id : "";
          formDatas.days_difference = inputs.days_difference;
          formDatas.first_and_next_recurring_days_diffrence =
            inputs.first_and_next_recurring_days_diffrence;
          formDatas.time_period_interval = inputs.time_period_interval;
          formDatas.trial_period_days = inputs.trial_period_days;
          formDatas.plan_type =
            inputs.plan_type == undefined ? "week" : inputs.plan_type;
          formDatas.initial_amount = inputs.initial_amount;
          formDatas.is_dont_show_full_amount = inputs.is_dont_show_full_amount;
          if (inputs.plan_type == "custom") {
            formDatas.number_of_days =
              inputs.number_of_days == undefined ? 0 : inputs.number_of_days;
          }
        }
      }
      const formObject = formDatas;
      const result =
        formType == "ADD"
          ? await addPaymentPlanApi(formObject)
          : await editPaymentPlanApi(formObject, params.plan_id);
      if (result.code === 200) {
        enqueueSnackbar(result.message, { variant: "success" });
        navigate(-1);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
      }
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleChangeOthers = (name, value) => {
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleChangeCommission = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({
      ...values,
      commission_info: { ...values.commission_info, [name]: value },
    }));
  };

  const getPageDetail = async () => {
    const result = await page_detail_by_id(params.page_id);
    if (result.code == 200) {
      handlePageDetail(result.sale_page);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const getPlanDetail = async () => {
    const result = await detailPaymentPlanApi(params.plan_id);
    if (result.code == 200) {
      handleFormatdata(result.payment_plan);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleFormatdata = (data) => {
    if (typeof data.commission_info == "string") {
      data.commission_info = JSON.parse(data.commission_info);
    }
    setFormType("EDIT");
    setInputs({
      ...data,
      commission_info: data.commission_info
        ? data.commission_info
        : {
            public_user_commission_amount: 0,
            public_user_commission_amount_for_first_transaction: 0,
            public_user_commission_amount_for_next_transaction: 0,
            associate_user_commission_amount: 0,
            associate_user_commission_amount_for_first_transaction: 0,
            associate_user_commission_amount_for_next_transaction: 0,
            delegate_user_commission_amount: 0,
            delegate_user_commission_amount_for_first_transaction: 0,
            delegate_user_commission_amount_for_next_transaction: 0,
            dynamite_digital_user_commission_amount: 0,
            dynamite_digital_user_commission_amount_for_first_transaction: 0,
            dynamite_digital_user_commission_amount_for_next_transaction: 0,
          },
    });
    setIsLoading(false);
  };

  const handlePageDetail = (data) => {
    setPageData(data);
    const get_module_info = data.module_info.filter(
      (item) => item.module_actual_name == module_actual_name
    )[0];
    setModuleData(get_module_info.module_replica_info);
  };

  useEffect(() => {
    get_data_list();
    if (params && params.plan_id) {
      if (state) {
        if (params.page_id) {
          handlePageDetail(state.page_info);
        }
        handleFormatdata(state.data);
      } else {
        getPlanDetail();
        if (params.page_id) {
          getPageDetail();
        }
      }
    } else {
      setIsLoading(false);
      if (params.page_id) {
        if (state) {
          handlePageDetail(state);
        } else {
          getPageDetail();
        }
      }
    }
  }, []);

  useEffect(() => {
    setOldImage(state?.data?.plan_image);
    let totalValue = inputs.plan_price - inputs.initial_amount;
    let installment = (totalValue / inputs.time_period_interval).toFixed(1);
    setInputs((values) => ({
      ...values,
      ["installment_amount"]:
        !isNaN(installment) && installment !== "Infinity" ? installment : 0,
    }));
  }, [inputs.plan_price, inputs.time_period_interval, inputs.initial_amount]);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      <div className="row mobile-margin display-flex">
        <div className="col-12 display-flex mb-4">
          <IconButton
            className="back-screen-button"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon />
          </IconButton>
          <span className="sale-page-title">{pageData.sale_page_title}</span>
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          {moduleData?.add_page_heading ? (
            <h2>
              {formType === "ADD"
                ? moduleData.add_page_heading
                : moduleData.edit_page_heading}
            </h2>
          ) : (
            <h2>{`${formType === "ADD" ? "Add" : "Edit"} Plan`}</h2>
          )}
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Plan Title"
              variant="outlined"
              fullWidth
              required
              name="plan_title"
              value={inputs.plan_title}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">Plan Type</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="is_plan_free"
                value={inputs.is_plan_free}
                label="Plan Type"
                onChange={handleChange}
              >
                <MenuItem value={true}>Free</MenuItem>
                <MenuItem value={false}>Paid</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">
                Is New User Created For
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="is_new_user_created_for"
                value={inputs.is_new_user_created_for}
                label="Is New User Created For"
                onChange={handleChange}
              >
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Inactive</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">Plan Status</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="plan_status"
                value={inputs.plan_status}
                label="Plan Status"
                onChange={handleChange}
              >
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Inactive</MenuItem>
              </Select>
            </FormControl>
          </div>
          {inputs.is_plan_free == false && (
            <>
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <FormControl fullWidth required>
                  <InputLabel id="demo-simple-select-label">
                    Plan Payment
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="payment_access"
                    value={inputs.payment_access}
                    label="Plan Payment"
                    onChange={handleChange}
                  >
                    <MenuItem value="onetime">OneTime</MenuItem>
                    <MenuItem value="recursion">Recurring</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div
                className={`col-lg-6 col-md-6 col-sm-12 mt-4 ${
                  inputs.payment_access == "recursion" ? "d-none" : "d-block"
                }`}
              >
                {renderCurrency()}
              </div>

              <div
                className={`col-lg-6 col-md-6 col-sm-12 mt-4 ${
                  inputs.payment_access == "recursion" ? "d-block" : "d-none"
                }`}
              >
                <FormControl fullWidth required>
                  <InputLabel id="demo-simple-select-label1">
                    Plan Payment Type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label1"
                    id="demo-simple-select"
                    name="plan_type"
                    value={inputs.plan_type ? inputs.plan_type : "week"}
                    label="Plan Payment Type"
                    onChange={handleChange}
                    required
                  >
                    <MenuItem value="week">Weekly</MenuItem>
                    <MenuItem value="month">Monthly</MenuItem>
                    <MenuItem value="year">Yearly</MenuItem>
                    <MenuItem value="custom">Custom</MenuItem>
                  </Select>
                </FormControl>
              </div>
              {inputs.plan_type == "custom" &&
                inputs.payment_access == "recursion" && (
                  <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                    <TextField
                      id="outlined-basic"
                      label="No of days"
                      variant="outlined"
                      fullWidth
                      required
                      type="number"
                      name="number_of_days"
                      value={inputs.number_of_days}
                      onChange={handleChange}
                    />
                  </div>
                )}
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <TextField
                  id="outlined-basic"
                  label="Total Plan Price"
                  variant="outlined"
                  fullWidth
                  required
                  type="number"
                  name="plan_price"
                  value={inputs.plan_price}
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <FormControl fullWidth required>
                  <InputLabel id="plan_for">Plan For</InputLabel>
                  <Select
                    labelId="plan_for"
                    id="demo-simple-select"
                    name="plan_for"
                    value={inputs.plan_for}
                    label="Plan For"
                    onChange={handleChange}
                  >
                    <MenuItem value="Basic">Basic</MenuItem>
                    <MenuItem value="Premium">Premium</MenuItem>
                  </Select>
                </FormControl>
              </div>
              {inputs.payment_access == "recursion" ? (
                <>
                  <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                    <TextField
                      id="outlined-basic"
                      label="Initial Amount"
                      variant="outlined"
                      fullWidth
                      type="number"
                      required
                      name="initial_amount"
                      value={inputs.initial_amount}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                    <TextField
                      id="outlined-basic"
                      label="Recurring Time Period "
                      variant="outlined"
                      fullWidth
                      required
                      type="number"
                      name="time_period_interval"
                      value={inputs.time_period_interval}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                    <TextField
                      id="outlined-basic"
                      label="Installment Amount"
                      variant="outlined"
                      fullWidth
                      type="number"
                      required
                      aria-readonly
                      name="installment_amount"
                      value={inputs.installment_amount}
                      // onChange={handleChange}
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                    <TextField
                      id="outlined-basic"
                      label="Trial Period Days"
                      variant="outlined"
                      fullWidth
                      name="trial_period_days"
                      value={inputs.trial_period_days}
                      type="number"
                      onChange={handleChange}
                    />
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                    {renderCurrency()}
                  </div>

                  <div className="col-12">
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                        <TextField
                          id="outlined-basic"
                          label="Public User Commission Amount for first recursion"
                          variant="outlined"
                          fullWidth
                          type="number"
                          name="public_user_commission_amount_for_first_transaction"
                          value={
                            inputs.commission_info
                              .public_user_commission_amount_for_first_transaction
                          }
                          onChange={handleChangeCommission}
                        />
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                        <TextField
                          id="outlined-basic"
                          label="Public User Commission Amount for next recursion"
                          variant="outlined"
                          fullWidth
                          type="number"
                          name="public_user_commission_amount_for_next_transaction"
                          value={
                            inputs.commission_info
                              .public_user_commission_amount_for_next_transaction
                          }
                          onChange={handleChangeCommission}
                        />
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                        <TextField
                          id="outlined-basic"
                          label="Associate User Commission Amount for first recursion"
                          variant="outlined"
                          fullWidth
                          type="number"
                          name="associate_user_commission_amount_for_first_transaction"
                          value={
                            inputs.commission_info
                              .associate_user_commission_amount_for_first_transaction
                          }
                          onChange={handleChangeCommission}
                        />
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                        <TextField
                          id="outlined-basic"
                          label="Associate User Commission Amount for next recursion"
                          variant="outlined"
                          fullWidth
                          type="number"
                          name="associate_user_commission_amount_for_next_transaction"
                          value={
                            inputs.commission_info
                              .associate_user_commission_amount_for_next_transaction
                          }
                          onChange={handleChangeCommission}
                        />
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                        <TextField
                          id="outlined-basic"
                          label="Delegate User Commission Amount for first recursion"
                          variant="outlined"
                          fullWidth
                          type="number"
                          name="delegate_user_commission_amount_for_first_transaction"
                          value={
                            inputs.commission_info
                              .delegate_user_commission_amount_for_first_transaction
                          }
                          onChange={handleChangeCommission}
                        />
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                        <TextField
                          id="outlined-basic"
                          label="Delegate User Commission Amount for next recursion"
                          variant="outlined"
                          fullWidth
                          type="number"
                          name="delegate_user_commission_amount_for_next_transaction"
                          value={
                            inputs.commission_info
                              .delegate_user_commission_amount_for_next_transaction
                          }
                          onChange={handleChangeCommission}
                        />
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                        <TextField
                          id="outlined-basic"
                          label="Dynamite Digital User Commission Amount for first recursion"
                          variant="outlined"
                          fullWidth
                          type="number"
                          name="dynamite_digital_user_commission_amount_for_first_transaction"
                          value={
                            inputs.commission_info
                              .dynamite_digital_user_commission_amount_for_first_transaction
                          }
                          onChange={handleChangeCommission}
                        />
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                        <TextField
                          id="outlined-basic"
                          label="Dynamite Digital User Commission Amount for next recursion"
                          variant="outlined"
                          fullWidth
                          type="number"
                          name="dynamite_digital_user_commission_amount_for_next_transaction"
                          value={
                            inputs.commission_info
                              .dynamite_digital_user_commission_amount_for_next_transaction
                          }
                          onChange={handleChangeCommission}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                    <FormControl fullWidth required>
                      <InputLabel id="plan_for">
                        Is There Difference Between First & Next Recurring ?
                      </InputLabel>
                      <Select
                        labelId="plan_for"
                        id="demo-simple-select"
                        name="first_and_next_recurring_days_diffrence"
                        value={inputs.first_and_next_recurring_days_diffrence}
                        label="Is There Difference Between First & Next Recurring ?"
                        onChange={handleChange}
                      >
                        <MenuItem value={true}>Yes</MenuItem>
                        <MenuItem value={false}>No</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  {inputs.first_and_next_recurring_days_diffrence && (
                    <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                      <TextField
                        id="outlined-basic"
                        label="Days Difference"
                        variant="outlined"
                        fullWidth
                        name="days_difference"
                        value={inputs.days_difference}
                        type="number"
                        onChange={handleChange}
                      />
                    </div>
                  )}
                </>
              ) : (
                <>
                  {/* <div className="col-6 d-none d-md-block mt-4"></div> */}
                  <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                    <TextField
                      id="outlined-basic"
                      label="Public User Commission Amount"
                      variant="outlined"
                      fullWidth
                      type="number"
                      name="public_user_commission_amount"
                      value={
                        inputs.commission_info.public_user_commission_amount
                      }
                      onChange={handleChangeCommission}
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                    <TextField
                      id="outlined-basic"
                      label="Associate User Commission Amount"
                      variant="outlined"
                      fullWidth
                      type="number"
                      name="associate_user_commission_amount"
                      value={
                        inputs.commission_info.associate_user_commission_amount
                      }
                      onChange={handleChangeCommission}
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                    <TextField
                      id="outlined-basic"
                      label="Delegate User Commission Amount"
                      variant="outlined"
                      fullWidth
                      type="number"
                      name="delegate_user_commission_amount"
                      value={
                        inputs.commission_info.delegate_user_commission_amount
                      }
                      onChange={handleChangeCommission}
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                    <TextField
                      id="outlined-basic"
                      label="Dynamite Digital User Commission Amount"
                      variant="outlined"
                      fullWidth
                      type="number"
                      name="dynamite_digital_user_commission_amount"
                      value={
                        inputs.commission_info
                          .dynamite_digital_user_commission_amount
                      }
                      onChange={handleChangeCommission}
                    />
                  </div>
                </>
              )}
            </>
          )}

          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Campaign Tag ID"
              variant="outlined"
              fullWidth
              type="number"
              name="active_campaign_tag_id"
              value={inputs.active_campaign_tag_id}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Campaign List ID"
              variant="outlined"
              fullWidth
              type="number"
              name="active_campaign_list_id"
              value={inputs.active_campaign_list_id}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Extra Coins"
              variant="outlined"
              fullWidth
              type="number"
              name="extra_coins"
              value={inputs.extra_coins}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Subscriber Count"
              variant="outlined"
              fullWidth
              type="number"
              name="subscriber_count"
              value={inputs.subscriber_count}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">
                Don't Show Full Amount ?
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="is_dont_show_full_amount"
                value={inputs.is_dont_show_full_amount}
                label="Don't Show Full Amount ? "
                onChange={handleChange}
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Plan Button Text"
              variant="outlined"
              fullWidth
              name="plan_button_text"
              value={inputs.plan_button_text}
              onChange={handleChange}
            />
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="is_basic_membership">
                Is Basic Membership
              </InputLabel>
              <Select
                labelId="is_basic_membership"
                id="demo-simple-select"
                name="is_basic_membership"
                value={inputs.is_basic_membership}
                label="Is Basic Membership"
                onChange={handleChange}
              >
                <MenuItem value={false}>No</MenuItem>
                <MenuItem value={true}>Yes</MenuItem>
              </Select>
            </FormControl>
          </div>
          {formType === "EDIT" && (
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <TextField
                id="outlined-basic"
                label="Plan Order"
                variant="outlined"
                fullWidth
                required
                type="number"
                name="plan_order"
                value={inputs.plan_order}
                onChange={handleChange}
              />
            </div>
          )}
          {inputs.is_plan_free == false && (
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <TextField
                id="outlined-basic"
                label="Stripe Product Name"
                variant="outlined"
                fullWidth
                required
                name="stripe_product_name"
                value={inputs.stripe_product_name}
                onChange={handleChange}
              />
            </div>
          )}
          {inputs.is_plan_free == false && (
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <FormControl fullWidth required>
                <InputLabel id="is_basic_membership-1">
                  Commission Pay To
                </InputLabel>
                <Select
                  labelId="is_basic_membership"
                  id="demo-simple-select-1"
                  name="commission_pay_to"
                  value={inputs.commission_pay_to}
                  label="Commission Pay To"
                  onChange={handleChange}
                >
                  <MenuItem value="referral">Referral</MenuItem>
                  <MenuItem value="both">Both</MenuItem>
                </Select>
              </FormControl>
            </div>
          )}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="event_type1">Event Type</InputLabel>
              <Select
                labelId="event_type1"
                id="event_type"
                name="event_type"
                value={inputs.event_type}
                label="Event Type"
                onChange={handleChange}
              >
                <MenuItem value="online">Online</MenuItem>
                <MenuItem value="onsite">Onsite</MenuItem>
              </Select>
            </FormControl>
          </div>
          {inputs.event_type == "onsite" && (
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <FormControl fullWidth required>
                <InputLabel id="plan_ticket_type1">Ticket Type</InputLabel>
                <Select
                  labelId="plan_ticket_type1"
                  id="plan_ticket_type"
                  name="plan_ticket_type"
                  value={inputs.plan_ticket_type}
                  label="Event Type"
                  onChange={handleChange}
                >
                  <MenuItem value="general">General</MenuItem>
                  <MenuItem value="vip">VIP</MenuItem>
                  <MenuItem value="executive">Executive</MenuItem>
                </Select>
              </FormControl>
            </div>
          )}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="plan_for">Is Wheel Of Life Enable ?</InputLabel>
              <Select
                labelId="plan_for"
                id="demo-simple-select"
                name="is_wheel_of_life_enable"
                value={inputs.is_wheel_of_life_enable}
                label="Is Wheel Of Life Enable ?"
                onChange={handleChange}
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </div>
          {inputs.payment_access == "onetime" && inputs.is_plan_free == false && (
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <TextField
                id="outlined-basic"
                label="Basic Membership Days"
                variant="outlined"
                fullWidth
                required
                type="number"
                name="basic_membership_days"
                value={inputs.basic_membership_days}
                onChange={handleChange}
              />
            </div>
          )}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <MUIAutocomplete
              inputLabel="Lead Status"
              selectedOption={inputs.lead_status}
              setSelectedOption={(v) => {
                handleChangeOthers("lead_status", v);
              }}
              optionsList={leadStatusList}
              autoComplete="new-password"
              name="title"
            />
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <div className="row w-100 div-style ms-0 pt-0">
              <div className="col-5">
                <p className="">Plan Image </p>
                <FormHelperText className="pt-0">
                  Image Size(1000 X 670) ("JPG", "JPEG", "PNG","WEBP")
                </FormHelperText>
              </div>
              <div className="col-3">
                <div className="col-3">
                  {file ? (
                    <img src={file} height="70" />
                  ) : (
                    oldImage && <img src={s3baseUrl + oldImage} height="70" />
                  )}
                </div>
              </div>
              <div className="col-4 text-end pt-2">
                <label htmlFor="contained-button-file">
                  <Input
                    accept="image/*"
                    id="contained-button-file"
                    multiple
                    type="file"
                    name="plan_image"
                    onChange={fileChangedHandler}
                  />

                  <Button
                    className="small-contained-button"
                    startIcon={<FileUploadIcon />}
                    component="span"
                  >
                    Upload
                  </Button>
                </label>
              </div>
            </div>
            {/* {inputs?.plan_image == "" ? (
              ""
            ) : (
              <p className="text-secondary">{inputs?.plan_image}</p>
            )} */}
          </div>
          {inputs.is_plan_free == false &&
            inputs.payment_access == "recursion" && (
              <div className="col-12 mt-4">
                <MUIAutocomplete
                  inputLabel="Product"
                  required
                  selectedOption={inputs.product}
                  setSelectedOption={(v) => {
                    handleChangeOthers("product", v);
                  }}
                  optionsList={products}
                  autoComplete="new-password"
                  name="name"
                />
              </div>
            )}

          <div className="col-12 mt-4">
            <FormControl fullWidth>
              <TextField
                id="outlined-multiline-flexible"
                label="Short Description"
                multiline
                required
                rows={6}
                name="short_description"
                value={inputs.short_description}
                onChange={handleChange}
              />
            </FormControl>
          </div>
          <div className="col-12 mt-4">
            <h4>Detail Description *</h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="detailed_description"
              editorHeight={320}
            />
          </div>
          <div className="col-12 mt-4">
            <h4>Benefit Description</h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="benefit_description"
              editorHeight={320}
            />
          </div>
          <div className="col-12 mt-4">
            <h4>Plan Package</h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="plan_package"
              editorHeight={320}
            />
          </div>
          <div className="text-end mt-4">
            <button className="small-contained-button">
              {formType == "ADD" ? "Submit" : "Update"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
