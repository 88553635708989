import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IconButton from "@mui/material/IconButton";
import { useParams } from "react-router-dom";
import { useState } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  FormHelperText,
  styled,
  CircularProgress,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  create_meeting_api,
  get_meeting_by_id_api,
  update_meeting_api,
  update_meeting_content_settings,
} from "src/DAL/Meeting/Meeting";
import { useSnackbar } from "notistack";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { s3baseUrl } from "src/config/config";
import { uploadImageOns3 } from "src/DAL/commonApi/commonApi";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const Input = styled("input")({
  display: "none",
});

export default function BeforeAfterMeetingContent() {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { meeting_id } = useParams();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const [meetingData, setMeetingData] = useState({});

  const [beforeMeeting, setBeforeMeeting] = useState({
    is_show_content: false,
    content_type: "video",
    video_link: "",
    file_url: "",
    is_loading: false,
  });
  const [afterMeeting, setAfterMeeting] = useState({
    is_show_content: false,
    content_type: "video",
    video_link: "",
    file_url: "",
    is_loading: false,
  });

  const handleLoading = (type, name, value) => {
    if (type === "before") {
      setBeforeMeeting((old) => ({ ...old, [name]: value }));
    } else {
      setAfterMeeting((old) => ({ ...old, [name]: value }));
    }
  };

  const handleChangeBefore = (e) => {
    const { name, value } = e.target;
    setBeforeMeeting((old) => ({ ...old, [name]: value }));
  };

  const handleChangeAfter = (e) => {
    const { name, value } = e.target;
    setAfterMeeting((old) => ({ ...old, [name]: value }));
  };

  const fileChangedHandler = async (e, type) => {
    const { files } = e.target;
    handleLoading(type, "is_loading", true);
    const formData = new FormData();
    formData.append("image", files[0]);

    const fileUpload = await uploadImageOns3(formData);
    if (fileUpload.code == 200) {
      handleLoading(type, "file_url", fileUpload.image_path);
      handleLoading(type, "is_loading", false);
    } else {
      handleLoading(type, "is_loading", false);
    }
  };

  const handleFormat = (meeting) => {
    setMeetingData(meeting);
    if (meeting.before_meeting_start_content) {
      setBeforeMeeting(meeting.before_meeting_start_content);
    }
    if (meeting.after_meeting_start_content) {
      setAfterMeeting(meeting.after_meeting_start_content);
    }
    window.history.replaceState({}, document.title);
    setIsLoading(false);
  };

  const audio_required = (content) => {
    if (
      content.is_show_content &&
      content.content_type == "file" &&
      !content.file_url
    ) {
      return true;
    } else {
      return false;
    }
  };

  const getMeetingDetail = async () => {
    setIsLoading(true);
    const result = await get_meeting_by_id_api(meeting_id);
    if (result.code == 200) {
      handleFormat(result.meeting);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (audio_required(beforeMeeting)) {
      enqueueSnackbar("Before Meeting Start Audio is required", {
        variant: "error",
      });
      return;
    }
    if (audio_required(afterMeeting)) {
      enqueueSnackbar("After Meeting Start Audio is required", {
        variant: "error",
      });
      return;
    }
    setIsLoadingForm(true);
    const formData = {
      before_meeting_start_content: {},
      after_meeting_start_content: {},
    };
    if (beforeMeeting.is_show_content) {
      formData.before_meeting_start_content = beforeMeeting;
    }
    if (afterMeeting.is_show_content) {
      formData.after_meeting_start_content = afterMeeting;
    }
    const result = await update_meeting_content_settings(meeting_id, formData);
    if (result.code === 200) {
      setIsLoadingForm(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setIsLoadingForm(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  useEffect(() => {
    if (state) {
      handleFormat(state);
    } else {
      getMeetingDetail();
    }
  }, []);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      <div className="row mt-2">
        <div className="col-12 d-flex mb-4">
          <IconButton
            className="back-screen-button"
            onClick={() => navigate(`/meetings`)}
          >
            <ArrowBackIcon />
          </IconButton>
          <h2 className="mb-0 ms-2">{meetingData.title}</h2>
        </div>
      </div>
      <form className="container" onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-12">
            <h3 className="h3-content-title">
              Content Before Meeting Start Time
            </h3>
          </div>
          <div className="col-md-6 mt-2">
            <FormControl variant="outlined" required>
              <InputLabel id="demo-simple-select-outlined-label">
                Is Show Content?
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={beforeMeeting.is_show_content}
                onChange={handleChangeBefore}
                label="Is Show Content?"
                name="is_show_content"
              >
                <MenuItem value={false}>No</MenuItem>
                <MenuItem value={true}>Yes</MenuItem>
              </Select>
            </FormControl>
          </div>
          {beforeMeeting.is_show_content && (
            <>
              <div className="col-md-6 mt-2 mt-md-2">
                <FormControl variant="outlined" required>
                  <InputLabel id="demo-simple-select-outlined-label">
                    Content Type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={beforeMeeting.content_type}
                    onChange={handleChangeBefore}
                    label="Content Type"
                    name="content_type"
                  >
                    <MenuItem value={"video"}>Video</MenuItem>
                    <MenuItem value={"file"}>Audio</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <>
                {beforeMeeting.content_type === "video" ? (
                  <div className="col-md-6 mt-4">
                    <TextField
                      id="outlined-basic"
                      label="Video Link"
                      variant="outlined"
                      fullWidth
                      required
                      name="video_link"
                      value={beforeMeeting.video_link}
                      onChange={handleChangeBefore}
                    />
                  </div>
                ) : (
                  <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
                    <div className="row w-100 div-style ms-0 pt-0">
                      <div className="col-7">
                        <p className="">Upload Audio</p>
                        <FormHelperText className="pt-0">
                          Audio *("mp3", "wav", "aac", "flac", "ogg", "wma",
                          "m4a", "alac", "aiff", "opus", "pcm", "amr", "dsd",
                          "dff", "dsf", "mp2", "ra", "mka", "caf", "voc", "au",
                          "ape", "wv")
                        </FormHelperText>
                      </div>
                      <div className="col-5 text-end pt-2">
                        {beforeMeeting.is_loading ? (
                          <div
                            className="d-flex align-items-center justify-content-end"
                            style={{ height: "60px" }}
                          >
                            <CircularProgress
                              color="primary"
                              style={{
                                height: "25px",
                                width: "25px",
                              }}
                            />
                          </div>
                        ) : (
                          <>
                            {beforeMeeting.file_url && (
                              <a
                                href={s3baseUrl + beforeMeeting.file_url}
                                target="_blank"
                                className="me-2 small-contained-button"
                              >
                                View File
                              </a>
                            )}
                            <label htmlFor="contained-button-file-other1">
                              <Input
                                accept="audio/*"
                                id="contained-button-file-other1"
                                multiple
                                type="file"
                                name="file_url"
                                onChange={(e) =>
                                  fileChangedHandler(e, "before")
                                }
                              />
                              <Button
                                className="small-contained-button"
                                startIcon={<FileUploadIcon />}
                                component="span"
                              >
                                Upload
                              </Button>
                            </label>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </>
            </>
          )}
        </div>
        <div className="row mt-4">
          <div className="col-12">
            <h3 className="h3-content-title">
              Content After Meeting Start Time
            </h3>
          </div>
          <div className="col-md-6 mt-2">
            <FormControl variant="outlined" required>
              <InputLabel id="demo-simple-select-outlined-label">
                Is Show Content?
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={afterMeeting.is_show_content}
                onChange={handleChangeAfter}
                label="Is Show Content?"
                name="is_show_content"
              >
                <MenuItem value={false}>No</MenuItem>
                <MenuItem value={true}>Yes</MenuItem>
              </Select>
            </FormControl>
          </div>
          {afterMeeting.is_show_content && (
            <>
              <div className="col-md-6 mt-2 mt-md-2">
                <FormControl variant="outlined" required>
                  <InputLabel id="demo-simple-select-outlined-label">
                    Content Type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={afterMeeting.content_type}
                    onChange={handleChangeAfter}
                    label="Content Type"
                    name="content_type"
                  >
                    <MenuItem value={"video"}>Video</MenuItem>
                    <MenuItem value={"file"}>Audio</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <>
                {afterMeeting.content_type === "video" ? (
                  <div className="col-md-6 mt-4">
                    <TextField
                      id="outlined-basic"
                      label="Video Link"
                      variant="outlined"
                      fullWidth
                      required
                      name="video_link"
                      value={afterMeeting.video_link}
                      onChange={handleChangeAfter}
                    />
                  </div>
                ) : (
                  <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
                    <div className="row w-100 div-style ms-0 pt-0">
                      <div className="col-7">
                        <p className="">Upload Audio</p>
                        <FormHelperText className="pt-0">
                          Audio *("mp3", "wav", "aac", "flac", "ogg", "wma",
                          "m4a", "alac", "aiff", "opus", "pcm", "amr", "dsd",
                          "dff", "dsf", "mp2", "ra", "mka", "caf", "voc", "au",
                          "ape", "wv")
                        </FormHelperText>
                      </div>
                      <div className="col-5 text-end pt-2">
                        {afterMeeting.is_loading ? (
                          <div
                            className="d-flex align-items-center justify-content-end"
                            style={{ height: "60px" }}
                          >
                            <CircularProgress
                              color="primary"
                              style={{
                                height: "25px",
                                width: "25px",
                              }}
                            />
                          </div>
                        ) : (
                          <>
                            {afterMeeting.file_url && (
                              <a
                                href={s3baseUrl + afterMeeting.file_url}
                                target="_blank"
                                className="me-2 small-contained-button"
                              >
                                View File
                              </a>
                            )}
                            <label htmlFor="contained-button-file-other">
                              <Input
                                accept="audio/*"
                                id="contained-button-file-other"
                                multiple
                                type="file"
                                name="file_url"
                                onChange={(e) => fileChangedHandler(e, "after")}
                              />
                              <Button
                                className="small-contained-button"
                                startIcon={<FileUploadIcon />}
                                component="span"
                              >
                                Upload
                              </Button>
                            </label>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </>
            </>
          )}
        </div>
        <div className="text-end mt-4">
          <button
            className="small-contained-button"
            disabled={
              beforeMeeting.is_loading ||
              afterMeeting.is_loading ||
              isLoadingForm
            }
          >
            {isLoadingForm ? "Updating..." : "Update"}
          </button>
        </div>
      </form>
    </div>
  );
}
