import * as React from "react";
import Badge from "@mui/material/Badge";
import { styled } from "@mui/material/styles";
import { get_root_value } from "src/utils/domUtils";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: 5,
    top: 10,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "5px",
    backgroundColor: get_root_value("--sidebars-background-color"),
    color: get_root_value("--portal-theme-primary"),
  },
}));

export default function CustomizedBadges({ count }) {
  return (
    <StyledBadge
      badgeContent={count ? count : "0"}
      max={999999999}
      min={-1}
      color="secondary"
    ></StyledBadge>
  );
}
