import { Box, CircularProgress, Typography } from "@mui/material";
import React from "react";
import { get_kmb_number } from "src/utils/formatNumber";

export default function DashboardProgress(props) {
  return (
    <div className="dashboard-progress-box">
      <CircularProgress
        className="dashboard-progress-icon"
        variant="determinate"
        value={100}
      />
      <div className="dashboard-progress-text">
        <div className="dashboard-progress-text-title">{`${get_kmb_number(
          props.value
        )}`}</div>
      </div>
    </div>
  );
}
