import { Icon } from "@iconify/react";
import {
  Avatar,
  Badge,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import CodeIcon from "@mui/icons-material/Code";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import { filter } from "lodash";
import AddIcon from "@mui/icons-material/Add";
import React from "react";
import { s3baseUrl } from "src/config/config";
import { get_root_value } from "src/utils/domUtils";
import {
  decode_markdown,
  get_date_with_user_time_zone,
  get_short_string,
} from "src/utils/constants";
import RefreshIcon from "@mui/icons-material/Refresh";
import { htmlDecode } from "src/utils/convertHtml";
import { useContentSetting } from "src/Hooks/ContentSettingState";
import { useChat } from "src/Hooks/ChatContext/ChatContext";
import { useRef } from "react";
import { useCallback } from "react";
import { useState } from "react";
import { live_events_list_api_chat } from "src/DAL/LiveEvents/LiveEvents";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import CustomDrawer from "src/components/CustomDrawer/CustomDrawer";

import MicIcon from "@mui/icons-material/Mic";
import { _get_chat_detail, _get_chat_list_history } from "src/DAL/Chat/Chat";
import { useSnackbar } from "notistack";
import RecordNotFound from "src/components/GeneralComponents/RecordNotFound";
import NewChatUsers from "../components/NewChatUsers";
import CreateNewBroadCast from "./CreateNewBroadCast";
import { broadcast } from "src/assets";
import { get_feeds_list } from "src/DAL/Community/Community";
import { _get_broadcast_history } from "src/DAL/Broadcast/Broadcast";
import { object } from "prop-types";
import moment from "moment";

export default function BroadCastChatList(props) {
  const { userInfo } = useContentSetting();
  const observer = useRef();
  const { enqueueSnackbar } = useSnackbar();
  const [loadMore, setLoadMore] = useState("");
  const [pageNumber, setPageNumber] = useState(0);

  const [totalPages, setTotalPages] = useState(0);
  const [loadMoreUserPath, setLoadMoreUserPath] = useState("");
  const [totalPagesChats, setTotalPagesChats] = useState(0);
  const [broadcastList, setBroadcastList] = useState([]);
  const [pageNumberChat, setPageNumberChat] = useState(0);
  const [isLoadingMoreChats, setIsLoadingMoreChats] = useState(false);
  const [loadMoreChatPath, setLoadMoreChatPath] = useState(
    `api/broadcast/list_broadcast?page=${0}&limit=${50}`
  );
  const [state, setState] = useState({
    search_text: "",
    event_id: "none",
    chat_type: "all",
  });

  const {
    drawerState,
    setDrawerState,
    setBroadcast,
    broadcasts,
    broadcastsEdit,
    count,
  } = props;

  const handleOpenDrawer = () => {
    setDrawerState(true);
  };

  const handleCloseDrawer = () => {
    setDrawerState(false);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };
  const get_chat_history_list = async (path, data, type) => {
    const result = await _get_broadcast_history(path, data);
    if (result.code === 200) {
      let editedObject = result.broadcasts.find(
        (item) => item._id === broadcasts?._id
      );
      if (editedObject) {
        setBroadcast(editedObject);
      }
      if (type === "search") {
        console.log("here new");
        console.log(
          result.broadcasts,
          "result.broadcastsresult.broadcastsresult.broadcasts"
        );
        setBroadcastList(result.broadcasts);
      } else {
        let array = broadcastList.concat(result.broadcasts);
        setBroadcastList(array);
      }
      setTotalPagesChats(result.total_pages);
      setLoadMoreChatPath(result.load_more_url);
      setPageNumberChat((page) => page + 1);
      setIsLoadingMoreChats(false);
    } else {
      setIsLoadingMoreChats(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const loadMoreData = () => {
    setIsLoadingMoreChats(true);
    let data = {
      search_text: state.search_text,
    };
    get_chat_history_list(loadMoreChatPath, data, "without_search");
  };
  const lastBookElementRef = useCallback(
    (node) => {
      if (isLoadingMoreChats) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && totalPagesChats >= pageNumberChat) {
          loadMoreData();
        }
      });
      if (node) observer.current.observe(node);
    },
    [
      isLoadingMoreChats,
      totalPagesChats,
      pageNumberChat,
      loadMoreChatPath,
      observer,
    ]
  );
  const selectBroadcast = (broadcast) => {
    setBroadcast(broadcast);
  };
  useEffect(() => {
    if (state.search_text.length >= 0) {
      let data = {
        search_text: state.search_text,
      };
      let path = `api/broadcast/list_broadcast?page=${0}&limit=${50}`;
      get_chat_history_list(path, data, "search");
    } else {
      let data = {
        search_text: state.search_text,
      };
      get_chat_history_list(loadMoreChatPath, data, "without_search");
    }
  }, [state.search_text.length]);
  useEffect(() => {
    let data = {
      search_text: "",
    };
    let path = `api/broadcast/list_broadcast?page=${0}&limit=${50}`;
    get_chat_history_list(path, data, "search");
  }, [count]);
  return (
    <>
      <div className="col-12 col-md-4 chat-left-part">
        <div className="chat-left-header" style={{ height: "50px" }}>
          <div className="chat-search-box mt-3">
            <Icon
              fontSize={20}
              className="chat-search-icon"
              icon="akar-icons:search"
            />
            <input
              className="chat-search-input"
              type="text"
              placeholder="Search"
              value={state.search_text}
              name="search_text"
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="position-relative">
          <div className="chat-list">
            {broadcastList && broadcastList.length > 0 ? (
              broadcastList.map((item, index) => {
                let last_message_time = "";
                let createdTime = "";

                if (item.latest_message) {
                  if (item.latest_message.message_type == "publish") {
                    last_message_time = moment(
                      item.latest_message.publish_date_time
                    ).format("DD-MM-YYYY hh:mm A");
                  } else {
                    last_message_time = moment
                      .utc(item.latest_message.schedule_date_time)
                      .format("DD-MM-YYYY hh:mm A");
                  }
                  if (item.latest_message.createdAt) {
                    createdTime = get_date_with_user_time_zone(
                      item.latest_message.createdAt,
                      "DD-MM-YYYY hh:mm A",
                      userInfo.time_zone,
                      userInfo.adminTimeZone
                    );
                  }
                }
                return (
                  <div
                    onClick={() => selectBroadcast(item)}
                    className={
                      item._id === broadcasts?._id
                        ? "chat-list-user selected-chat d-flex p-2"
                        : "chat-list-user hover-effect d-flex p-2"
                    }
                    // key={i}
                  >
                    <div>
                      <div className="user-profile">
                        <div className="user-level"></div>
                        <Avatar
                          src={broadcast}
                          alt={"BroadCastChatList"}
                          style={{ height: 50, width: 50 }}
                        ></Avatar>
                      </div>
                    </div>
                    <div className="chat-profile-data">
                      <div className="chat-profile-name ps-2 text-capitalize chat-profile-name-padding">
                        {item.broadcast_title}
                      </div>
                      <div className="chat-last-message ps-2 d-flex">
                        <div
                          className="ticket-icon me-1"
                          style={{ color: "#c2c2c2" }}
                        >
                          <Tooltip
                            title={
                              item?.latest_message &&
                              item?.latest_message.message_type == "publish"
                                ? "Sent"
                                : "This message will be published on " +
                                  last_message_time +
                                  " Europe/Dublin time."
                            }
                          >
                            {Object.keys(item?.latest_message).length > 1 ? (
                              <Icon
                                className=""
                                fontSize={16}
                                icon={
                                  item?.latest_message &&
                                  item?.latest_message.message_type == "publish"
                                    ? "mdi:tick-all"
                                    : "mdi:clock-time-four-outline"
                                }
                              />
                            ) : (
                              <span
                                style={{
                                  fontSize: "12px",
                                  color: "#c2c2c2",
                                  marginTop: "2px",
                                }}
                              >
                                {get_short_string(
                                  htmlDecode(decode_markdown("No message yet")),
                                  15
                                )}
                              </span>
                            )}
                          </Tooltip>
                        </div>
                        {item?.latest_message &&
                          item?.latest_message.message_content_type ==
                            "audio" && (
                            <span
                              style={{
                                color: "#c2c2c2",
                                fontSize: "12px",
                                marginTop: "2px",
                              }}
                            >
                              <MicIcon fontSize="18" /> Voice Message
                            </span>
                          )}
                        {item?.latest_message &&
                        item?.latest_message.message ? (
                          <span
                            style={{
                              fontSize: "12px",
                              color: "#c2c2c2",
                              marginTop: "2px",
                            }}
                          >
                            {get_short_string(
                              htmlDecode(
                                decode_markdown(item?.latest_message?.message)
                              ),
                              15
                            )}
                          </span>
                        ) : (
                          <>
                            {!item?.latest_message && (
                              <span
                                style={{
                                  fontSize: "12px",
                                  color: "#c2c2c2",
                                  marginTop: "2px",
                                }}
                              >
                                {get_short_string(
                                  htmlDecode(decode_markdown("No message yet")),
                                  15
                                )}
                              </span>
                            )}
                          </>
                        )}
                      </div>

                      <div className="chat-last-time text-end ps-2">
                        <Tooltip title={createdTime ?? last_message_time} arrow>
                          <span className="">
                            {createdTime ?? last_message_time}
                          </span>
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <>
                {!isLoadingMoreChats && (
                  <RecordNotFound title="Broadcast not found" />
                )}
              </>
            )}
            {totalPagesChats >= pageNumberChat ? (
              <div className="col-12 text-center p-2">
                <span
                  ref={lastBookElementRef}
                  className=" mt-3"
                  onClick={loadMoreData}
                  id="load-more-feed"
                >
                  {isLoadingMoreChats ? "Loading..." : "Load More"}
                </span>
              </div>
            ) : (
              ""
            )}
          </div>

          {!drawerState && (
            <Tooltip title="New Broadcast" placement="top">
              <button className="new-chat-button" onClick={handleOpenDrawer}>
                <AddIcon />
              </button>
            </Tooltip>
          )}
        </div>
      </div>
      <CustomDrawer
        isOpenDrawer={drawerState}
        onOpenDrawer={handleOpenDrawer}
        onCloseDrawer={handleCloseDrawer}
        pageTitle={broadcastsEdit ? "Edit Broadcast" : `New Broadcast `}
        componentToPassDown={
          <CreateNewBroadCast
            onCloseDrawer={handleCloseDrawer}
            broadcastsEdit={broadcastsEdit}
            broadcasts={broadcasts}
            getListapi={get_chat_history_list}
          />
        }
      />
    </>
  );
}
