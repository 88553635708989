import React from "react";
import CurrentUpcomingEvent from "./CurrentUpcomingEvent";

function CurrentUpcomingEvents({ currenEvents, upcomingEvents, eventDetail }) {
  if (currenEvents?.length > 0 || upcomingEvents?.length > 0) {
    return (
      <div className={`profile-cards p-3 community-card-title current-events`}>
        <div className="mb-4">
          <CurrentUpcomingEvent
            events_list={currenEvents}
            event_heading={
              eventDetail?.top_text ? eventDetail?.top_text : "CURRENT EVENTS"
            }
          />
        </div>
        <CurrentUpcomingEvent
          events_list={upcomingEvents}
          event_heading={
            eventDetail?.bottom_text
              ? eventDetail?.bottom_text
              : "UPCOMING EVENTS"
          }
        />
      </div>
    );
  } else {
    return <></>;
  }
}

export default CurrentUpcomingEvents;
