import * as React from "react";
import { useState } from "react";
import { useSnackbar } from "notistack";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  TextareaAutosize,
} from "@mui/material";
import { useEffect } from "react";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCkeditor";
import { change_status_payment_request_api } from "src/DAL/PaymentRequest/paymentRequest";

export default function ChangePaymentRequestStatus({
  selectedObject,
  setOpenChangePopup,
  getMemberListing,
  filterData,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [inputs, setInputs] = useState({
    verification_note: "",
    type: "paid",
    amount: "",
    currency: "",
    member: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (inputs.verification_note.length == 0 && inputs.type == "paid") {
      enqueueSnackbar("Transaction note is not allowed to be empty", {
        variant: "error",
      });
      setLoading(false);

      return;
    }
    let postData = {
      type: inputs.type,
      payment_request_slug: selectedObject.payment_request_slug,
      verification_note: inputs.verification_note,
    };

    const result = await change_status_payment_request_api(postData);

    if (result.code === 200) {
      setLoading(false);

      enqueueSnackbar(result.message, { variant: "success" });
      setOpenChangePopup(false);
      getMemberListing(filterData);
    } else {
      setLoading(false);

      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  useEffect(() => {
    let member = "";
    if (
      selectedObject.member_info &&
      Object.keys(selectedObject.member_info).length > 0
    ) {
      member =
        selectedObject.member_info.first_name +
        " " +
        selectedObject.member_info.last_name;
    } else {
      member =
        selectedObject.member.first_name +
        " " +
        selectedObject.member.last_name;
    }
    setInputs({
      ...inputs,
      ["amount"]: selectedObject.total_amount,
      ["currency"]: selectedObject.currency,
      ["member"]: member,
    });
  }, [selectedObject]);

  return (
    <div className="container">
      <form onSubmit={handleSubmit}>
        <div className="row notification-statement">
          <div className="col-lg-12 col-md-12 col-sm-12 mt-3">
            <TextField
              id="outlined-basic"
              label="Member"
              variant="outlined"
              fullWidth
              disabled
              name="member"
              value={inputs.member}
              onChange={handleChange}
            />
          </div>{" "}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
            <TextField
              id="outlined-basic"
              label="Total Amount"
              variant="outlined"
              fullWidth
              type="number"
              disabled
              name="amount"
              value={inputs.amount}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label" disabled>
                Currency
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="currency"
                disabled
                value={inputs.currency}
                label="currency"
              >
                <MenuItem value="usd">Dollar</MenuItem>
                <MenuItem value="gbp">UK Pounds</MenuItem>
                <MenuItem value="eur">Euro</MenuItem>
              </Select>
            </FormControl>
          </div>
          {inputs.type == "paid" && (
            <>
              <div className="col-12 ">
                <p className="tracking_code_heading text-white">
                  Transaction Note *
                </p>

                <GeneralCkeditor
                  setInputs={setInputs}
                  inputs={inputs}
                  name="verification_note"
                  editorHeight={310}
                />
              </div>
            </>
          )}
          <div className="text-end mt-4">
            <button className="small-contained-button">
              {loading ? "Please wait..." : "Submit"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
