import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { get_participant_list_by_module_id_api } from "src/DAL/LiveStream/LiveStream";
import ParticipantInfoCard from "./ParticipantInfoCard";
import { Icon } from "@iconify/react";
import RecordNotFound from "src/components/GeneralComponents/RecordNotFound";

function sortByJoinTimeDescending(data) {
  return data.sort((a, b) => new Date(b.join_time) - new Date(a.join_time));
}

function Participants(props) {
  const {
    module_id,
    openParticipants,
    participants,
    setParticipants,
    handleMakeHost,
    handleSelectTile,
    liveStream,
    hostUsers,
    setHostUsers,
    participantsCount,
    setParticipantsCount,
  } = props;
  const observer = useRef();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [searchText, setSearchText] = useState("");

  const getParticipantsList = async (is_new) => {
    const result = await get_participant_list_by_module_id_api(
      module_id,
      pageNumber,
      20,
      searchText
    );
    if (result.code === 200) {
      if (is_new) {
        setParticipants(sortByJoinTimeDescending(result.participants));
        setHostUsers(sortByJoinTimeDescending(result.host_users));
        setPageNumber(1);
      } else {
        let new_participants = sortByJoinTimeDescending(result.participants);
        let new_hosts = sortByJoinTimeDescending(result.host_users);
        setParticipants((old) => [...old, ...new_participants]);
        setHostUsers((old) => [...old, ...new_hosts]);
        setPageNumber((old) => old + 1);
      }
      setParticipantsCount(result.total_participants);
      setTotalPages(result.total_pages);
      setIsLoading(false);
      setIsLoadingMore(false);
    } else {
      setIsLoading(false);
      setIsLoadingMore(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const hanldeMenu = (participant) => {
    let MENU_OPTIONS = [];
    let label = "Make Co-Host";
    let action = "make_co_host";
    if (participant.user_role === "co_host") {
      label = "Remove Co-Host";
      action = "remove_co_host";
    }
    if (participant.user_role !== "host") {
      MENU_OPTIONS.push({
        label: label,
        icon: "akar-icons:edit",
        handleClick: (user) => handleMakeHost(user, action),
      });
    }
    if (
      participant.user_role !== "participant" &&
      participant.attendee_id !== liveStream.selected_attendee_id
    ) {
      MENU_OPTIONS.push({
        label: "Make Presenter",
        icon: "akar-icons:edit",
        handleClick: handleSelectTile,
      });
    }

    return MENU_OPTIONS;
  };

  const lastBookElementRef = useCallback(
    (node) => {
      if (isLoadingMore) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && totalPages > pageNumber) {
          loadMoreData();
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoadingMore, totalPages, pageNumber, observer]
  );

  const handleChange = (e) => {
    setPageNumber(0);
    setSearchText(e.target.value);
  };

  const loadMoreData = () => {
    setIsLoadingMore(true);
    getParticipantsList();
  };

  useEffect(() => {
    if (openParticipants) {
      setIsLoading(true);
      getParticipantsList(true);
    }
  }, [openParticipants, searchText]);

  return (
    <div className="participants-view pb-4">
      <div className="chat-search-box mt-3 px-2">
        <Icon
          fontSize={20}
          className="chat-search-icon"
          icon="akar-icons:search"
        />
        <input
          className="chat-search-input"
          type="text"
          placeholder="Search Participants"
          value={searchText}
          name="search_text"
          onChange={handleChange}
        />
      </div>
      {isLoading ? (
        <div className="participants-popover">
          <CircularProgress
            className="notification-popover-loader"
            color="primary"
          />
        </div>
      ) : (
        <>
          {hostUsers.length === 0 && participants.length == 0 && (
            <RecordNotFound title="Participants not found" />
          )}
          {hostUsers.length > 0 && (
            <>
              <h4>{`Co-Hosts (${hostUsers.length})`}</h4>
              {hostUsers.map((participant) => (
                <ParticipantInfoCard
                  participant={participant}
                  hanldeMenu={hanldeMenu}
                  liveStream={liveStream}
                />
              ))}
            </>
          )}
          {participants.length > 0 && (
            <>
              <h4>{`Participants (${participantsCount})`}</h4>
              {participants.map((participant) => (
                <ParticipantInfoCard
                  participant={participant}
                  hanldeMenu={hanldeMenu}
                  liveStream={liveStream}
                />
              ))}
            </>
          )}
          {isLoadingMore ? (
            <div className="text-center mt-3">
              <CircularProgress
                color="primary"
                style={{ height: "20px", width: "20px" }}
              />
            </div>
          ) : totalPages > pageNumber ? (
            <div className="col-12 text-center p-2">
              <span
                ref={lastBookElementRef}
                className=" mt-3"
                onClick={loadMoreData}
                id="load-more-participants"
              >
                {isLoadingMore ? "Loading..." : "Load More"}
              </span>
            </div>
          ) : (
            ""
          )}
        </>
      )}
    </div>
  );
}

export default Participants;
