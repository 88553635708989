import { invokeApi } from "../../bl_libs/invokeApi";

export const get_tutorials_list = async (data) => {
  const requestObj = {
    path: `api/help_video/list_help_videos_with_category_for_consultant`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
