import { Avatar, CircularProgress, IconButton, Tooltip } from "@mui/material";
import React, { useState } from "react";
import { s3baseUrl } from "src/config/config";
import { useChat } from "src/Hooks/ChatContext/ChatContext";
import dayjs from "dayjs";
import {
  change_text_for_note,
  decode_markdown,
  get_date_with_user_time_zone,
  string_avatar,
} from "src/utils/constants";
import SendIcon from "@mui/icons-material/Send";
import MicIcon from "@mui/icons-material/Mic";

import {
  _add_chat,
  _delete_message,
  _get_chat_message,
  _send_message,
  _update_message,
} from "src/DAL/Chat/Chat";
import { useSnackbar } from "notistack";
import { useContentSetting } from "src/Hooks/ContentSettingState";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { get_local_preview } from "src/utils/domUtils";
import { Icon } from "@iconify/react";
import { update_image_on_s3_for_chat } from "src/DAL/LiveEvents/LiveEvents";
import CustomPopover from "src/components/GeneralComponents/CustomPopover";
import CustomConfirmation from "src/components/GeneralComponents/CustomConfirmation";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect } from "react";
import TextareaAutosize from "react-textarea-autosize";
import Markdown from "markdown-to-jsx";

import GeneralPopUpModel from "src/components/GeneralComponents/GeneralPopUpModel";
import {
  addPersonalNote,
  addPersonalNoteFromChat,
} from "src/DAL/member/Member";
import moment from "moment";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import LinkPopup from "../components/ChatMessages/LinkPopup";
import VoiceRecorder from "../components/ChatMessages/VoiceRecorder";
import BoardcastDetail from "./BoardCastDetail";
import ScheduleMessage from "./ScheduleMessage";
import {
  _send_message_broadcast,
  _send_message_broadcast_update,
  deleteMessage,
  getBroadcastDetailNew,
} from "src/DAL/Broadcast/Broadcast";
import ReactAudioPlayer from "../components/ChatMessages/ReactAudioPlayer";
import { details } from "src/assets";

// Extend dayjs with the plugins

const initialTime = moment().tz("Europe/Dublin").add(2, "hours").toDate();

const scroll_to_bottom_on_message = (quick) => {
  const chat_box = document.getElementsByClassName("user-chat-box-body")[0];
  if (quick) {
    chat_box.scroll({ top: chat_box.scrollHeight });
  } else {
    chat_box.scroll({ top: chat_box.scrollHeight, behavior: "smooth" });
  }
};
function BroadCastChatMessages({
  is_popup,
  isExpandedChat,
  setShowDetail,
  showDetail,
  drawerState,
  setDrawerState,
  broadcastMain,
  setBroadcastEdit,
  setCount,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const {
    selectedChat,
    selectedUser,
    messagesList,
    loadMoreData,
    lastBookElementRef,
    isLoadingMore,
    count,
    setSelectedMessage,
    selectedMessage,
    setMessagesList,
    setSelectedChatUser,
    setSelectedChat,
    get_formatted_message,
    scroll_to_bottom_on_message,
    get_chat_history_list,
    setChatList,
    mark_as_unread,
    eventApiObject,
  } = useChat();
  const { userInfo, socket } = useContentSetting();
  const [isLoadingChat, setIsLoadingChat] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [message, setMessage] = useState("");
  const [images, setImage] = useState(null);
  const [file, setFile] = useState(null);
  const [openFormatter, setOpenFormatter] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openLink, setOpenLink] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isStartRecording, setIsStartRecording] = useState(false);
  const [inputHeight, setInputHeight] = useState(0);
  const [messageListNew, setMessagesListNew] = useState([]);
  const [selectBroadCast, setSelectBroadCast] = useState(null);
  const [timePop, setTimePop] = useState(false);
  const [isAudio, setIsAudio] = useState(false);
  const [audioData, setAudioData] = useState(null);
  const [inputs, setInputs] = useState({
    messageSchedule: "publish",
    date: new Date(),
    time: initialTime,
    add_as_personal_note: false,
  });
  const receiver_profile = selectedChat?.receiver_profile;
  const my_profile = selectedChat?.my_profile;

  function handleEditorInit() {
    setTimeout(() => {
      let containerWarning = document.getElementsByClassName(
        "chat-send-input-rare"
      )[0];
      if (containerWarning) {
        containerWarning.style.height = "90px";
      }
    }, 10);
  }
  const handleUploadFile = (e) => {
    const _file = e.target.files[0];
    setFile(_file);
  };

  const handleRemoveFile = (e) => {
    setFile(null);
    setImage(null);
  };

  const handleCancelUpdate = async () => {
    setSelectedMessage(null);
    setMessage("");
    setFile(null);
    setImage(null);
    setIsUpdate(false);
    handleRefresh();
  };

  const handleEdit = async (message_obj) => {
    setInputs((item) => ({
      ...item,
      ["messageSchedule"]:
        message_obj.message_type == "schedule" ? "schedule" : "publish",
      ["date"]: message_obj.schedule_date_time
        ? moment.utc(message_obj.schedule_date_time).format("YYYY-MM-DD")
        : new Date(),
      ["time"]: message_obj.schedule_date_time,

      ["add_as_personal_note"]: message_obj.add_as_personal_note,
    }));
    setSelectBroadCast(message_obj);
    setMessage(message_obj.message);

    setImage(message_obj.image);
    setIsUpdate(true);
  };
  const handleRefresh = () => {
    setInputs({
      messageSchedule: "publish",
      date: new Date(),
      time: initialTime,
      add_as_personal_note: false,
    });
    setIsStartRecording(false);
    setIsLoading(false);
    setFile(null);
    setImage(null);
    setIsUpdate(false);
    setMessage("");
  };
  const handleAddPersonelNote = async (message_obj) => {
    let postData = {
      message_id: message_obj._id,
      member_id: receiver_profile._id,
    };
    const result = await addPersonalNoteFromChat(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleCancelAudio = () => {
    setIsStartRecording(false);
  };

  const handleStartRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      stream.getTracks().forEach((track) => track.stop());
      setIsStartRecording(true);
    } catch (error) {
      enqueueSnackbar(
        "You have blocked microphone access. Please allow microphone to start recording",
        { variant: "error" }
      );
    }
  };
  const setAudioScadule = (file, duration) => {
    let audioObject = {
      file: file,
      duration: duration,
    };
    setAudioData(audioObject);
    setTimePop(true);
    setIsAudio(true);
  };
  const handleSendAudio = async () => {
    setIsLoading(true);
    let image = "";
    let is_error = false;
    if (!audioData.file) {
      enqueueSnackbar("message content is required", { variant: "error" });
      return;
    }
    const formData = new FormData();
    formData.append("image", audioData.file, "audio.mp3");
    formData.append("width", 600);
    formData.append("height", 700);
    // formData.append("file_type", "audio");
    const result = await update_image_on_s3_for_chat(formData);
    if (result.code === 200) {
      image = result.image_path;
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      is_error(true);
    }
    if (is_error) {
      return;
    }
    setIsAudio(false);
    setIsStartRecording(false);
    handleSendSchedule("audio", image, audioData.duration);
  };
  const handleSubmitMessage = async (e) => {
    e.preventDefault();
    setTimePop(true);
  };
  const handleSendSchedule = async (type, image, duration) => {
    setCount((prev) => prev + 1);
    setIsLoading(true);
    if (type != "audio") {
      let image = isUpdate ? images : "";
      let is_error = false;
      if (file) {
        const formData = new FormData();
        formData.append("image", file);
        formData.append("width", 600);
        formData.append("height", 700);
        const result = await update_image_on_s3_for_chat(formData);

        if (result.code === 200) {
          image = result.image_path;
        } else {
          enqueueSnackbar(result.message, { variant: "error" });
        }
      }
      let start_date = moment(inputs.date).format("YYYY-MM-DD");
      let start_time = moment(inputs.time).utc().format("HH:mm");
      let postdata = {
        schedule_date: inputs.date,
        schedule_time: inputs.time,
        message: message,
        image: image ? image : isUpdate ? images : "",
        message_type: inputs.messageSchedule,
        add_as_personal_note: inputs.add_as_personal_note,
        message_content_type: "text",
        broadcast_id: broadcastMain._id,
        schedule_date_time: start_date + " " + start_time,
      };
      if (type == "audio") {
        delete postdata.image;
        postdata.audio_duration = duration.toString();
        postdata.audio_url = image;
        postdata.message_content_type = type;
      }
      if (inputs.messageSchedule == "publish") {
        delete postdata.schedule_date;
        delete postdata.schedule_time;
        delete postdata.schedule_date_time;
      }
      const result = isUpdate
        ? await _send_message_broadcast_update(
            postdata,
            broadcastMain._id,
            selectBroadCast._id
          )
        : await _send_message_broadcast(postdata);
      if (result.code === 200) {
        let data = {
          broadcast_details: result.broadcast_details,
          broadcast_message: result.broadcast_message,
        };
        if (inputs.messageSchedule == "publish") {
          console.log("calles");
          socket.emit("publish_broadcast_message", data);
        }
        if (isUpdate) {
          const index = messageListNew.findIndex(
            (obj) => obj._id === result.broadcast_message._id
          );
          if (index !== -1) {
            messageListNew[index] = result.broadcast_message;
          }
        } else {
          setMessagesListNew((old_messages) => [
            ...old_messages,
            result.broadcast_message,
          ]);
        }
        handleRefresh();
        setTimePop(false);
        setIsStartRecording(false);
        setIsLoading(false);
        setIsUpdate(false);

        handleCancelUpdate();
        handleCancelAudio();

        scroll_to_bottom_on_message();
        enqueueSnackbar(result.message, { variant: "success" });
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
      }
    } else {
      let postdata = {
        schedule_date: inputs.date,
        schedule_time: inputs.time,
        message: message,
        image: image ? image : "",
        message_type: inputs.messageSchedule,
        add_as_personal_note: inputs.add_as_personal_note,
        message_content_type: "text",
        broadcast_id: broadcastMain._id,
      };
      if (type == "audio") {
        delete postdata.image;
        postdata.audio_duration = duration.toString();
        postdata.audio_url = image;
        postdata.message_content_type = type;
      }
      if (inputs.messageSchedule == "publish") {
        delete postdata.schedule_date;
        delete postdata.schedule_time;
      }
      const result = isUpdate
        ? await _send_message_broadcast_update(
            postdata,
            broadcastMain._id,
            selectBroadCast._id
          )
        : await _send_message_broadcast(postdata);
      if (result.code === 200) {
        if (isUpdate) {
          const index = messageListNew.findIndex(
            (obj) => obj._id === result.broadcast_message._id
          );
          if (index !== -1) {
            messageListNew[index] = result.broadcast_message;
          }
          handleRefresh();
        } else {
          setMessagesListNew((old_messages) => [
            ...old_messages,
            result.broadcast_message,
          ]);
        }
        setTimePop(false);
        handleCancelUpdate();
        scroll_to_bottom_on_message();
        setIsLoading(false);
        enqueueSnackbar(result.message, { variant: "success" });
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
      }
    }
  };
  const handleDelete = async (message_obj) => {
    setSelectBroadCast(message_obj);
    setOpenDelete(true);
  };

  const handleAgreeDelete = async () => {
    setOpenDelete(false);
    const postData = {
      chat_id: selectedMessage.chat_id,
      message_id: selectedMessage._id,
    };
    socket.emit("delete_chat_message", postData);
  };

  const handleUpdateMessage = async () => {
    let _image = image;
    let is_error = false;
    if (file) {
      const formData = new FormData();
      formData.append("image", file);
      formData.append("width", 600);
      formData.append("height", 700);
      const result = await update_image_on_s3_for_chat(formData);

      if (result.code === 200) {
        _image = result.image_path;
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        is_error(true);
      }
    }

    if (is_error) {
      return;
    }

    if (!message && !_image) {
      enqueueSnackbar("message content is required", { variant: "error" });
      return;
    }

    const postData = {
      message_id: selectedMessage._id,
      message: message,
      image: _image,
    };

    socket.emit("update_chat_message", postData);
    setMessage("");
    setFile(null);
    setImage(null);
    setSelectedMessage(null);
    setIsUpdate(false);
    setIsLoading(false);
    if (openFormatter) {
      handleToggleFormatter();
    }
  };

  const handleGetSelectedText = () => {
    const inputField = document.getElementById("inputField");
    if (inputField) {
      const selectedText = inputField.value.substring(
        inputField.selectionStart,
        inputField.selectionEnd
      );
      return selectedText;
    }
  };

  const handleOnBold = () => {
    let seleted_text = handleGetSelectedText();
    if (seleted_text) {
      var newText = inputField.value.replace(
        seleted_text,
        "**" + seleted_text + "**"
      );
      setMessage(newText);
    }
  };

  const handleOnItalic = () => {
    let seleted_text = handleGetSelectedText();
    if (seleted_text) {
      var newText = inputField.value.replace(
        seleted_text,
        "*" + seleted_text + "*"
      );
      setMessage(newText);
    }
  };

  const handleAddLink = (values) => {
    let make_link = ` [${values.display_text}](${values.link_address}) `;
    const inputField = document.getElementById("inputField");
    const start = inputField.selectionStart;
    const end = inputField.selectionEnd;

    // Insert the text at the cursor position
    setMessage(message.slice(0, start) + make_link + message.slice(end));

    // Move the cursor after the inserted text
    inputField.setSelectionRange(
      start + make_link.length,
      start + make_link.length
    );
    setOpenLink(false);
  };

  const handleOpenLinkPopup = () => {
    setOpenLink(true);
  };

  const handleToggleFormatter = () => {
    let heigh = 0;
    let content_height = is_popup ? "60vh" : "70vh";
    let marginTop = 0;
    let paddingTop = 0;
    let elements = document.getElementsByClassName("chat-formatters-box")[0];
    let content = document.getElementsByClassName("user-chat-box-body")[0];
    handleEditorInit();
    if (!openFormatter) {
      setTimeout(() => {
        setOpenFormatter(!openFormatter);
      }, 100);
      heigh = "35px";
      content_height = `calc(${is_popup ? "60vh" : "70vh"} - ${
        openFormatter ? "35px" : "0px"
      })`;
      handleEditorInit();
    } else {
      setOpenFormatter(!openFormatter);
      handleEditorInit();
    }
    elements.style.height = heigh;
    elements.style.marginTop = marginTop;
    elements.style.paddingTop = paddingTop;
    elements.content.height = content_height;
    handleEditorInit();
  };

  const handleMenu = (row) => {
    const MENU_OPTIONS = [
      {
        label: "Delete",
        icon: "ant-design:delete-twotone",
        handleClick: handleDelete,
      },
    ];

    if (row.message_content_type !== "audio") {
      MENU_OPTIONS.unshift({
        label: "Edit",
        icon: "akar-icons:edit",
        handleClick: handleEdit,
      });
    }

    return MENU_OPTIONS;
  };

  const received_message_option = (message) => {
    const MENU_OPTIONS = [
      {
        label: "Mark as unread",
        icon: "solar:chat-unread-outline",
        handleClick: mark_as_unread,
      },
    ];
    if (message.message_type !== "audio") {
      MENU_OPTIONS.push({
        label: "Add as Note",
        icon: "akar-icons:edit",
        handleClick: handleAddPersonelNote,
      });
    }

    return MENU_OPTIONS;
  };

  // handle new message from socket
  const handle_new_message = async (data) => {
    const new_message = get_formatted_message(data.message_obj);
    if (selectedChat) {
      setMessagesList((old_messages) => [...old_messages, new_message]);
    } else {
      setSelectedChat(data.chat_obj);
      setSelectedChatUser(data.chat_obj?.receiver_profile);
    }
    scroll_to_bottom_on_message();
    get_chat_history_list(eventApiObject),
      "api/chat/list_chat_with_event_delegate/list/v1?page=0&limit=50";
    [];
  };

  const handle_edit_message = async (data) => {
    const new_message = get_formatted_message(data.message_obj);
    setMessagesList((old_messages) => {
      return old_messages.map((message) => {
        if (message._id === data.message_obj._id) {
          return new_message;
        } else {
          return message;
        }
      });
    });
    setChatList((prev) => {
      return prev.map((chat) => {
        if (chat._id == new_message.chat_id) {
          return {
            ...chat,
            last_message: new_message.message,
            message_type: new_message.message_type,
            last_message_date_time: new_message.message_date_time,
            message_id: new_message._id,
          };
        } else {
          return chat;
        }
      });
    });
  };

  const handle_delete_message = async (data) => {
    setMessagesList((old_messages) => {
      return old_messages.filter((message) => message._id !== data.message_id);
    });
    get_chat_history_list(eventApiObject),
      "api/chat/list_chat_with_event_delegate/list/v1?page=0&limit=50";
    [];
  };

  const handle_chat_message_status = async (data) => {
    if (data.chat_id == selectedChat?._id) {
      setMessagesList((old_messages) =>
        old_messages.map((message) => ({ ...message, status: data.status }))
      );
    }

    setChatList((prev) => {
      return prev.map((chat) => {
        if (chat._id == data.chat_id) {
          return { ...chat, last_message_status: data.status };
        } else {
          return chat;
        }
      });
    });
  };

  let input_element = document.getElementById("inputField");
  useEffect(() => {
    if (input_element) {
      let input_height = input_element.style.height;
      setInputHeight(input_height);
    }
  }, [input_element, message]);

  useEffect(() => {
    handleCancelUpdate();
    socket.on("send_chat_message_event_for_sender", (data) => {
      handle_new_message(data);
    });
    socket.on("update_chat_message_event_for_sender", (data) => {
      handle_edit_message(data);
    });
    socket.on("delete_chat_message_event_for_sender", (data) => {
      handle_delete_message(data);
    });
    socket.on("chat_message_status", (data) => {
      handle_chat_message_status(data);
    });

    return () => {
      socket.off("send_chat_message_event_for_sender");
      socket.off("update_chat_message_receiver");
      socket.off("delete_chat_message_event_for_sender");
      socket.off("chat_message_status");
    };
  }, [selectedUser?._id, selectedChat?._id]);

  const closeDetail = () => {
    setShowDetail(false);
  };
  const renderLink = (props) => (
    <a {...props} target="_blank" rel="noopener noreferrer">
      {props.children}
    </a>
  );
  const getMessageList = async () => {
    if (!broadcastMain) {
      return;
    }
    setIsLoadingChat(true);
    const result = await getBroadcastDetailNew(broadcastMain._id);
    if (result.code === 200) {
      setMessagesListNew(result.broadcast_message);
      setIsLoadingChat(false);
      scroll_to_bottom_on_message(true);
    } else {
      setIsLoadingChat(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const DeleteMessage = async () => {
    const result = await deleteMessage(broadcastMain._id, selectBroadCast._id);
    if (result.code === 200) {
      setOpenDelete(false);
      setMessagesListNew((old_messages) => {
        return old_messages.filter(
          (message) => message._id !== selectBroadCast._id
        );
      });
      enqueueSnackbar(result.message, { variant: "success" });
      scroll_to_bottom_on_message(true);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  useEffect(() => {
    getMessageList();
    handleRefresh();
    handleEditorInit();
  }, [broadcastMain?._id]);
  useEffect(() => {
    if (!timePop) {
      handleRefresh();
    }
  }, [!timePop]);

  return (
    <>
      <div>
        {isLoadingChat ? (
          <div className="d-flex justify-content-center">
            <CircularProgress
              className=""
              style={{
                width: 20,
                height: "100%",
                marginTop: "25%",
                marginBottom: "30%",
              }}
              color="primary"
            />
          </div>
        ) : (
          <div>
            <div
              className="user-chat-box-body ps-2 pe-2"
              // style={
              //   is_popup
              //     ? {
              //         height: `calc(64vh - ${inputHeight} - ${
              //           file || image ? "85px" : "0px"
              //         })`,
              //         paddingBottom: "10px",
              //         overflow: isLoadingMore ? "hidden" : "scroll",
              //       }
              //     : {
              //         height: `calc(67vh - ${inputHeight})`,
              //         overflow: isLoadingMore ? "hidden" : "scroll",
              //       }
              // }
            >
              {/* {selectedChat && !isLoadingChat && count > messagesList.length && (
                  <div className="text-center">
                    {isLoadingMore ? (
                      <CircularProgress
                        className="mt-3"
                        style={{ width: 20, height: 20 }}
                        color="primary"
                      />
                    ) : (
                      <span
                        ref={lastBookElementRef}
                        onClick={loadMoreData}
                        id="load-more-messages"
                      >
                        <CircularProgress
                          className="mt-3"
                          onClick={loadMoreData}
                          style={{ width: 20, height: 20 }}
                          color="primary"
                        />
                      </span>
                    )}
                  </div>
                )} */}

              {!broadcastMain ? (
                <div
                  className="d-flex justify-content-center align-items-center no-chat-wrapper"
                  style={
                    is_popup
                      ? {
                          height: `calc(64vh - ${inputHeight} - ${
                            file || image ? "85px" : "0px"
                          })`,
                          paddingBottom: "10px",
                          overflow: isLoadingMore ? "hidden" : "scroll",
                        }
                      : {
                          height: `calc(67vh - ${inputHeight})`,
                          overflow: isLoadingMore ? "hidden" : "scroll",
                        }
                  }
                >
                  <div className="no-chat-div">
                    <Icon
                      className="no-chat-image"
                      icon="material-symbols:chat-bubble-outline"
                    />
                  </div>
                </div>
              ) : (
                <>
                  {messageListNew.length > 0 ? (
                    messageListNew.map((item, index) => {
                      let message_date_time = "";
                      let createdTime = "";
                      if (item.createdAt) {
                        createdTime = get_date_with_user_time_zone(
                          item.createdAt,
                          "DD-MM-YYYY hh:mm A",
                          userInfo.time_zone,
                          userInfo.adminTimeZone
                        );
                      }
                      if (item.publish_date_time) {
                        message_date_time = moment
                          .utc(item.publish_date_time)
                          .format("DD-MM-YYYY hh:mm A");
                      } else {
                        message_date_time = moment
                          .utc(item.schedule_date_time)
                          .format("DD-MM-YYYY hh:mm A");
                      }
                      return (
                        <div className="w-100 d-flex justify-content-end control-dots">
                          {item.message_type == "schedule" && (
                            <div className="chat-message-dots">
                              <CustomPopover
                                menu={handleMenu(item)}
                                data={item}
                              />
                            </div>
                          )}
                          <div
                            className={`chat-outgoing `}
                            style={{
                              backgroundColor: is_popup
                                ? "var(--background-primary-color)"
                                : "var(--background-secondary-color)",
                            }}
                          >
                            {item.image && (
                              <img
                                className="mb-2"
                                src={s3baseUrl + item.image}
                              ></img>
                            )}

                            <Markdown
                              options={{
                                overrides: { a: { component: renderLink } },
                              }}
                            >
                              {item.message}
                            </Markdown>

                            {item.message_content_type == "audio" &&
                              item.audio_url && (
                                <ReactAudioPlayer
                                  audio_url={s3baseUrl + item.audio_url}
                                  duration={item.audio_duration}
                                />
                              )}
                            <div
                              className="chat-message-time d-flex mt-1 justify-content-end "
                              style={{ textAlign: "end" }}
                            >
                              {item.schedule_date_time &&
                                item.publish_date_time && (
                                  <Tooltip
                                    title={
                                      "This message was published at " +
                                      message_date_time +
                                      " Europe/Dublin time."
                                    }
                                  >
                                    <img
                                      src={details}
                                      alt="details"
                                      className="pointer me-1 mt-1"
                                      style={{ height: 16, width: 16 }}
                                    />
                                  </Tooltip>
                                )}
                              <div className="ticket-icon me-1 ">
                                <Tooltip
                                  title={
                                    item.publish_date_time
                                      ? "deliver"
                                      : "This message will be published on " +
                                        message_date_time +
                                        " Europe/Dublin time."
                                  }
                                >
                                  <Icon
                                    className="message-seen"
                                    fontSize={16}
                                    icon={
                                      item.publish_date_time
                                        ? "mdi:tick-all"
                                        : "mdi:clock-time-four-outline"
                                    }
                                  />
                                </Tooltip>
                              </div>
                              <Tooltip
                                title={
                                  item.publish_date_time
                                    ? createdTime
                                    : "Message has been pusblished on " +
                                      message_date_time
                                }
                              >
                                <span
                                  className="message-date-time mt-1"
                                  style={{ color: "rgb(150, 150, 150) " }}
                                >
                                  {createdTime ?? message_date_time}
                                </span>
                              </Tooltip>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div
                      className="d-flex justify-content-center align-items-center no-chat-wrapper"
                      style={
                        is_popup
                          ? {
                              height: `calc(64vh - ${inputHeight} - ${
                                file || images ? "85px" : "0px"
                              })`,
                              paddingBottom: "10px",
                              overflow: isLoadingMore ? "hidden" : "scroll",
                            }
                          : {
                              height: `calc(67vh - ${inputHeight})`,
                              overflow: isLoadingMore ? "hidden" : "scroll",
                            }
                      }
                    >
                      <div className="no-chat-div">
                        <Icon
                          className="no-chat-image"
                          icon="material-symbols:chat-bubble-outline"
                        />
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        )}
      </div>
      {broadcastMain && (
        <div className="chat-right-bottom">
          <div
            className="chat-send-message-wrapper"
            style={{
              backgroundColor: is_popup
                ? "var(--background-secondary-color)"
                : "var(--background-primary-color)",
              paddingBottom: is_popup ? "10px" : "",
            }}
          >
            {/* {openFormatter && <hr className="mb-1 mt-0" />} */}
            {isStartRecording ? (
              <VoiceRecorder
                handleSubmit={setAudioScadule}
                handleCancel={handleCancelAudio}
              />
            ) : (
              <div>
                <form
                  onSubmit={handleSubmitMessage}
                  className="d-flex align-items-end w-100"
                >
                  {!file && !images && (
                    <span className="">
                      <input
                        color="primary"
                        accept="image/*"
                        type="file"
                        id="message-chat-upload-button"
                        style={{ display: "none" }}
                        name=""
                        onChange={handleUploadFile}
                      />
                      <label>
                        <Tooltip title="Add Image" placement="top">
                          <IconButton
                            onClick={() => {
                              document.getElementById(
                                "message-chat-upload-button"
                              ) &&
                                document
                                  .getElementById("message-chat-upload-button")
                                  .click();
                            }}
                            className="upload-image-icon mb-2"
                          >
                            <AddPhotoAlternateIcon />
                          </IconButton>
                        </Tooltip>
                      </label>
                    </span>
                  )}
                  <div className="w-100">
                    <div
                      className={`chat-formatters-box d-flex ${
                        openFormatter ? "chat-formatters-border" : ""
                      }`}
                      style={{
                        backgroundColor: is_popup
                          ? "var(--background-secondary-color)"
                          : "var(--background-primary-color)",
                      }}
                    >
                      {openFormatter && (
                        <>
                          <div
                            className="formatter-icon"
                            onClick={handleOnBold}
                          >
                            <Icon
                              fontSize="15"
                              style={{ color: "var(--portal-theme-primary)" }}
                              icon="octicon:bold-24"
                            />
                          </div>
                          <div
                            className="formatter-icon"
                            onClick={handleOnItalic}
                          >
                            <Icon
                              fontSize="15"
                              style={{ color: "var(--portal-theme-primary)" }}
                              icon="fe:italic"
                            />
                          </div>
                          <div
                            className="formatter-icon"
                            onClick={handleOpenLinkPopup}
                          >
                            <Icon
                              fontSize="15"
                              style={{ color: "var(--portal-theme-primary)" }}
                              icon="ion:link"
                            />
                          </div>
                        </>
                      )}
                    </div>
                    {images && (
                      <div
                        className="chat-image-preview-box"
                        style={{
                          borderRadius: openFormatter ? "0px" : "15px 15px 0 0",
                          backgroundColor: is_popup
                            ? "var(--background-primary-color) "
                            : "var(--background-secondary-color)",
                        }}
                      >
                        <div className="chat-preview-image chat-image">
                          <img
                            className="chat-preview-image"
                            src={s3baseUrl + images}
                            alt=""
                          />

                          <Icon
                            className="cross"
                            onClick={handleRemoveFile}
                            icon="charm:circle-cross"
                          />
                        </div>
                      </div>
                    )}
                    {file && (
                      <div
                        className="chat-image-preview-box"
                        style={{
                          borderRadius: openFormatter ? "0px" : "15px 15px 0 0",
                          backgroundColor: is_popup
                            ? "var(--background-primary-color) "
                            : "var(--background-secondary-color)",
                        }}
                      >
                        <div className="chat-preview-image chat-image">
                          <img
                            className="chat-preview-image"
                            src={get_local_preview(file)}
                            alt=""
                          />

                          <Icon
                            className="cross"
                            onClick={handleRemoveFile}
                            icon="charm:circle-cross"
                          />
                        </div>
                      </div>
                    )}

                    <div className="position-relative">
                      <Icon
                        fontSize={20}
                        className="show-hide-chat-formatter"
                        icon={`${
                          openFormatter
                            ? "mingcute:down-line"
                            : "fluent:text-edit-style-16-regular"
                        }`}
                        onClick={handleToggleFormatter}
                      />
                      <TextareaAutosize
                        id="inputField"
                        className={`chat-send-input chat-send-input-rare ${
                          file || images || openFormatter
                            ? "border-with-image"
                            : ""
                        }`}
                        style={{
                          backgroundColor: is_popup
                            ? "var(--background-primary-color) "
                            : "var(--background-secondary-color)",
                        }}
                        rows={4}
                        type="text"
                        value={message}
                        minRows={4}
                        maxRows={6}
                        onChange={(e) => setMessage(e.target.value)}
                        placeholder="Write your message..."
                        required={!(images || file)}
                        autoFocus
                      />
                    </div>
                  </div>
                  {!message && !images && !file && !isLoading && (
                    <Tooltip title="Start Recording">
                      <IconButton
                        className="ms-2 mb-2"
                        onClick={handleStartRecording}
                      >
                        <MicIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  {(message || images || file) && !isLoading && (
                    <IconButton type="submit" className="ms-2 mb-2">
                      <SendIcon />
                    </IconButton>
                  )}
                  {isLoading && (
                    <IconButton className="ms-2 mb-2">
                      <CircularProgress
                        style={{
                          width: 23,
                          height: 23,
                        }}
                      />
                    </IconButton>
                  )}
                  {isUpdate && (
                    <IconButton
                      onClick={handleCancelUpdate}
                      className="ms-2 mb-2"
                    >
                      <CloseIcon />
                    </IconButton>
                  )}
                </form>
              </div>
            )}
          </div>
        </div>
      )}
      <CustomConfirmation
        title={"Are you sure you want to delete this message?"}
        open={openDelete}
        setOpen={setOpenDelete}
        handleAgree={DeleteMessage}
      />

      <GeneralPopUpModel
        open={openLink}
        setOpen={setOpenLink}
        title={"Insert Link"}
        componentToPassDown={<LinkPopup handleAddLink={handleAddLink} />}
      />
      {showDetail && (
        <BoardcastDetail
          showDetail={showDetail}
          closeDetail={closeDetail}
          drawerState={drawerState}
          setDrawerState={setDrawerState}
          broadcastMain={broadcastMain}
        />
      )}
      <GeneralPopUpModel
        open={timePop}
        setOpen={setTimePop}
        title={"Broadcast"}
        componentToPassDown={
          <ScheduleMessage
            setTimePop={setTimePop}
            setInputs={setInputs}
            inputs={inputs}
            isAudio={isAudio}
            isLoading={isLoading}
            setAudioScadule={handleSendAudio}
            handleSendSchedule={handleSendSchedule}
          />
        }
      />
    </>
  );
}

export default BroadCastChatMessages;
