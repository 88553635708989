import React from "react";
import CurrentUpcomingEvents from "./CurrentUpcomingEvents";

function RightPartEvent({
  current_events_array,
  upcoming_events_array,
  eventDetail,
}) {
  return (
    <div className="community-left-sidebar">
      <CurrentUpcomingEvents
        upcomingEvents={upcoming_events_array}
        currenEvents={current_events_array}
        eventDetail={eventDetail}
      />
    </div>
  );
}

export default RightPartEvent;
