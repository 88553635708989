import React from "react";
import { s3baseUrl } from "src/config/config";
import CustomizedBadges from "src/components/BadgeLabel";
import RecordNotFound from "src/components/RecordNotFound";
import { Avatar } from "@mui/material";

function LeaderboardCard({ title, data }) {
  return (
    <div className="profile-cards mb-3">
      <h4 className="p-3 pb-0">
        {title ? title : "Monthly New Leads Leaderboard"}
      </h4>
      <hr />
      <div className="monthly-leads p-3 pt-0">
        {data?.length < 1 ? (
          <RecordNotFound title="Leads" heading="No Leads Present" />
        ) : (
          data?.map((value) => {
            return (
              <div className="d-flex ">
                <Avatar
                  className="avatar-adjust"
                  sx={{ width: 30, height: 30 }}
                  alt={name}
                  src={s3baseUrl + value?.image?.thumbnail_1}
                />
                <div className="text-start comment-icon leaderBoardWidth ">
                  <div className="d-flex justify-content-between ">
                    <div className="card-title pt-2 mb-0 w-75">
                      {value?.first_name.replace(" ", "\n") +
                        " " +
                        value?.last_name}
                    </div>
                    <div>
                      {value?.weekly_count ? (
                        <CustomizedBadges count={value?.weekly_count} />
                      ) : (
                        value?.monthly_count && (
                          <CustomizedBadges count={value?.monthly_count} />
                        )
                      )}
                      {value?.weekly_commission ? (
                        <CustomizedBadges
                          count={
                            "£" + Number(value?.weekly_commission?.toFixed(2))
                          }
                        />
                      ) : (
                        value?.monthly_commission && (
                          <CustomizedBadges
                            count={
                              "£" +
                              Number(value?.monthly_commission?.toFixed(2))
                            }
                          />
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
}

export default LeaderboardCard;
