import React from "react";
import EventVideos from "./EventVideos";
import NoticeBoard from "./NoticeBoard";
import CurrentUpcomingEvents from "./CurrentUpcomingEvents";

function LeftPart({ feeds_type, event_category, event_slug, data, list_type }) {
  return (
    <div className="community-left-sidebar">
      {list_type == "event" ? (
        <EventVideos event_category={event_category} event_slug={event_slug} />
      ) : (
        <>
          {data?.notice_board && (
            <NoticeBoard notice_board={data.notice_board} />
          )}
          <CurrentUpcomingEvents
            upcomingEvents={data?.upcoming_events_array}
            currenEvents={data?.current_events_array}
            feeds_type={feeds_type}
          />
        </>
      )}
    </div>
  );
}

export default LeftPart;
