import { CircularProgress, Tooltip } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { delete_team_api, team_list_api } from "src/DAL/salesTeam/SalesTeam";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import CustomConfirmation from "src/components/menuIcons/CustomConfirmation";

import { makeStyles } from "@mui/styles";
import { s3baseUrl } from "src/config/config";
import SalesTeamFilterDateBase from "./SalesTeamFilterDateBase";
import CustomDrawer from "src/components/DrawerForm/CustomDrawer";
import { Icon } from "@iconify/react";
import roundFilterList from "@iconify/icons-ic/round-filter-list";
import FilteredChip from "src/components/GeneralComponents/FilteredChip";
import moment from "moment";
import { useSnackbar } from "notistack";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
const EMPTY_FILTER = {
  start_date: null,
  end_date: null,

  commission_text: null,
  search_by_commission: false,
  commission_from: 0,
  commission_to: 0,
  status: true,
};
const SalesTeam = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [deleteDoc, setDeleteDoc] = useState("");
  const [teamList, SetTeamList] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [filterData, setFilterData] = useState(EMPTY_FILTER);
  const [filterUpdated, setFilterUpdated] = useState(EMPTY_FILTER);
  const [filterDrawerState, setFilterDrawerState] = useState(false);

  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState("");
  const [searchText, setSearchText] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(0);
  const handleChangeOthers = (name, value) => {
    setFilterData((values) => ({ ...values, [name]: value }));
  };

  const handleOpenFilterDrawer = () => {
    setFilterDrawerState(true);
  };
  const handleCloseFilterDrawer = () => {
    setFilterDrawerState(false);
  };
  const getTeamListing = async (filter_data) => {
    let postData = { ...filter_data };
    postData.commission_from = +postData.commission_from;
    postData.commission_to = +postData.commission_to;

    if (postData.start_date) {
      postData.start_date = moment(postData.start_date).format("YYYY-MM-DD");
    }
    if (postData.end_date) {
      postData.end_date = moment(postData.end_date).format("YYYY-MM-DD");
    }
    setIsLoading(true);
    const result = await team_list_api(page, rowsPerPage, postData);
    if (result.code == 200) {
      console.log(result, "resultresult");
      const team_member = result.sales_team.map((item) => {
        let name = item.first_name + " " + item.last_name;
        let commission_due = item.commission_due.toFixed(2);
        let commission_paid = item.commission_paid.toFixed(2);
        let total_commission = item.total_commission.toFixed(2);

        return {
          ...item,
          name,
          commission_due: "£" + commission_due,
          commission_paid: "£" + commission_paid,
          total_commission: "£" + total_commission,
          contact_no: item.contact_number ? "+" + item.contact_number : "N/A",
          table_avatar: {
            src: s3baseUrl + item.image.thumbnail_1,
            alt: item.first_name,
          },
          status: item.status,
        };
      });
      const chipData = { ...filter_data };
      let commission_text = "";
      let commission_date = " ";
      if (chipData.search_text) {
        delete chipData.search_text;
      }
      if (chipData.search_by_commission) {
        commission_text = `Due Commission From ${chipData.commission_from} To ${chipData.commission_to}`;
        chipData.commission_text = commission_text;
        delete chipData.search_by_commission;
        delete chipData.commission_from;
        delete chipData.commission_to;
      }
      if (chipData.start_date) {
        chipData.commission_date = `Commission Date from: ${moment(
          postData.start_date
        ).format("DD-MM-YYYY")} To ${moment(postData.end_date).format(
          "DD-MM-YYYY"
        )}`;
        delete chipData.start_date;
        delete chipData.end_date;
      }
      setFilterData(() => {
        return {
          ...filter_data,
          commission_text,
        };
      });

      setFilterUpdated(chipData);
      SetTeamList(team_member);
      setTotalPages(result.total_pages);
      setTotalCount(result.total_count);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };
  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPageCount(1);
    setPage(0);
  };
  const handleEdit = (value) => {
    navigate(`/sales-team/add-sales-team/${value?._id}`, {
      state: value,
    });
  };
  const handleCommissionDetail = (value) => {
    navigate(`/sales-team/commission-detail/${value?._id}`);
  };
  const handleAgreeDelete = (value) => {
    setOpenDelete(true);
    setDeleteDoc(value);
  };

  const searchFunction = (e) => {
    if (e) {
      e.preventDefault();
    }
    localStorage.setItem("card_search", searchText);
    setPage(0);
    setPageCount(1);
    filterData.search_text = searchText;
    localStorage.setItem("sales_team_filter_data", JSON.stringify(filterData));
    getTeamListing(filterData);
    handleCloseFilterDrawer();
  };
  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Commission Detail",
      icon: "akar-icons:edit",
      handleClick: handleCommissionDetail,
    },
    // {
    //   label: "Manage Navitems access",
    //   icon: "akar-icons:edit",
    //   handleClick: handleManageNavItemAccess,
    // },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];
  const handleDelete = async () => {
    setOpenDelete(false);
    const result = await delete_team_api(deleteDoc._id);
    if (result.code === 200) {
      SetTeamList((categoriesData) => {
        return categoriesData.filter((data) => data._id !== deleteDoc._id);
      });

      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleNavigate = () => {
    navigate("/sales-team/add-sales-team");
  };
  const handleClearFilter = () => {
    getTeamListing(EMPTY_FILTER);
    localStorage.removeItem("sales_team_filter_data");
    setFilterDrawerState(false);
    setSearchText("");
  };
  const handleDeleteChip = (data) => {
    console.log(data, "datadata");
    if (!data.commission_text) {
      data.commission_from = 0;
      data.commission_to = 0;
      data.search_by_commission = false;
    }
    if (!data.commission_date) {
      data.start_date = null;
      data.end_date = null;
    }
    setPage(0);
    setPageCount(1);
    getTeamListing(data);
    handleCloseFilterDrawer();
    localStorage.setItem("sales_team_filter_data", JSON.stringify(data));
  };

  const TABLE_HEAD = [
    { id: "action", label: "Action", type: "action" },
    { id: "number", label: "#", type: "number" },
    { id: "table_avatar", label: "Profile", type: "thumbnail" },
    {
      id: "name",
      label: "Name",
      renderData: (row) => {
        return (
          <>
            <Tooltip title={"View Commission Detail"}>
              <span
                onClick={() => handleCommissionDetail(row)}
                style={{ cursor: "pointer" }}
              >
                {row.name}
              </span>
            </Tooltip>
          </>
        );
      },
    },
    { id: "email", label: "Email" },
    { id: "contact_no", label: "Phone" },
    { id: "total_commission", label: "Total Commission" },
    { id: "commission_paid", label: "Paid Commission" },
    { id: "commission_due", label: "Due Commission" },

    { id: "status", label: "Status", type: "row_status" },
  ];

  useEffect(() => {
    let filter_data = EMPTY_FILTER;
    let find_filter = localStorage.getItem("sales_team_filter_data");
    if (find_filter) {
      filter_data = JSON.parse(find_filter);
    }
    if (filter_data.search_text) {
      setSearchText(filter_data.search_text);
    }
    setFilterData(filter_data);
    setFilterUpdated(filter_data);
    getTeamListing(filter_data);
  }, [rowsPerPage, page]);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-8">
            <h2>Sales Team</h2>
          </div>
          <div className="col-4 mt-2  ps-0" style={{ textAlign: "end" }}>
            <button
              className="small-contained-button me-2 mt-1 mb-4"
              onClick={handleOpenFilterDrawer}
            >
              Filters &nbsp;&nbsp; <Icon icon={roundFilterList} />
            </button>
            <button
              className="small-contained-button me-2 mt-1 mb-4 "
              onClick={handleNavigate}
            >
              Add Sales Team
            </button>
          </div>
        </div>

        <div className="row">
          <FilteredChip
            data={filterUpdated}
            tempState={filterData}
            EMPTY_FILTER={EMPTY_FILTER}
            onDeleteChip={handleDeleteChip}
            onClear={handleClearFilter}
          />
          <div className="col-12 mt-1 ">
            <CustomMUITable
              TABLE_HEAD={TABLE_HEAD}
              data={teamList}
              MENU_OPTIONS={MENU_OPTIONS}
              className="card-with-background"
              custom_pagination={{
                total_count: totalCount,
                rows_per_page: rowsPerPage,
                page: page,
                handleChangePage: handleChangePage,
                onRowsPerPageChange: handleChangeRowsPerPage,
              }}
              custom_search={{
                searchText: searchText,
                setSearchText: setSearchText,
                handleSubmit: searchFunction,
              }}
              pagePagination={true}
              pageCount={pageCount}
              totalPages={totalPages}
              handleChangePages={handleChangePages}
            />
          </div>
        </div>
        <CustomConfirmation
          open={openDelete}
          setOpen={setOpenDelete}
          title={"Are you sure you want to delete?"}
          handleAgree={handleDelete}
        />
        <CustomDrawer
          isOpenDrawer={filterDrawerState}
          onOpenDrawer={handleOpenFilterDrawer}
          onCloseDrawer={handleCloseFilterDrawer}
          pageTitle="Filters"
          componentToPassDown={
            <SalesTeamFilterDateBase
              filterData={filterData}
              setFilterData={setFilterData}
              searchSubmitFilter={searchFunction}
              handleChangeOthers={handleChangeOthers}
              handleClearFilter={handleClearFilter}
            />
          }
        />
      </div>
    </>
  );
};

export default SalesTeam;
