import React, { useState } from "react";
import { CircularProgress, IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import RecordNotFound from "src/components/RecordNotFound";

import { makeStyles } from "@mui/styles";
import AnswersList from "src/components/GeneralComponents/GeneralQuestions/AnswersList";
import { answers_detail_by_user_and_question_api } from "src/DAL/GeneralQuestions/GeneralQuestions";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function QuestionsAnswers() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { search } = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [userInfo, setUserInfo] = useState({});
  const [questions, setQuestions] = useState([]);
  const created_for_id = new URLSearchParams(search).get("created_for_id");
  const created_for = new URLSearchParams(search).get("created_for");
  const member_id = new URLSearchParams(search).get("member_id");

  const get_questions_list = async () => {
    let postData = {
      created_for_id: created_for_id ? created_for_id : "",
      member_id: member_id,
      created_for: created_for,
    };
    const result = await answers_detail_by_user_and_question_api(postData);
    if (result.code === 200) {
      let data = result.questionnaire.map((question) => {
        return {
          ...question,
          isExpanded: true,
        };
      });
      setUserInfo(result.member);
      setQuestions(data);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    get_questions_list();
  }, []);
  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <IconButton
            className="back-screen-button"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon />
          </IconButton>
          {userInfo?.first_name ? (
            <span className="sale-page-title">
              {userInfo?.first_name +
                " " +
                userInfo?.last_name +
                " (" +
                userInfo?.email +
                ")"}
            </span>
          ) : null}
        </div>
      </div>
      {questions.length == 0 ? (
        <RecordNotFound title="Questions " />
      ) : (
        <AnswersList questions={questions} />
      )}
    </div>
  );
}
