import { Avatar, Tooltip } from "@mui/material";
import React from "react";
import { s3baseUrl } from "src/config/config";
import { useChat } from "src/Hooks/ChatContext/ChatContext";
import {
  get_date_with_user_time_zone,
  string_avatar,
} from "src/utils/constants";
import {
  _delete_message,
  _get_chat_message,
  _send_message,
  _update_message,
} from "src/DAL/Chat/Chat";
import { Icon } from "@iconify/react";
import ChatMessages from "../ChatMessages/ChatMessages";
import { useContentSetting } from "src/Hooks/ContentSettingState";

function ChatRight() {
  const { userInfo } = useContentSetting();
  const { selectedChat, handleCloseChat, selectedChatUser } = useChat();
  const receiver_profile = selectedChatUser;

  return (
    <div className="chat-right-wrapper">
      <div className="chat-right-head p-1 d-flex justify-content-between">
        <div className="d-flex">
          {selectedChat && (
            <>
              <div className="user-profile">
                <Avatar
                  src={s3baseUrl + receiver_profile?.profile_image}
                  style={{ height: 45, width: 45 }}
                >
                  {string_avatar(receiver_profile?.first_name[0])}
                </Avatar>
                <div
                  className={receiver_profile?.is_online ? "online" : "offline"}
                ></div>
              </div>
              <div className="chat-profile-data">
                <div className="chat-profile-name ps-2 text-capitalize">
                  {receiver_profile?.first_name +
                    " " +
                    receiver_profile?.last_name}
                  {!receiver_profile?.is_online &&
                    receiver_profile.last_login_activity && (
                      <p className="chat-last-seen mb-0">
                        Last seen
                        {" " +
                          get_date_with_user_time_zone(
                            receiver_profile.last_login_activity,
                            "DD MMM YYYY [,] hh:mm A",
                            userInfo.time_zone,
                            userInfo.adminTimeZone
                          )}
                      </p>
                    )}
                </div>
              </div>
            </>
          )}
        </div>

        {receiver_profile && (
          <div className="me-2" onClick={handleCloseChat}>
            <Icon
              fontSize={18}
              className="chat-box-cross-icon"
              icon="material-symbols:close"
            />
          </div>
        )}
      </div>
      <ChatMessages is_popup={false} />
    </div>
  );
}

export default ChatRight;
