import React, { useState } from "react";
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { convertCurrencyToSign } from "src/utils/constants";
import { get_bank_request_url_api } from "src/DAL/PaymentRequest/paymentRequest";
import { useSnackbar } from "notistack";
import CopyToClipboard from "react-copy-to-clipboard";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const BankPaymetDetail = ({
  infoDetail,
  bankLoading,
  memberId,
  setshowPop,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [copyText, setCopyText] = useState("");

  let member_info = infoDetail?.payment_request?.member_info;
  let payment_in_euro = infoDetail?.payment_in_euro;
  let payment_in_pound = infoDetail?.payment_in_pound;

  const [checked, setChecked] = useState({
    paymentEuro: true,
    paymentPound: false,
  });

  const { paymentEuro, paymentPound } = checked;

  const handleChange = (event) => {
    const { name, checked: isChecked } = event.target;
    setChecked({
      paymentEuro: name === "paymentEuro" ? isChecked : false,
      paymentPound: name === "paymentPound" ? isChecked : false,
    });
  };

  const CopyBankPayment = async () => {
    let payable_currency = payment_in_pound?.currency;
    if (paymentEuro == true) {
      payable_currency = payment_in_euro?.currency;
    }

    const result = await get_bank_request_url_api(memberId, payable_currency);
    if (result.code === 200) {
      setCopyText(result.redirect_url);
      setTimeout(() => {
        document.getElementById("copy_url").click();
      }, 100);
      setshowPop(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleCopiedUrl = () => {
    enqueueSnackbar("bank URL coppied to clipboard", { variant: "success" });
  };

  if (bankLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <div className="container px-4">
      <CopyToClipboard text={copyText} onCopy={handleCopiedUrl}>
        <button id="copy_url" style={{ display: "none" }}>
          Hidden Button
        </button>
      </CopyToClipboard>
      <div className="row mt-3 mb-3 solid-border">
        <div className="col-2 mt-1 mb-2"> Name:</div>
        <div className="col-10 mt-1 text-muted">
          {member_info?.first_name + " " + member_info?.last_name}
        </div>
        <div className="col-2 mt-1 mb-2"> Email:</div>
        <div className="col-10 mt-1 text-muted overflow_auto ">
          {member_info?.email}
        </div>
        <div className="col-8 mt-1">
          <FormControlLabel
            control={
              <Checkbox
                checked={paymentEuro}
                onChange={handleChange}
                name="paymentEuro"
              />
            }
            label="Payment In Euro"
          />
        </div>
        <div className="col-4 mt-3 text-muted">
          {convertCurrencyToSign(payment_in_euro?.currency) +
            " " +
            payment_in_euro?.amount}
        </div>
        <div className="col-8 mt-1">
          <FormControlLabel
            control={
              <Checkbox
                checked={paymentPound}
                onChange={handleChange}
                name="paymentPound"
              />
            }
            label="Payment In Pound"
          />
        </div>
        <div className="col-4 mt-3 text-muted">
          {convertCurrencyToSign(payment_in_pound?.currency) +
            " " +
            payment_in_pound?.amount}
        </div>
        <div className="col-12 text-end mt-5">
          <button onClick={CopyBankPayment} className="small-contained-button">
            Copy Bank Url
          </button>
        </div>
      </div>
    </div>
  );
};

export default BankPaymetDetail;
