import React from "react";
import { s3baseUrl } from "src/config/config";
import { Avatar } from "@mui/material";
import RecordNotFound from "../RecordNotFound";
import { get_short_string } from "src/utils/constants";
import { htmlDecode } from "src/utils/convertHtml";

function SourceSession({ sourceSessions }) {
  return (
    <div className="mb-3 " style={{ height: "400px", overflowY: "scroll" }}>
      <h4 className="p-3 pb-0">Goal Statement</h4>
      <hr />
      <div className="p-3 pb-0 pt-0">
        {sourceSessions?.length < 1 ? (
          <RecordNotFound title="Questions" />
        ) : (
          sourceSessions?.map((value, i) => {
            return (
              <div className="d-flex">
                {/* <Avatar
                  className="avatar-adjust"
                  sx={{ width: 40, height: 40 }}
                  alt={value?.title}
                  src={s3baseUrl + value?.image.thumbnail_2}
                /> */}
                <b>{i + 1}.</b>
                <div className="text-start comment-icon w-100 ps-2 source-session">
                  <div className="card-title">
                    <h3 className="mb-0">{value?.question_statement}</h3>
                    {value?.answer ? (
                      <p> {htmlDecode(value?.answer)}</p>
                    ) : (
                      <p>No Answer</p>
                    )}
                    {/* <p>{get_short_string(value?.answer, 30)}</p> */}
                  </div>
                </div>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
}

export default SourceSession;
