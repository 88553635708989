import { invokeApi } from "../../bl_libs/invokeApi";

export const digitalAssetsListingApi = async (data) => {
  const requestObj = {
    path: `api/digital_asset/assets_list/delegate`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const digital_Assets_listing_with_category = async (category_id) => {
  const requestObj = {
    path: `api/digital_asset/assets_list/delegate/${category_id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const assest_category_list_api = async (data) => {
  const requestObj = {
    path: `api/assest_category/list/active`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
