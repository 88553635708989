import React from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useContentSetting } from "src/Hooks/ContentSettingState";
import { COMMUNITY_LEVELS, show_proper_words } from "src/utils/constants";
import SourceFeedLinks from "./SourceFeedLinks";
import LeaderboardCard from "./LeaderboardCard";
import SearchFilterFeeds from "./SeaarchFilter";

function RightPart({
  data,
  userType,
  handleChangeUserType,
  salePagesList,
  affiliateUrl,
  list_type,
  searchFeeds,
  setSearchFeeds,
  handleChangeSearch,
}) {
  const { userInfo, isAccess } = useContentSetting();

  return (
    <>
      {list_type === "the_cosmos" ? (
        <>
          {/* {window.screen.width > 991 && userInfo.is_super_delegate && ( */}
          {window.screen.width > 991 && isAccess.cosmos_feeds_filters && (
            <div className="thesource-filter mb-3">
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">
                  Select Level
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={userType}
                  label="Select Level"
                  className="text-capitalize"
                  onChange={handleChangeUserType}
                >
                  {isAccess.cosmos_feed_filters &&
                    isAccess.cosmos_feed_filters.length > 0 &&
                    isAccess.cosmos_feed_filters.map((item, index) => {
                      return (
                        <MenuItem value={item} className="text-capitalize">
                          {show_proper_words(
                            item == "marketing" ? "marketing team" : item
                          )}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </div>
          )}
          <LeaderboardCard
            title="Monthly New Leads Leaderboard"
            data={data?.consultant_list_by_monthly_count}
          />
          <LeaderboardCard
            title="Weekly New Leads Leaderboard"
            data={data?.consultant_list_by_weekly_count}
          />
        </>
      ) : (
        <>
          <div className="thesource-filter mb-3 ">
            <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label">
                Select Level
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={userType}
                label="Select Level"
                onChange={handleChangeUserType}
              >
                {COMMUNITY_LEVELS.map((val) => (
                  <MenuItem value={val.name} key={val.name}>
                    {val.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          {(list_type == "all_source" || list_type == "the_source") && (
            <>
              {isAccess.is_feed_search_allowed && (
                <div className="thesource-filter mb-3">
                  <SearchFilterFeeds
                    setSearchFeeds={setSearchFeeds}
                    searchFeeds={searchFeeds}
                    handleChangeSearch={handleChangeSearch}
                    list_type={list_type}
                    userType={userType}
                  />
                </div>
              )}
            </>
          )}
          {salePagesList?.length > 0 && (
            <SourceFeedLinks
              title="Links"
              data={salePagesList}
              affiliateUrl={affiliateUrl}
            />
          )}
        </>
      )}
    </>
  );
}

export default RightPart;
