import React, { useState, useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { htmlDecode } from "src/utils/convertHtml";

const BookingQuestions = ({ data }) => {
  const [questionMessages, setQuestionMessages] = useState([]);

  const expandArea = (index) => {
    let temp_state = [...questionMessages];
    let temp_element = { ...temp_state[index] };
    if (temp_element.isExpanded === false) {
      temp_element.isExpanded = true;
    } else {
      temp_element.isExpanded = false;
    }
    temp_state[index] = temp_element;
    setQuestionMessages(temp_state);
  };

  useEffect(() => {
    let questionsArray = [];
    data.questions.map((value, index) => {
      if (index === 0) {
        questionsArray.push({
          ...value,
          isExpanded: true,
        });
      } else {
        questionsArray.push({
          ...value,
          isExpanded: false,
        });
      }
      setQuestionMessages(questionsArray);
    });
  }, [data]);

  return (
    <>
      <div className="row mt-3">
        <div className="col">
          {questionMessages.length > 0
            ? questionMessages.map((question, index) => {
                return (
                  <div
                    className="card mb-2 booking-questions"
                    key={question._id}
                  >
                    <Accordion expanded={question.isExpanded}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        onClick={() => expandArea(index)}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className="svg-color text-white"
                      >
                        <div className="d-flex goal_statement_yes_icons justify-content-between w-100">
                          <Typography>
                            {htmlDecode(question.question)}
                          </Typography>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <p>{question.answer}</p>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                );
              })
            : ""}
        </div>
      </div>
    </>
  );
};

export default BookingQuestions;
