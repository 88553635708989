import React from "react";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import { useState, useEffect, useCallback, useRef } from "react";
import { makeStyles } from "@mui/styles";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {
  TextField,
  InputLabel,
  Button,
  Box,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  Select,
  MenuItem,
} from "@mui/material";
import moment from "moment";
import roundClearAll from "@iconify/icons-ic/round-clear-all";
import { get_root_value } from "src/utils/domUtils";
import { Icon } from "@iconify/react";
import { ListProgressCategory } from "src/DAL/Progress/Progress";
const useStyles = makeStyles(() => ({
  paper: {
    background: get_root_value("--popup-background-color"),
    color: get_root_value("--input-text-color"),
  },
}));
export const ProgressFilter = ({
  inputs,
  setInputs,
  check,
  setCheck,
  dataList,
  onCloseDrawer,
  value,
  handleChange,
  setSelectedValue,
  state,
  setState,
  categoryData,
  setCategoryData,
}) => {
  const classes = useStyles();
  const [show, setShow] = useState("");
  const [showDates, setShowDates] = useState(false);
  // const [check, setCheck] = useState("");
  const handleChangeCategory = (event) => {
    const { name, value } = event.target;
    setState(value);
  };
  const handleChangeStatus = (value) => {
    setCheck(value);

    if (value == false) {
      setInputs({
        start_date: null,
        end_date: null,
      });
    }
  };
  // const handleChangeDate = (name, value) => {
  //   setInputs((prevState) => {
  //     return {
  //       ...prevState,
  //       [name]: moment(value).format("YYYY-MM-DD"),
  //     };
  //   });
  // };
  const handleChangeDate = (name, value) => {
    const formattedDate = moment(value).format("YYYY-MM-DD");

    setInputs((prevState) => {
      return {
        ...prevState,
        [name]: formattedDate !== "Invalid date" ? formattedDate : null,
      };
    });
  };

  const handleFilter = (e) => {
    e.preventDefault();

    onCloseDrawer();
    let selectedCat = categoryData.find((val) => val._id == state);

    dataList(
      `api/progress_report/list_progress_report_by_filter_v2?page=0&limit=10`,
      inputs,
      value,
      state,
      "filter",
      selectedCat
    );
  };
  const handleClearFilter = () => {
    onCloseDrawer();
    dataList(
      `api/progress_report/list_progress_report_by_filter_v2?page=0&limit=10`,
      "",
      "clear"
    );

    setInputs({
      start_date: null,
      end_date: null,
    });
    setCheck(false);
    setState("");
    setSelectedValue("all");
  };
  const getCategoryListing = async () => {
    const result = await ListProgressCategory();
    if (result.code === 200) {
      // setCategoryData(result.progress_report_category);
      const statectNone = { _id: "none", title: "None" };

      // update the categoryData state with the new object
      const updatedCategories = [
        statectNone,
        ...result.progress_report_category,
      ];
      setCategoryData(updatedCategories);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  useEffect(() => {
    getCategoryListing();
    if (state) {
      setShow(state);
    }
  }, []);

  return (
    <div className="container new-memories">
      <form onSubmit={handleFilter}>
        <div className="row">
          <FormControlLabel
            control={
              <Checkbox
                // {...getFieldProps('status')}
                // value={check}
                checked={check}
                onChange={(e) => handleChangeStatus(e.target.checked)}
              />
            }
            label="Filter By Dates"
          />
          {check == true && (
            <>
              <div className="col-12 mt-3">
                {/* <h4>Filter using dates</h4> */}

                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="From"
                    name="startDate"
                    inputFormat="dd-MM-yyyy"
                    value={inputs.start_date}
                    onChange={(e) => {
                      handleChangeDate("start_date", e);
                    }}
                    format="DD-MM-YYYY"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="inputs-fields"
                        // required={true}
                        fullWidth
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>
              <div className="col-12 mt-3">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="To"
                    name="endDate"
                    value={inputs.end_date}
                    inputFormat="dd-MM-yyyy"
                    onChange={(e) => {
                      handleChangeDate("end_date", e);
                    }}
                    format="DD-MM-YYYY"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="inputs-fields"
                        // required={true}
                        fullWidth
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>
            </>
          )}
          <FormControl component="fieldset" className="mt-4">
            <h4>Choose Type</h4>
            <RadioGroup
              aria-label="gender"
              name="gender1"
              value={value}
              onChange={handleChange}
            >
              <FormControlLabel
                value="daily"
                control={<Radio />}
                label="Daily"
              />
              <FormControlLabel
                value="weekly"
                control={<Radio />}
                label="Weekly"
              />
              <FormControlLabel
                value="monthly"
                control={<Radio />}
                label="Monthly"
              />
              <FormControlLabel
                value="custom"
                control={<Radio />}
                label="Custom"
              />
              <FormControlLabel value="all" control={<Radio />} label="All" />
            </RadioGroup>
          </FormControl>

          <div className="col-12 mt-3">
            <FormControl variant="outlined" className="mt-4">
              <InputLabel id="demo-simple-select-outlined-label">
                Progress Categories
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={state}
                onChange={(e) => handleChangeCategory(e)}
                label="Progress Categories"
                name="category"
                className="svg-color"
                sx={{
                  color: get_root_value("--input-text-color"),
                }}
                MenuProps={{
                  classes: {
                    paper: classes.paper,
                  },
                }}
              >
                {/* {state.category ? (
                  <MenuItem value={state.category} key={state.category._id}>
                    {state.category.title}
                  </MenuItem>
                ) : null} */}

                {categoryData?.map((category, index) => (
                  <MenuItem
                    value={category._id}
                    eventKey={index}
                    key={category._id}
                  >
                    {category.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <Box className="mt-4">
            <Button
              fullWidth
              size="large"
              type="submit"
              color="inherit"
              variant="outlined"
              startIcon={
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ marginRight: 5 }}
                >
                  <path
                    d="M13.1165 0.25H0.883548C0.321452 0.25 0.0378205 0.932013 0.436097 1.33029L5.3125 6.20743V11.6406C5.3125 11.8471 5.41325 12.0406 5.58242 12.1591L7.69179 13.6351C8.10794 13.9264 8.6875 13.6312 8.6875 13.1167V6.20743L13.564 1.33029C13.9615 0.932804 13.6798 0.25 13.1165 0.25Z"
                    fill={get_root_value("--portal-theme-primary")}
                  />
                </svg>
              }
            >
              Filter
            </Button>
          </Box>
        </div>
      </form>
      <Button
        fullWidth
        size="large"
        type="submit"
        color="inherit"
        variant="outlined"
        onClick={handleClearFilter}
        startIcon={<Icon icon={roundClearAll} />}
        className="mt-3"
      >
        Clear All
      </Button>
    </div>
  );
};
