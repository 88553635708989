import React, { useEffect, useState } from "react";
import {
  _check_chat_existing,
  _get_chat_detail,
  _get_chat_message,
} from "src/DAL/Chat/Chat";
import PageDescription from "src/components/GeneralComponents/PageDescription";
import ChatRightWhatsApp from "./components/Chat-Right/ChatRightWhatsApp";
import WhatsAppChatList from "./components/WhatsAppChatList";
import {
  _initiate_chat_whatsapp,
  _whatsapp_chat_list,
  _whatsapp_chat_load_more,
} from "src/DAL/WhatsApp/WhatsApp";
import { useParams } from "react-router-dom";

function WhatsAppChat() {
  const [selectedChat, setSelectedChat] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [chatList, setChatList] = useState([]);
  const [totalPagesChats, setTotalPagesChats] = useState(0);
  const [pageNumberChat, setPageNumberChat] = useState(0);
  const [isLoadingMoreChats, setIsLoadingMoreChats] = useState(false);
  const [loadMoreChatPath, setLoadMoreChatPath] = useState("");
  const { user_id } = useParams();

  const [state, setState] = useState({
    search_text: "",
    chat_type: "all",
  });

  const handleCloseChat = () => {
    setSelectedChat({});
  };

  const get_chat_list = async () => {
    setIsLoading(true);
    const result = await _whatsapp_chat_list(state);
    if (result.code === 200) {
      let chats = result.data;
      let find = chats.find((chat) => chat._id == selectedChat?._id);
      if (!find) {
        setSelectedChat({});
      }
      if (user_id) {
        let find_user = chats.find((chat) => chat.receiver_info._id == user_id);
        if (find_user) {
          setSelectedChat(find_user);
        } else {
          let postData = { receiver_id: user_id };
          const result = await _initiate_chat_whatsapp(postData);
          setSelectedChat(result.data);
          chats.unshift(result.data);
        }
      }
      setChatList(chats);
      setLoadMoreChatPath(result.load_more);
      setTotalPagesChats(result.total_page);
      setIsLoading(false);
      setPageNumberChat(1);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const loadMoreChat = async (path, chats) => {
    setIsLoadingMoreChats(true);
    const result = await _whatsapp_chat_load_more(path);
    if (result.code === 200) {
      var newArray = chats.concat(result.data);
      setChatList(newArray);
      setPageNumberChat(pageNumberChat + 1);
      setLoadMoreChatPath(result.load_more);
      setTotalPagesChats(result.total_page);
      setTimeout(() => {
        setIsLoadingMoreChats(false);
      }, 1000);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingMoreChats(false);
    }
  };

  useEffect(() => {
    get_chat_list();
  }, [state]);

  const loadMoreData = () => {
    setIsLoadingMoreChats(true);
    loadMoreChat(loadMoreChatPath, chatList);
  };

  return (
    <>
      <div className="container">
        <PageDescription
          parameter="chat_page_description"
          else_title="WhatsApp Chats"
        />
      </div>
      <div className="row w-100 chat-section mt-2">
        <WhatsAppChatList
          setSelectedChat={setSelectedChat}
          selectedChat={selectedChat}
          chatList={chatList}
          setChatList={setChatList}
          state={state}
          setState={setState}
          isLoadingChats={isLoading}
          totalPagesChats={totalPagesChats}
          setTotalPagesChats={setTotalPagesChats}
          loadMoreChat={loadMoreChat}
          loadMoreData={loadMoreData}
          loadMoreChatPath={loadMoreChatPath}
          pageNumberChat={pageNumberChat}
          isLoadingMoreChats={isLoadingMoreChats}
        />

        <div className="col-12 col-md-8 chat-right-part p-2">
          <ChatRightWhatsApp
            selectedChat={selectedChat}
            setChatList={setChatList}
            handleCloseChat={handleCloseChat}
          />
        </div>
      </div>
    </>
  );
}

export default WhatsAppChat;
