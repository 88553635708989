import React, { useState } from "react";
import Chip from "@mui/material/Chip";
import CustomConfirmation from "src/components/GeneralComponents/CustomConfirmation";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import {
  DeleteSupportTicket,
  markResolved,
} from "src/DAL/SupportTicket/SupportTicket";
import CustomDrawer from "src/components/GeneralComponents/CustomDrawer";
import AddOrUpdateTicketSection from "./AddOrUpdateTicketSection";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";

const RenderStatus = ({ item }) => {
  let is_waiting = item.ticket_status === 0 && item.response_status === 0;
  let is_open = item.ticket_status === 0 && item.response_status === 1;
  let chip_title = "Solved";
  let chip_class = "solved-ticket-color";
  if (is_waiting) {
    chip_title = "Waiting";
    chip_class = "waiting-ticket-color";
  } else if (is_open) {
    chip_title = "Answered";
    chip_class = "open-ticket-color";
  }

  return (
    <>
      <Chip
        width="140px"
        className={chip_class}
        label={chip_title}
        variant="outlined"
        size="small"
      />
    </>
  );
};

export default function TicketList({
  data,
  handle_ticket_click,
  getTickestsListing,
  setIsLoading,
}) {
  const [openDelete, setOpenDelete] = useState(false);
  const [ticketId, setTicketId] = useState("");
  const [editData, setEditData] = useState({});
  const [openResolved, setOpenResolved] = useState(false);
  const [editDrawerState, setEditDrawerState] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const handleDelete = async () => {
    setOpenDelete(false);
    setIsLoading(true);
    const result = await DeleteSupportTicket(ticketId);
    if (result.code === 200) {
      getTickestsListing("all");
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const hanleChangePage = (value) => {
    navigate(`/contact-support/${value._id}`);
  };

  const handleCloseEditDrawer = () => {
    setEditDrawerState(false);
  };

  const handleOpenEditDrawer = () => {
    setEditDrawerState(true);
  };

  const handleEdit = (value) => {
    setEditData(value);
    setEditDrawerState(true);
  };

  const handleMarkResolvedPopup = (value) => {
    setTicketId(value._id);
    setOpenResolved(true);
  };

  const handleMarkResolved = async () => {
    setOpenResolved(false);
    setIsLoading(true);
    const result = await markResolved(ticketId);
    if (result.code === 200) {
      getTickestsListing("all");
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      navigate(`/support-ticket`);
    }
  };

  const handleAgreeDelete = (value) => {
    setTicketId(value._id);
    setOpenDelete(true);
  };

  const handle_menus = (row) => {
    const MENU_OPTIONS = [
      {
        label: "Detail",
        icon: "gg:details-more",
        handleClick: hanleChangePage,
      },
      {
        label: "Delete",
        icon: "ant-design:delete-twotone",
        handleClick: handleAgreeDelete,
      },
    ];
    if (row.ticket_status !== 1) {
      MENU_OPTIONS.push(
        {
          label: "Edit",
          icon: "akar-icons:edit",
          handleClick: handleEdit,
        },
        {
          label: "Mark Resolve",
          icon: "emojione-monotone:heavy-check-mark",
          handleClick: handleMarkResolvedPopup,
        }
      );
    }

    return MENU_OPTIONS;
  };

  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    { id: "reference_number", label: "ID" },
    {
      id: "subject",
      label: "Subject",
      handleClick: handle_ticket_click,
      className: "cursor-pointer",
    },
    { id: "department_title", label: "Department" },
    { id: "created_at", label: "Created" },
    {
      id: "status",
      label: "Status",
      renderData: (row) => <RenderStatus item={row} />,
    },
    { id: "action", label: "Action", type: "action" },
  ];

  return (
    <>
      <div className="col-12">
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={data}
          handle_menus={handle_menus}
          className="card-with-background"
        />
      </div>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this Ticket?"}
        handleAgree={handleDelete}
      />
      <CustomConfirmation
        open={openResolved}
        setOpen={setOpenResolved}
        title={"Are you sure you want to mark this ticket as resolved?"}
        handleAgree={handleMarkResolved}
      />
      <CustomDrawer
        isOpenDrawer={editDrawerState}
        onOpenDrawer={handleOpenEditDrawer}
        onCloseDrawer={handleCloseEditDrawer}
        pageTitle="Edit Support Ticket"
        componentToPassDown={
          <AddOrUpdateTicketSection
            editData={editData}
            formType="EDIT"
            dataList={getTickestsListing}
            onCloseDrawer={handleCloseEditDrawer}
          />
        }
      />
    </>
  );
}
