import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Chip, CircularProgress, Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import {
  deleteSalePageApi,
  import_template_data_api,
  page_listing_api,
} from "src/DAL/WebsitePages/WebsitePages";
import { client_url, websiteUrl } from "src/config/config";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import CustomConfirmation from "src/components/menuIcons/CustomConfirmation";
import { useContentSetting } from "src/Hooks/ContentSettingState";
import CopyToClipboard from "react-copy-to-clipboard";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function PagesList() {
  const navigate = useNavigate();
  const classes = useStyles();
  const { userInfo } = useContentSetting();
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [templateData, setTemplateData] = useState([]);
  const [selectedObject, setSelectedObject] = useState({});
  const [openModel, setOpenModel] = useState(false);
  const { consultantInfo } = useContentSetting();
  const [isAccess, setIsAccess] = useState();
  const [openDelete, setOpenDelete] = useState(false);
  const [pageSlug, setPageSlug] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };
  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
    localStorage.setItem("template_page_number", newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setPageCount(1);
  };
  const getTemplatePagesListing = async () => {
    setIsLoading(true);
    const result = await page_listing_api();
    if (result.code === 200) {
      let result_array = [];
      setIsAccess(result.is_access);
      result.Sale_page.map((template) => {
        result_array.push({
          ...template,
          preview_link_data: {
            to:
              userInfo.team_type == "sub_team"
                ? websiteUrl +
                  template.sale_page_title_slug +
                  "/" +
                  userInfo.affiliate_url_name
                : websiteUrl + template.sale_page_title_slug,
            target: "_blank",
            show_text: "Preview",
            className: "anchor-style",
          },
          MENU_OPTIONS: menuHandling(template),
        });
      });
      setTemplateData(result_array);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handleCopiedUrl = () => {
    enqueueSnackbar("Preview Url copied to clipboard", { variant: "success" });
  };
  const handleCopiedAppointment = () => {
    enqueueSnackbar("Appointment Url copied to clipboard", {
      variant: "success",
    });
  };
  const TABLE_HEAD = [
    { id: "number", label: "#", alignRight: false, type: "number" },
    { id: "sale_page_title", label: "Title", alignRight: false },
    {
      id: "action",
      label: "Action",
      alignRight: false,
      MENU_OPTIONS: "MENU_OPTIONS",
      type: "action",
    },
  ];

  TABLE_HEAD.splice(2, 0, {
    id: "sale_page_title1",
    label: "Main URL",
    alignRight: false,
    renderData: (row) => {
      return (
        <>
          {row.is_template_data_imported && (
            <>
              {row.type_of_page == "book_a_call_page" ? (
                <CopyToClipboard
                  text={websiteUrl + row.sale_page_title_slug + "/appointment"}
                  onCopy={() => handleCopiedAppointment(true)}
                >
                  <Tooltip title="Click to Appointment URL">
                    <Chip
                      label="Copy Appointment URL"
                      color="primary"
                      variant="outlined"
                      className="mt-2"
                    />
                  </Tooltip>
                </CopyToClipboard>
              ) : (
                <CopyToClipboard
                  text={
                    userInfo.team_type == "sub_team"
                      ? websiteUrl +
                        row.sale_page_title_slug +
                        "/" +
                        userInfo.affiliate_url_name
                      : websiteUrl + row.sale_page_title_slug
                  }
                  onCopy={() => handleCopiedUrl(true)}
                >
                  <Tooltip title="Click to copy Main URL">
                    <Chip
                      label="Copy Main URL"
                      color="primary"
                      variant="outlined"
                    />
                  </Tooltip>
                </CopyToClipboard>
              )}
            </>
          )}
        </>
      );
    },
  });
  // TABLE_HEAD.splice(3, 0, {
  //   id: "copy_appointment_url",
  //   label: "Appointment URL",
  //   alignRight: false,
  //   renderData: (row) => {
  //     if (row.type_of_page == "book_a_call_page") {
  //       return (
  //         <CopyToClipboard
  //           text={websiteUrl + row.sale_page_title_slug + "/appointment"}
  //           onCopy={() => handleCopiedAppointment(true)}
  //         >
  //           <Tooltip title="Click to Appointment URL">
  //             <Chip label="Copy URL" color="primary" variant="outlined" />
  //           </Tooltip>
  //         </CopyToClipboard>
  //       );
  //     }
  //   },
  // });
  TABLE_HEAD.splice(3, 0, {
    id: "import_template_data",
    label: "URL",
    alignRight: false,
    renderData: (row) => {
      if (!row.is_template_data_imported) {
        return (
          <Tooltip title="Click to import template data">
            <Chip
              label="Import Template Data"
              color="primary"
              variant="outlined"
              onClick={() => handleAgreeImportData(row)}
            />
          </Tooltip>
        );
      } else {
        return (
          <a
            href={row.preview_link_data.to}
            className="anchor-style tex-center"
            target="_blank"
          >
            Preview
          </a>
        );
      }
    },
  });

  const handleEdit = (value) => {
    delete value.MENU_OPTIONS;
    navigate(`/website-pages/edit-page/${value.sale_page_title_slug}`, {
      state: value,
    });
  };
  const handleDeletePages = (e) => {
    setOpenDelete(true);

    setPageSlug(e.sale_page_title_slug);
  };
  const handleAgreeDeletePage = async (e) => {
    setOpenDelete(false);
    setIsLoading(true);
    const result = await deleteSalePageApi(pageSlug);
    if (result.code == 200) {
      getTemplatePagesListing();
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleUpdatePageContent = (value) => {
    delete value.MENU_OPTIONS;
    navigate(
      `/website-pages/update-page-content/${value.sale_page_title_slug}`,
      {
        state: value,
      }
    );
  };
  const handleUpdateThanksContent = (value) => {
    delete value.MENU_OPTIONS;
    // if (is_website_pages) {
    navigate(
      `/website-pages/update-thanks-content/${value.sale_page_title_slug}`,
      {
        state: value,
      }
    );

    // } else {
    //   navigate(
    //     `/template-pages/update-thanks-content/${value.sale_page_title_slug}`,
    //     {
    //       state: value,
    //     }
    //   );
    // }
  };
  const handleSocialSharing = (value) => {
    delete value.MENU_OPTIONS;
    // if (is_website_pages) {
    navigate(
      `/website-pages/social-sharing-setting/${value.sale_page_title_slug}`,
      {
        state: value,
      }
    );
    // } else {
    //   navigate(
    //     `/template-pages/social-sharing-setting/${value.sale_page_title_slug}`,
    //     {
    //       state: value,
    //     }
    //   );
    // }
  };
  const handleEditPaymentPage = (value) => {
    delete value.MENU_OPTIONS;
    // if (is_website_pages) {
    navigate(
      `/website-pages/edit-page/${value.payment_page.sale_page_title_slug}`,
      {
        state: value.payment_page,
      }
    );
    // } else {
    //   navigate(
    //     `/template-pages/edit-page/${value.payment_page.sale_page_title_slug}`,
    //     {
    //       state: value.payment_page,
    //     }
    //   );
    // }
  };
  const handleUnpublishSetting = (value) => {
    delete value.MENU_OPTIONS;
    navigate(`/website-pages/unpublish-setting/${value._id}`, {
      state: value,
    });
  };
  const handleQuestionAnswers = (value) => {
    delete value.MENU_OPTIONS;
    navigate(`/website-pages/answers?created_for_id=${value._id}`, {
      state: value,
    });
  };

  const handleRedirect = (module, value) => {
    console.log(module, "module");
    switch (module.module_actual_name) {
      case "testimonial":
        navigate(`/website-pages/${value._id}/testimonials`, {
          state: value,
        });
        break;
      case "payment_plans":
        navigate(`/website-pages/${value._id}/payment-plans`, {
          state: value,
        });
        break;
      case "website_programmes":
        navigate(`/website-pages/${value._id}/website-programmes`, {
          state: value,
        });
        break;
      case "buisness_strategy":
        navigate(`/website-pages/${value._id}/business-strategy`, {
          state: value,
        });
        break;
      case "benefits":
        navigate(`/website-pages/${value._id}/benifits`, {
          state: value,
        });
        break;
      case "website_faq":
        navigate(`/website-pages/${value._id}/website-faq`, {
          state: value,
        });
        break;
      case "wheel_of_life":
        navigate(`/website-pages/${value._id}/questions`, {
          state: value,
        });
        break;
      case "success_schedule":
        navigate(`/website-pages/${value._id}/success-schedule`, {
          state: value,
        });
        break;
      case "vanues":
        navigate(`/website-pages/${value._id}/venues`, {
          state: value,
        });
        break;
      case "banner_slider":
        navigate(`/website-pages/${value._id}/banner-slider`, {
          state: value,
        });
        break;
      case "general_questions":
        navigate(
          `/website-pages/general-questions?created_for_id=${value._id}`,
          {
            state: value,
          }
        );
        break;
      case "food_menu":
        navigate(`/website-pages/${value._id}/food-menu`, {
          state: value,
        });
        break;
      default:
      // code block
    }
  };
  const handleEditThanksPage = (value) => {
    delete value.MENU_OPTIONS;
    // if (is_website_pages) {
    navigate(
      `/website-pages/edit-page/${value.thanks_page.sale_page_title_slug}`,
      {
        state: value.thanks_page,
      }
    );
    // } else {
    //   navigate(
    //     `/template-pages/edit-page/${value.thanks_page.sale_page_title_slug}`,
    //     {
    //       state: value.thanks_page,
    //     }
    //   );
    // }
  };
  const handleUpdateThanksPageContent = (value) => {
    delete value.MENU_OPTIONS;
    // if (is_website_pages) {
    navigate(
      `/website-pages/update-page-content/${value.thanks_page.sale_page_title_slug}`,
      {
        state: value.thanks_page,
      }
    );
    // } else {
    //   navigate(
    //     `/template-pages/update-page-content/${value.thanks_page.sale_page_title_slug}`,
    //     {
    //       state: value.thanks_page,
    //     }
    //   );
    // }
  };
  const handleUpdatePaymentPageContent = (value) => {
    delete value.MENU_OPTIONS;
    // if (is_website_pages) {
    navigate(
      `/website-pages/update-page-content/${value.payment_page.sale_page_title_slug}`,
      {
        state: value,
      }
    );
    // } else {
    //   navigate(
    //     `/template-pages/update-page-content/${value.payment_page.sale_page_title_slug}`,
    //     {
    //       state: value,
    //     }
    //   );
    // }
  };
  const handleAgreeImportData = (value) => {
    setSelectedObject(value);
    setOpenModel(true);
  };

  const handleDelete = async () => {
    setOpenModel(false);
    setIsLoading(true);
    const dataObject = {
      sale_page_id: selectedObject._id,
    };

    const result = await import_template_data_api(dataObject);
    if (result.code === 200) {
      getTemplatePagesListing();
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setIsLoading(true);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleCopyAppointmentURL = async (e) => {
    await navigator.clipboard.writeText(
      websiteUrl + e.sale_page_title_slug + "/appointment"
    );
    enqueueSnackbar("Appointment URL Copied to clipboard", {
      variant: "success",
    });
  };
  const handleCopyURL = async (template) => {
    await navigator.clipboard.writeText(
      userInfo.team_type == "sub_team"
        ? websiteUrl +
            template.sale_page_title_slug +
            "/" +
            userInfo.affiliate_url_name
        : websiteUrl + template.sale_page_title_slug
    );
    enqueueSnackbar("Preview Url copied to clipboard", {
      variant: "success",
    });
  };
  const handleSetCommission = (value) => {
    delete value.MENU_OPTIONS;
    navigate(`/website-pages/set-commission/${value._id}`, {
      state: value,
    });
  };
  function hasAccessToOPtion(data, label) {
    // Iterate through the array
    for (let obj of data) {
      // Check if the current object has label "payment_page" and is_access is true
      if (obj.label === label && obj.is_access) {
        return true; // If found, return true
      }
    }
    return false; // If not found, return false
  }

  const menuHandling = (value) => {
    let find_general_questions_module = value.module_info.find(
      (module) =>
        module.module_actual_name == "general_questions" && module.is_access
    );
    console.log(value, "valuevaluevalue");

    const MENU_OPTIONS = [];
    if (userInfo.team_type !== "sub_team") {
      MENU_OPTIONS.push({
        label: "Edit Template Setting",
        icon: "akar-icons:edit",
        handleClick: (e) => {
          handleEdit(e);
        },
      });

      if (hasAccessToOPtion(value.page_options_access, "update_page_content")) {
        MENU_OPTIONS.push({
          label: "Update Content",
          icon: "akar-icons:edit",
          handleClick: handleUpdatePageContent,
        });
      }
    }

    MENU_OPTIONS.push({
      label: "Copy main URL",
      icon: "eva:eye-fill",
      handleClick: handleCopyURL,
    });
    if (value.type_of_page == "book_a_call_page") {
      MENU_OPTIONS.splice(5, 0, {
        label: "Copy Appointment URL",
        icon: "eva:eye-fill",
        handleClick: handleCopyAppointmentURL,
      });
    }
    if (userInfo.team_type !== "sub_team") {
      if (
        value.type_of_page == "book_a_call_page"
        // && find_general_questions_module
      ) {
        MENU_OPTIONS.splice(6, 0, {
          label: "Question Answers",
          icon: "eva:eye-fill",
          handleClick: handleQuestionAnswers,
        });
      }
      // child menu
      if (hasAccessToOPtion(value.page_options_access, "thanks_page")) {
        if (value.thanks_page) {
          let child_menu_options = [
            {
              label: "Edit Page Setting",
              icon: "akar-icons:edit",
              handleClick: (e) => {
                handleEditThanksPage(e);
              },
            },

            {
              label: "Update Page Content",
              icon: "akar-icons:edit",
              handleClick: handleUpdateThanksPageContent,
            },
          ];
          value.thanks_page?.module_info?.map((module) => {
            child_menu_options.push({
              label: module.module_label_text,
              icon: "akar-icons:edit",
              handleClick: () => {
                handleRedirect(module, value, "thanks_page");
              },
            });
          });
          MENU_OPTIONS.push({
            label: "Thanks Page",
            icon: "akar-icons:edit",
            child_options: child_menu_options,
          });
        }
      }
      if (hasAccessToOPtion(value.page_options_access, "payment_page")) {
        if (value.payment_page) {
          let child_menu_options = [
            {
              label: "Edit Page Setting",
              icon: "akar-icons:edit",
              handleClick: (e) => {
                handleEditPaymentPage(e);
              },
            },
            {
              label: "Update Page Content",
              icon: "akar-icons:edit",
              handleClick: handleUpdatePaymentPageContent,
            },
          ];
          value.payment_page?.module_info?.map((module) => {
            child_menu_options.push({
              label: module.module_label_text,
              icon: "akar-icons:edit",
              handleClick: () => {
                handleRedirect(module, value, "payment_page");
              },
            });
          });
          MENU_OPTIONS.push({
            label: "Payment Page",
            icon: "akar-icons:edit",
            child_options: child_menu_options,
          });
        }
      }
      // child menu end
      if (!value.is_template_data_imported) {
        MENU_OPTIONS.push({
          label: "Import Template Data",
          icon: "akar-icons:edit",
          handleClick: handleAgreeImportData,
        });
      }
      if (value.plan_count > 0) {
        MENU_OPTIONS.push({
          label: "Set Commisssion",
          icon: "akar-icons:edit",
          handleClick: handleSetCommission,
        });
      }

      value.module_info.map((module) => {
        if (module.is_access) {
          MENU_OPTIONS.push({
            label: module.module_label_text,
            icon: "akar-icons:edit",
            handleClick: () => {
              handleRedirect(module, value);
            },
          });
        }
      });

      MENU_OPTIONS.push({
        label: "Delete",
        icon: "ant-design:delete-twotone",
        handleClick: (e) => {
          handleDeletePages(e);
        },
      });

      const shouldInsert = value.page_options_access.some(
        (obj) => obj.is_access === true
      );

      if (shouldInsert) {
        const index = 2;
        MENU_OPTIONS.splice(
          index,
          0,
          ...value.page_options_access.map((obj) => {
            console.log(obj, "page_options_access");
            if (
              obj.is_access === true &&
              obj.label == "social_sharing_setting"
            ) {
              return {
                label: obj.name,
                icon: "akar-icons:edit",
                handleClick: handleSocialSharing,
              };
            }
          })
        );
      }
    }
    let newArray = MENU_OPTIONS.filter((obj) => obj != undefined);

    return newArray;
  };

  useEffect(() => {
    getTemplatePagesListing();
  }, []);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  const handleNavigate = () => {
    navigate(`/website-pages/add-page`);
  };

  return (
    <>
      <CustomConfirmation
        open={openModel}
        setOpen={setOpenModel}
        title_html={
          <div className="confirmation-popup-title">
            <h2>Are you sure you want to import template data?</h2>
            <p>
              Importing template data will update page content and copy other
              modules data.
            </p>
          </div>
        }
        handleAgree={handleDelete}
      />
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this page?"}
        handleAgree={handleAgreeDeletePage}
      />
      <div className="container">
        <div className="row mb-5">
          <div className="col-lg-8 col-sm-12">
            <h2>Templates</h2>
          </div>
          {userInfo.team_type !== "sub_team" && isAccess == true ? (
            <div className="col-lg-4 col-sm-12 text-end">
              <button
                onClick={handleNavigate}
                className="small-contained-button"
              >
                Add New Template
              </button>
            </div>
          ) : (
            ""
          )}
        </div>
        {isAccess == true ? (
          <CustomMUITable
            TABLE_HEAD={TABLE_HEAD}
            data={templateData}
            className="card-with-background"
            // custom_pagination={{
            //   total_count: totalCount,
            //   rows_per_page: rowsPerPage,
            //   page: page,
            //   handleChangePage: handleChangePage,
            //   onRowsPerPageChange: handleChangeRowsPerPage,
            // }}
            pageCount={pageCount}
            totalPages={totalPages}
            handleChangePages={handleChangePages}
            localSearchName={"delegate-pages"}
            pagePagination={true}
          />
        ) : (
          <div
            dangerouslySetInnerHTML={{
              __html: consultantInfo?.content_for_no_template_page_access,
            }}
          ></div>
        )}
      </div>
    </>
  );
}
