import { useEffect, useState } from "react";
import { Stack, Typography } from "@mui/material";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function ZoomDetail({ zoomObject }) {
  const classes = useStyles();
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  let memberList = [];
  memberList.push(zoomObject);

  const getConsultantListing = async () => {
    setIsLoading(true);
    const data = memberList.map((member) => {
      let password_text = "N/A";
      if (member.password) {
        password_text = member.password;
      }
      return {
        ...member,
        password_text,
      };
    });

    setUserList(data);
    setIsLoading(false);
  };

  useEffect(() => {
    let memberList = [];
    memberList.push(zoomObject);
    getConsultantListing();
  }, []);

  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    {
      id: "zoom_link",
      label: " Name",
      renderData: (row) => {
        return (
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant="subtitle2" noWrap>
              {
                <a href={row.zoom_link} target="_blank">
                  View
                </a>
              }
            </Typography>
          </Stack>
        );
      },
    },
    { id: "password_text", label: "Password" },
  ];

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="row">
        <div className="col-12">
          <CustomMUITable
            TABLE_HEAD={TABLE_HEAD}
            data={userList}
            className="card-with-background"
            pagePagination={true}
          />
        </div>
      </div>
    </>
  );
}
