import * as React from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { MenuItem, Select } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  formControl: {
    "& .MuiInputBase-root": {
      color: "#6EC177",
      borderColor: "#6EC177",
      borderWidth: "1px",
      borderStyle: "solid",
      borderRadius: "100px",
      minWidth: "120px",
      justifyContent: "center",
    },
    "& .MuiSelect-select.MuiSelect-select": {
      paddingRight: "0px",
    },
  },
  select: {
    width: "auto",
    fontSize: "12px",
    "&:focus": {
      backgroundColor: "transparent",
    },
  },
  selectIcon: {
    position: "relative",
    color: "#6EC177",
    fontSize: "14px",
  },
  paper: {
    borderRadius: 12,
    marginTop: 8,
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
    "& li": {
      fontWeight: 200,
      paddingTop: 8,
      paddingBottom: 8,
      fontSize: "12px",
    },
    "& li.Mui-selected": {
      color: "white",
      background: "#6EC177",
    },
    "& li.Mui-selected:hover": {
      background: "#6EC177",
    },
  },
}));

export default function CustomPopoverAssociates({
  anchorEl,
  setAnchorEl,
  handleClickLeadChangePopup,
  handleClose,
  id,
  open,
  data,
  handleChangeSection,
  selectedData,
  associateType,
}) {
  const classes = useStyles();
  const [associatesList, setAssociateLists] = React.useState([]);
  const [activeClass, setActiveClass] = React.useState(true);

  // const [anchorEl, setAnchorEl] = React.useState(null);

  // const handleClickLeadChangePopup = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  // const open = Boolean(anchorEl);
  // const id = open ? "simple-popover" : undefined;

  var handleClassChange = (value) => {
    let associate_obj = associatesList?.find((o) => o?._id === value);
    if (associate_obj) {
      return "selectedAssociateColor";
    } else {
      return "";
    }
  };

  React.useEffect(() => {
    if (associateType == "main") {
      let mainArr = [];
      mainArr.push(selectedData?.main_associate);
      setAssociateLists(mainArr);
    } else {
      setAssociateLists(selectedData?.other_associates);
    }
  }, [selectedData, anchorEl]);

  return (
    <div>
      <Popover
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        {/* <Typography sx={{ p: 2 }}>The content of the Popover.</Typography> */}
        {/* {data.map((value) => {})} */}

        {data?.map((item) => (
          <MenuItem
            key={item._id}
            value={item._id}
            id="select"
            onClick={() => handleChangeSection(item)}
            className={`paper-color-leads ${handleClassChange(item._id)}`}
            // className={handleClass(item._id)} selectedAssociateColor
          >
            {item.first_name + " " + item.last_name}
          </MenuItem>
        ))}
      </Popover>
    </div>
  );
}
