import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
// material
import {
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import LockIcon from "@mui/icons-material/Lock";

import {
  live_events_list_api,
  _list_of_member_for_chat_against_event,
} from "src/DAL/LiveEvents/LiveEvents";
import { projectMode, project_name, s3baseUrl } from "src/config/config";
import CustomMUICard from "src/components/GeneralComponents/CustomMUICard";
import RecordNotFound from "src/components/GeneralComponents/RecordNotFound";
import {
  live_events_consultant_admin_list_api,
  live_events_consultant_list_api,
} from "src/DAL/LiveEvents/LiveEventsPortal";
import CustomConfirmation from "src/components/menuIcons/CustomConfirmation";
import {
  delete_dynamite_event_api,
  duplicate_dynamite_event_api,
} from "src/DAL/LiveEvents/DynamiteEvents";
import SearchIcon from "@mui/icons-material/Search";

// ----------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

// ----------------------------------------------------------------------

export default function LiveEvents() {
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();
  const navigate = useNavigate();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [eventsList, setEventsList] = useState({});

  const [deleteDoc, setDeleteDoc] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [openDuplicate, setOpenDuplicate] = useState(false);
  const [query, setQuery] = useState("");
  const handleChangeSearch = (e) => {
    setQuery(e.target.value);
    localStorage.setItem("portals", e.target.value);
  };
  const getFilteredData = (query) => {
    let dataToFilter = [...eventsList];
    if (query) {
      let resultFilter = dataToFilter.filter((post) =>
        post.title.toLowerCase().includes(query.toLowerCase())
      );
      return resultFilter;
    } else {
      return dataToFilter;
    }
  };
  const handleClick = (object) => {
    delete object.card_button;
    if (!object.locked) {
      navigate(`/live-events/${object._id}`);
    } else if (
      object.lock_configration &&
      object.lock_configration.lock_event_description !== ""
    ) {
      navigate(`/live-events-locked/${object._id}`, { state: object });
    }
  };
  const handleNavigate = (object) => {
    window.open(object.lock_configration.lock_event_button_link, "_self");
  };
  const handleDelete = async () => {
    setOpenDelete(false);
    const result = await delete_dynamite_event_api(deleteDoc.event_slug);
    if (result.code === 200) {
      getLiveEventsList();
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleDuplicate = async () => {
    setOpenDuplicate(false);
    const result = await duplicate_dynamite_event_api(deleteDoc._id);
    if (result.code === 200) {
      getLiveEventsList();
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const getLiveEventsList = async () => {
    const result = await live_events_consultant_admin_list_api("admin");
    if (result.code === 200) {
      let CARD_OPTIONS = [];
      result.dynamite_events.map((dynamite_event) => {
        CARD_OPTIONS.push({
          ...dynamite_event,
          card_title: dynamite_event.title,
          card_short_description: dynamite_event.short_description,
          card_image: s3baseUrl + dynamite_event.images.thumbnail_2,
          card_classes: "col-lg-4 col-md-6 col-sm-12 mt-4",
          // card_button: dynamite_event.locked === true && {
          //   button_title: (
          //     <>
          //       {/* <LockIcon />
          //       {` Unlock Access`} */}

          //       {dynamite_event.lock_configration ? (
          //         dynamite_event.lock_configration.lock_event_logo ? (
          //           <img
          //             src={
          //               s3baseUrl +
          //               dynamite_event.lock_configration.lock_event_logo
          //             }
          //             width={20}
          //             className="me-1"
          //           />
          //         ) : (
          //           <LockIcon />
          //         )
          //       ) : (
          //         <>
          //           <LockIcon />
          //         </>
          //       )}
          //       {dynamite_event.lock_configration &&
          //       dynamite_event.lock_configration.lock_event_button_text
          //         ? dynamite_event.lock_configration.lock_event_button_text
          //         : "Unlock Access"}
          //     </>
          //   ),
          //   handleClick: handleClick,
          // },
        });
      });
      setEventsList(CARD_OPTIONS);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleAddPortalNavigate = () => {
    navigate(`/live-events/add-events`);
  };
  const handleEdit = (value) => {
    navigate(`/live-events/add-events/${value.event_slug}`, {
      state: value,
    });
  };
  const handleChangeEventConfig = (value) => {
    navigate(`/live-events/configuration/${value.event_slug}`, {
      state: value,
    });
  };
  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };
  const handleChangeLockContent = (value) => {
    navigate(`/live-events/lock-event-content/${value.event_slug}`, {
      state: value,
    });
  };
  const handleChangCategories = (value) => {
    navigate(`/dynamite-event-categories/${value._id}`);
  };
  const handleEvntMembers = (value) => {
    navigate(`/dynamite-events/${value._id}/members`);
  };
  const handleGeneralEvntMembers = (value) => {
    navigate(`/dynamite-events/${value._id}/general-events`);
  };
  const handleAgreeDuplicate = (value) => {
    setDeleteDoc(value);
    setOpenDuplicate(true);
  };
  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
    {
      label: "Lock Event Content",
      icon: "akar-icons:edit",
      handleClick: handleChangeLockContent,
    },
    {
      label: "Event Timer Configuration",
      icon: "akar-icons:edit",
      handleClick: handleChangeEventConfig,
    },
    {
      label: "Categories",
      icon: "iconoir:profile-circled",
      handleClick: handleChangCategories,
    },
    {
      label: "Members",
      icon: "iconoir:profile-circled",
      handleClick: handleEvntMembers,
    },
    {
      label: "Events",
      icon: "iconoir:profile-circled",
      handleClick: handleGeneralEvntMembers,
    },
    {
      label: "Duplicate",
      icon: "akar-icons:edit",
      handleClick: handleAgreeDuplicate,
    },
  ];
  useEffect(() => {
    getLiveEventsList();
    const search_text = localStorage.getItem("portals");
    if (search_text) {
      setQuery(search_text);
    }
  }, []);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <h2>Portals</h2>
          </div>
          <div className="col-4">
            <div>
              <FormControl variant="outlined" style={{ marginTop: "2px" }}>
                <InputLabel htmlFor="outlined-adornment-password">
                  Search
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type="text"
                  variant="small"
                  onChange={handleChangeSearch}
                  value={query}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        edge="end"
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Search"
                />
              </FormControl>
            </div>
          </div>
          {/* {projectMode == "DEV" && (
            <div className="col-lg-6 col-sm-12 text-end">
              <button
                onClick={handleAddPortalNavigate}
                className="small-contained-button"
              >
                Add Events
              </button>
            </div>
          )} */}
        </div>
        {getFilteredData(query).length > 0 ? (
          <CustomMUICard
            data={getFilteredData(query)}
            // MENU_OPTIONS={MENU_OPTIONS}
            handleClick={handleClick}
          />
        ) : (
          <RecordNotFound title="Portals Not Found" />
        )}
      </div>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this event?"}
        handleAgree={handleDelete}
      />
      <CustomConfirmation
        open={openDuplicate}
        setOpen={setOpenDuplicate}
        title={"Are you sure you want to duplicate this event?"}
        handleAgree={handleDuplicate}
      />
    </>
  );
}
