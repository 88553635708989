import { useSnackbar } from "notistack";
import { useEffect, useRef } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { PaiChart } from "src/components/pie-chart/PaiChart";
import {
  getWheelOfLifeListApi,
  updateWheelOfLifeApi,
} from "src/DAL/WheelOfLife/WheelOfLifeApi";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useContentSetting } from "src/Hooks/ContentSettingState";
// import { navbarListAPi } from "src/DAL/ContentSetting/ContentSetting";
import { htmlDecode } from "src/utils/convertHtml";
import { consultantSettingApi } from "src/DAL/Login/Login";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
const AssessmentQuestionsSection = (props) => {
  const { questionsArray } = props;
  const {
    userInfo,
    handleUserInfo,
    handleNavbarList,
    wheelOfLifeSetting,
    consultantInfo,
    handleWheelOfLifeSetting,
    navbarList,
    handleConsultantInfo,
  } = useContentSetting();

  const classes = useStyles();
  const navigate = useNavigate();
  const ref = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const [showQuestions, setShowQuestions] = useState(true);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [assessmentQuestions, setAssessmentQuestions] = useState();
  const [totalScores, setTotalScores] = useState(0);
  const [gainedScores, setGainedScores] = useState(0);

  const [intention, setIntention] = useState("");
  const get_scale_indexes = (limit) => {
    const arr = [];
    for (let i = 0; i < limit; i++) {
      arr.push(i + 1);
    }
    return arr;
  };

  const getContentSetting = async () => {
    const result = await consultantSettingApi();
    if (result.code == 200) {
      handleConsultantInfo(result.consultant_setting);
      handleWheelOfLifeSetting(result.wheel_of_life_setting);
      localStorage.setItem(
        `consultant_data`,
        JSON.stringify(result.consultant_setting)
      );
      localStorage.setItem(
        `wheel_of_life_setting`,
        JSON.stringify(result.wheel_of_life_setting)
      );
      navigate("/questions/assessment/thank-you");
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const get_wheel_of_life_list = async () => {
    setIsLoading(true);
    const result = await getWheelOfLifeListApi();
    if (result.code === 200) {
      setTotalScores(result.wheel_of_life.length * 10);
      setAssessmentQuestions(result.wheel_of_life);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleClick = (index, answer) => {
    setGainedScores(gainedScores + answer);
    const updated = assessmentQuestions?.map((question, i) => {
      if (i === index) {
        return { ...question, answer };
      } else {
        return question;
      }
    });

    setAssessmentQuestions(updated);
    if (index < assessmentQuestions.length - 1) {
      // next question
      setCurrentQuestion(index + 1);
    } else {
      // hide questions
      setShowQuestions(false);
    }
  };

  const get_graph_data = () => {
    const colors = assessmentQuestions?.map((q) => q?.scaling_color);
    const labels = assessmentQuestions?.map((q) => q?.scaling_main_heading);
    const data = assessmentQuestions?.map((q) => (q?.answer ? q?.answer : 0));
    return { colors, labels, data };
  };

  const handleNext = async (e) => {
    e.preventDefault();
    let is_answered = true;
    assessmentQuestions.forEach((question) => {
      if (!question.answer) {
        is_answered = false;
      }
    });
    if (!is_answered) {
      enqueueSnackbar("Please choose answer against every question.", {
        variant: "error",
      });
      return;
    }
    const postData = {
      assessment: questionsArray, // we have questions in state
      wheel_of_life: assessmentQuestions,
      intention_statement: intention,
    };
    setIsLoading(true);
    const result = await updateWheelOfLifeApi(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      getContentSetting();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    get_wheel_of_life_list();
    // if (!navbarList.wheel_of_life) {
    //   navigate("/thesource");
    // }
  }, []);

  const replacedString = (string) => {
    if (string) {
      let reslut = string.replace(/{getScores}/g, gainedScores);
      let new_result = reslut.replace(/{totalScores}/g, totalScores);
      return new_result.replace(/{Name}/g, userInfo.first_name);
    } else {
      return "";
    }
  };

  const replacedStringName = (string) => {
    if (string) {
      return string.replace(/{Name}/g, userInfo.first_name);
    } else {
      return "";
    }
  };

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <section className="top-banner-section p-2" ref={ref}>
        <div className="container">
          <div className="row justify-content-center pb-5 ">
            <div className="col-12 col-lg-12 right-part">
              {showQuestions && (
                <div className="row justify-content-center mt-5 mb-4 ml-0 mr-0 w-100">
                  <div className="col-12 text-center">
                    <h2>
                      {replacedStringName(
                        assessmentQuestions[currentQuestion]
                          ?.scaling_main_heading
                      )}
                    </h2>
                    <p className="text-white">
                      {replacedStringName(
                        assessmentQuestions[currentQuestion]?.question_statement
                      )}
                    </p>
                  </div>

                  <div className="col-12">
                    <div className="scale-wrapper">
                      {get_scale_indexes(10).map((scale) => (
                        <div
                          onClick={() => handleClick(currentQuestion, scale)}
                          className={
                            scale === 10
                              ? "scale-index scale-border-left scale-border-right"
                              : "scale-index scale-border-left"
                          }
                        >
                          {scale}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
              {!showQuestions && (
                <div className="gained-cores mb-5">
                  <h2>
                    {replacedString(
                      wheelOfLifeSetting.wheel_of_life_score_description
                    )}
                  </h2>
                </div>
              )}
              <div className="row justify-content-center mb-4 mt-4">
                <div className="col-12 col-lg-5 text-center">
                  <PaiChart assessment_data={get_graph_data()} />
                </div>
                <div className="col-12 col-lg-6 text-center">
                  {assessmentQuestions.length > 0 &&
                    assessmentQuestions.map((item, index) => {
                      return (
                        <div
                          className="d-flex justify-content-start legend-title mb-2"
                          key={index}
                        >
                          <div
                            className="color-box"
                            style={{ backgroundColor: item.scaling_color }}
                          ></div>
                          <div className="description-box text-start">
                            {replacedString(item.scaling_main_heading)}
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
              <div className="col-12 text-center mb-4">
                {showQuestions ? (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: replacedStringName(
                        wheelOfLifeSetting.wheel_of_life_description
                      ),
                    }}
                  ></div>
                ) : (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: replacedStringName(
                        wheelOfLifeSetting.wheel_of_life_description_after_rating
                      ),
                    }}
                  ></div>
                )}
              </div>
              {!showQuestions && (
                <>
                  <div className="row justify-content-center align-items-center ">
                    <div className="col-12 col-lg-10 text-center">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: replacedStringName(
                            wheelOfLifeSetting.wheel_of_life_intention_statement
                          ),
                        }}
                      ></div>
                      <form onSubmit={handleNext}>
                        <textarea
                          className="assessment-text-area mt-2"
                          placeholder={htmlDecode(
                            wheelOfLifeSetting.intention_statement_field_placeholder
                          )}
                          value={intention}
                          onChange={(e) => setIntention(e.target.value)}
                          required
                        ></textarea>
                        <div className="text-center mt-5">
                          <button className="black-btn">
                            <b>
                              {htmlDecode(
                                wheelOfLifeSetting.wheel_of_life_submit_button_text
                              )}
                            </b>
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AssessmentQuestionsSection;
