import {
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";

import React, { useEffect, useState } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useTheme } from "@mui/material/styles";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Icon } from "@iconify/react";
import moment from "moment";
import AddIcon from "@mui/icons-material/Add";
import faker from "faker";
import {
  addAppointmentApi,
  listAppointmentApi,
} from "src/DAL/appointment/appointment";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { appointmentListing } from "src/DAL/GoalStatement/GoalStatement";
import CustomConfirmation from "src/components/GeneralComponents/CustomConfirmation";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
const names = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const AddAppointment = () => {
  const theme = useTheme();
  const classes = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [customeDuration, setCustomDuration] = useState(false);
  const [weekName, setWeekName] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState({});
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [openDuplicate, setOpenDuplicate] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [value, setValue] = useState();
  const [inputs, setInputs] = useState([
    {
      interval_id: faker.datatype.uuid(),
      // appointment_configration_name: "",
      slot_duration: "",
      slot_type: "",
      start_date: new Date(),
      end_date: new Date(),
      days: [],
      slots: [
        {
          slot_id: faker.datatype.uuid(),
          start_time: "00:00",
          end_time: "00:00",
        },
      ],
    },
  ]);
  const handleAgreeDelete = (index) => {
    setValue(index);

    setOpenDelete(true);
  };
  const handleDelete = async () => {
    setOpenDelete(false);

    const list = [...inputs];
    list.splice(value, 1);

    setInputs(list);
  };
  // const handleRemoveClick = (index) => {
  //   const list = [...inputs];
  //   list.splice(index, 1);

  //   setInputs(list);
  // };
  // add parent new object

  const handleAddClick = (index) => {
    let newIndex = inputs.length + 1;
    setInputs([
      ...inputs.slice(0, newIndex),
      {
        interval_id: faker.datatype.uuid(),
        // appointment_configration_name: "",
        slot_duration: "",
        slot_type: "",
        start_date: new Date(),
        end_date: new Date(),
        days: [],
        slots: [
          {
            slot_id: faker.datatype.uuid(),
            start_time: "00:00",
            end_time: "00:00",
          },
        ],
      },
      ...inputs.slice(newIndex),
    ]);
  };

  // add child new object
  const handleAddNestedInterval = (parentIndex) => {
    const list = [...inputs];
    let newItemObj = {
      slot_id: faker.datatype.uuid(),
      start_time: "00:00",
      end_time: "00:00",
    };
    list[parentIndex]["slots"].push(newItemObj);
    setInputs(list);
  };

  const handleRemoveNestedClick = (parent, index) => {
    const list = [...inputs];
    const itemsList = list[parent];
    itemsList.slots.splice(index, 1);
    setInputs(list);
  };

  const handleSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    // inputs.map((data) => {
    //   delete data._id;
    // });
    console.log(inputs, "data");
    let postData = { appointments: inputs };

    const result = await addAppointmentApi(postData);
    if (result.code == 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
      navigate("/appointment");
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  // const handleChangeDate = (event) => {
  //   setInputs((prevState) => {
  //     return {
  //       ...prevState,
  //       start_date: event.$d,
  //     };
  //   });
  // };
  const handleChangeEndDate = (event) => {
    setInputs((prevState) => {
      return {
        ...prevState,
        start_date: event.$d,
      };
    });
  };

  const handleChangeDays = (event) => {
    const {
      target: { value },
    } = event;
    setWeekName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const handleInputChange = (e, parentIndex, index) => {
    const list = [...inputs];
    const { name, value } = e.target;
    if (index !== undefined) {
      let selected_index = list[parentIndex];
      selected_index["slots"][index][name] = value;

      if (name === "start_time" || name === "slot_duration") {
        const slotDuration = parseInt(list[parentIndex]["slot_duration"], 10);
        console.log(slotDuration);
        const startTime = moment(
          selected_index["slots"][index]["start_time"],
          "HH:mm"
        );
        const endTime = startTime
          .clone()
          .add(slotDuration, "minutes")
          .format("HH:mm");
        selected_index["slots"][index]["end_time"] = endTime;
      }

      setInputs(list);
    } else {
      let selected_index = { ...list[parentIndex] };
      selected_index[name] = value;
      list[parentIndex] = selected_index;
      setInputs(list);
    }
  };
  const handleChangeDate = (e, parentIndex, name) => {
    const list = [...inputs];
    let selected_index = { ...list[parentIndex] };
    selected_index[name] = moment(e.$d).format("YYYY-MM-DD");
    list[parentIndex] = selected_index;
    setInputs(list);
  };

  const getList = async () => {
    setIsLoading(true);
    const result = await listAppointmentApi();
    if (result.code == 200) {
      if (result.appointments.length > 0) {
        setInputs(result.appointments);
      }
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleOpenDuplicate = (slot, index) => {
    setSelectedSlot(slot);
    setSelectedIndex(index);
    setOpenDuplicate(true);
  };

  const handleDuplicate = () => {
    setOpenDuplicate(false);
    let inputs_array = [...inputs];
    let slots = selectedSlot.slots.map((slot) => {
      return {
        ...slot,
        slot_id: faker.datatype.uuid(),
      };
    });
    inputs_array.splice(selectedIndex, 0, { ...selectedSlot, slots });
    console.log(inputs_array, "inputs_arrayinputs_arrayinputs_array");
    setInputs(inputs_array);
    setSelectedIndex(null);
    setSelectedSlot({});
    setTimeout(() => {
      const targetElement = document.getElementById(
        `slot-${selectedIndex + 1}`
      );
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth" });
      }
    }, 100);
  };
  console.log(inputs, "inputsinputsinputs");
  const onDragEnd = (result) => {
    if (!result.destination) return;

    const sourceParentIndex = parseInt(result.source.droppableId);
    const destinationParentIndex = parseInt(result.destination.droppableId);

    if (sourceParentIndex !== destinationParentIndex) return;

    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;

    const newInputs = [...inputs];
    const draggedSlot = newInputs[sourceParentIndex].slots.splice(
      sourceIndex,
      1
    )[0];
    newInputs[destinationParentIndex].slots.splice(
      destinationIndex,
      0,
      draggedSlot
    );

    setInputs(newInputs);
  };

  useEffect(() => {
    getList();
  }, []);
  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <div className="container">
      <div className="row">
        <div className="col-12 mb-4">
          <h2>Appointment Configuration</h2>
        </div>
      </div>

      <form onSubmit={handleSubmit}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="parents" type="PARENT">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {inputs.map((slot, parentIndex) => {
                  return (
                    <Draggable
                      key={parentIndex}
                      draggableId={`parent-${parentIndex}`}
                      index={parentIndex}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                        >
                          <div
                            className="card mb-3 p-2"
                            id={`slot-${parentIndex}`}
                          >
                            <div className="row mb-4">
                              <div className="col-6">
                                <b style={{ color: "white" }} className="mt-2">
                                  {parentIndex + 1}.
                                </b>
                              </div>
                              <div className="col-6 text-end">
                                <span
                                  href=""
                                  className="anchor-style"
                                  onClick={() =>
                                    handleOpenDuplicate(slot, parentIndex)
                                  }
                                  style={{ cursor: "pointer" }}
                                >
                                  <Icon
                                    icon="gg:duplicate"
                                    style={{
                                      fontSize: "20px",
                                    }}
                                  />
                                  <b> &nbsp;Duplicate Interval</b>
                                </span>
                              </div>

                              <div className="col-lg-6 col-md-6 col-sm-12 mt-3 mb-3">
                                <FormControl fullWidth required>
                                  <InputLabel id="demo-simple-select-label">
                                    Interval Type
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    name="slot_type"
                                    type="number"
                                    value={slot.slot_type}
                                    label="Interval Type"
                                    onChange={(e) =>
                                      handleInputChange(e, parentIndex)
                                    }
                                  >
                                    <MenuItem value="builtin">
                                      Built In
                                    </MenuItem>
                                    <MenuItem value="custom">Custom</MenuItem>
                                  </Select>
                                </FormControl>
                              </div>

                              {slot.slot_type == "builtin" && (
                                <div className="col-lg-6 col-md-6 col-sm-12 mt-3 mb-3">
                                  <FormControl fullWidth required>
                                    <InputLabel id="demo-simple-select-label">
                                      Slot Duration
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      name="slot_duration"
                                      type="number"
                                      value={slot.slot_duration}
                                      label="Slot Duration"
                                      onChange={(e) =>
                                        handleInputChange(e, parentIndex)
                                      }
                                    >
                                      <MenuItem value="15">15 min</MenuItem>
                                      <MenuItem value="30">30 min</MenuItem>
                                      <MenuItem value="45">45 min</MenuItem>
                                      <MenuItem value="60">60 min</MenuItem>
                                    </Select>
                                  </FormControl>
                                </div>
                              )}
                              {slot.slot_type == "custom" && (
                                <div className="col-lg-6 col-md-6 col-sm-12 mt-3 mb-3">
                                  <TextField
                                    id="outlined-basic"
                                    label="Custom Duration In Min"
                                    variant="outlined"
                                    fullWidth
                                    type="number"
                                    name="slot_duration"
                                    value={slot.slot_duration}
                                    onChange={(e) =>
                                      handleInputChange(e, parentIndex)
                                    }
                                  />
                                </div>
                              )}
                              <div className="col-lg-6 col-md-6 col-sm-12 mt-3 mb-3">
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DatePicker
                                    format="YYYY-DD-MM"
                                    mask="__/__/____"
                                    inputFormat="DD-MM-YYYY"
                                    label="Start Date"
                                    name="start_date"
                                    value={slot.start_date}
                                    onChange={(e) =>
                                      handleChangeDate(
                                        e,
                                        parentIndex,
                                        "start_date"
                                      )
                                    }
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        required={true}
                                        fullWidth
                                      />
                                    )}
                                  />
                                </LocalizationProvider>
                              </div>
                              <div className="col-lg-6 col-md-6 col-sm-12 mt-3 mb-3">
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DatePicker
                                    format="YYYY-DD-MM"
                                    mask="__/__/____"
                                    inputFormat="DD-MM-YYYY"
                                    label="End Date"
                                    name="end_date"
                                    value={slot.end_date}
                                    onChange={(e) =>
                                      handleChangeDate(
                                        e,
                                        parentIndex,
                                        "end_date"
                                      )
                                    }
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        required={true}
                                        fullWidth
                                      />
                                    )}
                                  />
                                </LocalizationProvider>
                              </div>
                              <Droppable
                                droppableId={`${parentIndex}`}
                                type="CHILD"
                              >
                                {(childProvided) => (
                                  <div
                                    ref={childProvided.innerRef}
                                    {...childProvided.droppableProps}
                                  >
                                    {slot.slots.map(
                                      (intervalSlot, childIndex) => {
                                        return (
                                          <Draggable
                                            key={intervalSlot.slot_id}
                                            draggableId={`${intervalSlot.slot_id}`}
                                            index={childIndex}
                                          >
                                            {(childProvided) => (
                                              <div
                                                className="container"
                                                ref={childProvided.innerRef}
                                                {...childProvided.draggableProps}
                                              >
                                                <div
                                                  className="row"
                                                  style={{
                                                    background: "#121826",
                                                    margin: "4px",
                                                    borderRadius: "10px",
                                                  }}
                                                >
                                                  <div className="col-lg-5 col-md-5 col-sm-12 mt-3 mb-3">
                                                    <TextField
                                                      variant="outlined"
                                                      id="time"
                                                      label="From"
                                                      type="time"
                                                      fullWidth
                                                      name="start_time"
                                                      required
                                                      value={
                                                        intervalSlot.start_time
                                                      }
                                                      onChange={(e) =>
                                                        handleInputChange(
                                                          e,
                                                          parentIndex,
                                                          childIndex
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                  <div className="col-lg-5 col-md-5 col-sm-12 mt-3 mb-3">
                                                    <TextField
                                                      variant="outlined"
                                                      id="time"
                                                      label="To"
                                                      type="time"
                                                      fullWidth
                                                      name="end_time"
                                                      required
                                                      value={
                                                        intervalSlot.end_time
                                                      }
                                                      onChange={(e) =>
                                                        handleInputChange(
                                                          e,
                                                          parentIndex,
                                                          childIndex
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                  <div className="col-2 mt-3 mb-3">
                                                    <span className="cross-icon">
                                                      {slot.slots.length > 1 ? (
                                                        <Tooltip title="Remove">
                                                          <RemoveCircleOutlineIcon
                                                            style={{
                                                              marginTop: "15px",
                                                            }}
                                                            onClick={() =>
                                                              handleRemoveNestedClick(
                                                                parentIndex,
                                                                childIndex
                                                              )
                                                            }
                                                            className="diary-icon-remove"
                                                          />
                                                        </Tooltip>
                                                      ) : (
                                                        ""
                                                      )}
                                                      <Tooltip title="Add">
                                                        <AddCircleOutlineIcon
                                                          style={{
                                                            marginTop: "15px",
                                                          }}
                                                          onClick={() =>
                                                            handleAddNestedInterval(
                                                              parentIndex
                                                            )
                                                          }
                                                          className="diary-icon-add"
                                                        />
                                                      </Tooltip>
                                                    </span>
                                                    <span
                                                      style={{
                                                        marginLeft: "30px",
                                                        marginTop: "15px",
                                                      }}
                                                      {...childProvided.dragHandleProps}
                                                    >
                                                      <DragIndicatorIcon />
                                                    </span>
                                                  </div>
                                                </div>
                                              </div>
                                            )}
                                          </Draggable>
                                        );
                                      }
                                    )}
                                  </div>
                                )}
                              </Droppable>
                              <div className="col-lg-12 col-md-12 col-sm-12 mt-3 d-flex">
                                <FormControl fullWidth required>
                                  <InputLabel id="demo-simple-select-label">
                                    Weekdays
                                  </InputLabel>
                                  <Select
                                    labelId="demo-multiple-name-label"
                                    id="demo-multiple-name"
                                    multiple
                                    name="days"
                                    value={slot.days}
                                    onChange={(e) =>
                                      handleInputChange(e, parentIndex)
                                    }
                                    input={<OutlinedInput label="Weekdays" />}
                                    MenuProps={MenuProps}
                                  >
                                    {names.map((name) => (
                                      <MenuItem
                                        key={name}
                                        value={name}
                                        style={getStyles(name, weekName, theme)}
                                      >
                                        {name}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                                {/* {inputs.length > 1 ? (
                    <DeleteOutlineIcon
                      onClick={() => handleRemoveClick(parentIndex)}
                      style={{ marginTop: "15px" }}
                    />
                  ) : (
                    ""
                  )} */}

                                <DeleteOutlineIcon
                                  // handleAgreeDelete
                                  onClick={() => handleAgreeDelete(parentIndex)}
                                  // onClick={() => handleRemoveClick(parentIndex)}
                                  style={{
                                    marginTop: "15px",
                                    cursor: "pointer",
                                  }}
                                />
                              </div>
                              {}
                            </div>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                <div>
                  <span
                    href=""
                    className="anchor-style "
                    onClick={() => handleAddClick()}
                    style={{ cursor: "pointer" }}
                  >
                    <AddIcon />
                    <b>Add New Interval</b>
                  </span>
                </div>
                {inputs.length > 0 ? (
                  <div className="col-12 text-end mb-5">
                    <button className="small-contained-button">
                      {isLoading ? "Submitting..." : "Submit"}
                    </button>
                  </div>
                ) : null}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </form>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this ?"}
        handleAgree={handleDelete}
      />
      <CustomConfirmation
        open={openDuplicate}
        setOpen={setOpenDuplicate}
        title={"Are you sure you want to duplicate this interval?"}
        handleAgree={handleDuplicate}
      />
    </div>
  );
};

export default AddAppointment;
