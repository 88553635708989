import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import MUICustomTabs from "src/components/GeneralComponents/MUICustomTabs";
import AttitudeAssessmentQuestions from "./AssessmentHistoryFiller";
import { IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { client_url } from "src/config/config";
import ChatCard from "../SupportTicket/ChatCard";
import ModelBox from "src/components/ModalBox/ImageModalBox";
import CustomConfirmation from "src/components/menuIcons/CustomConfirmation";
import { AssessmentDetailListApi } from "src/DAL/AssessmentApi/assessmentApi";
import ChatCardAssessment from "../SupportTicket/ChatCardAssessment";
import CustomDrawer from "src/components/DrawerForm/CustomDrawer";
import moment from "moment";

const supportTicketData = {
  action_by: "member_user",
  action_id: "6341533e64c1cc66c9a16a38",
  close_note: "",
  comment_badge_count: 9,
  createdAt: "2022-10-09T12:07:27.909Z",
  department: "62a7207733d25e1b5a824758",
  is_need_attention: false,
  is_need_fixes: false,
  is_reminder: false,
  last_action_date: "2023-04-04T04:39:19.000Z",
  member: "6341533e64c1cc66c9a16a38",
  member_profile_image: "N/A",
  member_user: "N/A",
  message: "",
  reason_to_solve: "",
  reference_number: 1055,
  response_status: 1,
  status: true,
  subject: "New Test Ticket",
  support_ticket_date: "2022-10-09T17:07:27.000Z",
  ticket_status: 0,
  updatedAt: "2023-04-04T03:39:19.433Z",
  __v: 2,
  _id: "6342b97f64c1cc66c9a49a75",
  comment: [
    {
      action_date: "2022-10-10 15:24",
      action_user_info: {
        action_by: "consultant_user",
        action_id: "6226e0f3eb4009313ce460c9",
        action_name: "Consultant",
        profile_image: "consultant/12006176-377a-4979-8541-695ad1cdb4ff.png",
      },
      createdAt: "2022-10-10 10:24",
      is_self: false,
      message:
        '<p><img src="https://dynamite-lifestyle-dev-app-bucket.s3.amazonaws.com/upload/859b9ae1-286c-4f5f-9bac-f9625a5ce81b.jpg" alt="" width="142" height="118" /></p>',
      status: true,
      support_ticket: "6342b97f64c1cc66c9a49a75",
      updatedAt: "2022-10-10T10:26:54.200Z",
      __v: 0,
      _id: "6343f2e91da8b89c743bdf68",
    },
  ],
};

const AssessmentHistory = () => {
  const { state, pathname } = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState(0);
  const [openResolved, setOpenResolved] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [open, setOpen] = useState(false);
  const [type, setType] = useState("");
  const [assessmetData, setAssessmetData] = useState();
  const [drawerCloseNote, setdrawerCloseNote] = useState(false);
  const [drawerInternalNote, setdrawerInternalNote] = useState(false);

  const [ReadyToCLose, setReadyToCLose] = useState(" ");
  ReadyToCLose;
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const handleMarkResolvedPopup = (value) => {
    setOpenResolved(true);
  };
  const handleClose = () => setOpen(false);
  const handleOpen = (image_path) => {
    setImageUrl(image_path);
    setOpen(true);
  };
  const handleMarkResolved = async () => {
    setOpenResolved(false);
  };
  const handleOpenInternalNoteDrawer = (value) => {
    setdrawerInternalNote(true);
  };
  const handleCLoseInternalNoteCLoseDrawer = () => {
    setdrawerInternalNote(false);
  };

  const getAssessmentDetail = async () => {
    let postData = {
      internal_note_type:
        tabValue == 0
          ? "thought"
          : tabValue == 1
          ? "feeling"
          : tabValue == 2
          ? "action"
          : "",
      assessment_id: params.id,
    };
    const result = await AssessmentDetailListApi(postData);
    if (result.code == 200) {
      setAssessmetData(result.internal_notes);
    }
  };
  const TABS_OPTIONS = [
    {
      title: `Thoughts`,
      component: (
        <AttitudeAssessmentQuestions
          data={state?.assessment_results["thought_result"]}
        />
      ),
    },
    {
      title: `Feelings`,
      component: (
        <AttitudeAssessmentQuestions
          data={state?.assessment_results["feeling_result"]}
        />
      ),
    },
    {
      title: `Actions`,
      component: (
        <AttitudeAssessmentQuestions
          data={state?.assessment_results["action_result"]}
        />
      ),
    },
  ];
  useEffect(() => {
    getAssessmentDetail();
    setType(
      tabValue == 0
        ? "thought"
        : tabValue == 1
        ? "feeling"
        : tabValue == 2
        ? "action"
        : ""
    );
  }, [tabValue]);
  console.log(state, "stateeeee");
  return (
    <div className="container">
      <div className="row section-space">
        <div className="col-12">
          <IconButton
            className="back-screen-button"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon />
          </IconButton>
          <span className="ms-3">
            {state?.Username + " (" + state.email + ")"}{" "}
            <b className="completeDate"> Completed Date :</b>
            <b>{" ( " + state.time + " )"}</b>
          </span>
        </div>
        <div className="col-sm-12 col-md-6 mb-4">
          <h2>Assessment History</h2>
        </div>
        <div className="col-sm-12 col-md-6 mb-4">
          <a
            className="small-contained-button float-end mt-1 me-2 buttonAssessement"
            onClick={handleOpenInternalNoteDrawer}
            target="_blank"
          >
            Client Notes
          </a>
          <a
            className="small-contained-button float-end mt-1 me-2"
            href={client_url + "login-as-customer"}
            target="_blank"
          >
            Login As Customer
          </a>
        </div>
        <div className="col-12">
          <MUICustomTabs
            data={TABS_OPTIONS}
            value={tabValue}
            handleChange={handleChange}
          />
        </div>
        {/* Chat Note Option */}
        <div className="row media-margin">
          {/* <div className="col-12">
            <p>Reply</p>
          </div> */}
          <div className="col-sm-12 my-2">
            {supportTicketData.ticket_images &&
              supportTicketData.ticket_images.map((image, i) => {
                const ext = image.thumbnail_1.split(".").pop();
                if (
                  ext == "jpg" ||
                  ext == "JPG" ||
                  ext == "png" ||
                  ext == "webp" ||
                  ext == "jpeg" ||
                  ext == "JPEG" ||
                  ext == "PNG"
                ) {
                  return (
                    <span className="preview ticket_image_preview" key={i}>
                      <img
                        onClick={() => {
                          handleOpen(s3baseUrl + image.thumbnail_1);
                        }}
                        className="p-0"
                        src={s3baseUrl + image.thumbnail_2}
                      />
                    </span>
                  );
                } else {
                  return (
                    <span className="preview ticket_image_preview" key={i}>
                      <a
                        href={s3baseUrl + image.thumbnail_1}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={getCommentImage(image)} />
                      </a>
                    </span>
                  );
                }
              })}
          </div>
          <CustomDrawer
            isOpenDrawer={drawerInternalNote}
            onOpenDrawer={handleOpenInternalNoteDrawer}
            onCloseDrawer={handleCLoseInternalNoteCLoseDrawer}
            pageTitle="Notes"
            componentToPassDown={
              <ChatCardAssessment
                comments={assessmetData}
                ticket_id={params.id}
                list_data={getAssessmentDetail}
                supportTicketData={supportTicketData}
                memberName={state?.Username}
                memberEmail={state?.email}
                hideImageBox={true}
                Assessment_type={type}
              />
            }
          />

          <ModelBox
            open={open}
            handleClose={handleClose}
            image_url={imageUrl}
          />
          <CustomConfirmation
            open={openResolved}
            setOpen={setOpenResolved}
            title={"Are you sure you want to mark this Ticket as resolved?"}
            handleAgree={handleMarkResolved}
          />
        </div>
        {/* Chat Note Option end*/}
      </div>
    </div>
  );
};

export default AssessmentHistory;
