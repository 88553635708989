import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useEffect, useState } from "react";
import MUIAutocomplete from "src/components/GeneralComponents/MUIAutocomplete";
import { updateMissionControlApi } from "src/DAL/Login/Login";
import {
  createBroadcast,
  getMemebersAndGroups,
  updateBroadcast,
} from "src/DAL/Broadcast/Broadcast";
import { useSnackbar } from "notistack";

const CreateNewBroadCast = ({
  onCloseDrawer,
  broadcastsEdit,
  broadcasts,
  getListapi,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [members, setMembers] = useState([]);

  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadMoreChatPath, setLoadMoreChatPath] = useState(
    `api/broadcast/list_broadcast?page=${0}&limit=${50}`
  );
  const [input, setInput] = useState({
    broadcast_title: "",
    group: [],
    member: [],
  });
  const getGroupsMembers = async () => {
    const result = await getMemebersAndGroups();
    if (result.code == 200) {
      let members = result.members.map((item) => {
        return {
          ...item,
          fullName:
            item.first_name + " " + item.last_name + "(" + item.email + ")",
        };
      });

      setMembers(members);
      setGroups(result.groups);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const addBroadCast = async (e) => {
    e.preventDefault();
    setLoading(true);
    let postData = {
      broadcast_title: input.broadcast_title,
      group: input.group,
      member: input.member,
    };
    const result = broadcastsEdit
      ? await updateBroadcast(postData, broadcasts._id)
      : await createBroadcast(postData);
    if (result.code == 200) {
      onCloseDrawer();
      let data = {
        search_text: "",
      };
      getListapi(loadMoreChatPath, data, "search");
      setLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleChange = (name, e, type) => {
    if (type == "multipe") {
      setInput({ ...input, [name]: e });
    } else {
      setInput({ ...input, [name]: e.target.value });
    }
  };

  useEffect(() => {
    getGroupsMembers();
  }, []);
  useEffect(() => {
    if (broadcastsEdit) {
      let members = broadcasts.member.map((item) => {
        return {
          ...item,
          fullName:
            item.first_name + " " + item.last_name + "(" + item.email + ")",
        };
      });

      setInput({
        broadcast_title: broadcasts.broadcast_title,
        group: broadcasts.group,
        member: members,
      });
    }
  }, [broadcastsEdit]);
  return (
    <>
      <div className="container">
        <form onSubmit={addBroadCast}>
          <div className="row">
            <div className="col-12">
              <TextField
                className="mt-4 inputs-fields"
                id="outlined-basic"
                label="Title"
                variant="outlined"
                name="broadcast_title"
                value={input.broadcast_title}
                required={true}
                onChange={(e) => handleChange("broadcast_title", e, "single")}
              />
            </div>
            <div className="col-12  mt-3">
              <MUIAutocomplete
                inputLabel="Group"
                multiple
                required={input.member.length > 0 ? false : true}
                limitTags={2}
                selectedOption={input.group}
                setSelectedOption={(e) => {
                  handleChange("group", e, "multipe");
                }}
                optionsList={groups}
                name="title"
              />
            </div>{" "}
            <div className="col-12  mt-3">
              <MUIAutocomplete
                inputLabel="Member"
                multiple
                required={input.group.length > 0 ? false : true}
                selectedOption={input.member}
                limitTags={2}
                setSelectedOption={(e) => {
                  handleChange("member", e, "multipe");
                }}
                optionsList={members}
                name="fullName"
              />
            </div>
          </div>
          <div className="mt-3">
            <span className="float-end">
              {broadcastsEdit ? (
                <button className="submit-button">
                  {loading ? "Updating..." : "Update"}
                </button>
              ) : (
                <button className="submit-button">
                  {loading ? "Saving..." : "Save"}
                </button>
              )}
            </span>
          </div>
        </form>
      </div>
    </>
  );
};

export default CreateNewBroadCast;
