import React from "react";
import { s3baseUrl } from "src/config/config";
import { htmlDecode } from "src/utils/convertHtml";

export default function CurrentUpcomingEvent({ event_heading, events_list }) {
  return (
    <>
      {events_list.length > 0 && <h4>{event_heading}</h4>}
      {events_list?.map((event) => {
        return (
          <>
            <div className="col-12 mt-2">
              <h6>{htmlDecode(event.title)}</h6>
              <a
                href={event.button_link}
                rel="noopener noreferrer"
                target="_blank"
              >
                <img src={s3baseUrl + event.images.thumbnail_2} />
              </a>
              <p
                className="mt-2"
                dangerouslySetInnerHTML={{
                  __html: event.description,
                }}
              ></p>
            </div>
            <div className="col-12 p-0 community-star-icon text-center">
              {event.button_text && (
                <a
                  href={event.button_link}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <button className="join-today-btn mt-0">
                    {htmlDecode(event.button_text)}
                  </button>
                </a>
              )}
            </div>
          </>
        );
      })}
    </>
  );
}
