import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import {
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { s3baseUrl } from "../../config/config";
import CustomPopover from "src/components/menuIcons/CustomPopover";
import CustomConfirmation from "src/components/menuIcons/CustomConfirmation";
import {
  DeleteRecording,
  RecordingListing,
} from "src/DAL/Recording/Recordings";
import RecordNotFound from "src/components/RecordNotFound";
import { htmlDecode } from "src/utils/convertHtml";
import SearchIcon from "@mui/icons-material/Search";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const RecordingCard = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [recordingsList, setRecordingsList] = useState([]);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [deleteValue, setDeleteValue] = useState("");
  const [query, setQuery] = useState("");
  const handleChangeSearch = (e) => {
    setQuery(e.target.value);
    localStorage.setItem("recordings_search", e.target.value);
  };
  const getFilteredData = (query) => {
    let dataToFilter = [...recordingsList];
    if (query) {
      let resultFilter = dataToFilter.filter((post) =>
        post.title.toLowerCase().includes(query.toLowerCase())
      );
      return resultFilter;
    } else {
      return dataToFilter;
    }
  };

  const recordingList = async () => {
    setIsLoading(true);
    const result = await RecordingListing();
    if (result.code === 200) {
      setRecordingsList(result.recording);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleEdit = (value) => {
    navigate("/recordings/Edit-recording", {
      state: { editValues: value },
    });
  };
  const handleAgreeDelete = (value) => {
    setDeleteValue(value.recording_slug);
    setOpenDelete(true);
    handleCloseMenu();
  };

  //Deleting Recording
  const handleDelete = async () => {
    setOpenDelete(false);
    const result = await DeleteRecording(deleteValue);
    setIsLoading(false);
    if (result.code === 200) {
      recordingList();
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleNavigate = () => {
    navigate(`/recordings/add-recording`);
  };

  const handleNavigateDetail = (value) => {
    navigate("/recordings/detail-recording", {
      state: { detailValues: value },
    });
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },

    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  useEffect(() => {
    recordingList();
    const search_text = localStorage.getItem("recordings_search");
    if (search_text) {
      setQuery(search_text);
    }
  }, []);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-6 col-sm-12">
          <h2>Recordings </h2>
        </div>
        <div className="col-lg-6 col-sm-12 text-end d-flex justify-content-end ">
          <div>
            <FormControl
              variant="outlined"
              style={{ marginTop: "2px", marginRight: "10px" }}
              size="small"
            >
              <InputLabel htmlFor="outlined-adornment-password">
                Search
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type="text"
                variant="small"
                onChange={handleChangeSearch}
                value={query}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      edge="end"
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                }
                label="Search"
              />
            </FormControl>
          </div>
          <button
            onClick={handleNavigate}
            className="small-contained-button"
            style={{ marginTop: "2px", marginLeft: "5px" }}
          >
            Add Recording
          </button>
        </div>

        {getFilteredData(query).length == 0 ? (
          <RecordNotFound title="Recordings" />
        ) : (
          getFilteredData(query).map((value, index) => {
            return (
              <div className="col-lg-4 col-md-4 col-sm-12 mb-4" key={index}>
                <div className="card mt-4 pods-cards-shadow cursor h-100">
                  <img
                    src={s3baseUrl + value.recording_image.thumbnail_2}
                    className="card-img-top pods-image"
                    alt="Pods"
                    onClick={() => handleNavigateDetail(value)}
                  />
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12">
                        <h3
                          className="h2-heading pe-4"
                          onClick={() => handleNavigateDetail(value)}
                        >
                          {htmlDecode(value.title)}
                        </h3>
                      </div>

                      <div className="col-2 menu_card_icon">
                        <CustomPopover menu={MENU_OPTIONS} data={value} />
                      </div>
                      <CustomConfirmation
                        open={openDelete}
                        setOpen={setOpenDelete}
                        title={
                          "Are you sure you want to delete this recording?"
                        }
                        handleAgree={handleDelete}
                      />
                    </div>
                    <p
                      className="programme-card-desc mb-3"
                      onClick={() => handleNavigateDetail(value)}
                    >
                      {htmlDecode(value.short_description)}
                    </p>
                    {/* <p className="programme-card-desc mb-3 programme-card-desc-muted">
                      <b className="text-muted">Programme:</b>{" "}
                      {value.program.title}
                    </p> */}
                    <div className="row recording-card-date">
                      <div className="col-8 card-button recording-card-date-position">
                        <p className="pods-active-members">
                          <span> {htmlDecode(value.recording_date)} </span>
                        </p>
                      </div>
                      <div className="col-4 text-end ms-auto">
                        {/* <div className="col-12 text-end mb-3"> */}
                        {value.status == true ? (
                          <button className="small-contained-chip-success">
                            ACTIVE
                          </button>
                        ) : (
                          <button className="small-contained-chip-error">
                            INACTIVE
                          </button>
                        )}
                        {/* </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

export default RecordingCard;
