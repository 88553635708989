import { invokeApi } from "../../bl_libs/invokeApi";

export const AddEventApi = async (data) => {
  const requestObj = {
    path: `api/event/consultant/`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const eventsListing = async (data) => {
  const requestObj = {
    path: `api/event/consultant`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const eventsListingWithPagination = async (
  page,
  limmit,
  search_text
) => {
  const requestObj = {
    path: `api/event/consultant/pagination?page=${page}&limit=${limmit}&search_text=${
      search_text ? search_text : ""
    }`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const eventsDetailApi = async (event_slug) => {
  const requestObj = {
    path: `api/event/detail/${event_slug}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const DeleteEvent = async (event_slug) => {
  const requestObj = {
    path: `api/event/${event_slug}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const EditEventApi = async (event_slug, data) => {
  const requestObj = {
    path: `api/event/consultant/${event_slug}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const add_new_event_api = async (data) => {
  const requestObj = {
    path: `api/event/add/by_admin`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const add_new_event_api_by_delegate = async (data) => {
  const requestObj = {
    path: `api/event/add/by_delegate`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const new_event_list_api = async (data) => {
  const requestObj = {
    path: `api/event/list`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const connect_with_google = async (data) => {
  const requestObj = {
    path: `app/sync_delegate_cylender`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const remove_google_access_api = async () => {
  const requestObj = {
    path: `api/member/remove_google_access/remove`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const eventDetailApi = async (slug) => {
  const requestObj = {
    path: `api/event/detail/${slug}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const eventDetailApiWithPagination = async (
  slug,
  type,
  page,
  rowsPerPage,
  searchText
) => {
  const requestObj = {
    path: `api/event/event_details/with_type/${slug}?page=${page}&limit=${rowsPerPage}&type=${type}&search_text=${
      searchText ? searchText : ""
    }`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const delete_event_api = async (data) => {
  const requestObj = {
    path: `api/event/delete`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const update_member_event_api = async (data, event_slug) => {
  const requestObj = {
    path: `api/event/update/by_admin/${event_slug}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const update_member_event_api_by_delegate = async (data, event_slug) => {
  const requestObj = {
    path: `api/event/event_iteration/update/by_member/${event_slug}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const update_event_iteration_api = async (data, event_slug) => {
  const requestObj = {
    path: `api/event/event_iteration/update/${event_slug}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const update_event_iteration_api_delegate = async (data, event_slug) => {
  const requestObj = {
    path: `api/event/event_iteration/update/by_member/${event_slug}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const event_iteration_members_api = async (data) => {
  const requestObj = {
    path: `api/event/update/event_iteration_members`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const calendar_event_members_api = async (page, limit, search, data) => {
  const requestObj = {
    path: `api/event/event_member_list?search_text=${search}&page=${page}&limit=${limit}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
