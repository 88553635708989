import React from "react";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { TextField } from "@mui/material";
import moment from "moment";

export const DashboardFilter = ({ inputs, setInputs, getDashboardDetail }) => {
  const handleChangeDate = (name, value) => {
    setInputs((prevState) => {
      return {
        ...prevState,
        [name]: moment(value).format("YYYY-MM-DD"),
      };
    });
  };
  const handleFilter = () => {
    getDashboardDetail(inputs);
    // setInputs({
    //   start_date: null,
    //   end_date: null,
    // });
  };
  const handleClearFilter = () => {
    getDashboardDetail();
    setInputs({
      start_date: null,
      end_date: null,
    });
  };

  return (
    <div className="container">
      <form onSubmit={handleFilter}>
        <div className="row">
          <div className="col-12 mt-3">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="From"
                name="startDate"
                inputFormat="yyyy-MM-dd"
                value={inputs.start_date}
                onChange={(e) => {
                  handleChangeDate("start_date", e);
                }}
                format="YYYY-MM-DD"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="inputs-fields"
                    required={true}
                    fullWidth
                  />
                )}
              />
            </LocalizationProvider>
          </div>
          <div className="col-12 mt-3">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="To"
                name="startDate"
                value={inputs.end_date}
                inputFormat="yyyy-MM-dd"
                onChange={(e) => {
                  handleChangeDate("end_date", e);
                }}
                format="YYYY-MM-DD"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="inputs-fields"
                    required={true}
                    fullWidth
                  />
                )}
              />
            </LocalizationProvider>
          </div>
          <div className="col-12 mt-3 text-end">
            <button
              className="small-contained-button me-2"
              onClick={handleClearFilter}
            >
              Clear Filter
            </button>
            <button className="small-contained-button">Submit</button>
          </div>
        </div>
      </form>
    </div>
  );
};
