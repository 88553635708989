import React, { useState, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import Tooltip from "@mui/material/Tooltip";
import "@fullcalendar/daygrid/main.css";
import moment from "moment";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useNavigate } from "react-router-dom";
import CustomDrawer from "src/components/DrawerForm/CustomDrawer";
import {
  get_view_dates,
  handle_downgrade_month,
  handle_upgrade_month,
} from "src/utils/constants";
import EventDetailNew from "src/pages/Calender/EventDetailNew";
import DayCalendar from "./DayCalendar";
import WeekCalendar from "./WeekCalendar";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { TIME_ZONE_CONVERSION } from "src/utils/constants";
import { useContentSetting } from "src/Hooks/ContentSettingState";
import AddCalendarEventsNew from "./AddCalendarEventsNew";
import FullPagePopup from "./FullPagePopup";
import EventDetail from "./EventDetail";
import DetailPopUpModel from "./DetailPopUpModel";
import { delete_event_api } from "src/DAL/Calendar/Calendar";
import PerformActionOn from "../ModalBox/PerformActionOn";
import { useSnackbar } from "notistack";
import UpdateCalendarEventsNew from "./UpdateCalendarEventsNew";
import UpdateCalendarItrationNew from "./UpdateCalendarItrationNew";
import GoogleAuth from "./GoogleAuth";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

function GeneralCalendar({
  eventList,
  setCurrentDate,
  showAddEvent,
  time_zone,
  currentDate,
  created_for,
  isLoading,
  user_type,
  getEventListings,
  setIsLoading,
  showGoogleCalender,
}) {
  const [events, setEvents] = useState([]);
  const navigate = useNavigate();
  const classes = useStyles();
  const [eventDetailData, setEventDetailData] = useState([]);
  const [editDrawerState, setEditDrawerState] = useState(false);
  const [drawerState, setDrawerState] = useState(false);
  const [drawerStateUpdate, setDrawerStateUpdate] = useState(false);
  const [openItration, setopenItration] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [popupState, setPopupState] = useState(false);
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const { enqueueSnackbar } = useSnackbar();
  const [selectedTool, setSelectedTool] = useState("month");
  const { userInfo, adminTimeZone, isEnableGoogleCalender } =
    useContentSetting();

  const get_dates = (tool) => {
    if (tool == "month") {
      return get_view_dates(tool);
    } else {
      return {
        start_date: moment().startOf(tool).toDate(),
        end_date: moment().endOf(tool).toDate(),
      };
    }
  };

  const handleOpenDrawer = () => {
    let path = `/calendar-events`;
    if (created_for == "consultant_user") {
      path = `/delegate-events`;
    }
    navigate(`${path}/add-event`);
  };

  const handleOpenDrawerItration = () => {
    let path = `/calendar-events`;
    if (created_for == "consultant_user") {
      path = `/delegate-events`;
    }
    navigate(
      `${path}/edit-iteration/${eventDetailData.event_slug}?iteration_id=${eventDetailData._id}`
    );
  };

  const handleUpdate = () => {
    let path = `/calendar-events`;
    if (created_for == "consultant_user") {
      path = `/delegate-events`;
    }
    navigate(`${path}/edit-event/${eventDetailData.event_slug}`);
  };

  const handleNavigateListEvent = () => {
    navigate(
      `${
        created_for
          ? "/delegate-events/event-list"
          : "/calendar-events/event-list"
      }`
    );
  };

  const handlecloseDrawerItration = () => {
    setopenItration(false);
  };

  const handleOpenDrawerUpdate = () => {
    setDrawerStateUpdate(true);
  };

  const handleOpenEditDrawer = () => {
    setEditDrawerState(true);
  };

  const handleCloseDrawer = () => {
    setDrawerState(false);
  };
  const handleCloseEditDrawer = () => {
    setEditDrawerState(false);
  };

  const eventDetail = (event) => {
    setEventDetailData(event);
    setPopupState(true);
  };
  const handleAgreeDelete = () => {
    setPopupState(false);
    setOpenDelete(true);
  };

  const handleCloseDrawerUpdate = () => {
    setDrawerStateUpdate(false);
  };
  const handleDelete = async (value) => {
    setOpenDelete(false);
    let postData = {
      event_slug: eventDetailData.event_slug,
      iteration_id: eventDetailData._id,
      update_type: value,
    };
    const result = await delete_event_api(postData);
    if (result.code === 200) {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
      getEventListings();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const get_start_date_end_time = (date) => {
    return TIME_ZONE_CONVERSION(
      date,
      "YYYY-MM-DD HH:mm",
      adminTimeZone,
      userInfo.time_zone
    );
  };

  const get_start_end_time = (date) => {
    return TIME_ZONE_CONVERSION(
      date,
      "HH:mm",
      adminTimeZone,
      userInfo.time_zone
    );
  };

  const renderEventContent = (eventInfo) => {
    return (
      <Tooltip title={eventInfo.event.title}>
        <div
          className="d-flex"
          onClick={() => eventDetail(eventInfo.event._def.extendedProps)}
        >
          <div
            className="event-title-dot"
            style={{
              backgroundColor: eventInfo.event._def.extendedProps.event_color,
            }}
          ></div>
          <div className="calendar-event-title"></div>
          <div className="calendar-event-title">
            <span className="start_time_for_popup">
              {eventInfo.event._def.extendedProps.start_time_for_popup}
            </span>
            &nbsp;&nbsp;
            {eventInfo.event.title.length > 15
              ? `${eventInfo.event.title.substring(0, 15)}...`
              : eventInfo.event.title}
          </div>
        </div>
      </Tooltip>
    );
  };

  const getEventListing = async (event, time_zone) => {
    let all_events = [];
    // moment.tz.setDefault(time_zone);
    if (event.length > 0) {
      all_events = event.map((event) => {
        return {
          ...event,
          title: event.title,
          color: event.color,
          event_color: event.color,
          event_title: event.title,
          status: event.status,
          date: moment(event.start_date_time).format("YYYY-MM-DD"),
          start_date: moment(event.start_date_time).format("YYYY-MM-DD"),
          end_date: moment(event.end_date_time).format("YYYY-MM-DD"),
          start: get_start_date_end_time(event.start_date_time),
          end: get_start_date_end_time(event.end_date_time),
          start_time: get_start_end_time(event.start_date_time),
          end_time: get_start_end_time(event.end_date_time),
        };
      });
    }
    setEvents(all_events);
  };

  const TOOL_BUTTONS = [
    {
      text: "month",
    },
    {
      text: "week",
    },
    {
      text: "day",
    },
  ];

  const handleChangeTools = (tool) => {
    setCurrentMonth(new Date());
    let dates = get_dates(tool);
    setCurrentDate(dates);
    setSelectedTool(tool);
  };

  const getCenteredText = () => {
    let text = "";
    if (selectedTool == "month") {
      // text = moment(currentDate.start_date).add(1, "month").format("MMM YYYY");
      text = moment(currentMonth).format("MMM YYYY");
    } else if (selectedTool == "week") {
      let month = moment(currentDate.start_date).format("MMM");
      let start_date = moment(currentDate.start_date).format("DD");
      let end_date = moment(currentDate.end_date).format("DD");
      let year = moment(currentDate.start_date).format("YYYY");
      text = `${month} ${start_date} - ${end_date} ${year}`;
    } else {
      text = moment(currentDate.start_date).format("MMM DD, YYYY");
    }
    return text;
  };

  const hanldeChangeDates = (value) => {
    let start_date = currentDate.start_date;
    let end_date = currentDate.end_date;
    let new_object = {};
    if (value == "next") {
      if (selectedTool == "month") {
        let data = handle_upgrade_month(currentMonth);
        new_object = {
          start_date: data.start_date,
          end_date: data.end_date,
        };
        setCurrentMonth(data.currentMonth);
      } else {
        new_object = {
          start_date: moment(start_date)
            .add(1, selectedTool)
            .startOf(selectedTool)
            .toDate(),
          end_date: moment(end_date)
            .add(1, selectedTool)
            .endOf(selectedTool)
            .toDate(),
        };
      }
    } else {
      if (selectedTool == "month") {
        let data = handle_downgrade_month(currentMonth);
        new_object = {
          start_date: data.start_date,
          end_date: data.end_date,
        };
        setCurrentMonth(data.currentMonth);
      } else {
        new_object = {
          start_date: moment(start_date)
            .subtract(1, selectedTool)
            .startOf(selectedTool)
            .toDate(),
          end_date: moment(end_date)
            .subtract(1, selectedTool)
            .endOf(selectedTool)
            .toDate(),
        };
      }
    }
    setCurrentDate(new_object);
  };

  const handleChangeNextPrev = (value) => {
    if (selectedTool == "month") {
      let classToClick = "fc-next-button";
      if (value == "prev") {
        classToClick = "fc-prev-button";
      }
      let click_element = document.getElementsByClassName(classToClick)[0];
      click_element.click();
    }
    hanldeChangeDates(value);
  };

  const handleClickDate = (event, type) => {
    let date_object = {
      start_date: event.dateStr,
      end_date: event.dateStr,
    };
    if (type == "more_link") {
      date_object = {
        start_date: moment(event.date).format("YYYY-MM-DD"),
        end_date: moment(event.date).format("YYYY-MM-DD"),
      };
    }
    setSelectedTool("day");
    setCurrentDate(date_object);
  };

  useEffect(() => {
    getEventListing(eventList, time_zone);
  }, [eventList]);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-4 col-md-6">
          <h2>
            {user_type == "delegate"
              ? "All Year Calendar"
              : created_for == "consultant_user"
              ? "Delegate Events"
              : "Calendar"}
          </h2>
        </div>
        <div className="col-8 col-md-6">
          {showAddEvent && (
            <>
              {user_type !== "delegate" && (
                <button
                  className="small-contained-button float-end mt-1"
                  onClick={handleOpenDrawer}
                >
                  Add Event
                </button>
              )}
              {created_for == "consultant_user" ? (
                ""
              ) : (
                <button
                  className="small-contained-button float-end mt-1 me-2"
                  onClick={handleNavigateListEvent}
                >
                  List Event
                </button>
              )}
            </>
          )}
          {showGoogleCalender && isEnableGoogleCalender && (
            <div className="float-end mt-1 mb-4 me-2">
              <GoogleAuth />
            </div>
          )}
        </div>
        <div className="col-lg-4 col-sm-12 text-center text-lg-start full-calendar-toolbar mt-4">
          <div class="btn-group toollbar-group-buttons">
            <button
              className={`small-contained-button`}
              onClick={() => {
                handleChangeNextPrev("prev");
              }}
            >
              <ArrowBackIosIcon />
            </button>
            <button
              className={`small-contained-button`}
              onClick={() => {
                handleChangeNextPrev("next");
              }}
            >
              <ArrowForwardIosIcon />
            </button>
          </div>
        </div>
        <div className="col-lg-4 col-sm-12 text-center full-calendar-toolbar mt-4">
          <h2>{getCenteredText()}</h2>
        </div>
        <div className="col-lg-4 col-sm-12 text-center text-lg-end mt-4">
          <div class="btn-group toollbar-group-buttons">
            {TOOL_BUTTONS.map((button) => {
              return (
                <button
                  className={`small-contained-button ${
                    button.text == selectedTool ? "selected-button" : ""
                  }`}
                  onClick={() => {
                    handleChangeTools(button.text);
                  }}
                >
                  {button.text}
                </button>
              );
            })}
          </div>
        </div>

        <div className="col-md-12 full-calendar full-calendar-new-flow mt-3 order-4">
          {selectedTool == "month" ? (
            <FullCalendar
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
              headerToolbar={{
                left: "today prev,next",
                center: "title",
                right: "dayGridMonth,timeGridWeek,timeGridDay",
              }}
              className="fc-day-sun fc-toolbar-chunk fc eventimage fc-col-header-cell-cushion fc-today-button fc-right fc-prev-button fc-right fc-next-button fc-dayGridMonth-button fc-timeGridWeek-button fc-timeGridDay-button fc-daygrid-day-number fc-daygrid-day-top"
              eventContent={renderEventContent}
              events={events}
              initialView="dayGridMonth"
              editable={true}
              selectable={true}
              selectMirror={true}
              dayMaxEvents={
                window.screen.width > 768
                  ? 3
                  : window.screen.width > 575
                  ? 2
                  : 1
              }
              weekends={true}
              fixedWeekCount={false}
              moreLinkContent={function (args) {
                return "+" + args.num;
              }}
              dateClick={function (args) {
                handleClickDate(args, "");
              }}
              moreLinkClick={function (args) {
                handleClickDate(args, "more_link");
              }}
            />
          ) : selectedTool == "week" ? (
            <WeekCalendar
              events={events}
              handleClick={eventDetail}
              currentDate={currentDate}
              isLoading={isLoading}
            />
          ) : (
            <DayCalendar
              events={events}
              handleClick={eventDetail}
              currentDate={currentDate}
              isLoading={isLoading}
            />
          )}
        </div>
      </div>

      <CustomDrawer
        isOpenDrawer={editDrawerState}
        onOpenDrawer={handleOpenEditDrawer}
        onCloseDrawer={handleCloseEditDrawer}
        pageTitle="Event Detail"
        componentToPassDown={
          <EventDetailNew
            setEditDrawerState={setEditDrawerState}
            eventDetailData={eventDetailData}
            setEventDetailData={setEventDetailData}
            dataList={getEventListings}
            onCloseDrawer={handleCloseEditDrawer}
            defaultTimeZone={time_zone}
            user_type={user_type}
          />
        }
      />
      <FullPagePopup
        open={drawerState}
        setOpen={setDrawerState}
        componentToPassDown={
          <AddCalendarEventsNew
            dataList={getEventListings}
            created_for={created_for}
            onCloseDrawer={handleCloseDrawer}
          />
        }
      />
      <DetailPopUpModel
        open={popupState}
        setOpen={setPopupState}
        handleAgreeDelete={handleAgreeDelete}
        handleEdit={handleUpdate}
        handleOpenDrawerItration={handleOpenDrawerItration}
        title={"Event Detail"}
        created_for={created_for}
        eventDetailData={eventDetailData}
        user_type={user_type}
        componentToPassDown={<EventDetail eventDetailData={eventDetailData} />}
      />
      <PerformActionOn
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Delete recurring event?"}
        handleAgree={handleDelete}
      />
      <FullPagePopup
        open={drawerStateUpdate}
        setOpen={setDrawerStateUpdate}
        componentToPassDown={
          <UpdateCalendarEventsNew
            dataList={getEventListings}
            editValues={eventDetailData}
            created_for={created_for}
            onCloseDrawer={handleCloseDrawerUpdate}
            defaultTimeZone={time_zone}
          />
        }
      />
      <FullPagePopup
        open={openItration}
        setOpen={setopenItration}
        componentToPassDown={
          <UpdateCalendarItrationNew
            dataList={getEventListings}
            editValues={eventDetailData}
            created_for={created_for}
            onCloseDrawer={handlecloseDrawerItration}
            defaultTimeZone={time_zone}
          />
        }
      />
      {/* <CustomDrawer
        isOpenDrawer={drawerState}
        onOpenDrawer={handleOpenDrawer}
        onCloseDrawer={handleCloseDrawer}
        pageTitle="New Event"
        componentToPassDown={
          <AddCalendarEvents
            dataList={getEventListings}
            onCloseDrawer={handleCloseDrawer}
          />
        }
      /> */}
    </div>
  );
}

export default GeneralCalendar;
