import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { IconButton, CircularProgress } from "@mui/material";
import { useEffect } from "react";
import { page_detail_by_id } from "src/DAL/WebsitePages/WebsitePages";
import TinyEditor from "src/components/Ckeditor";
import {
  addVenueApi,
  // successScheduleDetailApi,
  updatevenueApi,
} from "src/DAL/WebsitePages/Venues/venuesApi";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
const Input = styled("input")({
  display: "none",
});
export default function AddOrUpdateVenues() {
  const navigate = useNavigate();
  const classes = useStyles();
  const { state } = useLocation();
  const [formType, setFormType] = useState("ADD");
  const { enqueueSnackbar } = useSnackbar();
  const [moduleData, setModuleData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const params = useParams();
  const [file, setProfileImage] = React.useState();
  const [oldfile, setoldProfileImage] = React.useState();
  const [pageData, setPageData] = useState({});
  const [shortDescription, setShortDescription] = useState("");
  const [reviewTopicCk, setReviewTopicCk] = useState("");
  const module_actual_name = "vanues";
  const [inputs, setInputs] = React.useState({
    title: "",
    status: true,
    number_of_seats: "",
    vip_number_of_seats: "",
    executive_number_of_seats: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    let postData = {};
    postData = {
      title: inputs.title,
      short_description: shortDescription,
      detail_description: reviewTopicCk,
      page_id: params.page_id,
      number_of_seats: inputs.number_of_seats,
      vip_number_of_seats: inputs.vip_number_of_seats,
      executive_number_of_seats: inputs.executive_number_of_seats,
      status: inputs.status,
      created_for: "sale_page",
    };

    const result =
      formType == "ADD"
        ? await addVenueApi(postData)
        : await updatevenueApi(postData, params?.success_id);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      navigate(-1);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const getPageDetail = async () => {
    const result = await page_detail_by_id(params.page_id);
    if (result.code == 200) {
      handlePageDetail(result.sale_page);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  // const getFaqDetailData = async () => {
  //   setIsLoading(true);
  //   const result = await successScheduleDetailApi(params.success_id);
  //   if (result.code == 200) {
  //     setFormType("EDIT");
  //     setInputs(result.success_schedule);
  //     setIsLoading(false);
  //   } else {
  //     enqueueSnackbar(result.message, { variant: "error" });
  //     setIsLoading(false);
  //   }
  // };

  const handlePageDetail = (data) => {
    setPageData(data);
    const get_module_info = data?.module_info?.filter(
      (item) => item.module_actual_name == module_actual_name
    )[0];
    setModuleData(get_module_info?.module_replica_info);
  };

  useEffect(() => {
    if (params && params.success_id) {
      if (state) {
        if (params.page_id) {
          handlePageDetail(state.page_info);
        }

        setInputs(state?.data);
        setShortDescription(state?.data?.short_description);
        setReviewTopicCk(state?.data?.detail_description);
        setFormType("EDIT");
      } else {
        // getFaqDetailData();
        if (params.page_id) {
          getPageDetail();
        }
      }
    } else {
      if (params.page_id) {
        if (state) {
          handlePageDetail(state);
        } else {
          getPageDetail();
        }
      }
    }
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <div className="container">
      <div className="row mobile-margin display-flex">
        <div className="col-12 display-flex mb-4">
          <IconButton
            className="back-screen-button"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon />
          </IconButton>
          <span className="sale-page-title">{pageData.sale_page_title}</span>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <h2>
            {moduleData?.add_page_heading ? (
              <h2>
                {formType === "ADD"
                  ? moduleData.add_page_heading
                  : moduleData.edit_page_heading}
              </h2>
            ) : (
              <h2>{`${formType === "ADD" ? "Add" : "Edit"} Venues`}</h2>
            )}
          </h2>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Title"
              variant="outlined"
              fullWidth
              required
              name="title"
              value={inputs.title}
              onChange={handleChange}
            />
          </div>{" "}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="General Tickets"
              variant="outlined"
              fullWidth
              required
              type="number"
              name="number_of_seats"
              value={inputs.number_of_seats}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="VIP Tickets"
              variant="outlined"
              fullWidth
              required
              type="number"
              name="vip_number_of_seats"
              value={inputs.vip_number_of_seats}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Executive Tickets"
              variant="outlined"
              fullWidth
              required
              type="number"
              name="executive_number_of_seats"
              value={inputs.executive_number_of_seats}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">Status </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="status"
                value={inputs.status}
                label="Status"
                onChange={handleChange}
              >
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Inactive</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-12 mt-5">
            <h4>Short Description </h4>
            <TinyEditor
              setDetailDescription={setShortDescription}
              detailDescriptionCk={shortDescription}
              editorHeight={300}
            />
          </div>
          <div className="col-12 mt-5">
            <h4>Detail Description </h4>
            <TinyEditor
              setDetailDescription={setReviewTopicCk}
              detailDescriptionCk={reviewTopicCk}
              editorHeight={300}
            />
          </div>
          <div className="text-end mt-4">
            <button className="small-contained-button">
              {formType == "ADD" ? "Submit" : "Update"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
