import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { CircularProgress } from "@mui/material";
import Chip from "@mui/material/Chip";
import { s3baseUrl } from "src/config/config";
import {
  filteredRecordingList,
  // submitVaultFilter,
  // vaultCategorySidebarListing,
} from "src/DAL/Programmes/Programmes";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { htmlDecode } from "src/utils/convertHtml";
import PageDescription from "src/components/GeneralComponents/PageDescription";
import RecordNotFound from "src/components/GeneralComponents/RecordNotFound";
import ReactVideoPlayer from "src/components/ReactVideoPlayer";
// import VaultFilterSidebar from "src/components/Vault/VaultFilterSidebar";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

function Vault(props) {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [openFilter, setOpenFilter] = useState(false);
  const [recordingsList, setRecordingsList] = useState([]);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [filterData, setFilterdata] = useState({});
  const [programOptions, setProgramOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [loadMorePath, setLoadMorePath] = useState("");
  const [loadMore, setLoadMore] = useState(
    "api/program_recording/program_recording_list/delegate?page=0&limit=10"
  );
  const getVaultCategorySidebarListing = async () => {
    setIsLoading(true);
    const result = await vaultCategorySidebarListing();
    if (result.code === 200) {
      setProgramOptions(result.program);
      setCategoryOptions(result.vault_category);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      gender: "",
      category: "",
      colors: "",
      priceRange: "",
      rating: "",
    },
    onSubmit: () => {
      setOpenFilter(false);
    },
  });

  const { resetForm, handleSubmit } = formik;

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };
  const getFilteredRecordingList = async () => {
    const result = await filteredRecordingList();
    if (result.code === 200) {
      setRecordingsList(result.recording);
      setIsLoading(false);
      setIsLoadingMore(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
      setIsLoadingMore(false);
    }
  };

  // const onSubmitFilter = async (dataObject) => {
  //   setIsLoading(true);
  //   const result = await submitVaultFilter(dataObject);
  //   if (result.code === 200) {
  //     setRecordingsList(result.recording);
  //     setIsLoading(false);
  //     setPageNumber(pageNumber + 1);
  //     setTotlePages(result.total_pages + 1);
  //     setLoadMorePath(result.load_more);
  //     setIsLoadingMore(false);
  //   } else {
  //     enqueueSnackbar(result.message, { variant: "error" });
  //     setIsLoading(false);
  //     setIsLoadingMore(false);
  //   }
  // };

  // const withNoFilter = async () => {
  //   setIsLoading(true);
  //   const result = await submitVaultFilter(filterData);
  //   if (result.code === 200) {
  //     setRecordingsList(result.recording);
  //     setIsLoading(false);
  //     setPageNumber(pageNumber + 1);
  //     setTotlePages(result.total_pages + 1);
  //     setLoadMorePath(result.load_more);
  //     setIsLoadingMore(false);
  //   } else {
  //     enqueueSnackbar(result.message, { variant: "error" });
  //     setIsLoading(false);
  //     setIsLoadingMore(false);
  //   }
  // };

  const loadMoreData = () => {
    setIsLoadingMore(true);
    setLoadMore(loadMorePath);
  };

  const handleResetFilter = () => {
    // getVaultCategorySidebarListing();
    handleCloseFilter();
    // onSubmitFilter({});
  };

  useEffect(() => {
    // getVaultCategorySidebarListing();
  }, []);

  // useEffect(() => {
  //   withNoFilter();
  // }, []);

  useEffect(() => {
    getFilteredRecordingList();
  }, []);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <div className="container">
      <div className="row mb-5">
        <PageDescription parameter="vault_description" else_title="Vault" />
        {/* <div className="col-12 svg-color">
          <VaultFilterSidebar
            formik={formik}
            isOpenFilter={openFilter}
            onResetFilter={handleResetFilter}
            onOpenFilter={handleOpenFilter}
            onCloseFilter={handleCloseFilter}
            setFilterdata={setFilterdata}
            setIsLoading={setIsLoading}
            programOptions={programOptions}
            categoryOptions={categoryOptions}
            setProgramOptions={setProgramOptions}
            setCategoryOptions={setCategoryOptions}
            onSubmitFilter={onSubmitFilter}
          />
        </div> */}
      </div>
      {recordingsList.length > 0 ? (
        recordingsList.map((value, index) => {
          return (
            <div className="card recordings-card h-100 secondary-background" key={index}>
              <div className="row section-space">
                <div className="col-10">
                  <h3 className="programmes-heading">
                    {htmlDecode(value?.title)}
                  </h3>
                </div>
                <div className="col-lg-2 col-md-2 col-sm-12 text-end">
                  <p className="vault-date text-secondary">
                    {value?.recording_date}
                  </p>
                </div>
              </div>
              <div className="row media-margin">
                <div className="col-12">
                  <ReactVideoPlayer url={value?.video_url} />
                </div>

                {value?.audio_recording && (
                  <div className="col-12 mt-3 text-center">
                    <div>
                      <audio
                        controlsList="nodownload"
                        className="w-100"
                        src={s3baseUrl + value?.audio_recording}
                        controls
                      />
                    </div>
                  </div>
                )}
                <div className="col-12 section-space">
                  <p>{htmlDecode(value?.short_description)}</p>
                </div>
                <div className="col-sm-12 col-md-7 program-btn">
                  <button className="small-contained-button ">
                    {htmlDecode(value?.program_info?.title)}
                  </button>
                </div>
                <div className="col-sm-12 col-md-5 program-category-btn">
                  {value?.vault_category && (
                    <Chip
                      label={htmlDecode(value?.vault_category?.vault_title)}
                      color="primary"
                      className="me-1"
                      variant="outlined"
                    />
                  )}
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <RecordNotFound title="Recordings Not Found" />
      )}
      {/* {totlePages > pageNumber ? (
        <div className="col-12 text-center">
          <button
            className="small-contained-button mt-3"
            onClick={loadMoreData}
            disabled={isLoadingMore}
          >
            {isLoadingMore ? "Loading..." : "Load More"}
          </button>
        </div>
      ) : (
        ""
      )} */}
    </div>
  );
}

export default Vault;
