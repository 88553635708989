import { CircularProgress, Tooltip } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { delete_team_api, team_list_api } from "src/DAL/salesTeam/SalesTeam";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";

import { makeStyles } from "@mui/styles";

import { useSnackbar } from "notistack";
import { payment_plan_payment } from "src/DAL/WebsitePages/WebsitePages";
import { convertCurrencyToSign } from "src/utils/constants";
import ActiveLastBreadcrumb from "src/components/BreadCrums";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const CommissionPLans = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const params = useParams();
  const path = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [teamList, SetTeamList] = useState([]);
  const [salePageNames, setSalePageName] = useState("");

  const getTeamListing = async () => {
    setIsLoading(true);
    const result = await payment_plan_payment(params.id);
    if (result.code == 200) {
      if (Object.keys(result.sale_page).length > 0) {
        let SalePageName = result.sale_page.sale_page_title;
        setSalePageName(SalePageName);
      }
      const team_member = result.commission_info.map((item) => {
        return {
          ...item,
          plan_name: item.plan_title,
          // plan_price:
          //   convertCurrencyToSign(item.plan_currency) +
          //   item.plan_price.toFixed(2),
          payment_access: item.payment_access,
        };
      });

      SetTeamList(team_member);

      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleCommissionDetail = (value) => {
    if (path.pathname.includes("/website-pages")) {
      navigate(
        `/website-pages/set-commission/${params.id}/sales-team-commission/${value?._id}`
      );
    } else {
      navigate(
        `/website-links/set-commission/${params.id}/sales-team-commission/${value?._id}`
      );
    }
  };

  const MENU_OPTIONS = [
    {
      label: "Manage Sales Team Commission",
      icon: "akar-icons:edit",
      handleClick: handleCommissionDetail,
    },
  ];

  const handleNavigate = () => {
    navigate("/sales-team/add-sales-team");
  };

  const TABLE_HEAD = [
    { id: "action", label: "Action", type: "action" },
    { id: "number", label: "#", type: "number" },

    {
      id: "name",
      label: "Plan Title",
      renderData: (row) => {
        return (
          <>
            {/* <Tooltip title={"View Commission Detail"}> */}
            <span
              onClick={() => handleCommissionDetail(row)}
              style={{ cursor: "pointer" }}
            >
              {row.plan_name}
            </span>
            {/* </Tooltip> */}
          </>
        );
      },
    },
    { id: "payment_access", label: "Plan Type", className: "text-capitalize" },
    // { id: "plan_price", label: "Plan Price" },
  ];
  let breadCrumbMenu = [
    {
      title: path.pathname.includes("/website-pages") ? "Templates" : "Links",
      navigation: -1,
      active: false,
    },

    {
      title: salePageNames,
      navigation: ``,
      active: true,
    },
  ];

  useEffect(() => {
    getTeamListing();
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="mb-2">
            {salePageNames && (
              <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
            )}
          </div>
          <div className="col-8">
            <h2>Payment Plans</h2>
          </div>
        </div>

        <div className="row">
          <div className="col-12 mt-1 ">
            <CustomMUITable
              TABLE_HEAD={TABLE_HEAD}
              data={teamList}
              MENU_OPTIONS={MENU_OPTIONS}
              className="card-with-background"
              pagePagination={true}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CommissionPLans;
