import { Dialog } from "@mui/material";
import React from "react";

export default function GeneralModelBox({
  open,
  setOpen,
  componentToPassDown,
  className,
}) {
  return (
    <>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{
          style: {
            boxShadow: "none",
          },
          className: `p-3 general-popup-model ${className || ""}`,
        }}
      >
        <div className="responce-messages">{componentToPassDown}</div>
      </Dialog>
    </>
  );
}
