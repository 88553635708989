import { Avatar, CircularProgress, IconButton, Tooltip } from "@mui/material";
import React, { useState } from "react";
import { s3baseUrl } from "src/config/config";
import {
  decode_whatsapp,
  get_date_with_user_time_zone,
  string_avatar,
} from "src/utils/constants";
import SendIcon from "@mui/icons-material/Send";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import {
  _add_chat,
  _delete_message,
  _get_chat_message,
  _send_message,
  _update_message,
} from "src/DAL/Chat/Chat";
import { useSnackbar } from "notistack";
import { useContentSetting } from "src/Hooks/ContentSettingState";
import { Icon } from "@iconify/react";
import { useEffect } from "react";
import TextareaAutosize from "react-textarea-autosize";
import {
  _list_whatsapp_messages,
  _whatsapp_templates_list,
} from "src/DAL/WhatsApp/WhatsApp";
import MUIAutocomplete from "src/components/GeneralComponents/MUIAutocomplete";
import WhatsAppTemplate from "./WhatsAppTemplate";

const scroll_to_bottom_on_message = (quick) => {
  const chat_box = document.getElementsByClassName("user-chat-box-body")[0];
  if (quick) {
    chat_box.scroll({ top: chat_box.scrollHeight });
  } else {
    chat_box.scroll({ top: chat_box.scrollHeight, behavior: "smooth" });
  }
};

function WhatsAppChatMessages(props) {
  const { is_popup, setChatList, selectedChat } = props;
  const { receiver_info } = selectedChat;
  const [messagesList, setMessagesList] = useState([]);
  const [templatesList, setTemplatesList] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const { userInfo, socket } = useContentSetting();
  const [message, setMessage] = useState("");
  const [image, setImage] = useState("");
  const [file, setFile] = useState(null);
  const [openFormatter, setOpenFormatter] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [inputHeight, setInputHeight] = useState(0);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [isLoadingChat, setIsLoadingChat] = useState(false);

  const handleClearAll = async () => {
    setMessage("");
    setFile(null);
    setImage(null);
    setIsLoading(false);
    setSelectedTemplate(null);
    if (openFormatter) {
      handleToggleFormatter();
    }
  };

  const handleSubmitMessage = async (e) => {
    e.preventDefault();
    let message_text = message;
    let message_type = "text";
    if (receiver_info?.whatsapp_chat_status !== "accepted") {
      message_text = selectedTemplate.name;
      message_type = "template";
    }
    const postData = {
      message: message_text,
      receiver_id: receiver_info?._id,
      chat_id: selectedChat?._id,
      token: localStorage.getItem("token"),
      message_type: message_type,
    };

    socket.emit("whatsapp_chat_message_event", postData);
    handleClearAll();
  };

  const get_chat_list = async () => {
    setIsLoadingChat(true);
    const result = await _list_whatsapp_messages(selectedChat?._id);
    if (result.code === 200) {
      let templates = result.list_templates;
      setTemplatesList(templates);
      setMessagesList(result.data);
      setChatList((old) =>
        old.map((chat) => {
          if (chat._id == selectedChat?._id) {
            chat.sender_info.unread_message_count = 0;
          }
          return chat;
        })
      );
      setIsLoadingChat(false);
      setTimeout(() => {
        scroll_to_bottom_on_message(true);
      }, 100);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingChat(false);
    }
  };

  const handleGetSelectedText = () => {
    const inputField = document.getElementById("inputField");
    if (inputField) {
      const selectedText = inputField.value.substring(
        inputField.selectionStart,
        inputField.selectionEnd
      );
      return selectedText;
    }
  };

  const handleOnBold = () => {
    let seleted_text = handleGetSelectedText();
    if (seleted_text) {
      var newText = inputField.value.replace(
        seleted_text,
        "*" + seleted_text + "*"
      );
      setMessage(newText);
    }
  };

  const handleOnItalic = () => {
    let seleted_text = handleGetSelectedText();
    if (seleted_text) {
      var newText = inputField.value.replace(
        seleted_text,
        "_" + seleted_text + "_"
      );
      setMessage(newText);
    }
  };

  const handleToggleFormatter = () => {
    let heigh = 0;
    let content_height = "70vh";
    let marginTop = 0;
    let paddingTop = 0;
    let elements = document.getElementsByClassName("chat-formatters-box")[0];
    if (!openFormatter) {
      setTimeout(() => {
        setOpenFormatter(!openFormatter);
      }, 100);
      heigh = "35px";
      content_height = `calc(${"70vh"} - ${openFormatter ? "35px" : "0px"})`;
    } else {
      setOpenFormatter(!openFormatter);
    }
    elements.style.height = heigh;
    elements.style.marginTop = marginTop;
    elements.style.paddingTop = paddingTop;
    elements.content.height = content_height;
  };

  // handle new message from socket
  const handle_new_message = async (data) => {
    console.log(data, "handle_new_message");
    let new_message = data.data.response;
    if (selectedChat._id == new_message.whatssapp_chat) {
      setMessagesList((old_messages) => [...old_messages, new_message]);
      let postData = { message_id: new_message.whatssapp_message_id };
      socket.emit("mark_as_read_whatsapp_chat_message_event", postData);
    }

    setChatList((old) =>
      old.map((chat) => {
        if (chat._id == new_message.whatssapp_chat) {
          chat.message_type = new_message.message_type;
          chat.last_message = new_message.message;
          chat.last_message_date_time = new_message.createdAt;
          if (new_message.whatssapp_chat !== selectedChat._id) {
            chat.sender_info.unread_message_count++;
          }
          if (new_message.sender_info._id !== userInfo._id) {
            chat.receiver_info.whatsapp_chat_status = "accepted";
          }
        }

        return chat;
      })
    );
    scroll_to_bottom_on_message();
  };

  const handle_whatsapp_message_status = async (data) => {
    setMessagesList((old_messages) => {
      return old_messages.map((message) => {
        if (message.whatssapp_message_id === data.whatssapp_message_id) {
          return {
            ...message,
            status: data.status,
            failed_reason: data.failed_reason,
          };
        } else {
          return message;
        }
      });
    });
  };

  let input_element = document.getElementById("inputField");
  useEffect(() => {
    if (input_element) {
      let input_height = input_element.style.height;
      setInputHeight(input_height);
    }
  }, [input_element, message]);

  useEffect(() => {
    handleClearAll();
    if (receiver_info) {
      get_chat_list();
    }

    socket.on("whatsapp_chat_message_event_receiver", (data) => {
      handle_new_message(data);
    });
    socket.on("whatsapp_message_status", (data) => {
      handle_whatsapp_message_status(data);
    });

    return () => {
      socket.off("whatsapp_chat_message_event_receiver");
      socket.off("whatsapp_message_status");
    };
  }, [selectedChat]);

  return (
    <>
      <div>
        {!receiver_info ? (
          <div className="d-flex justify-content-center align-items-center no-chat-wrapper">
            <div className="no-chat-div">
              <Icon
                className="no-chat-image"
                icon="material-symbols:chat-bubble-outline"
              />
            </div>
          </div>
        ) : (
          <div>
            <div
              className="user-chat-box-body ps-2 pe-2"
              style={{
                height: `calc(67vh - ${inputHeight})`,
                overflow: "auto",
              }}
            >
              {isLoadingChat ? (
                <div className="d-flex justify-content-center no-chat-wrapper">
                  <CircularProgress
                    className=""
                    style={{ width: 40, height: 40, marginTop: "25%" }}
                    color="primary"
                  />
                </div>
              ) : (
                messagesList.map((message_obj, i) => {
                  let is_self = Boolean(
                    message_obj.sender_info._id == userInfo._id
                  );
                  let components = [];
                  if (message_obj.message_type == "template") {
                    let find = templatesList.find(
                      (template) => template.name == message_obj.message.message
                    );
                    if (find) {
                      components = find.components;
                    }
                  }

                  return (
                    <>
                      {is_self ? (
                        <div
                          className="w-100 d-flex justify-content-end control-dots"
                          key={message_obj._id}
                        >
                          <div
                            className={`chat-outgoing ${
                              message_obj.message_type == "video" &&
                              message_obj.embed_code
                                ? "w-85"
                                : ""
                            }`}
                            style={{
                              backgroundColor:
                                "var(--background-secondary-color)",
                            }}
                          >
                            {message_obj.message_type == "image" &&
                              message_obj.image && (
                                <img
                                  className="mb-2"
                                  src={s3baseUrl + message_obj.image}
                                ></img>
                              )}

                            {message_obj.message_type == "html" ? (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: message_obj.message,
                                }}
                              ></div>
                            ) : message_obj.message_type == "template" ? (
                              <>
                                <WhatsAppTemplate components={components} />
                              </>
                            ) : (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: decode_whatsapp(
                                    message_obj.message.message
                                  ),
                                }}
                              ></div>
                            )}

                            <div className="d-flex justify-content-end">
                              <div className="ticket-icon me-1">
                                {message_obj.status == "sent" ? (
                                  <Tooltip title="Sent">
                                    <Icon
                                      className=""
                                      fontSize={16}
                                      icon="mdi:tick"
                                    />
                                  </Tooltip>
                                ) : message_obj.status == "delivered" ? (
                                  <Tooltip title="Delivered">
                                    <Icon
                                      className=""
                                      fontSize={16}
                                      icon="mdi:tick-all"
                                    />
                                  </Tooltip>
                                ) : message_obj.status == "read" ? (
                                  <Tooltip title="Seen">
                                    <Icon
                                      className="message-seen"
                                      fontSize={16}
                                      icon="mdi:tick-all"
                                    />
                                  </Tooltip>
                                ) : (
                                  <Tooltip
                                    title={`Failed - ${message_obj.failed_reason}`}
                                  >
                                    <div className="mui-icon">
                                      <DoNotDisturbIcon />
                                    </div>
                                  </Tooltip>
                                )}
                              </div>
                              <div className="chat-message-time">
                                <Tooltip
                                  title={get_date_with_user_time_zone(
                                    message_obj.createdAt,
                                    "DD MMM YYYY [at] hh:mm A",

                                    userInfo.time_zone,
                                    userInfo.adminTimeZone
                                  )}
                                  arrow
                                >
                                  <span className="message-date-time">
                                    {get_date_with_user_time_zone(
                                      message_obj.createdAt,
                                      "DD-MM-YYYY hh:mm A",
                                      userInfo.time_zone,
                                      userInfo.adminTimeZone
                                    )}
                                  </span>
                                </Tooltip>
                              </div>
                            </div>
                          </div>
                          <Avatar
                            src={s3baseUrl + userInfo?.image?.thumbnail_1}
                            style={{ height: 35, width: 35, marginTop: 10 }}
                          >
                            {string_avatar(userInfo?.first_name[0])}
                          </Avatar>
                        </div>
                      ) : (
                        <div
                          className="w-100 d-flex justify-content-start"
                          key={message_obj._id}
                        >
                          <Avatar
                            src={
                              s3baseUrl + message_obj.sender_info?.profile_image
                            }
                            style={{ height: 35, width: 35, marginTop: 10 }}
                          >
                            {string_avatar(
                              message_obj.sender_info?.first_name[0]
                            )}
                          </Avatar>
                          <div className="message-name">
                            <span className="message-sender-name">
                              {`${message_obj.sender_info?.first_name}  ${
                                message_obj.sender_info?.last_name
                              } (${
                                message_obj.sender_info.user_type ==
                                "consultant"
                                  ? "Delegate"
                                  : "Member"
                              })`}
                            </span>
                            <div
                              className={`chat-incoming mt-0 ${
                                message_obj.message_type == "video" &&
                                message_obj.embed_code
                                  ? "w-85"
                                  : ""
                              }`}
                            >
                              {message_obj.message_type == "image" &&
                                message_obj.image && (
                                  <img
                                    className="mb-2"
                                    src={s3baseUrl + message_obj.image}
                                  ></img>
                                )}
                              {message_obj.message_type == "video" &&
                                message_obj.embed_code && (
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: message_obj.embed_code,
                                    }}
                                  ></div>
                                )}

                              {message_obj.message_type == "html" ? (
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: message_obj.message,
                                  }}
                                ></div>
                              ) : message_obj.message_type == "template" ? (
                                <>
                                  <WhatsAppTemplate components={components} />
                                </>
                              ) : (
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: decode_whatsapp(
                                      message_obj.message.message
                                    ),
                                  }}
                                ></div>
                              )}

                              <Tooltip
                                title={get_date_with_user_time_zone(
                                  message_obj.createdAt,
                                  "DD MMM YYYY [at] hh:mm A",

                                  userInfo.time_zone,
                                  userInfo.adminTimeZone
                                )}
                                arrow
                              >
                                <span className="message-time">
                                  {get_date_with_user_time_zone(
                                    message_obj.createdAt,
                                    "DD-MM-YYYY hh:mm A",
                                    userInfo.time_zone,
                                    userInfo.adminTimeZone
                                  )}
                                </span>
                              </Tooltip>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  );
                })
              )}
            </div>
          </div>
        )}
      </div>
      <div className="chat-right-bottom">
        {receiver_info && (
          <div
            className="chat-send-message-wrapper"
            style={{
              backgroundColor: "var(--background-primary-color)",
              paddingBottom: "",
            }}
          >
            <div>
              <form
                onSubmit={handleSubmitMessage}
                className="d-flex align-items-end w-100"
              >
                {receiver_info?.whatsapp_chat_status !== "accepted" ? (
                  <>
                    <MUIAutocomplete
                      inputLabel="Select Template"
                      selectedOption={selectedTemplate}
                      setSelectedOption={(v) => {
                        setSelectedTemplate(v);
                      }}
                      optionsList={templatesList}
                      autoComplete="new-password"
                      name="name"
                      size="small"
                    />
                  </>
                ) : (
                  <div className="w-100">
                    <div
                      className={`chat-formatters-box d-flex ${
                        openFormatter ? "chat-formatters-border" : ""
                      }`}
                      style={{
                        backgroundColor: "var(--background-primary-color)",
                      }}
                    >
                      {openFormatter && (
                        <>
                          <div
                            className="formatter-icon"
                            onClick={handleOnBold}
                          >
                            <Icon
                              fontSize="15"
                              style={{ color: "var(--portal-theme-primary)" }}
                              icon="octicon:bold-24"
                            />
                          </div>
                          <div
                            className="formatter-icon"
                            onClick={handleOnItalic}
                          >
                            <Icon
                              fontSize="15"
                              style={{ color: "var(--portal-theme-primary)" }}
                              icon="fe:italic"
                            />
                          </div>
                        </>
                      )}
                    </div>

                    <div className="position-relative">
                      <Icon
                        fontSize={20}
                        className="show-hide-chat-formatter"
                        icon={`${
                          openFormatter
                            ? "mingcute:down-line"
                            : "fluent:text-edit-style-16-regular"
                        }`}
                        onClick={handleToggleFormatter}
                      />
                      <TextareaAutosize
                        id="inputField"
                        className={`chat-send-input ${
                          file || image || openFormatter
                            ? "border-with-image"
                            : ""
                        }`}
                        style={{
                          height: is_popup ? "50px" : "100px",
                          backgroundColor: is_popup
                            ? "var(--background-primary-color) "
                            : "var(--background-secondary-color)",
                        }}
                        type="text"
                        value={message}
                        maxRows={6}
                        onChange={(e) => setMessage(e.target.value)}
                        placeholder="Write your message..."
                        required={!(image || file)}
                        autoFocus
                      />
                    </div>
                  </div>
                )}

                {(message || selectedTemplate) && !isLoading && (
                  <IconButton
                    type="submit"
                    className={`ms-2 ${selectedTemplate ? "" : "mb-2"}`}
                  >
                    <SendIcon />
                  </IconButton>
                )}
                {isLoading && (
                  <IconButton className="ms-2 mb-2">
                    <CircularProgress
                      style={{
                        width: 23,
                        height: 23,
                      }}
                    />
                  </IconButton>
                )}
              </form>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default WhatsAppChatMessages;
