import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomDrawer from "src/components/GeneralComponents/CustomDrawer";
import roundFilterList from "@iconify/icons-ic/round-filter-list";
import { useSnackbar } from "notistack";
import { Chip, CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import PageDescription from "src/components/GeneralComponents/PageDescription";
import TicketList from "./TicketList";
import SupportTicketFilterDrawer from "./SupportTicketFilterDrawer";
import AddOrUpdateTicketSection from "./AddOrUpdateTicketSection";
import { tickestsListAPI } from "src/DAL/ContactSupport api/contactSupport";
import { get_root_value } from "src/utils/domUtils";
import {
  capitalizeFirst,
  get_date_with_user_time_zone,
} from "src/utils/constants";
import { useContentSetting } from "src/Hooks/ContentSettingState";
import moment from "moment";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

function ContactSupport() {
  const navigate = useNavigate();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { userInfo, adminTimeZone } = useContentSetting();
  const [drawerState, setDrawerState] = useState(false);
  const [editDrawerState, setEditDrawerState] = useState(false);
  const [tickestData, setTickestData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedValue, setSelectedValue] = useState("all");
  const [selectedValueUpdate, setSelectedValueUpdate] = useState("all");
  moment.tz.setDefault(userInfo.time_zone);

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const getTickestsListing = async (filterBy) => {
    setIsLoading(true);
    const formData = { filter_by: filterBy };

    const result = await tickestsListAPI(formData);
    if (result.code === 200) {
      let support_tickets = result.support_ticket.map((ticket) => {
        const created_date = get_date_with_user_time_zone(
          ticket.support_ticket_date,
          "YYYY-MM-DD HH:mm",
          userInfo.time_zone,
          adminTimeZone
        );

        ticket.department_title = ticket.department?.title;
        ticket.created_at = capitalizeFirst(
          moment(created_date, "YYYY-MM-DD HH:mm").fromNow()
        );
        return ticket;
      });
      setTickestData(support_tickets);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handle_ticket_click = (value) => {
    navigate(`/contact-support/${value._id}`);
  };

  const handleOpenDrawer = () => {
    setDrawerState(true);
  };

  const handleCloseDrawer = () => {
    setDrawerState(false);
  };

  const handleOpenEditDrawer = () => {
    setEditDrawerState(true);
  };

  const handleCloseEditDrawer = () => {
    setEditDrawerState(false);
  };

  const handleDeleteProgramChips = () => {
    localStorage.setItem("contact_supporrt_data", JSON.stringify("all"));
    setSelectedValue("all");
    setSelectedValueUpdate("all");
    getTickestsListing("all");
  };

  const handleClearFilterall = () => {
    localStorage.setItem("contact_supporrt_data", JSON.stringify("all"));
    setSelectedValue("all");
    setSelectedValueUpdate("all");
    getTickestsListing("all");
  };

  useEffect(() => {
    let find_filter = localStorage.getItem("contact_supporrt_data");
    let filter_data;
    if (find_filter) {
      filter_data = JSON.parse(find_filter);
      setSelectedValue(filter_data);
      setSelectedValueUpdate(filter_data);
      getTickestsListing(filter_data);
    } else {
      getTickestsListing("all");
    }
  }, []);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      <div className="row mobile-margin display-flex">
        <div className="col-sm-12 col-md-6">
          <PageDescription
            parameter="support_ticket_page_title"
            else_title="Support Tickets"
          />
        </div>
        <div className="col-sm-12 col-md-6 text-end">
          <button
            className="small-contained-button me-2 mt-1 mb-4"
            onClick={handleOpenDrawer}
          >
            New Ticket
          </button>
          <button
            className="small-contained-button me-2 mt-1 mb-4"
            onClick={handleOpenEditDrawer}
          >
            Filters &nbsp;&nbsp; <Icon icon={roundFilterList} />
          </button>
        </div>
        <div className="mb-2">
          {selectedValueUpdate && (
            <div className="col-lg-12 col-sm-12">
              <b className="me-3 pt-1 ms-2">Filtered By:</b>
              <Chip
                label={selectedValueUpdate}
                onDelete={() => handleDeleteProgramChips(selectedValueUpdate)}
                className="mb-2 me-1 mt-2"
                sx={{
                  color: "white",
                  textTransform: "capitalize",
                }}
              />
              <span
                className="anchor-style ms-2 pt-1"
                onClick={() => handleClearFilterall()}
                style={{ color: get_root_value("--portal-theme-primary") }}
              >
                Clear All
              </span>
            </div>
          )}
        </div>
        <TicketList
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          data={tickestData}
          handle_ticket_click={handle_ticket_click}
          getTickestsListing={getTickestsListing}
        />
      </div>
      <CustomDrawer
        isOpenDrawer={editDrawerState}
        onOpenDrawer={handleOpenEditDrawer}
        onCloseDrawer={handleCloseEditDrawer}
        pageTitle="Filters"
        componentToPassDown={
          <SupportTicketFilterDrawer
            value={selectedValue}
            handleChange={handleChange}
            dataList={getTickestsListing}
            setSelectedValue={setSelectedValue}
            setSelectedValueUpdate={setSelectedValueUpdate}
            onCloseDrawer={handleCloseEditDrawer}
          />
        }
      />
      <CustomDrawer
        isOpenDrawer={drawerState}
        onOpenDrawer={handleOpenDrawer}
        onCloseDrawer={handleCloseDrawer}
        pageTitle="New Ticket"
        componentToPassDown={
          <AddOrUpdateTicketSection
            formType="ADD"
            dataList={getTickestsListing}
            onCloseDrawer={handleCloseDrawer}
          />
        }
      />
    </div>
  );
}

export default ContactSupport;
