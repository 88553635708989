import moment from "moment";
import React from "react";
import MeetingActionIcons from "./MeetingActionIcons";
import { htmlDecode } from "src/utils/convertHtml";
import { show_proper_words } from "src/utils/constants";

const MeetingDetail = ({ meetingDetail }) => {
  return (
    <div className="p-2">
      <div className="meeting-detail-flex">
        <h4 className="me-2">Meeting Title:</h4>
        <p>{meetingDetail.title}</p>
      </div>
      <div className="mt-2 meeting-detail-flex">
        <h4 className="me-2">Meeting Time:</h4>
        <p>
          {moment(meetingDetail.meeting_start_date).format("MMM DD, YYYY")}
          &nbsp;
          {moment(meetingDetail.meeting_start_time, "HH:mm:ss").format(
            "hh:mm A"
          )}
          &nbsp;
          {`(${meetingDetail.meeting_time_zone})`}
        </p>
      </div>
      <div className="mt-2 meeting-detail-flex">
        <h4 className="me-2">Meeting Status:</h4>
        <p>{show_proper_words(meetingDetail.meeting_status)}</p>
      </div>
      <div className="mt-2 meeting-detail-flex meeting-card align-items-center">
        <h4 className="me-2">Meeting Actions:</h4>
        <MeetingActionIcons item={meetingDetail} />
      </div>
      {meetingDetail?.description && (
        <div className="mt-2 meeting-detail-flex">
          <h4 className="me-2">Description:</h4>
          <p>{htmlDecode(meetingDetail?.description)}</p>
        </div>
      )}
      {meetingDetail.meeting_agenda && (
        <>
          <h4 className="me-2 mt-2 portal-color">Meeting Agenda:</h4>
          <div
            dangerouslySetInnerHTML={{
              __html: meetingDetail.meeting_agenda,
            }}
          ></div>
        </>
      )}
    </div>
  );
};

export default MeetingDetail;
