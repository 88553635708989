import { useState } from "react";
import { useEffect } from "react";
import { useSnackbar } from "notistack";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { programmesListing } from "../../DAL/Programmes/Programmes";
import { s3baseUrl } from "src/config/config";
import CustomMUICard from "src/components/GeneralComponents/CustomMUICard";
import { useNavigate } from "react-router-dom";
import RecordNotFound from "src/components/RecordNotFound";
import LockIcon from "@mui/icons-material/Lock";

// ----------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function Programmes() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [programmesData, setProgrammesData] = useState([]);
  const navigate = useNavigate();

  const handleClick = (object) => {
    delete object.card_button;
    console.log(object, "objectobject");
    if (object.locked_status === false) {
      navigate(`/delegate-training/${object.program_slug}`, {
        state: object,
      });
    }
  };

  const getProgrammesList = async () => {
    const result = await programmesListing();
    if (result.code === 200) {
      let CARD_OPTIONS = [];
      result.program.map((program, index) => {
        console.log(program);
        // mapping data to CustomMUICard component
        CARD_OPTIONS.push({
          ...program,
          card_title: program.title,
          card_short_description: program.short_description,
          card_image:
            index == 0
              ? s3baseUrl + program.program_images.thumbnail_1
              : s3baseUrl + program.program_images.thumbnail_2,
          card_classes:
            index == 0
              ? "col-12 wider-card"
              : "col-lg-4 col-md-6 col-sm-12 mt-4", // first index should be in 12 cols
          card_gray_description: `${program.no_of_lesson} Modules | ${program.total_lesson_duration}`,
          card_button: program.locked_status === true && {
            button_title: (
              <>
                {/* {program.locked_program_info.image && (
                  <img
                    src={s3baseUrl + program.locked_program_info.image}
                    width={20}
                    className="me-1"
                  />
                )} */}
                {program?.program_lock_icon ? (
                  <img
                    src={s3baseUrl + program?.program_lock_icon}
                    className=" program_lock_img"
                  />
                ) : (
                  program?.locked_program_info?.image && (
                    <img
                      src={s3baseUrl + program?.locked_program_info.image}
                      className=" program_lock_img"
                    />
                  )
                )}

                {program.program_lock_statement
                  ? program.program_lock_statement
                  : "locked"}
              </>
            ),
            button_icon: "MUILockIcon",
            handleClick: handleClick,
          },
        });
      });
      setProgrammesData(CARD_OPTIONS);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getProgrammesList();
  }, []);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <div className="container mt-3">
      {/* <PageDescription
        parameter="programs_description"
        else_title="Programmes"
      /> */}
      {programmesData.length > 0 ? (
        <CustomMUICard
          data={programmesData}
          handleClick={handleClick}
          handleButtonClick={handleClick}
        />
      ) : (
        <RecordNotFound title="Programmes" />
      )}
    </div>
  );
}
