import React, { useEffect, useState } from "react";
import { CircularProgress, IconButton } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { s3baseUrl } from "src/config/config";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { list_member_replies_against_member } from "src/DAL/GeneralQuestions/GeneralQuestions";
import moment from "moment";
import { replace_created_for } from "src/utils/constants";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function QuestionsAnswersForUser() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const location = useLocation();
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [isLoading, setIsLoading] = useState(true);
  const [answersData, setAnswersData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const member_id = new URLSearchParams(location.search).get("member_id");

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setPageCount(1);
  };

  const getAnswersListing = async () => {
    setIsLoading(true);
    const result = await list_member_replies_against_member(
      page,
      rowsPerPage,
      member_id
    );
    if (result.code == 200) {
      const answers = result?.questionnaire_list?.map((answer, index) => {
        return {
          ...answer,
          title: answer?.created_for_id?.title,
          created_for_title: replace_created_for(answer.created_for),
          answered_date: moment(answer.reply_date).format("DD-MM-YYYY"),
        };
      });

      setAnswersData(answers);
      setTotalCount(result.toal_count);
      setTotalPages(result.total_pages);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleNavigate = (value) => {
    let created_for_id = "";
    if (value.created_for_id?._id) {
      created_for_id = value.created_for_id._id;
    }
    navigate(
      `/members/questions-answers/answers-detail?member_id=${member_id}&created_for_id=${created_for_id}&created_for=${value?.created_for}`
    );
  };

  const MENU_OPTIONS = [
    {
      label: "Answers Detail",
      icon: "akar-icons:edit",
      handleClick: handleNavigate,
    },
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
    localStorage.setItem("member_page_number", newPage);
  };

  useEffect(() => {
    getAnswersListing();
  }, [rowsPerPage, page]);

  const TABLE_HEAD = [
    { id: "number", label: "#", alignRight: false, type: "number" },
    {
      id: "created_for_title",
      label: "Questions Created For",
      alignRight: false,
      className: "text-capitalize cursor-pointer",
      handleClick: handleNavigate,
    },
    { id: "title", label: "Module Title", alignRight: false },
    {
      id: "answered_date",
      label: "Answered Date",
      calignRight: false,
    },
    { id: "action", label: "Action", alignRight: false, type: "action" },
  ];

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="container">
        <div className="row mb-3">
          <div className="col-12 d-flex">
            <span>
              <IconButton
                className="back-screen-button"
                onClick={() => navigate(-1)}
              >
                <ArrowBackIcon />
              </IconButton>
            </span>
            <h2 className="ms-1">Questions Answers List</h2>
          </div>
        </div>

        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={answersData}
          MENU_OPTIONS={MENU_OPTIONS}
          className="card-with-background"
          custom_pagination={{
            total_count: totalCount,
            rows_per_page: rowsPerPage,
            page: page,
            handleChangePage: handleChangePage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          pageCount={pageCount}
          totalPages={totalPages}
          handleChangePages={handleChangePages}
          pagePagination={true}
        />
      </div>
    </>
  );
}
