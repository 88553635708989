import React from "react";
import RecordNotFound from "../RecordNotFound";
import { htmlDecode } from "src/utils/convertHtml";

function LessonQuestionAssessment({ sourceSessions, name, title, onclick }) {
  return (
    <div className="mb-3">
      <h4 className="p-3 pb-0">{title ? title : "Goal Statement"}</h4>
      <hr />
      <div className="p-3 pb-0 pt-0">
        {sourceSessions?.length < 1 ? (
          <RecordNotFound title="Questions" />
        ) : (
          sourceSessions?.map((value, i) => {
            return (
              <div className="d-flex mb-2">
                <b>{i + 1}.</b>
                <div className="text-start comment-icon w-100 ps-2 source-session">
                  <div className="card-title" onClick={() => onclick(value)}>
                    <h3 className="mb-0">{value.title}</h3>
                    {value.description ? (
                      <p>{htmlDecode(value?.description)}</p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
}

export default LessonQuestionAssessment;
