import moment from "moment";
import React from "react";
import CustomPopover from "src/components/menuIcons/CustomPopover";
import RecordNotFound from "src/components/RecordNotFound";
import { Avatar, CircularProgress, Tooltip } from "@mui/material";
import GroupsIcon from "@mui/icons-material/Groups";
import DateRangeOutlinedIcon from "@mui/icons-material/DateRangeOutlined";
import ScheduleIcon from "@mui/icons-material/Schedule";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { show_proper_words } from "src/utils/constants";
import { client_url } from "src/config/config";
import MeetingActionIcons from "./MeetingActionIcons";
import { useNavigate } from "react-router-dom";

export default function MeetingItems({ data }) {
  const navigate = useNavigate();

  const {
    meetingsData,
    MENU_OPTIONS,
    totalPages,
    pageNumber,
    lastBookElementRef,
    loadMoreData,
    isLoadingMore,
    handleOpenDetail,
    tabType,
  } = data;

  const handleStart = (value) => {
    navigate(`/meetings/${value._id}/live-meeting`);
  };

  return (
    <>
      {meetingsData.length > 0 ? (
        <>
          {meetingsData.map((item, index) => {
            return (
              <div className="card p-3 meeting-card" key={index}>
                <div className="d-flex justify-content-between">
                  <div className="d-flex">
                    <Tooltip title={show_proper_words(item.meeting_status)}>
                      <Avatar sx={{ bgcolor: "#ffda0014" }}>
                        {item.meeting_status === "scheduled" ? (
                          <ScheduleIcon
                            style={{ color: "#f6bd4b", fill: "#f6bd4b" }}
                          />
                        ) : item.meeting_status === "expired" ? (
                          <HourglassEmptyIcon
                            style={{ color: "#f6bd4b", fill: "#f6bd4b" }}
                          />
                        ) : item.meeting_status === "attended" ? (
                          <CheckCircleIcon
                            style={{ color: "#f6bd4b", fill: "#f6bd4b" }}
                          />
                        ) : (
                          <GroupsIcon
                            style={{ color: "#f6bd4b", fill: "#f6bd4b" }}
                          />
                        )}
                      </Avatar>
                    </Tooltip>
                    <div className="ms-3">
                      <h5
                        style={{
                          marginBottom: "5px",
                          cursor: "pointer",
                        }}
                        onClick={() => handleOpenDetail(item)}
                      >
                        {item.title}
                      </h5>
                      <div className="mb-2 d-flex justify-content-between">
                        <MeetingActionIcons item={item} />
                      </div>
                      <p
                        style={{
                          fontSize: "14px",
                          marginBottom: "5px",
                        }}
                      >
                        Status: &nbsp;
                        <span style={{ color: "#f6bd4b" }}>
                          {show_proper_words(item.meeting_status)}
                        </span>
                      </p>
                    </div>
                  </div>

                  <div className="mt-4 mt-md-0 ms-auto">
                    <div className="d-flex align-items-center mb-4">
                      <div className="me-3">
                        <div className="meeting-detail-flex">
                          <h4 className="me-2 text-nowrap">Created At:</h4>
                          <p className="text-nowrap">
                            {moment(item.createdAt).format(
                              "DD-MM-YYYY hh:mm A"
                            )}
                          </p>
                        </div>
                        <div className="meeting-detail-flex">
                          <h4 className="me-2 text-nowrap">Meeting Time:</h4>
                          <p className="text-nowrap">
                            {moment(item.meeting_start_date).format(
                              "MMM DD, YYYY"
                            )}
                            &nbsp;
                            {moment(item.meeting_start_time, "HH:mm:ss").format(
                              "hh:mm A"
                            )}
                            &nbsp;
                            {`(${item.meeting_time_zone})`}
                          </p>
                        </div>
                      </div>
                      <div className="link-menu">
                        <CustomPopover menu={MENU_OPTIONS} data={item} />
                      </div>
                    </div>
                  </div>
                </div>
                {tabType === "upcoming" && (
                  <div className="d-flex justify-content-end">
                    <button
                      className="small-contained-button"
                      style={{
                        color: "#fff",
                        padding: "6px 10px",
                        borderRadius: "5px",
                        cursor: "pointer",
                        fontSize: "12px",
                      }}
                      onClick={() => handleStart(item)}
                    >
                      Start Meeting
                    </button>
                  </div>
                )}
              </div>
            );
          })}
        </>
      ) : (
        <div className="text-center mt-5">
          <RecordNotFound title={"Meetings"} />
        </div>
      )}
      {totalPages >= pageNumber ? (
        <div className="text-center mt-3">
          <span
            ref={lastBookElementRef}
            className="mt-3"
            onClick={loadMoreData}
            id="load-more-feed"
          >
            {isLoadingMore ? (
              <CircularProgress color="primary" size="1.5rem" />
            ) : (
              "Load More"
            )}
          </span>
        </div>
      ) : (
        ""
      )}
    </>
  );
}
