import { invokeApi } from "../../bl_libs/invokeApi";

export const create_meeting_api = async (data) => {
  const requestObj = {
    path: `api/meeting/create_meeting`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const get_meeting_list_api = async (path, data) => {
  const requestObj = {
    path: path,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const delete_meeting_api = async (meeting_id) => {
  const requestObj = {
    path: `api/meeting/delete_meeting/${meeting_id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const get_meeting_by_id_api = async (meeting_id) => {
  const requestObj = {
    path: `api/meeting/get_meeting/${meeting_id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const update_meeting_api = async (meeting_id, data) => {
  const requestObj = {
    path: `api/meeting/update_meeting/${meeting_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const update_meeting_content_settings = async (meeting_id, data) => {
  const requestObj = {
    path: `api/meeting/update_meeting_content_settings/${meeting_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
