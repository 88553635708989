import React, { useState, useEffect } from "react";
import {
  TextField,
  InputLabel,
  Select,
  FormControl,
  MenuItem,
  CircularProgress,
  Avatar,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useSnackbar } from "notistack";
import moment from "moment";
import { get_root_value } from "src/utils/domUtils";
import { makeStyles } from "@mui/styles";
import {
  AddSupportTicketAPI,
  DeleteSupportTicketImageOnS3,
  departmentListAPI,
  NewdepartmentListAPI,
  UpdateSupportTicketAPI,
  UploadSupportTicketImageOnS3,
  _add_internal_note,
  _delete_internal_id,
  _internal_note,
  _send_reminder,
  _update_internal_note,
} from "src/DAL/SupportTicket/SupportTicket";
import { s3baseUrl } from "src/config/config";
import {
  csvImage,
  docImage,
  pdfImage,
  wordImage,
  audioImage,
  xlsxImage,
  otherImage,
} from "src/assets";
import TinyEditor from "src/components/Ckeditor";
import CustomPopover from "src/components/CustomPopover";
import CustomConfirmation from "src/components/menuIcons/CustomConfirmation";
import RecordNotFound from "src/components/RecordNotFound";
import { get_date_with_user_time_zone } from "src/utils/constants";
import { useContentSetting } from "src/Hooks/ContentSettingState";
const useStyles = makeStyles(() => ({
  paper: {
    background: get_root_value("--popup-background-color"),
    color: get_root_value("--input-text-color"),
  },
}));

export default function InternalNote({
  onCloseDrawer,
  dataList,
  editData,
  ticketId,
  formType,
}) {
  const classes = useStyles();
  const { userInfo, adminTimeZone } = useContentSetting();
  const { enqueueSnackbar } = useSnackbar();
  const [Message, setMessage] = useState("");
  const settings = useContentSetting();
  const { socket } = settings;
  const [isLoading, setIsLoading] = useState(false);
  const [editValue, setEditValue] = useState("");
  const [isLoadingCard, setisLoadingCard] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [FormType, setFormType] = useState("add");

  const [InternalNoteData, setInternalNoteData] = useState();
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const data = {
      internal_note: Message,
    };
    if (FormType == "update") {
      (data.internal_note_id = editValue._id), (data.support_id = ticketId);
    } else if (FormType == "add") {
      data.support_ticket = ticketId;
    }
    console.log(data);
    const result =
      FormType == "update"
        ? await _update_internal_note(data)
        : await _add_internal_note(data);
    if (result.code === 200) {
      if (FormType == "add") {
        socket.emit("send_add_support_ticket", result);
      }
      setFormType("add");
      enqueueSnackbar(result.message, { variant: "success" });

      getInternalNote();
      setMessage("");
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const getInternalNote = async () => {
    setisLoadingCard(true);
    const result = await _internal_note(ticketId);
    console.log(result, "result from internal api");
    if (result.code === 200) {
      setInternalNoteData(result?.support_ticket?.internal_note);
      setisLoadingCard(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setisLoadingCard(false);
    }
  };
  const handleEdit = (value) => {
    setEditValue(value);
    setMessage(value.internal_note);
    setFormType("update");
  };
  const handleAgreeDelete = (value) => {
    setEditValue(value._id);
    setOpenDelete(true);
  };
  const handleDelete = async () => {
    setisLoadingCard(true);
    setOpenDelete(false);
    const result = await _delete_internal_id(ticketId, editValue);
    if (result.code === 200) {
      getInternalNote();
      enqueueSnackbar(result.message, { variant: "success" });
      setisLoadingCard(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setisLoadingCard(false);
    }
  };
  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },

    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];
  useEffect(() => {
    getInternalNote();
  }, []);

  return (
    <div className="container new-memories">
      <div className="parent-container">
        {isLoadingCard ? (
          <div className="centered">
            <CircularProgress className={classes.loading} color="primary" />
          </div>
        ) : (
          <>
            {InternalNoteData?.length > 0 ? (
              <>
                {InternalNoteData?.map((item, index) => {
                  let time = moment
                    .utc(item.note_date_time)
                    .format("YYYY-MM-DD hh:mm");

                  return (
                    <>
                      <div className="mini-cards d-flex">
                        <div className="w-100 ms-2 me-2">
                          <div className="diary-font-weight d-flex">
                            <div className="d-flex">
                              <Avatar
                                alt={item.action_user_info.action_name}
                                src={
                                  s3baseUrl +
                                  item.action_user_info.profile_image
                                }
                                sx={{ marginRight: 1, marginLeft: 1 }}
                              />
                              <h4
                                className="headingMain"
                                style={{ marginTop: "10px" }}
                              >
                                {item.action_user_info.action_name}
                              </h4>
                            </div>
                            <div className="d-flex menu-container">
                              <span
                                className="diary-first-heading pe-2"
                                style={{ paddingTop: 10, fontSize: "12px" }}
                              >
                                {get_date_with_user_time_zone(
                                  time,
                                  "YYYY-MM-DD hh:mm A",
                                  userInfo.time_zone,
                                  adminTimeZone
                                )}
                              </span>
                              <CustomPopover menu={MENU_OPTIONS} data={item} />
                            </div>
                          </div>
                          <div className="d-flex justify-content-between ml-3 ms-3  diary-first-heading">
                            <div>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: item.internal_note,
                                }}
                                className="diary-para mt-2"
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </>
            ) : (
              <RecordNotFound title=" Internal Notes " />
            )}
          </>
        )}
      </div>

      <form onSubmit={formType === "ADD" ? handleSubmit : handleUpdate}>
        <div className="col-12 mt-4">
          <h4>Internal Note *</h4>
          <TinyEditor
            setDetailDescription={setMessage}
            detailDescriptionCk={Message}
            height={300}
          />
        </div>

        <div className="mt-3">
          <span className="float-end">
            <button className="submit-button" disabled={isLoading}>
              {isLoading
                ? FormType === "update"
                  ? "Saving..."
                  : "Saving..."
                : FormType === "ADD"
                ? "Update"
                : "Save"}
            </button>
          </span>
        </div>
      </form>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleDelete}
      />
    </div>
  );
}
