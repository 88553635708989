import React, { useEffect } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Container,
  Grid,
  TextareaAutosize,
  IconButton,
  CircularProgress,
  Tooltip,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import roundFilterList from "@iconify/icons-ic/round-filter-list";
import { Icon } from "@iconify/react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import Slider from "@mui/material/Slider";
import { useState } from "react";
import { get_past_activities_data } from "src/DAL/Tracking/Tracking";
import { useNavigate } from "react-router-dom";
import RecordNotFound from "../RecordNotFound";
import moment from "moment";
import { useSnackbar } from "notistack";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
  fonts: {
    fontSize: "13px",
    fontWeight: "500",
  },
  root: {
    color: "#f6bd4b",
    height: 1,
    // color for the part that is not covered
  },
  rail: {
    color: "#888", // color for the part that is covered
    height: 1,
  },
}));
const marks = [
  {
    value: 0,
    label: <span style={{ color: "gray" }}>0</span>,
  },

  {
    value: 10,
    label: "10",
  },
];
const filterDate = {
  from_date: null,
  to_date: null,
};
const PerformanceStreakPastActivities = () => {
  const [SettingObject, setSettingObject] = React.useState();
  const [isLoading, setIsLoading] = React.useState(true);
  const navigate = useNavigate();
  const [Activities, setActivitiesArray] = React.useState([]);
  const [totlePages, setTotlePages] = useState(0);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [loadMorePath, setLoadMorePath] = useState("");
  const [cout, setCount] = useState(0);
  const [StreakCount, setStreakCount] = useState("");
  const [settings, setSettings] = useState();
  const [pageNumber, setPageNumber] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const [loadMore, setLoadMore] = useState(
    `api/dynamite_streak/get_dynamite_streak_list?page=0&limit=10`
  );
  const [filterDates, setFilterDates] = useState(filterDate);
  const classes = useStyles();
  const handleChangeOthers = (event, name) => {
    setFilterDates((values) => ({ ...values, [name]: event.$d }));
  };
  const getPerformanceData = async (data, type) => {
    let filterData = {};
    filterData = { ...data };
    let postData = {
      date_from: filterData.from_date
        ? moment(filterData.from_date).format("DD-MM-YYYY")
        : "",
      date_to: filterData.to_date
        ? moment(filterData.to_date).format("DD-MM-YYYY")
        : "",
    };
    let path = "";
    if (type == "from_filter") {
      path = `api/dynamite_streak/get_dynamite_streak_list?page=0&limit=10ssss`;
    } else {
      path = loadMore;
    }

    const result = await get_past_activities_data(path, postData);
    if (result.code == 200) {
      setStreakCount(result.streak_count);
      setSettings(result.streak_performance_setting);
      let newArray = [];
      if (type == "from_filter") {
        newArray = result.past_activities;
        setPageNumber(1);
      } else {
        newArray = Activities?.concat(result.past_activities);
        setPageNumber((pageNumber) => pageNumber + 1);
      }
      setActivitiesArray(newArray);
      setTotlePages(result.total_pages);
      setLoadMorePath(result.load_more_url);
      setIsLoadingMore(false);
      setIsLoading(false);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const loadMoreData = () => {
    setCount((count) => count + 1);
    setIsLoadingMore(true);
    setLoadMore(loadMorePath);
  };
  const filterDataButton = () => {
    getPerformanceData(filterDates, "from_filter");
  };
  const filterDataButtonReset = () => {
    setFilterDates(filterDate);
    getPerformanceData(filterDate, "from_filter");
  };
  useEffect(() => {
    getPerformanceData(filterDates, "data");
  }, [loadMore, cout]);
  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <div className="container">
      <div className="row mt-3">
        <div className="col-lg-5 d-flex " style={{ alignItems: "center" }}>
          <span className="me-2">
            <IconButton
              className="back-screen-button mb-4"
              onClick={() => navigate(`/performance-streak`)}
            >
              <ArrowBackIcon />
            </IconButton>
          </span>
          <h2 style={{ marginBottom: "0" }}>
            {settings.past_activities_performance_heading ??
              "performance streak logs"}
          </h2>
        </div>
        <div className="col-lg-3 pe-0">
          <div className="">
            <LocalizationProvider dateAdapter={AdapterDayjs} size="small">
              <DatePicker
                label="From Date"
                name="to_date"
                format="DD-MM-YYYY"
                inputFormat="DD-MM-YYYY"
                value={filterDates.from_date}
                onChange={(e) => handleChangeOthers(e, "from_date")}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    {...params}
                    className="mt-3 inputs-fields"
                    style={{ backgroundColor: "transparent" }}
                    required
                  />
                )}
              />
            </LocalizationProvider>
          </div>{" "}
        </div>{" "}
        <div className="col-lg-4">
          <div className="d-flex">
            <LocalizationProvider dateAdapter={AdapterDayjs} size="small">
              <DatePicker
                label="To Date"
                name="to_date"
                format="DD-MM-YYYY"
                inputFormat="DD-MM-YYYY"
                value={filterDates.to_date}
                onChange={(e) => handleChangeOthers(e, "to_date")}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    {...params}
                    className="mt-3 inputs-fields"
                    required
                    style={{ backgroundColor: "transparent" }}
                  />
                )}
              />
            </LocalizationProvider>
            <Tooltip title="filter">
              <button
                className="small-contained-button-filter ms-2 mt-3 "
                onClick={filterDataButton}
              >
                <Icon icon={roundFilterList} />
              </button>
            </Tooltip>{" "}
            <Tooltip title="Reset Filter">
              <button
                className="small-contained-button-filter ms-2 mt-3 "
                onClick={filterDataButtonReset}
              >
                <RotateLeftIcon />
              </button>
            </Tooltip>
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <p className="text-end mb-0 pe-4" style={{ color: "#f6bd4b" }}>
          {settings.streack_count_text ?? "Streak Score" + +" " + ":"}
          <span style={{ color: "white" }}> : {StreakCount}</span>
        </p>
        {Activities.length > 0 ? (
          Activities.map((item, index) => {
            let total_score =
              item.win_note_performance_rate +
              item.discipline_performance_rate +
              item.desire_performance_rate +
              item.focus_performance_rate +
              item.attitude_performance_rate;
            return (
              <Container className="mt-2">
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon className="icon-color" />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className="svg-color"
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <p className="mb-0" style={{ color: "#f6bd4b" }}>
                        Activity Date :
                        <span style={{ color: "white" }}>
                          {" " + item.date}
                        </span>
                      </p>
                      <p
                        className="text-end mb-0 pe-4"
                        style={{ color: "#f6bd4b" }}
                      >
                        {settings.total_score_text + " " + ":"}
                        <span style={{ color: "white" }}> {total_score}</span>
                      </p>
                    </div>
                  </AccordionSummary>

                  <AccordionDetails
                    style={{
                      backgroundColor: "#121826",
                      border: "2px solid #1D2537",
                      borderBottomLeftRadius: "20px",
                      borderBottomRightRadius: "20px",
                    }}
                  >
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className=" mt-3 h-100">
                            <div className="row" style={{ padding: "0px 0px" }}>
                              <div className="col-lg-6 pe-0">
                                {/* <div
                                  className="d-flex justify-content-between mb-2"
                                  style={{
                                    backgroundColor: "#1D2537",
                                    alignItems: "center",
                                    padding: "15px",
                                    borderRadius: "5px",
                                  }}
                                >
                                  <p
                                    className="mb-0"
                                    style={{
                                      color: "white",
                                      fontSize: "16px",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    Your Attitude :
                                  </p>
                                  <p
                                    style={{ fontSize: "18px" }}
                                    className="mb-0 text-muted"
                                  >
                                    <span
                                      className={` ${
                                        item.attitude_performance_rate == 10
                                          ? "colored-last-value"
                                          : "text-muted"
                                      }`}
                                    >
                                      {item.attitude_performance_rate}
                                    </span>{" "}
                                    /<span className="pro"> 10</span>
                                  </p>
                                </div> */}

                                <div
                                  className="input-accountability-box small-boxss"
                                  style={{
                                    color: "white",
                                    fontSize: "16px",
                                    textTransform: "capitalize",
                                    display: "",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    padding: "",
                                    backgroundColor: "transparent",
                                  }}
                                >
                                  <span className="input-accountability-label">
                                    Your Attitude
                                  </span>
                                  <div
                                    className="input-slider"
                                    style={{ marginTop: "10px" }}
                                  >
                                    <span
                                      className="mt-1 me-3"
                                      style={{ color: "white" }}
                                    >
                                      0
                                    </span>
                                    <Slider
                                      aria-label="Temperature"
                                      defaultValue={0}
                                      className={classes.root}
                                      value={item.attitude_performance_rate}
                                      classes={{ rail: classes.rail }}
                                      valueLabelDisplay={
                                        item.attitude_performance_rate > 0
                                          ? "on"
                                          : "off"
                                      }
                                      min={0}
                                      max={10}
                                    />
                                    <span
                                      className={`mt-1 ms-3 ${
                                        item.attitude_performance_rate == 10
                                          ? "colored-last-value"
                                          : "color-white"
                                      }`}
                                    >
                                      10
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div
                                  className="input-accountability-box small-boxss"
                                  style={{
                                    color: "white",
                                    fontSize: "16px",
                                    textTransform: "capitalize",
                                    display: "",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    padding: "",
                                    backgroundColor: "transparent",
                                  }}
                                >
                                  <span className="input-accountability-label">
                                    Your Focus
                                  </span>
                                  <div
                                    className="input-slider"
                                    style={{ marginTop: "10px" }}
                                  >
                                    <span
                                      className="mt-1 me-3"
                                      style={{ color: "white" }}
                                    >
                                      0
                                    </span>
                                    <Slider
                                      aria-label="Temperature"
                                      defaultValue={0}
                                      className={classes.root}
                                      value={item.focus_performance_rate}
                                      classes={{ rail: classes.rail }}
                                      valueLabelDisplay={
                                        item.attitude_performance_rate > 0
                                          ? "on"
                                          : "off"
                                      }
                                      min={0}
                                      max={10}
                                    />
                                    <span
                                      className={`mt-1 ms-3 ${
                                        item.focus_performance_rate == 10
                                          ? "colored-last-value"
                                          : "color-white"
                                      }`}
                                    >
                                      10
                                    </span>
                                  </div>
                                </div>
                                {/* <div className="input-accountability-box">
                                
                                  <span className="input-accountability-label">
                                    Your Focus
                                  </span>
                                  <div className="input-slider">
                                    <span
                                      className="mt-1 me-3"
                                      style={{ color: "white" }}
                                    >
                                      0
                                    </span>
                                    <Slider
                                      aria-label="Temperature"
                                      defaultValue={0}
                                      className={classes.root}
                                      value={item.focus_performance_rate}
                                      classes={{ rail: classes.rail }}
                                      valueLabelDisplay={
                                        item.attitude_performance_rate > 0
                                          ? "on"
                                          : "off"
                                      }
                                      min={0}
                                      max={10}
                                    />
                                    <span
                                      className={`mt-1 ms-3 ${
                                        item.attitude_performance_rate == 10
                                          ? "colored-last-value"
                                          : "color-white"
                                      }`}
                                    >
                                      10
                                    </span>
                                  </div>
                                </div> */}
                              </div>{" "}
                              <div className="col-lg-6 pe-0">
                                <div
                                  className="input-accountability-box small-boxss"
                                  style={{
                                    color: "white",
                                    fontSize: "16px",
                                    textTransform: "capitalize",
                                    display: "",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    padding: "",
                                    backgroundColor: "transparent",
                                  }}
                                >
                                  <span className="input-accountability-label">
                                    Your Desire
                                  </span>
                                  <div
                                    className="input-slider"
                                    style={{ marginTop: "10px" }}
                                  >
                                    <span
                                      className="mt-1 me-3"
                                      style={{ color: "white" }}
                                    >
                                      0
                                    </span>
                                    <Slider
                                      aria-label="Temperature"
                                      defaultValue={0}
                                      className={classes.root}
                                      value={item.desire_performance_rate}
                                      classes={{ rail: classes.rail }}
                                      valueLabelDisplay={
                                        item.desire_performance_rate > 0
                                          ? "on"
                                          : "off"
                                      }
                                      min={0}
                                      max={10}
                                    />
                                    <span
                                      className={`mt-1 ms-3 ${
                                        item.desire_performance_rate == 10
                                          ? "colored-last-value"
                                          : "color-white"
                                      }`}
                                    >
                                      10
                                    </span>
                                  </div>
                                </div>
                                {/* <div className="input-accountability-box">
                                  <span className="input-accountability-label">
                                    Your Desire
                                  </span>
                                  <div className="input-slider">
                                    <span
                                      className="mt-1 me-3"
                                      style={{ color: "white" }}
                                    >
                                      0
                                    </span>
                                    <Slider
                                      aria-label="Temperature"
                                      defaultValue={0}
                                      className={classes.root}
                                      value={item.desire_performance_rate}
                                      classes={{ rail: classes.rail }}
                                      valueLabelDisplay={
                                        item.desire_performance_rate > 0
                                          ? "on"
                                          : "off"
                                      }
                                      min={0}
                                      max={10}
                                    />
                                    <span
                                      className={`mt-1 ms-3 ${
                                        item.desire_performance_rate == 10
                                          ? "colored-last-value"
                                          : "color-white"
                                      }`}
                                    >
                                      10
                                    </span>
                                  </div>
                                </div> */}
                              </div>{" "}
                              <div className="col-lg-6">
                                <div
                                  className="input-accountability-box small-boxss"
                                  style={{
                                    color: "white",
                                    fontSize: "16px",
                                    textTransform: "capitalize",
                                    display: "",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    padding: "",
                                    backgroundColor: "transparent",
                                  }}
                                >
                                  <span className="input-accountability-label">
                                    Your Discipline
                                  </span>
                                  <div
                                    className="input-slider"
                                    style={{ marginTop: "10px" }}
                                  >
                                    <span
                                      className="mt-1 me-3"
                                      style={{ color: "white" }}
                                    >
                                      0
                                    </span>
                                    <Slider
                                      aria-label="Temperature"
                                      defaultValue={0}
                                      className={classes.root}
                                      value={item.discipline_performance_rate}
                                      classes={{ rail: classes.rail }}
                                      valueLabelDisplay={
                                        item.discipline_performance_rate > 0
                                          ? "on"
                                          : "off"
                                      }
                                      min={0}
                                      max={10}
                                    />
                                    <span
                                      className={`mt-1 ms-3 ${
                                        item.discipline_performance_rate == 10
                                          ? "colored-last-value"
                                          : "color-white"
                                      }`}
                                    >
                                      10
                                    </span>
                                  </div>
                                </div>
                                {/* <div className="input-accountability-box">
                                  <span className="input-accountability-label">
                                    Your Discipline
                                  </span>
                                  <div className="input-slider">
                                    <span
                                      className="mt-1 me-3"
                                      style={{ color: "white" }}
                                    >
                                      0
                                    </span>
                                    <Slider
                                      aria-label="Temperature"
                                      defaultValue={0}
                                      className={classes.root}
                                      value={item.discipline_performance_rate}
                                      classes={{ rail: classes.rail }}
                                      valueLabelDisplay={
                                        item.discipline_performance_rate > 0
                                          ? "on"
                                          : "off"
                                      }
                                      min={0}
                                      max={10}
                                    />
                                    <span
                                      className={`mt-1 ms-3 ${
                                        item.discipline_performance_rate == 10
                                          ? "colored-last-value"
                                          : "color-white"
                                      }`}
                                    >
                                      10
                                    </span>
                                  </div>
                                </div> */}
                              </div>{" "}
                              <div className="col-lg-6 pe-0">
                                <div
                                  className="input-accountability-box small-boxss"
                                  style={{
                                    color: "white",
                                    fontSize: "16px",
                                    textTransform: "capitalize",
                                    display: "",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    padding: "",
                                    backgroundColor: "transparent",
                                  }}
                                >
                                  <span className="input-accountability-label">
                                    Rate This Win
                                  </span>
                                  <div
                                    className="input-slider"
                                    style={{ marginTop: "10px" }}
                                  >
                                    <span
                                      className="mt-1 me-3"
                                      style={{ color: "white" }}
                                    >
                                      0
                                    </span>
                                    <Slider
                                      aria-label="Temperature"
                                      defaultValue={0}
                                      className={classes.root}
                                      value={item.win_note_performance_rate}
                                      classes={{ rail: classes.rail }}
                                      valueLabelDisplay={
                                        item.win_note_performance_rate > 0
                                          ? "on"
                                          : "off"
                                      }
                                      min={0}
                                      max={10}
                                    />
                                    <span
                                      className={`mt-1 ms-3 ${
                                        item.win_note_performance_rate == 10
                                          ? "colored-last-value"
                                          : "color-white"
                                      }`}
                                    >
                                      10
                                    </span>
                                  </div>
                                </div>
                                {/* <div className="input-accountability-box">
                                  <span className="input-accountability-label">
                                    Rate This Win
                                  </span>
                                  <div className="input-slider">
                                    <span
                                      className="mt-1 me-3"
                                      style={{ color: "white" }}
                                    >
                                      0
                                    </span>
                                    <Slider
                                      aria-label="Temperature"
                                      defaultValue={0}
                                      className={classes.root}
                                      value={item.win_note_performance_rate}
                                      classes={{ rail: classes.rail }}
                                      valueLabelDisplay={
                                        item.win_note_performance_rate > 0
                                          ? "on"
                                          : "off"
                                      }
                                      min={0}
                                      max={10}
                                    />
                                    <span
                                      className={`mt-1 ms-3 ${
                                        item.win_note_performance_rate == 10
                                          ? "colored-last-value"
                                          : "color-white"
                                      }`}
                                    >
                                      10
                                    </span>
                                  </div>
                                </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                        {item.win_note && (
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="mt-3 mb-3">
                                <div className="mt-2 ps-3">
                                  <h4 className="heading">Win Info</h4>
                                  <p
                                    className="mb-0 text-muted"
                                    style={{
                                      color: "#c7cfd6",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {item.win_note}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              </Container>
            );
          })
        ) : (
          <RecordNotFound title={"Activities"} />
        )}
        {totlePages > pageNumber ? (
          <div className="col-12 text-center">
            <button
              className="small-contained-button mt-3"
              onClick={loadMoreData}
              disabled={isLoadingMore}
            >
              {isLoadingMore ? "Loading..." : "Load More"}
            </button>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default PerformanceStreakPastActivities;
