import { TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import {
  active_lead_status_api,
  update_lead_status_for_member_api,
  update_lead_status_history_for_member_api,
} from "src/DAL/leadStatus/leadStatus";
import MUIAutocomplete from "src/components/GeneralComponents/MUIAutocomplete";
import CustomConfirmation from "src/components/menuIcons/CustomConfirmation";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import dayjs from "dayjs";
import moment from "moment";
import { useParams } from "react-router";

export default function ChangeLeadStatusHistory({
  selectedObject,
  setOpen,
  LeadStatusList,
  leadStatusData,
}) {
  const params = useParams();
  const [inputs, setInputs] = useState({ lead_status: null });
  const [inputsStates, setInputsStates] = useState({
    changed_date_time: dayjs(new Date()).$d,
    income_value: 0,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [openStatus, setOpenStatus] = useState(false);
  const [leadStatus, setLeadStatus] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const handleChangeOthers = (name, value) => {
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleChangeDates = (name, value) => {
    setInputsStates((values) => ({ ...values, [name]: value.$d }));
  };

  const handleChangeStatus = async () => {
    setOpenStatus(false);
    setIsLoading(true);
    const postData = {
      member_id: leadStatusData._id,
      lead_status: inputs.lead_status ? inputs.lead_status._id : "",
      changed_date_time: moment(inputsStates.changed_date_time).format(
        "YYYY-MM-DD"
      ),
      income_value: inputsStates.income_value,
      id: selectedObject._id,
    };
    console.log(postData, "postDatapostData");
    const result = await update_lead_status_history_for_member_api(postData);
    if (result.code === 200) {
      LeadStatusList();
      setOpen(false);
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const get_active_lead_status = async () => {
    const result = await active_lead_status_api();
    if (result.code === 200) {
      setLeadStatus(result.lead_status);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputsStates((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleChangeStatus();
  };

  useEffect(() => {
    get_active_lead_status();
    setInputs(selectedObject);
  }, [selectedObject]);
  useEffect(() => {
    setInputsStates((prevState) => ({
      ...prevState,
      ["changed_date_time"]: selectedObject?.changed_date_time,
      ["income_value"]: selectedObject?.income_value,
    }));
  }, [selectedObject]);

  return (
    <>
      <div className="container">
        <form className="row" onSubmit={handleSubmit}>
          <div className="col-lg-12 mt-3">
            <MUIAutocomplete
              inputLabel="Lead Status"
              selectedOption={inputs.lead_status}
              setSelectedOption={(v) => {
                handleChangeOthers("lead_status", v);
              }}
              optionsList={leadStatus}
              disabled
              autoComplete="new-password"
              name="title"
            />
          </div>
          <div className="col-12">
            <TextField
              fullWidth
              className="mt-4"
              id="outlined-basic"
              label="Income Value"
              variant="outlined"
              name="income_value"
              type="number"
              InputProps={{ inputProps: { min: 0 } }}
              required={true}
              value={inputsStates.income_value}
              onChange={handleChange}
            />
          </div>
          <div className="col-12">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="Date"
                name="changed_date_time"
                inputFormat="DD-MM-YYYY"
                value={inputsStates.changed_date_time}
                format="DD-MM-YYYY"
                className="mt-3"
                onChange={(e) => handleChangeDates("changed_date_time", e)}
                renderInput={(params) => (
                  <TextField {...params} required={true} fullWidth />
                )}
              />
            </LocalizationProvider>
          </div>
          <div class="col-12 mt-3">
            <button class="small-contained-button w-100">
              {isLoading ? "Updating..." : "Update"}
            </button>
          </div>
        </form>
      </div>
      <CustomConfirmation
        open={openStatus}
        setOpen={setOpenStatus}
        title={"Are you sure you want to update lead status?"}
        handleAgree={handleChangeStatus}
      />
    </>
  );
}
