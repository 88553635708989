import React, { useEffect, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
// material
import { styled } from "@mui/material/styles";
import {
  Card,
  Stack,
  Link,
  Container,
  Typography,
  CircularProgress,
} from "@mui/material";
//

import { consultantSettingApi, login } from "../DAL/Login/Login";
// layouts
import AuthLayout from "../layouts/AuthLayout";
import { makeStyles } from "@material-ui/core/styles";
// components
import Page from "../components/Page";
import { MHidden } from "../components/@material-extend";
import { LoginForm } from "../components/authentication/login";
import AuthSocial from "../components/authentication/AuthSocial";
import { get_app_headers, project_name, s3baseUrl } from "../config/config";
import { useContentSetting } from "src/Hooks/ContentSettingState";
import { GetProfileApi } from "src/DAL/Profile/Profile";
import { consultantLogo } from "src/assets";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function Login() {
  const navigate = useNavigate();
  const [projectData, setProjectData] = useState({});
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const headers = get_app_headers();
  const {
    userInfo,
    handleSetUserInfo,
    handleConsultantInfo,
    handleAdminTimeZone,
    setUserProfile,
    setAdminTimeZone,
    START_SOCKET_FORCEFULLY,
    consultantInfo,
    handleWheelOfLifeSetting,
  } = useContentSetting();

  const getContentSetting = async () => {
    const result = await consultantSettingApi();
    if (result.code == 200) {
      handleConsultantInfo(result.consultant_setting);
      handleWheelOfLifeSetting(result.wheel_of_life_setting);
      setProjectData(result.consultant_setting);
      localStorage.setItem(
        `consultant_data`,
        JSON.stringify(result.consultant_setting)
      );
      localStorage.setItem(
        `wheel_of_life_setting`,
        JSON.stringify(result.wheel_of_life_setting)
      );
      setLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setLoading(false);
    }
  };

  const handleSubmitLogin = async (data) => {
    setLoading(true);
    const result = await login(data);
    if (result.code === 200) {
      console.log(result.message, "resultresult from login");
      handleSetUserInfo(result.consultantUser);
      START_SOCKET_FORCEFULLY(result.consultantUser._id);
      handleAdminTimeZone(result.time_zone);
      localStorage.setItem(`user_data`, JSON.stringify(result.consultantUser));
      localStorage.setItem("token", result.token);
      if (result.access_object.other_link) {
        window.location.href = result.access_object.other_link;
      } else {
        navigate("/dashboard", { replace: true });
      }
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   getContentSetting();
  // }, []);

  if (loading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <RootStyle>
      {/* <AuthLayout>
        Don’t have an account? &nbsp;
        <Link underline="none" variant="subtitle2" component={RouterLink} to="/register">
          Get started
        </Link>
      </AuthLayout> */}

      <MHidden width="mdDown">
        <SectionStyle>
          {/* <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
            Hi, Welcome Back
          </Typography> */}
          <img src={consultantLogo} alt="login" />
        </SectionStyle>
      </MHidden>

      <Container maxWidth="sm">
        <ContentStyle>
          <div>
            <div className="image-container">
              <img src={consultantLogo} alt="login" />
            </div>
          </div>
          <Stack sx={{ mb: 3 }}>
            <Typography variant="h4" gutterBottom className="mb-3">
              Welcome To
            </Typography>
            <Typography variant="div" gutterBottom>
              {consultantInfo.brand_logo && (
                <img src={s3baseUrl + consultantInfo.brand_logo} alt="" />
              )}
            </Typography>
          </Stack>
          <Stack sx={{ mb: 5 }}>
            <Typography sx={{ color: "text.secondary" }}>
              Enter your details below.
            </Typography>
          </Stack>
          {/* <AuthSocial /> */}

          <LoginForm isLoading={loading} onhandleLogin={handleSubmitLogin} />

          <MHidden width="smUp">
            <Typography variant="body2" align="center" sx={{ mt: 3 }}>
              Don’t have an account?&nbsp;
              {/* <Link variant="subtitle2" component={RouterLink} to="register">
                Get started
              </Link> */}
            </Typography>
          </MHidden>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
