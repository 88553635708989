// Recorder.js
import { CircularProgress, IconButton, Tooltip } from "@mui/material";
import React, { useRef, useState } from "react";
import { ReactMic } from "react-mic";
import ClearIcon from "@mui/icons-material/Clear";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { useEffect } from "react";
import PauseIcon from "@mui/icons-material/Pause";
import { MAX_RECORDING_MINUTES } from "src/utils/constants";
import SendIcon from "@mui/icons-material/Send";

export default function VoiceRecorder(props) {
  const { handleSubmit, handleCancel } = props;
  const [isRecording, setIsRecording] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const isRecordingStopped = useRef(false);
  const [audioData, setAudioData] = useState(null);
  const [timer, setTimer] = useState(0);

  const startRecording = () => {
    setIsRecording(true);
  };

  const handleCancelRecording = () => {
    setIsRecording(false);
    setTimeout(() => {
      setTimer(0);
      handleCancel();
    }, 100);
  };

  const stopRecording = () => {
    setIsLoading(true);
    setTimeout(() => {
      if (!isRecording) {
        handleSubmit(audioData);
      }
      setIsRecording(false);
      isRecordingStopped.current = true;
    }, 100);
  };

  const playRecording = () => {
    setIsPaused(false);
    setIsRecording(true);
  };

  const pauseRecording = () => {
    setIsPaused(true);
    setIsRecording(false);
  };

  const onData = (recordedData) => {
    // console.log(recordedData, "recordedDatarecordedData");
  };

  const onStop = (blob) => {
    const startTime = blob.startTime;
    const stopTime = blob.stopTime;
    const duration = stopTime - startTime;
    let blobWithProp = new Blob([blob["blob"]], { type: "audio/mpeg" });
    setAudioData(blobWithProp);
    if (isRecordingStopped.current) {
      handleSubmit(blobWithProp, duration);
    }
  };

  const handleStoped = () => {
    setIsRecording(false);
    setIsPaused(true);
    setTimer(0);
  };

  useEffect(() => {
    startRecording();
    return () => handleStoped();
  }, []);

  useEffect(() => {
    let interval;
    if (isRecording) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer + 1);
      }, 1000);
    } else {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [isRecording]);

  useEffect(() => {
    if (timer >= MAX_RECORDING_MINUTES * 60) {
      pauseRecording();
    }
  }, [timer]);

  let minutes = Math.floor(timer / 60);
  let seconds = timer % 60;

  if (minutes < 10) {
    minutes = `0${minutes}`;
  }
  if (seconds < 10) {
    seconds = `0${seconds}`;
  }

  let recorded_with = (timer / (MAX_RECORDING_MINUTES * 60)) * 100;

  return (
    <div className="d-flex w-100">
      <ReactMic
        record={isRecording}
        className="sound-wave"
        onStop={onStop}
        onData={onData}
        strokeColor="#000000"
        backgroundColor="#FF4081"
        mimeType="audio/mp3"
      />
      <>
        <div className="recording-slider">
          <div className="action-buttons">
            <Tooltip title="Cancel">
              <ClearIcon onClick={handleCancelRecording} />
            </Tooltip>
            {/* {isPaused ? (
              <Tooltip title="Resume">
                <PlayArrowIcon onClick={playRecording} />
              </Tooltip>
            ) : (
              <Tooltip title="Pause">
                <PauseIcon onClick={pauseRecording} />
              </Tooltip>
            )} */}
          </div>
          <div className="inner-slider">
            <div
              className="recorded-audio"
              style={{
                width: `${recorded_with}%`,
              }}
            ></div>
          </div>
          <div className="recorded-time">{`${minutes}:${seconds}`}</div>
        </div>

        {isLoading ? (
          <IconButton className="ms-2 mb-2">
            <CircularProgress
              style={{
                width: 23,
                height: 23,
              }}
            />
          </IconButton>
        ) : (
          <Tooltip title="Send">
            <IconButton className="ms-2 mb-2" onClick={stopRecording}>
              <SendIcon />
            </IconButton>
          </Tooltip>
        )}
      </>
    </div>
  );
}
