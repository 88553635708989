import { Icon } from "@iconify/react";
import { Avatar, Tabs, Tab, CircularProgress, Tooltip } from "@mui/material";
import React, { useCallback, useRef, useState } from "react";
import { motion } from "framer-motion";
import { s3baseUrl } from "src/config/config";
import { string_avatar } from "src/utils/constants";
import { _add_chat, _get_chat_message, _send_message } from "src/DAL/Chat/Chat";
import { useEffect } from "react";
import RefreshIcon from "@mui/icons-material/Refresh";

function UserBox(props) {
  const {
    handleSetSelectedChat,
    handleSetSelectedUser,
    online_members = [],
    offline_members = [],
    get_users_list_against_event,
    get_more_users_list_against_event,
    onlineMembersCount,
    offlineMembersCount,
    totalPages,
    pageNumber,
    loadMorePath,
    isLoadingUsers,
    setPageNumber,
  } = props;

  const [isExpanded, setIsExpanded] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [isRefresh, setIsRefresh] = useState(false);
  const [selectedTab, setSelectedTab] = useState(1);
  const [allowHiiting, setAllowHiiting] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const observer = useRef();

  const handleTabChange = (value) => {
    setSelectedTab(value);
  };

  const handleSelectUser = (user) => {
    if (user.chat) {
      handleSetSelectedChat(user.chat);
      handleSetSelectedUser(null);
    } else {
      handleSetSelectedChat(null);
      handleSetSelectedUser(user);
    }
  };

  const lastBookElementRef = useCallback(
    (node) => {
      if (isLoadingMore) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && totalPages > pageNumber) {
          loadMoreData();
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoadingMore, totalPages, pageNumber, loadMorePath, observer]
  );

  const loadMoreData = () => {
    setIsLoadingMore(true);
    get_more_users_list_against_event(loadMorePath, setIsLoadingMore);
  };

  const bottom_in = {
    hidden: {
      y: "100px",
      x: "0px",
      height: "0",
      opacity: 1,
      scale: 1,
    },
    visible: {
      opacity: 1,
      scale: 1,
      height: "60vh",
      y: "0px",
      x: "0px",
      transition: { delay: 0, duration: 0.5 },
    },
  };

  function unique_array(arr) {
    return arr.reduce((unique, item) => {
      return unique.some((u) => u._id === item._id)
        ? unique
        : [...unique, item];
    }, []);
  }

  const handleChangeText = (e) => {
    if (e.target.value.length > 3) {
      setAllowHiiting(true);
    }
    setSearchInput(e.target.value);
  };

  const get_members_list = (tab) => {
    if (tab === 1) {
      return online_members;
    } else {
      return offline_members;
    }
  };

  const handleUserRefesh = () => {
    setIsRefresh(!isRefresh);
  };

  useEffect(() => {
    setPageNumber(0);
    if (isExpanded) {
      get_users_list_against_event("", searchInput, selectedTab);
    }
  }, [selectedTab, isRefresh, isExpanded === true]);

  useEffect(() => {
    if (allowHiiting && isExpanded) {
      setPageNumber(0);
      get_users_list_against_event("", searchInput, selectedTab);
    }
  }, [searchInput]);

  return (
    <>
      {/* Listing */}
      <div className="user-box-wrapper">
        <div
          className="user-box-header d-flex align-items-center justify-content-between"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          <div className="user-box-heading">Event Users</div>
          <div className="">
            {isExpanded ? (
              <Icon icon="akar-icons:chevron-down" />
            ) : (
              <Icon icon="akar-icons:chevron-up" />
            )}
          </div>
        </div>

        {isExpanded && (
          <>
            <div className="row mt-1 p-2">
              <div className="col-10 user-search-box">
                <Icon
                  fontSize={20}
                  className="chat_icon_search"
                  icon="akar-icons:search"
                />
                <input
                  className="user-search-input"
                  type="text"
                  placeholder="Search"
                  value={searchInput}
                  onChange={handleChangeText}
                />
              </div>
              <Tooltip title="Refresh">
                <div
                  onClick={handleUserRefesh}
                  className="col-2 text-end pointer mt-2 pe-4"
                >
                  <RefreshIcon />
                </div>
              </Tooltip>
            </div>

            <Tabs
              value={selectedTab}
              onChange={(e, value) => handleTabChange(value)}
              textColor="primary"
              indicatorColor="primary"
              variant="fullWidth"
            >
              <Tab value={1} label={`Online (${onlineMembersCount})`} />
              <Tab value={0} label={`Offline (${offlineMembersCount})`} />
            </Tabs>
          </>
        )}

        <motion.div
          variants={bottom_in}
          initial="hidden"
          animate={isExpanded ? "visible" : "hidden"}
          exit="hidden"
          className={"user-box-body-expand"}
        >
          <div className="list-users">
            {isLoadingUsers ? (
              <CircularProgress
                className="notification-popover-loader"
                color="primary"
              />
            ) : (
              unique_array(get_members_list(selectedTab)).map((user, i) => (
                <div
                  onClick={() => handleSelectUser(user)}
                  className="chat-list-user hover-effect d-flex p-2"
                  key={i}
                >
                  <div className="user-profile">
                    <Avatar
                      src={s3baseUrl + user.profile_image}
                      style={{ height: 30, width: 30 }}
                    >
                      {string_avatar(user.first_name[0])}
                    </Avatar>
                    <div
                      className={user.is_online ? "online" : "offline"}
                    ></div>
                  </div>
                  <div className="chat-profile-data">
                    <div className="chat-profile-name ps-2">
                      {user.first_name + " " + user.last_name}
                    </div>
                  </div>
                </div>
              ))
            )}
            {unique_array(get_members_list(selectedTab)).length > 0 &&
            totalPages > pageNumber ? (
              <div className="col-12 text-center p-2">
                <span
                  ref={lastBookElementRef}
                  className=" mt-3"
                  onClick={loadMoreData}
                  id="load-more-feed"
                >
                  {isLoadingMore ? "Loading..." : "Load More"}
                </span>
              </div>
            ) : (
              ""
            )}
          </div>
        </motion.div>
      </div>
    </>
  );
}

export default UserBox;
