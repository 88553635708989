import React, { useState, useEffect } from "react";
import {
  TextField,
  InputLabel,
  Select,
  FormControl,
  MenuItem,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useSnackbar } from "notistack";
import { get_root_value } from "src/utils/domUtils";
import { makeStyles } from "@mui/styles";
import { _send_reminder } from "src/DAL/SupportTicket/SupportTicket";
import TinyEditor from "src/components/Ckeditor";
import { useContentSetting } from "src/Hooks/ContentSettingState";
const useStyles = makeStyles(() => ({
  paper: {
    background: get_root_value("--popup-background-color"),
    color: get_root_value("--input-text-color"),
  },
}));

export default function SendReminder({
  onCloseDrawer,
  dataList,
  editData,
  ticketId,
  formType,
  ReminderMessage,
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const settings = useContentSetting();
  const { socket } = settings;
  const [Message, setMessage] = useState("");
  console.log(ReminderMessage.object.message, "ReminderMessage");
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const data = {
      message: Message,
      support_ticket: ticketId,
    };
    const result = await _send_reminder(data);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      socket.emit("send_reminder_support_ticket", result);
      setIsLoading(false);
      dataList();
      onCloseDrawer();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  return (
    <div className="container new-memories">
      <form onSubmit={formType === "ADD" ? handleSubmit : handleUpdate}>
        <div className="col-12 mt-4">
          <h4>Message *</h4>
          <TinyEditor
            setDetailDescription={setMessage}
            detailDescriptionCk={Message}
          />
        </div>

        <div className="mt-3">
          <span className="float-end">
            <button className="submit-button" disabled={isLoading}>
              {isLoading
                ? formType === "ADD"
                  ? "Sending..."
                  : "Updating..."
                : formType === "ADD"
                ? "Send"
                : "Update"}
            </button>
          </span>
        </div>
      </form>
    </div>
  );
}
