import { Link as RouterLink, Outlet, Navigate } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
// components
import Logo from "../components/Logo";
import { consultantSettingApi, witoutTokeninitapi } from "src/DAL/Login/Login";
import { useContentSetting } from "src/Hooks/ContentSettingState";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { useState } from "react";
import { useEffect } from "react";
// ----------------------------------------------------------------------

const HeaderStyle = styled("header")(({ theme }) => ({
  top: 0,
  left: 0,
  lineHeight: 0,
  width: "100%",
  position: "absolute",
  padding: theme.spacing(3, 3, 0),
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(5, 5, 0),
  },
}));
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
// ----------------------------------------------------------------------

export default function LogoOnlyLayout() {
  const [projectData, setProjectData] = useState({});
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const { handleConsultantInfo, handleWheelOfLifeSetting } =
    useContentSetting();
  const getContentSetting = async () => {
    const result = await witoutTokeninitapi();
    if (result.code == 200) {
      handleConsultantInfo(result.consultant_setting);
      handleWheelOfLifeSetting(result.wheel_of_life_setting);
      setProjectData(result.consultant_setting);
      localStorage.setItem(
        `consultant_data`,
        JSON.stringify(result.consultant_setting)
      );
      localStorage.setItem(
        `wheel_of_life_setting`,
        JSON.stringify(result.wheel_of_life_setting)
      );
      setLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setLoading(false);
    }
  };
  useEffect(() => {
    getContentSetting();
  }, []);
  if (loading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  if (localStorage.getItem("token")) {
    return <Navigate to="/dashboard"> </Navigate>;
  }
  return (
    <>
      <HeaderStyle>
        <RouterLink to="/">
          <Logo />
        </RouterLink>
      </HeaderStyle>
      <Outlet />
    </>
  );
}
