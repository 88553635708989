import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { departmentListAPIs } from "src/DAL/SupportTicket/SupportTicket";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
import { get_root_value } from "src/utils/domUtils";

function CustomConfirmationNeedFixesPopup({
  open,
  setOpenDelete,
  handleAgree,
  value,
  setOpen,
  handleChange,
  setAffiliateData,
  affiliateData,
  endDate,
}) {
  const [departmentsData, setDepartmentsData] = useState([]);
  const handleCloseDialog = () => {
    setOpen(false);
  };
  const getDepartmentListing = async () => {
    const result = await departmentListAPIs();
    if (result.code === 200) {
      setDepartmentsData(result.department);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  return (
    <>
      <Dialog
        open={open}
        onClose={handleCloseDialog}
        PaperProps={{
          style: {
            backgroundColor: get_root_value("--popup-background-color"),
            // backgroundColor: "black",
            color: get_root_value("--portal-theme-primary"),
            boxShadow: "none",
            width: "50%",
          },
        }}
      >
        <DialogTitle>
          <div className="row">
            <DialogTitle>
              {"Are you sure you want to move this ticket to needs fixes?"}
            </DialogTitle>
            <div className="col-lg-12 col-md-6 col-sm-12 mt-4">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  format="DD-MM-YYYY"
                  mask="__/__/____"
                  inputFormat="DD-MM-YYYY"
                  label="Target Date"
                  name="start_date"
                  value={endDate}
                  onChange={handleChange}
                  renderInput={(params) => (
                    <TextField {...params} required={true} fullWidth />
                  )}
                />
              </LocalizationProvider>
            </div>
          </div>
        </DialogTitle>
        <DialogActions className="dialog-box">
          <Button className="model-button-hover" onClick={handleCloseDialog}>
            Cancel
          </Button>
          <Button className="model-button-hover" onClick={handleAgree}>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default CustomConfirmationNeedFixesPopup;
