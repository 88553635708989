import { invokeApi } from "../../bl_libs/invokeApi";

export const paymentTemplateDetailApi = async (id) => {
  const requestObj = {
    path: `api/payment_template/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const agreementConfigurationPaymentRequestApi = async (data, id) => {
  const requestObj = {
    path: `api/payment_request/agreement_config/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const addPaymentRequest = async (data) => {
  const requestObj = {
    path: `api/payment_request/add`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const EditPaymentRequestApi = async (data, id) => {
  const requestObj = {
    path: `api/payment_request/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const CommissionConfigurationPaymentRequestApi = async (data, id) => {
  const requestObj = {
    path: `api/payment_request/commission_info/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const get_list_list_without_team = async (search_text) => {
  const requestObj = {
    path: `api/consultant/consultant_list_without_team?search_text=${search_text}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const paymentRequestListApi = async (page, limit, status) => {
  const requestObj = {
    path: `api/payment_request/list/consultant?page=${page}&limit=${limit}&payment_status=${status}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const change_status_payment_request_api = async (data) => {
  const requestObj = {
    path: `api/payment_request/change_one_time_payment_status/paid/canceled`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const AddPaymentTemplateApi = async (data) => {
  const requestObj = {
    path: `api/payment_template`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const EditPaymentTemplateApi = async (data, id) => {
  const requestObj = {
    path: `api/payment_template/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const memberBillingDetailApi = async (slug) => {
  const requestObj = {
    path: `api/payment_request/${slug}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const DeletePaymentRequestApi = async (slug) => {
  const requestObj = {
    path: `api/payment_request/${slug}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const get_bank_request_url_api = async (
  request_id,
  payable_currency
) => {
  const requestObj = {
    path: `api/payment_request/get_bank_request_url/${request_id}?payable_currency=${payable_currency}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _payment_request_detail_api = async (_id) => {
  const requestObj = {
    path: `api/payment_request/payment_request_detail_for/bank_by_delegate/${_id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const send_request_reminder_api = async (data) => {
  const requestObj = {
    path: `api/payment_request/send_reminder`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
