import { Chip } from "@mui/material";

const LeadStatusMemberDetail = ({ userValue }) => {
  return (
    <>
      <div className="container lead-starus-member-detail">
        <div className="row mt-3">
          <div className="col-4 mb-2">
            <h4>Name :</h4>
          </div>
          <div className="col-8 mb-2">
            <span>{userValue.name}</span>
          </div>
          <div className="col-4 mb-2">
            <h4>Email :</h4>
          </div>
          <div className="col-8 mb-2">
            <span>{userValue.email}</span>
          </div>{" "}
          <div className="col-4 mb-2">
            <h4>Contact No:</h4>
          </div>
          <div className="col-8 mb-2">
            <span>+9231123313344</span>
          </div>{" "}
          <div className="col-4 mb-2">
            <h4>Community level:</h4>
          </div>
          <div className="col-8 mb-2">
            <span>Elite</span>
          </div>{" "}
          <div className="col-4 mb-2">
            <h4>Last login:</h4>
          </div>
          <div className="col-8 mb-2">
            <span>5-12-2024 09:46pm</span>
          </div>{" "}
          <div className="col-4 mb-2">
            <h4>Created date:</h4>
          </div>
          <div className="col-8 mb-2">
            <span>5-12-2022 </span>
          </div>
          <div className="col-4 mb-2">
            <h4>Membership Expiry date:</h4>
          </div>
          <div className="col-8 mb-2">
            <span>5-12-2025 </span>
          </div>{" "}
          <div className="col-4 mb-2">
            <h4>Status:</h4>
          </div>
          <div className="col-8 mb-2">
            <span>
              {" "}
              <Chip
                width="140px"
                label={true === true ? "Active" : "Inactive"}
                variant="contained"
                color={true === true ? "success" : "error"}
                size="small"
              />{" "}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeadStatusMemberDetail;
