import React from "react";
import {
  Chart as ChartJS,
  RadialLinearScale,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";
import { PolarArea } from "react-chartjs-2";
// import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";

ChartJS.register(RadialLinearScale, ArcElement, Tooltip, Legend);
ChartJS.defaults.borderColor = "#fff";
ChartJS.defaults.color = "#fff";
ChartJS.defaults.font.size = 16;
ChartJS.defaults.plugins.legend.fullSize = true;
// ChartJS.defaults.plugins.legend.labels.boxWidth = 60;
// ChartJS.defaults.plugins.legend.title = 60;

// ChartJS.scaleService.defaults.radialLinear.ticks.backdropColor =
//   "rgba(0, 0, 0, 0)";

export function PaiChart({ assessment_data, nameData }) {
  const fullName = nameData?.first_name + " " + nameData?.last_name;
  const replacedStringName = (labels) => {
    if (labels?.length > 0) {
      let data = labels.map((label) => {
        return label?.replace(/{Name}/g, fullName);
      });
      return data;
    } else {
      return labels;
    }
  };

  const data = {
    labels: replacedStringName(assessment_data.labels),
    datasets: [
      {
        data: assessment_data.data,
        backgroundColor: assessment_data.colors,
        borderWidth: 0,
        borderColor: "none",
      },
    ],
  };

  return (
    <PolarArea
      id="wheel-of-life"
      redraw={!true}
      options={{
        scales: { range: { min: 0, max: 10 } },
        plugins: {
          legend: {
            position: "right",
            align: "left",
            onClick: null,
            display: false,
          },
        },
        maintainAspectRatio: false,
        arc: {
          borderColor: "white",
        },
        layout: {
          padding: {
            right: 0,
          },
        },
      }}
      data={{ ...data }}
      height={"400px"}
    />
  );
}
