import React, { useCallback, useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import { useSnackbar } from "notistack";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { communityHandScreenshot, communityHeart } from "src/assets";
import MessageIcon from "@mui/icons-material/Message";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { get_more_activity_list } from "src/DAL/Community/Community";
import { Avatar, CircularProgress, Tooltip } from "@mui/material";
import { s3baseUrl } from "src/config/config";
import { makeStyles } from "@mui/styles";
import { get_date_with_user_time_zone } from "src/utils/constants";
import { useContentSetting } from "src/Hooks/ContentSettingState";
import { useChat } from "src/Hooks/ChatContext/ChatContext";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "0%",
    marginTop: "20%",
    marginBottom: "20%",
  },
  resize: {
    fontSize: 20,
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 480,
  minHeight: 380,
  maxHeight: 480,
  maxWidth: "100%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  pt: 0,
  borderRadius: 1,
  overflow: "auto",
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ShowAllLikes({
  openLikesModal,
  handleCloseSimpleBox,
  post_id,
  handleCloseDetailBox,
}) {
  const settings = useContentSetting();
  const classes = useStyles();
  const { pathname } = useLocation();
  const { handleAddChat } = useChat();
  const [value, setValue] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [feedActivity, setFeedActivity] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [pageNumber, setPageNumber] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [isLoadMore, setIsLoadMore] = useState(false);
  const [loadMorePath, setLoadMorePath] = useState("");
  const [loadMore, setLoadMore] = useState(
    "api/feeds/feed_activity_detail/delegate?page=0&limit=10"
  );
  const observer = useRef();
  const { userInfo } = useContentSetting();

  const lastBookElementRef = useCallback(
    (node) => {
      if (isLoadingMore) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && totalPages > pageNumber) {
          loadMoreData();
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoadingMore, totalPages, pageNumber, loadMorePath, observer]
  );

  //Changing tab values
  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const handleSendMessage = async (id) => {
    handleAddChat(id);
    handleCloseSimpleBox();
    handleCloseDetailBox();
  };

  const getMoreActivityList = async (feed, load_path) => {
    const formData = new FormData();
    var action_type = "all";
    if (value == 1) {
      action_type = "like";
    }
    formData.append("feed", post_id);
    formData.append("action_type", action_type);

    const result = await get_more_activity_list(load_path, formData);
    if (result.code === 200) {
      var newArray = feed.concat(result.feed_activity);
      setFeedActivity(newArray);
      setPageNumber((page) => page + 1);
      setTotalPages(result.total_pages);
      setLoadMorePath(result.load_more);
      setIsLoadingMore(false);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      setIsLoadingMore(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const loadMoreData = () => {
    setIsLoadingMore(true);
    getMoreActivityList(feedActivity, loadMorePath);
  };

  useEffect(() => {
    setPageNumber(0);
    setTotalPages(0);
    setIsLoading(true);
    getMoreActivityList([], loadMore);
  }, [value]);

  return (
    <div>
      <Modal
        open={openLikesModal}
        onClose={handleCloseSimpleBox}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="create-post-title-form">
          <Box sx={style} className="modal-theme">
            <div className="text-center modalIcon clo-12 show-all-likes">
              <Box sx={{ width: "100%" }}>
                <Box
                  sx={{
                    borderBottom: 1,
                    borderColor: "divider",
                    position: "relative",
                  }}
                >
                  <span
                    className="cross-show-all-likes-model"
                    onClick={handleCloseSimpleBox}
                  >
                    x
                  </span>
                  <Tabs
                    value={value}
                    onChange={handleChangeTab}
                    variant="scrollable"
                    scrollButtons="auto"
                    // allowScrollButtonsMobile="auto"
                    aria-label="scrollable auto tabs example"
                    className="show-all-likes-tabs"
                  >
                    <Tab
                      icon={<img src={communityHeart} alt="" />}
                      iconPosition="start"
                      label="Likes"
                      {...a11yProps(1)}
                    />
                  </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                  {isLoading ? (
                    <CircularProgress
                      className={classes.loading}
                      color="primary"
                    />
                  ) : (
                    <div className="row">
                      {feedActivity.map((activity, index) => {
                        return (
                          <div className="col-12 mt-3 d-flex">
                            <div className="post-creator me-auto ">
                              <div className="like-profile-image">
                                <Avatar
                                  src={
                                    s3baseUrl +
                                    activity.user_info_action_by?.profile_image
                                  }
                                  alt="photoURL"
                                >
                                  {activity.user_info_action_by?.name[0]}
                                </Avatar>
                                <div className="tiny-like-icon">
                                  {activity.activity_type === "feedlike" ? (
                                    <img src={communityHeart} alt="" />
                                  ) : (
                                    <img src={communityHandScreenshot} alt="" />
                                  )}
                                </div>
                              </div>
                              <div className="creator-name ps-2 pt-1">
                                <h3>{activity.user_info_action_by?.name}</h3>
                                <span className="date-color">
                                  {get_date_with_user_time_zone(
                                    activity?.createdAt,
                                    "DD MMM YYYY [at] hh:mm A",
                                    settings.userInfo.time_zone,
                                    settings.adminTimeZone
                                  )}
                                </span>
                              </div>
                            </div>
                            <div>
                              {userInfo.is_chat_allow &&
                                !pathname.includes("thesource") &&
                                userInfo._id !==
                                  activity.user_info_action_by.action_id &&
                                activity.user_info_action_by.action_by !==
                                  "consultant_user" && (
                                  <Tooltip title={"Chat"} arrow>
                                    <MessageIcon
                                      style={{
                                        fontSize: "20px",
                                      }}
                                      onClick={() =>
                                        handleSendMessage(
                                          activity.user_info_action_by.action_id
                                        )
                                      }
                                    />
                                  </Tooltip>
                                )}
                            </div>
                          </div>
                        );
                      })}
                      {totalPages > pageNumber ? (
                        <div className="col-12 text-center">
                          <span
                            onClick={loadMoreData}
                            className="load-more-text"
                            ref={lastBookElementRef}
                          >
                            {isLoadingMore ? "Loading..." : "Load More"}
                          </span>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  )}
                </TabPanel>
                <TabPanel value={value} index={1}>
                  {isLoading ? (
                    <CircularProgress
                      className={classes.loading}
                      color="primary"
                    />
                  ) : (
                    <div className="row">
                      {feedActivity.map((activity, index) => {
                        return (
                          <div className="col-12 mt-3">
                            <div className="post-creator">
                              <div className="like-profile-image">
                                <Avatar
                                  src={
                                    s3baseUrl +
                                    activity.user_info_action_by?.profile_image
                                  }
                                  alt="photoURL"
                                >
                                  {activity.user_info_action_by?.name[0]}
                                </Avatar>
                                <div className="tiny-like-icon">
                                  <img src={communityHeart} alt="" />
                                </div>
                              </div>
                              <div className="creator-name ps-2 pt-1">
                                <h3>{activity.user_info_action_by?.name}</h3>
                                <span className="date-color">
                                  {get_date_with_user_time_zone(
                                    activity?.createdAt,
                                    "DD MMM YYYY [at] hh:mm A",
                                    settings.userInfo.time_zone,
                                    settings.adminTimeZone
                                  )}
                                </span>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                      {totalPages > pageNumber ? (
                        <div className="col-12 text-center">
                          <span
                            onClick={loadMoreData}
                            className="load-more-text"
                            ref={lastBookElementRef}
                          >
                            {isLoadingMore ? "Loading..." : "Load More"}
                          </span>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  )}
                </TabPanel>
                <TabPanel value={value} index={2}>
                  {isLoading ? (
                    <CircularProgress
                      className={classes.loading}
                      color="primary"
                    />
                  ) : (
                    <div className="row">
                      {feedActivity.map((activity, index) => {
                        return (
                          <div className="col-12 mt-3">
                            <div className="post-creator">
                              <div className="like-profile-image">
                                <Avatar
                                  src={
                                    s3baseUrl +
                                    activity.user_info_action_by?.profile_image
                                  }
                                  alt="photoURL"
                                >
                                  {activity.user_info_action_by?.name[0]}
                                </Avatar>
                                <div className="tiny-like-icon">
                                  <img src={communityHandScreenshot} alt="" />
                                </div>
                              </div>
                              <div className="creator-name ps-2 pt-1">
                                <h3>{activity.user_info_action_by?.name}</h3>
                                <span className="date-color">
                                  {get_date_with_user_time_zone(
                                    activity?.createdAt,
                                    "DD MMM YYYY [at] hh:mm A",
                                    settings.userInfo.time_zone,
                                    settings.adminTimeZone
                                  )}
                                </span>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                      {totalPages > pageNumber ? (
                        <div className="col-12 text-center">
                          <span
                            onClick={loadMoreData}
                            className="load-more-text"
                            ref={lastBookElementRef}
                          >
                            {isLoadingMore ? "Loading..." : "Load More"}
                          </span>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  )}
                </TabPanel>
              </Box>
            </div>
          </Box>
        </div>
      </Modal>
    </div>
  );
}
