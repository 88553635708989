import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { IconButton, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import {
  memberQuestionList,
  memberWheelOfLifeQuestionList,
} from "src/DAL/member/Member";
import { useEffect } from "react";
import { useState } from "react";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { htmlDecode } from "src/utils/convertHtml";
import { QuestionSurvey } from "./WheelOfLife";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SearchNotFound from "src/components/SearchNotFound";
import RecordNotFound from "src/components/RecordNotFound";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"span"} variant={"body2"}>
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function WheelOfLife({}) {
  const [value, setValue] = React.useState(0);
  const params = useParams();
  const [questions, setQuestions] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const questionData = async () => {
    const result = await memberWheelOfLifeQuestionList(params.id);
    if (result.code == 200) {
      //console.log(result);
      setQuestions(result.questions);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    questionData();
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  //console.log(questions, "questions");

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <IconButton
            className="back-screen-button mb-4"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon />
          </IconButton>
        </div>
        {questions.length > 0 ? (
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                // allowScrollButtonsMobile="auto"
                aria-label="scrollable auto tabs example"
              >
                {questions &&
                  questions.map((question, index) => (
                    <Tab
                      key={index}
                      label={htmlDecode(question.sale_page.sale_page_title)}
                      {...a11yProps(0)}
                    />
                  ))}
              </Tabs>
            </Box>

            {questions.length &&
              questions.map((question, sectionIndex) => (
                <TabPanel value={value} index={sectionIndex} key={sectionIndex}>
                  <QuestionSurvey question={question} />
                </TabPanel>
              ))}
          </Box>
        ) : (
          <RecordNotFound title="Questions" />
        )}
      </div>
    </div>
  );
}
