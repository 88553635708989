import React from "react";
import { useNavigate } from "react-router-dom";
import SideImageCard from "src/components/GeneralComponents/SideImageCard";
import RecordNotFound from "../GeneralComponents/RecordNotFound";

export default function LessonsCardTab({
  lessons,
  programLockStatus,
  programSlug,
}) {
  const navigate = useNavigate();
  const handleClick = (lesson) => {
    // if (programLockStatus === false) {
    //   // navigate(`/lessons/${lesson.lesson_slug}`);
    // }
    navigate(`/lessons/${lesson.lesson_slug}`, {
      state: lesson,
    });
  };
  return (
    <>
      {lessons.length > 0 ? (
        <SideImageCard data={lessons} handleClick={handleClick} />
      ) : (
        <RecordNotFound title="Lessons Not Found" />
      )}
    </>
  );
}
