import { invokeApi } from "../../bl_libs/invokeApi";

export const update_reminder_time_api = async (data) => {
  const requestObj = {
    path: `api/consultant/reminder_time/update`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const update_reminder_time_performanceapi = async (data) => {
  const requestObj = {
    path: `api/consultant/streak_reminder_time/update`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const perfomance_add_api = async (data) => {
  const requestObj = {
    path: `api/dynamite_streak/add_streak`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const update_all_data = async (data) => {
  const requestObj = {
    path: `api/daily_dynamite_tracker/add_daily_dynamite`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const get_intention_dates = async (date) => {
  const requestObj = {
    path: `api/daily_dynamite_tracker/get_daily_dynamite_intentions?date=${date}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const get_intention_dates_spacific = async (deleagte_id, date) => {
  const requestObj = {
    path: `api/daily_dynamite_tracker/daily_dynamite_intention/${deleagte_id}?date=${date}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const pastActivitiesApi = async (date) => {
  const requestObj = {
    path: `api/daily_dynamite_tracker/get_daily_dynamite_list`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const deleteDiaryApi = async (id) => {
  const requestObj = {
    path: `api/daily_dynamite_tracker/delete_daily_dynamite/${id} `,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const get_performance_data = async () => {
  const requestObj = {
    path: `api/dynamite_streak/get_dynamite_streak`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const get_past_activities_data = async (loadMore, data) => {
  const requestObj = {
    path: loadMore,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const get_past_activities_data_accoutability = async (
  loadMore,
  data
) => {
  const requestObj = {
    path: loadMore,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const sale_performance_api = async (page, limit, data) => {
  const requestObj = {
    path: `api/consultant/list_sales_performance?page=${page}&limit=${limit}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const get_detail_data = async (data) => {
  const requestObj = {
    path: "api/daily_dynamite_tracker/list_and_performance_stats_infor_for_delegate",
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const move_to_tomorrow = async (data) => {
  const requestObj = {
    path: `api/daily_dynamite_tracker/tracker_move_to_tomorrow`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const GetTrackerApi = async (data) => {
  const requestObj = {
    path: `api/default_setting/get/accountability_tracker_setting`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const updateDynamiteDiaryApi = async (id, data) => {
  const requestObj = {
    path: `api/daily_dynamite_tracker/update_daily_tracker/${id}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const getMonthalyReport = async (data) => {
  const requestObj = {
    path: `api/daily_dynamite_tracker/delegate_performance/stats`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const getMonthalyReportSpacific = async (data) => {
  const requestObj = {
    path: `api/daily_dynamite_tracker/delegate_performance/stats_with_delegate_id`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const getMonthalyAccountabilityReport = async (data) => {
  const requestObj = {
    path: `api/daily_dynamite_tracker/daily_dynamites_for_delegate`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const getMonthalyReportSales = async (id, start_date, end_date) => {
  const requestObj = {
    path: `api/consultant/delegate/commission_stats/${id}?start_date=${start_date}&end_date=${end_date}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const delegate_commission_stats_api = async (data, id) => {
  const requestObj = {
    path: `api/consultant/commission_stats/${id}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const updatePerformanceApi = async (id, data) => {
  const requestObj = {
    path: `api/dynamite_streak/update_streak/${id}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const pages_and_delegates_list_api = async (filterName, delegate_id) => {
  const requestObj = {
    path: `api/consultant/get_pages_for_delegate/sales/${delegate_id}?search_text=${
      filterName ? filterName : ""
    }`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
