import { CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { s3baseUrl } from "src/config/config";
// import {
//   get_nav_item_details_api,
//   update_nav_items_access,
// } from "src/DAL/Menu/Menu";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import DoneIcon from "@mui/icons-material/Done";
import { Icon } from "@iconify/react";
import RecordNotFound from "src/components/RecordNotFound";
import ActiveLastBreadcrumb from "src/components/BreadCrums";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
let navItemsArray = [
  {
    icon: "upload/b2288c86-41da-44fa-aef5-b378dbfacffc.png",
    is_link: false,
    is_open_new_tab: false,
    link: "",
    order: 1,
    path: "/dashboard",
    title: "Mission Control",
    value: "mission_control",
    _id: "867a0259-dafe-40c0-82e4-55bc1d4e548f",
  },
  {
    icon: "upload/4272a0f1-af99-4053-b363-9268c56c922f.png",
    is_link: false,
    is_open_new_tab: false,
    link: "",
    order: 2,
    path: "/thesource",
    title: "The Cosmos",
    value: "the_cosmos",
    _id: "433f8aaf-59cf-46f1-a1d9-ac31d645946d",
  },
  {
    icon: "upload/129f73bc-fb74-4550-a5ab-d28183db457c.png",
    is_link: false,
    is_open_new_tab: false,
    link: "",
    order: 3,
    path: "/chat",
    title: "Chat",
    value: "chat",
    _id: "34ac8703-da69-4282-9f62-f5295cbcac8d",
  },
  {
    icon: "upload/ad0f8658-298a-4a3b-8d1e-c6b7cb4b0965.png",
    is_link: false,
    is_open_new_tab: false,
    link: "",
    order: 4,
    path: "/whatsapp-chat",
    title: "Whatsapp Chat",
    value: "whatsapp_chat",
    _id: "3fd672ca-06bf-4c59-b66a-44880c8effca",
  },
  {
    icon: "upload/57530b0c-a667-4513-989e-b9155e4cd602.png",
    is_link: false,
    is_open_new_tab: false,
    link: "",
    order: 5,
    path: "",
    title: "Members",
    value: "members",
    _id: "d2ff9f8b-20a8-4646-a08b-cbdfd931fb54",
    child_options: [
      {
        icon: "upload/2989ff79-adab-49c2-852b-9f2934a96973.png",
        is_link: false,
        is_open_new_tab: false,
        link: "",
        order: 1,
        path: "/members",
        title: "Members",
        value: "members",
        _id: "5f063d7b-640b-4088-a821-0b77a08b18c7",
      },
      {
        icon: "upload/7a48c43f-f6c2-4e0e-b8ce-9a5d97ae7c2c.png",
        is_link: false,
        is_open_new_tab: false,
        link: "",
        order: 2,
        path: "/nurture-members",
        title: "Nurture Members",
        value: "nurture_members",
        _id: "06cafbd4-cee7-4723-acc5-ce265d01a148",
      },
      {
        icon: "upload/383dcf15-424e-4687-980f-6fb753a25b26.png",
        is_link: false,
        is_open_new_tab: false,
        link: "",
        order: 3,
        path: "/all-members",
        title: "All Member List",
        value: "all_member_list",
        _id: "9daa2af3-a55d-40e4-9821-c3fe4199d5ab",
      },
    ],
  },
  {
    icon: "upload/84f5187c-bedd-4a67-b7ff-d59663f5aa9f.png",
    is_link: false,
    is_open_new_tab: false,
    link: "",
    order: 6,
    title: "Payments",
    value: "payments",
    _id: "455ccc75-c8d3-41b3-856b-5a133a185c5b",
    child_options: [
      {
        icon: "upload/85e97aed-659a-474b-b4ea-75eba71f04ee.png",
        is_link: false,
        is_open_new_tab: false,
        link: "",
        order: 1,
        path: "/transaction-list",
        title: "Transactions",
        value: "transactions",
        _id: "558ec2ba-49ca-4740-bb81-a0a29296d69d",
      },
      {
        icon: "upload/30ce126b-427f-4448-bec1-3444a3eab072.png",
        is_link: false,
        is_open_new_tab: false,
        link: "",
        order: 2,
        path: "/commission-detail",
        title: "Commission Detail",
        value: "commission_detail",
        _id: "443a08f7-f6e0-4fa7-81fb-5236e6e970de",
      },
      {
        icon: "upload/56580ea5-b5bf-460a-9d16-d5841d82d20b.png",
        is_link: false,
        is_open_new_tab: false,
        link: "",
        order: 3,
        path: "/payment-request",
        title: "Payment Requests",
        value: "payment_request",
        _id: "84a645c5-7979-49bc-9ca6-a4214336ec77",
      },
    ],
  },
  {
    icon: "upload/d629809a-b965-499f-9597-66b1bd0b7619.png",
    is_link: false,
    is_open_new_tab: false,
    link: "",
    order: 7,
    path: "/website-pages",
    title: "Templates",
    value: "templates",
    _id: "0a3fc4be-fac3-46f2-a887-ae1e08f9d351",
  },
  {
    icon: "upload/ba2b69ed-d122-4917-a9bc-197b8c4947cc.png",
    is_link: false,
    is_open_new_tab: false,
    link: "",
    order: 8,
    path: "/website-links",
    title: "Links",
    value: "links",
    _id: "f6f13dcc-c828-4ee8-81e4-85b9a21f6308",
  },
  {
    icon: "upload/d9ec56ad-d9d2-43f2-ad0e-ab81e9d46f8c.png",
    is_link: false,
    is_open_new_tab: false,
    link: "",
    order: 9,
    path: "/sourcefeeds",
    title: "The Source Feed",
    value: "the_source_feed",
    _id: "34e7b7db-b8c5-4d55-98fc-700d3c6cd357",
  },
  {
    icon: "upload/008ebedc-8f39-4b1b-95b3-e0fb119d20cf.png",
    is_link: false,
    is_open_new_tab: false,
    link: "",
    order: 10,
    path: "/all-sourcefeeds",
    title: "All Source Feed",
    value: "all_source_feed",
    _id: "cbd826ec-9082-44c4-a94c-4af0bf98eeb9",
  },
  {
    icon: "upload/41cba57a-c91d-4311-abf0-16134fd81af4.png",
    is_link: false,
    is_open_new_tab: false,
    link: "",
    order: 11,
    path: "/scheduled-feeds",
    title: "Scheduled Feeds",
    value: "scheduled_feeds",
    _id: "214ab401-1f91-45c6-9250-42df98cd20ee",
  },
  {
    icon: "upload/d44dfe27-1be6-455e-8b12-7c7a9f372f53.png",
    is_link: false,
    is_open_new_tab: false,
    link: "",
    order: 12,
    path: "/member-answers-list",
    title: "Member Answers List",
    value: "member_answers_list",
    _id: "c843ea03-df9e-4916-b59f-7357e79b90cb",
  },
  {
    icon: "upload/b844ed4d-e54d-4989-83c9-0632e8028c39.png",
    is_link: false,
    is_open_new_tab: false,
    link: "",
    order: 13,
    path: "/subscription-list",
    title: "Subscription List",
    value: "subscription_list",
    _id: "73e12b1a-5c26-4092-ac3c-571fd8738f67",
  },
  {
    icon: "upload/be1bd5e7-a07d-4888-98af-aef04f3ec45e.png",
    is_link: false,
    is_open_new_tab: false,
    link: "",
    order: 14,
    path: "delegate-training",
    title: "Delegate Training",
    value: "delegate_training",
    matches: ["/lessons", "/delegate-training", "/lessons-recordings"],
    _id: "50f33585-a162-43f2-9248-5ae844488772",
  },
  {
    icon: "upload/efdec7f8-649f-4546-96fb-23b05d0b0908.png",
    is_link: false,
    is_open_new_tab: false,
    link: "",
    order: 15,
    path: "",
    title: "Appointments",
    value: "appointment",
    _id: "3bc7d829-9b1b-4cba-b657-39485b52f625",
    child_options: [
      {
        icon: "upload/93c5d1db-449a-476b-bf5d-bb934b3b170a.png",
        is_link: false,
        is_open_new_tab: false,
        link: "",
        order: 1,
        path: "/booking",
        title: "Bookings",
        value: "bookings",
        _id: "68cf2ca6-010a-4e51-94ee-26231efe87e5",
      },
      {
        icon: "upload/ab69b5a7-ef25-4619-b079-9c80996a8ef3.png",
        is_link: false,
        is_open_new_tab: false,
        link: "",
        order: 2,
        path: "/appointment",
        title: "Appointments Configuration",
        value: "schedule_appointment",
        _id: "6fbc5f59-7886-4d6d-b936-14636b4bdff9",
      },
    ],
  },
  {
    icon: "upload/9faeb592-9c3e-404c-a530-ed01eb566394.png",
    is_link: false,
    is_open_new_tab: false,
    link: "",
    order: 16,
    path: "",
    title: "90 Day Plan",
    value: "90_day_plan",
    _id: "08c114e9-3ce1-40c7-808f-0ac556f3b93c",
    child_options: [
      {
        icon: "upload/5e89ebeb-e4db-45f6-a490-c212b2b769b1.png",
        is_link: false,
        is_open_new_tab: false,
        link: "",
        order: 1,
        path: "/90-day-plan",
        title: "90 Day Plan",
        value: "90_day_plan",
        _id: "9f051351-3edc-481c-ac23-85e09f4e2e11",
      },
      {
        icon: "upload/37fb0605-0f82-4893-bbe3-b2ca2bac88b4.png",
        is_link: false,
        is_open_new_tab: false,
        link: "",
        order: 2,
        path: "/90-day-tracker",
        title: "90 Day Tracker",
        value: "90_day_tracker",
        _id: "d2a962e6-b079-4eb3-8f67-c467d85323e6",
      },
    ],
  },
  {
    icon: "upload/b9b809a4-0fa8-4b55-996c-f7a351c9d6c9.png",
    is_link: false,
    is_open_new_tab: false,
    link: "",
    order: 17,
    title: "Calendar",
    value: "calendar",
    _id: "79fe1cf4-6cd7-42d0-a855-3fcf5fec045a",
    child_options: [
      {
        icon: "upload/665ec30c-daf8-494e-82f1-23d359b4b462.png",
        is_link: false,
        is_open_new_tab: false,
        link: "",
        order: 1,
        path: "/groups",
        title: "Groups",
        value: "groups",
        _id: "62c5554e-72cb-4876-98b5-889ecb5e8db7",
      },
      {
        icon: "upload/8142cd95-2d6e-4a19-89fe-1be31d8587f1.png",
        is_link: false,
        is_open_new_tab: false,
        link: "",
        order: 2,
        path: "/calendar-events",
        title: "Calendar Events",
        value: "calendar_events",
        _id: "38bdd187-f583-4b46-bbb9-20bd784bf241",
      },
    ],
  },
  {
    icon: "upload/b631bf79-d862-48fe-bffa-68d6354d44bb.png",
    is_link: false,
    is_open_new_tab: false,
    link: "",
    order: 18,
    path: "/support-ticket",
    title: "Support Ticket",
    value: "support_ticket",
    _id: "83b8897f-5ef3-434d-bc5e-76142371f5e7",
  },
  {
    icon: "upload/f6fd216b-42d6-4194-86fa-9e9748dbe93c.png",
    is_link: false,
    is_open_new_tab: false,
    link: "",
    order: 19,
    path: "/internal-tickets",
    title: "Internal Tickets",
    value: "internal-tickets",
    _id: "c47fa8a3-9f0d-469c-b78c-9f841c251a91",
  },
  {
    icon: "upload/2dca95c8-be0a-4235-b520-9ef035a5e15d.png",
    is_link: false,
    is_open_new_tab: false,
    link: "",
    order: 20,
    path: "/live-events",
    title: "Portals",
    value: "portals",
    matches: ["/live-events"],
    _id: "c4406822-391b-4320-892f-ceb760f157c0",
  },
  {
    icon: "upload/c1aecd77-e829-49cd-b271-3b80dfce8348.png",
    is_link: false,
    is_open_new_tab: false,
    link: "",
    order: 21,
    path: "/my-events",
    title: "My Portals",
    value: "my_portals",
    matches: [
      "/my-events",
      "/dynamite-event-categories",
      "/dynamite-event-videos",
      "/dynamite-events",
    ],
    _id: "2f8afe1f-58b9-4415-93a6-d2a65b3cfa5e",
  },
  {
    icon: "upload/cdd7b341-f8e2-4ed1-8e0d-5842d0c9dac2.png",
    is_link: false,
    is_open_new_tab: false,
    link: "",
    order: 22,
    path: "",
    title: "Delegate Pods",
    value: "delegate_pods",
    _id: "17fc7008-54ea-4917-b74a-05256563398e",
    child_options: [
      {
        icon: "upload/322459e3-d120-4500-9289-3ac7f230af8b.png",
        is_link: false,
        is_open_new_tab: false,
        link: "",
        order: 1,
        path: "/source-pods",
        title: "Source Pods",
        value: "source_pods",
        _id: "ee600ffb-0afa-449b-9b76-99761c01ed3d",
      },
      {
        icon: "upload/d4a0ab69-1992-4cb7-82b4-c45a1f37fbd1.png",
        is_link: false,
        is_open_new_tab: false,
        link: "",
        order: 2,
        path: "/book-call-pods",
        title: "Book Call Pods",
        value: "book_call_pods",
        _id: "657bbdbb-661f-4ebb-8373-9c0c34485fb7",
      },
    ],
  },
  {
    icon: "upload/a9dc3a2e-6a79-48ea-93c1-83fd6b30d378.png",
    is_link: false,
    is_open_new_tab: false,
    link: "",
    order: 23,
    path: "/dynamite-pods",
    title: "Dynamite Pods",
    value: "dynamite_pods",
    _id: "14f2dd46-0399-43d7-b17d-cae069a6244a",
  },
  {
    icon: "upload/573f441d-a97c-41b3-a89b-7bcd1198154e.png",
    is_link: false,
    is_open_new_tab: false,
    link: "",
    order: 24,
    path: "/delegate-events",
    title: "Delegate Events",
    value: "delegate_events",
    _id: "d2ff9f8b-20a8-4646-a08b-cbdfd931f454",
  },
  {
    icon: "upload/7582a545-f7f3-4f4a-aff3-7616066085a1.png",
    is_link: false,
    is_open_new_tab: false,
    link: "",
    order: 25,
    path: "/recordings",
    title: "Your Recordings",
    value: "your_recordings",
    _id: "25a25c8b-b9c0-4f7d-b77d-074c97c55195",
  },
  {
    icon: "upload/eb7ef224-703c-4364-9b75-ecde20bef812.png",
    is_link: false,
    is_open_new_tab: false,
    link: "",
    order: 26,
    path: "vault",
    title: "Your Vault",
    value: "your_vault",
    _id: "bc7d4e01-05aa-49a6-9bb6-09b90e44908d",
  },
  {
    icon: "upload/02ae9787-408a-4877-ab12-df2052dfca04.png",
    is_link: false,
    is_open_new_tab: false,
    link: "",
    order: 27,
    path: "/assessment",
    title: "Attitude Assessment",
    value: "attitude_assessment",
    _id: "d855c0fa-6dd4-45f9-9fc9-f1bed80e2192",
  },
  {
    icon: "upload/a06f3061-8bb1-4266-90fb-8190c69193ac.png",
    is_link: false,
    is_open_new_tab: false,
    link: "",
    order: 28,
    path: "/progress",
    title: "Progress",
    value: "progress",
    _id: "93a6e107-c620-4ba4-81d9-64660b4fb4db",
  },
  {
    icon: "upload/34101efa-b1ac-4c65-aa00-6456a70fa810.png",
    is_link: false,
    is_open_new_tab: false,
    link: "",
    order: 29,
    title: "Certification",
    value: "certification",
    _id: "9ebb9563-c76c-42d0-b2e5-de7a88bbf3c9",
    child_options: [
      {
        icon: "upload/81324d23-e945-49da-b0c5-f80956ffbdba.png",
        is_link: false,
        is_open_new_tab: false,
        link: "",
        order: 1,
        path: "delegate-question",
        title: "Assessments / Study",
        value: "assessment_study",
        _id: "3bbea0ca-7d66-4225-9950-9ecec741637d",
      },
    ],
  },
  {
    icon: "upload/55aae5ce-d554-4ae0-b172-e15564bdc8ab.png",
    is_link: false,
    is_open_new_tab: false,
    link: "",
    order: 30,
    title: "Member Goal Statement",
    value: "member_goal_statement",
    _id: "75176cef-cff2-4741-8139-67ce23bdca6c",
    child_options: [
      {
        icon: "upload/6eca760d-9f31-421b-8b7a-5fcd4d1c9721.png",
        is_link: false,
        is_open_new_tab: false,
        link: "",
        order: 1,
        path: "/member-completed-goalstatements",
        title: "Complete",
        value: "complete",
        _id: "f18f9630-2dc1-4e42-a498-0a0e7e9407be",
      },
      {
        icon: "upload/b5e603d5-d6a6-4eae-bd62-593e7e6ed254.png",
        is_link: false,
        is_open_new_tab: false,
        link: "",
        order: 2,
        path: "/member-incomplete-goalstatements/",
        title: "Incomplete",
        value: "incomplete",
        _id: "6777364d-4014-47d4-abd2-f488fa1c58bc",
      },
      {
        icon: "upload/14e997f0-f6e0-4cb8-8c91-03ffd2901178.png",
        is_link: false,
        is_open_new_tab: false,
        link: "",
        order: 3,
        path: "/member-responded-goalstatements",
        title: "Responded",
        value: "responded",
        _id: "46eb45b0-1e4a-4903-b3a2-1f0823d990d7",
      },
    ],
  },
  {
    icon: "upload/a6b9e380-52ea-4ebf-891a-051dff725feb.png",
    is_link: false,
    is_open_new_tab: false,
    link: "",
    order: 31,
    title: "Self Image",
    value: "self_image",
    _id: "c31a3cf2-ff7a-4957-9546-9c104f68d96c",
    child_options: [
      {
        icon: "upload/96565b57-ed21-483b-b476-a580894f3f99.png",
        is_link: false,
        is_open_new_tab: false,
        link: "",
        order: 1,
        path: "/self-image-completed",
        title: "Completed",
        value: "completed",
        _id: "1dbc99eb-1a68-40dc-8d60-102c23a026c9",
      },
      {
        icon: "upload/7bc125d2-7cad-4d03-81c6-a408d9816b55.png",
        is_link: false,
        is_open_new_tab: false,
        link: "",
        order: 2,
        path: "/self-image-incompleted",
        title: "Incompleted",
        value: "incompleted",
        _id: "45118873-02a7-4bc7-a181-3d501f7ad677",
      },
      {
        icon: "upload/62f66db4-3465-4985-8cb6-1c116d0e483b.png",
        is_link: false,
        is_open_new_tab: false,
        link: "",
        order: 3,
        path: "/self-image-responded",
        title: "Responded",
        value: "responded",
        _id: "7ad62d39-8cea-4b72-8394-d270a12fe850",
      },
    ],
  },
  {
    icon: "upload/ec30ff05-bebe-4649-ba57-1591b585b182.png",
    is_link: false,
    is_open_new_tab: false,
    link: "",
    order: 32,
    path: "",
    title: "Help",
    value: "support",
    _id: "c351eb22-6d51-4187-9111-f05872a6d4b8",
    child_options: [
      {
        icon: "upload/c6e7b8e2-f401-4aef-ba76-22249cbd5700.png",
        is_link: false,
        is_open_new_tab: false,
        link: "",
        order: 1,
        path: "/contact-support",
        title: "Contact Support",
        value: "contact_support",
        _id: "26ddb8b0-31d9-4154-b3a9-81619fd1b49c",
      },
      {
        icon: "upload/8ad2087c-4753-468a-b8bf-bf2f1962e642.png",
        is_link: false,
        is_open_new_tab: false,
        link: "",
        order: 2,
        path: "/digital-asset",
        title: "Digital Assets",
        value: "digital_assets",
        _id: "96eadc59-d4a6-44f3-b99e-14a87136de90",
      },
      {
        icon: "upload/a24243ec-67b8-44e1-993c-ed995c5b2120.png",
        is_link: false,
        is_open_new_tab: false,
        link: "",
        order: 3,
        path: "/tutorials",
        title: "Help Tech",
        value: "help_tech",
        _id: "50dc0ed7-7b87-4955-b1ad-13cb8a2fd52b",
      },
    ],
  },
  {
    icon: "upload/2a2641ad-6550-40c2-8397-41a4779eef54.png",
    is_link: false,
    is_open_new_tab: false,
    link: "",
    order: 33,
    path: "/tracker",
    title: "Daily Dynamite Accountabalility Tracker",
    value: "daily_dynamite_accountabalility_tracker",
    _id: "aadbd7a3-91c1-40dd-bf24-532fc288d265",
  },
  {
    icon: "upload/3f885b78-7418-4cc6-a1b4-881e04dcc61b.png",
    is_link: false,
    is_open_new_tab: false,
    link: "",
    order: 34,
    path: "/performance-streak",
    title: "Daily Streak Performance",
    value: "daily_streak_performance",
    _id: "aadbd7a3-91c1-40dd-bf24-532fc288d2659",
  },
  {
    icon: "upload/caec85a1-7434-4a78-a03d-e81001e5dea4.png",
    is_link: false,
    is_open_new_tab: false,
    order: 35,
    path: "/accountability-tracker-report",
    title: "Delegate Report",
    value: "accountability_tracker_report",
    _id: "aadbd7a3-91c1-40dd-bf24-532fc288d265555",
  },
  {
    icon: "upload/986315b0-9fc7-4e12-b628-70989360f585.png",
    is_link: false,
    is_open_new_tab: false,
    order: 36,
    path: "/performance-stats",
    title: "Monthly Report",
    value: "performance_stats",
    _id: "aadbd7a3-91c1-40dd-bf24-8987688566hhh",
  },
  {
    icon: "upload/986315b0-9fc7-4e12-b628-70989360f585.png",
    is_link: false,
    is_open_new_tab: false,
    order: 37,
    path: "/welcome-reminder-setting",
    title: "Welcome Reminder Setting",
    value: "welcome_reminder_settings",
    _id: "aadbd7a3-91c1-40dd-bf24-898768876",
  },
  {
    icon: "upload/986315b0-9fc7-4e12-b628-70989360f585.png",
    is_link: false,
    is_open_new_tab: false,
    order: 38,
    path: "/broadcast-chat",
    title: "Broadcast Chat",
    value: "broadcast_chats",
    _id: "aadbd7a3-91c1-40dd-bf24-898768876hhh",
  },
  {
    icon: "upload/986315b0-9fc7-4e12-b628-70989360f585.png",
    is_link: false,
    is_open_new_tab: false,
    order: 39,
    path: "/quarter-questions",
    title: "Quarter Questions",
    value: "quarter_questions",
    _id: "aadbd7a3-91c1-40dd-bf24-7846756776677887",
  },
  {
    icon: "upload/5ef619d0-f0c3-48ee-8dee-ee9cb0ec5ed1.png",
    is_link: false,
    is_open_new_tab: false,
    order: 40,
    path: "/sales-team",
    title: "Sales Team",
    value: "/sales-team",
    _id: "aadbd7a3-91c1-499dd-bf24-7846756776677887",
  },
];

const ManageNavItemsAccess = ({ type, title, navigate }) => {
  const classes = useStyles();
  const { user_id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [userData, setUserData] = useState({});
  const [navItems, setNavItems] = useState(navItemsArray);
  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [selected, setSelected] = useState([]);
  const [searchInput, setSearchInput] = useState("");

  const handleSelect = (value, child_option) => {
    let new_value = { item_id: value._id };
    if (child_option) {
      new_value.child_options = [{ item_id: child_option._id }];
    }

    let find = selected.find((item) => item.item_id == value._id);
    if (find) {
      if (!child_option && value.child_options) {
        setSelected((old) => old.filter((item) => item.item_id !== value._id));
      } else {
        if (find.child_options) {
          let find_child = find.child_options.find(
            (find_c) => find_c.item_id == child_option._id
          );
          let child_options = find.child_options;
          if (find_child) {
            child_options = find.child_options.filter(
              (remove) => remove.item_id !== child_option._id
            );
          } else {
            child_options = [...child_options, { item_id: child_option._id }];
          }
          find.child_options = child_options;
          if (find.child_options.length > 0) {
            setSelected((old) =>
              old.map((item) => {
                if (item.item_id == find.item_id) {
                  return find;
                }
                return item;
              })
            );
          } else {
            setSelected((old) =>
              old.filter((item) => item.item_id !== value._id)
            );
          }
        } else {
          setSelected((old) =>
            old.filter((item) => item.item_id !== value._id)
          );
        }
      }
    } else {
      if (!child_option && value.child_options) {
        let child_options = value.child_options.map((c_option) => {
          return {
            item_id: c_option._id,
          };
        });
        new_value.child_options = child_options;
      }
      setSelected([...selected, new_value]);
    }
  };

  // const handleSubmit = async () => {
  //   setIsLoadingButton(true);
  //   let postData = {
  //     nav_items: selected,
  //     type: type,
  //   };
  //   const result = await update_nav_items_access(user_id, postData);
  //   if (result.code == 200) {
  //     setIsLoadingButton(false);
  //     enqueueSnackbar(result.message, { variant: "success" });
  //   } else {
  //     setIsLoadingButton(false);
  //     enqueueSnackbar(result.message, { variant: "error" });
  //   }
  // };

  const handleToggle = (index) => {
    setNavItems((old) =>
      old.map((item, i) => {
        if (i == index) {
          item.is_expanded = !item.is_expanded;
        }
        return item;
      })
    );
  };

  const checkAllItemsExist = (data1, data2) => {
    let is_selected_all = true;
    data2.forEach((item) => {
      let find_p = data1.find((p) => p.item_id == item._id);
      if (!find_p) {
        is_selected_all = false;
      } else {
        if (find_p.child_options?.length > 0) {
          let find_c = item.child_options.every((co) =>
            find_p.child_options.some((c) => co._id === c.item_id)
          );
          if (!find_c) {
            is_selected_all = false;
          }
        }
      }
    });
    return is_selected_all;
  };
  // const GetNavList = async () => {
  //   setIsLoading(true);
  //   let postData = {
  //     type: type,
  //   };
  //   const result = await get_nav_item_details_api(user_id, postData);
  //   if (result.code == 200) {
  //     setSelected(result.assigned_nav_items);
  //     setNavItems(result.default_setting_nav_items);
  //     setUserData(result.user_details);
  //     setIsLoading(false);
  //   } else {
  //     setIsLoading(false);
  //     enqueueSnackbar(result.message, { variant: "error" });
  //   }
  // };

  const getNavItemsList = (query) => {
    let array = navItems;
    if (query) {
      const _nav_list = array
        .map((data) => {
          const is_main =
            data.title.toLowerCase().indexOf(query.toLowerCase()) !== -1;
          const match_child = data.child_options?.filter(
            (item) =>
              item.title.toLowerCase().indexOf(query.toLowerCase()) !== -1
          );

          if (is_main) {
            return data;
          } else if (match_child?.length > 0) {
            return {
              ...data,
              child_options: match_child,
            };
          }
          return null;
        })
        .filter(Boolean);
      return _nav_list;
    }

    return array;
  };

  const handleCheckAll = (value) => {
    let updated_array = [];
    if (value) {
      updated_array = navItems.map((item) => {
        let data_object = { item_id: item._id };
        if (item.child_options?.length > 0) {
          let child_options = item.child_options.map((child) => {
            return {
              item_id: child._id,
            };
          });
          data_object.child_options = child_options;
        }
        return data_object;
      });
    }
    setSelected(updated_array);
  };

  // useEffect(() => {
  //   GetNavList();
  // }, []);

  useEffect(() => {
    setNavItems((old) =>
      old.map((item) => {
        return { ...item, is_expanded: true };
      })
    );
  }, [searchInput]);

  useEffect(() => {
    const is_checked = checkAllItemsExist(selected, navItems);
    console.log(is_checked, "is_checkedis_checked");
    console.log(selected, "selectedselected");
    setIsCheckedAll(is_checked);
  }, [selected, navItems]);

  let breadCrumbMenu = [
    {
      title: "User Name",
      navigation: navigate,
      active: false,
    },
    {
      title: "Manage NavItem Access",

      active: true,
    },
  ];

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      <div className="row mobile-margin display-flex  mb-2">
        <div className="col-12 mb-3">
          <span>
            <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
          </span>
        </div>
        <div className="col-12">
          <h2>Manage NavItems Access</h2>
        </div>
        <div className="col-12 col-md-9 mt-3">
          <div
            className="select-all-menus"
            onClick={() => handleCheckAll(!isCheckedAll)}
          >
            <div
              className={`select-menu-checkbox ps-3 mt-1 ${
                isCheckedAll ? "selected" : ""
              }`}
            >
              {isCheckedAll && (
                <span className="select-menu-tick">
                  <DoneIcon />
                </span>
              )}
            </div>
            <div className="ms-2">Select All</div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="menus-search-box">
            <Icon
              fontSize={20}
              className="menus-search-icon"
              icon="akar-icons:search"
            />
            <input
              className="menus-search-input"
              type="text"
              placeholder="Search"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
          </div>
        </div>
      </div>

      {getNavItemsList(searchInput).length > 0 ? (
        getNavItemsList(searchInput).map((item, index) => {
          let is_checked = selected.find(
            (option) => option.item_id == item._id
          );
          return (
            <div className="card mt-2">
              <div className="main row ps-3 align-items-center">
                <div className="col-1">
                  <div
                    className={`select-menu-checkbox m-auto ${
                      is_checked ? "selected" : ""
                    }`}
                    onClick={() => {
                      handleSelect(item);
                    }}
                  >
                    {is_checked && (
                      <span className="select-menu-tick">
                        <DoneIcon />
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-7 col-md-3 mt-3 mb-3">
                  <div className="static-textfield">
                    {item?.title && (
                      <div className="static-textfield-label px-1">Title</div>
                    )}
                    <div className="field-value">
                      {item?.title ? (
                        item?.title
                      ) : (
                        <span className="disabled_label">Title</span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="d-none d-lg-block col-lg-3 mt-3 mb-3 ps-0">
                  {(!item.child_options || item.child_options?.length < 1) && (
                    <div className="static-textfield">
                      <div className="static-textfield-label px-1">Is Link</div>
                      <div className="field-value">
                        {item?.is_link ? "Yes" : "No"}
                      </div>
                      <div className="dropdown-arrow">
                        <ArrowDropDownIcon />
                      </div>
                    </div>
                  )}
                </div>
                <div className="d-none d-lg-block col-lg-3 mt-3 mb-3 ps-0">
                  {(!item.child_options || item.child_options?.length < 1) && (
                    <div className="static-textfield">
                      <div className="static-textfield-label px-1">
                        Is Open New Tab
                      </div>
                      <div className="field-value">
                        {item?.is_open_new_tab ? "Yes" : "No"}
                      </div>
                      <div className="dropdown-arrow">
                        <ArrowDropDownIcon />
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-2 col-lg-1 mb-3 navitems">
                  {item?.icon && (
                    <div className="preview">
                      <img
                        src={s3baseUrl + item?.icon}
                        style={{
                          padding: "10px",
                          width: "120px",
                          height: "auto",
                          maxHeight: "110px",
                          cursor: "pointer",
                        }}
                        className="main"
                      />
                    </div>
                  )}
                </div>
                <div className="col-2 col-lg-1">
                  {item.child_options?.length > 0 && (
                    <div
                      onClick={() => {
                        handleToggle(index);
                      }}
                      className="cursor-pointer"
                    >
                      {item.is_expanded ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </div>
                  )}
                </div>
              </div>
              {item.child_options?.length > 0 &&
                item.is_expanded &&
                item.child_options.map((child_option, child_index) => {
                  let is_checked_c = null;
                  if (is_checked?.child_options) {
                    is_checked_c = is_checked?.child_options.find(
                      (c) => c.item_id == child_option._id
                    );
                  }
                  return (
                    <div className="main row ps-5 align-items-center">
                      <div className="col-1">
                        <div
                          onClick={() => {
                            handleSelect(item, child_option);
                          }}
                          className={`select-menu-checkbox m-auto ${
                            is_checked_c ? "selected" : ""
                          }`}
                        >
                          {is_checked_c && (
                            <span className="select-menu-tick">
                              <DoneIcon />
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-8 col-md-3 mt-3 mb-3 mt-3 mb-3">
                        <div className="static-textfield">
                          {child_option?.title && (
                            <div className="static-textfield-label px-1">
                              Title
                            </div>
                          )}
                          <div className="field-value">
                            {child_option?.title ? (
                              child_option?.title
                            ) : (
                              <span className="disabled_label">Title</span>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="d-none d-lg-block col-lg-3 mt-3 mb-3 ps-0">
                        <div className="static-textfield">
                          <div className="static-textfield-label px-1">
                            Is Link
                          </div>
                          <div className="field-value">
                            {child_option?.is_link ? "Yes" : "No"}
                          </div>
                          <div className="dropdown-arrow">
                            <ArrowDropDownIcon />
                          </div>
                        </div>
                      </div>
                      <div className="d-none d-lg-block col-lg-3 mt-3 mb-3 ps-0">
                        <div className="static-textfield">
                          <div className="static-textfield-label px-1">
                            Is Open New Tab
                          </div>
                          <div className="field-value">
                            {child_option?.is_open_new_tab ? "Yes" : "No"}
                          </div>
                          <div className="dropdown-arrow">
                            <ArrowDropDownIcon />
                          </div>
                        </div>
                      </div>
                      <div className="col-2 col-lg-1 mb-3 ms-2 navitems">
                        {child_option?.icon && (
                          <div className="preview">
                            <img
                              src={s3baseUrl + child_option?.icon}
                              style={{
                                padding: "10px",
                                width: "120px",
                                height: "auto",
                                maxHeight: "110px",
                                cursor: "pointer",
                              }}
                              className="main"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
            </div>
          );
        })
      ) : (
        <RecordNotFound title="Data" />
      )}

      <div className="text-end mt-4">
        <button
          // onClick={handleSubmit}
          className="small-contained-button"
        >
          {isLoadingButton ? "Submitting..." : "Submit"}
        </button>
      </div>
    </div>
  );
};

export default ManageNavItemsAccess;
