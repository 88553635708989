import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import {
  departmentListAPIs,
  departmentListNewAPIs,
} from "src/DAL/SupportTicket/SupportTicket";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
import { get_root_value } from "src/utils/domUtils";

function CustomConfirmationdata({
  open,
  setOpenDelete,
  handleAgree,
  value,
  setOpen,
  handleChange,
  setAffiliateData,
  affiliateData,
  inputs,
}) {
  const [departmentsData, setDepartmentsData] = useState([]);
  const handleCloseDialog = () => {
    setOpen(false);
  };
  const getDepartmentListing = async () => {
    const result = await departmentListNewAPIs();
    if (result.code === 200) {
      setDepartmentsData(result.department);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  useEffect(() => {
    getDepartmentListing();
  }, []);

  return (
    <>
      <Dialog
        open={open}
        onClose={handleCloseDialog}
        PaperProps={{
          style: {
            backgroundColor: get_root_value("--popup-background-color"),
            // backgroundColor: "black",
            color: get_root_value("--portal-theme-primary"),
            boxShadow: "none",
            width: "300px",
          },
        }}
      >
        <DialogTitle>
          <div className="row">
            <div className="col-lg-12 col-md-6 col-sm-12 mt-4">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Departments *
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="departments"
                  label="Departments*"
                  required
                  value={inputs.departments}
                  onChange={handleChange}
                >
                  {departmentsData?.map((department, index) => (
                    <MenuItem
                      value={department._id}
                      eventKey={index}
                      key={department._id}
                    >
                      {department.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
        </DialogTitle>
        <DialogActions className="dialog-box">
          <Button className="model-button-hover" onClick={handleCloseDialog}>
            Cancel
          </Button>
          <Button className="model-button-hover" onClick={handleAgree}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default CustomConfirmationdata;
