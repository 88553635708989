import React from "react";
import { Box, CircularProgress } from "@mui/material";
import Chart from "react-apexcharts";
import { delegate_commission_stats_api } from "src/DAL/Tracking/Tracking";
import moment from "moment";
import { makeStyles } from "@mui/styles";
import { useEffect } from "react";
import CustomDrawer from "../GeneralComponents/CustomDrawer";
import { useState } from "react";
import { SalesPerformanceFilter } from "./SalesPerformanceFilter";
import roundFilterList from "@iconify/icons-ic/round-filter-list";
import { Icon } from "@iconify/react";
import FilteredChip from "../GeneralComponents/FilteredChip";
import { useSnackbar } from "notistack";
import {
  getRandomColor,
  get_full_name,
  get_kmb_number,
} from "src/utils/constants";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
  fonts: {
    fontSize: "13px",
    fontWeight: "500",
  },
}));

function getDataInRange(data, startDate, endDate) {
  let start = new Date(startDate.split("-").reverse().join("-"));
  let end = new Date(endDate.split("-").reverse().join("-"));
  let days = Math.ceil(
    (end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24)
  );
  let dates = [];
  let scores = [];
  let monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  for (let i = 0; i <= days; i++) {
    let currentDate = new Date(start);
    currentDate.setDate(start.getDate() + i);

    let day = String(currentDate.getDate()).padStart(2, "0");
    let month = String(currentDate.getMonth() + 1).padStart(2, "0");
    let year = currentDate.getFullYear();

    let dateStringForComparison = `${day}-${month}-${year}`;
    let found = data.find((item) => item.date === dateStringForComparison);
    let dayForDisplay = currentDate.getDate();
    let monthForDisplay = monthNames[currentDate.getMonth()];
    let dateStringForDisplay = `${monthForDisplay} ${dayForDisplay}`;

    dates.push(dateStringForDisplay);
    scores.push(found ? found.total_commission : 0);
  }
  let dataObject = { dates, scores };
  return dataObject;
}

const REMOVE_ITEMS = ["month_and_year"];

const SalesPerformanceGraph = ({ id }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [resultData, setResultData] = useState({});
  const [saleData, setSaleData] = useState([]);
  const [pageCommissionStats, setPageCommissionStats] = useState([]);
  const [commissionStatsCompare, setCommissionStatsCompare] = useState([]);
  const [saleDataCompare, setSaleDataCompare] = useState([]);

  const EMPTY_FILTER = {
    month_and_year: new Date(),
    month_name: new Date().toLocaleString("default", {
      month: "long",
    }),
    year_name: new Date().getFullYear(),
    sale_page: [],
    compare_with: null,
  };
  const [filterData, setFilterData] = useState(EMPTY_FILTER);
  const [filterDataUpdate, setFilterDataUpdate] = useState(EMPTY_FILTER);
  const [filterDrawerStare, setFilterDrawerState] = useState(false);

  const handleOpenFilterDrawer = () => {
    setFilterDrawerState(true);
  };

  const handleCloseFilterDrawer = () => {
    setFilterDrawerState(false);
  };

  const handleChaneOthers = (name, value) => {
    setFilterData((values) => ({ ...values, [name]: value }));
  };

  const handleSubmitFilter = (e) => {
    e.preventDefault();
    monthlyReport(filterData);
    handleCloseFilterDrawer();
  };

  const handleClearFilter = () => {
    setFilterData(EMPTY_FILTER);
    monthlyReport(EMPTY_FILTER);
    handleCloseFilterDrawer();
  };

  const handleDeleteChip = (data, item) => {
    if (item.key === "month_name") {
      data.month_name = new Date().toLocaleString("default", {
        month: "long",
      });
      data.month_and_year.setMonth(new Date().getMonth());
    } else if (item.key === "year_name") {
      data.month_name = new Date().toLocaleString("default", {
        month: "long",
      });
      data.month_and_year.setFullYear(new Date().getFullYear());
    }
    setFilterData(data);
    monthlyReport(data);
  };

  const monthlyReport = async (data) => {
    setIsLoading(true);
    let postData = { ...data };
    postData.month_and_year = moment(postData.month_and_year).format("MM-YYYY");
    if (postData.compare_with) {
      postData.compare_with = postData.compare_with._id;
    } else {
      delete postData.compare_with;
    }
    if (postData.sale_page.length > 0) {
      postData.page = postData.sale_page.map((page) => page._id);
      postData.sale_page;
    }
    const result = await delegate_commission_stats_api(postData, id);
    if (result.code === 200) {
      setResultData(result);
      let Sales = getDataInRange(
        result.commission_stats,
        result.start_date,
        result.end_date
      );
      setSaleData(Sales);
      let Compare_Sales = getDataInRange(
        result.compare_with_use_commission_stats,
        result.start_date,
        result.end_date
      );

      let commission_data = result.page_commission_stats_array.map((item) => {
        let stats = getDataInRange(
          item.commission_stats_array,
          result.start_date,
          result.end_date
        );
        return { ...item, stats, color: getRandomColor() };
      });
      setPageCommissionStats(commission_data);

      let commission_data_2 =
        result.compare_with_page_commission_stats_array.map((item) => {
          let stats = getDataInRange(
            item.commission_stats_array,
            result.start_date,
            result.end_date
          );
          return { ...item, stats, color: getRandomColor() };
        });
      setCommissionStatsCompare(commission_data_2);
      setSaleDataCompare(Compare_Sales);
      data.month_name = `${data.month_and_year.toLocaleString("default", {
        month: "long",
      })}`;

      data.year_name = data.month_and_year.getFullYear();
      setFilterDataUpdate(data);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  let series_array = [];
  if (pageCommissionStats.length > 0) {
    series_array = pageCommissionStats.map((item) => {
      return {
        name: item.sale_page_title,
        data: item.stats.scores,
        color: item.color,
      };
    });
  }

  let series_array_2 = [];
  if (commissionStatsCompare.length > 0) {
    series_array_2 = commissionStatsCompare.map((item, index) => {
      let first_array = pageCommissionStats[index]; // passing the same colors as in first array
      return {
        name: item.sale_page_title,
        data: item.stats.scores,
        color: first_array?.color,
      };
    });
  }

  let lineChart = {
    options: {
      chart: {
        type: "area",
      },
      grid: {
        show: true,
        borderColor: "gray",
        strokeDashArray: 4,
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: "#c6c2c2",
          },
        },
        axisBorder: {
          show: true,
          color: "gray",
          dashArray: 4,
        },
      },
      xaxis: {
        labels: {
          style: {
            colors: "#c6c2c2",
          },
          axisTicks: {
            show: false, // This hides the default x-axis ticks
          },
        },
        categories: saleData.dates,
      },

      stroke: {
        curve: "smooth",
        width: 3,
      },
      fill: {
        colors: ["#f6bd4b"],
      },
      tooltip: {
        enabled: true,
        x: {
          format: "dd/MM/yyyy",
        },
        y: {
          formatter: function (val) {
            return "£" + val;
          },
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val, opts) {
          if (val === 0) {
            return "";
          }
          return "£" + val;
        },
      },
    },
    series:
      filterDataUpdate.sale_page.length > 0
        ? series_array
        : [
            {
              name: "Total  Commission",
              data: saleData.scores,
              color: "#f6bd4b",
            },
          ],
  };

  let lineChartCompare = {
    options: {
      chart: {
        type: "area",
      },
      grid: {
        show: true,
        borderColor: "gray",
        strokeDashArray: 4,
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: "#c6c2c2",
          },
        },
        axisBorder: {
          show: true,
          color: "gray",
          dashArray: 4,
        },
      },
      xaxis: {
        labels: {
          style: {
            colors: "#c6c2c2",
          },
          axisTicks: {
            show: false, // This hides the default x-axis ticks
          },
        },

        categories: saleDataCompare.dates,
      },

      stroke: {
        curve: "smooth",
        width: 3,
      },
      fill: {
        colors: ["#f6bd4b"],
      },
      tooltip: {
        enabled: true,
        x: {
          format: "dd/MM/yyyy",
        },
        y: {
          formatter: function (val) {
            return "£" + val;
          },
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val, opts) {
          if (val === 0) {
            return "";
          }
          // Add the currency sign to the value
          return "£" + val;
        },
      },
    },
    series:
      filterDataUpdate.sale_page.length > 0
        ? series_array_2
        : [
            {
              name: "Total  Commission",
              data: saleDataCompare.scores,
              color: "#f6bd4b",
            },
          ],
  };

  useEffect(() => {
    monthlyReport(EMPTY_FILTER);
  }, []);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="row">
        <div className="col-12 text-end">
          <button
            className="small-contained-button me-2 my-3"
            onClick={handleOpenFilterDrawer}
          >
            Filter &nbsp;&nbsp; <Icon icon={roundFilterList} />
          </button>
        </div>
        <div className="ps-3">
          <FilteredChip
            data={filterDataUpdate}
            tempState={filterData}
            EMPTY_FILTER={EMPTY_FILTER}
            REMOVE_ITEMS={REMOVE_ITEMS}
            onDeleteChip={handleDeleteChip}
            onClear={handleClearFilter}
          />
        </div>

        <div className={`col-12 mt-3`}>
          <h3
            className="ps-4"
            style={{
              color: "#fff",
            }}
          >{`Total Monthly Commission £${get_kmb_number(
            resultData.total_commission
          )}`}</h3>
          {filterDataUpdate.compare_with && (
            <span className="ms-4 portal-color">
              {get_full_name(resultData.consultant)}
            </span>
          )}
          <div className="ms-4 d-flex flex-wrap">
            {pageCommissionStats.map((item) => {
              return (
                <div
                  className="d-flex align-content-center me-3 mt-2"
                  style={{ alignItems: "center" }}
                >
                  <span
                    className="box-1 me-1"
                    style={{
                      backgroundColor: item.color,
                    }}
                  ></span>
                  <span className="">{item.sale_page_title}</span>
                </div>
              );
            })}
          </div>

          <Box sx={{ p: 1 }} dir="ltr">
            <Chart
              options={lineChart.options}
              series={lineChart.series}
              type="area"
              height={340}
            />
          </Box>
        </div>
        {filterDataUpdate.compare_with && (
          <div className="col-12 mt-3">
            <h3
              className="ps-4"
              style={{
                color: "#fff",
              }}
            >{`Total Monthly Commission £${get_kmb_number(
              resultData.compare_with_total_commission
            )}`}</h3>
            <span className="ms-4 portal-color">
              {get_full_name(filterDataUpdate.compare_with)}
            </span>
            <Box sx={{ p: 1 }} dir="ltr">
              <Chart
                options={lineChartCompare.options}
                series={lineChartCompare.series}
                type="area"
                height={340}
              />
            </Box>
          </div>
        )}
      </div>
      <CustomDrawer
        isOpenDrawer={filterDrawerStare}
        onOpenDrawer={handleOpenFilterDrawer}
        onCloseDrawer={handleCloseFilterDrawer}
        pageTitle="Filter"
        componentToPassDown={
          <SalesPerformanceFilter
            filterData={filterData}
            handleChaneOthers={handleChaneOthers}
            handleSubmitFilter={handleSubmitFilter}
            handleClearFilter={handleClearFilter}
            delegate_id={id}
          />
        }
      />
    </>
  );
};

export default SalesPerformanceGraph;
