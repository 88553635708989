import * as React from "react";
import {
  Box,
  Card,
  CardHeader,
  CircularProgress,
  TextField,
  Tooltip,
} from "@mui/material";
import Chart from "react-apexcharts";
import CircleIcon from "@mui/icons-material/Circle";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";

import { useState } from "react";
import { get_site_setting } from "src/DAL/Payment/Payment";
import { useEffect } from "react";
import {
  getMonthalyReport,
  getMonthalyReportSpacific,
} from "src/DAL/Tracking/Tracking";
import moment from "moment";
import { makeStyles } from "@mui/styles";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
  fonts: {
    fontSize: "13px",
    fontWeight: "500",
  },
}));
function getDataInRange(data, startDate, endDate) {
  let start = new Date(startDate.split("-").reverse().join("-"));
  let end = new Date(endDate.split("-").reverse().join("-"));
  let days = Math.ceil(
    (end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24)
  );
  let dates = [];
  let scores = [];
  let monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  for (let i = 0; i <= days; i++) {
    let currentDate = new Date(start);
    currentDate.setDate(start.getDate() + i);
    let day = String(currentDate.getDate()).padStart(2, "0");
    let month = String(currentDate.getMonth() + 1).padStart(2, "0");
    let year = currentDate.getFullYear();
    let dateStringForComparison = `${day}-${month}-${year}`;
    let found = data.find((item) => item.date === dateStringForComparison);
    let dayForDisplay = currentDate.getDate();
    let monthForDisplay = monthNames[currentDate.getMonth()];
    let dateStringForDisplay = `${monthForDisplay} ${dayForDisplay}`;
    dates.push(dateStringForDisplay);
    scores.push(found ? found.rate : 0);
  }
  let dataObject = { dates, scores };
  return dataObject;
}
const MonthlyAccountabilityReportForUsers = ({ detailData }) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = React.useState(true);
  const [intentionComplete, setComplete] = useState({});
  const [settings, setSettings] = useState({});
  let [attitudePerformance, SetAttitudePerformance] = useState({});
  let [desirePerformance, SetDesirePerformance] = useState({});
  let [disciplinePerformance, SetDisciplinePerformance] = useState({});
  let [focusPerformance, SetFocusPerformance] = useState({});
  let [winPerformance, SetWinPerformance] = useState({});
  const initialArray = ["attitude", "focus", "desire", "discipline", "win"];
  const [selectedItems, setSelectedItems] = useState(initialArray);
  const [state, setState] = useState({
    month: new Date(),
  });
  const toggleNumber = (item) => {
    const newSelectedItems = [...selectedItems];
    if (newSelectedItems.includes(item)) {
      let filtered = newSelectedItems.filter((i) => i !== item);
      setSelectedItems(filtered);
    } else {
      setSelectedItems([...selectedItems, item]);
    }
  };
  const monthlyReport = async () => {
    setIsLoading(true);
    const result = detailData;
    if (result.code === 200) {
      setSettings(result.default_setting);
      let intentionObject = {
        complete: Number(result.complete_accountability_avg),
        notComplete: Number(result.incomplete_accountability_avg),
      };
      setComplete(intentionObject);
      let attitude_performance = getDataInRange(
        result.attitude_performance_rate_array,
        result.start_date,
        result.end_date
      );
      let desire_performance = getDataInRange(
        result.desire_performance_rate_array,
        result.start_date,
        result.end_date
      );
      let discipline_performance = getDataInRange(
        result.discipline_performance_rate_array,
        result.start_date,
        result.end_date
      );
      let focus_performance = getDataInRange(
        result.focus_performance_rate_array,
        result.start_date,
        result.end_date
      );
      let win_note_performance = getDataInRange(
        result.win_note_performance_rate_array,
        result.start_date,
        result.end_date
      );
      SetWinPerformance(win_note_performance);
      SetFocusPerformance(focus_performance);
      SetDisciplinePerformance(discipline_performance);
      SetDesirePerformance(desire_performance);
      SetAttitudePerformance(attitude_performance);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const allSeries = [
    {
      name: settings.attitude_text,
      value: "attitude",
      data: attitudePerformance?.scores,
      color: "#f6bd4b",
    },
    {
      name: settings.focus_text,
      value: "focus",
      data: focusPerformance?.scores,
      color: "#5ab834",
    },
    {
      name: settings.desires_text,
      data: desirePerformance?.scores,
      color: "#A020F0",
      value: "desire",
    },
    {
      name: settings.discipline_text,
      data: disciplinePerformance?.scores,
      color: "#0000FF",
      value: "discipline",
    },
    {
      name: settings.win_text,
      data: winPerformance?.scores,
      color: "#C70039",
      value: "win",
    },
  ];

  const series = allSeries.filter((series) =>
    selectedItems.includes(series.value)
  );
  let lineChartWin = {
    options: {
      chart: {
        type: "line",
      },
      grid: {
        show: true,
        borderColor: "gray",
        strokeDashArray: 4,
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: "#c6c2c2",
          },
        },
        axisBorder: {
          show: true,
          color: "gray",
          dashArray: 4,
        },
        max: series.length > 0 ? 10 : undefined,
      },
      xaxis: {
        labels: {
          style: {
            colors: "#c6c2c2",
          },
          axisTicks: {
            show: false, // This hides the default x-axis ticks
          },
        },

        categories: attitudePerformance.dates,
      },

      stroke: {
        curve: "smooth",
        width: 2,
      },
      markers: {
        size: 0,
      },
      fill: {
        colors: ["#f6bd4b"],
      },
      dataLabels: {
        enabled: true,
        formatter: function (val, opts) {
          if (val === 0) {
            return "";
          }
          return val;
        },
      },
    },
    series: series,
  };
  let donutChart = {
    series: [intentionComplete.complete, intentionComplete.notComplete],
    options: {
      tooltip: {
        enabled: true,
        y: {
          formatter: function (val) {
            return val + "%"; // Add the percentage sign to the value
          },
        },
      },
      chart: {
        type: "donut",
        height: 300,
      },
      plotOptions: {
        pie: {
          startAngle: -90,
          endAngle: 90,
          offsetY: 10,
          hover: {
            highlight: false, // Prevent color change on hover
          },
          selection: false, // Disable selection
        },
      },
      stroke: {
        show: false, // Hide the outline
      },

      labels: ["Complete", "Not Completed"],
      colors: ["#f6bd4b", "#f6bd4b50"],
      grid: {
        padding: {
          bottom: -80,
        },
      },

      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: "100%",
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  };
  const handleChangeStartDate = (date, viewType) => {
    setState((prevState) => ({
      ...prevState,
      [viewType]: date,
    }));
  };
  const handleFormateDate = () => {
    monthlyReport();
  };
  useEffect(() => {
    monthlyReport();
  }, []);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <>
      <div className="container">
        <div className="row mt-3">
          <div className="row justify-content-center">
            <div className="col-12  col-lg-6 mt-3  ">
              <h2 class="mt-2 ms-1 secondary-heading text-center">
                {settings.intentions_heading}
              </h2>
              <div className="chart-container ">
                {intentionComplete && (
                  <Chart
                    options={donutChart.options}
                    series={donutChart.series}
                    type="donut"
                  />
                )}
              </div>

              <div className="d-flex justify-content-between">
                <p style={{ color: "white" }}>
                  <CircleIcon className="position-completed" /> Completed
                </p>
                <p style={{ color: "white" }}>
                  <CircleIcon className="position-notcompleted" />
                  Not Completed
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-12 col-lg-12 mt-3 pe-0">
            <Card>
              <div className="d-flex justify-content-between for-mobile mb-3">
                <div>
                  <CardHeader
                    title={settings.performance_heading}
                    subheader={``}
                  />
                </div>
                <div
                  className="d-flex for-mobile-indication"
                  style={{ alignItems: "end" }}
                >
                  <Tooltip
                    title={
                      selectedItems.includes("attitude")
                        ? "Click here to hide the performance in graph"
                        : "Click here to view the performance in graph"
                    }
                  >
                    <div
                      className="d-flex align-content-center me-3"
                      style={{
                        alignItems: "center",
                        opacity: selectedItems.includes("attitude") ? 1 : 0.5,

                        cursor: "pointer",
                      }}
                      onClick={() => toggleNumber("attitude")}
                    >
                      <span className="box-1 box-1-color  me-1"></span>
                      <span className="">{settings.attitude_text}</span>
                    </div>{" "}
                  </Tooltip>
                  <Tooltip
                    title={
                      selectedItems.includes("focus")
                        ? "Click here to hide the performance in graph"
                        : "Click here to view the performance in graph"
                    }
                  >
                    <div
                      className="d-flex align-content-center me-3"
                      style={{
                        alignItems: "center",

                        cursor: "pointer",
                        opacity: selectedItems.includes("focus") ? 1 : 0.5,
                      }}
                      onClick={() => toggleNumber("focus")}
                    >
                      <span className="box-1 box-2-color  me-1"></span>
                      <span className="">{settings.focus_text}</span>
                    </div>{" "}
                  </Tooltip>
                  <Tooltip
                    title={
                      selectedItems.includes("desire")
                        ? "Click here to hide the performance in graph"
                        : "Click here to view the performance in graph"
                    }
                  >
                    <div
                      className="d-flex align-content-center me-3"
                      style={{
                        alignItems: "center",
                        cursor: "pointer",
                        opacity: selectedItems.includes("desire") ? 1 : 0.5,
                      }}
                      onClick={() => toggleNumber("desire")}
                    >
                      <span className="box-1 box-3-color  me-1"></span>
                      <span className="">{settings.desires_text}</span>
                    </div>{" "}
                  </Tooltip>
                  <Tooltip
                    title={
                      selectedItems.includes("discipline")
                        ? "Click here to hide the performance in graph"
                        : "Click here to view the performance in graph"
                    }
                  >
                    <div
                      className="d-flex align-content-center me-3"
                      style={{
                        alignItems: "center",
                        cursor: "pointer",
                        opacity: selectedItems.includes("discipline") ? 1 : 0.5,
                      }}
                      onClick={() => toggleNumber("discipline")}
                    >
                      <span className="box-1 box-4-color  me-1"></span>
                      <span className="">{settings.discipline_text}</span>
                    </div>{" "}
                  </Tooltip>
                  <Tooltip
                    title={
                      selectedItems.includes("win")
                        ? "Click here to hide the performance in graph"
                        : "Click here to view the performance in graph"
                    }
                  >
                    <div
                      className="d-flex align-content-center me-3"
                      style={{
                        alignItems: "center",

                        cursor: "pointer",
                        opacity: selectedItems.includes("win") ? 1 : 0.5,
                      }}
                      onClick={() => toggleNumber("win")}
                    >
                      <span className="box-1 box-5-color  me-1"></span>
                      <span className="">{settings.win_text}</span>
                    </div>
                  </Tooltip>
                </div>
              </div>
              <Box sx={{ p: 1, marginLeft: "20px" }} dir="ltr">
                <Chart
                  options={lineChartWin.options}
                  series={lineChartWin.series}
                  type="line"
                  height={440}
                />
              </Box>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default MonthlyAccountabilityReportForUsers;
