import React, { useEffect, useState } from "react";
import { CircularProgress, IconButton } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { s3baseUrl } from "src/config/config";
import { list_member_replies_against_module } from "src/DAL/GeneralQuestions/GeneralQuestions";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import moment from "moment";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function QuestionsAnswersAsCreatedFor({ created_for }) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const location = useLocation();
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [isLoading, setIsLoading] = useState(true);
  const [answersData, setAnswersData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchText, setSearchText] = useState("");
  const created_for_id = new URLSearchParams(location.search).get(
    "created_for_id"
  );

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setPageCount(1);
  };

  const getAnswersListing = async (search_text) => {
    console.log(created_for_id, "created_for_idcreated_for_id");
    setIsLoading(true);
    let postData = {
      search_text: search_text ? search_text : "",
      created_for: created_for ? created_for : "",
      created_for_id: created_for_id ? created_for_id : "",
    };
    const result = await list_member_replies_against_module(
      page,
      rowsPerPage,
      postData
    );
    if (result.code == 200) {
      const answers = [];
      result?.members?.map((answer, index) => {
        if (answer?.first_name) {
          answers.push({
            ...answer,
            user_name: answer?.first_name + " " + answer?.last_name,
            user_email: answer?.email,
            answered_date: moment(answer.reply_date).format("DD-MM-YYYY"),
            table_avatar: {
              src: answer?.profile_image
                ? s3baseUrl + answer.profile_image
                : "",
              alt: answer.first_name,
            },
          });
        }
      });

      setAnswersData(answers);
      setTotalCount(result.toal_count);
      setTotalPages(result.total_pages);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleNavigate = (value) => {
    navigate(
      `${window.location.pathname}/answers-detail?${created_for_id}&member_id=${value.member_id}&created_for=${value.created_for}`
    );
  };

  const MENU_OPTIONS = [
    {
      label: "Answers Detail",
      icon: "akar-icons:edit",
      handleClick: handleNavigate,
    },
  ];

  const searchFunction = () => {
    setPage(0);
    setPageCount(1);
    getAnswersListing(searchText);
    localStorage.setItem("questions_answers_search", searchText);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
    localStorage.setItem("member_page_number", newPage);
  };

  useEffect(() => {
    let find_search = localStorage.getItem("questions_answers_search");
    if (find_search) {
      setSearchText(find_search);
    }
    getAnswersListing(find_search);
  }, [rowsPerPage, page]);

  const TABLE_HEAD = [
    { id: "number", label: "#", alignRight: false, type: "number" },
    {
      id: "table_avatar",
      label: "User Profile",
      alignRight: false,
      type: "thumbnail",
    },
    { id: "user_name", label: "User Name", alignRight: false },
    {
      id: "user_email",
      label: "User Email",
      calignRight: false,
    },
    {
      id: "answered_date",
      label: "Answered Date",
      calignRight: false,
    },
    { id: "action", label: "Action", alignRight: false, type: "action" },
  ];

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12 display-flex d-flex mt-3">
            <span>
              <IconButton
                className="back-screen-button"
                onClick={() => navigate(-1)}
              >
                <ArrowBackIcon />
              </IconButton>
            </span>

            <h2 className="ms-2">Questions Answers List</h2>
          </div>
          <div className="col-lg-8 col-sm-8 mb-3"></div>
        </div>

        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={answersData}
          MENU_OPTIONS={MENU_OPTIONS}
          className="card-with-background"
          custom_pagination={{
            total_count: totalCount,
            rows_per_page: rowsPerPage,
            page: page,
            handleChangePage: handleChangePage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          pageCount={pageCount}
          totalPages={totalPages}
          handleChangePages={handleChangePages}
          pagePagination={true}
          custom_search={{
            searchText: searchText,
            setSearchText: setSearchText,
            handleSubmit: searchFunction,
          }}
        />
      </div>
    </>
  );
}
