import { invokeApi } from "../../bl_libs/invokeApi";

export const _whatsapp_chat_list = async ({ chat_type, search_text }) => {
  const requestObj = {
    path: `api/whatsapp_chat/list_whatsapp_chat?chat_type=${chat_type}&search_text=${search_text}&page=0&limit=20`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _whatsapp_chat_load_more = async (path) => {
  const requestObj = {
    path: path,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _list_whatsapp_messages = async (chat_id) => {
  const requestObj = {
    path: `api/whatsapp_chat/list_whatsapp_messages/${chat_id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _whatsapp_templates_list = async (chat_id) => {
  const requestObj = {
    path: `api/whatsapp_chat/list_templates`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _consultant_member_list_whatsapp = async (data) => {
  const requestObj = {
    path: `api/whatsapp_chat/member_list_delegate/whatsapp_chat`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };

  return invokeApi(requestObj);
};

export const _initiate_chat_whatsapp = async (data) => {
  const requestObj = {
    path: `api/whatsapp_chat/initiate_chat`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };

  return invokeApi(requestObj);
};
