import { useMemo } from "react";
import * as d3 from "d3";

const MARGIN = 30;

const colors = ["#f6bd4b", "#f6bd4b50"];

export const PieChart = ({ width, height, data }) => {
  const radius = Math.min(width, height) / 2 - MARGIN;

  const pie = useMemo(() => {
    const pieGenerator = d3
      .pie()
      .value((d) => d.value)
      .sort(null);
    return pieGenerator(data);
  }, [data]);

  const arcGenerator = useMemo(() => {
    return d3.arc().innerRadius(0).outerRadius(radius);
  }, [radius]);

  const arcs = useMemo(() => {
    return pie.map((p) => arcGenerator(p));
  }, [pie, arcGenerator]);

  return (
    <svg width={width} height={height} style={{ display: "inline-block" }}>
      <g transform={`translate(${width / 2}, ${height / 2})`}>
        <line x1={0} y1={0} x2={0} y2={-radius} stroke="#f6bd4b" />
        {arcs.map((arc, i) => (
          <g key={i}>
            <path d={arc} fill={colors[i]} />
            <text
              transform={`translate(${arcGenerator.centroid(pie[i])})`}
              dy=".35em"
              textAnchor="middle"
              fill="white"
            >
              {data[i].value + "%"}
            </text>
          </g>
        ))}
      </g>
    </svg>
  );
};
