import React, { useState } from "react";
import PollDetail from "./PollDetail";
import PollUsers from "./PollUsers";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ClearIcon from "@mui/icons-material/Clear";
import { useContentSetting } from "src/Hooks/ContentSettingState";

export default function FeedPollResult(props) {
  const { selectedFeed, handleClosePoll, openPoll } = props;
  const [componentState, setComponentState] = useState(0);
  const [selectedOption, setSelectedOption] = useState({});

  const handleOptionResult = (option) => {
    setSelectedOption(option);
    setComponentState(1);
  };

  const handleBack = (option) => {
    setComponentState((old) => old - 1);
  };

  return (
    <div className="poll-result-modal">
      <div className="d-flex justify-content-between">
        <div>
          {componentState > 0 && (
            <h2 className="cursor-pointer" onClick={handleBack}>
              <ArrowBackIcon />
            </h2>
          )}
        </div>
        <h2 className="text-center">Poll</h2>
        <div>
          <h2 className="cursor-pointer" onClick={handleClosePoll}>
            <ClearIcon />
          </h2>
        </div>
      </div>
      <hr className="my-2" />
      <div className="poll-result-card">
        {componentState === 0 ? (
          <PollDetail
            selectedFeed={selectedFeed}
            handleOptionResult={handleOptionResult}
          />
        ) : (
          <PollUsers
            selectedFeed={selectedFeed}
            selectedOption={selectedOption}
            openPoll={openPoll}
          />
        )}
      </div>
    </div>
  );
}
