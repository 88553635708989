import * as React from "react";
import Dialog from "@mui/material/Dialog";
import { Icon } from "@iconify/react";
import { Box, Typography, IconButton } from "@mui/material";
import closeFill from "@iconify/icons-eva/close-fill";

export default function NewCustomPopover({
  isOpenPop,
  isClosePop,
  title,
  componentToPassDown,
  heigh,
  width,
}) {
  const handleClose = () => {
    isClosePop(false);
  };

  return (
    <Dialog
      maxWidth="md"
      sx={{
        ml: 35,
        "@media (max-width: 768px)": {
          ml: 0,
          width: "100%",
        },
      }}
      open={isOpenPop}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: {
          color: "white",
          boxShadow: "none",
          width: width ? heigh : "550px",
          minHeight: heigh ? heigh : "300px",
        },
      }}
    >
      <Box>
        <div className="d-flex justify-content-between align-items-center  mt-2 px-4 mt-2 ">
          <div>
            <Typography variant="h5" component="h2" sx={{ color: "#f6bd4b" }}>
              {title}
            </Typography>
          </div>
          <div>
            <IconButton onClick={handleClose} className="icon-button-modal">
              <Icon icon={closeFill} width={20} height={20} />
            </IconButton>
          </div>
        </div>
        <div>{componentToPassDown}</div>
      </Box>
    </Dialog>
  );
}
