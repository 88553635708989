import { merge } from "lodash";
import ReactApexChart from "react-apexcharts";
// material
import { Card, CardHeader, Box } from "@mui/material";
//

import moment from "moment";
import { BaseOptionChart } from "../charts";

// ----------------------------------------------------------------------

export default function AppWebsiteVisits({
  graphData,
  graphLabels,
  startDate,
}) {
  const todayDate = moment(new Date()).format("MM/DD/YYYY");
  const CHART_DATA = [
    {
      name: "Your Intentions Percentage",
      type: "line",
      data: graphData,
    },
  ];

  const chartOptions = merge(BaseOptionChart(), {
    // stroke: { width: [0, 2, 3], curve: "smooth" },
    markers: {
      size: [4, 7],
    },
    stroke: {
      curve: "straight",
    },
    // dataLabels: {
    //   enabled: true,
    // },
    plotOptions: { bar: { columnWidth: "51%", borderRadius: 4 } },
    fill: { type: ["solid", "gradient", "solid"] },
    labels: graphLabels,
    xaxis: { type: "datetime" },
    yaxis: {
      min: 0,
      max: 100,
      tickAmount: 10,
    },

    xaxis: {
      type: "datetime",
      tickAmount: 20,
      min: new Date(startDate).getTime(),
      max: new Date(todayDate).getTime(),
      labels: {
        formatter: function (val) {
          return moment(new Date(val)).format("MM/DD/YYYY");
        },
      },
    },

    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y) => {
          if (typeof y !== "undefined") {
            return `${y.toFixed(0)} %`;
          }
          return y;
        },
      },
    },
  });
  var options = {
    chart: {
      height: 350,
      type: "line",
      id: "areachart-2",
    },
    annotations: {
      yaxis: [
        {
          y: 9000,
          borderColor: "#00E396",
          label: {
            borderColor: "#00E396",
            style: {
              color: "#fff",
              background: "#00E396",
            },
            text: "Support",
          },
        },
        // {
        //   y: 8600,
        //   y2: 9000,
        //   borderColor: "#000",
        //   fillColor: "#FEB019",
        //   opacity: 0.2,
        //   label: {
        //     borderColor: "#333",
        //     style: {
        //       fontSize: "10px",
        //       color: "#333",
        //       background: "#FEB019",
        //     },
        //     text: "Y-axis range",
        //   },
        // },
      ],
      xaxis: [
        {
          x: new Date("23 Nov 2017").getTime(),
          strokeDashArray: 0,
          borderColor: "#775DD0",
          label: {
            borderColor: "#775DD0",
            style: {
              color: "#fff",
              background: "#775DD0",
            },
            text: "Anno Test",
          },
        },
        {
          x: new Date("26 Nov 2017").getTime(),
          x2: new Date("28 Nov 2017").getTime(),
          fillColor: "#B3F7CA",
          opacity: 0.4,
          label: {
            borderColor: "#B3F7CA",
            style: {
              fontSize: "10px",
              color: "#fff",
              background: "#00E396",
            },
            offsetY: -10,
            text: "X-axis range",
          },
        },
      ],
      points: [
        {
          x: new Date("01 Dec 2017").getTime(),
          y: 8607.55,
          marker: {
            size: 8,
            fillColor: "#fff",
            strokeColor: "red",
            radius: 2,
            cssClass: "apexcharts-custom-class",
          },
          label: {
            borderColor: "#FF4560",
            offsetY: 0,
            style: {
              color: "#fff",
              background: "#FF4560",
            },

            text: "Point Annotation",
          },
        },
        {
          x: new Date("08 Dec 2017").getTime(),
          y: 9340.85,
          marker: {
            size: 0,
          },
          image: {
            path: "../../assets/images/ico-instagram.png",
          },
        },
      ],
    },
    dataLabels: {
      style: {
        colors: ["#F44336", "#E91E63", "#9C27B0"],
      },
    },
    stroke: {
      curve: "straight",
    },
    // grid: {
    //   padding: {
    //     right: 30,
    //     left: 20,
    //   },
    // },

    labels: graphLabels,
    xaxis: {
      type: "datetime",
    },
  };

  return (
    <Card className="card dashboard-chart">
      <CardHeader
        title="Daily Dynamite Intentions Analysis"
        className="title"
        subheader=""
      />
      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <ReactApexChart
          type="line"
          series={CHART_DATA}
          options={chartOptions}
          height={364}
        />
      </Box>
    </Card>
  );
}
