import React from "react";

export default function PagesList({ pages, show_full, handleClick }) {
  let total_pages = pages;
  let show_pages = 4;

  if (!show_full) {
    total_pages = pages.slice(0, show_pages);
  }
  return (
    <div className="style-page-name p-2">
      {pages.length > 0
        ? total_pages.map((show, i) => {
            return (
              <>
                {show.plan_info ? (
                  <p className="m-1">
                    {show.page_info
                      ? show.page_info.sale_page_title +
                        " " +
                        "|" +
                        " " +
                        show.plan_info?.plan_title
                      : "N/A"}
                  </p>
                ) : (
                  <p className="m-1">
                    {show.page_info ? show.page_info.sale_page_title : "N/A"}
                  </p>
                )}
                {pages.length > show_pages &&
                  handleClick &&
                  i == show_pages - 1 && (
                    <p
                      className="mb-1 mt-1 view-more"
                      aria-describedby={`view-more${i}`}
                      variant="contained"
                      onClick={(e) => handleClick(e, pages)}
                    >
                      view more
                    </p>
                  )}
              </>
            );
          })
        : "N/A"}
    </div>
  );
}
