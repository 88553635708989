import { invokeApi } from "../../bl_libs/invokeApi";
const api_token = localStorage.getItem("token");
const client_api_token =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2MzQxNTMzZTY0YzFjYzY2YzlhMTZhMzgiLCJsb2dpbl90b2tlbiI6ImUyZDYzNzEwLTZmYTUtMTFlZC1iNWMwLWRiYmFiNzBmMGFjNyIsImxvZ2luX2J5IjoibWVtYmVyX3VzZXIiLCJpYXQiOjE2Njk2OTkzNzh9.Qa8F02qBl4yVQu1Lulfz0t2cJtD6jiPfI2nmcgWUaC8";

let prod =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2MWZjYTkxNmRmYjY2NjdiZTNjYTg4MzQiLCJsb2dpbl90b2tlbiI6IjBmODkyMmMwLTExOWMtMTFlZS1hZmM1LWM5NGNiMDU2ZjI4YSIsImxvZ2luX2J5IjoibWVtYmVyX3VzZXIiLCJpYXQiOjE2ODc1MDcyNDZ9.fxlKQBc9v3QhkfMAO6XEJuUDmB6bDunGbgw3iJJCZP0";
export const get_community_content = async (data) => {
  const requestObj = {
    path: `api/home`,
    method: "GET",
    headers: {
      "x-sh-auth": prod,
    },
  };
  return invokeApi(requestObj);
};
export const get_community_content_delegate = async (list_type) => {
  const requestObj = {
    path: `api/feeds/delegate_portal/extra_data/get?list_type=${list_type}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const get_cosmos_content = async (url) => {
  let loadMore = "api/consultant/the_cosmos/data";
  if (url) {
    loadMore = url;
  }
  const requestObj = {
    path: loadMore,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const get_monthly_leads_api = async (url) => {
  let loadMore = "api/consultant/the_cosmos_data/delegate_monthly_lead_count";
  if (url) {
    loadMore = url;
  }
  const requestObj = {
    path: loadMore,
    method: "GET",
    headers: {
      "x-sh-auth": client_api_token,
    },
  };
  return invokeApi(requestObj);
};
export const get_weekly_leads_api = async (url) => {
  let loadMore = "api/consultant/the_cosmos_data/delegate_weekly_lead_count";
  if (url) {
    loadMore = url;
  }
  const requestObj = {
    path: loadMore,
    method: "GET",
    headers: {
      "x-sh-auth": client_api_token,
    },
  };
  return invokeApi(requestObj);
};
export const pin_feed_action = async (data) => {
  const requestObj = {
    path: `api/feeds/status_action/client`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const get_feed_activity_list = async (data) => {
  const requestObj = {
    path: `api/feeds/feed_activity_detail?page=0&limit=1`,
    method: "POST",
    headers: {
      "x-sh-auth": client_api_token,
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const get_more_activity_list = async (path, data) => {
  const requestObj = {
    path: path,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const get_feeds_list = async (loadMore) => {
  const requestObj = {
    path: loadMore,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const get_member_feeds_list = async (loadMore) => {
  console.log(loadMore, "from api");
  const requestObj = {
    path: loadMore,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const get_all_member_feeds_list = async (loadMore) => {
  console.log(loadMore, "from api new api");
  const requestObj = {
    path: loadMore,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const get_series_video_detail = async (video_id) => {
  const requestObj = {
    path: `api/series_videos/${video_id}`,
    method: "GET",
    headers: {
      "x-sh-auth": client_api_token,
    },
  };
  return invokeApi(requestObj);
};

export const get_event_video_detail = async (video_id) => {
  const requestObj = {
    path: `api/dynamite_event_category_video/by_slug/${video_id}`,
    method: "GET",
    headers: {
      "x-sh-auth": client_api_token,
    },
  };
  return invokeApi(requestObj);
};

export const get_feeds_detail = async (feed_id) => {
  const requestObj = {
    path: `api/feeds/delegate_portal/feed_details/v1/${feed_id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const favourite_videos_list = async () => {
  const requestObj = {
    path: `api/series_videos/list_favorite`,
    method: "GET",
    headers: {
      "x-sh-auth": client_api_token,
    },
  };
  return invokeApi(requestObj);
};

export const get_members_and_delegates_api = async (data) => {
  const requestObj = {
    path: `api/feeds/delegate_or_member/list`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const set_favourite_video = async (data) => {
  const requestObj = {
    path: `api/series_videos/favorite_unfavorite`,
    method: "POST",
    headers: {
      "x-sh-auth": client_api_token,
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const add_new_feed = async (data) => {
  const requestObj = {
    path: `api/feeds/delegate_portal/create`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const add_new_feed_with_poll = async (data) => {
  const requestObj = {
    path: `api/feeds/delegate_portal/create_with_poll`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const add_new_feed_member = async (data) => {
  const requestObj = {
    path: `api/feeds/add_feeds_by_delegate/member_feeds`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const update_feed = async (data, feed_id) => {
  const requestObj = {
    path: `api/feeds/update_feed_by_delegate/${feed_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const update_feed_with_poll = async (data, feed_id) => {
  const requestObj = {
    path: `api/feeds/update_feed_by_delegate_with_poll/${feed_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const community_feed_action = async (data) => {
  const requestObj = {
    path: `api/feeds/action/delegate`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const delegate_community_feed_action = async (data) => {
  const requestObj = {
    path: `api/feeds/action/delegate_v1`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const community_feed_action_api = async (data) => {
  const requestObj = {
    path: `api/feeds/delegate_portal/action`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const delete_feed_action = async (id) => {
  const requestObj = {
    path: `api/feeds/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const add_new_feed_v1 = async (data) => {
  const requestObj = {
    path: `api/feeds/member/v1`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const update_feed_v1 = async (data, feed_id) => {
  const requestObj = {
    path: `api/feeds/v1/${feed_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const get_token_for_a_video_stream = async (id) => {
  const requestObj = {
    path: `api/feeds/member/join/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
