import { invokeApi } from "../../bl_libs/invokeApi";

export const get_commission_detail_api = async (page_number, limit, type) => {
  const requestObj = {
    path: `api/consultant/commision?page=${page_number}&limit=${limit}&type=${
      type ? type : "credit"
    }`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
