import React, { useState, useEffect } from "react";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useSnackbar } from "notistack";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { makeStyles } from "@mui/styles";
import { get_root_value } from "src/utils/domUtils";
import TinyEditor from "src/components/GeneralComponents/Ckeditor";
import { useContentSetting } from "src/Hooks/ContentSettingState";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  csvImage,
  docImage,
  pdfImage,
  wordImage,
  audioImage,
  xlsxImage,
  otherImage,
} from "src/assets";

// import {
//   AddDelegateEventApi,
//   EditDelegateEventApi,
// } from "src/DAL/delegateEventCalender/DelegateEventCalendar";

import { LocalizationProvider } from "@mui/x-date-pickers";
import {
  AddProgressAPI,
  DetailProgressApi,
  EditProgressAPI,
  ListProgressCategory,
} from "src/DAL/Progress/Progress";
import { s3baseUrl } from "src/config/config";

const useStyles = makeStyles(() => ({
  paper: {
    background: get_root_value("--popup-background-color"),
    color: get_root_value("--input-text-color"),
  },
}));
export default function AddOrUpdateProgress({
  editValues,
  dataList,
  setShowEditComponent,
  setEditValues,
  formType,
  onCloseDrawer,
  setComments,
}) {
  const settings = useContentSetting();
  const { socket } = settings;
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [shortDescriptionCk, setShortDescriptionCk] = useState("");
  const [image, setImage] = useState();
  const [categoryData, setCategoryData] = useState([]);
  const [previews, setPreviews] = useState("");
  const [state, setState] = useState({
    Type: "daily",
    Description: "",
    endDate: new Date(),
    startDate: new Date(),
    category: "",
  });
  const handleRemove = () => {
    setPreviews("");
    setImage("");
  };
  const handleUpload = (event) => {
    setImage(event.target.files[0]);
    const preview = URL.createObjectURL(event.target.files[0]);
    setPreviews(preview);
  };
  const getCommentImage = (file) => {
    if (file.name) {
      return URL.createObjectURL(file);
    } else {
      return s3baseUrl + file;
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleChangeStartDate = (event) => {
    setState((prevState) => {
      return {
        ...prevState,
        startDate: event.$d,
      };
    });
  };
  const handleChangeEndDate = (event) => {
    setState((prevState) => {
      return {
        ...prevState,

        endDate: event.$d,
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (shortDescriptionCk.length < 1) {
      return enqueueSnackbar("Discription can not be empty !", {
        variant: "error",
      });
    }
    setIsLoading(true);
    var startdateString = moment(state.startDate).format("YYYY-MM-DD");
    var enddateString = moment(state.endDate).format("YYYY-MM-DD");
    const data = new FormData();
    data.append("report_type", state.Type);
    data.append("progress_category", state.category);

    data.append("start_date", startdateString);
    data.append("end_date", enddateString);
    data.append("description", shortDescriptionCk);
    if (image) {
      data.append("image", image);
    }
    const result = await AddProgressAPI(data);
    if (result.code === 200) {
      dataList(
        `api/progress_report/list_progress_report_by_filter_v2?page=0&limit=10`
      );
      setIsLoading(false);
      onCloseDrawer();
      socket.emit("progress_report_internal_note", result);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    if (shortDescriptionCk.length < 1) {
      return enqueueSnackbar("Discription can not be empty !", {
        variant: "error",
      });
    }
    setIsLoading(true);
    var startdateString = moment(state.startDate).format("YYYY-MM-DD");
    var enddateString = moment(state.endDate).format("YYYY-MM-DD");

    const data = new FormData();
    data.append("report_type", state.Type);
    data.append("progress_category", state.category);
    data.append("start_date", startdateString);
    if (state.Type == "daily") {
      data.append("end_date", startdateString);
    } else {
      data.append("end_date", enddateString);
    }
    data.append("description", shortDescriptionCk);
    if (image) {
      data.append("image", image);
    }
    // console.log(...data, "editt dataaa");
    const result = await EditProgressAPI(editValues, data);

    if (result.code === 200) {
      setComments((prev) => {
        return prev.map((comment) => {
          if (comment._id == editValues) {
            const updatedComment = {
              ...comment,
              description: shortDescriptionCk,
              report_type: state.Type,
              start_date: startdateString,
              end_date: state.Type == "daily" ? startdateString : enddateString,
            };

            if (result.progress_report[0]?.image?.thumbnail_2) {
              updatedComment.image = {
                thumbnail_1: result.progress_report[0].image.thumbnail_1,
                thumbnail_2: result.progress_report[0].image.thumbnail_2,
              };
            }

            return updatedComment;
          } else {
            return comment;
          }
        });
      });
      setIsLoading(false);
      onCloseDrawer();
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const getDetailProgress = async () => {
    const result = await DetailProgressApi(editValues);
    if (result.code === 200) {
      setState((prevState) => ({
        ...prevState,
        ["Type"]: result.progress_report.report_type,
        ["category"]: result.progress_report.progress_category,
        ["startDate"]: result.progress_report.start_date,
        ["endDate"]: result.progress_report.end_date,
        ["Description"]: result.progress_report.description,
      }));
      setShortDescriptionCk(result.progress_report.description);
      setPreviews(s3baseUrl + result.progress_report.image.thumbnail_2);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const getCategoryListing = async () => {
    const result = await ListProgressCategory();
    if (result.code === 200) {
      setCategoryData(result.progress_report_category);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (formType === "EDIT") {
      getDetailProgress();
    }
  }, [formType]);
  useEffect(() => {
    getCategoryListing();
  }, []);
  useEffect(() => {
    if (state.Type == "weekly") {
      let dateTo = moment(new Date()).format("YYYY-MM-DD");
      let dateFrom = moment(new Date()).subtract(7, "d").format("YYYY-MM-DD");
      var enddate = moment(new Date()).format("YYYY-MM-DD");

      setState((prevState) => {
        return {
          ...prevState,
          ["startDate"]: dateFrom,
          ["endDate"]: enddate,
        };
      });
    }
    if (state.Type == "monthly") {
      let dateTo = moment(new Date()).format("YYYY-MM-DD");
      let dateFrom = moment(new Date()).subtract(30, "d").format("YYYY-MM-DD");

      setState((prevState) => {
        return {
          ...prevState,
          ["startDate"]: dateFrom,
        };
      });
    }
    if (state.Type == "custom") {
      let dateTo = moment(new Date()).format("YYYY-MM-DD");
      let dateFrom = moment(new Date()).format("YYYY-MM-DD");

      setState((prevState) => {
        return {
          ...prevState,
          ["startDate"]: dateFrom,
        };
      });
    }
    if (state.Type == "daily") {
      let dateTo = moment(new Date()).format("YYYY-MM-DD");
      let dateFrom = moment(new Date()).format("YYYY-MM-DD");

      setState((prevState) => {
        return {
          ...prevState,
          ["startDate"]: dateFrom,
        };
      });
    }
  }, [state.Type]);

  const renderEndDatePicker = () => {
    if (
      state.Type === "weekly" ||
      state.Type === "monthly" ||
      state.Type === "custom"
    ) {
      return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            format="DD-MM-YYYY"
            mask="__/__/____"
            inputFormat="DD-MM-YYYY"
            label="End Date"
            name="endDate"
            value={state.endDate}
            onChange={(e) => handleChangeEndDate(e, "endDate")}
            renderInput={(params) => (
              <TextField {...params} className="mt-3" required={true} />
            )}
          />
        </LocalizationProvider>
      );
    }
  };
  return (
    <div className="container new-memories">
      <form onSubmit={formType === "ADD" ? handleSubmit : handleUpdate}>
        <FormControl variant="outlined" className="mt-3">
          <InputLabel id="demo-simple-select-outlined-label">
            Add Type
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={state.Type}
            onChange={(e) => handleChange(e)}
            label="Add Type"
            name="Type"
            className="svg-color"
            MenuProps={{
              classes: {
                paper: classes.paper,
              },
            }}
            sx={{
              color: get_root_value("--input-text-color"),
            }}
          >
            <MenuItem value="daily">
              <em>Daily</em>
            </MenuItem>
            <MenuItem value="weekly">
              <em>Weekly</em>
            </MenuItem>
            <MenuItem value="monthly">
              <em>Monthly</em>
            </MenuItem>
            <MenuItem value="custom">
              <em>Custom</em>
            </MenuItem>
          </Select>
        </FormControl>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            format="DD-MM-YYYY"
            mask="__/__/____"
            inputFormat="DD-MM-YYYY"
            label="Start Date"
            name="startDate"
            value={state.startDate}
            onChange={(e) => handleChangeStartDate(e, "startDate")}
            renderInput={(params) => (
              <TextField {...params} className="mt-3" required={true} />
            )}
          />
        </LocalizationProvider>
        {renderEndDatePicker()}
        <FormControl variant="outlined" className="mt-4">
          <InputLabel id="demo-simple-select-outlined-label">
            Progress Categories *
          </InputLabel>
          <Select
            required={true}
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={state.category}
            onChange={(e) => handleChange(e)}
            label="Progress Categories"
            name="category"
            className="svg-color"
            sx={{
              color: get_root_value("--input-text-color"),
            }}
            MenuProps={{
              classes: {
                paper: classes.paper,
              },
            }}
          >
            {categoryData.map((category, index) => (
              <MenuItem
                value={category._id}
                eventKey={index}
                key={category._id}
              >
                {category.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <div className="col-12 mt-4">
          <h4>Description *</h4>
          <TinyEditor
            setDetailDescription={setShortDescriptionCk}
            detailDescriptionCk={shortDescriptionCk}
            required={true}
          />
        </div>

        <div className="row w-100 mb-3 mt-4">
          <p>Image (Recommended Size (1000x670))</p>
          <div className="col-md-12 mt-2 d-flex">
            {previews && (
              <span className="preview mt-2">
                <span onClick={handleRemove}>x</span>
                <img src={previews} height={50} width="100%" />
              </span>
            )}
            {previews == "" && (
              <span className="upload-button mt-2">
                <input
                  color="primary"
                  accept="image/*"
                  type="file"
                  id="icon-button-file"
                  style={{ display: "none" }}
                  onChange={handleUpload}
                />
                <label htmlFor="icon-button-file">
                  <CloudUploadIcon />
                </label>
              </span>
            )}
          </div>
        </div>

        <div className="text-end mt-3">
          <button className="submit-button" disabled={isLoading}>
            {isLoading
              ? formType === "ADD"
                ? "Saving..."
                : "Updating..."
              : formType === "ADD"
              ? "Save"
              : "Update"}
          </button>
        </div>
      </form>
    </div>
  );
}
