import React, { useState, useEffect, useMemo } from "react";
// import "react-credit-cards/es/styles-compiled.css";

import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import { get_site_setting } from "src/DAL/Payment/Payment";
// import { useStripe, CardElement, useElements } from "@stripe/react-stripe-js";
import { useStripe, CardElement, useElements } from "@stripe/react-stripe-js";
import { useSnackbar } from "notistack";
import { get_root_value } from "src/utils/domUtils";

const CardInformation = ({ handleCardAction, isLoading, setIsLoading }) => {
  const stripe = useStripe();
  const elements = useElements();
  const { enqueueSnackbar } = useSnackbar();
  const [inputs, setInputs] = React.useState({
    cvc: "",
    expiry: "",
    focus: "",
    name: "",
    number: "",
  });

  const [siteSettings, setSiteSettings] = useState({});

  const handleInputChange = (e) => {
    // const { name, value } = e.target;
    const { name, value } = e.target;
    setInputs((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleInputFocus = (e) => {
    const { name, value } = e.target;
    setInputs((prevState) => ({
      ...prevState,
      focus: name,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (elements == null) {
      return;
    }

    setIsLoading(true);

    const cardElement = elements.getElement(CardElement);

    stripe.createToken(cardElement).then(function (result) {
      // Handle result.error or result.token

      if (result.error) {
        setIsLoading(false);
        enqueueSnackbar(result.error.message, { variant: "error" });
        return;
      }

      handleCardAction(result.token.id, cardElement);
    });
  };

  // get_site_setting
  const getSiteSetting = async () => {
    const result = await get_site_setting();
    if (result.code === 200) {
      setSiteSettings(result.site_setting);

      // Testing or live keys
      if (result.site_setting.stripe_mode === "sandBox") {
        if (window.Stripe.setPublishableKey) {
          window.Stripe.setPublishableKey(
            result.site_setting.sandBox_publish_key
          );
        }
      } else {
        if (window.Stripe.setPublishableKey) {
          window.Stripe.setPublishableKey(result.site_setting.live_publish_key);
        }
      }
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  useEffect(() => {
    getSiteSetting();
  }, []);

  return (
    <>
      <h2 className="mt-5 mb-3">Enter Card Details</h2>
      <div className="row">
        <div className="col-12 text-end">
          <form onSubmit={handleSubmit}>
            <div className="card p-3">
              <CardElement
                options={{
                  hidePostalCode: true,
                  style: {
                    base: {
                      iconColor: get_root_value("--portal-theme-primary"),
                      color: get_root_value("--text-primary-color"),
                      "::placeholder": {
                        color: get_root_value("--text-primary-color"),
                      },
                      fontSize: "18px",
                    },
                  },
                }}
              />
            </div>
            {isLoading ? (
              <button
                className="small-contained-button mt-4 mb-4"
                disabled={true}
              >
                Processing...
              </button>
            ) : (
              <button
                className="small-contained-button mt-4 mb-4"
                type="submit"
                disabled={!stripe || !elements}
              >
                Pay Now
              </button>
            )}
          </form>
        </div>
      </div>
    </>
  );
};
export default CardInformation;
