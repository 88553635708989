import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  IconButton,
  CircularProgress,
  TextField,
  Autocomplete,
  Paper,
  FormHelperText,
  styled,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useRef, useState } from "react";
import { TimeZones } from "src/utils/constants";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import PhoneInput from "react-phone-input-2";
import { makeStyles } from "@mui/styles";
import {
  add_sale_team_member,
  edit_sale_team_member,
} from "src/DAL/salesTeam/SalesTeam";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { s3baseUrl } from "src/config/config";
const Input = styled("input")({
  display: "none",
});
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
let info = {
  first_name: "",
  last_name: "",
  email: "",
  password: "",
  contact_number: "",
  address: "",
  city: "",
  state: "",
  timeZone: "",
  image: "",
  biography: "",
  status: true,
  // commission_on_transaction: "first_transection",
  // commission_percentage: "",
};
const AddOrUpdateSalesTeam = ({ type }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { params } = useParams();
  const phoneInputRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const [file, setProfileImage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [timeZoneValue, setTimeZoneValue] = useState("Europe/Dublin");
  const [inputValue, setInputValue] = useState("");
  const [inputs, setInputs] = useState(info);

  const handleChange = (e) => {
    let name = e.target.name;
    let newValue = e.target.value;
    setInputs((prevState) => {
      return {
        ...prevState,
        [name]: newValue,
      };
    });
  };

  const fileChangedHandler = (e) => {
    setProfileImage(URL.createObjectURL(e.target.files[0]));
    setInputs({
      ...inputs,
      ["image"]: e.target.files[0],
    });
  };

  const handleChangeOthers = (name, value) => {
    setInputs((values) => ({ ...values, [name]: value }));
  };
  console.log(
    inputs.biography.length,
    "inputs.biography.lengthinputs.biography.length"
  );
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (inputs.biography.length > 500) {
      enqueueSnackbar("Biography is not more then  500 characters", {
        variant: "error",
      });
      setIsLoading(false);
      return;
    }
    if (!inputs.contact_number) {
      enqueueSnackbar("Contact No. is required", { variant: "error" });

      setIsLoading(false);
      return;
    }
    const formData = new FormData();
    formData.append("first_name", inputs.first_name);
    formData.append("last_name", inputs.last_name);
    if (file) {
      formData.append("image", inputs.image);
    }
    formData.append("biography", inputs.biography);
    formData.append("email", inputs.email);
    if (type !== "edit") {
      formData.append("password", inputs.password);
    }
    formData.append("contact_number", inputs.contact_number);
    formData.append("status", inputs.status);
    formData.append("address", inputs.address);
    formData.append("city", inputs.city);
    formData.append("state", inputs.state);
    formData.append("time_zone", timeZoneValue);
    // formData.append(
    //   "commission_on_transaction",
    //   inputs.commission_on_transaction
    // );
    // formData.append("commission_percentage", inputs.commission_percentage);
    setIsLoading(true);
    const result =
      type == "edit"
        ? await edit_sale_team_member(formData, state._id)
        : await add_sale_team_member(formData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      navigate(-1);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (state) {
      setInputs(state);

      setTimeZoneValue(state.time_zone);
    }
  }, [state]);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <div className="container">
      <div className="row mobile-margin display-flex">
        <div className="col-12 d-flex ">
          <span>
            <IconButton
              className="back-screen-button mb-4"
              onClick={() => navigate(-1)}
            >
              <ArrowBackIcon />
            </IconButton>
          </span>

          <h2 className="ms-2">{`${
            type == "edit" ? "Edit" : "Add"
          } Sales Team`}</h2>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <TextField
                id="outlined-basic"
                label="First Name"
                variant="outlined"
                fullWidth
                name="first_name"
                required
                value={inputs.first_name}
                onChange={handleChange}
              />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <TextField
                id="outlined-basic"
                label="Last Name"
                variant="outlined"
                fullWidth
                name="last_name"
                required
                value={inputs.last_name}
                onChange={handleChange}
              />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <TextField
                id="outlined-basic"
                label="Email"
                variant="outlined"
                fullWidth
                name="email"
                required
                type="email"
                value={inputs.email}
                onChange={handleChange}
              />
            </div>
            {type !== "edit" && (
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <TextField
                  id="outlined-basic"
                  label="Password"
                  variant="outlined"
                  fullWidth
                  name="password"
                  required
                  type="password"
                  value={inputs.password}
                  onChange={handleChange}
                />
              </div>
            )}
            <div
              className="col-lg-6 col-md-6 col-sm-12 mt-4 table-phone-input"
              ref={phoneInputRef}
            >
              <PhoneInput
                ref={phoneInputRef}
                country={"gb"}
                inputClass="react-phone-input"
                dropdownClass="flag-input"
                inputProps={{
                  name: "contact_number",
                  autoComplete: "off",
                }}
                value={inputs.contact_number}
                enableSearch
                disableSearchIcon
                countryCodeEditable={false}
                buttonStyle={{
                  border: "none",
                  backgroundColor: "transparent",
                }}
                onChange={(value) => {
                  handleChangeOthers("contact_number", value);
                }}
              />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <TextField
                id="outlined-basic"
                label="City"
                variant="outlined"
                fullWidth
                name="city"
                value={inputs.city}
                onChange={handleChange}
              />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <TextField
                id="outlined-basic"
                label="State/Country"
                variant="outlined"
                fullWidth
                name="state"
                value={inputs.state}
                onChange={handleChange}
              />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <TextField
                id="outlined-basic"
                label="Address"
                variant="outlined"
                fullWidth
                name="address"
                value={inputs.address}
                onChange={handleChange}
              />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <Autocomplete
                value={timeZoneValue}
                onChange={(event, newValue) => {
                  setTimeZoneValue(newValue);
                }}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                PaperComponent={({ children }) => (
                  <Paper style={{ background: "#1d1c1d", color: "#fff" }}>
                    {children}
                  </Paper>
                )}
                id="controllable-states-demo"
                options={TimeZones}
                renderInput={(params) => (
                  <TextField {...params} label="Time Zone *" />
                )}
              />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Status *</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="status"
                  value={inputs.status}
                  label="Status *"
                  onChange={handleChange}
                >
                  <MenuItem value={true}>Active</MenuItem>
                  <MenuItem value={false}>Inactive</MenuItem>
                </Select>
              </FormControl>
            </div>
            {/* <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Commission On Transaction *
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="commission_on_transaction"
                  value={inputs.commission_on_transaction}
                  label="Commission On Transaction *"
                  onChange={handleChange}
                >
                  <MenuItem value={"first_transection"}>
                    First Transaction
                  </MenuItem>
                  <MenuItem value={"every_transection"}>
                    Every Transaction
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <TextField
                id="outlined-basic"
                label="Commission Percentage"
                variant="outlined"
                fullWidth
                name="commission_percentage"
                value={inputs.commission_percentage}
                onChange={handleChange}
                type="number"
                InputProps={{ inputProps: { min: 0, max: 100 } }}
                required={true}
              />
            </div> */}
            <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
              <div className="row w-100 div-style ms-0 pt-0">
                <div className="col-5">
                  <p className="">Upload Image *</p>
                  <FormHelperText className="pt-0">
                    Image Size(1000 X 670) ("JPG", "JPEG", "PNG","WEBP")
                  </FormHelperText>
                </div>
                <div className="col-2">
                  {file ? (
                    <img src={file} height="50" />
                  ) : (
                    <>
                      {inputs.image.thumbnail_1 && (
                        <img
                          src={s3baseUrl + inputs.image.thumbnail_1}
                          height="50"
                        />
                      )}
                    </>
                  )}
                </div>
                <div className="col-5 text-end pt-2">
                  <label htmlFor="contained-button-file">
                    <Input
                      accept="image/*"
                      id="contained-button-file"
                      multiple
                      type="file"
                      name="image"
                      onChange={fileChangedHandler}
                    />

                    <Button
                      className="small-contained-button"
                      startIcon={<FileUploadIcon />}
                      component="span"
                    >
                      Upload
                    </Button>
                  </label>
                </div>
              </div>
              {inputs.image.name == "" ? null : (
                <p className="text-secondary">{inputs.image.name}</p>
              )}
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="mt-4 textarea-block">
                <TextField
                  id="outlined-basic"
                  label="Biography"
                  variant="outlined"
                  rows={4}
                  multiline
                  fullWidth
                  size="small"
                  value={inputs.biography}
                  name="biography"
                  onChange={(e) => handleChange(e)}
                />
                <FormHelperText>Maximum limit 500 characters</FormHelperText>
              </div>
            </div>
            <div className="text-end ms-auto">
              <button className="small-contained-button mt-2 ">
                {type == "edit" ? "Update" : "Submit"}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddOrUpdateSalesTeam;
