import React from "react";
import {
  Checkbox,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  TextareaAutosize,
  Tooltip,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import faker from "faker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { useEffect, useState } from "react";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import {
  GetTrackerApi,
  deleteDiaryApi,
  get_intention_dates,
  get_intention_dates_spacific,
  move_to_tomorrow,
  updateDynamiteDiaryApi,
  update_all_data,
  update_reminder_time_api,
} from "src/DAL/Tracking/Tracking";
import moment from "moment";
import { uploadImageOns3 } from "src/DAL/commonApi/commonApi";
import { s3baseUrl } from "src/config/config";
import CustomPopover from "../CustomPopover";
import CustomConfirmation from "../GeneralComponents/CustomConfirmation";
import { useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MUIAutocomplete from "../GeneralComponents/MUIAutocomplete";
import { get_member_filter_data_list_api } from "src/DAL/member/Member";
import ContentAudioPlayer from "./ContentAudioPlayer";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
  fonts: {
    fontSize: "13px",
    fontWeight: "500",
  },
}));
const AccountabilityTrackerForSpacificUser = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = React.useState(true);
  const [calenderDate, setCalenderDate] = useState(new Date());
  const [objectDataAvailable, setObjectDataAvailable] = useState(false);
  const [firstDiayDate, setFirstDiayDate] = useState(new Date());
  const [trackerArray, setTrackerArray] = useState([]);
  const [viewContent, setViewContent] = useState(false);
  const [contentData, setContentData] = useState(null);
  const [objectData, setObjectData] = useState(false);
  const [prevDiaries, setPrevDiaries] = useState([]);

  const [data, setdata] = useState();
  const classes = useStyles();
  const myFunctionRef = useRef(null);
  const [addArray, setaddArray] = useState([
    {
      _id: faker.datatype.uuid(),
      option: "",
      complete: false,
      is_moved_to_tomorrow: false,
    },
    {
      _id: faker.datatype.uuid(),
      option: "",
      complete: false,
      is_moved_to_tomorrow: false,
    },
    {
      _id: faker.datatype.uuid(),
      option: "",
      complete: false,
      is_moved_to_tomorrow: false,
    },
    {
      _id: faker.datatype.uuid(),
      option: "",
      complete: false,
      is_moved_to_tomorrow: false,
    },
  ]);
  const [inputs, setinputs] = useState({
    morning_time: null,
    evening_time: null,
    morning_days: [],
    evening_days: [],
    affirm: "",
    paridigm: false,
    goal: false,
    gratitude: false,
  });
  const navigate = useNavigate();
  const [previews, setPreviews] = useState("");
  const [previewsUrl, setPreviewsUrl] = useState("");
  const [previewsUrl2, setPreviewsUrl2] = useState("");
  const [previewsUrl3, setPreviewsUrl3] = useState("");
  const [previews2, setPreviews2] = useState("");
  const [deleteValue, setDeleteValue] = useState("");
  const [previews3, setPreviews3] = useState("");
  const [delegatesList, setDelegatesList] = useState([]);
  const [delegateSearch, setDelegateSearch] = useState("");
  const [delegate, setDelegate] = useState({ delegate: null });
  const [searchText, setSearchText] = useState("");
  const handleChangeOthers = (event, name) => {
    setDelegate((values) => ({ ...values, [name]: event }));
    if (event) {
      GetIntentionsData(new Date(state.date_time), event._id);
    }
  };
  const { state } = useLocation();

  const GetIntentionsData = async (date, userId) => {
    setIsLoading(true);

    const result = await get_intention_dates_spacific(
      userId ?? state.user,
      date
        ? moment(date).format("DD-MM-YYYY")
        : calenderDate
        ? moment(calenderDate).format("DD-MM-YYYY")
        : moment(new Date()).format("DD-MM-YYYY")
    );
    if (result.code == 200) {
      let first_daily_dynamite_date = new Date();
      let morning_reminder_time = null;
      let evening_reminder_time = null;
      let tracker_intention = [];
      let consultant = {
        name:
          result.consultant.first_name +
          " " +
          result.consultant.last_name +
          "(" +
          result.consultant.email +
          ")",
        _id: result.consultant._id,
      };

      if (result.first_daily_dynamite_date) {
        first_daily_dynamite_date = result.first_daily_dynamite_date;
      }
      if (result.daily_dynamite?.tracker_intention?.length > 0) {
        tracker_intention = result.daily_dynamite.tracker_intention;
      }
      if (result.daily_dynamite_morning_reminder_time) {
        morning_reminder_time = result.daily_dynamite_morning_reminder_time;
      }
      if (result.daily_dynamite_evening_reminder_time) {
        evening_reminder_time = result.daily_dynamite_evening_reminder_time;
      }
      setdata(result?.delegate_report_setting);

      setDelegate((inputs) => ({
        ...inputs,
        ["delegate"]: consultant,
      }));

      setObjectDataAvailable(result.daily_dynamite._id ? true : false);
      setPrevDiaries(result.past_activities);
      setObjectData(result.daily_dynamite);
      setFirstDiayDate(first_daily_dynamite_date);
      setinputs((inputs) => ({
        ...inputs,
        ["morning_time"]: morning_reminder_time,
        ["evening_time"]: evening_reminder_time,
        ["affirm"]: result.daily_dynamite.note,
      }));
      setTrackerArray(tracker_intention);

      if (result.daily_dynamite.statement_array?.length > 0) {
        setaddArray(result.daily_dynamite.statement_array);
      } else {
        setaddArray([
          {
            _id: faker.datatype.uuid(),
            option: "",
            complete: false,
            is_moved_to_tomorrow: false,
          },
          {
            _id: faker.datatype.uuid(),
            option: "",
            complete: false,
            is_moved_to_tomorrow: false,
          },
          {
            _id: faker.datatype.uuid(),
            option: "",
            complete: false,
            is_moved_to_tomorrow: false,
          },
        ]);
      }
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const getDataList = async () => {
    let result = await get_member_filter_data_list_api(
      searchText,
      delegateSearch,
      "all-member"
    );
    if (result.code == 200) {
      let delegates_list = result.delegates_list.map((delegate) => {
        let name =
          delegate.first_name +
          " " +
          delegate.last_name +
          "(" +
          delegate.email +
          ")";
        return {
          name: name,
          _id: delegate._id,
        };
      });
      setDelegatesList(delegates_list);
    } else {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
    }
  };

  const handleViewContent = (data) => {
    setContentData(data);
    setViewContent(true);
  };

  useEffect(() => {
    GetIntentionsData();
  }, [calenderDate]);
  useEffect(() => {
    if (state) {
      GetIntentionsData(new Date(state.date_time));
      setCalenderDate(new Date(state.date_time));
    }
  }, [state]);

  useEffect(() => {
    getDataList();
  }, [delegateSearch]);
  useEffect(() => {
    if (viewContent === false) {
      setContentData(null);
    }
  }, [viewContent]);
  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <>
      <div>
        <div className="container ">
          <div className="row">
            <div className="col-lg-8 col-md-8 d-flex">
              <span>
                {" "}
                <IconButton
                  className="back-screen-button mb-1"
                  onClick={() =>
                    navigate("/accountability-tracker-report", {
                      state: 4,
                    })
                  }
                >
                  <ArrowBackIcon />
                </IconButton>
              </span>
              <h2
                style={{
                  padding: "0px",
                  color: "#f6bd4b",
                  marginLeft: "8px",
                }}
              >
                {data?.main_heading ?? ""}
              </h2>
            </div>{" "}
            <div className="col-lg-4 col-md-4">
              <MUIAutocomplete
                inputLabel="Choose Delegate"
                selectedOption={delegate.delegate}
                setSelectedOption={(e) => {
                  handleChangeOthers(e, "delegate");
                }}
                optionsList={delegatesList}
                name="name"
                setCustomSearch={setDelegateSearch}
              />
            </div>
          </div>
          {inputs.affirm && (
            <div className="col-lg-12 mt-4">
              <p className="afrim mb-0">{data?.affirm_title}</p>
              <div className="mt-1">
                <TextareaAutosize
                  aria-label="empty textarea"
                  placeholder={data?.affirm_placeHolder}
                  className="text-color textarea-autosize"
                  required
                  value={inputs.affirm}
                  // onChange={(e) => handleChangeInputs(e, "affirm")}
                  minRows={4}
                  name="option"
                  style={{
                    width: "100%",
                    borderRadius: "11px",
                    background: "#1D2537",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    height: "150px",
                    paddingLeft: "15px",
                    color: "white",
                    fontSize: "15px",
                    border: "2px solid rgba(71, 66, 34, 0.3)",
                  }}
                />
              </div>
            </div>
          )}
        </div>
        <form>
          <div className="container mt-3" ref={myFunctionRef}>
            <div className="row">
              <h2
                className="mt-2 ms-1"
                style={{
                  fontSize: "20px",
                  fontWeight: "600",

                  color: "white",
                }}
              >
                {data?.intentions_heading}
              </h2>
              <div className="col-lg-7 col-md-7 mt-3">
                {addArray?.map((data, index) => {
                  return (
                    <div className="d-flex position-relative mt-1">
                      <div className="mt-2 field-container">
                        <TextareaAutosize
                          aria-label="empty textarea"
                          placeholder={`${index + 1}.`}
                          className="text-color textarea-autosize"
                          required
                          value={data.option}
                          minRows={1}
                          name="option"
                          style={{
                            width: "100%",
                            borderRadius: "11px",
                            background: "#1D2537",
                            paddingTop: "10px",
                            paddingBottom: "10px",
                            paddingLeft: "15px",
                            color: "white",
                            fontSize: "15px",
                            border: "2px solid rgba(71, 66, 34, 0.3)",
                          }}
                        />
                        {data.option && (
                          <div className="text-end intentionCheckox d-flex justify-content-between on-mobile">
                            <div></div>
                            <div>
                              <Checkbox
                                checked={data?.complete}
                                inputProps={{ "aria-label": "controlled" }}
                                style={{ padding: "2px" }}
                              />

                              {data.complete == true ? (
                                <span className="pt-2">
                                  Intention Completed
                                </span>
                              ) : (
                                <span className="pt-2">
                                  Intention incomplete
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className=" top-section-video col-lg-5 col-sm-5 mt-3">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <StaticDatePicker
                    className="static-date-picker"
                    value={calenderDate}
                    minDate={firstDiayDate ? firstDiayDate : new Date()}
                    maxDate={new Date()}
                    renderInput={(params) => <TextField {...params} />}
                    componentsProps={{
                      actionBar: {
                        actions: ["today"],
                      },
                    }}
                  />
                </LocalizationProvider>
              </div>
            </div>
          </div>
          {trackerArray.length > 0 && (
            <div className="container mt-4">
              <div className="row">
                {" "}
                <h2
                  className="mt-2 ms-1"
                  style={{
                    fontSize: "20px",
                    fontWeight: "600",
                  }}
                >
                  {data?.completed_heading}
                </h2>
                <div className="col-lg-12 ">
                  {trackerArray.map((item, index) => {
                    console.log(item, "itemitem");
                    return (
                      <div className="tracking-intention">
                        <div
                          className="d-flex justify-content-between "
                          style={{ alignItems: "end", flexWrap: "wrap" }}
                        >
                          <p className="mb-0" style={{ fontSize: "17px" }}>
                            <Checkbox
                              checked={item.status}
                              inputProps={{ "aria-label": "controlled" }}
                            />
                            {item.statement}
                          </p>

                          <div
                            className="width text-end d-flex"
                            style={{
                              alignItems: "end",
                              justifyContent: "end",
                            }}
                          >
                            {!item.image ? (
                              <Tooltip
                                title={`${
                                  inputs.goal
                                    ? "Upload Goal Statement Image"
                                    : "Complete Goal Statement before Uploading Image"
                                }`}
                              >
                                <span
                                  className={`${
                                    inputs.goal
                                      ? "upload-button"
                                      : "upload-button-cross"
                                  } mb-1`}
                                >
                                  <input
                                    color="primary"
                                    accept="image/*,.pdf,.xlsx,.xls,.docx,.csv,.doc"
                                    type="file"
                                    id="icon-button-file"
                                    style={{ display: "none" }}
                                    disabled={!inputs.goal}
                                    name="goal"
                                    // onChange={(e) => fileChangedHandler(e)}
                                    multiple={true}
                                  />
                                  <label
                                    htmlFor="icon-button-file"
                                    className=""
                                    style={{
                                      cursor: inputs.goal
                                        ? "pointer"
                                        : "not-allowed",
                                    }}
                                  >
                                    <CloudUploadIcon />
                                  </label>
                                </span>
                              </Tooltip>
                            ) : (
                              <div className="preview">
                                <img
                                  src={s3baseUrl + item.image}
                                  style={{
                                    padding: "10px",
                                    width: "120px",
                                    height: "auto",
                                    maxHeight: "110px",
                                    cursor: "pointer",
                                  }}
                                  className="main"
                                />
                              </div>
                            )}
                          </div>
                        </div>
                        <hr className="mt-1" />
                        {item.content_type == "audio" ? (
                          <ContentAudioPlayer item={item} />
                        ) : (
                          <>
                            {item.content && (
                              <span
                                className="view-content"
                                onClick={() => handleViewContent(item)}
                              >
                                View Content
                              </span>
                            )}
                          </>
                        )}
                        {item.is_text_box_shown && (
                          <div className="col-lg-12 mt-3">
                            <div className="mt-1">
                              <TextareaAutosize
                                aria-label="empty textarea"
                                placeholder={"write comments here"}
                                className="text-color textarea-autosize"
                                required
                                disabled
                                value={item.text_box_content}
                                minRows={2}
                                name="text_box_content"
                                style={{
                                  width: "100%",
                                  borderRadius: "11px",
                                  background: "#1D2537",
                                  paddingTop: "10px",
                                  paddingBottom: "10px",
                                  height: "100px",
                                  paddingLeft: "15px",
                                  color: "white",
                                  fontSize: "15px",
                                  border: "2px solid rgb(71 66 34 / 55%)",
                                }}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
        </form>
        <div></div>
      </div>
    </>
  );
};

export default AccountabilityTrackerForSpacificUser;
