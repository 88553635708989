import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate, useParams } from "react-router-dom";
import { Divider, IconButton, Tooltip } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { htmlDecode } from "src/utils/convertHtml";
import { addOrUpdateQuestionsAnswers } from "src/DAL/DelegatePrograms/delegateProgramApi";
const useStyles = makeStyles((theme) => ({
  dense: {
    marginTop: theme.spacing(0),
  },
}));

const Message = ({
  replies,
  data,
  goalStatementCompletedStatus,
  goalStatementCompletedDate,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [toggle, setToggle] = useState(true);
  const params = useParams();
  const [responceDrawer, setResponceDrawer] = useState(false);
  const [repliesDrawer, setRepliesDrawer] = useState(false);
  const [isLoadingAll, setIsLoadingAll] = useState(false);
  const [isAllAnswered, setIsAllAnswered] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [questionMessages, setQuestionMessages] = useState([]);
  const [comments, setComments] = useState([]);
  const [question, setQuestion] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  // const { contentSettingData, userInfo, adminTimeZone } = useContentSetting();

  const handleKimResponce = (question, comments) => {
    setResponceDrawer(true);
    setComments(comments);
    setQuestion(question);
  };
  const handleKimReplies = () => {
    setRepliesDrawer(true);
  };
  const handleChange = (index, event) => {
    let value = event.target.value;
    let temp_state = [...questionMessages];
    let temp_element = { ...temp_state[index] };
    temp_element.answer = value;
    temp_state[index] = temp_element;
    setQuestionMessages(temp_state);
  };

  const startLoading = (index) => {
    let temp_state = [...questionMessages];
    let temp_element = { ...temp_state[index] };
    temp_element.isLoading = true;
    temp_state[index] = temp_element;
    setQuestionMessages(temp_state);
  };

  const hideLoading = (index) => {
    const length = questionMessages.length - 1;
    let temp_state = [...questionMessages];
    let temp_element = { ...temp_state[index] };
    temp_element.isLoading = false;
    temp_element.isExpanded = false;
    temp_state[index] = temp_element;
    if (index !== length) {
      let temp_nex_element = { ...temp_state[index + 1] };
      temp_nex_element.isExpanded = true;
      temp_state[index + 1] = temp_nex_element;
    }
    setQuestionMessages(temp_state);
  };

  const handleOpenDrawer = () => {
    setResponceDrawer(true);
  };

  const handleCloseDrawer = () => {
    setResponceDrawer(false);
  };

  const handleOpenRepliesDrawer = () => {
    setRepliesDrawer(true);
  };

  const handleCloseRepliesDrawer = () => {
    setRepliesDrawer(false);
  };

  const handleSubmitReply = () => { };

  const expandArea = (index) => {
    let temp_state = [...questionMessages];
    let temp_element = { ...temp_state[index] };
    if (temp_element.isExpanded === false) {
      temp_element.isExpanded = true;
    } else {
      temp_element.isExpanded = false;
    }
    temp_state[index] = temp_element;
    setQuestionMessages(temp_state);
  };

  const handleSubmit = async (e, index) => {
    e.preventDefault();

    // startLoading(index);
    // let temp_state = [...questionMessages];
    // let temp_element = { ...temp_state[index] };
    // checkIfAllAnswered(questionMessages);
    // const formDataObject = {
    //   question_id: temp_element._id,
    //   answer: temp_element.answer,
    //   goal_statement_completed_status: isAllAnswered,
    // };

    // const result = await AddSingleAnswerApi(JSON.stringify(formDataObject));
    // if (result.code === 200) {
    //   hideLoading(index);
    //   enqueueSnackbar(result.message, { variant: "success" });
    // } else {
    //   enqueueSnackbar(result.message, { variant: "error" });
    //   hideLoading(index);
    // }
  };

  const handleSubmitAll = async (button) => {
    setIsLoadingAll(true);
    console.log(questionMessages, "questionMessages");
    let answersArray = [];
    let answersObject = {};
    // checkIfAllAnswered(questionMessages);
    questionMessages.map((answers, index) => {
      answersObject = {
        _id: answers._id,
        answer_statement: answers.answer ? answers.answer : "",
      };
      answersArray.push(answersObject);
    });

    const formDataObject = {
      program_slug: params.program_slug,
      question_answer_array: answersArray,
    };
    console.log(formDataObject, "formDataObject");

    const result = await addOrUpdateQuestionsAnswers(
      JSON.stringify(formDataObject)
    );
    if (result.code === 200) {
      setIsLoadingAll(false);
      setIsSubmitting(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingAll(false);
      setIsSubmitting(false);
    }
  };

  const checkIfAllAnswered = (data) => {
    data.map((value, index) => {
      if (value.answer === "") {
        setIsAllAnswered(false);
        return;
      } else {
        setIsAllAnswered(true);
      }
    });
  };

  useEffect(() => {
    let questionsArray = [];
    data.map((value, index) => {
      if (index === 0) {
        questionsArray.push({
          ...value,
          isExpanded: true,
          isLoading: false,
        });
      } else {
        questionsArray.push({
          ...value,
          isExpanded: false,
          isLoading: false,
        });
      }
      setQuestionMessages(questionsArray);
    });
    checkIfAllAnswered(data);
  }, [data]);
  console.log(questionMessages, "questionMessages");
  return (
    <>
      <div className="row goal-statement-messages">
        <div className="col-12">
          <IconButton
            className="back-screen-button"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon />
          </IconButton>
        </div>
        <div className="col-12 my-3">
          <h2>Delegate Questions</h2>
        </div>
        <div className="col">
          {questionMessages.length > 0
            ? questionMessages.map((message, index) => {
              return (
                <div className="card mb-2 " key={message.id}>
                  <form
                    onSubmit={(e) => {
                      handleSubmit(e, index);
                    }}
                  >
                    <Accordion expanded={message.isExpanded}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        onClick={() => expandArea(index)}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className="svg-color text-white"
                      >
                        <div className="d-flex goal_statement_yes_icons justify-content-between w-100">
                          <Typography>
                            {htmlDecode(message.question_statement)}
                          </Typography>
                          {/* <img
                              src={
                                message.answer
                                  ? goal_statement_yes_icons
                                  : goal_statement_no_icons
                              }
                              className="float-end"
                              alt=""
                            /> */}
                          {/* <img
                              src={
                                message.answer
                                  ? goal_statement_yes_icons_new1
                                  : goal_statement_no_new_icons
                              }
                              className="float-end"
                              alt=""
                            /> */}
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        {message.question_type == "textarea" ? (
                          <TextField
                            id="filled-multiline-flexible"
                            fullWidth
                            multiline
                            rows={5}
                            label={htmlDecode(message.question_placeholder)}
                            className={(classes.dense, "textarea-color")}
                            value={
                              message?.answer
                                ? htmlDecode(message.answer)
                                : ""
                            }
                            onChange={(e) => handleChange(index, e)}
                            variant="outlined"
                            name="message"
                          />
                        ) : (
                          <TextField
                            id="filled-multiline-flexible"
                            fullWidth
                            label={htmlDecode(message.question_placeholder)}
                            className={(classes.dense, "textarea-color")}
                            value={
                              message.answer ? htmlDecode(message.answer) : ""
                            }
                            onChange={(e) => handleChange(index, e)}
                            variant="outlined"
                            name="message"
                          />
                        )}

                        <div className="d-flex justify-content-between mt-3">
                          {/* <button
                              onClick={(e) => {
                                e.preventDefault();
                                handleKimResponce(
                                  message.question,
                                  message.comment
                                );
                              }}
                              className="small-contained-button me-2"
                            >
                              {`Your Dynamite Response`}
                            </button> */}
                          {/* <button
                              className="small-contained-button text-end"
                              disabled={message.isLoading}
                            >
                              {message.isLoading ? "Saving..." : "Save & Next"}
                            </button> */}
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </form>
                </div>
              );
            })
            : ""}
        </div>
      </div>
      <div className="row">
        <div className="d-flex justify-content-end mt-3">
          {/* <button onClick={handleKimReplies} className="small-contained-button">
            {`Your Dynamite Reply`}
          </button> */}
          <div id="fixedbutton">
            {/* {contentSettingData.goal_statement_button_text &&
              goalStatementCompletedStatus === false && (
                <Tooltip title={contentSettingData.goal_statement_button_info}>
                  <button
                    className={`small-contained-button me-2 ${
                      goalStatementCompletedStatus === false &&
                      isAllAnswered === false
                        ? "show-btn-disabled"
                        : ""
                    }`}
                    disabled={
                      goalStatementCompletedStatus === false &&
                      isAllAnswered === false
                        ? true
                        : isSubmitting
                    }
                    onClick={() => {
                      handleSubmitAll("save-and-submit");
                    }}
                  >
                    {isSubmitting ? "Submitting..." : "Save and Submit"}
                  </button>
                </Tooltip>
              )} */}
            <button
              onClick={handleSubmitAll}
              className="small-contained-button"
              disabled={isLoadingAll}
            >
              {isLoadingAll ? "Saving..." : "Save All"}
            </button>
          </div>
        </div>
      </div>
      {goalStatementCompletedStatus === true && (
        <div className="row mt-4">
          <div className="col-md-8 goal-statement-completed-text">
            {/* <p>
              Goal Statement was completed on the &nbsp;
              {get_date_with_user_time_zone(
                goalStatementCompletedDate,
                "DD MMM YYYY",
                userInfo.time_zone,
                adminTimeZone
              )}
            </p> */}
          </div>
          {/* <div className="col-md-4 download-pdf-button-box">
            <PDFDownloadLink
              document={
                <MyDocument
                  data={questionMessages}
                  completed_date={get_date_with_user_time_zone(
                    goalStatementCompletedDate,
                    "DD MMM YYYY",
                    userInfo.time_zone,
                    adminTimeZone
                  )}
                />
              }
              fileName="DynamiteLifeStyle"
              className="download-pdf-button"
            >
              {({ loading }) => (loading ? "Download" : "Download")}
            </PDFDownloadLink>
          </div> */}
        </div>
      )}

      {/* <CustomDrawer
        isOpenDrawer={responceDrawer}
        onOpenDrawer={handleOpenDrawer}
        onCloseDrawer={handleCloseDrawer}
        pageTitle={`Your Dynamite Response`}
        componentToPassDown={
          <KimResponce question={question} data={comments} />
        }
      /> */}
      {/* <CustomDrawer
        isOpenDrawer={repliesDrawer}
        onOpenDrawer={handleOpenRepliesDrawer}
        onCloseDrawer={handleCloseRepliesDrawer}
        pageTitle={`Your Dynamite Reply`}
        componentToPassDown={<KimResponce data={replies} />}
      /> */}
    </>
  );
};

export default Message;
