import { invokeApi } from "../../bl_libs/invokeApi";

export const get_list_internal_tickets_with_pagination_new = async (
  valueData,
  page,
  limit,
  filterName
) => {
  const requestObj = {
    path: `api/support_ticket/internal_tickets/delegate?page=${page}&limit=${limit}&search_text=${
      filterName ? filterName : ""
    }`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: {
      filter_by: valueData,
    },
  };
  return invokeApi(requestObj);
};
