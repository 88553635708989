import React from "react";
import RecordNotFound from "src/components/GeneralComponents/RecordNotFound";
import ResourcesCard from "./ResourcesCard";

export default function ResourcesCardTab({ data, imageLink }) {
  return (
    <div className="row">
      {data?.length > 0 ? (
        data?.map((resource, i) => (
          <div className="col-lg-6 col-md-6 col-sm-12 mt-3 d-flex" key={i}>
            <ResourcesCard imageLink={imageLink} resource={resource} />
          </div>
        ))
      ) : (
        <RecordNotFound title="Resource Not Found" />
      )}
    </div>
  );
}
