import { Dialog } from "@mui/material";
import React from "react";

export default function WhatsappPopUpModel({
  open,
  title,
  setOpen,
  componentToPassDown,
  show_date_and_income,
  mainTitle,
}) {
  return (
    <>
      <Dialog
        className="hide-index"
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{
          className: `p-3 general-popup-model ${
            show_date_and_income ? "general-popup-model-max-width" : ""
          }`,
        }}
      >
        <div class="cross-icon" onClick={() => setOpen(false)}>
          x
        </div>
        <div className="popup-title">
          <div className="d-flex justify-content-center flex-wrap">
            {mainTitle && (
              <h2 style={{ color: "#f6bd4b", marginRight: "5px" }}>
                {mainTitle + "'s"}
              </h2>
            )}
            <h2 className="mt-2 mt-md-0">{" " + title}</h2>
          </div>
        </div>
        <hr />
        <div className="responce-messages">{componentToPassDown}</div>
      </Dialog>
    </>
  );
}
