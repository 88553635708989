import React, { useEffect, useState } from "react";
import { no_data_found } from "src/assets";
export default function RecordNotFoundSalesLeads(props) {
  const [delayTime, setDelayTime] = useState(false);

  useEffect(() => {
    setTimeout(() => setDelayTime(true), 1000);
  }, []);

  return (
    <>
      {delayTime && (
        <div className="no-access-string">
          <img className="mx-auto" src={no_data_found} />
          <p className="mt-3 text-white">No Lead</p>
          <span className="mt-3 text-muted">
            Drag and Drop a card here to move a lead to this stage
          </span>
        </div>
      )}
    </>
  );
}
