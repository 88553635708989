import {
  CircularProgress,
  InputAdornment,
  TextField,
  Tooltip,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { add_lead_stage_api } from "src/DAL/LeadsCenter/lead_stages";
import AddTaskIcon from "@mui/icons-material/AddTask";
import CancelIcon from "@mui/icons-material/Cancel";
import AddIcon from "@mui/icons-material/Add";

export default function AddNewStage(props) {
  const { setLeadStages } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [addState, setAddState] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [stageName, setStageName] = useState("");

  const handleAddStage = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let postData = {
      title: stageName,
    };
    const result = await add_lead_stage_api(postData);
    if (result.code == 200) {
      setLeadStages((prev) => [
        ...prev,
        { ...result.stage, users: [], total_member_count: 0, total_pages: 0 },
      ]);
      setStageName("");
      setAddState(false);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleChange = (event) => {
    const value = event.target.value;
    setStageName(value);
  };

  const handleAddClick = () => {
    setAddState(true);
    const add_stage = document.getElementById("add-stage");
    const lastCard = add_stage.lastElementChild;
    lastCard.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  };

  return (
    <div className="main-member-container ms-2" id="add-stage">
      <div className="heading-container " style={{ width: "290px" }}>
        {isLoading ? (
          <div className="text-center w-100">
            <CircularProgress color="primary" className="mt-5" />
          </div>
        ) : addState ? (
          <form onSubmit={handleAddStage} className="w-100">
            <TextField
              id="outlined-basic"
              label=""
              variant="outlined"
              fullWidth
              required
              placeholder="Add Stage"
              size="small"
              // name="designation"
              InputProps={{
                style: {
                  fontSize: "13px",
                  paddingLeft: "4px",
                  paddingRight: "10px",
                },
                endAdornment: (
                  <InputAdornment position="end">
                    {stageName.length > 0 ? (
                      <span
                        className="diary-icon-add"
                        onClick={(e) => handleAddStage(e)}
                      >
                        <Tooltip title={"click here to add "}>
                          <AddTaskIcon />
                        </Tooltip>
                      </span>
                    ) : (
                      <span
                        className="diary-icon-remove"
                        onClick={() => setAddState(false)}
                      >
                        <Tooltip title={"Click here to cancel "}>
                          <CancelIcon />
                        </Tooltip>
                      </span>
                    )}
                  </InputAdornment>
                ),
                inputProps: {
                  readOnly: false,
                  min: 1,
                },
              }}
              value={stageName}
              onChange={handleChange}
            />
          </form>
        ) : (
          <span
            href=""
            className="anchor-style add-to-cart-button"
            onClick={() => handleAddClick()}
            style={{ cursor: "pointer" }}
          >
            <AddIcon />
            <b>Add New Stage</b>
          </span>
        )}
      </div>
    </div>
  );
}
