import React, { useEffect, useState } from "react";
import { Chip, CircularProgress, Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { s3baseUrl } from "src/config/config";
import { all_questions_listing_api } from "src/DAL/GeneralQuestions/GeneralQuestions";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { dd_date_format, replace_created_for } from "src/utils/constants";
import CustomDrawer from "src/components/DrawerForm/CustomDrawer";
import roundFilterList from "@iconify/icons-ic/round-filter-list";
import { Icon } from "@iconify/react";
// import QuestionAnswerFilter from "./QuestionAnswerFilter";
import { get_root_value } from "src/utils/domUtils";
import { sale_performance_api } from "src/DAL/Tracking/Tracking";
import { AccountabilityReportFilter } from "./AccountabilityReportFilter";
import FilteredChip from "../GeneralComponents/FilteredChip";
import WhatsappPopUpModel from "../GeneralComponents/WhatsappPopUpModel";
import SalesPerformanceGraph from "./SalesPerformanceGraph";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const EMPTY_FILTER = {
  from_date: null,
  to_date: null,
  created_for: null,
};
export default function SalesPerformance({
  user_type,
  heading,
  SalesPerformanceFilterCount,
  setSalesPerformanceFilterCount,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [isLoading, setIsLoading] = useState(true);
  const [answersData, setAnswersData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [filterDrawerState, setFilterDrawerState] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [drawerState, setDrawerState] = useState(false);
  const [open, setOpen] = useState(false);
  const [userData, setUserData] = useState({});

  const [filterData, setFilterData] = useState(EMPTY_FILTER);
  const [filterDataUpdated, setFilterDataUpdated] = useState(EMPTY_FILTER);

  const handleChangeOthers = (event, name) => {
    setFilterData((values) => ({ ...values, [name]: event }));
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setPageCount(1);
  };

  const handleCloseDrawer = () => {
    setDrawerState(false);
    setSalesPerformanceFilterCount(false);
  };

  const handleOpenFilterDrawer = () => {
    setFilterDrawerState(true);
  };
  const handleCloseFilterDrawer = () => {
    setFilterDrawerState(false);
    setSalesPerformanceFilterCount(false);
  };

  const handleClearFilter = () => {
    setFilterData(EMPTY_FILTER);
    localStorage.removeItem("sales_performance_filter_data_sales");
    getAnswersListing(EMPTY_FILTER);
    setFilterDrawerState(false);
    setSalesPerformanceFilterCount(false);
  };
  const handleDeleteChip = (data) => {
    console.log(data, "datadata");
    setPage(0);
    setPageCount(1);
    if (!data.date) {
      delete data.date;
      data.start_date = null;
      data.filter_by_dates = false;
      data.end_date = null;
      data.from_date = null;
      data.to_date = null;
    }
    localStorage.setItem(
      "sales_performance_filter_data_sales",
      JSON.stringify(data)
    );
    setFilterDataUpdated(data);
    setFilterData(data);
    getAnswersListing(data);
    setSalesPerformanceFilterCount(false);
  };

  const searchFunction = (e) => {
    if (e) {
      e.preventDefault();
    }
    setDrawerState(false);
    setPage(0);
    setPageCount(1);
    filterData.search_text = searchText;

    getAnswersListing(filterData);
    localStorage.setItem(
      "sales_performance_filter_data_sales",
      JSON.stringify(filterData)
    );
    handleCloseFilterDrawer();
  };

  const getAnswersListing = async (data) => {
    console.log(data, "data");
    setIsLoading(true);
    let created_for = null;
    if (data.created_for) {
      created_for = data.created_for?.created_for;
    }
    let postData = {
      created_for: created_for,
      search_text: data.search_text,
    };
    if (data.from_date) {
      postData.start_date = moment(data.from_date).format("YYYY-MM-DD");
    }
    if (data.to_date) {
      postData.end_date = moment(data.to_date).format("YYYY-MM-DD");
    }
    const result = await sale_performance_api(page, rowsPerPage, postData);

    if (result.code == 200) {
      const answers = result?.consultant_list?.map((answer, index) => {
        let image_src = "";
        if (answer?.image && answer?.image.thumbnail_1) {
          image_src = answer?.image.thumbnail_1;
        }

        return {
          ...answer,
          user_name: answer?.first_name + " " + answer?.last_name,
          user_email: answer?.email,
          answered_date: moment(answer.reply_date).format("DD-MM-YYYY"),
          total_commission: "£" + answer.total_commission.toFixed(2),
          paid_commission: "£" + answer.commission_paid.toFixed(2),
          commission_due: "£" + answer.commission_due.toFixed(2),
          // total_leads: answer.total_leads,
          table_avatar: {
            src: s3baseUrl + image_src,
            alt: answer.first_name,
          },
        };
      });
      let chipData = { ...data };
      if (chipData.from_date) {
        let date = `Start Date : ${dd_date_format(
          chipData.from_date
        )} End Date :  ${dd_date_format(chipData.to_date)}`;
        chipData.date = {
          chip_label: date,
          chip_value: date,
        };
        delete chipData.from_date;
        delete chipData.to_date;
      }
      if (data.search_text) {
        delete chipData.search_text;
      }
      console.log(chipData, "chipDatachipData");
      setFilterDataUpdated(chipData);
      setAnswersData(answers);
      setTotalCount(result.total_count);
      setTotalPages(result.total_pages);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleNavigate = (value) => {
    let check_user = false;
    if (value.created_for == "self_image") {
      check_user = true;
    }
    navigate(
      `${window.location.pathname}/answers-detail?created_for_id=${
        value.created_for_id ? value.created_for_id : ""
      }&member_id=${value.member_id}&created_for=${
        value.created_for
      }&check_user=${check_user}`
    );
  };

  const MENU_OPTIONS = [
    {
      label: "Answers Detail",
      icon: "akar-icons:edit",
      handleClick: handleNavigate,
    },
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
    localStorage.setItem("member_page_number", newPage);
  };
  const clearFlter = () => {
    handleCloseDrawer();
    setFilterData(EMPTY_FILTER);
    handleClearFilter();
  };
  useEffect(() => {
    setPage(0);
    setPageCount(1);
  }, [user_type]);

  useEffect(() => {
    let filter_data = EMPTY_FILTER;
    let find_filter = localStorage.getItem(
      "sales_performance_filter_data_sales"
    );
    if (find_filter) {
      filter_data = JSON.parse(find_filter);
      setFilterData(filter_data);
      setFilterDataUpdated(filter_data);
      if (filter_data.search_text) {
        setSearchText(filter_data.search_text);
      }
    }
    getAnswersListing(filter_data);
  }, [rowsPerPage, page, user_type]);
  const handleOpenDrawer = () => {
    setDrawerState(true);
  };
  useEffect(() => {
    if (SalesPerformanceFilterCount) {
      handleOpenDrawer();
    }
  }, [SalesPerformanceFilterCount]);

  const onPopup = (item) => {
    console.log(item, "itemitem");
    setUserData(item);
    setOpen(true);
  };
  const TABLE_HEAD = [
    { id: "number", label: "#", alignRight: false, type: "number" },

    {
      id: "table_avatar",
      label: " Profile",
      alignRight: false,
      type: "thumbnail",
    },
    {
      id: "user_name",
      label: "Name",
      renderData: (row) => {
        return (
          <>
            <Tooltip title={"View Detail"}>
              <p className="mb-0 cursor-pointer" onClick={() => onPopup(row)}>
                {row.user_name}
              </p>
            </Tooltip>
          </>
        );
      },
    },
    {
      id: "user_email",
      label: "Email",
      calignRight: false,
    },
    {
      id: "total_commission",
      label: "Total Commission",
      calignRight: false,
    },
    {
      id: "paid_commission",
      label: "Paid Commission",
      calignRight: false,
    },
    {
      id: "commission_due",
      label: "Due Commission",
      calignRight: false,
    },
    // {
    //   id: "total_leads",
    //   label: "Total Leads",
    //   calignRight: false,
    // },
  ];

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <>
      <div className="">
        <div className="row mt-3"></div>
        <div>
          <FilteredChip
            data={filterDataUpdated}
            tempState={filterData}
            EMPTY_FILTER={EMPTY_FILTER}
            onDeleteChip={handleDeleteChip}
            onClear={handleClearFilter}
          />
        </div>
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={answersData}
          MENU_OPTIONS={MENU_OPTIONS}
          className="card-with-background"
          custom_pagination={{
            total_count: totalCount,
            rows_per_page: rowsPerPage,
            page: page,
            handleChangePage: handleChangePage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          pageCount={pageCount}
          totalPages={totalPages}
          handleChangePages={handleChangePages}
          pagePagination={true}
          custom_search={{
            searchText: searchText,
            setSearchText: setSearchText,
            handleSubmit: searchFunction,
          }}
        />
      </div>
      <CustomDrawer
        isOpenDrawer={drawerState}
        onOpenDrawer={handleOpenDrawer}
        onCloseDrawer={handleCloseDrawer}
        pageTitle="Filter"
        componentToPassDown={
          <AccountabilityReportFilter
            getDashboardDetail=""
            inputs={filterData}
            filterData={filterData}
            handleChangeOthers={handleChangeOthers}
            setInputs={setFilterData}
            searchFunction={searchFunction}
            onClear={clearFlter}
            onCloseDrawer={handleCloseDrawer}
          />
        }
      />
      {/* <CustomDrawer
        isOpenDrawer={filterDrawerState}
        onOpenDrawer={handleOpenFilterDrawer}
        onCloseDrawer={handleCloseFilterDrawer}
        pageTitle="Filters"
        componentToPassDown={
          <QuestionAnswerFilter
            filterData={filterData}
            handleChange={handleChangeCreatedFor}
            searchSubmitFilter={searchFunction}
            handleClearFilter={handleClearFilter}
          />
        }
      /> */}
      {userData.user_name && (
        <WhatsappPopUpModel
          open={open}
          setOpen={setOpen}
          title={"Sales"}
          mainTitle={userData?.user_name}
          show_date_and_income={true}
          componentToPassDown={
            <SalesPerformanceGraph filterData={filterData} id={userData._id} />
          }
        />
      )}
    </>
  );
}
