import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { AllGroupListing, DeleteGroup } from "src/DAL/Groups/Groups";
import CustomConfirmation from "src/components/GeneralComponents/CustomConfirmation";
import { show_proper_words } from "src/utils/constants";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function CalendarGroupsList() {
  const navigate = useNavigate();
  const classes = useStyles();
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [deleteDoc, setDeleteDoc] = useState("");
  const [openDelete, setOpenDelete] = useState(false);

  const getConsultantListing = async () => {
    setIsLoading(true);
    const result = await AllGroupListing();
    if (result.code === 200) {
      const data = result.group.map((group) => {
        let group_by_type = "Event";
        if (group.group_by === "program") {
          group_by_type = "Programme";
        }

        let member_list = "N/A";
        if (group.member) {
          member_list = group.member.length;
        }
        return { ...group, member_list, group_by_type: group_by_type };
      });
      setUserList(data);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleEdit = (value) => {
    navigate(`/groups/edit-group/${value.group_slug}`, {
      state: value,
    });
  };

  const handleViewDetail = (value) => {
    navigate(`/groups/group-detail/${value.group_slug}`, {
      state: value,
    });
  };

  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    setIsLoading(true);
    const result = await DeleteGroup(deleteDoc.group_slug);
    if (result.code === 200) {
      getConsultantListing();
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleNavigate = () => {
    navigate(`/groups/add-group`);
  };

  useEffect(() => {
    getConsultantListing();
  }, []);

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
    {
      label: "View Detail",
      icon: "fluent:video-recording-20-regular",
      handleClick: handleViewDetail,
    },
  ];

  const TABLE_HEAD = [
    { id: "action", label: "Action", type: "action" },
    { id: "number", label: "#", type: "number" },
    {
      id: "title",
      label: " Name",
    },
    {
      id: "program",
      label: "Programme / Event",
      renderData: (row) => {
        return (
          <>
            {row.program && row.program.length > 0
              ? row.program.map((program, i) => {
                  if (program._id !== null) {
                    return (
                      <div
                        key={i}
                        dangerouslySetInnerHTML={{
                          __html: program._id.title + ",",
                        }}
                      ></div>
                    );
                  }
                })
              : row.event.map((event, i) => {
                  if (event._id !== null) {
                    return (
                      <div
                        key={i}
                        dangerouslySetInnerHTML={{
                          __html: event._id?.title + ",",
                        }}
                      ></div>
                    );
                  }
                })}
          </>
        );
      },
    },
    {
      id: "group_type",
      label: "Type",
      className: "text-capitalize",
    },
    {
      id: "group_by_type",
      label: "Group By",
    },
    { id: "member_list", label: "Members" },
    {
      id: "community_level",
      label: "Community Level",
      renderData: (row) => {
        return show_proper_words(
          row?.community_level ? row?.community_level : "all"
        );
      },
    },
    { id: "status", label: "Status", type: "row_status" },
  ];

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleDelete}
      />

      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-sm-12 mb-3">
            <h2>Groups</h2>
          </div>
          <div className="col-lg-4 col-sm-12 mb-3 text-end">
            <button onClick={handleNavigate} className="small-contained-button">
              Add Group
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-12 mt-3">
            <CustomMUITable
              TABLE_HEAD={TABLE_HEAD}
              MENU_OPTIONS={MENU_OPTIONS}
              data={userList}
              className="card-with-background"
              pagePagination={true}
              localSearchName="calender_group_searh"
            />
          </div>
        </div>
      </div>
    </>
  );
}
