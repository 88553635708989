import * as React from "react";
import { useState } from "react";
import "react-tagsinput/react-tagsinput.css";
import { Icon } from "@iconify/react";
import TextareaAutosize from "react-textarea-autosize";
import GeneralPopUpModel from "src/components/GeneralComponents/GeneralPopUpModel";
import { useEffect } from "react";
import LinkPopup from "./LinkPopup";

export default function MessageSetting(props) {
  const { onCloseDrawer, data, drawerState } = props;
  const [message, setMessage] = useState("");
  const [openLink, setOpenLink] = useState(false);

  const handleGetSelectedText = () => {
    const inputField = document.getElementById("inputField");
    if (inputField) {
      const selectedText = inputField.value.substring(
        inputField.selectionStart,
        inputField.selectionEnd
      );
      return selectedText;
    }
  };

  const handleOnBold = () => {
    let seleted_text = handleGetSelectedText();
    if (seleted_text) {
      var newText = inputField.value.replace(
        seleted_text,
        "**" + seleted_text + "**"
      );
      setMessage(newText);
    }
  };
  const handleOpenLinkPopup = () => {
    setOpenLink(true);
  };
  const handleOnItalic = () => {
    let seleted_text = handleGetSelectedText();
    if (seleted_text) {
      var newText = inputField.value.replace(
        seleted_text,
        "*" + seleted_text + "*"
      );
      setMessage(newText);
    }
  };
  const handleAddLink = (values) => {
    let make_link = ` [${values.display_text}](${values.link_address}) `;
    const inputField = document.getElementById("inputField");
    const start = inputField.selectionStart;
    const end = inputField.selectionEnd;
    // Insert the text at the cursor position
    setMessage(message.slice(0, start) + make_link + message.slice(end));
    // Move the cursor after the inserted text
    inputField.setSelectionRange(
      start + make_link.length,
      start + make_link.length
    );
    setOpenLink(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let obj = {
      ...data,
      message_notification_info: {
        message: message,
      },
    };
    onCloseDrawer(obj, drawerState);
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setMessage(value);
  };

  useEffect(() => {
    if (data.message_notification_info) {
      setMessage(data.message_notification_info.message);
    }
  }, [drawerState]);

  return (
    <div className="container new-memories px-4">
      <GeneralPopUpModel
        open={openLink}
        setOpen={setOpenLink}
        title={"Insert Link"}
        componentToPassDown={<LinkPopup handleAddLink={handleAddLink} />}
      />
      <form className="row" onSubmit={handleSubmit}>
        <div className="col-12 mt-3">
          <div className="w-100">
            <h4>Write Message*</h4>
            <div
              className={`chat-formatters-box chat-formatters-border d-flex`}
              style={{
                backgroundColor: "var(--background-primary-color)",
                height: "35px",
              }}
            >
              <>
                <div className="formatter-icon" onClick={handleOnBold}>
                  <Icon
                    fontSize="15"
                    style={{ color: "var(--portal-theme-primary)" }}
                    icon="octicon:bold-24"
                  />
                </div>
                <div className="formatter-icon" onClick={handleOnItalic}>
                  <Icon
                    fontSize="15"
                    style={{ color: "var(--portal-theme-primary)" }}
                    icon="fe:italic"
                  />
                </div>
                <div className="formatter-icon" onClick={handleOpenLinkPopup}>
                  <Icon
                    fontSize="15"
                    style={{ color: "var(--portal-theme-primary)" }}
                    icon="ion:link"
                  />
                </div>
              </>
            </div>

            <div className="position-relative parent">
              <TextareaAutosize
                id="inputField"
                className={`whatsapp-send-input chat-formatters-border `}
                style={{
                  backgroundColor: "var(--background-secondary-color)",
                }}
                type="text"
                value={message}
                maxRows={6}
                minRows={6}
                onChange={handleChange}
                placeholder="Write your message..."
                required
                autoFocus
              />
            </div>
          </div>
        </div>
        <div className="text-end mt-4">
          <button type="submit" className="small-contained-button">
            Save
          </button>
        </div>
      </form>
    </div>
  );
}
