import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import {
  Avatar,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
  Tooltip,
} from "@mui/material";
import { get_root_value } from "src/utils/domUtils";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import { _consultant_member_list_with_search } from "src/DAL/Chat/Chat";
import { string_avatar } from "src/utils/constants";
import { s3baseUrl } from "src/config/config";
import { useContentSetting } from "src/Hooks/ContentSettingState";

const useStyles = makeStyles(() => ({
  paper: {
    background: get_root_value("--popup-background-color"),
    color: get_root_value("--input-text-color"),
  },
}));
export default function NewChatUsers({
  handleAddChat,
  eventsList,
  onCloseDrawer,
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { feedSetting } = useContentSetting();
  const [offlineUsersList, setOfflineUsersList] = useState([]);
  const [onlineUsersList, setOnlineUsersList] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [state, setState] = useState({
    search_text: "",
    event_id: "none",
  });

  const getChatUsersList = async () => {
    let postObject = {
      search_text: state.search_text,
      event_id: state.event_id == "none" ? "" : state.event_id,
    };
    console.log(postObject, "postObject");
    const result = await _consultant_member_list_with_search(postObject);
    if (result.code === 200) {
      setOnlineUsersList(result.members_list);
      setOfflineUsersList(result.members_list_offline);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const get_members_list = (tab) => {
    if (tab == 0) {
      return onlineUsersList;
    } else {
      return offlineUsersList;
    }
  };

  useEffect(() => {
    getChatUsersList();
  }, [state]);

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="new-message-search-box mt-3">
            <Icon
              fontSize={20}
              className="new-message-search-icon"
              icon="akar-icons:search"
            />
            <input
              className="new-message-search-input"
              type="text"
              placeholder="Search"
              name="search_text"
              value={state.search_text}
              onChange={handleChange}
            />
            <FormControl variant="outlined" className="mt-3" fullWidth>
              <InputLabel id="demo-simple-select-outlined-label">
                Portals
              </InputLabel>
              <Select
                sx={{
                  color: get_root_value("--input-text-color"),
                }}
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={state.event_id}
                onChange={handleChange}
                label="Portals"
                name="event_id"
                className="svg-color"
                MenuProps={{
                  classes: {
                    paper: classes.paper,
                  },
                }}
              >
                <MenuItem value="none">
                  <em>None</em>
                </MenuItem>
                {eventsList.map((event) => (
                  <MenuItem value={event._id} key={event._id}>
                    {event.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Tabs
              value={selectedTab}
              onChange={(e, value) => setSelectedTab(value)}
              textColor="primary"
              indicatorColor="primary"
              variant="fullWidth"
              className="mt-1"
            >
              <Tab value={0} label={`Online`} />
              <Tab value={1} label={`Offline`} />
            </Tabs>
            <div className="new-message-users-list mt-4">
              <h3 className="text-white">Users List</h3>
              {get_members_list(selectedTab).map((user) => {
                let level_title = user.community_level + "_title";
                let level_badge = user.community_level + "_badge";
                return (
                  <div
                    className="message-user-box p-2 mt-3"
                    onClick={() => {
                      handleAddChat(user._id, onCloseDrawer);
                    }}
                    key={user._id}
                  >
                    <div className="d-flex align-items-center">
                      <div className="user-profile">
                        <div className="user-level">
                          <Tooltip title={"Elite"}>
                            <img src={""} />
                          </Tooltip>
                        </div>
                        <Avatar
                          src={s3baseUrl + user.profile_image}
                          className="message-user-avatar"
                          alt="photoURL"
                          style={{ width: 33, height: 33 }}
                        >
                          {string_avatar(
                            user.first_name + " " + user.last_name
                          )}
                        </Avatar>
                        <div
                          className={user.is_online ? "online" : "offline"}
                        ></div>
                      </div>
                      <div className="ms-2">
                        <p>{user.first_name + " " + user.last_name}</p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
