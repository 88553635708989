import faker from "faker";
import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import { formatDistance } from "date-fns";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import arrowIosForwardFill from "@iconify/icons-eva/arrow-ios-forward-fill";
// material
import {
  Box,
  Stack,
  Link,
  Card,
  Button,
  Divider,
  Typography,
  CardHeader,
} from "@mui/material";
// utils
import { mockImgCover } from "../../../utils/mockImages";
//
import Scrollbar from "../../Scrollbar";
import {
  paddyPrfileImage,
  g1Image,
  g2Image,
  g3Image,
  dummyImage,
} from "src/assets";
import { convertCurrencyToSign } from "src/utils/constants";
import RecordNotFound from "src/components/RecordNotFound";
import { sortBy } from "lodash";

// ----------------------------------------------------------------------

// const NEWS = [...Array(5)].map((_, index) => {
//   const setIndex = index + 1;
//   return {
//     title: faker.name.title(),
//     description: faker.lorem.paragraphs(),
//     image: mockImgCover(setIndex),
//     postedAt: faker.date.soon(),
//   };
// });
const TopSellers = [
  {
    name: "Positive Paddy",
    description: "Positive Paddy",
    sale: "$481",
    image: paddyPrfileImage,
  },
  {
    name: "Jade Greenway",
    description: "Jade Greenway",
    sale: "$8441",
    image: g1Image,
  },
  {
    name: "James",
    description: "James",
    sale: "$4481",
    image: g2Image,
  },
  {
    name: "Oliver",
    description: "Oliver",
    sale: "$4481",
    image: g3Image,
  },
  {
    name: "Michael",
    description: "Michael",
    sale: "$4481",
    image: g2Image,
  },
];

const NEWS = TopSellers.map((seller, index) => {
  const setIndex = index + 1;
  return {
    title: seller.name,
    description: seller.description,
    image: seller.image,
    sale: seller.sale,
  };
});

// ----------------------------------------------------------------------

NewsItem.propTypes = {
  news: PropTypes.object.isRequired,
};

function NewsItem({ news }) {
  const { image, user_name, description, amount, currency } = news;

  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      <Box
        component="img"
        alt={user_name}
        src={image ? image : dummyImage}
        sx={{ width: 48, height: 48, borderRadius: 1.5 }}
      />
      <Box sx={{ minWidth: 140 }}>
        <Link
          to="/commission-detail"
          color="inherit"
          underline="hover"
          component={RouterLink}
        >
          <Typography variant="subtitle2" noWrap>
            {user_name}
          </Typography>
        </Link>
        <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
          {description}
        </Typography>
      </Box>
      <Typography
        variant="caption"
        sx={{ pr: 2, flexShrink: 0, color: "text.secondary" }}
      >
        {currency ? convertCurrencyToSign(currency) : "£"}
        {amount}
      </Typography>
    </Stack>
  );
}

export default function AppNewsUpdate({ transactions }) {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(`/commission-detail`);
  };
  const transactionList = sortBy(transactions, "amount");

  return (
    <Card>
      <CardHeader title="Latest Transactions" />
      <Scrollbar>
        <Stack spacing={3} sx={{ p: 1, pr: 0 }}>
          {transactions?.length < 1 ? (
            <RecordNotFound title="Transaction" />
          ) : (
            transactionList
              .reverse()
              ?.map((news, index) => <NewsItem key={index} news={news} />)
          )}
        </Stack>
      </Scrollbar>

      <Divider />

      <Box sx={{ p: 2, textAlign: "right" }}>
        <Button
          to="#"
          size="small"
          color="inherit"
          // component={RouterLink}
          endIcon={<Icon icon={arrowIosForwardFill} />}
          onClick={handleNavigate}
        >
          View all
        </Button>
      </Box>
    </Card>
  );
}
