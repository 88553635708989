import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  CircularProgress,
  Container,
  Tooltip,
} from "@mui/material";
import { useSnackbar } from "notistack";
import Chart from "react-apexcharts";
import { useEffect, useState } from "react";
import {
  getBookingReport,
  getBroadcastDetail,
  get_booking_stats_api,
} from "src/DAL/Broadcast/Broadcast";
import { makeStyles } from "@mui/styles";
import { s3baseUrl } from "src/config/config";
import WhatsappPopUpModel from "../GeneralComponents/WhatsappPopUpModel";
import BookingListForDelegates from "./BookingListForDelegates";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import moment from "moment";
import RecordNotFound from "../GeneralComponents/RecordNotFound";
import ReactApexChart from "react-apexcharts";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "10%",
    marginBottom: "10%",
  },
  fonts: {
    fontSize: "13px",
    fontWeight: "500",
  },
}));
const BookingReport = ({
  search,
  searchCount,
  filterDataBooking,
  clearFilterCount,
  setSearchCount,
  setclearFilterCount,
}) => {
  const classes = useStyles();
  const [totlePages, setTotlePages] = useState(0);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [isloadingdetail, setIsLoadingDetail] = useState(false);
  const [detailData, setDetailData] = useState([]);
  const [loadMorePath, setLoadMorePath] = useState("");
  const [userData, setUserData] = useState({});
  let [pageNumber, setPageNumber] = useState(0);
  const [Activities, setActivitiesArray] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [broadcastDetail, setBroadcastDetail] = useState();
  const [open, setOpen] = useState(false);
  const openDrawr = (item) => {
    setUserData(item);
    setOpen(true);
  };
  const [loadMore, setLoadMore] = useState(
    `api/daily_dynamite_tracker/list_and_performance_stats_infor_for_delegate?page=0&limit=20`
  );
  const [cout, setCount] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  console.log(
    filterDataBooking,
    "filterDataBookingfilterDataBookingfilterDataBooking"
  );
  const getBraodcastDetail = async (type) => {
    let path = "";
    let postData = {
      type: "bookings",
      search_text: "",
    };
    if (type == "search") {
      setIsLoading(true);
      path =
        "api/daily_dynamite_tracker/list_and_performance_stats_infor_for_delegate?page=0&limit=20";
      postData.search_text = search;
      postData.start_date = filterDataBooking.from_date
        ? moment(filterDataBooking.from_date).format("DD-MM-YYYY")
        : "";
      postData.end_date = filterDataBooking.to_date
        ? moment(filterDataBooking.to_date).format("DD-MM-YYYY")
        : "";
    } else if (type == "other") {
      setIsLoading(true);
      postData.search_text = search;
      path =
        "api/daily_dynamite_tracker/list_and_performance_stats_infor_for_delegate?page=0&limit=20";
    } else {
      path = loadMore;
    }

    const result = await getBookingReport(path, postData);
    if (result.code == 200) {
      console.log(result, "resultresultresultresult");
      if (type == "search") {
        const newArray = result.delegate.map((item, index) => ({
          ...item,
          isExpand: index === 0 ? true : false,
        }));
        getPerformanceDetail(newArray[0], filterDataBooking);
        setActivitiesArray(newArray);
      } else if (type == "other") {
        const newArray = result.delegate.map((item, index) => ({
          ...item,
          isExpand: index === 0 ? true : false,
        }));
        getPerformanceDetail(newArray[0], filterDataBooking);
        setActivitiesArray(newArray);
      } else {
        setActivitiesArray(Activities?.concat(result.delegate));
      }
      setPageNumber(pageNumber + 1);
      setTotlePages(result.total_pages + 1);
      setLoadMorePath(result.load_more_url);
      setIsLoadingMore(false);
      setIsLoading(false);
      setSearchCount(false);
      setclearFilterCount(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const getPerformanceDetail = async (item, filterDate) => {
    setIsLoadingDetail(true);
    let postData = {
      type: "performance_info",
      consultant_id: item._id,
      start_date: filterDate.from_date
        ? moment(filterDate.from_date).format("DD-MM-YYYY")
        : "",
      end_date: filterDate.to_date
        ? moment(filterDate.to_date).format("DD-MM-YYYY")
        : "",
    };
    const result = await get_booking_stats_api(postData);
    if (result.code == 200) {
      let data_ = result.booking_counts.map((item) => {
        let percentage = (item.count / result.total_booking_count) * 100;
        return {
          ...item,
          percentage: +percentage.toFixed(2),
        };
      });
      setDetailData(data_);
      setIsLoadingDetail(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingDetail(false);
    }
  };

  const handleToggle = (item, index) => {
    if (!item.isExpand) {
      getPerformanceDetail(item, filterDataBooking);
    }
    setActivitiesArray((old) =>
      old.map((item, i) => {
        item.isExpand = i === index ? !item.isExpand : false;
        return item;
      })
    );
  };

  const loadMoreData = () => {
    setCount((count) => count + 1);
    setIsLoadingMore(true);
    setLoadMore(loadMorePath);
  };

  console.log(detailData, "detailData.map((item) => item.percentage)");

  let count_Array = detailData.map((item) => item.count);
  let colors = detailData.map((item) => item.background_color);
  let categories = detailData.map((item) => item.title);
  let totalCounts = detailData.reduce((a, b) => a + b.count, 0);
  let barChart = {
    series: [
      {
        data: count_Array,
      },
    ],
    options: {
      tooltip: {
        shared: true,
        intersect: false,
        marker: {
          fillColors: colors, // Use the same colors as the bars
        },

        y: {
          title: {
            formatter: function (seriesName, opts) {
              return opts.w.config.xaxis.categories[opts.dataPointIndex];
            },
          },
        },
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          let color = w.config.colors[dataPointIndex];
          let category = w.config.xaxis.categories[dataPointIndex];
          let value = series[seriesIndex][dataPointIndex];
          return (
            '<div style="display: flex; align-items: center; padding: 10px;">' +
            '<div style="width: 12px; height: 12px; border-radius: 50%; background-color: ' +
            color +
            '; margin-right: 10px;"></div>' +
            "<div>" +
            "<strong>" +
            category +
            "</strong>" +
            "     " +
            value +
            "</div>" +
            "</div>"
          );
        },
      },
      chart: {
        height: 350,
        type: "bar",
        events: {
          click: function (chart, w, e) {
            // console.log(chart, w, e)
          },
        },
      },
      colors: colors,
      plotOptions: {
        bar: {
          columnWidth: "20%",
          distributed: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      xaxis: {
        categories: categories,
        labels: {
          style: {
            colors: "#fff",
            fontSize: "12px",
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: "#c6c2c2",
          },
        },
        axisBorder: {
          show: true,
          color: "gray",
          dashArray: 4,
        },
        max: totalCounts,
      },
    },
  };

  function makeArrayUnique(array) {
    const uniqueMap = new Map();
    array.forEach((obj) => {
      uniqueMap.set(obj._id, obj);
    });
    return Array.from(uniqueMap.values());
  }
  console.log(pageNumber, "pageNumberpageNumber");
  console.log(totlePages, "totlePages");

  useEffect(() => {
    getBraodcastDetail();
  }, [loadMore, cout]);
  useEffect(() => {
    setPageNumber(1);
    if (searchCount) {
      getBraodcastDetail("search");
    }
  }, [searchCount]);
  useEffect(() => {
    setPageNumber(0);
    if (clearFilterCount) {
      getBraodcastDetail("other");
    }
  }, [clearFilterCount]);
  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="row mt-3">
      {Activities && Activities.length > 0 ? (
        makeArrayUnique(Activities).map((item, index) => {
          return (
            <Container className="mt-2">
              <Accordion expanded={Boolean(item.isExpand)}>
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      className="icon-color"
                      onClick={() => {
                        handleToggle(item, index);
                      }}
                    />
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  className="svg-color summary"
                >
                  <div
                    className="delegate-info"
                    style={{ width: "100%", justifyContent: "space-between" }}
                  >
                    <div className="delegate-info">
                      <Avatar
                        className="avatar-adjust"
                        sx={{ width: 30, height: 30 }}
                        alt={item.first_name}
                        src={s3baseUrl + item?.image?.thumbnail_1}
                      />
                      <span style={{ color: "white" }}>
                        {item.first_name + " " + item.last_name + " "}
                        <span className="text-muted">
                          {"(" + item.email + ")"}
                        </span>{" "}
                      </span>
                    </div>
                    <Tooltip title="Click here to view All Bookings">
                      <span
                        onClick={() => openDrawr(item)}
                        className="cursor-pointer"
                      >
                        <p
                          className="text-end mb-0 pe-4"
                          style={{ color: "rgb(246, 189, 75)" }}
                        >
                          Total Bookings:
                          <span
                            style={{ color: "white" }}
                            className="text-muted"
                          >
                            {" " + item.total_bookings}
                          </span>
                        </p>
                      </span>
                    </Tooltip>
                  </div>
                </AccordionSummary>

                <AccordionDetails
                  style={{
                    backgroundColor: "#121826",
                    border: "2px solid #1D2537",
                    borderBottomLeftRadius: "20px",
                    borderBottomRightRadius: "20px",
                  }}
                >
                  {isloadingdetail ? (
                    <CircularProgress
                      className={classes.loading}
                      color="primary"
                    />
                  ) : (
                    <div className="row">
                      <div className="col-12">
                        <ReactApexChart
                          options={barChart.options}
                          series={barChart.series}
                          type="bar"
                          height={350}
                        />
                      </div>
                      {/* <div className="col-6 booking-report-detail">
                        <div>
                          {detailData.map((booking) => {
                            return (
                              <div className="d-flex mb-2">
                                <div
                                  className="colored-part me-3"
                                  style={{
                                    backgroundColor: booking.background_color,
                                    color: booking.text_color,
                                  }}
                                >
                                  {booking.count}
                                </div>
                                <div className="booking-title text-muted">
                                  {booking.title}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div> */}
                    </div>
                  )}
                </AccordionDetails>
              </Accordion>
            </Container>
          );
        })
      ) : (
        <div className="mt-5">
          <RecordNotFound />
        </div>
      )}
      {totlePages > pageNumber ? (
        <div className="col-12 text-center">
          <button
            className="small-contained-button mt-3"
            onClick={loadMoreData}
            disabled={isLoadingMore}
          >
            {isLoadingMore ? "Loading..." : "Load More"}
          </button>
        </div>
      ) : (
        " "
      )}
      {userData.first_name && (
        <WhatsappPopUpModel
          open={open}
          setOpen={setOpen}
          title={"Bookings"}
          mainTitle={userData?.first_name + " " + userData?.last_name}
          show_date_and_income={true}
          componentToPassDown={
            <BookingListForDelegates
              id={userData._id}
              filterDataBooking={filterDataBooking}
            />
          }
        />
      )}
    </div>
  );
};

export default BookingReport;
