import { CircularProgress, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import LiveMeeting from "../Live-Stream/LiveMeeting";
import { live_meeting_by_module_id_api } from "src/DAL/LiveStream/LiveStream";
import { useSnackbar } from "notistack";
import { htmlDecode } from "src/utils/convertHtml";
import InfoIcon from "@mui/icons-material/Info";
import GeneralPopUpModel from "src/components/GeneralComponents/GeneralPopUpModel";
import MeetingDetail from "src/components/Meeting/MeetingDetail";
import BeforeStartMeeting from "../Live-Stream/components/BeforeStartMeeting";
import { isTimePassedUTC } from "src/utils/constants";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IconButton from "@mui/material/IconButton";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

function getRemainingSeconds(targetDate) {
  const targetTime = new Date(targetDate);
  const currentTime = new Date();
  const differenceInSeconds = Math.max(
    0,
    Math.floor((targetTime - currentTime) / 1000)
  );

  return differenceInSeconds;
}

export default function StartMeeting({ navigateTo }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { topic } = useParams();
  const [moduleDetail, setModuleDetail] = useState({});
  const [liveStream, setLiveStream] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [pageState, setPageState] = useState(0);
  const [isTimePassed, setIsTimePassed] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);

  const getMeetingDeteil = async () => {
    const result = await live_meeting_by_module_id_api(topic);
    if (result.code === 200) {
      setModuleDetail(result.meeting);
      setLiveStream(result.live_stream);
      const { meeting_time_zone, meeting_start_date, meeting_start_time } =
        result.meeting;
      const is_time_passed = isTimePassedUTC(
        meeting_start_date + " " + meeting_start_time,
        meeting_time_zone
      );
      setIsTimePassed(is_time_passed);

      setIsLoading(false);
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
      navigate(`/meetings`);
    }
  };

  const handleStart = () => {
    setPageState(2);
  };

  useEffect(() => {
    getMeetingDeteil();
  }, []);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  let streamData = {
    feedDetail: moduleDetail,
    liveStream,
    isFullScreen,
    setIsFullScreen,
    joinType: "host",
    setLiveStream,
    module_name: "meeting",
    navigateTo: navigateTo,
  };

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-9">
            <div className="d-flex mb-2">
              {pageState == 0 && (
                <IconButton
                  className="back-screen-button me-2"
                  onClick={() => navigate(navigateTo)}
                >
                  <ArrowBackIcon />
                </IconButton>
              )}
              <h2 className="mb-0">{htmlDecode(moduleDetail.title)}</h2>
            </div>
          </div>
          <div className="col-3">
            <Tooltip title="Meeting Detail">
              <div className="meeting-info" onClick={() => setOpenDetail(true)}>
                <InfoIcon />
              </div>
            </Tooltip>
          </div>
          <div className="col-12">
            {pageState == 0 ? (
              <BeforeStartMeeting
                moduleDetail={moduleDetail}
                isStart={isTimePassed}
                handleStart={handleStart}
                setIsTimePassed={setIsTimePassed}
              />
            ) : (
              <LiveMeeting {...streamData} />
            )}
          </div>
        </div>
      </div>
      <GeneralPopUpModel
        open={openDetail}
        setOpen={setOpenDetail}
        title={"Meeting Detail"}
        componentToPassDown={<MeetingDetail meetingDetail={moduleDetail} />}
      />
    </>
  );
}
