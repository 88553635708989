import React, { useState, useEffect } from "react";
import { get_root_value } from "src/utils/domUtils";
import { makeStyles } from "@mui/styles";
import RecordNotFound from "src/components/RecordNotFound";
const useStyles = makeStyles(() => ({
  paper: {
    background: get_root_value("--popup-background-color"),
    color: get_root_value("--input-text-color"),
  },
}));
export default function CloseNote({ onCloseDrawer, ticketData }) {
  const noteData = ticketData?.object?.close_note;
  return (
    <>
      {noteData ? (
        <div className="container new-memories">
          <p style={{ fontSize: "14px", color: "white", marginTop: "20px" }}>
            {noteData}
          </p>
        </div>
      ) : (
        <RecordNotFound title="Note " />
      )}
    </>
  );
}
