import React from "react";
import { useState } from "react";
import {
  pen2_image,
  pen3_image,
  pen4_image,
  pen5_image,
  pen_image,
} from "src/assets";
import ReactVideoPlayer from "src/components/ReactVideoPlayer";

export default function ImageSlider() {
  const [showFile, setShowFile] = useState({
    type: "image",
    path: pen2_image,
    is_selected: "is_selected",
  });
  const handleChangeFile = (type, path) => {
    setShowFile({
      type: type,
      path: path,
      is_selected: "is_selected",
    });
  };
  return (
    <div className="image-slider">
      <div className="main-image">
        {showFile.type === "video" ? (
          <ReactVideoPlayer url={showFile.path} />
        ) : (
          <img width="100%" src={showFile.path} />
        )}
      </div>
      <div className="image-thumbnails mt-3 text-center">
        <img
          width="100%"
          className="is_selected"
          src={pen_image}
          onClick={() => {
            handleChangeFile("image", pen_image);
          }}
        />
        <img
          width="100%"
          src={pen2_image}
          onClick={() => {
            handleChangeFile("image", pen2_image);
          }}
        />
        <img
          width="100%"
          src={pen3_image}
          onClick={() => {
            handleChangeFile("image", pen3_image);
          }}
        />
        <img
          width="100%"
          src={pen4_image}
          onClick={() => {
            handleChangeFile("image", pen4_image);
          }}
        />
        <img
          width="100%"
          src={pen5_image}
          onClick={() => {
            handleChangeFile(
              "video",
              "https://player.vimeo.com/video/546405491"
            );
          }}
        />
      </div>
    </div>
  );
}
