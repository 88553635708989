import { filter } from "lodash";
import { Icon } from "@iconify/react";
import { sentenceCase } from "change-case";
import { useEffect, useState } from "react";
import plusFill from "@iconify/icons-eva/plus-fill";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// import { get_root_value } from "src/utils/domUtils";
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  IconButton,
  Pagination,
} from "@mui/material";
// components
// import Page from "../components/Page";
// import Label from "./Label";
// import Scrollbar from "../components/Scrollbar";
import SearchNotFound from "src/components/SearchNotFound";
import Label from "src/components/Label";
import { UserListHead, UserListToolbar } from "src/components/_dashboard/user";
// import UserListHead from "./UserListHead";
// import GoalStatementMenu from "../components/_dashboard/user/GoalStatementMenu";
// import { QuestionListing } from "src/DAL/GoalStatement/GoalStatement";
import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { htmlDecode } from "src/utils/convertHtml";
import CustomPopoverSection from "src/components/MenuOption/CustomPopoverSection";

import CustomConfirmation from "src/components/menuIcons/CustomConfirmation";

// import {
//   consultantListing,
//   DeleteConsultantApi,
// } from "src/DAL/consultant/consultant";
import { transactionListApi } from "../../DAL/TransactionList/transactionList";
import moment from "moment";
import {
  convertCurrencyToSign,
  get_date_with_user_time_zone,
} from "src/utils/constants";
import { bookingListApi } from "src/DAL/Booking/bookingApi";
import CustomDrawer from "src/components/DrawerForm/CustomDrawer";
import { attitudeAssessmentListApi } from "src/DAL/AssessmentApi/assessmentApi";
import CustomPopover from "src/components/CustomPopover";
import { useContentSetting } from "src/Hooks/ContentSettingState";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";

//
// import USERLIST from "../_mocks_/user";

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    console.log(array, "array");
    return filter(
      array,
      (_user) =>
        _user.email.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.Username.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
export default function AttitudeAssessmentList() {
  const navigate = useNavigate();
  const params = useLocation();
  const { userInfo, adminTimeZone } = useContentSetting();
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("number");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [open, setOpen] = useState(false);
  const [questionData, setQuestionData] = useState([]);
  const [totalCount, setTotalCount] = useState("");
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [deleteDoc, setDeleteDoc] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [member, setData] = useState([]);
  const [totalMembers, setTotalMembers] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [UserId, setUserId] = useState(false);
  const [drawerState, setDrawerState] = useState(false);
  const [messages, setMessages] = useState(false);
  const [searchText, setSearchText] = useState("");
  const handleOpenDrawer = (data) => {
    setMessages(data);
    setDrawerState(true);
  };
  const handleCloseDrawer = () => {
    setDrawerState(false);
  };

  const handleOpen = (e) => {
    setUserId(e);
    setOpen(true);
  };
  const searchFunction = () => {
    setPage(0);
    setPageCount(1);
    localStorage.setItem("Assessment_search", searchText);

    getConsultantListing(searchText);
  };
  const handleRequestSort = (event, property) => {
    // const isAsc = orderBy === property && order === "asc";
    // setOrder(isAsc ? "desc" : "asc");
    // setOrderBy(property);
  };

  const getConsultantListing = async (text) => {
    setIsLoading(true);

    try {
      setIsLoading(true);
      const result = await attitudeAssessmentListApi(page, rowsPerPage, text);
      if (result.code === 200) {
        const data = result.assessment.map((val, index) => {
          let consultant = "N/A";
          let nurture = "N/A";
          if (val.consultant && Object.keys(val.consultant).length > 0) {
            consultant =
              val.consultant.first_name + " " + val.consultant.last_name;
          }
          if (val.nurture && Object.keys(val.nurture).length > 0) {
            nurture = val.nurture.first_name + " " + val.nurture.last_name;
          }
          return {
            id: val._id,
            Username: val.member.first_name + " " + val.member.last_name,
            email: val.member.email,
            object: val,
            delegate: consultant,
            nurture: nurture,
            coins: val.attitude_assessment_coins_count,
            assessment_results: val.assessment_results,
            time: get_date_with_user_time_zone(
              val.activity_date_time,
              "DD-MM-YYYY hh:mm",
              userInfo.time_zone,
              adminTimeZone
            ),

            count: index + 1 + rowsPerPage * page,
          };
        });
        setUserList(data);
        setTotalPages(result.total_pages);
        setTotalCount(result.total_count);
        setIsLoading(false);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
      }
    } catch (error) {}
  };
  const handleEdit = (value) => {
    navigate(`/assessment/history/${value.id}`, {
      state: value,
    });
  };

  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };
  const handleDelete = async () => {
    setOpenDelete(false);
    setIsLoading(true);
    const result = await deleteDoc.id;
    if (result.code === 200) {
      getConsultantListing();
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPageCount(1);
    setPage(0);
  };
  const Capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };
  const handleNavigate = () => {
    navigate(`/consultant/add-consultant`);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - userList.length) : 0;

  const filteredUsers = applySortFilter(
    userList,
    getComparator(order, orderBy),
    filterName
  );

  useEffect(() => {
    let search_text = localStorage.getItem("Assessment_search");
    if (search_text) {
      setSearchText(search_text);
      getConsultantListing(search_text);
    } else {
      setSearchText("");
      getConsultantListing("");
    }
  }, [page, rowsPerPage]);
  const MENU_OPTIONS = [
    {
      label: "View History",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
  ];
  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
  };
  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    {
      id: "email",
      label: "Name",
      renderData: (row) => {
        return (
          <span onClick={() => handleEdit(row)} className="pointer">
            {" "}
            {htmlDecode(row.Username)}
          </span>
        );
      },
    },
    { id: "email", label: "Email", alignRight: false },
    { id: "coins", label: "Attitude Coins", alignRight: false },
    { id: "delegate", label: "Delegate", alignRight: false },
    { id: "nurture", label: "Nurture", alignRight: false },
    { id: "time", label: "Completed Date", alignRight: false },
    { id: "action", label: "Action", alignRight: false, type: "action" },
  ];

  const isUserNotFound = filteredUsers.length === 0;
  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleDelete}
      />

      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-sm-12">
            <h2>Assessments</h2>
          </div>
          <div className="col-lg-4 col-sm-12 text-end"></div>
        </div>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        ></Stack>
        <div className="col-12 mt-4">
          <CustomMUITable
            TABLE_HEAD={TABLE_HEAD}
            data={userList}
            selected={selected}
            MENU_OPTIONS={MENU_OPTIONS}
            setSelected={setSelected}
            className="card-with-background"
            custom_pagination={{
              total_count: totalCount,
              rows_per_page: rowsPerPage,
              page: page,
              handleChangePage: handleChangePage,
              onRowsPerPageChange: handleChangeRowsPerPage,
            }}
            custom_search={{
              searchText: searchText,
              setSearchText: setSearchText,
              handleSubmit: searchFunction,
            }}
            pageCount={pageCount}
            totalPages={totalPages}
            handleChangePages={handleChangePages}
            pagePagination={true}
          />
        </div>
      </div>
    </>
  );
}
