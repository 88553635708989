import { Avatar, Tooltip } from "@mui/material";
import React from "react";
import { s3baseUrl } from "src/config/config";
import { useChat } from "src/Hooks/ChatContext/ChatContext";
import {
  get_date_with_user_time_zone,
  string_avatar,
} from "src/utils/constants";
import {
  _delete_message,
  _get_chat_message,
  _send_message,
  _update_message,
} from "src/DAL/Chat/Chat";
import { Icon } from "@iconify/react";

import { useContentSetting } from "src/Hooks/ContentSettingState";
import ChatMessages from "../components/ChatMessages/ChatMessages";
import BroadCastChatMessages from "./BroadCastChatMessages";
import { useState } from "react";
import { broadcast, details } from "src/assets";

function BroadcastChatRight({
  drawerState,
  setDrawerState,
  broadcastMain,
  setBroadcast,
  setBroadcastEdit,
  setCount,
}) {
  const { userInfo } = useContentSetting();
  const [showDetail, setShowDetail] = useState(false);
  const { selectedChat, handleCloseChat, selectedChatUser } = useChat();
  const receiver_profile = selectedChatUser;
  const showDetilss = () => {
    setShowDetail(true);
  };
  const showDrawer = () => {
    setDrawerState(true);
    setBroadcastEdit(true);
  };

  return (
    <div className="chat-right-wrapper">
      <div className="chat-right-head p-1 d-flex justify-content-between">
        {broadcastMain && (
          <Tooltip title="View Detail">
            <div className="d-flex pointer" onClick={showDetilss}>
              <>
                <div className="user-profile">
                  <Avatar
                    src={broadcast}
                    alt={"BroadCastChatList"}
                    style={{ height: 45, width: 45 }}
                  ></Avatar>
                </div>
                <div className="chat-profile-data">
                  <div
                    className="chat-profile-name ps-2 text-capitalize d-flex"
                    style={{ alignItems: "center" }}
                  >
                    {broadcastMain.broadcast_title}
                    <img
                      src={details}
                      alt="details"
                      className="pointer ms-2"
                      style={{ height: 16, width: 16 }}
                    />
                  </div>
                </div>
              </>
            </div>
          </Tooltip>
        )}
        {broadcastMain && (
          <div className="me-2" onClick={() => setBroadcast("")}>
            <Icon
              fontSize={18}
              className="chat-box-cross-icon"
              icon="material-symbols:close"
            />
          </div>
        )}
      </div>

      <BroadCastChatMessages
        is_popup={false}
        showDetail={showDetail}
        setShowDetail={setShowDetail}
        drawerState={drawerState}
        setDrawerState={showDrawer}
        broadcastMain={broadcastMain}
        setBroadcastEdit={setBroadcastEdit}
        setCount={setCount}
      />
    </div>
  );
}

export default BroadcastChatRight;
