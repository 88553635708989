import { Tooltip } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { dd_date_format } from "src/utils/constants";
import { urlify } from "src/utils/convertHtml";

const scrollableContentStyle = {
  maxHeight: "350px",
  overflowY: "auto",
  overflowWrap: "anywhere",
};

const NotesPopup = ({ reverse_client_note }) => {
  return (
    <div style={scrollableContentStyle} className="notes-popup">
      {reverse_client_note.map((item, index) => {
        return (
          <div className={`mb-2 ${index === 0 ? "mt-3" : ""}`} key={index}>
            <span className="count-color-user">{index + 1}</span>
            <span
              dangerouslySetInnerHTML={{
                __html: urlify(item.note),
              }}
            ></span>
            <p className="text-end note-date mt-2">
              {dd_date_format(item.note_date_time)}
            </p>
            <hr />
          </div>
        );
      })}
    </div>
  );
};

export default function PersonalNotes({ client_note }) {
  const [isOpen, setIsOpen] = useState(false);
  const boxRef = useRef(null);
  let reverse_client_note = [...client_note].reverse();
  const handleOpen = () => {
    console.log(isOpen, "isOpenisOpenisOpenisOpenisOpen");
    setIsOpen((old) => !old);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      // Check if the clicked element is outside the box
      if (boxRef.current && !boxRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    // Add the click event listener to the document when the box is open
    if (isOpen) {
      document.addEventListener("click", handleOutsideClick);
    }

    // Cleanup: Remove the event listener when the component unmounts or when the box is closed
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [isOpen]);

  return (
    <div
      className="toltip_border"
      // onClick={handleOpen}
    >
      <Tooltip
        title={
          reverse_client_note.length > 0 ? (
            <div style={scrollableContentStyle}>
              {reverse_client_note.map((item, index) => {
                return (
                  <div
                    className={`mb-2 ${index === 0 ? "mt-3" : ""}`}
                    key={index}
                  >
                    <span className="count-color-user">{index + 1}</span>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: urlify(item.note),
                      }}
                    ></span>
                    <p className="text-end note-date mt-2">
                      {dd_date_format(item.note_date_time)}
                    </p>
                    <hr />
                  </div>
                );
              })}
            </div>
          ) : (
            ""
          )
        }
      >
        <span className="count-color" onClick={handleOpen}>
          {reverse_client_note.length}
        </span>
      </Tooltip>
      {reverse_client_note.length > 0 && isOpen && (
        <div className="ref" ref={boxRef}>
          <NotesPopup reverse_client_note={reverse_client_note} />
        </div>
      )}
    </div>
  );
}
