import React, { useState } from "react";
import ProgramsPopover from "./ProgramsPopover";

export default function ProgramsInfo({ row, groupData }) {
  const [program, setprogram] = useState([]);
  const [titleInfo, setTitleInfo] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const opens = Boolean(anchorEl);
  const id = opens ? "simple-popover" : undefined;

  const handleClickPopUP = (event, data, title) => {
    setTitleInfo(title);
    let real_data = [];
    data.map((item) => {
      if (item._id !== null) {
        real_data.push(item);
      }
    });
    setprogram(real_data);
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopUp = () => {
    setAnchorEl(null);
  };
  let programs_array = row.program.filter((item) => {
    if (item._id !== null) {
      return item;
    }
  });
  let events_array = row.event.filter((item) => {
    if (item._id !== null) {
      return item;
    }
  });
  const first_programs = programs_array?.slice(0, 5);
  const first_events = events_array?.slice(0, 5);

  return (
    <>
      {first_programs &&
      first_programs.length > 0 &&
      groupData.group_by == "program"
        ? first_programs?.map((program, i) => {
            if (program._id !== null) {
              return (
                <>
                  <div key={i}>
                    <div>{program?._id?.title + ","}</div>
                  </div>
                  {i == 4 && (
                    <div
                      className="mb-1 mt-1 view-more"
                      variant="contained"
                      onClick={(e) =>
                        handleClickPopUP(e, row.program, "Programmes")
                      }
                    >
                      view more
                    </div>
                  )}
                </>
              );
            }
          })
        : first_events?.map((event, i) => {
            if (event._id !== null) {
              return (
                <>
                  <div key={i}>
                    <div>{event?._id?.title + ","}</div>
                  </div>
                  {i == 4 && (
                    <div
                      className="mb-1 mt-1 view-more"
                      variant="contained"
                      onClick={(e) => handleClickPopUP(e, row.event, "Events")}
                    >
                      view more
                    </div>
                  )}
                </>
              );
            }
          })}
      <ProgramsPopover
        handleClick={handleClickPopUP}
        handleClose={handleClosePopUp}
        anchorEl={anchorEl}
        id={id}
        open={opens}
        program={program}
        titleInfo={titleInfo}
      />
    </>
  );
}
