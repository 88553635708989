import { Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { client_url, s3baseUrl } from "src/config/config";
import KeyIcon from "@mui/icons-material/Key";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useSnackbar } from "notistack";
import SmartDisplayIcon from "@mui/icons-material/SmartDisplay";
import { isFileExistsOnS3 } from "src/utils/file_utils";

export default function MeetingActionIcons(props) {
  const { item } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [isFileExist, setIsFileExist] = useState(false);
  let meeting_link = `${client_url}join-meeting/${item._id}`;

  const handleCopyMessage = (message) => {
    enqueueSnackbar(message, { variant: "success" });
  };

  useEffect(async () => {
    if (item.stream_recording_url) {
      const is_file = await isFileExistsOnS3(item.stream_recording_url);
      setIsFileExist(is_file);
    }
  }, [item]);

  return (
    <div className="meeting-actions d-flex">
      <div className="meeting-action-item">
        <CopyToClipboard
          text={meeting_link}
          onCopy={() => handleCopyMessage("Meeting Link Copied to clipboard")}
        >
          <Tooltip title="Copy Meeting Link">
            <div className="link-chip">
              <ContentCopyIcon
                style={{
                  fontSize: "16px",
                }}
              />
            </div>
          </Tooltip>
        </CopyToClipboard>
      </div>
      <div className="meeting-action-item">
        <a href={meeting_link} target="_blank">
          <Tooltip title="Open Meeting Link">
            <div className="link-chip">
              <OpenInNewIcon
                style={{
                  fontSize: "16px",
                }}
              />
            </div>
          </Tooltip>
        </a>
      </div>
      {item.meeting_password && (
        <div className="meeting-action-item">
          <CopyToClipboard
            text={item.meeting_password}
            onCopy={() =>
              handleCopyMessage("Meeting Password Copied to clipboard")
            }
          >
            <Tooltip title="Copy Meeting Password">
              <div className="link-chip">
                <KeyIcon
                  style={{
                    fontSize: "16px",
                  }}
                />
              </div>
            </Tooltip>
          </CopyToClipboard>
        </div>
      )}
      {item.meeting_document && (
        <div className="meeting-action-item">
          <a href={s3baseUrl + item.meeting_document} target="_blank">
            <Tooltip title="View Meeting Document">
              <div className="link-chip">
                <VisibilityIcon
                  style={{
                    fontSize: "16px",
                  }}
                />
              </div>
            </Tooltip>
          </a>
        </div>
      )}
      {item.stream_recording_url && isFileExist && (
        <div className="meeting-action-item">
          <a href={s3baseUrl + item.stream_recording_url} target="_blank">
            <Tooltip title="View Meeting Recording">
              <div className="link-chip">
                <SmartDisplayIcon
                  style={{
                    fontSize: "16px",
                  }}
                />
              </div>
            </Tooltip>
          </a>
        </div>
      )}
    </div>
  );
}
