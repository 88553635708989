import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  Input,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import GeneralQuestionStarRating from "src/components/GeneralComponents/GeneralQuestions/GeneralQuestionStarRating";
import { s3baseUrl } from "src/config/config";
import {
  add_answers_api,
  questions_listing_api,
  upload_document_api,
} from "src/DAL/GeneralQuestions/GeneralQuestions";
import { delete_image_on_s3_for_chat } from "src/DAL/LiveEvents/LiveEvents";
import { useContentSetting } from "src/Hooks/ContentSettingState";
import RecordNotFound from "src/components/GeneralComponents/RecordNotFound";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function GeneralQuestionsList({
  created_for,
  remove_back_button,
}) {
  const classes = useStyles();
  const navigate = useNavigate();
  const { search } = useLocation();
  const { userInfo } = useContentSetting();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [questions, setQuestions] = useState([]);
  const [moduleTitle, setModuleTitle] = useState("");
  const [questionConfigration, setQuestionConfigration] = useState([]);
  const [questionAnswers, setQuestionsAnswers] = useState([]);
  const created_for_id = new URLSearchParams(search).get("created_for_id");

  const get_questions_list = async () => {
    let postObject = {
      created_for_id: created_for_id,
      created_for: created_for,
    };

    const result = await questions_listing_api(postObject);
    if (result.code === 200) {
      let data = result.questionnaire.map((question) => {
        return {
          ...question,
          isExpanded: true,
          isLoading: false,
        };
      });
      setQuestions(data);
      setQuestionsAnswers(result.questionnaire_replies);
      setQuestionConfigration(result.question_configration);
      setModuleTitle(result.title);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChangeAnswer = (question_id, selected_option) => {
    if (questionAnswers.length > 0) {
      let answered = questionAnswers.find(
        (answer) => answer._id == question_id
      );
      if (answered) {
        setQuestionsAnswers((old_answers) =>
          old_answers.map((answers) => {
            if (answers._id == question_id) {
              return {
                ...answers,
                answer_statement: selected_option,
              };
            } else {
              return answers;
            }
          })
        );
      } else {
        setQuestionsAnswers((old_answers) => [
          ...old_answers,
          {
            _id: question_id,
            answer_statement: selected_option,
          },
        ]);
      }
    } else {
      setQuestionsAnswers([
        {
          _id: question_id,
          answer_statement: selected_option,
        },
      ]);
    }
  };
  const fileChangedHandler = async (question_id, e) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    const imageUpload = await upload_document_api(formData);
    if (imageUpload.code == 200) {
      let document_url = imageUpload.image_path;
      if (questionAnswers.length > 0) {
        let answered = questionAnswers.find(
          (answer) => answer._id == question_id
        );
        if (answered) {
          setQuestionsAnswers((old_answers) =>
            old_answers.map((answers) => {
              if (answers._id == question_id) {
                return {
                  ...answers,
                  document_url: document_url,
                };
              } else {
                return answers;
              }
            })
          );
        } else {
          setQuestionsAnswers((old_answers) => [
            ...old_answers,
            {
              _id: question_id,
              document_url: document_url,
            },
          ]);
        }
      } else {
        setQuestionsAnswers([
          {
            _id: question_id,
            document_url: document_url,
          },
        ]);
      }
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleChangeAnswerCheckbox = (question_id, selected_option) => {
    if (questionAnswers.length > 0) {
      let answered = questionAnswers.find(
        (answer) => answer._id == question_id
      );
      if (answered) {
        setQuestionsAnswers((old_answers) =>
          old_answers.map((answers) => {
            if (answers._id == question_id) {
              let find_already_exist = answers?.answer_statement?.find(
                (prev) => prev == selected_option
              );
              let new_array = answers.answer_statement;
              if (find_already_exist) {
                new_array = answers.answer_statement.filter(
                  (prev) => prev !== selected_option
                );
              } else {
                if (new_array && new_array.length > 0) {
                  new_array = [...answers.answer_statement, selected_option];
                } else {
                  new_array = [selected_option];
                }
              }
              return {
                ...answers,
                answer_statement: new_array,
              };
            } else {
              return answers;
            }
          })
        );
      } else {
        setQuestionsAnswers((old_answers) => [
          ...old_answers,
          {
            _id: question_id,
            answer_statement: [selected_option],
          },
        ]);
      }
    } else {
      setQuestionsAnswers([
        {
          _id: question_id,
          answer_statement: [selected_option],
        },
      ]);
    }
  };

  const expandArea = (index) => {
    let temp_state = [...questions];
    let temp_element = { ...temp_state[index] };
    temp_element.isExpanded = !temp_element.isExpanded;
    temp_state[index] = temp_element;
    setQuestions(temp_state);
  };

  const handleStartLoading = (index) => {
    let temp_state = [...questions];
    let temp_element = { ...temp_state[index] };
    temp_element.isLoading = true;
    temp_state[index] = temp_element;
    setQuestions(temp_state);
  };

  const handleStopLoading = (index) => {
    let temp_state = [...questions];
    let temp_element = { ...temp_state[index] };
    temp_element.isLoading = false;
    temp_state[index] = temp_element;
    setQuestions(temp_state);
  };

  const handleSubmit = async (e, index) => {
    e.preventDefault();
    if (index == 0 || index > 0) {
      handleStartLoading(index);
    } else {
      setIsLoadingForm(true);
    }
    let postData = {
      created_for: questions[0]?.created_for,
      created_for_id: created_for_id ? created_for_id : "",
      question_answer_array: questionAnswers,
    };
    const result = await add_answers_api(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      if (index == 0 || index > 0) {
        handleStopLoading(index);
      } else {
        if (questionConfigration?.is_show_thank_you_page) {
          navigate(`${window.location.pathname}/thank-you`, {
            state: {
              question_configration: questionConfigration,
              module_title: moduleTitle,
            },
          });
        }
        setIsLoadingForm(false);
      }
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingForm(false);
      handleStopLoading(index);
    }
  };

  const handleDeleteDocument = async (question_id, image_path) => {
    const formData = new FormData();
    formData.append("image", image_path);

    const result = await delete_image_on_s3_for_chat(formData);
    if (result.code === 200) {
      setQuestionsAnswers((prev) => {
        return prev.map((old_question) => {
          if (old_question._id == question_id) {
            delete old_question.document_url;
          }
          return old_question;
        });
      });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  useEffect(() => {
    get_questions_list();
  }, []);

  const replacedString = (string) => {
    if (string) {
      return string.replace(/{Name}/g, userInfo?.first_name);
    } else {
      return "";
    }
  };

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      <div className="row">
        {!remove_back_button && (
          <div className="col-12">
            <IconButton
              className="back-screen-button"
              onClick={() => navigate(-1)}
            >
              <ArrowBackIcon />
            </IconButton>
            {moduleTitle && (
              <span className="sale-page-title">{moduleTitle}</span>
            )}
          </div>
        )}
      </div>
      <div className="row justify-content-center pb-5">
        <div className="col-12 mt-2 general-questions-top-description">
          {questionConfigration &&
            questionConfigration.questions_top_description && (
              <div
                dangerouslySetInnerHTML={{
                  __html: questionConfigration?.questions_top_description,
                }}
              ></div>
            )}
          <div className="col-12 mt-4">
            {questions.length > 0 ? (
              questions.map((question, i) => {
                let find_answer = questionAnswers.find(
                  (answer) => answer._id == question._id
                );

                console.log(find_answer, "find_answerfind_answerfind_answer");

                return (
                  <div className="card mb-2" key={question.id}>
                    <Accordion expanded={question.isExpanded}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        onClick={() => expandArea(i)}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className="accordion-summary qustion-section-summary"
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html: replacedString(question.question_statement),
                          }}
                        ></div>
                      </AccordionSummary>

                      <AccordionDetails>
                        {question.question_type == "mcq" ? (
                          <FormControl className="w-100">
                            <RadioGroup
                              aria-labelledby="demo-radio-buttons-group-label"
                              defaultValue=""
                              name="radio-buttons-group"
                              value={find_answer?.answer_statement}
                              onChange={(e) =>
                                handleChangeAnswer(question._id, e.target.value)
                              }
                            >
                              {question?.options.map((option, index) => (
                                <div
                                  className={`question-answer-new  ${
                                    find_answer?.answer_statement
                                      ? option === find_answer?.answer_statement
                                        ? "selectedAnswer"
                                        : ""
                                      : ""
                                  }`}
                                  key={option.id}
                                >
                                  <FormControlLabel
                                    value={option}
                                    id={`capsule${index}`}
                                    className="question-answer-one"
                                    control={<Radio />}
                                    label={option}
                                  />
                                </div>
                              ))}
                            </RadioGroup>
                          </FormControl>
                        ) : question.question_type == "checkbox" ? (
                          <FormControl className="w-100">
                            <FormGroup
                              aria-labelledby="demo-radio-buttons-group-label"
                              defaultValue=""
                              name="radio-buttons-group"
                              value={question?.answer}
                              onChange={(e) =>
                                handleChangeAnswerCheckbox(
                                  question._id,
                                  e.target.value
                                )
                              }
                            >
                              {question?.options.map((option, index) => {
                                let checkbox_answer =
                                  find_answer?.answer_statement?.find(
                                    (old_answer) => old_answer == option
                                  );
                                return (
                                  <div
                                    className={`question-answer-new`}
                                    key={option.id}
                                  >
                                    <FormControlLabel
                                      value={option}
                                      id={`capsule${index}`}
                                      className="question-answer-one"
                                      control={
                                        <Checkbox
                                          checked={
                                            checkbox_answer ? true : false
                                          }
                                        />
                                      }
                                      label={option}
                                    />
                                  </div>
                                );
                              })}
                            </FormGroup>
                          </FormControl>
                        ) : question.question_type == "scaling" ? (
                          <FormControl className="w-100">
                            <ul className="list-unstyled pt-1 mb-0">
                              <li className="row">
                                <div className="col-12">
                                  <GeneralQuestionStarRating
                                    question={question}
                                    handleAnswer={handleChangeAnswer}
                                    find_answer={find_answer}
                                  />
                                </div>
                              </li>
                            </ul>
                          </FormControl>
                        ) : (
                          <TextField
                            id="outlined-multiline-static"
                            label={question.question_placeholder}
                            multiline
                            rows={4}
                            className="mb-3 w-100"
                            value={find_answer?.answer_statement}
                            variant="outlined"
                            onChange={(e) =>
                              handleChangeAnswer(question._id, e.target.value)
                            }
                          />
                        )}
                        <div className="col-12 text-end pt-2">
                          <button
                            className="small-contained-button save-and-next mt-2 me-2"
                            onClick={(e) => {
                              handleSubmit(e, i);
                            }}
                          >
                            {question.isLoading ? "Saving..." : "Save"}
                          </button>
                          {question.is_document_allowed && (
                            <>
                              {find_answer?.document_url ? (
                                <button className="small-contained-button button-link preview mt-2">
                                  <span
                                    onClick={(e) => {
                                      handleDeleteDocument(
                                        question._id,
                                        find_answer.document_url
                                      );
                                    }}
                                  >
                                    x
                                  </span>
                                  <a
                                    href={s3baseUrl + find_answer.document_url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    View Document
                                  </a>
                                </button>
                              ) : (
                                <label htmlFor={`contained-button-file${i}`}>
                                  <Input
                                    id={`contained-button-file${i}`}
                                    multiple
                                    type="file"
                                    name="image"
                                    className="d-none"
                                    onChange={(e) => {
                                      fileChangedHandler(question._id, e);
                                    }}
                                  />

                                  <Button
                                    className="small-contained-button"
                                    startIcon={<FileUploadIcon />}
                                    component="span"
                                  >
                                    Upload Document
                                  </Button>
                                </label>
                              )}
                            </>
                          )}
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                );
              })
            ) : (
              <RecordNotFound title="Questions not found" />
            )}
          </div>
          {questions.length > 0 && (
            <div className="mt-4 text-center">
              <button
                type="button"
                onClick={(e) => {
                  handleSubmit(e);
                }}
                className="black-btn"
              >
                {isLoadingForm ? "Submitting..." : "Submit"}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
