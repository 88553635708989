import React from "react";
import ReactVideoPlayer from "src/components/ReactPlayers/ReactVideoPlayer";

export const VideoSeries = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-12 text-center mt-2">
          <h1>Video Series 1</h1>
          <p className="text-center">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry.
          </p>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum is simply dummy text of the printing and
            typesetting industry.
          </p>
        </div>
        <div className="col-12 mt-3">
          <div className="video-portal-border w-100 ">
            {
              <ReactVideoPlayer url="https://www.youtube.com/watch?v=D0UnqGm_miA"></ReactVideoPlayer>
            }
          </div>
        </div>
      </div>
    </div>
  );
};
