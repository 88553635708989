import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Radio,
  Select,
  TextField,
} from "@mui/material";
import React, { useRef, useState } from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { useParams } from "react-router-dom";
import { makeStyles } from "@mui/styles";

import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
// import {
//   _add_wheel_of_life,
//   _delete_wheel_of_life,
//   _get_wheel_of_life,
//   _update_wheel_of_life,
// } from "src/DAL/QuestionSurvey/QuestionSurvey";
import { useSnackbar } from "notistack";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useEffect } from "react";
import CustomConfirmation from "src/components/menuIcons/CustomConfirmation";
import SearchNotFound from "src/components/SearchNotFound";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
const data = [
  {
    createdAt: "2022-09-12T05:56:15.782Z",
    created_for: "sale_page",
    options: ["Great ", "Feeling Ok ", "Feeling Poor "],
    order: 1,
    question_statement: "How are you feeling today",
    question_type: "mcq",
    sale_page: "62d9067acce729f78b8660f7",
    scaling_color: "",
    scaling_main_heading: "",
    scaling_max: 5,
    scaling_min: 0,
    updatedAt: "2022-09-13T06:00:06.202Z",
    _id: "631ec9ff7d507f12377ab775",
  },
  {
    createdAt: "2022-09-13T06:01:02.495Z",
    created_for: "sale_page",
    options: [],
    order: 2,
    question_statement: "How would you rate this area of your life?",
    question_type: "scaling",
    sale_page: "62d9067acce729f78b8660f7",
    scaling_color: "green",
    scaling_main_heading: "Health",
    scaling_max: 10,
    scaling_min: 1,
    updatedAt: "2022-09-13T06:01:02.495Z",
    _id: "63201c9e010489420c5ae352",
  },
];

export const QuestionSurvey = ({ question }) => {
  //console.log(question, "question survey");
  const params = useParams();
  const [openDelete, setOpenDelete] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const ref = useRef(null);
  const [selectedQuestion, setSelectedQuestion] = useState({});
  const [formAction, setFormAction] = useState("ADD");
  const [isOpenForm, setIsOpenForm] = useState(false);
  const [questionsList, setQuestionsList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [sliderDay, setSliderDay] = useState(5);
  const [inputList, setInputList] = useState([{ option: "", checked: false }]);
  const classes = useStyles();
  const [sliderMarks, setSliderMArks] = useState([]);
  const [inputs, setInputs] = useState({
    question_type: "mcq",
    scaling_min: "",
    scaling_max: "",
    question_statement: "",
    scaling_color: "#000",
  });

  const handleClick = () => {
    setIsOpenForm(true);
    setTimeout(() => {
      ref.current?.scrollIntoView({ behavior: "smooth" });
    }, 100);
  };

  // handle click event of the Add button
  const handleAddClick = (index) => {
    let newIndex = inputList.length + 1;
    setInputList([
      ...inputList.slice(0, index),
      {
        option: "",
        checked: false,
      },
      ...inputList.slice(index),
    ]);
  };
  const marks = [
    {
      value: 0,
      // scaledValue: 0,
      label: "0",
    },
    {
      value: 2,
      // scaledValue: 2,
      label: "2",
    },
    {
      value: 4,
      // scaledValue: 4,
      label: "4",
    },
    {
      value: 6,
      // scaledValue: 6,
      label: "6",
    },
    {
      value: 8,
      // scaledValue: 8,
      label: "8",
    },
    {
      value: 10,
      // scaledValue: 10,
      label: "10",
    },
  ];

  function valuetext(value) {
    return `${value}°C`;
  }
  function valueLabelFormat(value) {
    return marks?.findIndex((mark) => mark.value === value) + 1;
  }
  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  const expandArea = (index) => {
    let temp_state = [...questionsList];
    let temp_element = { ...temp_state[index] };
    if (temp_element.isExpanded === false) {
      temp_element.isExpanded = true;
    } else {
      temp_element.isExpanded = false;
    }
    temp_state[index] = temp_element;
    setQuestionsList(temp_state);
  };
  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };

  const handleChecked = (e, i) => {
    const { name, checked } = e.target;
    const list = [...inputList];
    if (list[i][name] === true) {
      list[i][name] = false;
    } else {
      list[i][name] = true;
    }
    setInputList(list);
  };

  const handleRemoveValues = () => {
    setInputs({
      question_type: inputs.question_type,
      scaling_min: "",
      scaling_max: "",
      scaling_main_heading: "",
      question_statement: "",
      scaling_color: "#000",
    });
    setInputList([{ option: "", checked: false }]);
    setFormAction("ADD");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    let error = "";
    const formData = new FormData();
    formData.append("question_statement", inputs.question_statement);
    formData.append("question_type", inputs.question_type);

    if (params && params.page_id) {
      formData.append("created_for", "sale_page");
      formData.append("page_id", params.page_id);
    } else {
      formData.append("created_for", "general");
    }

    if (inputs.question_type == "scaling") {
      formData.append("scaling_max", inputs.scaling_max);
      formData.append("scaling_min", inputs.scaling_min);
      formData.append("scaling_color", inputs.scaling_color);
      formData.append("scaling_main_heading", inputs.scaling_main_heading);
    } else {
      let options = [];
      inputList.map((item, index) => {
        if (item.option === "") {
          error = `Option ${index + 1} is required`;
          return;
        }
        options.push(item.option);
      });
      formData.append("options", JSON.stringify(options));
    }
    if (error) {
      enqueueSnackbar(error, { variant: "error" });
      setIsSubmitting(false);
    } else {
      const result = await formData;
      if (result.code === 200) {
        getQuestionSurveyList(params?.page_id);
        handleRemoveValues();
        setIsSubmitting(false);
        enqueueSnackbar(result.message, { variant: "success" });
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsSubmitting(false);
      }
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    let error = "";
    const formData = new FormData();
    formData.append("question_statement", inputs.question_statement);
    formData.append("question_type", inputs.question_type);
    formData.append("order", selectedQuestion.order);

    if (params && params.page_id) {
      formData.append("created_for", "sale_page");
      formData.append("page_id", params.page_id);
    } else {
      formData.append("created_for", "general");
    }

    if (inputs.question_type == "scaling") {
      formData.append("scaling_max", inputs.scaling_max);
      formData.append("scaling_min", inputs.scaling_min);
      formData.append("scaling_color", inputs.scaling_color);
      formData.append("scaling_main_heading", inputs.scaling_main_heading);
    } else {
      let options = [];
      inputList.map((item, index) => {
        if (item.option === "") {
          error = `Option ${index + 1} is required`;
          return;
        }
        options.push(item.option);
      });
      formData.append("options", JSON.stringify(options));
    }
    if (error) {
      enqueueSnackbar(error, { variant: "error" });
      setIsSubmitting(false);
    } else {
      const result = await _update_wheel_of_life(
        selectedQuestion._id,
        formData
      );
      if (result.code === 200) {
        getQuestionSurveyList(params?.page_id);
        handleRemoveValues();
        setIsSubmitting(false);
        enqueueSnackbar(result.message, { variant: "success" });
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsSubmitting(false);
      }
    }
  };

  const getQuestionSurveyList = async (page_id) => {
    const result = await page_id;

    let questionsArray = [];
    question?.question_array.map((value, index) => {
      if (index === 0) {
        questionsArray.push({
          ...value,
          isExpanded: true,
        });
      } else {
        questionsArray.push({
          ...value,
          isExpanded: false,
        });
      }
    });
    setQuestionsList(questionsArray);
    setIsLoading(false);
    if (result.code === 200) {
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    const result = await _delete_wheel_of_life(selectedQuestion._id);
    if (result.code === 200) {
      getQuestionSurveyList(params?.page_id);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  useEffect(() => {
    getQuestionSurveyList(params?.page_id);
  }, []);
  useEffect(() => {
    let maxVal = 100;
    let mark = [];
    let marksObject = {};
    for (let i = 0; i < maxVal.length; i++) {
      if (i % 2 == 0) {
        marksObject = {
          value: i,
          label: i,
        };
        mark.push(marksObject);
        //console.log(marksObject, "marksObject");
      }
    }
    setSliderMArks(mark);
  }, []);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div>
      {questionsList.length < 0 ? (
        questionsList.map((questions, index) => {
          return (
            <div className="row mb-2">
              <Accordion
                expanded={questions.isExpanded}
                className="question-background question-style"
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  onClick={() => expandArea(index)}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  className="svg-color question-style"
                >
                  <div className="col-lg-12 col-md-9 col-sm-12 mt-1 me-3">
                    {questions.question_type == "scaling" ? (
                      <b>{questions.scaling_main_heading}</b>
                    ) : (
                      <>{questions.question_statement}</>
                    )}
                  </div>
                </AccordionSummary>
                <AccordionDetails className="pt-0">
                  <>
                    {questions.question_type == "mcq" ? (
                      <div className="row d-flex">
                        {questions.options.map((option, i) => {
                          return (
                            <>
                              <div className="col-6  d-flex">
                                <Radio
                                  checked={option == questions.answer}
                                  name="checked"
                                />
                                <p className="pt-3">{option}</p>
                              </div>
                            </>
                          );
                        })}
                      </div>
                    ) : (
                      <>
                        {questions.question_type == "scaling" && (
                          <p>{questions.question_statement}</p>
                        )}

                        <div className="col-12">
                          <Box sx={{ width: "100%" }}>
                            <Slider
                              className="mt-4"
                              aria-label="Always visible"
                              getAriaValueText={valuetext}
                              // step={10}
                              value={questions.answer}
                              marks={marks}
                              max={
                                questions.scaling_max > questions.answer
                                  ? questions.scaling_max
                                  : questions.answer
                              }
                              valueLabelDisplay="on"
                            />
                          </Box>
                        </div>
                      </>
                    )}
                  </>
                </AccordionDetails>
              </Accordion>
            </div>
          );
        })
      ) : (
        <h2>Sorry No Data Found</h2>
      )}

      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleDelete}
      />
    </div>
  );
};
