import { invokeApi } from "../../bl_libs/invokeApi";
export const AddEmailSettingApi = async (data) => {
  const requestObj = {
    path: `/api/email_setting/`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const emailSettingApi = async (data) => {
  const requestObj = {
    path: `/api/email_setting/`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const clientSettingApi = async (data) => {
  const requestObj = {
    path: `/api/content_setting/`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const updateConsultantGeneralSettingApi = async (data) => {
  const requestObj = {
    path: `/api/content_setting/add_consultant_setting`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const updateClientGeneralSettingApi = async (data) => {
  const requestObj = {
    path: `/api/content_setting/client_general_setting`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const updateWheelOfLifeSettingApi = async (data) => {
  const requestObj = {
    path: `/api/content_setting/wheel_of_life_content_setting`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const UpdateDynamiteDiarySetting = async (data) => {
  const requestObj = {
    path: `/api/attitude_assessment_question/assessment_setting_update/dynamite_diary`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const UpdateMeditationSetting = async (data) => {
  const requestObj = {
    path: `/api/attitude_assessment_question/assessment_setting_update/mediation`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const UpdateMeditationChallengeSetting = async (data) => {
  const requestObj = {
    path: `/api/attitude_assessment_question/assessment_setting_update/meditation_challenge`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const UpdateGratitudeDailySetting = async (data) => {
  const requestObj = {
    path: `/api/attitude_assessment_question/assessment_setting_update/gratitude_daily `,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const updateSiteSettingApi = async (data) => {
  const requestObj = {
    path: `/api/content_setting/update_pages_content`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const updateClientContentSettingApi = async (data) => {
  const requestObj = {
    path: `/api/content_setting/`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const updateClientDashboardSettingApi = async (data) => {
  const requestObj = {
    path: `/api/content_setting/dashboard_setting`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const updateThanksSettingApi = async (data, slug) => {
  const requestObj = {
    path: `api/sale_page/update/page-content/${slug}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const updateUnpublishSetting = async (data) => {
  const requestObj = {
    path: `api/sale_page/update_page_access_info`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const updateCoinConfiguration = async (data) => {
  const requestObj = {
    path: `/admin_users/update_coins_configuration`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
