// ----------------------------------------------------------------------

import { user, unlock } from "src/assets";

const getIcon = (name) => (
  <img className="navbar-icon" src={name} width={18} height={18} />
);

const SettingsSidebarConfig = ({ foundObject }) => {
  let sidebarMenus = [];
  sidebarMenus.push({
    title: "Zoom Setting",
    path: "/zoom-setting",
    icon: getIcon(user),
  });
  if (foundObject) {
    sidebarMenus.push({
      title: "Welcome Reminder Setting",
      path: "/welcome-reminder-setting",
      icon: getIcon(user),
    });
  }
  sidebarMenus.push({
    title: "Change Password",
    path: "/change-password",
    icon: getIcon(unlock),
    password: true,
  });

  return sidebarMenus;
};
export default SettingsSidebarConfig;
