import React, { useEffect, useState } from "react";
import MUICustomTabs from "src/components/GeneralComponents/MUICustomTabs";
import { useContentSetting } from "src/Hooks/ContentSettingState";
import CircleIcon from "@mui/icons-material/Circle";
import { consultantProfileApi } from "src/DAL/Login/Login";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { useSnackbar } from "notistack";
import {
  _move_need_attention,
  _move_reminder,
  markResolved,
} from "src/DAL/SupportTicket/SupportTicket";
import moment from "moment";
import { makeStyles } from "@mui/styles";
import { CircularProgress } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import CustomConfirmation from "src/components/menuIcons/CustomConfirmation";
import { get_list_internal_tickets_with_pagination_new } from "src/DAL/InternalTickets/InternalTickets";
import MarkResolve from "./MarkResolve";
import CustomDrawer from "src/components/DrawerForm/CustomDrawer";
import InternalNote from "../SupportTicket/InternalNote";
import WhatsappPopUpModel from "src/components/GeneralComponents/WhatsappPopUpModel";
import FullPagePopup from "src/components/GeneralComponents/FullPagePopup";
import InternalNoteNew from "../SupportTicket/InternalNoteNew";
import FullPagePopupSupportTicket from "./FullPagePopupSupportTicket";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

let status_object = {
  0: "waiting",
  1: "answered",
  2: "needs_to_attention",
  3: "solved",
};

const InternalTickets = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const { state } = useLocation();
  const { userInfo, handleSetUserInfo } = useContentSetting();
  const [tabValue, setTabValue] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [Waiting, setWaiting] = useState(" ");
  const settingss = useContentSetting();
  const { socket } = settingss;
  const [Answered, setAnswered] = useState(" ");
  const [openNeedAttention, setopenNeedAttention] = useState(false);
  const [solved, setSolved] = useState(" ");
  const [NeedsAttentionCount, setNeedsAttentionCount] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [userList, setUserList] = useState([]);
  const [openReminder, setopenReminder] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [drawerMarkResolveState, setdrawerMarkResolveState] = useState(false);
  const [drawerInternalNote, setdrawerInternalNote] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [ticketId, setTicketId] = useState("");
  const [ticketData, setticketData] = useState();
  const [openResolved, setOpenResolved] = useState(false);
  const [MoveStatus, setMoveStatus] = useState("");
  const answered = window.location.pathname.includes(
    "/internal-tickets/answered"
  );
  const attention = window.location.pathname.includes(
    "/internal-tickets/needs-attention"
  );
  const solve = window.location.pathname.includes("/internal-tickets/solved");
  const handleChange = (event, newValue) => {
    setSearchText("");
    setTabValue(newValue);
  };

  const getProfile = async () => {
    const result = await consultantProfileApi(userInfo?._id);
    if (result.code == 200) {
      localStorage.setItem(`user_data`, JSON.stringify(result.consultant));
      localStorage.setItem(`admin_time_zone`, JSON.stringify(result.time_zone));
      handleSetUserInfo(result.consultant);
    }
  };

  const handleMarkResolved = async () => {
    setOpenResolved(false);
    setIsLoading(true);
    const result = await markResolved(ticketId);
    if (result.code === 200) {
      getMemberListing(searchText);
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
    localStorage.setItem("member_page_number", newPage);
  };

  const searchFunction = () => {
    setPage(0);
    setPageCount(1);

    if (answered) {
      localStorage.setItem("answer-internal-search", searchText);
    } else if (attention) {
      localStorage.setItem("attention-internal-search", searchText);
    } else if (solve) {
      localStorage.setItem("solve-internal-search", searchText);
    } else {
      localStorage.setItem("internal-search", searchText);
    }
    getMemberListing(tabValue, searchText);
  };

  const handleNavigatePage = (value) => {
    if (answered) {
      navigate(`/internal-tickets/answered/${value._id}`, {
        state: { value, tabValue },
      });
    } else if (attention) {
      navigate(`/internal-tickets/needs-attention/${value._id}`, {
        state: { value, tabValue },
      });
    } else if (solve) {
      navigate(`/internal-tickets/solved/${value._id}`, {
        state: { value, tabValue },
      });
    } else {
      navigate(`/internal-tickets/${value._id}`, {
        state: { value, tabValue },
      });
    }
  };

  const handleOpenMarkResolveDrawer = (value) => {
    setdrawerMarkResolveState(true);
    setTicketId(value._id);
  };

  const handleMarkResolvedPopup = (value) => {
    setTicketId(value._id);
    setOpenResolved(true);
  };

  const handleCloseMarkResolveDrawer = () => {
    setdrawerMarkResolveState(false);
  };

  const getMemberListing = async (value, search_text) => {
    setIsLoading(true);
    let filter_By = status_object[value ? value : tabValue];
    console.log(filter_By, "filter_Byfilter_By");
    const result = await get_list_internal_tickets_with_pagination_new(
      filter_By,
      page,
      rowsPerPage,
      search_text
    );
    if (result.code === 200) {
      setWaiting(result?.waiting_ticket_count);
      setAnswered(result?.answered_ticket_count);
      setSolved(result?.solved_ticket_count);
      setNeedsAttentionCount(result?.need_to_attention_count);
      const data = result.support_ticket.map((ticket) => {
        let full_name = ticket?.member?.first_name
          ? ticket.member.first_name + " " + ticket.member.last_name
          : "N/A";
        let department_name = ticket.department ? ticket.department.title : "";
        let created_at = moment(
          ticket.support_ticket_date,
          "YYYY-MM-DD HH:mm"
        ).fromNow();
        let last_activity_date_time = ticket?.last_action_date
          ? moment(ticket?.last_action_date, "YYYY-MM-DD HH:mm").fromNow()
          : "N/A";

        return {
          ...ticket,
          full_name,
          department_name,
          created_at,
          last_activity_date_time,
        };
      });
      setTotalCount(result?.total_count);
      setTotalPages(result?.total_pages);
      setUserList(data);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handleMoveAttened = (value) => {
    setTicketId(value._id);
    setopenReminder(true);
    setMoveStatus("attended");
  };
  const handleMoveNeedAttentionPopUp = (value) => {
    setTicketId(value._id);
    setopenNeedAttention(true);
  };
  const handleMoveNeedAttention = async () => {
    setopenNeedAttention(false);
    setIsLoading(true);
    const result = await _move_need_attention(ticketId);
    if (result.code === 200) {
      setIsLoading(false);
      getMemberListing(tabValue, searchText);
      enqueueSnackbar(result.message, { variant: "success" });
      socket.emit("send_add_support_ticket_comment", result);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handleReminder = async () => {
    setIsLoading(true);
    setopenReminder(false);
    const postData = {
      status_to_move: MoveStatus,
      support_ticket: ticketId,
    };
    const result = await _move_reminder(postData);
    if (result.code === 200) {
      getMemberListing(tabValue, searchText);
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handleOpenInternalNoteDrawer = (value) => {
    setdrawerInternalNote(true);
    setticketData(value);
    setTicketId(value._id);
  };
  const handleCLoseInternalNoteCLoseDrawer = () => {
    setdrawerInternalNote(false);
  };
  useEffect(() => {
    setTimeout(() => {
      if (answered) {
        setTabValue(1);
      } else if (attention) {
        setTabValue(2);
      } else if (solve) {
        setTabValue(3);
      }
    }, 300);
  }, []);
  const MENU_OPTIONS = [
    {
      label: "Detail",
      icon: "gg:details-more",
      handleClick: handleNavigatePage,
    },
    {
      label: "Internal Notes",
      icon: "gg:details-more",
      handleClick: handleOpenInternalNoteDrawer,
    },
    {
      label: "Mark Resolve",
      icon: "emojione-monotone:heavy-check-mark",
      handleClick: handleMarkResolvedPopup,
    },
    {
      label: "Move To Needs Attention",
      icon: "fe:loop",
      handleClick: handleMoveNeedAttentionPopUp,
    },
  ];

  const MENU_OPTIONS_SOLVED = [
    {
      label: "Detail",
      icon: "gg:details-more",
      handleClick: handleNavigatePage,
    },
  ];
  const MENU_OPTIONS_Attention = [
    {
      label: "Detail",
      icon: "gg:details-more",
      handleClick: handleNavigatePage,
    },
    {
      label: "Internal Notes",
      icon: "gg:details-more",
      handleClick: handleOpenInternalNoteDrawer,
    },
    {
      label: "Attended",
      icon: "fe:loop",
      handleClick: handleMoveAttened,
    },
  ];
  const MENU_OPTIONS_ANS = [
    {
      label: "Detail",
      icon: "gg:details-more",
      handleClick: handleNavigatePage,
    },
    {
      label: "Internal Notes",
      icon: "gg:details-more",
      handleClick: handleOpenInternalNoteDrawer,
    },
    {
      label: "Mark Resolve",
      icon: "emojione-monotone:heavy-check-mark",
      handleClick: handleOpenMarkResolveDrawer,
    },
    {
      label: "Move To Needs Attention",
      icon: "fe:loop",
      handleClick: handleMoveNeedAttentionPopUp,
    },
  ];

  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number", alignRight: false },
    {
      id: "full_name",
      label: "User Name",
      renderData: (row) => {
        return (
          <>
            <p
              onClick={() => handleNavigatePage(row)}
              className="mb-0"
              style={{ cursor: "pointer" }}
            >
              {row.full_name}
            </p>
          </>
        );
      },
    },
    {
      id: "subject",
      label: " Subject",
      renderData: (row) => {
        return (
          <>
            <p
              onClick={() => handleNavigatePage(row)}
              className="mb-0"
              style={{ cursor: "pointer" }}
            >
              {row.subject}
            </p>
          </>
        );
      },
    },
    { id: "department_name", label: " Department", alignRight: false },
    { id: "created_at", label: "Created", alignRight: false },
    {
      id: "last_activity_date_time",
      label: "Responded",
      alignRight: false,
    },
    {
      id: "action",
      label: "Action",
      type: "action",
      alignRight: false,
      MENU_OPTIONS: "MENU_OPTIONS",
    },
  ];

  let settings = {
    TABLE_HEAD: TABLE_HEAD,
    data: userList,
    className: "card-with-background mt-4",
    pageCount: pageCount,
    totalPages: totalPages,
    handleChangePages: handleChangePages,
  };
  const handleTabClick = (e, row) => {
    console.log(e, "eeeeeeee");
    console.log(row, "rowrowrowrowrowrowrow");
    if (row.tab_slug == "answered") {
      navigate(`/internal-tickets/answered`);
    } else if (row.tab_slug == "needs_to_attention") {
      navigate(`/internal-tickets/needs-attention`);
    } else if (row.tab_slug == "solved") {
      navigate(`/internal-tickets/solved`);
    } else {
      navigate(`/internal-tickets`);
    }
  };
  const TABS_OPTIONS = [
    {
      title: (
        <div className="d-flex">
          Waiting ({Waiting == undefined ? 0 : Waiting})
          {userInfo.notify_tab == "waiting" && (
            <CircleIcon className="support_notification_sidebar_page" />
          )}
        </div>
      ),
      tab_slug: "waiting",
      onClick: handleTabClick,
      component: (
        <CustomMUITable
          {...settings}
          MENU_OPTIONS={MENU_OPTIONS}
          custom_pagination={{
            total_count: totalCount,
            rows_per_page: rowsPerPage,
            page: page,
            handleChangePage: handleChangePage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          custom_search={{
            searchText: searchText,
            setSearchText: setSearchText,
            handleSubmit: searchFunction,
          }}
          pagePagination={true}
        />
      ),
    },
    {
      title: (
        <div className="d-flex">
          Answered ({Answered == undefined ? 0 : Answered})
          {userInfo.notify_tab == "answered" && (
            <CircleIcon className="support_notification_sidebar_page" />
          )}
        </div>
      ),
      tab_slug: "answered",
      onClick: handleTabClick,
      component: (
        <CustomMUITable
          {...settings}
          MENU_OPTIONS={MENU_OPTIONS_ANS}
          custom_pagination={{
            total_count: totalCount,
            rows_per_page: rowsPerPage,
            page: page,
            handleChangePage: handleChangePage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          custom_search={{
            searchText: searchText,
            setSearchText: setSearchText,
            handleSubmit: searchFunction,
          }}
          pagePagination={true}
        />
      ),
    },
    {
      title: (
        <div className="d-flex">
          Needs Attention (
          {NeedsAttentionCount == undefined ? 0 : NeedsAttentionCount})
          {/* {userInfo.notify_tab == "solved" && (
            <CircleIcon className="support_notification_sidebar_page" />
          )} */}
        </div>
      ),

      tab_slug: "needs_to_attention",
      onClick: handleTabClick,
      component: (
        <CustomMUITable
          MENU_OPTIONS={MENU_OPTIONS_Attention}
          custom_pagination={{
            total_count: totalCount,
            rows_per_page: rowsPerPage,
            page: page,
            handleChangePage: handleChangePage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          custom_search={{
            searchText: searchText,
            setSearchText: setSearchText,
            handleSubmit: searchFunction,
          }}
          {...settings}
          pagePagination={true}
        />
      ),
    },
    {
      title: (
        <div className="d-flex">
          Solved ({solved == undefined ? 0 : solved})
          {userInfo.notify_tab == "solved" && (
            <CircleIcon className="support_notification_sidebar_page" />
          )}
        </div>
      ),
      tab_slug: "solved",
      onClick: handleTabClick,
      component: (
        <CustomMUITable
          MENU_OPTIONS={MENU_OPTIONS_SOLVED}
          custom_pagination={{
            total_count: totalCount,
            rows_per_page: rowsPerPage,
            page: page,
            handleChangePage: handleChangePage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          custom_search={{
            searchText: searchText,
            setSearchText: setSearchText,
            handleSubmit: searchFunction,
          }}
          {...settings}
          pagePagination={true}
        />
      ),
    },
  ];

  useEffect(() => {
    getProfile();
  }, [tabValue]);

  useEffect(() => {
    let search_text = localStorage.getItem("internal-search");
    if (answered) {
      search_text = localStorage.getItem("answer-internal-search");
    } else if (attention) {
      search_text = localStorage.getItem("attention-internal-search");
    } else if (solve) {
      search_text = localStorage.getItem("solve-internal-search");
    } else {
      search_text = localStorage.getItem("internal-search");
    }
    if (search_text) {
      setSearchText(search_text);
    }
    getMemberListing(tabValue, search_text);
  }, [page, rowsPerPage, tabValue]);
  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <div className="container">
      <div className="row section-space">
        <div className="col-sm-12 col-md-6 mb-4">
          <h2>Internal Tickets</h2>
        </div>
        <div className="col-12">
          <MUICustomTabs
            data={TABS_OPTIONS}
            value={tabValue}
            handleChange={handleChange}
          />
        </div>
        <CustomConfirmation
          open={openResolved}
          setOpen={setOpenResolved}
          title={"Are you sure you want to mark this ticket as resolved?"}
          handleAgree={handleMarkResolved}
        />
        <CustomDrawer
          isOpenDrawer={drawerMarkResolveState}
          onOpenDrawer={handleOpenMarkResolveDrawer}
          onCloseDrawer={handleCloseMarkResolveDrawer}
          pageTitle="Mark Resolve"
          componentToPassDown={
            <MarkResolve
              formType="ADD"
              ticketId={ticketId}
              dataList={getMemberListing}
              onCloseDrawer={handleCloseMarkResolveDrawer}
            />
          }
        />
        <CustomConfirmation
          open={openNeedAttention}
          setOpen={setopenNeedAttention}
          title={"Are you sure you want to move this ticket to need attention?"}
          handleAgree={handleMoveNeedAttention}
        />
        <CustomConfirmation
          open={openReminder}
          setOpen={setopenReminder}
          title={
            MoveStatus == "ready_to_close"
              ? "Are you sure you want to move this ticket to ready to close?"
              : MoveStatus == "fixed"
              ? "Are you sure you want to move this ticket to fixed?"
              : MoveStatus == "attended"
              ? "Are you sure you want to move this ticket to attended?"
              : "Are you sure you want to move this ticket to Reminder?"
          }
          handleAgree={handleReminder}
        />
        {/* <CustomDrawer
          isOpenDrawer={drawerInternalNote}
          onOpenDrawer={handleOpenInternalNoteDrawer}
          onCloseDrawer={handleCLoseInternalNoteCLoseDrawer}
          pageTitle="Internal Notes"
          componentToPassDown={
            <InternalNote
              formType="ADD"
              ticketId={ticketId}
              dataList={getMemberListing}
              onCloseDrawer={handleCLoseInternalNoteCLoseDrawer}
            />
          }
        /> */}
        <FullPagePopupSupportTicket
          open={drawerInternalNote}
          setOpen={setdrawerInternalNote}
          ticketData={ticketData}
          componentToPassDown={
            <InternalNoteNew
              formType="ADD"
              ticketId={ticketId}
              dataList={getMemberListing}
              onCloseDrawer={handleCLoseInternalNoteCLoseDrawer}
            />
          }
        />
      </div>
    </div>
  );
};

export default InternalTickets;
