import { Dialog } from "@mui/material";
import React from "react";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

export default function GeneralPopUpModelWarning({
  open,
  title,
  setOpen,
  componentToPassDown,
}) {
  return (
    <>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{
          className: "p-3 general-popup-model",
        }}
        disableEnforceFocus={true}
      >
        <div class="cross-icon" onClick={() => setOpen(false)}>
          x
        </div>
        <div className="popup-title d-flex justify-content-center ">
          <h2>{title}</h2>
          <span className="ms-2 mt-1">
            <WarningAmberIcon />
          </span>
        </div>
        <hr />
        <div className="responce-messages">{componentToPassDown}</div>
      </Dialog>
    </>
  );
}
