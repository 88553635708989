import { invokeApi } from "../../bl_libs/invokeApi";

export const add_lead_stage_api = async (data) => {
  const requestObj = {
    path: `api/stage/add`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const add_user_to_lead_stage_api = async (data) => {
  const requestObj = {
    path: `api/stage/user/add`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const change_stage_order_api = async (data, stage_id) => {
  const requestObj = {
    path: `api/stage/change_order/${stage_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const update_lead_stage_api = async (data, stage_id) => {
  const requestObj = {
    path: `api/stage/update/${stage_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const lead_stages_list_api = async (data) => {
  const requestObj = {
    path: `api/stage/list`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const delete_stages_list_api = async (stage_id) => {
  const requestObj = {
    path: `api/stage/delete/${stage_id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const remove_user_from_stage_api = async (data) => {
  const requestObj = {
    path: `api/stage/user/remove`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
