import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { CircularProgress, IconButton } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { client_url, s3baseUrl } from "src/config/config";
import ChatCard from "src/components/SupportTickets/ChatCard";
import ModelBox from "src/components/ModalBox/ImageModalBox";
import {
  csvImage,
  docImage,
  pdfImage,
  wordImage,
  audioImage,
  xlsxImage,
  otherImage,
} from "src/assets";
const imageLinks = {
  docx: wordImage,
  mp3: audioImage,
  pdf: pdfImage,
  csv: csvImage,
  doc: docImage,
  xlsx: xlsxImage,
  xls: xlsxImage,
  other: otherImage,
};
import {
  _internal_note,
  markResolved,
  SupportTicketDetailApi,
} from "src/DAL/SupportTicket/SupportTicket";
import { htmlDecode } from "src/utils/convertHtml";
import CustomConfirmation from "src/components/menuIcons/CustomConfirmation";
import CustomPopover from "src/components/CustomPopover";
import CustomDrawer from "src/components/DrawerForm/CustomDrawer";
import InternalNote from "./InternalNote";
import moment from "moment";
import MarkResolve from "./MarkResolve";
import { useContentSetting } from "src/Hooks/ContentSettingState";
import {
  TIME_ZONE_CONVERSION,
  get_date_with_user_time_zone,
} from "src/utils/constants";
import FullPagePopupSupportTicket from "../InternalTicket/FullPagePopupSupportTicket";
import InternalNoteNew from "./InternalNoteNew";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function SupportTicketDetail(props) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const params = useParams();
  const { state } = useLocation();
  const [supportTicketData, setSupportTicketData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [drawerInternalNote, setdrawerInternalNote] = useState(false);
  const [open, setOpen] = useState(false);
  const [drawerMarkResolveState, setdrawerMarkResolveState] = useState(false);
  const [openResolved, setOpenResolved] = useState(false);
  const [AutoResponded, setAutoResponded] = useState([]);
  const [ticketId, setTicketId] = useState("");
  const [lastAction, setlastAction] = useState("");

  const [internalNoteCount, setinternalNoteCount] = useState("");
  const [navigateBack, setnavigateBack] = useState(true);
  const [imageUrl, setImageUrl] = useState("");
  const [MemberData, setMemberData] = useState({});
  const { adminTimeZone, userInfo } = useContentSetting();
  const handleOpen = (image_path) => {
    setImageUrl(image_path);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);
  const getSupportTicketDetail = async () => {
    const result = await SupportTicketDetailApi(params.id);
    if (result.code === 200) {
      setlastAction(result.support_ticket.last_action_date);
      console.log(result.support_ticket.last_action_date, "resultresult");
      console.log(result, "resultresult2");
      setMemberData(result.support_ticket);
      setAutoResponded(
        result.auto_responder_message,
        "result.auto_responder_message"
      );
      setSupportTicketData(result.support_ticket);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      navigate(`/support-ticket`);
    }
  };
  const getInternalNote = async () => {
    const result = await _internal_note(params.id);
    console.log(result, "result from internal api");
    if (result.code === 200) {
      setinternalNoteCount(result?.support_ticket?.internal_note.length);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const get_last_responded_time = (date) => {
    return TIME_ZONE_CONVERSION(
      date,
      "DD-MM-YYYY hh:mm A",
      adminTimeZone,
      userInfo.time_zone
    );
  };
  const handleNavigate = () => {
    navigate("/support-ticket", { state: state ? state : 0 });
  };
  const handleOpenMarkResolveDrawer = (value) => {
    setdrawerMarkResolveState(true);
    setTicketId(params.id ? params.id : value.id);
  };
  const handleCloseMarkResolveDrawer = () => {
    setdrawerMarkResolveState(false);
  };
  const handleCopyString = async (value, name) => {
    await navigator.clipboard.writeText(value);
    enqueueSnackbar(name + " Copied", { variant: "success" });
  };
  const handleMarkResolvedPopup = (value) => {
    setOpenResolved(true);
  };
  const handleMarkResolved = async () => {
    setOpenResolved(false);
    setIsLoading(true);
    const result = await markResolved(params.id);
    if (result.code === 200) {
      getSupportTicketDetail(result.support_ticket);
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      navigate(`/support-ticket`);
    }
  };
  const handleLoginAsCustomer = () => {
    // navigate(`${client_url}login-as-customer`);
  };
  const getCommentImage = (file) => {
    const ext = file.thumbnail_1.split(".").pop();
    if (
      ext == "jpg" ||
      ext == "JPG" ||
      ext == "png" ||
      ext == "webp" ||
      ext == "jpeg" ||
      ext == "JPEG" ||
      ext == "PNG"
    ) {
      return s3baseUrl + file.thumbnail_2;
    } else if (imageLinks[ext]) {
      return imageLinks[ext];
    } else {
      return imageLinks.other;
    }
  };
  const handleLoginAsCustomers = (value) => {
    window.open(`${client_url}login-as-customer`, "_blank");
  };
  const handleCopyPassword = (value) => {
    handleCopyString("Met@Logix", "Password");
  };
  const MENU_OPTIONS = [
    {
      label:
        supportTicketData.ticket_status === 1 ||
        supportTicketData.ticket_status === 2
          ? "Resolved"
          : "Mark Resolve",
      icon: "gg:details-more",
      handleClick:
        supportTicketData.ticket_status === 1 ||
        supportTicketData.ticket_status === 2
          ? ""
          : handleOpenMarkResolveDrawer,
    },
    {
      label: "Login As Customer",
      icon: "gg:details-more",
      handleClick: handleLoginAsCustomers,
    },
    {
      label: "Copy Password",
      icon: "gg:details-more",
      handleClick: handleCopyPassword,
    },
  ];
  // internal note drawer
  const handleOpenInternalNoteDrawer = (value, id) => {
    setdrawerInternalNote(true);
    setTicketId(id ? id : params.id ? params.id : value.id);
  };
  const handleCLoseInternalNoteCLoseDrawer = () => {
    setdrawerInternalNote(false);
    getInternalNote();
  };

  useEffect(() => {
    getSupportTicketDetail();
    if (state) {
      if (state.open == true) {
        handleOpenInternalNoteDrawer("", state.support_ticket);
      }
    }
  }, []);
  useEffect(() => {
    getInternalNote();
  }, []);
  console.log(params.id, "params.idparams.idparams.id");
  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <>
      {Object.keys(MemberData).length > 0 ? (
        <div className="container">
          <CustomDrawer
            isOpenDrawer={drawerMarkResolveState}
            onOpenDrawer={handleOpenMarkResolveDrawer}
            onCloseDrawer={handleCloseMarkResolveDrawer}
            pageTitle="Mark Resolve"
            componentToPassDown={
              <MarkResolve
                formType="ADD"
                ticketId={ticketId}
                // dataList={getTickestsListing}
                onCloseDrawer={handleCloseMarkResolveDrawer}
                navigateBack={navigateBack}
              />
            }
          />
          <div className="row mobile-margin display-flex">
            <div className="col-12 button-menu">
              <div>
                <IconButton
                  className="back-screen-button me-3"
                  onClick={handleNavigate}
                >
                  <ArrowBackIcon />
                </IconButton>
                <span
                  className="anchor-style"
                  onClick={() =>
                    handleCopyString(
                      state?.value?.object?.member?.email,
                      "Email"
                    )
                  }
                >
                  {MemberData?.member?.first_name
                    ? MemberData?.member?.first_name +
                      " " +
                      MemberData?.member?.last_name +
                      " (" +
                      MemberData?.member?.email +
                      ")"
                    : ""}
                </span>
                {/* <p>{htmlDecode(supportTicketData.description)}</p> */}
              </div>

              <div style={{ display: "flex" }}>
                {/* {supportTicketData.ticket_status === 1 ||
            supportTicketData.ticket_status === 2 ? (
              <button
                className="small-contained-button float-end mt-1 disabled"
                disabled="disabled"
              >
                Resolved
              </button>
            ) : (
              <button
                className="small-contained-button float-end mt-1"
                onClick={handleMarkResolvedPopup}
              >
                Mark Resolve
              </button>
            )} */}
                {/* <a
            className="small-contained-button float-end mt-1 me-2"
            href={client_url + "login-as-customer"}
            target="_blank"
          >
            Login As Customer
          </a> */}
                <button
                  className="small-contained-button float-end mt-1 ms-2"
                  onClick={() => handleOpenInternalNoteDrawer()}
                >
                  Internal Notes ({internalNoteCount ? internalNoteCount : "0"})
                </button>
                <CustomPopover menu={MENU_OPTIONS} />
              </div>
            </div>
          </div>
          <div className="row section-space-support-ticket-setail">
            <div className="col-sm-12 col-lg-6 col-md-6 d-flex justify-content-between mt-4">
              <h1 style={{ width: "100%" }}>
                {htmlDecode(supportTicketData.subject)}
              </h1>
            </div>
            <div className="col-sm-12 col-lg-6 col-md-6 d-flex justify-content-end mt-4">
              <div className="detials-date-container">
                <div className="d-flex">
                  <h4 style={{ color: "#f6bd4b" }}>Created Date:</h4>
                  <span className="ms-2">
                    {moment(state?.value?.createdAt).format(
                      "DD-MM-YYYY hh:mm A"
                    )}
                  </span>
                </div>
                <div className="d-flex">
                  <h4 style={{ color: "#f6bd4b" }} className="mt-1">
                    Responded Time:
                  </h4>
                  <span className="ms-2" style={{ marginTop: "3px" }}>
                    {lastAction
                      ? get_last_responded_time(lastAction)
                      : "Not Responded Yet"}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="row media-margin">
            <div className="col-12">
              <p>{htmlDecode(supportTicketData.description)}</p>
            </div>
            <div className="col-sm-12 my-2">
              {supportTicketData.ticket_images &&
                supportTicketData.ticket_images.map((image, i) => {
                  const ext = image.thumbnail_1.split(".").pop();
                  if (
                    ext == "jpg" ||
                    ext == "JPG" ||
                    ext == "png" ||
                    ext == "webp" ||
                    ext == "jpeg" ||
                    ext == "JPEG" ||
                    ext == "PNG"
                  ) {
                    return (
                      <span className="preview ticket_image_preview" key={i}>
                        <img
                          onClick={() => {
                            handleOpen(s3baseUrl + image.thumbnail_1);
                          }}
                          className="p-0"
                          src={s3baseUrl + image.thumbnail_2}
                        />
                      </span>
                    );
                  } else {
                    return (
                      <span className="preview ticket_image_preview" key={i}>
                        <a
                          href={s3baseUrl + image.thumbnail_1}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img src={getCommentImage(image)} />
                        </a>
                      </span>
                    );
                  }
                })}
            </div>
            <ChatCard
              comments={supportTicketData.comment}
              ticket_id={supportTicketData._id}
              AutoResponded={AutoResponded}
              list_data={getSupportTicketDetail}
              supportTicketData={supportTicketData}
              setlastAction={setlastAction}
              memberName={state?.value?.object?.member?.first_name}
              memberEmail={state?.value?.object?.member?.email}
            />
            <ModelBox
              open={open}
              handleClose={handleClose}
              image_url={imageUrl}
            />
            <CustomConfirmation
              open={openResolved}
              setOpen={setOpenResolved}
              title={"Are you sure you want to mark this Ticket as resolved?"}
              handleAgree={handleMarkResolved}
            />
            <FullPagePopupSupportTicket
              open={drawerInternalNote}
              setOpen={setdrawerInternalNote}
              ticketData={MemberData}
              componentToPassDown={
                <InternalNoteNew
                  formType="ADD"
                  ticketId={params.id}
                  setinternalNoteCount={setinternalNoteCount}
                  onCloseDrawer={handleCLoseInternalNoteCLoseDrawer}
                />
              }
            />
          </div>
        </div>
      ) : (
        <CircularProgress className={classes.loading} color="primary" />
      )}
    </>
  );
}
