import { CircularProgress } from "@mui/material";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomDrawer from "src/components/DrawerForm/CustomDrawer";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import RecordNotFound from "src/components/RecordNotFound";
import { listAppointmentApi } from "src/DAL/appointment/appointment";
import { appointmentListing } from "src/DAL/GoalStatement/GoalStatement";
import AddAppointment from "./AddAppointment";
import { makeStyles } from "@mui/styles";
import CustomConfirmation from "src/components/GeneralComponents/CustomConfirmation";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const Appointment = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [drawerState, setDrawerState] = useState(false);
  const [appointments, setAppointments] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const handleOpenDrawer = () => {
    setDrawerState(true);
    navigate("/appointment/add-appointment");
  };

  // const handleNavigateSakesCount = (value) => {
  //   navigate(`/all-leads/${value._id}`, {
  //     state: value,
  //   });
  // };
  const handleNavigate = () => {
    setDrawerState(true);
    navigate("/appointment");
  };
  const handleAgreeDelete = (value) => {
    console.log(value, "i am value");
    // setProgressId(value._id);

    setOpenDelete(true);
  };
  const handleDelete = async () => {
    setOpenDelete(false);

    // const result = await DeleteProgress(progressId);
    // if (result.code === 200) {
    //   getProgressListingOnDelete(progressId);
    //   enqueueSnackbar(result.message, { variant: "success" });
    // } else {
    //   enqueueSnackbar(result.message, { variant: "error" });
    // }
  };
  const getList = async () => {
    setIsLoading(true);
    const result = await appointmentListing();
    if (result.code == 200) {
      console.log(result, "resultttt");
      let array = result.appointments.map((appointment) => {
        return {
          ...appointment,
          start_date: moment(appointment.start_date).format("DD-MM-YYYY"),
          end_date: moment(appointment.end_date).format("DD-MM-YYYY"),
        };
      });
      setAppointments(array);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleCloseDrawer = () => {
    setDrawerState(false);
  };
  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleNavigate,
    },

    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];
  const array = [
    {
      duration: "45 min",
      startenddate: "11 to 17",
      fromto: "11AM to 5PM",
      day: "Monday",
    },
    {
      duration: "15 min",
      startenddate: "3 to 7",
      fromto: "2AM to 9PM",
      day: "Monday",
    },
  ];
  const TABLE_HEAD = [
    { id: "number", label: "#", alignRight: false, type: "number" },
    // { id: "start_date", label: "Start Date", alignRight: false },
    // { id: "end_date", label: "End Date", alignRight: false },
    { id: "name", label: "Name", alignRight: false },
    {
      id: "date",
      label: "Date",
      alignRight: false,
      renderData: (row) => {
        return (
          <div>
            <span>{row.start_date}</span>
            <span className="ms-1 me-1 date-heading ">-</span>
            <span>{row.end_date}</span>
          </div>
        );
      },
    },
    {
      id: "days",
      label: "Days",
      alignRight: false,
      // renderData: (row) => {
      //   console.log(row, "rowwww");
      //   if (row?.days?.length > 0) {
      //     return row?.days?.map((value) => {
      //       return <div>{value ? value : "N/A"}</div>;
      //     });
      //   }
      // },
      // renderData: (row) => {
      //   if (row?.days?.length > 0) {
      //     const days = row.days.map((value) => `${value}, `);
      //     days[days.length - 1] = days[days.length - 1].replace(", ", "");
      //     return <div>{days}</div>;
      //   } else {
      //     return "N/A";
      //   }
      // },
      renderData: (row) => {
        if (row?.days?.length > 0) {
          const firstFourDays = row.days.slice(0, 4).join(", ");
          const restOfDays = row.days.slice(4).join(", ");
          return (
            <div>
              <div>{firstFourDays}</div>
              {restOfDays && <div>{restOfDays}</div>}
            </div>
          );
        } else {
          return "N/A";
        }
      },
    },
    // {
    //   id: "slots",
    //   label: "Time Slots",
    //   alignRight: false,
    //   renderData: (row) => {
    //     if (row?.slots?.length > 0) {
    //       return row?.slots?.map((value) => {
    //         return (
    //           <div>
    //             <span className="me-2">{value.start_time}</span>to
    //             <span className="ms-2">{value.end_time}</span>
    //           </div>
    //         );
    //       });
    //     }
    //   },
    // },

    { id: "action", label: "Action", alignRight: false, type: "action" },
  ];

  React.useEffect(() => {
    getList();
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this Configuration?"}
        handleAgree={handleDelete}
      />
      <CustomDrawer
        isOpenDrawer={drawerState}
        onOpenDrawer={handleOpenDrawer}
        onCloseDrawer={handleCloseDrawer}
        pageTitle="New Appointment"
        customWidth={500}
        componentToPassDown={<AddAppointment />}
      />
      <div className="container">
        {/* <div className="col-6 mb-4">
            <h2>Appointment Configuration</h2>
          </div>
          <div className="col-12">
            <button
              className="small-contained-button float-end mt-1 mb-4"
              onClick={handleNavigate}
            >
              Add New Configuration
            </button>
          </div> */}
        <div className="row section-space">
          <div className="col-sm-12 col-md-6">
            <h2>Appointments Configuration</h2>
          </div>
          <div className="col-sm-12 col-md-6 text-end">
            <button
              className="small-contained-button float-end mt-1 mb-4"
              onClick={handleNavigate}
            >
              Add New Configuration
            </button>
          </div>

          {/* {<RecordNotFound title="Appointments" />} */}
          <CustomMUITable
            TABLE_HEAD={TABLE_HEAD}
            data={appointments}
            MENU_OPTIONS={MENU_OPTIONS}
            className="card-with-background"
          />
        </div>
      </div>
    </>
  );
};

export default Appointment;
