import React from "react";
import { useState, useEffect } from "react";
import { Button, Box, TextField } from "@mui/material";
import roundClearAll from "@iconify/icons-ic/round-clear-all";
import { get_root_value } from "src/utils/domUtils";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { Icon } from "@iconify/react";
import MUIAutocomplete from "../GeneralComponents/MUIAutocomplete";
import { get_full_name } from "src/utils/constants";
import { pages_and_delegates_list_api } from "src/DAL/Tracking/Tracking";

export const SalesPerformanceFilter = ({
  filterData,
  handleChaneOthers,
  handleSubmitFilter,
  handleClearFilter,
  delegate_id,
}) => {
  const [salePageList, setSalePageList] = useState([]);
  const [delegatesList, setDelegatesList] = useState([]);
  const [serchText, setSerchText] = useState("");

  const getPageListing = async () => {
    const result = await pages_and_delegates_list_api(serchText, delegate_id);
    if (result.code === 200) {
      if (!serchText) {
        let pages = result.sale_pages.map((item) => {
          let page_title = item.sale_page_title;
          if (item.action_by === "admin_user") {
            page_title = page_title + " (Admin)";
          }
          return {
            ...item,
            chip_label: item.sale_page_title,
            page_title: page_title,
            chip_value: item._id,
          };
        });
        setSalePageList(pages);
      }
      let delegates = result.delegate.map((item) => {
        return {
          ...item,
          chip_label: `Compare with : ${get_full_name(item)}`,
          user_name: get_full_name(item),
          chip_value: item._id,
        };
      });
      setDelegatesList(delegates);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  useEffect(() => {
    getPageListing();
  }, [serchText]);

  return (
    <div className="container new-memories">
      <form onSubmit={handleSubmitFilter}>
        <div className="row">
          <div className="col-12 mt-3">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                size="small"
                label={"Month and Year"}
                name="startDate"
                value={filterData.month_and_year}
                views={["month", "year"]}
                onChange={(e) => handleChaneOthers("month_and_year", e)}
                maxDate={new Date()}
                renderInput={(params) => (
                  <TextField {...params} required={true} fullWidth />
                )}
              />
            </LocalizationProvider>
          </div>
          <div className="col-12 mt-3">
            <MUIAutocomplete
              multiple
              inputLabel="Select Pages"
              selectedOption={filterData.sale_page}
              setSelectedOption={(value) => {
                handleChaneOthers("sale_page", value);
              }}
              optionsList={salePageList}
              name="page_title"
            />
          </div>
          <div className="col-12 mt-3">
            <MUIAutocomplete
              inputLabel="Compare With"
              selectedOption={filterData.compare_with}
              setSelectedOption={(e) => {
                handleChaneOthers("compare_with", e);
              }}
              optionsList={delegatesList}
              name="user_name"
              setCustomSearch={setSerchText}
            />
          </div>
          <Box className="mt-4">
            <Button
              fullWidth
              size="large"
              type="submit"
              color="inherit"
              variant="outlined"
              startIcon={
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ marginRight: 5 }}
                >
                  <path
                    d="M13.1165 0.25H0.883548C0.321452 0.25 0.0378205 0.932013 0.436097 1.33029L5.3125 6.20743V11.6406C5.3125 11.8471 5.41325 12.0406 5.58242 12.1591L7.69179 13.6351C8.10794 13.9264 8.6875 13.6312 8.6875 13.1167V6.20743L13.564 1.33029C13.9615 0.932804 13.6798 0.25 13.1165 0.25Z"
                    fill={get_root_value("--portal-theme-primary")}
                  />
                </svg>
              }
            >
              Filter
            </Button>
          </Box>
        </div>
      </form>
      <Button
        fullWidth
        size="large"
        type="submit"
        color="inherit"
        variant="outlined"
        onClick={handleClearFilter}
        startIcon={<Icon icon={roundClearAll} />}
        className="mt-3"
      >
        Clear All
      </Button>
    </div>
  );
};
