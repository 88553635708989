import { invokeApi } from "../../bl_libs/invokeApi";
const client_token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2MzQxNTMzZTY0YzFjYzY2YzlhMTZhMzgiLCJsb2dpbl90b2tlbiI6IjMyYjQxODYwLTkzMDAtMTFlZC1iN2I4LWI3MjE4Y2QyMTA4MCIsImxvZ2luX2J5IjoibWVtYmVyX3VzZXIiLCJpYXQiOjE2NzM1ODY0NTd9.ADZ6u8jHxmUznLZ5X14R0orpyThKgPVit8s_k1P_pLo"
export const NinteenDayQuestionList = async (data) => {
  const requestObj = {
    path: `api/ninteen_day_vision_questions/get_question`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const EarningListApi = async () => {
  const requestObj = {
    path: `api/delegate_earning/get_delegate_earning`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const AddNineteenDay = async (data) => {
  const requestObj = {
    path: `api/consultant/nineteen_day_vision`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const EditNinetyDayEarning = async (data, id) => {
  const requestObj = {
    path: `api/delegate_earning/edit_delegate_earning/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const AddnineteenDayNewEarning = async (data) => {
  const requestObj = {
    path: `api/delegate_earning/add_delegate_earning`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const DeleteEarning = async (id) => {
  const requestObj = {
    path: `api/delegate_earning/delete_delegate_earning/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};