import { Tooltip } from "@mui/material";
import React from "react";
import { dd_date_format } from "src/utils/constants";

export default function ProgramsList({ programs, handleClick, show_full }) {
  let total_program = programs;
  let show_programs = 2;

  if (!show_full) {
    total_program = programs.slice(0, show_programs);
  }

  return (
    <div className="p-2">
      {programs.length > 0 ? (
        total_program.map((program, i) => {
          return (
            <>
              <Tooltip
                key={i}
                title={
                  program.expiry_date
                    ? " Expiry: " + dd_date_format(program.expiry_date)
                    : "No Expiry"
                }
                arrow
              >
                <p className="mb-1 program-hover">{program._id.title}</p>
              </Tooltip>
              {programs.length > show_programs &&
                handleClick &&
                i == show_programs - 1 && (
                  <p
                    className="mb-1 mt-1 view-more"
                    aria-describedby={`program-hover${i}`}
                    variant="contained"
                    onClick={(e) => handleClick(e, programs)}
                  >
                    view more
                  </p>
                )}
            </>
          );
        })
      ) : (
        <p>N/A</p>
      )}
    </div>
  );
}
