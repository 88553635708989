import React from "react";
import { s3baseUrl } from "src/config/config";
import { Avatar } from "@mui/material";
import RecordNotFound from "../RecordNotFound";
import { get_short_string, replaceName } from "src/utils/constants";
import { htmlDecode } from "src/utils/convertHtml";
import AnswersList from "../GeneralComponents/GeneralQuestions/AnswersList";

function NintyDayQuestions({ sourceSessions, title }) {
  return (
    <div className="mb-3">
      <h4 className="p-3 pb-0">{title ? title : "Goal Statement"}</h4>
      <hr className="mb-0" />
      <div className="p-0">
        {sourceSessions?.length < 1 ? (
          <RecordNotFound title="Questions" />
        ) : (
          <AnswersList is_profile={true} questions={sourceSessions} />
        )}
      </div>
    </div>
  );
}

export default NintyDayQuestions;
