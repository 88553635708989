import React from "react";
import { useNavigate } from "react-router-dom";
import {
  book_image,
  pen2_image,
  pen3_image,
  pen4_image,
  pen5_image,
  pen_image,
} from "src/assets";
import CustomMUICard from "src/components/GeneralComponents/CustomMUICard";

export default function Shop() {
  const navigate = useNavigate();

  const handleClick = (object) => {
    navigate(`/shop/${object._id}`, {
      state: object,
    });
  };
  const data_array = [
    {
      category_heading: "Dynamite Life Style Stationery",
      data: [
        {
          _id: 1,
          card_title: "Dynamite Life Style Pen 1",
          card_short_description: "$48.00 USD",
          card_short_description_2:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard",
          card_image: pen_image,
          card_classes: "col-lg-3 col-md-6 col-sm-12 mb-4 shop-cards",
        },
        {
          _id: 1,
          card_title: "Dynamite Life Style Pen 2",
          card_short_description: "$80.00 USD",
          card_short_description_2:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make",
          card_image: pen2_image,
          card_classes: "col-lg-3 col-md-6 col-sm-12 mb-4 shop-cards",
        },
        {
          _id: 1,
          card_title: "Dynamite Life Style Pen 3",
          card_short_description: "$20.00 USD",
          card_short_description_2:
            "psum has been the industry's standard dummy text ever since the 1500s, when an unknown printer ",
          card_image: pen3_image,
          card_classes: "col-lg-3 col-md-6 col-sm-12 mb-4 shop-cards",
        },
        {
          _id: 1,
          card_title: "Dynamite Life Style Pen 4",
          card_short_description: "$200.00 USD",
          card_short_description_2:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make",
          card_image: pen4_image,
          card_classes: "col-lg-3 col-md-6 col-sm-12 mb-4 shop-cards",
        },
        {
          _id: 1,
          card_title: "Dynamite Life Style Pen 5",
          card_short_description: "$230.00 USD",
          card_short_description_2:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
          card_image: pen5_image,
          card_classes: "col-lg-3 col-md-6 col-sm-12 mb-4 shop-cards",
        },
      ],
    },
    {
      category_heading: "Dynamite Life Style Books",
      data: [
        {
          _id: 1,
          card_title: "Dynamite Life Style Pen",
          card_short_description: "$48.00 USD",
          card_short_description_2:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard",
          card_image: pen_image,
          card_classes: "col-lg-3 col-md-6 col-sm-12 mb-4 shop-cards",
        },
        {
          _id: 1,
          card_title: "Dynamite Life Style Book 1",
          card_short_description: "$80.00 USD",
          card_short_description_2:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make",
          card_image: book_image,
          card_classes: "col-lg-3 col-md-6 col-sm-12 mb-4 shop-cards",
        },
        {
          _id: 1,
          card_title: "Dynamite Life Style Book 2",
          card_short_description: "$20",
          card_short_description_2:
            "psum has been the industry's standard dummy text ever since the 1500s, when an unknown printer ",
          card_image: pen_image,
          card_classes: "col-lg-3 col-md-6 col-sm-12 mb-4 shop-cards",
        },
        {
          _id: 1,
          card_title: "Dynamite Life Style Book 3",
          card_short_description: "$200.00 USD",
          card_short_description_2:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make",
          card_image: book_image,
          card_classes: "col-lg-3 col-md-6 col-sm-12 mb-4 shop-cards",
        },
        {
          _id: 1,
          card_title: "Dynamite Life Style Book 4",
          card_short_description: "$230.00 USD",
          card_short_description_2:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
          card_image: pen_image,
          card_classes: "col-lg-3 col-md-6 col-sm-12 mb-4 shop-cards",
        },
      ],
    },
  ];

  return data_array.map((item) => {
    return (
      <div className="container">
        <h2 className="my-3">{item.category_heading}</h2>
        <CustomMUICard
          data={item.data}
          handleClick={handleClick}
          className="dd-shop-cards"
        />
      </div>
    );
  });
}
