import { invokeApi } from "../../bl_libs/invokeApi";
export const bookingListApi = async (page, limit, data) => {
  const requestObj = {
    // path: `api/consultant/booking/list/v1?page=${page}&limit=${limit}&search_text=${
    //   filterName ? filterName : ""
    // }`,
    path: `api/consultant/booking/list/v1?page=${page}&limit=${limit}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    // postData: {
    //   // filter: valueData,
    // },

    postData: data,
  };
  return invokeApi(requestObj);
};
export const bookingListDelegate = async (page, limit, data, id) => {
  const requestObj = {
    path: `api/consultant/delegate_booking/list/${id}?page=${page}&limit=${limit}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const bookingTimeSlots = async (data) => {
  const requestObj = {
    path: `api/consultant/slots/list/`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const booking_slots_by_delegate_and_date = async (data) => {
  const requestObj = {
    path: `api/consultant/slots_by_delegate`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const bookingPageList = async (filterName) => {
  const requestObj = {
    path: `api/sale_page/booking_page/list_for_consultant?search_text=${
      filterName ? filterName : ""
    }`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const bookingPageListAddNew = async (data) => {
  const requestObj = {
    path: `api/consultant/get_sale_page_member_list_for/booking`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const delegate_list_for_pass_booking = async () => {
  const requestObj = {
    path: `api/consultant/list/for_delegate`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const Addbooking = async (data) => {
  const requestObj = {
    path: `api/consultant/booking/add/`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const AddbookingNew = async (data) => {
  const requestObj = {
    path: `api/consultant/booking/add/new`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const Deletebooking = async (id) => {
  const requestObj = {
    path: `api/consultant/booking/delete/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const Detailbooking = async (id) => {
  const requestObj = {
    path: `api/consultant/booking/detail/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const EditBooking = async (id, data) => {
  const requestObj = {
    path: `api/consultant/booking/update/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const submit_pass_booking_api = async (id, data) => {
  const requestObj = {
    path: `api/consultant/pass_booking/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const update_booking_status_api = async (id, data) => {
  const requestObj = {
    path: `api/consultant/update_booking_status/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const update_member_call_history_api = async (id, data) => {
  const requestObj = {
    path: `api/member/call_history_note/add/${id}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const booking_detail_api = async (booking_id) => {
  const requestObj = {
    path: `admin_users/booking/detail/${booking_id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const active_booking_status_api = async (booking_id) => {
  const requestObj = {
    path: `api/booking_status/active_booking_status`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
