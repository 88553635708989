import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IconButton from "@mui/material/IconButton";
import { useParams } from "react-router-dom";
import { useState } from "react";
import AdapterDayjs from "@mui/lab/AdapterDayjs";
import {
  LocalizationProvider,
  TimePicker,
  DatePicker,
} from "@mui/x-date-pickers";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  TextareaAutosize,
  Button,
  Paper,
  Autocomplete,
  FormHelperText,
  styled,
  CircularProgress,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  create_meeting_api,
  get_meeting_by_id_api,
  update_meeting_api,
} from "src/DAL/Meeting/Meeting";
import { useSnackbar } from "notistack";
import moment from "moment";
import dayjs from "dayjs";
import { TimeZones } from "src/utils/constants";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { s3baseUrl } from "src/config/config";

import {
  csvImage,
  docImage,
  pdfImage,
  wordImage,
  audioImage,
  xlsxImage,
  otherImage,
} from "src/assets";
import GeneralCkeditor from "../GeneralComponents/GeneralCkeditor";

const imageLinks = {
  docx: wordImage,
  mp3: audioImage,
  pdf: pdfImage,
  csv: csvImage,
  doc: docImage,
  xlsx: xlsxImage,
  xls: xlsxImage,
  other: otherImage,
};

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const Input = styled("input")({
  display: "none",
});

const getCommentImage = (file) => {
  const ext = file.name.split(".").pop().toLowerCase();
  if (
    ext == "jpg" ||
    ext == "JPG" ||
    ext == "png" ||
    ext == "webp" ||
    ext == "jpeg" ||
    ext == "JPEG" ||
    ext == "PNG"
  ) {
    return URL.createObjectURL(file);
  } else if (imageLinks[ext]) {
    return imageLinks[ext];
  } else {
    return imageLinks.other;
  }
};

const AddOrUpdateMeeting = () => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { meeting_id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [oldFiles, setOldFiles] = useState({});
  const [files, setFiles] = useState({
    meeting_document: "",
    image: "",
  });

  const [inputs, setInputs] = useState({
    title: "",
    meeting_start_date: new Date(),
    meeting_start_time: new Date(),
    meeting_password: "",
    record_meeting: false,
    description: "",
    meeting_time_zone: "",
    meeting_document: "",
    meeting_agenda: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs({ ...inputs, [name]: value });
  };

  const fileChangedHandler = async (e) => {
    const { name, files } = e.target;
    setFiles((old) => ({ ...old, [name]: files[0] }));
  };

  const handleChangeOthers = (value, name) => {
    setInputs((values) => ({ ...values, [name]: value.$d }));
  };

  const getMeetingDetail = async () => {
    setIsLoading(true);
    const result = await get_meeting_by_id_api(meeting_id);
    if (result.code == 200) {
      let meeting_start_time = `1970-01-01 ${result.meeting.meeting_start_time}`; //adding 1970-01-01 to format correctly you can use any user
      setInputs({
        ...result.meeting,
        meeting_start_time: dayjs(meeting_start_time).$d,
      });
      setOldFiles({
        meeting_document: result?.meeting?.meeting_document,
        image: result?.meeting?.image,
      });
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!meeting_id && !files.image) {
      enqueueSnackbar("Meeting Thumbnail is required", { variant: "error" });
      return;
    }
    setIsLoading(true);
    const formData = new FormData();
    formData.append("title", inputs.title);
    formData.append(
      "meeting_start_date",
      moment(inputs.meeting_start_date).format("YYYY-MM-DD")
    );
    formData.append(
      "meeting_start_time",
      moment(inputs.meeting_start_time, "hh:mm A").format("HH:mm") + ":00"
    );

    formData.append("meeting_password", inputs.meeting_password);
    formData.append("record_meeting", inputs.record_meeting);
    formData.append("description", inputs.description);
    formData.append("meeting_time_zone", inputs.meeting_time_zone);
    formData.append("meeting_agenda", inputs.meeting_agenda);

    if (files.meeting_document) {
      formData.append("meeting_document", files.meeting_document);
    }
    if (files.image) {
      formData.append("image", files.image);
    }

    const result = meeting_id
      ? await update_meeting_api(meeting_id, formData)
      : await create_meeting_api(formData);

    if (result.code === 200) {
      setIsLoading(false);
      navigate(`/meetings`);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  useEffect(() => {
    if (meeting_id) {
      getMeetingDetail();
    }
  }, []);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      <div className="row mobile-margin display-flex mt-2">
        <div className="col-6 d-flex">
          <span>
            <IconButton
              className="back-screen-button mb-3"
              onClick={() => navigate(`/meetings`)}
            >
              <ArrowBackIcon />
            </IconButton>
          </span>
          <h2 className="ms-2">{`${meeting_id ? "Edit" : "Add"} Meeting`}</h2>
        </div>{" "}
      </div>
      <form className="container" onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-md-6 mt-3">
            <TextField
              id="outlined-basic"
              label="Title"
              variant="outlined"
              fullWidth
              required
              name="title"
              value={inputs.title}
              onChange={handleChange}
            />
          </div>
          <div className="col-md-6 mt-4 mt-md-3">
            <TextField
              id="outlined-basic"
              label="Meeting Password"
              variant="outlined"
              fullWidth
              name="meeting_password"
              value={inputs.meeting_password}
              onChange={handleChange}
            />
          </div>
          <div className="col-md-6 mt-4 ">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Meeting Start Date"
                inputFormat="DD-MM-YYYY"
                value={inputs.meeting_start_date}
                disablePast
                onChange={(newValue) =>
                  handleChangeOthers(newValue, "meeting_start_date")
                }
                renderInput={(params) => (
                  <TextField {...params} fullWidth required />
                )}
              />
            </LocalizationProvider>
          </div>
          <div className="col-md-6 mt-4 ">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                label="Meeting Start Time"
                value={inputs.meeting_start_time}
                onChange={(newValue) =>
                  handleChangeOthers(newValue, "meeting_start_time")
                }
                renderInput={(params) => (
                  <TextField {...params} fullWidth required />
                )}
              />
            </LocalizationProvider>
          </div>
          <div className="col-md-6 mt-4">
            <Autocomplete
              value={inputs.meeting_time_zone || ""}
              onChange={(event, newValue) => {
                setInputs({ ...inputs, meeting_time_zone: newValue });
              }}
              inputValue={inputs.meeting_time_zone || ""}
              onInputChange={(event, newInputValue) => {
                setInputs({ ...inputs, meeting_time_zone: newInputValue });
              }}
              PaperComponent={({ children }) => (
                <Paper style={{ color: "#fff" }}>{children}</Paper>
              )}
              id="controllable-states-demo"
              options={TimeZones}
              renderInput={(params) => (
                <TextField {...params} label="Time Zone" required />
              )}
            />
          </div>
          <div className="col-md-6 mt-4">
            <FormControl variant="outlined" required>
              <InputLabel id="demo-simple-select-outlined-label">
                Record Meeting
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={inputs.record_meeting}
                onChange={handleChange}
                label="Record Meeting"
                name="record_meeting"
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <div className="row w-100 div-style ms-0 pt-0">
              <div className="col-5">
                <p className="">Upload Document</p>
                <FormHelperText className="pt-0">
                  Document *("PDF", "DOC", "XLSX", "DOCX","CSV")
                </FormHelperText>
              </div>
              <div className="col-2 meeting-document-thumbnail">
                {files.meeting_document && (
                  <img src={getCommentImage(files.meeting_document)} />
                )}
              </div>
              <div className="col-5 text-end pt-2">
                {oldFiles.meeting_document && !files.meeting_document && (
                  <a
                    href={s3baseUrl + oldFiles.meeting_document}
                    target="_blank"
                    className="me-2 small-contained-button"
                  >
                    View File
                  </a>
                )}
                <label htmlFor="contained-button-file-other">
                  <Input
                    accept=".xlsx,.xls,.doc,.docx,.ppt,.pptx,.txt,.pdf,.png,.csv"
                    id="contained-button-file-other"
                    multiple
                    type="file"
                    name="meeting_document"
                    onChange={fileChangedHandler}
                  />
                  <Button
                    className="small-contained-button"
                    startIcon={<FileUploadIcon />}
                    component="span"
                  >
                    Upload
                  </Button>
                </label>
              </div>
            </div>
            {files?.meeting_document?.name && (
              <p className="text-secondary">{files?.meeting_document?.name}</p>
            )}
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <div className="row w-100 div-style ms-0 pt-0">
              <div className="col-5">
                <p className="">Upload Thumbnail *</p>
                <FormHelperText className="pt-0">
                  Image Size(1000 X 670) ("JPG", "JPEG", "PNG","WEBP")
                </FormHelperText>
              </div>
              <div className="col-2 meeting-document-thumbnail">
                {files.image ? (
                  <img src={getCommentImage(files.image)} />
                ) : (
                  oldFiles.image && (
                    <img src={s3baseUrl + oldFiles.image?.thumbnail_1} />
                  )
                )}
              </div>
              <div className="col-5 text-end pt-2">
                <label htmlFor="contained-button-file">
                  <Input
                    accept="image/*"
                    id="contained-button-file"
                    multiple
                    type="file"
                    name="image"
                    onChange={fileChangedHandler}
                  />

                  <Button
                    className="small-contained-button"
                    startIcon={<FileUploadIcon />}
                    component="span"
                  >
                    Upload
                  </Button>
                </label>
              </div>
            </div>
            {inputs?.image?.name == "" ? (
              ""
            ) : (
              <p className="text-secondary">{inputs?.image?.name}</p>
            )}
          </div>
          <div className="col-md-12 mt-4 ">
            <h4>Description</h4>
            <TextareaAutosize
              aria-label="empty textarea"
              placeholder={"Description"}
              className="text-color textarea-autosize"
              value={inputs.description}
              onChange={handleChange}
              minRows={2}
              name="description"
              style={{
                width: "100%",
                borderRadius: "11px",
                background: "transparent",
                paddingTop: "10px",
                paddingBottom: "10px",
                height: "120px",
                paddingLeft: "15px",
                color: "white",
                fontSize: "15px",
                border: "1px solid rgba(145, 158, 171, 0.32)",
              }}
            />
          </div>
          <div className="col-12 ">
            <p className="tracking_code_heading text-white">Meeting Agenda </p>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="meeting_agenda"
              editorHeight={320}
            />
          </div>
          <div className="text-end mt-4">
            <button className="small-contained-button">
              {meeting_id ? "Update" : "Submit"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddOrUpdateMeeting;
