import { filter } from "lodash";
import { Icon } from "@iconify/react";
import { sentenceCase } from "change-case";
import React, { useState } from "react";
import plusFill from "@iconify/icons-eva/plus-fill";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  IconButton,
  CircularProgress,
  Pagination,
} from "@mui/material";
// components
import Page from "src/components/Page";
import Label from "src/components/Label";
import Scrollbar from "src/components/Scrollbar";
import SearchNotFound from "src/components/SearchNotFound";
import { get_root_value } from "src/utils/domUtils";
import {
  UserListHead,
  UserListToolbar,
  UserMoreMenu,
} from "src/components/_dashboard/user";
//
// import USERLIST from "src/_mocks_/user";
import DeletedModal from "src/components/modal/DeleteModal";
import { AllMemberListing, MemberListing } from "src/DAL/member/Member";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { baseUri, s3baseUrl } from "src/config/config";
import { htmlDecode } from "src/utils/convertHtml";
import CustomPopover from "src/components/CustomPopover";
import {
  goalIncompleteApi,
  goalMemberListingApi,
  goalSaveAndCloseAddApi,
} from "src/DAL/MemberGoalStatement/MemberGoalStatement";
import moment from "moment";
import ConfirmationModal from "src/components/modal/CutomeModalConfirmation";
import { useContentSetting } from "src/Hooks/ContentSettingState";
import {
  selfImageMemberListingApi,
  selfSaveAndCloseListApi,
} from "src/DAL/SelfImageMember/SelfImageMember";
import UserListToolbars from "src/components/_dashboard/user/UserListToolbars";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "number", label: "#", alignRight: false },
  { id: "name", label: "Name", alignRight: false },
  { id: "profile", label: "Profile", alignRight: false },
  { id: "email", label: "Email", alignRight: false },
  { id: "goalStatus", label: "Status", alignRight: false },
  // {
  //   id: "goal_statement_completed_date",
  //   label: "Completed Date",
  //   alignRight: false,
  // },

  { id: "action", label: "Action", alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) =>
        _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.email.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

//
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function SelfImageInCompleted({ type }) {
  const navigate = useNavigate();
  const pathName = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [open, setOpen] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [openIncompleteConfirmation, setOpenIncompleteConfirmation] =
    useState(false);
  const [UserId, setUserId] = useState(false);
  const [userList, setUserList] = useState([]);
  const { socket } = useContentSetting();
  const [totalPages, setTotalPages] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [totalMembers, setTotalMembers] = useState(0);

  const handleOpenConfirmation = (e) => {
    setUserId(e);
    setOpenConfirmation(true);
  };
  const handleOpenIncompleteConfirmation = (e) => {
    setUserId(e);
    setOpenIncompleteConfirmation(true);
  };

  const Capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const handleEdit = (value) => {
    navigate(`/self-image-incompleted/${value.member_id}`, {
      state: value,
    });
  };
  const navigateByName = (value) => {
    navigate(`/member-completed-goalstatements/${value.id}`, {
      state: value,
    });
  };

  const getMemberListing = async (search) => {
    let postData = {
      search_text: search ? search : filterName,
      created_for: "self_image",
      type: type,
      created_for_id: "",
    };
    try {
      setIsLoading(true);
      const result = await selfImageMemberListingApi(
        page,
        rowsPerPage,
        postData
      );
      if (result.code === 200) {
        const data = result.members.map((member) => {
          return {
            id: member._id,
            name: member.first_name + " " + member.last_name,
            email: member.email,
            avatarUrl: member.profile_image,
            goal: member.self_image_status,
            goalStatus: member.self_image_status,
            goal_statement_completed_date: member.self_image_completed_date,
            member_id: member.member_id,
            object: member,
          };
        });
        setTotalPages(result.total_pages);
        setTotalMembers(result.toal_count);
        setUserList(data);
        setIsLoading(false);
      } else {
        // enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
      }
    } catch (error) {}
  };
  const saveAndClose = async () => {
    setIsLoading(true);

    const result = await selfSaveAndCloseListApi(UserId?.id);
    if (result.code == 200) {
      setOpenConfirmation(false);
      // const socketData = {
      //   action: "goal_statement_save_and_close_status",
      //   creator_id: result.action_response.creator_id,
      //   action_response: result.action_response,
      // };

      // socket.emit("responded_goal_statement_event", socketData);
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
      getMemberListing();
    } else {
      setOpenConfirmation(false);
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const markIncomplete = async () => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("member", UserId?.id);
    // formData.append("save_and_close_status", true);

    const result = await goalIncompleteApi(formData);
    if (result.code == 200) {
      setOpenIncompleteConfirmation(false);
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
      getMemberListing();
    } else {
      setOpenIncompleteConfirmation(false);
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    // setOrder(isAsc ? "desc" : "asc");
    // setOrderBy(property);
  };
  const handleSearchName = () => {
    getMemberListing();
    setPage(0);
    setPageCount(1);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = userList.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };
  const handleNavigate = () => {
    navigate(`/addroom`);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - userList.length) : 0;

  const filteredUsers = applySortFilter(
    userList,
    getComparator(order, orderBy),
    filterName
  );
  const MENU_OPTIONS = [
    {
      label: "Detail",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    // {
    //   label: "Save & Close",
    //   icon: "akar-icons:edit",
    //   handleClick: handleOpenConfirmation,
    // },
    // {
    //   label: "Incomplete",
    //   icon: "akar-icons:edit",
    //   handleClick: handleOpenIncompleteConfirmation,
    // },
  ];
  React.useEffect(() => {
    getMemberListing();
  }, [page, rowsPerPage, pathName]);
  // console.log(filteredUsers, "filter is working or not");

  const isUserNotFound = filteredUsers.length === 0;
  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <>
      <DeletedModal title="member" id={UserId} open={open} setOpen={setOpen} />
      <ConfirmationModal
        title="member"
        message="Are you sure you want save and close?"
        id={UserId}
        open={openConfirmation}
        setOpen={setOpenConfirmation}
        saveAndClose={saveAndClose}
      />
      <ConfirmationModal
        title="member"
        message="Are you sure you want to mark this incomplete?"
        id={UserId}
        open={openIncompleteConfirmation}
        setOpen={setOpenIncompleteConfirmation}
        saveAndClose={markIncomplete}
      />

      <div className="container">
        <div className="row">
          <div className="col-12">
            <h2>Self Image</h2>
          </div>
        </div>
        {/* <IconButton onClick={() => navigate(-1)}>
          <ArrowBackIcon />
        </IconButton> */}
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        ></Stack>
        <Card style={{ overflowX: "auto" }}>
          <div className="row">
            <div className="col-sm-12 col-md-6 d-flex flex-column align-items-start mt-1">
              <TablePagination
                rowsPerPageOptions={[50, 100, 150]}
                component="div"
                count={totalMembers}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
              <Pagination
                count={totalPages + 1}
                page={pageCount}
                defaultPage={0}
                onChange={handleChangePage}
                className="pagination-style"
              />
            </div>
            <div className="col-sm-12 col-md-6 ">
              <UserListToolbars
                numSelected={selected.length}
                filterName={filterName}
                onFilterName={handleFilterByName}
                onSearch={handleSearchName}
              />
            </div>
          </div>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <UserListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={userList.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {filteredUsers
                  // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, i) => {
                    const {
                      id,
                      name,
                      email,
                      programs,
                      status,
                      avatarUrl,
                      order,
                      goal,
                      goalStatus,
                      goal_statement_completed_date,
                    } = row;
                    const isItemSelected = selected.indexOf(name) !== -1;

                    return (
                      <TableRow
                        hover
                        key={id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                        sx={{
                          " td, th, tr": {
                            borderBottom: `1px solid ${get_root_value(
                              "--text-secondary-color"
                            )}`,
                          },
                        }}
                      >
                        <TableCell align="left">{i + 1}</TableCell>
                        <TableCell component="th" scope="row">
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            <Typography
                              variant="subtitle2"
                              noWrap
                              onClick={() => handleEdit(row)}
                              className="anchor-style"
                            >
                              {Capitalize(htmlDecode(name))}
                            </Typography>
                          </Stack>
                        </TableCell>
                        {/* <TableCell align="left">
                            {isVerified ? "Yes" : "No"}
                          </TableCell> */}
                        <TableCell
                          align="left"
                          onClick={() => handleEdit(row)}
                          className="anchor-style"
                        >
                          {avatarUrl ? (
                            <Avatar alt={name} src={s3baseUrl + avatarUrl} />
                          ) : (
                            <Avatar alt={name} src={name} />
                          )}
                          {/* <Avatar alt={name} src={s3baseUrl + avatarUrl} /> */}
                        </TableCell>
                        <TableCell
                          align="left"
                          onClick={() => handleEdit(row)}
                          className="anchor-style"
                        >
                          {htmlDecode(email)}
                        </TableCell>

                        <TableCell align="left">
                          <Label
                            variant="ghost"
                            color={
                              (goalStatus === "incompleted" && "error") ||
                              "success"
                            }
                          >
                            {goalStatus === "completed"
                              ? "Completed"
                              : goalStatus === "incompleted"
                              ? "Incomplete"
                              : "Responded"}
                          </Label>
                        </TableCell>
                        {/* <TableCell align="left">
                          {goal_statement_completed_date == "Invalid date" ||
                          goal_statement_completed_date == ""
                            ? "N/A"
                            : goalStatus == false
                            ? ""
                            : moment(goal_statement_completed_date).format(
                                "DD/MM/YYYY"
                              )}
                        </TableCell> */}

                        <TableCell align="right">
                          <CustomPopover menu={MENU_OPTIONS} data={row} />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {/* {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )} */}
              </TableBody>
              {isUserNotFound === true && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={9} sx={{ py: 3 }}>
                      <SearchNotFound searchQuery={filterName} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[50, 100, 150]}
            component="div"
            count={userList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <Pagination
            count={totalPages + 1}
            page={pageCount}
            defaultPage={0}
            onChange={handleChangePage}
            className="pagination-style"
          />
        </Card>
      </div>
    </>
  );
}
