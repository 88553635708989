import React, { useState, useEffect } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import MomentUtils from "@date-io/moment";
import TextField from "@mui/material/TextField";
import moment from "moment";
import { InputAdornment } from "@mui/material";
import {
  AddnineteenDayNewEarning,
  EditNineteenDayEarning,
} from "../../DAL/90Day/NinetyDay";
import { date } from "faker";
import { useSnackbar } from "notistack";
import NumberFormat from "react-number-format";
import { get_root_value } from "src/utils/domUtils";
import { convertCurrencyToSign } from "src/utils/constants";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useContentSetting } from "src/Hooks/ContentSettingState";

const AddNewEarning = ({
  onCloseDrawer,
  setEarningList,
  earningList,
  setMember,
  setReload,
  reload,
  dataList,
  date,
  maxDate,
  apiCall,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [inputs, setInputs] = useState({
    earning: "",
    date: new Date(),
    description: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const { userInfo } = useContentSetting();
  const DateConvert = (str) => {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  };

  const handleChange = (event) => {
    const inputName = event.target.name;
    const value = event.target.value;
    setInputs((inputs) => ({
      ...inputs,
      [inputName]: value,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const startDate = new Date(date);
    const currentDate = new Date(inputs.date);
    const postData = {
      earning: inputs.earning,
      date: moment(inputs.date).format("YYYY-MM-DD"),
      description: inputs.description,
    };
    if (currentDate >= startDate) {
      const diffTime = Math.abs(currentDate - startDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      if (diffDays <= 90) {
        const result = await AddnineteenDayNewEarning(postData);

        if (result.code == 200) {
          dataList();
          apiCall();
          setReload(!reload);
          setIsLoading(false);
          enqueueSnackbar("Your record was saved successfully", {
            variant: "success",
          });
          onCloseDrawer();
        } else {
          enqueueSnackbar(result.message, { variant: "error" });
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
        enqueueSnackbar("Please select a date between the 90 days range.", {
          variant: "error",
        });
      }
    } else {
      setIsLoading(false);
      enqueueSnackbar("Please select a date between the 90 days range.", {
        variant: "error",
      });
    }
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-12 title-golden-large text-center mb-3 pt-4">
          {/* <p>THE DYNAMITE CHART</p> */}
        </div>
        <form onSubmit={handleSubmit}>
          <div className="col-12">
            {/* <TextField
              fullWidth
              className="text-area-task inputs-fields"
              id="outlined-required"
              label="Today's Earning"
              type="number"
              required
              placeholder="Add Financial Number Statement"
              name="earning"
              value={inputs.earning}
              onChange={handleChange}
            /> */}
            <NumberFormat
              value={inputs.earning}
              customInput={TextField}
              decimalScale={0}
              sx={{ input: { color: get_root_value("--input-text-color") } }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {/* {convertCurrencyToSign(
                      userInfo.nineteen_day_plan_currency
                    )} */}
                    £
                  </InputAdornment>
                ),
              }}
              type="text"
              name="earning"
              className="inputs-fields"
              label="Today's Earning"
              thousandSeparator={true}
              fullWidth
              required
              // onChange={(e) => setValue(parseFloat(e.target.value).toFixed(0))}
              onValueChange={({ value: v }) =>
                setInputs((inputs) => ({
                  ...inputs,
                  ["earning"]: v,
                }))
              }
            />
          </div>
          <div className="col-12 mt-4">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Date"
                format="DD-MM-YYYY"
                mask="__/__/____"
                inputFormat="DD-MM-YYYY"
                value={inputs.date}
                fullWidth
                minDate={new Date(date)}
                maxDate={new Date(maxDate)}
                onChange={(value) => {
                  setInputs((inputs) => ({
                    ...inputs,
                    ["date"]: DateConvert(value),
                  }));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    className="text-area-task inputs-fields"
                    name="date"
                  //value={inputs.date}
                  />
                )}
              />
            </LocalizationProvider>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-12 mt-4">
            <TextField
              className="text-area-task inputs-fields"
              fullWidth
              id="outlined-multiline-static"
              label="Add Description"
              multiline
              required
              rows={4}
              name="description"
              value={inputs.description}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 text-end">
            <button
              className="small-contained-button mt-4 mb-4"
              disabled={isLoading}
            >
              {isLoading ? "Saving..." : "Save"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddNewEarning;
