import { invokeApi } from "../../bl_libs/invokeApi";

export const AddPods = async (data) => {
  const requestObj = {
    path: `api/room/consultant`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const DelegateProgramListingApi = async (data) => {
  const requestObj = {
    path: `api/delegates_question/consutant`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const DelegateQuestionListingByProgramApi = async (slug) => {
  const requestObj = {
    path: `api/delegates_question/delegate_question_by_programme_for_consultant/${slug}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const addOrUpdateQuestionsAnswers = async (data) => {
  const requestObj = {
    path: `api/delegates_question/add_comment_on_delegate_question`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const DetailPodsApi = async (pods_slug) => {
  const requestObj = {
    path: `api/room/detail/${pods_slug}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
