import React, { useState, useEffect } from "react";
import CustomDrawer from "../GeneralComponents/CustomDrawer";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DeleteEarning, EarningListApi } from "../../DAL/90Day/NinetyDay";
import CustomPopover from "../GeneralComponents/CustomPopover";
import CustomConfirmation from "../GeneralComponents/CustomConfirmation";
import EditEarning from "src/pages/90DayPlan/EditEarning";
import { useSnackbar } from "notistack";
import { convertCurrencyToSign } from "src/utils/constants";
import { htmlDecode } from "src/utils/convertHtml";
import { useContentSetting } from "src/Hooks/ContentSettingState";

const Message90Day = ({
  reload,
  setMemberearningList,
  dataList,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [toggle, setToggle] = useState(true);
  const [openDelete, setOpenDelete] = useState(false);
  const [drawerState, setDrawerState] = useState(false);
  const [editEarning, seteditEarning] = useState();
  const [earningList, setEarningList] = useState([]);
  const [deleteEarningId, setDeleteEarningId] = useState("");
  const [startDate, setStartDate] = useState("");
  const { userInfo } = useContentSetting();
  const handleOpenDrawer = () => {
    setDrawerState(true);
  };
  const handleCloseDrawer = () => {
    setDrawerState(false);
  };

  // get earning list
  const getEarningList = async () => {
    dataList();
    const result = await EarningListApi();
    if (result.code == 200) {
      let questionsArray = [];
      result.DelegateEarning.map((value, index) => {
        if (index === 0) {
          questionsArray.push({
            ...value,
            isExpanded: true,
            isShowDots: true,
          });
        } else {
          questionsArray.push({
            ...value,
            isExpanded: false,
            isShowDots: false,
          });
        }
      });
      setEarningList(questionsArray);
      setStartDate(result.ninteen_day_vision_start_date);
      setMemberearningList(result.DelegateEarning);
      // setEarningList(result.memberEarning);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  //Deleting earning
  const handleDelete = async (e) => {
    const result = await DeleteEarning(deleteEarningId);
    if (result.code == 200) {
      setOpenDelete(false);
      dataList();
      enqueueSnackbar(result.message, { variant: "success" });
      getEarningList();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const expandArea = (index) => {
    let temp_state = [...earningList];
    let temp_element = { ...temp_state[index] };
    if (temp_element.isExpanded === false) {
      temp_element.isShowDots = true;
      temp_element.isExpanded = true;
    } else {
      temp_element.isShowDots = false;
      temp_element.isExpanded = false;
    }
    temp_state[index] = temp_element;
    setEarningList(temp_state);
  };

  const handleEdit = (value) => {
    seteditEarning(value);

    setDrawerState(true);
  };
  const handleAgreeDelete = (value) => {
    setDeleteEarningId(value._id);
    setOpenDelete(true);
  };
  const getReverseList = (earningListing) => {
    let reversed = earningListing;
    return reversed;
    // return earningListing.reverse();
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },

    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  useEffect(() => {
    getEarningList();
  }, [reload]);

  return (
    <>
      <div className="row goal-statement-messages">
        <div className="col-12">
          {getReverseList(earningList).length > 0
            ? getReverseList(earningList).map((message, index) => {
              return (
                <div className="card mb-2 accordion-detail-earning" key={message.id}>
                  <form>
                    <Accordion expanded={message.isExpanded}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        onClick={() => expandArea(index)}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className="svg-color"
                      >
                        <Typography>
                          {`Earning Date: ${message.date} : `}
                        </Typography>
                        <Typography>
                          &nbsp;
                          {` £${message.earning.toLocaleString()}`}
                        </Typography>
                      </AccordionSummary>
                      <div className="Ninty-Day-trackerList-dots">
                        {message.isShowDots && (
                          <CustomPopover menu={MENU_OPTIONS} data={message} />
                        )}
                      </div>
                      <div className="pe-5">
                        <p className="ms-3">
                          {htmlDecode(message.description)}
                        </p>
                      </div>
                      <AccordionDetails>
                        <div className="d-flex justify-content-between mt-3"></div>
                      </AccordionDetails>
                    </Accordion>
                  </form>
                </div>
              );
            })
            : ""}
        </div>
      </div>
      <CustomDrawer
        isOpenDrawer={drawerState}
        onOpenDrawer={handleOpenDrawer}
        onCloseDrawer={handleCloseDrawer}
        pageTitle="Edit Earning"
        componentToPassDown={
          <EditEarning
            dataList={getEarningList}
            onCloseDrawer={handleCloseDrawer}
            editObject={editEarning}
            targetDate={startDate}
          />
        }
      />
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete?"}
        handleAgree={handleDelete}
      />
    </>
  );
};

export default Message90Day;
