import React from "react";
import RecordingList from "../Recordings";
import RecordingCard from "../Recordings/RecordingCards";

const RecordingsList = () => {
  return (
    // <RecordingList />;
    <RecordingCard />
  );
};

export default RecordingsList;
