import {
  CircularProgress,
  FormControl,
  IconButton,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ChartJs from "./Chart";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  getNinetyDayQuestionApi,
  memberDetailApi,
} from "src/DAL/member/Member";
import { makeStyles } from "@mui/styles";
import moment from "moment";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const Todo = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const classes = useStyles();
  const [questions, setQuestions] = useState([]);
  const [totalEarning, setTotalEarning] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [days, setDays] = React.useState("");
  const [earningList, setEarningList] = React.useState([]);
  const [startDate, setStartDate] = React.useState("");
  const [chartDynamicData, setChartDynamicData] = useState([]);
  const [TargetToAchieve, setTargetToAchieve] = useState("");
  const [TargetAmount, setTargetAmount] = useState([
    // {
    //   value: 0,
    //   label: `${convertCurrencyToSign(
    //     user_profile.nineteen_day_plan_currency
    //   )}0`,
    // },
  ]);
  const [totalAmount, setTotalAmount] = useState("");

  const goalDetail = async () => {
    const result = await getNinetyDayQuestionApi();
    if (result.code == 200) {
      console.log(result, "result of goalstatement");
      setQuestions(result.Questions_array);
      setIsLoading(false);
    } else {
      console.log("else case");
      setIsLoading(false);
    }
  };
  const dateDifference = (startDate, lastDate) => {
    const date1 = new Date(startDate);
    const date2 = new Date(lastDate);
    const Difference_In_Time = date2.getTime() - date1.getTime();
    const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    const NinetyDay = Difference_In_Days;
    return NinetyDay;
  };

  const dataForGraph = async () => {
    const result1 = await memberDetailApi(params.id);
    if (result1.code == 200) {
      console.log(result1, "result1");
      setEarningList(result1.memberEarning);
      setTargetToAchieve(result1.target_amount);
      setTotalAmount(result1.total_earning);
      setStartDate(
        moment(result1.ninteen_day_vision_start_date).format("MM/DD/YYYY")
      );
      const start_date = moment(result1.ninteen_day_vision_start_date).format(
        "MM/DD/YYYY"
      );

      // chart dynamic value
      let counting = 0;
      const array_of_earning = [];
      const ChartPoints = [];
      const array_of_days = [];
      const reverseEarning = [];
      const demoXYValues = [];
      let LastDateObject = "";
      if (result1.memberEarning.length > 0) {
        // moment(result1.ninteen_day_vision_start_date).format("MM/DD/YYYY");
        LastDateObject = moment(result1.memberEarning[0].date).format(
          "MM/DD/YYYY"
        );
      }
      console.log(LastDateObject, "LastDateObject");
      console.log(startDate, "startDate");

      if (LastDateObject > startDate) {
        console.log("inside foreach");
        result1?.memberEarning.reverse().forEach((earning) => {
          reverseEarning.push(earning.earning); // needs to reverse
          const date = moment(earning.date).format("MM/DD/YYYY");
          counting = counting + earning.earning;
          const dateCheck = dateDifference(start_date, date);

          // array_of_earning.push(earning.earning);
          array_of_days.push(dateDifference(start_date, date));
          array_of_earning.push(counting);
          ChartPoints.push(dateDifference(start_date, date));
          demoXYValues.push({
            x: dateCheck < 0 ? 1 : dateCheck + 1,
            y: counting,
          });
        });
      } else {
      }
      setChartDynamicData(demoXYValues);
      // chart dynamic value end
    }
  };

  useEffect(() => {
    goalDetail();
    setTargetAmount(state?.target_amount);
    setTotalEarning(state?.total_earning);

    let days = [];
    for (let index = 1; index <= 90; index++) {
      days.push(index);
    }
    setDays(days);
  }, []);
  useEffect(() => {
    dataForGraph();
  }, []);
  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      <div className="col-12">
        <IconButton
          className="back-screen-button mb-4"
          onClick={() => navigate(-1)}
        >
          <ArrowBackIcon />
        </IconButton>
      </div>
      <div className="row">
        <div className="col-12 mb-5">
          <h2>To Do Tracker</h2>
        </div>
        <div className="col-12">
          <ChartJs
            days={days}
            // TargetToAchieve={state?.target_amount}
            // totalAmount={state?.total_earning}
            ChartDataList={earningList}
            chartDynamicData={chartDynamicData}
            TargetToAchieve={TargetToAchieve}
            totalAmount={totalAmount}
          />
        </div>
        <div className="col-12 mt-5">
          <h2 className="mb-4">90 Day Formula</h2>
        </div>
        {questions.map((question) => {
          return (
            <>
              <div className="col-12 ">
                <h4>
                  <b>{question.question_statement}</b>
                </h4>
              </div>
              <div className="col-12">
                <div
                  dangerouslySetInnerHTML={{
                    __html: question.description,
                  }}
                ></div>
              </div>
              <div className="col-12 mt-1 mb-3">
                <FormControl fullWidth>
                  <TextField
                    id="outlined-multiline-flexible"
                    label={question.answer == "" ? "Did Not Answer" : "Answer"}
                    multiline
                    rows={2}
                    name="Answer"
                    disabled
                    value={question.answer}
                  />
                </FormControl>
              </div>
            </>
          );
        })}
      </div>
    </div>
  );
};

export default Todo;
