import { invokeApi } from "../../bl_libs/invokeApi";

export const DeleteBookingNote = async (id) => {
  const requestObj = {
    path: `api/booking_note/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const addBookingNote = async (data) => {
  const requestObj = {
    path: `api/booking_note/`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const noteBookingListing = async (id) => {
  const requestObj = {
    path: `api/booking_note/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const EditBookingNote = async (data, id) => {
  const requestObj = {
    path: `api/booking_note/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
