import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  Select,
  TextField,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import React, { useRef, useState } from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { makeStyles } from "@mui/styles";

import {
  _add_wheel_of_life,
  _delete_wheel_of_life,
  _get_wheel_of_life,
  _update_wheel_of_life,
} from "src/DAL/QuestionSurvey/QuestionSurvey";
import { useSnackbar } from "notistack";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useEffect } from "react";
import RecordNotFound from "src/components/RecordNotFound";
import {
  addLessonQuestionApi,
  deleteLessonQuestion,
  editLessonQuestionApi,
  lessonQuestionListingApi,
} from "src/DAL/GeneralQuestions/GeneralQuestions";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCkeditor";
import CustomConfirmation from "src/components/GeneralComponents/CustomConfirmation";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export const GeneralQuestions = ({ created_for }) => {
  const params = useParams();
  const ref = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [openDelete, setOpenDelete] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [selectedQuestion, setSelectedQuestion] = useState({});
  const [formAction, setFormAction] = useState("ADD");
  const [isOpenForm, setIsOpenForm] = useState(false);
  const [questionsList, setQuestionsList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [inputList, setInputList] = useState([{ option: "", checked: false }]);
  const classes = useStyles();
  const created_for_id = new URLSearchParams(location.search).get(
    "created_for_id"
  );

  const [inputs, setInputs] = useState({
    question_type: "mcq",
    scaling_min: "",
    status: true,
    is_document_allowed: false,
    scaling_max: "",
    is_required: false,
    question_statement: "",
    question_placeholder: "",
    scaling_color: "#000",
    question_input_field_type: "text",
    image: "",
  });

  const handleClick = () => {
    setIsOpenForm(true);
    setTimeout(() => {
      ref.current?.scrollIntoView({ behavior: "smooth" });
    }, 100);
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
    if (inputList.length < 1) {
      setInputList([{ option: "", checked: false }]);
    }
  };
  // handle click event of the Add button
  const handleAddClick = (index) => {
    setInputList([
      ...inputList.slice(0, index),
      {
        option: "",
        checked: false,
      },
      ...inputList.slice(index),
    ]);
  };
  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  const expandArea = (index) => {
    let temp_state = [...questionsList];
    let temp_element = { ...temp_state[index] };
    temp_element.isExpanded = !temp_element.isExpanded;
    temp_state[index] = temp_element;
    setQuestionsList(temp_state);
  };
  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };

  const handleRemoveValues = () => {
    setInputs({
      question_type: "mcq",
      scaling_min: "",
      status: true,
      is_document_allowed: false,
      is_required: false,
      scaling_max: "",
      question_statement: "",
      question_placeholder: "",
      scaling_color: "#000",
      question_input_field_type: "text",
      image: "",
    });
    setInputList([{ option: "", checked: false }]);
    setFormAction("ADD");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!inputs.question_statement) {
      enqueueSnackbar("Question Statement is required", { variant: "error" });
      return;
    }
    setIsSubmitting(true);
    let error = "";
    const formData = new FormData();
    formData.append("status", inputs.status);
    formData.append("question_statement", inputs.question_statement);
    formData.append("question_placeholder", inputs.question_placeholder);
    formData.append("question_type", inputs.question_type);
    formData.append("is_required", inputs.is_required);
    if (created_for_id) {
      formData.append("created_for_id", created_for_id);
    }
    formData.append("is_document_allowed", inputs.is_document_allowed);
    formData.append("created_for", created_for);

    if (inputs.question_type == "scaling") {
      formData.append("scaling_max", inputs.scaling_max);
      formData.append("scaling_min", inputs.scaling_min);
      // formData.append("scaling_color", inputs.scaling_color);
      // formData.append("scaling_main_heading", inputs.scaling_main_heading);
      //
    } else {
      let options = [];
      inputList.map((item, index) => {
        if (
          (inputs.question_type == "mcq" ||
            inputs.question_type == "checkbox") &&
          item.option === ""
        ) {
          error = `Option ${index + 1} is required`;
          return;
        }
        options.push(item.option);
      });
      formData.append("options", JSON.stringify(options));
    }

    if (error) {
      enqueueSnackbar(error, { variant: "error" });
      setIsSubmitting(false);
    } else {
      const result =
        formAction === "ADD"
          ? await addLessonQuestionApi(formData)
          : await editLessonQuestionApi(formData, selectedQuestion._id);
      if (result.code === 200) {
        getQuestionSurveyList();
        handleRemoveValues();
        setIsSubmitting(false);
        enqueueSnackbar(result.message, { variant: "success" });
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsSubmitting(false);
      }
    }
  };

  const getQuestionSurveyList = async () => {
    const result = await lessonQuestionListingApi(created_for, created_for_id);

    if (result.code === 200) {
      let questionsArray = [];
      result.questionnaire.map((value, index) => {
        let isExpanded = false;
        if (index === 0) {
          isExpanded = true;
        }
        questionsArray.push({
          ...value,
          isExpanded: isExpanded,
        });
      });
      setQuestionsList(questionsArray);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleAgreeDelete = (value) => {
    setSelectedQuestion(value);
    setOpenDelete(true);
  };

  const handleEdit = (value) => {
    setSelectedQuestion(value);
    handleClick();
    setFormAction("EDIT");
    setInputs(value);
    setInputList(() => {
      return value.options.map((option) => {
        return {
          option: option,
          checked: false,
        };
      });
    });
  };

  const handleCancel = () => {
    handleRemoveValues();
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    const result = await deleteLessonQuestion(selectedQuestion._id);
    if (result.code === 200) {
      getQuestionSurveyList();
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleCloseForm = () => {
    setIsOpenForm(false);
    handleRemoveValues();
  };

  useEffect(() => {
    getQuestionSurveyList();
  }, [params.page_id]);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      {created_for !== "90-day-questions" && (
        <div className="row">
          <div className="col-12">
            <IconButton
              className="back-screen-button"
              onClick={() => navigate(-1)}
            >
              <ArrowBackIcon />
            </IconButton>
          </div>
        </div>
      )}
      <div className="row mb-4">
        <div className="col-12 col-md-6">
          <h1>Questions</h1>
        </div>
        <div className="col-12 col-md-6">
          <button
            className="small-contained-button float-end mt-1"
            onClick={handleClick}
          >
            Add Question
          </button>
        </div>
      </div>

      {questionsList.length < 1 ? (
        <RecordNotFound title="Questions" />
      ) : (
        questionsList.map((questions, index) => {
          return (
            <div className="row mb-2" key={index}>
              <Accordion
                expanded={questions.isExpanded}
                className="question-background pb-4"
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  onClick={() => expandArea(index)}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  className="svg-color"
                >
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12 col-md-9 col-sm-12 mt-4 me-3 new-questions-statement">
                        <h3>Question Statement</h3>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: questions.question_statement,
                          }}
                        ></div>
                      </div>
                      <div className="col-lg-6 col-sm-12 mt-4">
                        <TextField
                          id="outlined-basic"
                          label="Question Placeholder"
                          variant="outlined"
                          fullWidth
                          name="question_placeholder"
                          value={questions.question_placeholder}
                          disabled
                        />
                      </div>
                      <div className="col-lg-6 col-sm-12 mt-4">
                        <TextField
                          label="Question Type"
                          variant="outlined"
                          disabled
                          fullWidth
                          value={
                            questions.question_type === "mcq"
                              ? "Single Selection"
                              : questions.question_type === "checkbox"
                              ? "Multiple Selection"
                              : questions.question_type == "editor"
                              ? "Editor"
                              : questions.question_type == "textarea"
                              ? "Text Area"
                              : "Scaling"
                          }
                        />
                      </div>
                      <div className="col-lg-4 col-sm-12 mt-4">
                        <TextField
                          label="Status"
                          variant="outlined"
                          disabled
                          fullWidth
                          value={
                            questions.status === true ? "Active" : "Inactive"
                          }
                        />
                      </div>
                      <div className="col-lg-4 col-sm-12 mt-4">
                        <FormControl fullWidth disabled>
                          <InputLabel id="demo-simple-select-label">
                            Is Document Allowed *
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            name="is_document_allowed"
                            value={questions.is_document_allowed}
                            label="Is Document Allowed *"
                            onChange={handleChange}
                          >
                            <MenuItem value={true}>Yes</MenuItem>
                            <MenuItem value={false}>No</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                      <div className="col-lg-4 col-sm-12 mt-4">
                        <FormControl fullWidth disabled>
                          <InputLabel id="demo-simple-select-label">
                            Is Required *
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            name="is_required"
                            value={questions?.is_required}
                            label="Is Required *"
                            onChange={handleChange}
                          >
                            <MenuItem value={false}>No</MenuItem>
                            <MenuItem value={true}>Yes</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <>
                    {questions.question_type == "mcq" ||
                    questions.question_type === "checkbox" ? (
                      <>
                        {questions.options.map((option, i) => {
                          return (
                            <div
                              className="col-lg-12 col-md-12 col-sm-12 mb-4 d-flex"
                              key={i}
                            >
                              <div className="col-1">
                                <Radio
                                  checked={true}
                                  className="mt-2"
                                  name="checked"
                                />
                              </div>
                              <div className="col-11">
                                <TextField
                                  id="outlined-basic"
                                  label="Enter an answer choice"
                                  variant="outlined"
                                  fullWidth
                                  disabled
                                  value={option}
                                />
                              </div>
                            </div>
                          );
                        })}
                      </>
                    ) : questions.question_type == "scaling" ? (
                      <div className="container">
                        <h3 className="scale-limit-heading">Scale Limit</h3>
                        <div className="row">
                          {/* <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
                            <TextField
                              id="outlined-basic"
                              variant="outlined"
                              fullWidth
                              label="Scaling Main Heading"
                              disabled
                              value={questions.scaling_main_heading}
                            />
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
                            <TextField
                              id="outlined-basic"
                              label="Scaling Color"
                              variant="outlined"
                              fullWidth
                              type="color"
                              disabled
                              value={questions.scaling_color}
                            />
                          </div> */}
                          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                            <TextField
                              id="outlined-basic"
                              variant="outlined"
                              fullWidth
                              label="Min"
                              disabled
                              value={questions.scaling_min}
                            />
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                            <TextField
                              id="outlined-basic"
                              label="Max"
                              variant="outlined"
                              fullWidth
                              disabled
                              value={questions.scaling_max}
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <>
                        {/* <div className="row">
                          <div className="col-lg-12 col-md-12 col-sm-12">
                            <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label">
                                Field Type *
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                name="question_input_field_type"
                                value={questions.question_input_field_type}
                                label="Field Type *"
                                onChange={handleChange}
                              >
                                <MenuItem value="text">Text</MenuItem>
                                <MenuItem value="textarea">TextArea</MenuItem>
                              </Select>
                            </FormControl>
                          </div>
                        </div> */}
                      </>
                    )}

                    <div className="col-12 mt-4 text-end">
                      <button
                        className="small-contained-button"
                        onClick={() => {
                          handleEdit(questions);
                        }}
                      >
                        Edit
                      </button>
                      <button
                        className="small-contained-button ms-2"
                        onClick={() => {
                          handleAgreeDelete(questions);
                        }}
                      >
                        Delete
                      </button>
                    </div>
                  </>
                </AccordionDetails>
              </Accordion>
            </div>
          );
        })
      )}
      {isOpenForm && (
        <form ref={ref} onSubmit={handleSubmit}>
          <div className="row question-background mt-5 pb-4">
            <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
              <h4>Question Statement *</h4>
              <GeneralCkeditor
                setInputs={setInputs}
                inputs={inputs}
                name="question_statement"
                editorHeight={320}
              />
            </div>
            <div className="col-lg-6 col-sm-12 mt-4">
              <TextField
                id="outlined-basic"
                label="Question Placeholder"
                variant="outlined"
                fullWidth
                name="question_placeholder"
                value={inputs.question_placeholder}
                onChange={handleChange}
              />
            </div>
            <div className="col-lg-6 col-sm-12 mt-4">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Question Type *
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="question_type"
                  value={inputs.question_type}
                  label="Question Type *"
                  onChange={handleChange}
                >
                  <MenuItem value="mcq">Single Selection</MenuItem>
                  <MenuItem value="checkbox">Multiple Selection</MenuItem>
                  <MenuItem value="scaling">Scaling</MenuItem>
                  <MenuItem value="textarea">Text Area</MenuItem>
                  {/* <MenuItem value="editor">Editor</MenuItem> */}
                </Select>
              </FormControl>
            </div>
            <div className="col-lg-4 col-sm-12 mt-4">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">status *</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="status"
                  value={inputs.status}
                  label="status *"
                  onChange={handleChange}
                >
                  <MenuItem value={true}>Active</MenuItem>
                  <MenuItem value={false}>Inactive</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="col-lg-4 col-sm-12 mt-4">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Is Document Allowed *
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="is_document_allowed"
                  value={inputs.is_document_allowed}
                  label="Is Document Allowed *"
                  onChange={handleChange}
                >
                  <MenuItem value={true}>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="col-lg-4 col-sm-12 mt-4">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Is Required *
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="is_required"
                  value={inputs.is_required}
                  label="Is Required *"
                  onChange={handleChange}
                >
                  <MenuItem value={false}>No</MenuItem>
                  <MenuItem value={true}>Yes</MenuItem>
                </Select>
              </FormControl>
            </div>
            {inputs.question_type == "checkbox" && (
              <>
                {inputList.map((x, i) => {
                  return (
                    <div className="col-lg-12 col-md-12 col-sm-12 mt-4 d-flex">
                      <div className="col-1">
                        <Radio checked={true} className="mt-2" name="checked" />
                      </div>
                      <div className="col-9">
                        <TextField
                          id="outlined-basic"
                          label="Enter an answer choice"
                          variant="outlined"
                          fullWidth
                          required
                          name="option"
                          value={x.option}
                          onChange={(e) => handleInputChange(e, i)}
                        />
                      </div>
                      <div className="col-2">
                        <AddCircleOutlineIcon
                          className="question-survey"
                          onClick={() => handleAddClick(i + 1)}
                        />
                        {inputList.length !== 1 && (
                          <RemoveCircleOutlineIcon
                            className="question-survey"
                            onClick={() => handleRemoveClick(i)}
                          />
                        )}
                      </div>
                    </div>
                  );
                })}
              </>
            )}
            {inputs.question_type == "mcq" && (
              <>
                {inputList.map((x, i) => {
                  return (
                    <div className="col-lg-12 col-md-12 col-sm-12 mt-4 d-flex">
                      <div className="col-1">
                        <Radio checked={true} className="mt-2" name="checked" />
                      </div>
                      <div className="col-9">
                        <TextField
                          id="outlined-basic"
                          label="Enter an answer choice"
                          variant="outlined"
                          fullWidth
                          required
                          name="option"
                          value={x.option}
                          onChange={(e) => handleInputChange(e, i)}
                        />
                      </div>
                      <div className="col-2">
                        <AddCircleOutlineIcon
                          className="question-survey"
                          onClick={() => handleAddClick(i + 1)}
                        />
                        {inputList.length !== 1 && (
                          <RemoveCircleOutlineIcon
                            className="question-survey"
                            onClick={() => handleRemoveClick(i)}
                          />
                        )}
                      </div>
                    </div>
                  );
                })}
              </>
            )}
            {inputs.question_type == "scaling" && (
              <>
                <h3 className="mt-4">Scale Limit</h3>
                {/* <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
                  <TextField
                    id="outlined-basic"
                    label="Scaling Main Heading"
                    variant="outlined"
                    fullWidth
                    type="text"
                    required
                    name="scaling_main_heading"
                    value={inputs.scaling_main_heading}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
                  <TextField
                    id="outlined-basic"
                    label="Scaling Color"
                    variant="outlined"
                    fullWidth
                    type="color"
                    required
                    name="scaling_color"
                    value={inputs.scaling_color}
                    onChange={handleChange}
                  />
                </div> */}
                <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                  <TextField
                    id="outlined-basic"
                    label="Min"
                    variant="outlined"
                    fullWidth
                    type="number"
                    required
                    name="scaling_min"
                    value={inputs.scaling_min}
                    onChange={handleChange}
                    inputProps={{ min: 1 }}
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                  <TextField
                    id="outlined-basic"
                    label="Max"
                    variant="outlined"
                    fullWidth
                    type="number"
                    required
                    inputProps={{ min: 1 }}
                    name="scaling_max"
                    value={inputs.scaling_max}
                    onChange={handleChange}
                  />
                </div>
              </>
            )}
            <div className="col-12 mt-4 text-end">
              <button className="small-contained-button">
                {formAction === "ADD"
                  ? isSubmitting
                    ? "Submitting"
                    : "Submit"
                  : isSubmitting
                  ? "Updating"
                  : "Update"}
              </button>
              {formAction === "EDIT" && (
                <button
                  className="small-contained-button ms-2"
                  onClick={handleCancel}
                >
                  Cancel
                </button>
              )}
              <button
                className="small-contained-button ms-2"
                onClick={handleCloseForm}
              >
                Close
              </button>
            </div>
          </div>
        </form>
      )}
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleDelete}
      />
    </div>
  );
};
