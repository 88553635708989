import { get_kmb_number } from "src/utils/constants";

export default function DashboradCountCard({ title, count, icon, bgColor }) {
  return (
    <div className="card dashborad-count-card">
      <div
        className="count-card-icon"
        style={{
          backgroundImage: `linear-gradient(135deg, rgba(${bgColor}, 0) 0%, rgba(${bgColor}, 0.24) 100%)`,
        }}
      >
        {icon}
      </div>
      <h4>£{get_kmb_number(count)}</h4>
      <h6>{title}</h6>
    </div>
  );
}
