import PropTypes from "prop-types";
// material
import { Box } from "@mui/material";
import { appLogoIcon } from "../assets";
import { get_app_logo, project_name, s3baseUrl } from "../config/config";

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object,
};
export default function Logo({ sx, consultantInfo }) {
  // const logo = get_app_logo();
  const logo = consultantInfo;
  //console.log(consultantInfo, "logo");
  return (
    <Box component="img" src={logo ? s3baseUrl + logo : ""} sx={{ ...sx }} />
  );
}
