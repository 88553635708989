import {
  Avatar,
  Box,
  CircularProgress,
  Divider,
  Modal,
  Tooltip,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useEffect, useState } from "react";

import { getBroadcastDetail } from "src/DAL/Broadcast/Broadcast";
import { useSnackbar } from "notistack";
import { s3baseUrl } from "src/config/config";
import { makeStyles } from "@mui/styles";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 480,
  minHeight: 380,
  maxHeight: 480,
  maxWidth: "100%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  pt: 0,
  borderRadius: 1,
  overflow: "auto",
};
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "0",
    marginTop: "20%",
  },
  fonts: {
    fontSize: "13px",
    fontWeight: "500",
  },
}));
const BoardcastDetail = ({
  showDetail,
  closeDetail,
  setDrawerState,
  broadcastMain,
}) => {
  const classes = useStyles();
  const [totlePages, setTotlePages] = useState(0);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [loadMorePath, setLoadMorePath] = useState("");
  let [pageNumber, setPageNumber] = useState(0);
  const [Activities, setActivitiesArray] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [broadcastDetail, setBroadcastDetail] = useState();

  const [loadMore, setLoadMore] = useState(
    `api/broadcast/broadcast_details/${broadcastMain._id}?page=0&limit=20`
  );
  const [cout, setCount] = useState(0);
  const { enqueueSnackbar } = useSnackbar();

  const getBraodcastDetail = async () => {
    const result = await getBroadcastDetail(loadMore);
    if (result.code == 200) {
      console.log(result, "resultresultresultresult");
      let newArray = [];
      newArray = Activities?.concat(result.broadcast_members);
      setActivitiesArray(newArray);
      setBroadcastDetail(result.broadcast);

      setPageNumber((pageNumber) => pageNumber + 1);
      setTotlePages(result.total_pages);
      setLoadMorePath(result.load_more);
      setIsLoadingMore(false);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const loadMoreData = () => {
    setCount((count) => count + 1);
    setIsLoadingMore(true);
    setLoadMore(loadMorePath);
  };
  useEffect(() => {
    getBraodcastDetail();
  }, [loadMore, cout]);

  return (
    <div>
      <Modal
        open={showDetail}
        className="hide-index"
        onClose={closeDetail}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="create-post-title-form">
          <Box sx={style} className="modal-theme">
            <div className="text-center modalIcon clo-12 show-all-likes">
              <Box sx={{ width: "100%" }}>
                <Box
                  sx={{
                    borderBottom: 1,
                    borderColor: "divider",
                    position: "relative",
                  }}
                >
                  <span
                    className="cross-show-all-likes-models"
                    onClick={closeDetail}
                  >
                    x
                  </span>
                </Box>
                <div
                  className="d-flex"
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    width: "75%",
                    margin: "auto",
                  }}
                >
                  <h3
                    style={{ marginTop: "20px", marginBottom: "20px " }}
                    className="broadcastDetail"
                  >
                    {broadcastMain.broadcast_title}
                  </h3>
                  <Tooltip title="Edit">
                    <EditIcon
                      className="eidit-icon"
                      onClick={() => setDrawerState(true)}
                    />
                  </Tooltip>
                </div>
                {isLoading ? (
                  <CircularProgress
                    className={classes.loading}
                    color="primary"
                  />
                ) : (
                  <>
                    <Divider />
                    {Activities.length > 0 &&
                      Activities.map((item, index) => {
                        return (
                          <div className="col-12 mt-3 d-flex">
                            <div className="post-creator me-auto ">
                              <div className="like-profile-image">
                                <Avatar
                                  src={s3baseUrl + item.profile_image}
                                  alt={item.first_name}
                                ></Avatar>
                              </div>
                              <div className="creator-name ps-2 pt-1">
                                <h3>
                                  {item.first_name + " " + item.last_name}
                                </h3>
                                <span className="date-color">{item.email}</span>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    {totlePages > pageNumber ? (
                      <div className="col-12 text-center">
                        <button
                          className="small-contained-button mt-3"
                          onClick={loadMoreData}
                          disabled={isLoadingMore}
                        >
                          {isLoadingMore ? "Loading..." : "Load More"}
                        </button>
                      </div>
                    ) : (
                      " "
                    )}
                  </>
                )}
              </Box>
            </div>
          </Box>
        </div>
      </Modal>
    </div>
  );
};

export default BoardcastDetail;
