import React, { useState } from "react";
import { s3baseUrl } from "src/config/config";
import LoopIcon from "@mui/icons-material/Loop";
import RepeatOneSharpIcon from "@mui/icons-material/RepeatOneSharp";
import { Tooltip } from "@mui/material";

export default function ContentAudioPlayer({ item }) {
  const [isLooping, setIsLooping] = useState(false);

  return (
    <div className="audio-container">
      <span
        className="hide-audio cursor-pointer"
        onClick={() => setIsLooping(!isLooping)}
      >
        <Tooltip title={isLooping ? "Play In Loop" : "Play Once"}>
          {isLooping ? <LoopIcon /> : <RepeatOneSharpIcon />}
        </Tooltip>
      </span>
      <div>
        {isLooping ? (
          <audio
            className="w-100"
            src={s3baseUrl + item.content}
            controls
            loop
          />
        ) : (
          <audio className="w-100" src={s3baseUrl + item.content} controls />
        )}
      </div>
    </div>
  );
}
