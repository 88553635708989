import { Icon } from "@iconify/react";
import { useEffect } from "react";
import { MenuItem } from "@mui/material";

export default function MenuPopover(props) {
  const { menu, menuPosition, MENUS_WIDTH, setOpenUserMenu } = props;

  const handleClickOutside = (event) => {
    if (
      !event.target.closest(".lead-stages-popup") &&
      !event.target.closest(".lead-centre-user-menus")
    ) {
      setOpenUserMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      className="lead-centre-user-menus"
      style={{
        top: menuPosition.top + 3,
        left: menuPosition.left,
        width: MENUS_WIDTH,
      }}
    >
      {menu.map((option) => (
        <MenuItem
          key={option.label}
          onClick={() => {
            option.handleClick();
          }}
        >
          {option.icon && (
            <Icon
              fontSize="18"
              style={{ color: "var(--portal-theme-primary)" }}
              className="me-2"
              icon={option.icon}
            />
          )}
          <span>{option.label}</span>
        </MenuItem>
      ))}
    </div>
  );
}
