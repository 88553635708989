import {
  CircularProgress,
  Chip,
  Tooltip,
  Card,
  CardHeader,
  Box,
} from "@mui/material";
import { useContentSetting } from "src/Hooks/ContentSettingState";
import { AppNewsUpdate, AppWebsiteVisits } from "../components/_dashboard/app";
import { useEffect, useState } from "react";
import { dashboardApi } from "src/DAL/Login/Login";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import Chart from "react-apexcharts";
import CircleIcon from "@mui/icons-material/Circle";
import { DashboardFilter } from "src/components/DashboardFilter";
import CustomDrawer from "src/components/DrawerForm/CustomDrawer";
import FilterListIcon from "@mui/icons-material/FilterList";
import DashboradCountCard from "src/components/_dashboard/app/DashboradCountCard";
import CurrencyPoundIcon from "@mui/icons-material/CurrencyPound";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import moment from "moment";
import CompleteUncompleteChart from "src/components/AccountabilityTracker/CompleteUncompleteChart";
import LeaderboardCardPerformance from "src/components/AccountabilityTracker/LeaderboardCardPerformance";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

function getLastWeekData(data) {
  // Get the date one week ago
  let oneWeekAgo = new Date();
  oneWeekAgo.setDate(oneWeekAgo.getDate() - 6);
  // Initialize arrays for dates and scores
  let dates = [];
  let scores = [];
  // Array of month names
  let monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  for (let i = 0; i < 7; i++) {
    let currentDate = new Date(oneWeekAgo);
    currentDate.setDate(oneWeekAgo.getDate() + i);

    let day = String(currentDate.getDate()).padStart(2, "0");
    let month = String(currentDate.getMonth() + 1).padStart(2, "0");
    let year = currentDate.getFullYear();

    let dateStringForComparison = `${day}-${month}-${year}`;

    let found = data.find((item) => item.date === dateStringForComparison);

    if (found) {
      let dayForDisplay = currentDate.getDate();
      let monthForDisplay = monthNames[currentDate.getMonth()];
      let dateStringForDisplay = `${monthForDisplay} ${dayForDisplay}`;
      dates.push(dateStringForDisplay);
      scores.push(found.rate);
    } else {
      let dayForDisplay = currentDate.getDate();
      let monthForDisplay = monthNames[currentDate.getMonth()];

      let dateStringForDisplay = `${monthForDisplay}' ${dayForDisplay}`;

      dates.push(dateStringForDisplay);
      scores.push(0);
    }
  }
  let dataObject = { dates, scores };
  return dataObject;
}
const TABLE_HEAD = [
  {
    id: "user_name",
    label: "Name",
    alignRight: false,
  },
  {
    id: "page_title",
    label: "Booking Page",
  },
  { id: "booking_date", label: "Date", alignRight: false },
  {
    id: "status",
    label: "Booking Status",
    renderData: (row) => {
      let find_status = row.booking_status_info;
      if (find_status) {
        return (
          <Tooltip title={find_status?.title}>
            <Chip
              label={find_status?.title}
              variant="contained"
              className="booking-status-chip"
              style={{
                backgroundColor: find_status.background_color,
                color: find_status.text_color,
                height: "22px",
              }}
              size="small"
            />
          </Tooltip>
        );
      }
    },
  },
];

const get_user_name = (user_info) => {
  return (
    user_info?.first_name +
    " " +
    user_info?.last_name +
    " (" +
    user_info?.email +
    ")"
  );
};

const get_booking_date = (booking) => {
  const dateTime = moment().format("YYYY-MM-DD") + " " + booking.time;
  let booking_date = `${moment(booking.date).format("DD-MM-YYYY")} (${
    booking.time +
    " - " +
    moment(dateTime, "DD-MM-YYYY hh:mm A")
      .add(booking.slot_duration, "minutes")
      .format("hh:mm A")
  })`;
  return booking_date;
};

const get_bookings_list = (booking_list) => {
  let bookings = [];
  booking_list.map((booking) => {
    let page_title = booking?.page?.sale_page_title;
    let booking_object = {
      ...booking,
      booking_date: get_booking_date(booking),
      page_title: page_title ? page_title : "N/A",
      user_name: get_user_name(booking.user_info),
    };
    bookings.push(booking_object);
  });
  return bookings;
};

export default function DashboardApp() {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { consultantInfo } = useContentSetting();
  const [isLoader, setIsLoader] = useState(false);
  const [attitudeRecord, setAttitudeRecord] = useState();
  const [focusRecords, setFocusRecord] = useState();
  const [desireRecords, setDesire] = useState();
  const [disciplineRecords, setDisciplineRecords] = useState();
  const [winRecords, setwinRecords] = useState();

  const [dashboardData, setDashboardData] = useState({
    latest_bookings: [],
    upcomming_bookings: [],
  });

  const [drawerState, setDrawerState] = useState(false);
  const [inputs, setInputs] = useState({
    start_date: null,
    end_date: null,
  });

  const handleOpenDrawer = () => {
    setDrawerState(true);
  };

  const handleCloseDrawer = () => {
    setDrawerState(false);
  };

  const getDashboardDetail = async (filterData) => {
    setIsLoader(true);
    const result = await dashboardApi(filterData ? filterData : "");
    if (result.code == 200) {
      result.latest_bookings = get_bookings_list(result.latest_booking_list);
      result.upcomming_bookings = get_bookings_list(
        result.upcomming_booking_list
      );
      let attitudeRecords = getLastWeekData(
        result.attitude_performance_rate_array
      );
      let focusRecords = getLastWeekData(result.focus_performance_rate_array);
      let DesireRecords = getLastWeekData(result.desire_performance_rate_array);
      let DiscplineRecords = getLastWeekData(
        result.discipline_performance_rate_array
      );
      let winRecords = getLastWeekData(result.win_note_performance_rate_array);
      setAttitudeRecord(attitudeRecords);
      setFocusRecord(focusRecords);
      setDesire(DesireRecords);
      setDisciplineRecords(DiscplineRecords);
      setwinRecords(winRecords);
      setDashboardData(result);
      setIsLoader(false);

      handleCloseDrawer();
    } else {
      setIsLoader(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  console.log(attitudeRecord, "datesdatesdatesdatesdates");

  useEffect(() => {
    getDashboardDetail();
  }, []);
  // only for attituede chats

  let lineChart = {
    options: {
      chart: {
        type: "line",
      },
      grid: {
        show: true,
        borderColor: "gray",
        strokeDashArray: 4,
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: "#c6c2c2",
          },
        },
        axisBorder: {
          show: true,
          color: "gray",
          dashArray: 4,
        },
        max: 10,
      },
      xaxis: {
        labels: {
          style: {
            colors: "#c6c2c2",
          },
          axisTicks: {
            show: false, // This hides the default x-axis ticks
          },
        },

        categories: attitudeRecord?.dates,
      },

      stroke: {
        curve: "smooth",
      },
      fill: {
        colors: ["#f6bd4b"],
      },
      dataLabels: {
        enabled: false,
      },
    },
    series: [
      {
        name: "Attitude",
        data: attitudeRecord?.scores,
        color: "#f6bd4b",
      },
    ],
  };

  // attitude is end

  // only for focus chats

  let lineChartFocus = {
    options: {
      chart: {
        type: "line",
      },
      grid: {
        show: true,
        borderColor: "gray",
        strokeDashArray: 4,
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: "#c6c2c2",
          },
        },
        axisBorder: {
          show: true,
          color: "gray",
          dashArray: 4,
        },
        max: 10,
      },
      xaxis: {
        labels: {
          style: {
            colors: "#c6c2c2",
          },
          axisTicks: {
            show: false, // This hides the default x-axis ticks
          },
        },

        categories: focusRecords?.dates,
      },

      stroke: {
        curve: "smooth",
      },
      fill: {
        colors: ["#f6bd4b"],
      },
      dataLabels: {
        enabled: false,
      },
    },
    series: [
      {
        name: "Focus",
        data: focusRecords?.scores,
        color: "#f6bd4b",
      },
    ],
  };

  // focus is end
  // only for Desire chats
  let lineChartDesire = {
    options: {
      chart: {
        type: "line",
        events: {
          click: function (event, chartContext, config) {
            // Open your popup here
            console.log(config, "eventeventeventeventeventeventevent");
          },
        },
      },
      grid: {
        show: true,
        borderColor: "gray",
        strokeDashArray: 4,
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: "#c6c2c2",
          },
        },
        axisBorder: {
          show: true,
          color: "gray",
          dashArray: 4,
        },
        max: 10,
      },
      xaxis: {
        labels: {
          style: {
            colors: "#c6c2c2",
          },
          axisTicks: {
            show: false, // This hides the default x-axis ticks
          },
        },

        categories: desireRecords?.dates,
      },

      stroke: {
        curve: "smooth",
      },
      fill: {
        colors: ["#f6bd4b"],
      },
      dataLabels: {
        enabled: false,
      },
    },
    series: [
      {
        name: "Desire",
        data: desireRecords?.scores,
        color: "#f6bd4b",
      },
    ],
  };
  // desire is end
  // only for Discipline chats
  let lineChartDiscipline = {
    options: {
      chart: {
        type: "line",
      },
      grid: {
        show: true,
        borderColor: "gray",
        strokeDashArray: 4,
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: "#c6c2c2",
          },
        },
        axisBorder: {
          show: true,
          color: "gray",
          dashArray: 4,
        },
        max: 10,
      },
      xaxis: {
        labels: {
          style: {
            colors: "#c6c2c2",
          },
          axisTicks: {
            show: false, // This hides the default x-axis ticks
          },
        },

        categories: disciplineRecords?.dates,
      },

      stroke: {
        curve: "smooth",
      },
      fill: {
        colors: ["#f6bd4b"],
      },
      dataLabels: {
        enabled: false,
      },
    },
    series: [
      {
        name: "Discipline",
        data: disciplineRecords?.scores,
        color: "#f6bd4b",
      },
    ],
  };
  // Discipline is end // only for Discipline chats
  let lineChartWin = {
    options: {
      chart: {
        type: "line",
      },
      grid: {
        show: true,
        borderColor: "gray",
        strokeDashArray: 4,
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: "#c6c2c2",
          },
        },
        axisBorder: {
          show: true,
          color: "gray",
          dashArray: 4,
        },
        max: 10,
      },
      xaxis: {
        labels: {
          style: {
            colors: "#c6c2c2",
          },
          axisTicks: {
            show: false, // This hides the default x-axis ticks
          },
        },

        categories: winRecords?.dates,
      },

      stroke: {
        curve: "smooth",
      },
      fill: {
        colors: ["#f6bd4b"],
      },
      dataLabels: {
        enabled: false,
      },
    },
    series: [
      {
        name: "Win",
        data: winRecords?.scores,
        color: "#f6bd4b",
      },
    ],
  };
  // Win is end
  if (isLoader === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container main-div-img">
      <div className="dashboard-background">
        <div className="row">
          <div className="col-12 mb-4 mt-4">
            <div
              className="ms-3"
              dangerouslySetInnerHTML={{
                __html: consultantInfo?.dashboard_content,
              }}
            ></div>
          </div>
          <div className="col-12 mb-4 text-end">
            <button
              className="small-contained-button"
              onClick={handleOpenDrawer}
            >
              <FilterListIcon className="me-2" />
              Filter
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6 col-lg-3 mb-4">
            <DashboradCountCard
              title="Today's Commission"
              count={dashboardData?.today_commission}
              icon={<CurrencyPoundIcon />}
              bgColor="34, 154, 22"
            />
          </div>
          <div className="col-12 col-md-6 col-lg-3 mb-4">
            <DashboradCountCard
              title="Pending Commission"
              count={
                dashboardData?.total_commission - dashboardData?.paid_commission
              }
              icon={<CurrencyPoundIcon />}
              bgColor="12, 83, 183"
            />
          </div>
          <div className="col-12 col-md-6 col-lg-3 mb-4">
            <DashboradCountCard
              title="Total Paid Commission"
              count={dashboardData?.paid_commission}
              icon={<CurrencyPoundIcon />}
              bgColor="183, 129, 3"
            />
          </div>
          <div className="col-12 col-md-6 col-lg-3 mb-4">
            <DashboradCountCard
              title="Total Commission Attracted"
              count={dashboardData?.total_commission}
              icon={<CurrencyPoundIcon />}
              bgColor="183, 33, 54"
            />
          </div>
          {/* <div className="col-12 col-md-6 col-lg-3 mb-4">
            <DashboradCountCard
              title="Today's Bookings"
              count={dashboardData?.today_bookings}
              icon={<CurrencyPoundIcon />}
            />
          </div>
          <div className="col-12 col-md-6 col-lg-3 mb-4">
            <DashboradCountCard
              title="Completed Bookings"
              count={dashboardData?.completed_bookings}
              icon={<CurrencyPoundIcon />}
            />
          </div>
          <div className="col-12 col-md-6 col-lg-3 mb-4">
            <DashboradCountCard
              title="Cancelled Bookings"
              count={dashboardData?.cancelled_bookings}
              icon={<CurrencyPoundIcon />}
            />
          </div>
          <div className="col-12 col-md-6 col-lg-3 mb-4">
            <DashboradCountCard
              title="Pending Bookings"
              count={dashboardData?.pending_bookings}
              icon={<CurrencyPoundIcon />}
            />
          </div>
          <div className="col-12 col-md-6 col-lg-3 mb-4">
            <DashboradCountCard
              title="No Show Bookings"
              count={dashboardData?.no_show_bookings}
              icon={<CurrencyPoundIcon />}
            />
          </div>
          <div className="col-12 col-md-6 col-lg-3 mb-4">
            <DashboradCountCard
              title="Rescheduled Bookings"
              count={dashboardData?.rescheduled_bookings}
              icon={<CurrencyPoundIcon />}
            />
          </div>
          <div className="col-12 col-md-6 col-lg-3 mb-4">
            <DashboradCountCard
              title="No Action Bookings"
              count={dashboardData?.no_action_bookings}
              icon={<CurrencyPoundIcon />}
            />
          </div> */}
        </div>
        <div className="row">
          <div className="col-6 mb-4">
            <h2 className="mb-3">Latest Bookings</h2>
            <CustomMUITable
              TABLE_HEAD={TABLE_HEAD}
              data={dashboardData.latest_bookings}
              className="card-with-background mt-1 h-100"
              hide_search={true}
            />
          </div>
          <div className="col-6 mb-4">
            <h2 className="mb-3">Upcoming Bookings</h2>
            <CustomMUITable
              TABLE_HEAD={TABLE_HEAD}
              data={dashboardData.upcomming_bookings}
              className="card-with-background mt-1 h-100"
              hide_search={true}
            />
          </div>
        </div>

        <div className="row mt-5">
          {/* <div className="col-12 col-md-6 col-lg-4">
            <h2 class="mt-2 ms-1 secondary-heading text-center">Intentions</h2>
            <div className="d-flex justify-content-center">
              <CompleteUncompleteChart data={dashboardData} />
            </div>
            <div className="d-flex justify-content-around">
              <p>
                <CircleIcon className="position-completed" /> Completed
              </p>
              <p>
                <CircleIcon className="position-notcompleted" />
                Not Completed
              </p>
            </div>
          </div> */}
          {/* <div className="col-12 col-md-6 pe-0 col-lg-4">
            <LeaderboardCardPerformance
              title="Performance Streak"
              data={dashboardData?.streak_performance}
            />
          </div> */}
          <div className="col-6 col-md-8 mb-4 ">
            <AppWebsiteVisits height={415} />
          </div>
          <div className="col-12 col-md-4 mb-4 ps-0">
            <AppNewsUpdate transactions={dashboardData?.transaction} />
          </div>
          {/* <div className="col-12 col-md-6 col-lg-6 mt-4 pe-0">
            <Card>
              <CardHeader
                title="Your Attitude "
                subheader={`Achieved an average rating of ( ${dashboardData.attitude_performance_rate_avg} ) this week.`}
              />
              <Box sx={{ p: 1, marginLeft: "20px" }} dir="ltr">
                <Chart
                  options={lineChart.options}
                  series={lineChart.series}
                  type="area"
                  height={340}
                />
              </Box>
            </Card>
          </div>
          <div className="col-12 col-md-6 col-lg-6 mt-4">
            <Card>
              <CardHeader
                title="Your Foucs "
                subheader={`Achieved an average rating of ( ${dashboardData.focus_performance_rate_avg} ) this week.`}
              />
              <Box sx={{ p: 1, marginLeft: "20px" }} dir="ltr">
                <Chart
                  options={lineChartFocus.options}
                  series={lineChartFocus.series}
                  type="area"
                  height={340}
                />
              </Box>
            </Card>
          </div>
          <div className="col-12 col-md-6 col-lg-6 mt-3 pe-0">
            <Card>
              <CardHeader
                title="Your Desire "
                subheader={`Achieved an average rating of ( ${dashboardData.desire_performance_rate_avg} ) this week.`}
              />
              <Box sx={{ p: 1, marginLeft: "20px" }} dir="ltr">
                <Chart
                  options={lineChartDesire.options}
                  series={lineChartDesire.series}
                  type="area"
                  height={340}
                />
              </Box>
            </Card>
          </div>
          <div className="col-12 col-md-6 col-lg-6 mt-3">
            <Card>
              <CardHeader
                title="Your Discipline"
                subheader={`Achieved an average rating of ( ${dashboardData.discipline_performance_rate_avg} ) this week.`}
              />
              <Box sx={{ p: 1, marginLeft: "20px" }} dir="ltr">
                <Chart
                  options={lineChartDiscipline.options}
                  series={lineChartDiscipline.series}
                  type="area"
                  height={340}
                />
              </Box>
            </Card>
          </div>
          <div className="col-12 col-md-6 col-lg-6 mt-3 pe-0">
            <Card>
              <CardHeader
                title="Rate This Win"
                subheader={`Achieved an average rating of ( ${dashboardData.win_note_performance_rate_avg} ) this week.`}
              />
              <Box sx={{ p: 1, marginLeft: "20px" }} dir="ltr">
                <Chart
                  options={lineChartWin.options}
                  series={lineChartWin.series}
                  type="area"
                  height={340}
                />   
              </Box>
            </Card>
          </div> */}
        </div>
        <CustomDrawer
          isOpenDrawer={drawerState}
          onOpenDrawer={handleOpenDrawer}
          onCloseDrawer={handleCloseDrawer}
          pageTitle="Filter"
          componentToPassDown={
            <DashboardFilter
              getDashboardDetail={getDashboardDetail}
              inputs={inputs}
              setInputs={setInputs}
              onCloseDrawer={handleCloseDrawer}
            />
          }
        />
      </div>
    </div>
  );
}
