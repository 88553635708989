import React from "react";

export default function WhatsAppTemplate(props) {
  const { components } = props;

  const handleNatigate = (url) => {
    window.open(url);
  };

  return (
    <div className="template">
      {components?.length > 0 &&
        components.map((component) => {
          console.log(component, "componentcomponent");
          if (component.type == "HEADER") {
            if (component.format == "IMAGE") {
              let image_path = component.example.header_handle[0];
              return <img className="mb-2" src={image_path}></img>;
            } else if (component.format == "TEXT") {
              return <h6 className="mb-1">{component.text}</h6>;
            }
          }
          if (component.type == "BODY") {
            return (
              <div className="mb-1">
                <span>{component.text}</span>
              </div>
            );
          }
          if (component.type == "FOOTER") {
            return <div className="whatsapp-footer-text">{component.text}</div>;
          }
          if (component.type == "BUTTONS") {
            return component.buttons.map((button) => {
              let count = component.buttons.length;
              let url = button.url;
              return (
                <div className="d-flex justify-content-between">
                  <button
                    className="whatsapp-preview-button my-2"
                    style={{
                      width: `${(count == 1 ? 100 : 96) / count}%`,
                    }}
                    onClick={() => {
                      handleNatigate(url);
                    }}
                  >
                    {button.text}
                  </button>
                </div>
              );
            });
          }
        })}
    </div>
  );
}
