import { Avatar, CircularProgress, IconButton, TextField } from "@mui/material";
import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useEffect } from "react";
import Alert from "@mui/material/Alert";

import { s3baseUrl } from "src/config/config";
import {
  changeDelegateCommissionApi,
  manage_commission_access_detail,
} from "src/DAL/WebsitePages/WebsitePages";
import ActiveLastBreadcrumb from "src/components/BreadCrums";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function ManageSalesTeamCommission() {
  const params = useParams();
  const classes = useStyles();
  const navigate = useNavigate();
  const path = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const [programsList, setProgramsList] = useState([]);
  const [selected, setSelected] = useState([]);
  const [planDetail, setPlanDetail] = useState({});
  const [pageDetail, setPageDetail] = useState({});
  const [rowPerPage, setrowPerPage] = useState("");
  console.log(params, "paramsparamsparamsparamsparamsparams");

  const getTemplateConfig = async () => {
    let data = {
      page_id: params.page_id,
      plan_id: params.id,
    };
    const result = await manage_commission_access_detail(data);
    if (result.code === 200) {
      let result_array = [];
      result.team_list.map((item) => {
        result_array.push({
          ...item,
          name: item.first_name + " " + item.last_name + "(" + item.email + ")",
          image: item?.image?.thumbnail_1,
          commission_amount: 0,
        });
      });

      console.log(result_array, "result_arrayresult_array");
      setProgramsList(result_array);
      setrowPerPage(result_array.length);
      let selected_array = [];
      result?.team_list?.map((delegateObj) => {
        let filter = delegateObj.sales_commision_for_plan.find(
          (selected) => selected.plan_id == params.id
        );
        if (filter) {
          selected_array.push({
            _id: delegateObj._id,
            commission_amount: filter.commission_amount,
          });
        }
      });
      setSelected(selected_array);
      setPageDetail(result.sale_page);
      setPlanDetail(result.commission_info);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let selectedConsultants = [];
    selected.map((consultant) => {
      selectedConsultants.push({
        consultant_id: consultant._id,
        commission_amount: consultant.commission_amount
          ? consultant.commission_amount
          : 0,
      });
    });
    const postData = {
      plan_id: params.id,
      consultant_comission: selectedConsultants,
    };
    const result = await changeDelegateCommissionApi(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      navigate(-1);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handleChange = (event, name) => {
    const value = event.target.value;
    const program_id = event.target.name;

    setProgramsList((data) =>
      data.map((obj) => {
        if (obj._id === program_id) {
          return { ...obj, [name]: value };
        }
        return obj;
      })
    );

    setSelected((data) =>
      data.map((obj) => {
        if (obj._id === program_id) {
          return { ...obj, [name]: value };
        }
        return obj;
      })
    );
  };
  function isAnyCommissionGreaterThanTen(arr) {
    return arr.some(
      (obj) => obj.commission_amount > planDetail.commission_amount
    );
  }
  const TABLE_HEAD = [
    {
      id: "image",
      label: "Profile",
      renderData: (row) => {
        return (
          <>
            <Avatar alt={row.name} src={s3baseUrl + row.image} />
          </>
        );
      },
    },
    { id: "name", label: "User", alignRight: false },

    {
      id: "no_of_limited_days",
      label: "Commission Amount",
      renderData: (row) => {
        let is_selected = selected.find((object) => row._id === object._id);
        let commission_amount =
          is_selected && is_selected.commission_amount
            ? is_selected.commission_amount
            : row.commission_amount;

        return (
          <TextField
            id="outlined-basic"
            variant="outlined"
            fullWidth
            size="small"
            type="number"
            required
            name={row._id}
            className="default_consultant_commission_input"
            value={commission_amount}
            InputProps={{
              inputProps: { min: 0, max: planDetail.commission_amount },
            }}
            sx={{
              "& fieldset": { border: "none" },
            }}
            onChange={(e) => {
              handleChange(e, "commission_amount");
            }}
          />
        );
      },
    },
  ];

  useEffect(() => {
    getTemplateConfig();
  }, []);
  useEffect(() => {
    isAnyCommissionGreaterThanTen(selected);
  }, [selected]);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  const websitePages = window.location.pathname.includes("/website-pages");

  let breadCrumbMenu = [
    {
      title: pageDetail.sale_page_title,
      navigation: path.pathname.includes("/website-pages")
        ? "/website-pages"
        : `/website-links`,
      active: false,
    },

    {
      title: planDetail.plan_title,
      navigation: -1,
      active: false,
    },
    {
      title: "manage Sale Team commission",
      active: true,
    },
  ];
  console.log(programsList, "----programsList");
  console.log(selected, "----selected");
  return (
    <div className="container">
      <div className="row mb-4">
        <div className="col-12 mb-3">
          <span>
            <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
          </span>
        </div>
        <div className="col-6">
          <h2>Sales Team Commission</h2>
        </div>{" "}
        {isAnyCommissionGreaterThanTen(selected) && (
          <div className="col-6">
            <Alert severity="warning">
              Commission amount must be less than £
              {planDetail.commission_amount.toFixed(2)}.
            </Alert>
          </div>
        )}
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-12">
            <CustomMUITable
              TABLE_HEAD={TABLE_HEAD}
              data={programsList}
              selected={selected}
              setSelected={setSelected}
              checkbox_selection={true}
              pagePagination={false}
              rows_per_page_count={rowPerPage}
              hide_footer_pagination={true}
              title={`Your Commission on every transaction : £${planDetail.commission_amount?.toFixed(
                2
              )}`}
            />
          </div>
        </div>
        <div className="ms-auto text-end">
          <button
            className="small-contained-button mt-3 ml-auto"
            //   onClick={}
          >
            {isLoadingForm ? "Updating..." : "Update"}
          </button>
        </div>
      </form>
    </div>
  );
}
