export function htmlDecode(input) {
  var doc = new DOMParser().parseFromString(input, "text/html");
  return doc.documentElement.textContent;
}

export function urlify(text) {
  var urlRegex = /(https?:\/\/[^\s]+)/g;

  return text.replace(urlRegex, function (url) {
    var hyperlink = url;
    if (!hyperlink.match("^https?://")) {
      hyperlink = "http://" + hyperlink;
    }
    return (
      '<a class="click-able-link" target="_blank" href="' +
      url +
      '" rel="noopener" noreferrer>' +
      url +
      "</a>"
    );
  });
  // or alternatively
}
export function htmlDecodes(input) {
  var doc = new DOMParser().parseFromString(input, "text/html");
  return doc.documentElement.textContent;
}

export function download_csv_file(csv, csvFileData) {
  const headerRow = csv.map((column) => column.label);
  const csvRows = [headerRow];

  csvFileData.forEach((row) => {
    const dataRow = csv.map((column) => {
      const value = row[column.key] || "";
      return value.toString();
    });

    csvRows.push(dataRow);
  });

  const csvContent = csvRows.map((row) => row.join(",")).join("\n");

  const hiddenElement = document.createElement("a");
  hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csvContent);
  hiddenElement.target = "_blank";
  hiddenElement.download = "data.csv";
  hiddenElement.click();
}
