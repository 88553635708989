import {
  Checkbox,
  FormControlLabel,
  TextareaAutosize,
  TextField,
  Tooltip,
} from "@mui/material";
import {
  DesktopDatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React from "react";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

export default function CreatePoll(props) {
  const { pollInfo, setPollInfo, pollOptions, setPollOptions } = props;

  const handleChangeDates = (name, value) => {
    setPollInfo((values) => ({ ...values, [name]: value.$d }));
  };

  const handleChange = (e) => {
    setPollInfo((values) => ({
      ...values,
      is_multiple_allow: e.target.checked,
    }));
  };

  const handleChangeOptions = (e, index) => {
    const { name, value } = e.target;
    const list = [...pollOptions];
    list[index][name] = value;
    setPollOptions(list);
  };

  const handleAddOption = () => {
    const addedOptions = [...pollOptions, { text: "" }];
    setPollOptions(addedOptions);
  };

  const handleDeleteOption = (i) => {
    const poll_options = [...pollOptions];
    poll_options.splice(i, 1);
    setPollOptions(poll_options);
  };

  console.log(pollInfo, "pollInfopollInfo");

  return (
    <div className="create-poll-box">
      <div className="row ">
        <div className="col-12 col-sm-6 mb-2">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              label="Expiry Date"
              name="expiry_date"
              inputFormat="DD-MM-YYYY"
              value={pollInfo.expiry_date}
              format="DD-MM-YYYY"
              disablePast
              className="mt-3"
              onChange={(e) => handleChangeDates("expiry_date", e)}
              renderInput={(params) => (
                <TextField {...params} required={true} size="small" />
              )}
            />
          </LocalizationProvider>
        </div>
        <div className="col-12 col-sm-6 mb-2">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
              label="Expiry Time"
              name="expiry_time"
              disablePast
              value={pollInfo.expiry_time}
              sx={{ color: "#fff" }}
              className="mt-3"
              onChange={(e) => handleChangeDates("expiry_time", e)}
              renderInput={(params) => <TextField {...params} size="small" />}
            />
          </LocalizationProvider>
        </div>
        <div className="col-12">
          {pollOptions?.map((data, index) => {
            return (
              <div className="d-flex justify-content-between align-items-center">
                <div className="mt-2 field-container">
                  <TextareaAutosize
                    aria-label="empty textarea"
                    placeholder={`Option ${index + 1}.`}
                    className="text-color textarea-autosize"
                    required
                    value={data.text}
                    minRows={1}
                    name="text"
                    onChange={(e) => handleChangeOptions(e, index)}
                    style={{
                      width: "100%",
                      borderRadius: "8px",
                      background: "transparent",
                      paddingTop: "7px",
                      paddingBottom: "7px",
                      paddingLeft: "15px",
                      color: "white",
                      fontSize: "15px",
                      border: "1px solid var(--input-border-color)",
                    }}
                  />
                </div>
                <div className="text-end">
                  {pollOptions.length > 2 && (
                    <Tooltip title="Remove">
                      <RemoveCircleOutlineIcon
                        onClick={() => handleDeleteOption(index)}
                        className="remove-poll-options"
                      />
                    </Tooltip>
                  )}
                  {pollOptions.length < 5 && (
                    <Tooltip title="Add">
                      <AddCircleOutlineIcon
                        onClick={handleAddOption}
                        className="diary-icon-add"
                      />
                    </Tooltip>
                  )}
                </div>
              </div>
            );
          })}
          <div className="ps-1 allow-multiple-options">
            <FormControlLabel
              control={
                <Checkbox
                  checked={pollInfo.is_multiple_allow}
                  onChange={handleChange}
                />
              }
              label="Allow Selecting Multiple Options"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
