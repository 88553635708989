import { Avatar } from "@mui/material";
import React from "react";
import { communityHandScreenshot, communityHeart } from "src/assets";
import { useContentSetting } from "src/Hooks/ContentSettingState";
import { get_date_with_user_time_zone } from "src/utils/constants";

export default function UserInfoCard({
  profile_image,
  user_name,
  date,
  activity_type,
  avatar_char,
}) {
  const { userInfo, adminTimeZone } = useContentSetting();

  return (
    <>
      <div className="post-creator user-info-card">
        <div className="like-profile-image">
          <Avatar src={profile_image} alt="photoURL">
            {avatar_char}
          </Avatar>
          {(activity_type === "like" || activity_type === "gratitude") && (
            <div className="tiny-like-icon">
              {activity_type === "like" ? (
                <img src={communityHeart} alt="" />
              ) : (
                <img src={communityHandScreenshot} alt="" />
              )}
            </div>
          )}
        </div>
        <div className="creator-name ps-2 pt-1 text-start">
          <span className="d-flex feed-level">
            <h3 className="mb-0">{user_name}</h3>
          </span>

          <span className="date-color">
            {get_date_with_user_time_zone(
              date,
              "DD MMM YYYY [at] hh:mm A",
              userInfo.time_zone,
              adminTimeZone
            )}
          </span>
        </div>
      </div>
    </>
  );
}
