import React from "react";
import { Box, Card, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import { htmlDecode } from "src/utils/convertHtml";
import LockIcon from "@mui/icons-material/Lock";
import CustomPopover from "src/components/CustomPopover";

const ImageStyle = styled("img")({
  top: 0,
  width: "100%",
  height: "auto",
  objectFit: "cover",
});

// ----------------------------------------------------------------------

export default function CustomMUICard({
  data,
  handleClick,
  MENU_OPTIONS,
  handleButtonClick,
}) {
  const getIcon = (name) => {
    if (name === "MUILockIcon") {
      return <LockIcon />;
    }
  };

  return (
    <div className="row">
      {data.map((item, index) => {
        return (
          <div className={item.card_classes} key={index}>
            <Card className="mui-custom-card h-100" sx={{ cursor: "pointer" }}>
              <Box
                sx={{
                  position: "relative",
                  cursor: "pointer",
                }}
              >
                {item.card_button && item.card_button.button_title && (
                  <button
                    variant="contained"
                    className="small-contained-button mui-card-button"
                    onClick={
                      handleButtonClick
                        ? () => {
                            handleButtonClick(item);
                          }
                        : undefined
                    }
                  >
                    {/* if you want to pass icon with title */}
                    {item.card_button.button_icon &&
                      getIcon(item.card_button.button_icon)}
                    &nbsp;&nbsp;{item.card_button.button_title}
                  </button>
                )}
                <ImageStyle
                  alt={item.title}
                  src={item.card_image}
                  onClick={
                    handleClick
                      ? () => {
                          handleClick(item);
                        }
                      : undefined
                  }
                />
              </Box>
              {MENU_OPTIONS && (
                <div className="mui-custom-card-dots">
                  <CustomPopover menu={MENU_OPTIONS} data={item} />
                </div>
              )}
              <Stack
                spacing={2}
                className="mui-custom-card-content"
                onClick={
                  handleClick
                    ? () => {
                        handleClick(item);
                      }
                    : undefined
                }
              >
                <h3 className="pe-4">{htmlDecode(item.card_title)}</h3>
                {item.card_short_description && (
                  <p className="mui-custom-card-description">
                    {htmlDecode(item.card_short_description)}
                  </p>
                )}
                {item.card_short_description_2 && (
                  <p className="card_lower_title_description">
                    {htmlDecode(item.card_short_description_2)}
                  </p>
                )}
                {item.card_gray_description && (
                  <p className="mui_gray_description">
                    <span>{item.card_gray_description}</span>
                  </p>
                )}
              </Stack>
            </Card>
          </div>
        );
      })}
    </div>
  );
}
