import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { useState } from "react";
import SettingsSidebar from "./dashboard/SettingsSidebar";

const RootStyle = styled("div")({
  display: "flex",
  minHeight: "100%",
  overflow: "hidden",
});

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 15;

const MainStyle = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  minHeight: "100%",
  paddingTop: APP_BAR_MOBILE,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up("lg")]: {
    paddingTop: APP_BAR_DESKTOP,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

export default function SettingsLayout() {
  const location = useLocation();

  return (
    <RootStyle>
      <SettingsSidebar />

      <MainStyle>
        <Outlet />
      </MainStyle>
    </RootStyle>
  );
}
