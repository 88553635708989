import React, { useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { invokeApi } from "src/bl_libs/invokeApi";
import { s3baseUrl } from "src/config/config";

export default function CkeditorForChat({
  inputs,
  setInputs,
  name,
  editorHeight,
  direct_state,
  className,
  is_array,
  index,
  add_style,
}) {
  const editorRef = useRef(null);

  const log = () => {
    if (editorRef.current) {
      if (is_array) {
        const list = [...inputs];
        list[index][name] = editorRef.current.getContent();
        setInputs(list);
      } else if (direct_state) {
        setInputs(editorRef.current.getContent());
      } else {
        setInputs((prevState) => ({
          ...prevState,
          [name]: editorRef.current.getContent(),
        }));
      }
    }
  };

  function example_image_upload_handler(blobInfo, success, failure, progress) {
    let requestObj = {
      path: "app/update_image_on_s3/",
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };

    let _formData = new FormData();
    _formData.append("image", blobInfo.blob());
    _formData.append("width", "600");
    requestObj["postData"] = _formData;

    invokeApi(requestObj).then((res) => {
      if (res.code === 200) {
        success(s3baseUrl + res.image_path);
      } else {
        failure(res.message);
      }
    });
  }

  return (
    <div className={className}>
      <Editor
        apiKey="pp4itzf43oqpk1yvcc0whqmbss5riqi49qzl7l9hni59yyfi"
        onChange={log}
        value={
          is_array ? inputs[index][name] : direct_state ? inputs : inputs[name]
        }
        onInit={(evt, editor) => (editorRef.current = editor)}
        onEditorChange={(newValue, editor) => {
          log(newValue, editor);
        }}
        init={{
          images_upload_handler: example_image_upload_handler,
          height: editorHeight ? editorHeight : 500,
          menubar: false,
          plugins: [
            "advlist autolink lists link image charmap print preview anchor",
            "searchreplace visualblocks code fullscreen",
            "insertdatetime media table paste code wordcount",
          ],
          toolbar: "link | bold italic underline | forecolor backcolor",
          content_style: `body {font-family:Helvetica,Arial,sans-serif; font-size:14px; ${add_style}}`,
          // "body {font-family:Helvetica,Arial,sans-serif; font-size:14px; color: white; }",
        }}
      />
    </div>
  );
}
