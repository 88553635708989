import React from "react";
import RecordNotFound from "../RecordNotFound";
import { replaceName } from "src/utils/constants";
import { htmlDecode } from "src/utils/convertHtml";

function DashboardAssessment({
  sourceSessions,
  name,
  title,
  WheelOfLifeQuestions,
  intention_statement,
  wheelOfLifeStatment,
}) {
  const replacedStringName = (string) => {
    if (string) {
      return string.replace(/{Name}/g, " ");
    } else {
      return "";
    }
  };
  return (
    <div className="mb-3">
      <h4 className="p-3 pb-0 questions-heading">
        {title ? title : "Goal Statement"}
      </h4>
      <hr />
      <div className="p-3 pb-0 pt-0">
        {sourceSessions?.length < 1 ? (
          <RecordNotFound title="Questions" />
        ) : (
          sourceSessions?.map((value, i) => {
            return (
              <div className="d-flex">
                <b>{i + 1}.</b>
                <div className="text-start comment-icon w-100 ps-2 source-session">
                  <div className="card-title">
                    <h3 className="mb-0">
                      {htmlDecode(replaceName(value.question_statement, name))}
                    </h3>
                    {value.description ? (
                      <p>{htmlDecode(value?.description)}</p>
                    ) : (
                      ""
                    )}
                    {value?.answer ? (
                      <p className="mt-2">{htmlDecode(value?.answer)}</p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            );
          })
        )}
      </div>

      <h4 className="p-3 pb-0 questions-heading">
        {"Wheel Of Life Questions"}
      </h4>
      <hr />

      <div className="p-3 pb-0 pt-0">
        {WheelOfLifeQuestions && WheelOfLifeQuestions?.length < 1 ? (
          <RecordNotFound title="Questions" />
        ) : (
          WheelOfLifeQuestions?.map((value, i) => {
            return (
              <div className="d-flex">
                {/* <Avatar
                  className="avatar-adjust"
                  sx={{ width: 40, height: 40 }}
                  alt={value?.title}
                  src={s3baseUrl + value?.image.thumbnail_2}
                /> */}
                <b>{i + 1}.</b>
                <div className="text-start comment-icon w-100 ps-2 source-session">
                  <div className="card-title">
                    <h3 className="mb-0">
                      {htmlDecode(replaceName(value.question_statement, name))}
                    </h3>
                    {value.description ? (
                      <p>{htmlDecode(value?.description)}</p>
                    ) : (
                      ""
                    )}
                    {value?.answer ? (
                      <p className="mt-2">
                        {htmlDecode(value?.answer)}
                        {/* {get_short_string(value?.answer, 30)} */}
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            );
          })
        )}
      </div>
      {WheelOfLifeQuestions && (
        <>
          <h4 className="p-3 pb-0 questions-heading">
            {"Wheel Of Life Intention Statement"}
          </h4>
          <hr />
        </>
      )}
      <div className="d-flex">
        <div className="text-start comment-icon w-100 ps-2 source-session">
          <div className="card-title">
            {wheelOfLifeStatment && (
              <h3 className="mb-0">
                <div
                  dangerouslySetInnerHTML={{
                    __html: replacedStringName(wheelOfLifeStatment),
                  }}
                ></div>
              </h3>
            )}
            {intention_statement ? (
              <p className="mt-2">{htmlDecode(intention_statement)}</p>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardAssessment;
