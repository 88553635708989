import { invokeApi } from "../../bl_libs/invokeApi";
export const ProgressListApi = async (path, data) => {
  const requestObj = {
    path: path,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: {
      // filter: valueData,
    },

    // postData: data,
  };

  if (data?.filter_by !== undefined) {
    requestObj.postData.start_date = data.start_date;
    requestObj.postData.end_date = data.end_date;
    requestObj.postData.progress_category = data.progress_category;
    requestObj.postData.filter_by = data.filter_by;
  } else {
    requestObj.postData.filter_by = "all";
  }

  return invokeApi(requestObj);
};
export const AddProgressAPI = async (data) => {
  const requestObj = {
    path: `api/progress_report/`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const DeleteProgress = async (id) => {
  const requestObj = {
    path: `api/progress_report/delete/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const DetailProgressApi = async (id) => {
  const requestObj = {
    path: `api/progress_report/detail/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const EditProgressAPI = async (id, data) => {
  const requestObj = {
    path: `api/progress_report/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _add_progress_note = async (data) => {
  const requestObj = {
    path: `api/progress_internal_note/`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const list_progress_note = async (Id) => {
  const requestObj = {
    path: `api/progress_internal_note/list_progress_internal_note/${Id}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const _update_progress_note = async (data, id) => {
  const requestObj = {
    path: `api/progress_internal_note/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _delete_progress_id = async (ProgressId, id) => {
  const requestObj = {
    path: `api/progress_internal_note/delete/${ProgressId}/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    // postData: data,
  };
  return invokeApi(requestObj);
};

export const ListProgressCategory = async () => {
  const requestObj = {
    path: `api/progress_report_cateogry/list_progress_report_category_for_delegates`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

// export const UploadSupportTicketImageOnS3 = async (data) => {
//     const requestObj = {
//       path: `api/support_ticket/upload_support_ticket_comment_images`,
//       method: "POST",
//       headers: {
//         "x-sh-auth": localStorage.getItem("token"),
//       },
//       postData: data,
//     };
//     return invokeApi(requestObj);
//   };

//   export const DeleteCommentImageOnS3 = async (data) => {
//     const requestObj = {
//       path: `api/support_ticket/delete_support_ticket_comment_images_from_s3/`,
//       method: "POST",
//       headers: {
//         "x-sh-auth": localStorage.getItem("token"),
//       },
//       postData: data,
//     };
//     return invokeApi(requestObj);
//   };
