import React, { useEffect, useState, useRef } from "react";

export default function HiddenAudioPlayer({ fileUrl }) {
  const [isMuted, setIsMuted] = useState(true);
  const audioRef = useRef(null);

  useEffect(() => {
    audioRef.current = new Audio(fileUrl);
    audioRef.current.muted = true;

    const tryPlayAudio = () => {
      audioRef.current.play().catch((error) => {
        console.log("Autoplay attempt failed:", error);
      });
    };

    tryPlayAudio();
    audioRef.current.addEventListener("ended", tryPlayAudio);
    const handleUserClick = () => {
      audioRef.current.muted = false;
      tryPlayAudio();
      window.removeEventListener("click", handleUserClick);
    };
    window.addEventListener("click", handleUserClick);

    return () => {
      audioRef.current.pause();
      window.removeEventListener("click", handleUserClick);
      audioRef.current.removeEventListener("ended", tryPlayAudio);
      audioRef.current = null;
    };
  }, [fileUrl]);

  const toggleMute = () => {
    if (audioRef.current) {
      audioRef.current.muted = !isMuted;
      setIsMuted(!isMuted);
    }
  };

  return (
    <div className="w-100 hidden-audio-player">
      <button onClick={toggleMute}>{isMuted ? "Unmute" : "Mute"} Audio</button>
    </div>
  );
}
