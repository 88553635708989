import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function GroupList({ groupList }) {
  const classes = useStyles();
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getConsultantListing = async () => {
    setIsLoading(true);
    const data = groupList?.map((group) => {
      let member_name = "N/A";
      if (group.member) {
        member_name = group.member.length;
      }
      return {
        ...group,
        member_name,
      };
    });
    setUserList(data);
    setIsLoading(false);
  };

  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    { id: "title", label: " Name" },
    { id: "member_name", label: "Member" },
  ];

  useEffect(() => {
    getConsultantListing();
  }, []);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="row">
        <div className="col-12">
          <CustomMUITable
            TABLE_HEAD={TABLE_HEAD}
            data={userList}
            className="card-with-background"
            pagePagination={true}
          />
        </div>
      </div>
    </>
  );
}
