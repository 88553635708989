import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { IconButton, CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { lessonList, programmDetail } from "../../DAL/Programmes/Programmes";
import { s3baseUrl } from "../../config/config";
import CustomMUICard from "src/components/GeneralComponents/CustomMUICard";
import RecordNotFound from "src/components/GeneralComponents/RecordNotFound";
// ----------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

// ----------------------------------------------------------------------

export default function LessonListing() {
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();
  const navigate = useNavigate();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [lessonData, setLessonData] = useState([]);
  const [programmesDetail, setProgrammesDetail] = useState({});

  const getProgrammesDetail = async () => {
    const result = await programmDetail(params.program_slug);
    if (result.code === 200) {
      setProgrammesDetail(result.program);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      navigate(`/programmes`);
    }
  };

  const getLessonList = async () => {
    const result = await lessonList(params.program_slug);
    if (result.code === 200) {
      let CARD_OPTIONS = [];
      result.lesson.map((lesson) => {
        CARD_OPTIONS.push({
          ...lesson,
          card_title: lesson.title,
          card_short_description: lesson.short_description,
          card_image: s3baseUrl + lesson.lesson_images.thumbnail_2,
          card_classes: "col-lg-4 col-md-6 col-sm-12 mt-4",
        });
      });
      setLessonData(CARD_OPTIONS);
      getProgrammesDetail();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      navigate(`/programmes`);
    }
  };

  const handleClick = (object) => {
    navigate(`/lessons/${object.lesson_slug}`, {
      state: object,
    });
  };

  useEffect(() => {
    getLessonList();
  }, []);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="container">
        <div className="row mobile-margin display-flex">
          <div className="col-12">
            <IconButton
              className="back-screen-button"
              onClick={() => navigate(-1)}
            >
              <ArrowBackIcon />
            </IconButton>
          </div>
        </div>
        <div className="row section-space">
          <div className="col-12">
            <h1>{programmesDetail.title}</h1>
          </div>
        </div>
        {lessonData.length > 0 ? (
          <CustomMUICard data={lessonData} handleClick={handleClick} />
        ) : (
          <RecordNotFound title="Lessons Not Found" />
        )}
      </div>
    </>
  );
}
