import React from "react";
import { filler_bottom_img, filler_top_img } from "src/assets";

function Filler(props) {
  const {
    maximum_like_count,
    like_count_for_bonus,
    coins_count_for_next_level,
    coins_count,
  } = props;

  const get_top_filler_color = () => {
    const percentage_fill = (like_count_for_bonus / maximum_like_count) * 100;
    const percentage_empty = 100 - percentage_fill;

    return `linear-gradient(#1d1c1d ${percentage_empty}%, #db4437 ${percentage_fill}%)`;
  };

  const get_bottom_filler_color = () => {
    const percentage_fill = (coins_count / coins_count_for_next_level) * 100;
    const percentage_empty = 100 - percentage_fill;

    return `linear-gradient(#1d1c1d ${percentage_empty}%, #847752 ${0}%)`;
  };
  return (
    <div>
      <img
        style={{ background: get_top_filler_color() }}
        src={filler_top_img}
      ></img>
      <img
        style={{ background: get_bottom_filler_color() }}
        src={filler_bottom_img}
      ></img>
    </div>
  );
}

export default Filler;
