import React, { useRef, useState, useEffect } from "react";
import RecordRTC from "recordrtc";
import { TestLiveStreem } from "src/DAL/SupportTicket/SupportTicket";

const VideoRecorderUploader = () => {
  const videoRef = useRef(null);
  const [recorder, setRecorder] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [isUploadEnabled, setIsUploadEnabled] = useState(false);
  let [chunkIndex, setChunkIndex] = useState(0);
  const [rmtpurl, setUrl] = useState("");

  // Get access to the user's camera
  const initCamera = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({ video: true });
    videoRef.current.srcObject = stream;
    return stream;
  };

  // Start recording
  const startRecording = () => {
    setIsRecording(true);
    const formData = new FormData();
    formData.append("type", "start");
    formData.append("module_id", generateFiveDigitID());
    handleSubmit(formData, "start");
  };

  // Stop recording
  const stopRecording = () => {
    recorder.stopRecording(() => {
      const blob = recorder.getBlob();
      const videoURL = URL.createObjectURL(blob);
      videoRef.current.srcObject = null;
      videoRef.current.src = videoURL;
      videoRef.current.controls = true;

      // Save the video file locally
      const link = document.createElement("a");
      link.href = videoURL;
      link.download = "recorded-video.webm";
      link.click();

      setIsUploadEnabled(true);
      setIsRecording(false);
    });
  };
  function generateFiveDigitID() {
    return Math.floor(10000 + Math.random() * 90000);
  }

  const handleSubmit = async (data, type) => {
    console.log(...data, "datadatadatadatadatadata");
    console.log(type, "typetypetype");
    const result = await TestLiveStreem(data);
    if (type == "start") {
      console.log(result, "resultresultresult");
      setUrl(result.response.rtmp_url);
    }
    console.log(result, "resultresultresultresultresultresultresult");
  };
  // Send the video chunk to the server
  const sendRecordingChunk = async (blob) => {
    if (blob instanceof Blob) {
      console.log(blob, "blobblobblob");
      const formData = new FormData();
      formData.append("file", blob, `chunk_${chunkIndex++}.webm`);
      formData.append("type", "upload");
      formData.append("rtmp_url", rmtpurl);
      handleSubmit(formData, "send");
    } else {
      console.error("Error: getBlob() did not return a Blob object.");
    }
  };

  // Upload the entire video manually
  const uploadVideo = async (stream) => {
    const newRecorder = RecordRTC(stream, {
      type: "video",
      mimeType: "video/webm",
      timeSlice: 10000, // Send chunks every 5 seconds
      ondataavailable: sendRecordingChunk,
    });
    console.log(newRecorder, "newRecordernewRecordernewRecorder");
    newRecorder.startRecording();
    setRecorder(newRecorder);
    // setIsRecording(true);
    setIsUploadEnabled(false);
  };

  // Initialize camera on component mount
  useEffect(() => {
    const init = async () => {
      const stream = await initCamera();
      return stream;
    };

    init();
  }, []);
  console.log(rmtpurl, "rmtpurlrmtpurlrmtpurl");
  return (
    <div>
      <video ref={videoRef} autoPlay style={{ width: "100%" }}></video>
      <div>
        <button onClick={() => startRecording()} disabled={isRecording}>
          Start Recording
        </button>
        <button onClick={stopRecording} disabled={!isRecording}>
          Stop Recording
        </button>
        <button onClick={() => uploadVideo(videoRef.current.srcObject)}>
          Upload Video
        </button>
      </div>
    </div>
  );
};

export default VideoRecorderUploader;
