import React, { useState, useEffect } from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { useSnackbar } from "notistack";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import dayjs from "dayjs";
import { makeStyles } from "@mui/styles";
import { get_root_value } from "src/utils/domUtils";
import { update_event_iteration_api } from "src/DAL/Calendar/Calendar";
import { TIME_ZONE_CONVERSION } from "src/utils/constants";
import { useContentSetting } from "src/Hooks/ContentSettingState";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCkeditor";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

const useStyles = makeStyles(() => ({
  paper: {
    background: get_root_value("--popup-background-color"),
    color: get_root_value("--input-text-color"),
  },
}));

const notification_object = {
  notify_before_unit: "minutes",
  notify_before_time: 30,
  notification_title: "",
};

export default function UpdateEventIteration({
  editValues,
  dataList,
  setShowEditComponent,
  setEventDetailData,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();
  const { userInfo, adminTimeZone } = useContentSetting();
  const [notificationArray, setNotificationArray] = useState([
    notification_object,
  ]);

  const [state, setState] = useState({
    event_title: "",
    description: "",
    event_color: "#000",
    event_slug: "",
    notify_before_unit: "minutes",
    notify_before_time: 30,
    status: true,
    start_date: dayjs(new Date()).$d,
    end_date: dayjs(new Date()).$d,
    start_time: dayjs(new Date()).$d,
    end_time: dayjs(new Date()).$d,
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleChangeNotification = (event, index) => {
    const { name, value } = event.target;
    let temp_state = [...notificationArray];
    let temp_element = { ...temp_state[index] };
    temp_element[name] = value;
    temp_state[index] = temp_element;
    setNotificationArray(temp_state);
  };

  const handleChangeDate = (name, event) => {
    setState((prevState) => {
      return {
        ...prevState,
        [name]: event.$d,
      };
    });
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const formData = {
      iteration_id: editValues._id,
      start_date: moment(state.start_date).format("YYYY-MM-DD"),
      end_date: moment(state.end_date).format("YYYY-MM-DD"),
      start_time: moment(state.start_time).format("HH:mm"),
      end_time: moment(state.end_time).format("HH:mm"),
      title: state.event_title,
      color: state.event_color,
      description: state.description,
      status: state.status,
      is_notify_user: notificationArray.length > 0 ? true : false,
      notify_before: notificationArray,
    };

    const result = await update_event_iteration_api(formData, state.event_slug);
    if (result.code === 200) {
      setEventDetailData({
        ...state,
        date: moment(state.start_date).format("YYYY-MM-DD"),
        start_date: moment(state.start_date.$d).format("YYYY-MM-DD"),
        start_date_time: result.event.start_date_time,
        end_date_time: result.event.end_date_time,
        end_date: moment(state.end_date.$d).format("YYYY-MM-DD"),
        is_notify_user: notificationArray.length > 0 ? true : false,
        notify_before: notificationArray,
      });
      dataList();
      setIsLoading(false);
      setShowEditComponent(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const get_start_end_time = (date) => {
    return TIME_ZONE_CONVERSION(
      date,
      "YYYY-MM-DD hh:mm A",
      adminTimeZone,
      userInfo.time_zone
    );
  };

  const handleAddNotification = () => {
    setNotificationArray((old_array) => [...old_array, notification_object]);
  };

  const handleRemoveNotification = (index) => {
    const updatedItems = [...notificationArray];
    updatedItems.splice(index, 1);
    setNotificationArray(updatedItems);
  };

  useEffect(() => {
    setState({
      ...editValues,
      start_date: dayjs(get_start_end_time(editValues.start_date_time)).$d,
      end_date: dayjs(get_start_end_time(editValues.end_date_time)).$d,
      start_time: dayjs(get_start_end_time(editValues.start_date_time)).$d,
      end_time: dayjs(get_start_end_time(editValues.end_date_time)).$d,
    });
    let notify_before = [];
    if (editValues.notify_before && editValues.notify_before.length > 0) {
      notify_before = editValues.notify_before;
    }
    setNotificationArray(notify_before);
  }, [editValues]);

  return (
    <div className="container new-memories">
      <form onSubmit={handleUpdate} className="row">
        <div className="col-12">
          <TextField
            className="mt-4"
            id="outlined-basic"
            label="Title"
            variant="outlined"
            name="event_title"
            value={state.event_title}
            required={true}
            onChange={handleChange}
          />
        </div>
        <div className="col-12">
          <TextField
            id="color"
            type="color"
            label="Color"
            name="event_color"
            className="mt-3"
            variant="outlined"
            required={true}
            value={state.event_color}
            onChange={handleChange}
          />
        </div>
        <div className="col-12">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              label="Start Date"
              name="start_date"
              inputFormat="DD-MM-YYYY"
              value={state.start_date}
              format="DD-MM-YYYY"
              className="mt-3"
              onChange={(e) => handleChangeDate("start_date", e)}
              renderInput={(params) => (
                <TextField {...params} required={true} />
              )}
            />
          </LocalizationProvider>
        </div>
        <div className="col-12">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
              label="Start Time"
              name="start_time"
              value={state.start_time}
              sx={{ color: "#fff" }}
              className="mt-3"
              onChange={(e) => handleChangeDate("start_time", e)}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </div>
        <div className="col-12">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              label="End Date"
              name="end_date"
              inputFormat="DD-MM-YYYY"
              value={state.end_date}
              format="DD-MM-YYYY"
              className="mt-3"
              onChange={(e) => handleChangeDate("end_date", e)}
              renderInput={(params) => (
                <TextField {...params} required={true} />
              )}
            />
          </LocalizationProvider>
        </div>
        <div className="col-12">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
              label="End Time"
              name="end_time"
              value={state.end_time}
              sx={{ color: "#fff" }}
              className="mt-3"
              onChange={(e) => handleChangeDate("end_time", e)}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </div>
        <div className="mt-3 mb-2">
          <h4>Notifications Setting</h4>
        </div>
        {notificationArray.length > 0 &&
          notificationArray.map((notification, index) => {
            return (
              <>
                <div className="col-11">
                  <TextField
                    className="mb-3"
                    id="outlined-basic"
                    label={`Notification ${index + 1} Title`}
                    variant="outlined"
                    name="notification_title"
                    value={notification.notification_title}
                    onChange={(e) => {
                      handleChangeNotification(e, index);
                    }}
                    required={true}
                  />
                </div>
                <div className="col-6">
                  <FormControl variant="outlined" className="mb-3">
                    <InputLabel id="demo-simple-select-outlined-label">
                      Notify Before
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={notification.notify_before_unit}
                      onChange={(e) => {
                        handleChangeNotification(e, index);
                      }}
                      label="Notifications Before"
                      name="notify_before_unit"
                      className="inputs-fields svg-color"
                      MenuProps={{
                        classes: {
                          paper: classes.paper,
                        },
                      }}
                      sx={{
                        color: get_root_value("--input-text-color"),
                      }}
                    >
                      <MenuItem value="days">Days</MenuItem>
                      <MenuItem value="hours">Hours</MenuItem>
                      <MenuItem value="minutes">Minutes</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="col-5">
                  <TextField
                    className="mb-3"
                    id="outlined-basic"
                    label={`${
                      notification.notify_before_unit == "days"
                        ? "Days"
                        : notification.notify_before_unit == "hours"
                        ? "Hours"
                        : "Minutes"
                    }`}
                    variant="outlined"
                    name="notify_before_time"
                    type="number"
                    value={notification.notify_before_time}
                    required={true}
                    onChange={(e) => {
                      handleChangeNotification(e, index);
                    }}
                    inputProps={{
                      min: 0,
                      max:
                        notification.notify_before_unit == "days"
                          ? 100
                          : notification.notify_before_unit == "hours"
                          ? 24
                          : 60,
                    }}
                  />
                </div>
                <div className="col-1 p-0 mt-4">
                  <Tooltip title="Remove">
                    <RemoveCircleOutlineIcon
                      onClick={() => handleRemoveNotification(index)}
                      className="diary-icon-remove"
                    />
                  </Tooltip>
                </div>
              </>
            );
          })}
        <div className="col-12 mt-1">
          <FormControlLabel
            control={<AddCircleOutlineIcon className="diary-icon-add mx-2" />}
            label="Add Notification"
            onClick={handleAddNotification}
          />
        </div>
        <div className="col-12">
          <FormControl variant="outlined" className="mt-3">
            <InputLabel id="demo-simple-select-outlined-label">
              Status
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={state.status}
              onChange={handleChange}
              label="Status"
              name="status"
              className="svg-color"
              MenuProps={{
                classes: {
                  paper: classes.paper,
                },
              }}
              sx={{
                color: get_root_value("--input-text-color"),
              }}
            >
              <MenuItem value={true}>Active</MenuItem>
              <MenuItem value={false}>Inactive</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="col-12 mt-3">
          <h4>Event Description </h4>
          <GeneralCkeditor
            setInputs={setState}
            inputs={state}
            name="description"
            editorHeight={320}
          />
        </div>
        <div className="text-end mt-3 mb-4">
          <button className="small-contained-button" disabled={isLoading}>
            {isLoading ? "Updating..." : "Update"}
          </button>
        </div>
      </form>
    </div>
  );
}
