import * as React from "react";
import { useNavigate } from "react-router";
import NewPodsRoomCards from "src/pages/Room/NewPodsRoomCards";

export default function NewPods() {
  return (
    <>
      <NewPodsRoomCards />
    </>
  );
}
