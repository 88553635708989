import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useSnackbar } from "notistack";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import unlockFill from "@iconify/icons-eva/unlock-fill";
import { makeStyles } from "@mui/styles";
import { afflicateChangeid, changePassword } from "src/DAL/Profile/Profile";
import { useContentSetting } from "src/Hooks/ContentSettingState";
import { useEffect } from "react";
import { Tooltip } from "@mui/material";
import { infoImages } from "src/assets";
// import { chanePassword } from "src/DAL/Login/Login";
const useStyles = makeStyles({
  resize: {
    fontSize: 20,
  },
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 380,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  borderRadius: 1,
};

export default function ChangeAffliateId({
  openPasswordModal,
  setopenPasswordModal,
}) {
  const { userInfo, setUserInfo } = useContentSetting();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const handleOpen = () => setopenPasswordModal(true);
  const handleClose = () => setopenPasswordModal(false);
  const [isLoading, setIsLoading] = useState(false);
  const [inputs, setInputs] = useState({
    name: "",
    affiliate_url_name: "",
  });

  const handlechangeAffiliate = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const postData = {
      affiliate_url_name: inputs.affiliate_url_name,
      user_type: "consultant",
    };

    const result = await afflicateChangeid(postData, userInfo._id);
    if (result.code === 200) {
      setIsLoading(false);
      setUserInfo((value) => ({
        ...value,
        ["affiliate_url_name"]: result.affiliate_url_name,
      }));

      enqueueSnackbar(result.message, { variant: "success" });
      setopenPasswordModal(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
      setopenPasswordModal(true);
    }
  };

  const handleChange = (event) => {
    let value = event.target.value;
    let name = event.target.name;

    value = value.toLowerCase().replace(/\s+/g, "-");

    setInputs((values) => ({ ...values, [name]: value }));
  };

  useEffect(() => {
    setInputs((inputs) => ({
      ...inputs,
      ["affiliate_url_name"]: userInfo?.affiliate_url_name
        ? userInfo?.affiliate_url_name
        : "",
      ["name"]: userInfo?.first_name + " " + userInfo?.last_name,
    }));
  }, [userInfo]);
  return (
    <div>
      <Modal
        disableTypography="true"
        open={openPasswordModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form onSubmit={handlechangeAffiliate}>
          <Box sx={style}>
            {/* <div
              clo-12
              className="text-end modalIcon"
              onClick={() => {
                handleClose();
              }}
              style={{ position: "absolute", right: " 4%", top: "4%" }}
            >
              X
            </div> */}
            <div
              class="cross-iconss"
              onClick={() => {
                handleClose();
              }}
            >
              x
            </div>
            <Typography
              className="text-center mb-4"
              id="spring-modal-title"
              variant="h6"
              component="h2"
            >
              Change Affiliate ID
              <span style={{ paddingLeft: "10px" }}>
                <Tooltip
                  title={
                    <div
                      dangerouslySetInnerHTML={{
                        __html: `
                        <p>
                  <div>
                    <b>NOTE: </b><br>If you change your affiliate ID, the links
                    you have already shared on your social media platforms will
                    not be affected directly. However, any new members who use
                    those old links will not be associated with you, as the ID
                    has changed.
                  </div>
                  &nbsp;
                  <div>
                    Therefore, every time you change your affiliate ID, you will
                    need to share the updated links again. It is important to
                    update your affiliate ID at your own risk, knowing that the
                    previously shared links will no longer track new leads to
                    your account.
                  </div>
                </p>
`,
                      }}
                    />
                  }
                >
                  <img className="info-image" src={infoImages} />
                </Tooltip>
              </span>
            </Typography>

            <div className="col-12 mt-3">
              <TextField
                className="text-muted"
                id="outlined-basic"
                label="Affiliate ID"
                variant="outlined"
                fullWidth
                size="small"
                name="affiliate_url_name"
                value={inputs.affiliate_url_name}
                onChange={handleChange}
                required={true}
              />
            </div>
            <div className="col-12 mt-3">
              <button className="small-contained-button w-100">
                {isLoading ? "Saving..." : "Save"}
              </button>
            </div>
          </Box>
        </form>
      </Modal>
    </div>
  );
}
