import { useRef, useState } from "react";
import { Menu } from "@mui/material";
import { emoji_picker_icon } from "src/assets";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
// ----------------------------------------------------------------------

export default function EmojiPickerForEditor(props) {
  const anchorRef = useRef(null);
  const { handleEmojiSelect } = props;
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSelect = (emoji) => {
    // setOpen(false);
    handleEmojiSelect(emoji);
  };

  let MORE_OPTIONS = {
    previewPosition: "none",
    searchPosition: "top",
    skinTonePosition: "none",
    maxFrequentRows: 1,
    perLine: 7,
  };
  return (
    <>
      <div className="mb-2 event-chat-emoji-picker-button emoji-picker-button-creat-post">
        <img src={emoji_picker_icon} ref={anchorRef} onClick={handleOpen} />
      </div>

      <Menu
        anchorEl={anchorRef.current}
        id="account-menu"
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
          className: "emoji-picker-popup",
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Picker
          {...MORE_OPTIONS}
          data={data}
          onEmojiSelect={handleSelect}
          theme="dark"
          dynamicWidth={false}
          searchDisabled={false} // Ensure search is enabled
          showFrequent={true}
        />
      </Menu>
    </>
  );
}
